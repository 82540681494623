import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
    TextField,
    DatePicker,
    DayOfWeek,
    Label,
    Separator,
    mergeStyleSets,
    Stack,
} from '@fluentui/react';
import { useStateContext } from '../services/contextProvider';
import {
    useGetTaskLazyQuery,
    GetTaskQuery,
    useGetMissionQuery,
    GetMissionQuery,
    GetMissionDocument,
    GetMissionQueryVariables,
} from '../data/types';

import { ResourcePicker, ResourcePickerResource } from './ResourcePicker';
import { LinkedMeasuresSelector } from './LinkedMeasuresSelector';
import { InputShimmer, TaskCategoryPicker } from './inputs';
import { ExtractQueryArrayType } from '../data/extendedTypes';
import { DatePickerStrings, useLanguage } from '../services/i18n';
import TaskWarningPanel from './TaskWarningPanel';
import { useTaskWarning } from '../hooks/useTaskWarning';
import { useIsTaskImplied } from '../hooks/useIsTaskImplied';
import DuplicateTaskList from './DuplicateTaskList';
import { useTaskEditRules } from '../hooks/useTaskEditRules';
import { Guid } from 'guid-typescript';
import { useApolloClient } from '@apollo/client';
import max from 'lodash/max';
import { sorters } from '../data/sorters';
import { ChecklistTask, TaskChecklist } from './TaskChecklist';
import { TaskStatusDropDown } from './inputs/TaskStatusDropDown';
import { PercentageCompleteInput } from './inputs/PercentageCompleteInput';
import { CostWeightingRating, EffortWeightingRating } from './WeightingRating';
import { InfoLabel } from './InfoLabel';
import { MergeControlContainer } from './MergeControlContainer';
import { RollupToggle } from './RollupToggle';
import { TaskTypeToggle } from './TaskTypeToggle';

type TaskPanelEditSectionProps = {
    missionId: string | null | undefined;
    taskId: string | null | undefined;
    newImpliedSpecifiedTaskId: string | null | undefined; // If this is a new task, use this a the specified ID
    isSaving: boolean;
    hasSaved: boolean;
    onDismiss: () => void;
    onTaskInputChanged: (
        updatedTask: ResourcedTask,
        selectedResource: ResourcePickerResource[],
        checklistTasks: ChecklistTask[],
        isValid: boolean
    ) => void;
    onSwapTask: (taskId: string | null) => void;
};

type ResourcedTask = ExtractQueryArrayType<GetTaskQuery, ['task']>;

export type ResourcedFromTask = ExtractQueryArrayType<
    GetTaskQuery,
    ['task', 'resourcedFromTask']
>;

export type DuplicateTask = ExtractQueryArrayType<
    GetTaskQuery,
    ['task', 'subTasks']
>;

export default function TaskPanelEditSection(
    props: TaskPanelEditSectionProps
): JSX.Element {
    const { taskId, missionId, newImpliedSpecifiedTaskId, onTaskInputChanged } =
        props;

    const client = useApolloClient();

    const { currentTenantId, currentFinancialYearCode, currentTeamCode } =
        useStateContext();

    const { t } = useLanguage();

    const [isValid, setIsValid] = useState(true);
    const [userHasMadeChanges, setUserHasMadeChanges] = useState(false);

    const [formErrorMessages, setFormErrorMessages] = useState({
        name: '',
    });

    const [task, setTask] = useState<ResourcedTask | null>(null);

    const [dismissedMerges, setDismissedMerges] = useState<string[]>([]);

    const [isTaskChecklistEnabled, setIsTaskChecklistEnabled] = useState(false);

    const [selectedResources, setSelectedResources] = useState<
        ResourcePickerResource[]
    >([]);

    const [checklistTasks, setChecklistTasks] = useState<ChecklistTask[]>([]);

    const validate = useCallback((): boolean => {
        const errorMessages = {
            name: !task?.name ? 'Please enter a value.' : '',
        };

        const isValid = !errorMessages.name;

        setFormErrorMessages(errorMessages);
        setIsValid(isValid);

        return isValid;
    }, [task?.name]);

    useEffect(() => {
        let validationResult = false;

        // Do not immediately validate new tasks as it looks rubbish
        if (taskId || !isValid || userHasMadeChanges) {
            validationResult = validate();
        }

        if (task) {
            onTaskInputChanged(
                task,
                selectedResources,
                checklistTasks,
                validationResult
            );
        }
    }, [
        taskId,
        onTaskInputChanged,
        validate,
        task,
        selectedResources,
        checklistTasks,
        isValid,
        userHasMadeChanges,
    ]);

    const [loadTask, { loading: isLoading, refetch }] = useGetTaskLazyQuery({
        fetchPolicy: 'network-only',
    });

    const handleWarningRequestAgainClicked = () => {
        setSelectedResources(
            selectedResources.map((r) => ({
                ...r,
                requestAgain: r.task?.utcRejected ? true : r.requestAgain,
            }))
        );
    };

    const warning = useTaskWarning(task, {
        isEditing: true,
        editingResources: selectedResources,
        onRequestAgainClicked: handleWarningRequestAgainClicked,
    });

    const { hasWarning, actions, isBlocking, enableMerge, mergeFields } =
        warning;

    const isDataImpliedTask = useIsTaskImplied(task);

    const isImpliedTask = taskId
        ? isDataImpliedTask
        : !!newImpliedSpecifiedTaskId;

    // Load the mission to get the task data if this is a new task.
    const { data: missionData } = useGetMissionQuery({
        skip: !missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: missionId || '',
        },
    });

    // Only allow resources for tasks with a mission.
    const allowResources = !!task?.mission || !!newImpliedSpecifiedTaskId;

    // Check the mission this task belongs to for the access level.
    const missionAccess = task?.mission
        ? task?.mission?.rights
        : task?.resourcedFromTask?.mission?.rights ||
          missionData?.mission?.rights;

    const taskEditRules = useTaskEditRules(task, missionAccess);

    const isTaskReadOnly =
        taskEditRules.isReadOnly || isBlocking || props.isSaving;

    const getResourcesForTask = useCallback(
        (task: ResourcedTask): ResourcePickerResource[] => {
            return (
                task?.resourcedTasks
                    ?.filter((rt) => rt?.resource)
                    .map((rt) => {
                        const isAssignedToSelf =
                            !!rt?.resource?.userId &&
                            !!task?.mission?.userId &&
                            rt?.resource?.userId === task?.mission?.userId;

                        return {
                            resourceId: rt?.resource?.id || '',
                            userId: rt?.resource?.userId || null,
                            name: rt?.resource?.displayName || '??',
                            userHasMission: !!rt?.resource?.userMissionFYs.find(
                                (fy) =>
                                    fy.code?.toUpperCase() ===
                                    currentFinancialYearCode?.toUpperCase()
                            ),
                            displayName: rt?.resource?.displayName || '??',
                            resourceIsPrimary: rt?.resourceIsPrimary || false,
                            isAssignedToSelf: isAssignedToSelf,
                            task: {
                                ...rt,
                                id: rt.id,
                                missionId: rt.mission?.id || null,
                                resource: rt.resource,
                                resourcedFromTask: task,
                            },
                        };
                    }) || []
            );
        },
        [currentFinancialYearCode]
    );

    const onResourcePickerChange = (
        resources: ResourcePickerResource[]
    ): void => {
        const currentUserResources = getResourcesForTask(task as ResourcedTask);

        const updatedResources = resources.map((ur) => {
            const existing = currentUserResources.find(
                (r) =>
                    (r.userId && r.userId === ur.userId) ||
                    (r.resourceId && r.resourceId === ur.resourceId) ||
                    (r.displayName?.toUpperCase() ===
                        ur.displayName?.toUpperCase() &&
                        !r.userId &&
                        !ur.userId)
            );

            if (existing) {
                return {
                    ...existing,
                    resourceIsPrimary: ur.resourceIsPrimary,
                    requestAgain: ur.requestAgain,
                };
            } else {
                return ur;
            }
        });

        if (updatedResources.length === 0 && task) {
            setTask({ ...task, isPercentageCompleteFromResources: false });
        }

        setSelectedResources(updatedResources);
    };

    const onChecklistTasksChange = (items: ChecklistTask[]) => {
        setChecklistTasks(items);
        if (items.length === 0 && task) {
            setIsTaskChecklistEnabled(false);
            setTask({ ...task, isPercentageCompleteFromSubTasks: false });
        }
    };

    const handleUserInput = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void => {
        const element = ev.target as HTMLInputElement;
        const name = element.name;
        if (task) {
            setTask({ ...task, [name]: newValue });
            setUserHasMadeChanges(true);
        }
    };

    const onLinkedMeasureSelectionChanged = (
        linkedMeasureIds: string[]
    ): void => {
        if (task) {
            setTask({
                ...task,
                linkedMeasures: linkedMeasureIds.map((measureId) => {
                    return {
                        id:
                            task.linkedMeasures?.find(
                                (m) => m.measureId === measureId
                            )?.id || null,
                        taskId: task?.id || '',
                        measureId: measureId,
                        __typename: 'LinkedMeasureQL',
                    };
                }),
            });
        }
    };

    const taskCategoryChange = (taskCategoryId: string | null) => {
        if (task) {
            setTask({
                ...task,
                taskCategoryId: taskCategoryId,
            });
        }
    };

    const setupNewTask = useCallback(async () => {
        const mission = await client.query<
            GetMissionQuery,
            GetMissionQueryVariables
        >({
            query: GetMissionDocument,
            variables: {
                tenantId: currentTenantId || '',
                missionId: missionId || '',
            },
        });

        let lastSequence: number | undefined;

        if (newImpliedSpecifiedTaskId) {
            //  last implied sequence
            lastSequence = max(
                mission.data?.tasksSummary
                    ?.filter((t) => !t.isDuplicate)
                    .filter((t) => t.parentTaskId === newImpliedSpecifiedTaskId)
                    .map((t) => t.sequence)
            );
        } else {
            //  last specified sequence
            lastSequence = max(
                mission.data?.tasksSummary
                    ?.filter((t) => !t.isDuplicate)
                    .filter((t) => !t.parentTaskId)
                    .map((t) => t.sequence)
            );
        }

        const newSequence = lastSequence !== undefined ? lastSequence + 1 : 0;

        // Setup a new specified task.
        setTask({
            id: Guid.create().toString(),
            missionId: missionId || null,
            name: '',
            description: '',
            percentComplete: 0,
            parentTaskId: newImpliedSpecifiedTaskId || null,
            resourcedFromTaskId: null,
            start: null,
            due: null,
            done: null,
            review: null,
            utcAccepted: null,
            utcRejected: null,
            rejectedReason: null,
            resourceId: null,
            taskCategoryId: null,
            utcResourceRemoved: null,
            utcChangesPending: null,
            utcCancelled: null,
            utcPostponed: null,
            utcAtRisk: null,
            isPercentageCompleteFromResources: false,
            isPercentageCompleteFromSubTasks: false,
            linkedMeasures: [],
            sequence: newSequence,
            resource: null,
            isDuplicate: false,
            resourceIsPrimary: false,
            effortWeight: 1,
            effortResourceWeight: 1,
            costWeight: 1,
            version: '',
            parentTask: null,
            resourcedFromTask: null,
            resourcedTasks: [],
            subTasks: [],
            taskCategory: null,
            mission: null,
            tags: [],
        });
    }, [client, currentTenantId, missionId, newImpliedSpecifiedTaskId]);

    const loadTaskData = useCallback(
        (loadedTask: GetTaskQuery['task']) => {
            if (loadedTask) {
                setTask(loadedTask);
                setDismissedMerges([]);

                const subTasks = loadedTask.subTasks
                    .slice()
                    .sort(sorters.sequenceSorter)
                    .filter((t) => !t.isDuplicate);

                if (loadedTask.parentTaskId != null) {
                    const taskResources = getResourcesForTask(
                        loadedTask as ResourcedTask
                    );
                    setSelectedResources(taskResources);
                    setChecklistTasks(subTasks);
                    setIsTaskChecklistEnabled(subTasks.length > 0);
                } else if (!loadedTask.missionId) {
                    // This is a non mission task, setup for checklists
                    setIsTaskChecklistEnabled(true);
                    setSelectedResources([]);
                    setChecklistTasks(
                        subTasks.length
                            ? subTasks
                            : [
                                  {
                                      id: Guid.create().toString(),
                                      name: null,
                                      due: null,
                                      done: null,
                                      sequence: 0,
                                      isNew: true,
                                  },
                              ]
                    );
                } else {
                    setSelectedResources([]);
                    setChecklistTasks([]);
                }
            }
        },
        [getResourcesForTask]
    );

    useEffect(() => {
        (async () => {
            if (props.taskId) {
                const result = await loadTask({
                    variables: {
                        tenantId: currentTenantId || '',
                        id: props.taskId || '',
                    },
                });
                if (result.data) {
                    loadTaskData(result.data.task);
                }
            } else if (props.missionId) {
                // BUG: This runs after working on a mission, need a better solution
                setDismissedMerges([]);
                setSelectedResources([]);
                setTask(null);
                setupNewTask();
            }
        })();
    }, [
        props.taskId,
        props.missionId,
        currentTenantId,
        loadTask,
        loadTaskData,
        setupNewTask,
    ]);

    const linkedMeasureIds = task?.linkedMeasures
        ? task.linkedMeasures.map((l) => l.measureId)
        : null;

    const resourcedFromTask = task?.resourcedFromTask;

    const duplicateTasks =
        task?.subTasks?.filter((t: DuplicateTask) => t.isDuplicate) || [];

    const handleTaskUnmerged = async () => {
        if (refetch) {
            const result = await refetch();
            setTask(result.data.task);
        }
    };

    const allowMerge = (fieldName: string): boolean =>
        enableMerge &&
        !!task?.resourcedFromTask &&
        !!mergeFields?.some((mf) => mf === fieldName);

    const isDismissed = (fieldName: string): boolean =>
        dismissedMerges?.some((dm) => dm === fieldName);

    const handleDismiss = (fieldName: string) => {
        setDismissedMerges((currentValue) => [
            ...currentValue.filter((v) => v !== fieldName),
            fieldName,
        ]);
    };

    const handleAcceptMerge = (fieldName: string) => {
        if (task && resourcedFromTask) {
            const field = fieldName as keyof ResourcedTask;
            const resourceFromField = fieldName as keyof ResourcedFromTask;

            if (fieldName === 'status') {
                setTask({
                    ...task,
                    utcPostponed: resourcedFromTask?.utcPostponed || null,
                    utcCancelled: resourcedFromTask?.utcCancelled || null,
                    utcAtRisk: resourcedFromTask?.utcAtRisk || null,
                });
            } else if (field && resourceFromField) {
                setTask({
                    ...task,
                    [field]:
                        resourcedFromTask[
                            resourceFromField as keyof ResourcedFromTask
                        ],
                });
            } else {
                throw `Merge not supported for the field '${fieldName}'.`;
            }
        }
    };

    const alreadyResourced =
        resourcedFromTask?.resourcedTasks
            ?.filter(
                (t) =>
                    t.resource?.userId &&
                    t.resource?.userId !== task?.mission?.userId
            )
            .map((t) => {
                return {
                    userId: t.resource?.userId,
                };
            }) || [];

    // Do not allow the requested to be resourced.
    if (
        resourcedFromTask?.mission?.userId &&
        !alreadyResourced.some(
            (r) => r.userId === resourcedFromTask?.mission?.userId
        )
    ) {
        alreadyResourced.push({ userId: resourcedFromTask.mission.userId });
    }

    const mission = missionData?.mission;

    let currentDivisionId =
        mission?.team?.division?.id || task?.mission?.team?.id;

    if (currentTeamCode && mission) {
        const thisTeam = mission.leaderOfTeams?.find(
            (lm) => lm.code?.toLowerCase() === currentTeamCode?.toLowerCase()
        );
        if (thisTeam?.division) {
            currentDivisionId = thisTeam.division.id;
        }
    }

    const financialYear =
        mission?.team?.division?.financialYear ||
        task?.mission?.team?.division?.financialYear;

    const initialPickerStartDate = dayjs
        .max(dayjs(financialYear?.startDate), dayjs())
        ?.toDate();

    const initialPickerDueDate = dayjs
        .max(dayjs(financialYear?.endDate), dayjs())
        ?.toDate();

    // Disable the checklist toggle if there are any tasks with names
    const taskTypeAllowChange =
        allowResources &&
        selectedResources.length === 0 &&
        !checklistTasks.some((t) => t.name && !t.isDeleted);

    const classNames = mergeStyleSets({
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        fieldRow: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            selectors: {
                '> div': {
                    flexGrow: 1,
                    flexBasis: 1,
                },
            },
        },
    });

    const form = (
        <div className={classNames.formContainer}>
            <InputShimmer isDataLoaded={!isLoading} inputHeight={60}>
                <div className={classNames.fieldRow}>
                    <div>
                        <TextField
                            label="Name"
                            required
                            multiline
                            autoAdjustHeight
                            name="name"
                            value={task?.name || ''}
                            errorMessage={formErrorMessages.name}
                            onChange={handleUserInput}
                            disabled={
                                taskEditRules.isNameReadOnly || isTaskReadOnly
                            }
                        />
                    </div>

                    {enableMerge && (
                        <div>
                            {allowMerge('name') && (
                                <MergeControlContainer
                                    fieldName="name"
                                    resourcedFromTask={resourcedFromTask}
                                    onDismiss={handleDismiss}
                                    onAccept={handleAcceptMerge}
                                    allowAccept={!taskEditRules.isNameReadOnly}
                                    isDismissed={isDismissed('name')}
                                >
                                    <TextField
                                        multiline
                                        autoAdjustHeight
                                        value={resourcedFromTask?.name || ''}
                                        readOnly={true}
                                        disabled={true}
                                    />
                                </MergeControlContainer>
                            )}
                        </div>
                    )}
                </div>
            </InputShimmer>
            {(isLoading || !isImpliedTask) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    <div className={classNames.fieldRow}>
                        <div>
                            {currentDivisionId && (
                                <TaskCategoryPicker
                                    label="Category"
                                    placeholder="No category selected"
                                    divisionId={currentDivisionId}
                                    selectedTaskCategoryId={
                                        task?.taskCategoryId
                                    }
                                    onChange={taskCategoryChange}
                                    disabled={
                                        isTaskReadOnly || task?.isDuplicate
                                    }
                                />
                            )}
                        </div>
                        {enableMerge && <div></div>}
                    </div>
                </InputShimmer>
            )}

            {(isLoading || isImpliedTask || enableMerge) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    <div className={classNames.fieldRow}>
                        {task && (
                            <div>
                                <DatePicker
                                    label="Start"
                                    placeholder="Start date"
                                    initialPickerDate={initialPickerStartDate}
                                    strings={DatePickerStrings}
                                    ariaLabel="Select a start date"
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    value={
                                        task.start
                                            ? dayjs(task.start).toDate()
                                            : undefined
                                    }
                                    allowTextInput={true}
                                    onSelectDate={(
                                        date: Date | null | undefined
                                    ): void => {
                                        setTask({
                                            ...task,
                                            start: date
                                                ? dayjs(date).format(
                                                      'YYYY-MM-DD'
                                                  )
                                                : null,
                                        });
                                    }}
                                    isRequired={!!props.taskId && isImpliedTask} // Only set as required on old tasks
                                    disabled={
                                        taskEditRules.isStartDateReadOnly ||
                                        isTaskReadOnly
                                    }
                                />
                            </div>
                        )}
                        {enableMerge && (
                            <div>
                                {allowMerge('start') && (
                                    <MergeControlContainer
                                        fieldName="start"
                                        allowAccept={
                                            !taskEditRules.isStartDateReadOnly
                                        }
                                        isDismissed={isDismissed('start')}
                                        resourcedFromTask={resourcedFromTask}
                                        onDismiss={handleDismiss}
                                        onAccept={handleAcceptMerge}
                                    >
                                        <DatePicker
                                            value={
                                                resourcedFromTask?.start
                                                    ? dayjs(
                                                          resourcedFromTask.start
                                                      ).toDate()
                                                    : undefined
                                            }
                                            disabled={true}
                                        />
                                    </MergeControlContainer>
                                )}
                            </div>
                        )}
                    </div>
                </InputShimmer>
            )}
            {(isLoading || isImpliedTask || enableMerge) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    <div className={classNames.fieldRow}>
                        {task && (
                            <div>
                                <DatePicker
                                    label="Due"
                                    placeholder="Due date"
                                    initialPickerDate={initialPickerDueDate}
                                    strings={{
                                        ...DatePickerStrings,
                                        isOutOfBoundsErrorMessage:
                                            'The due date must be after the start date.',
                                    }}
                                    ariaLabel="Select a due date"
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    value={
                                        task.due
                                            ? dayjs(task.due).toDate()
                                            : undefined
                                    }
                                    allowTextInput={true}
                                    onSelectDate={(
                                        date: Date | null | undefined
                                    ): void => {
                                        setTask({
                                            ...task,
                                            due: date
                                                ? dayjs(date).format(
                                                      'YYYY-MM-DD'
                                                  )
                                                : null,
                                        });
                                    }}
                                    minDate={
                                        task.start
                                            ? dayjs(task.start).toDate()
                                            : undefined
                                    }
                                    isRequired={!!props.taskId && isImpliedTask} // Only set as required on old tasks
                                    disabled={
                                        taskEditRules.isDueDateReadOnly ||
                                        isTaskReadOnly
                                    }
                                />
                            </div>
                        )}
                        {enableMerge && (
                            <div>
                                {allowMerge('due') && (
                                    <MergeControlContainer
                                        fieldName="due"
                                        allowAccept={
                                            !taskEditRules.isDueDateReadOnly
                                        }
                                        isDismissed={isDismissed('due')}
                                        resourcedFromTask={resourcedFromTask}
                                        onDismiss={handleDismiss}
                                        onAccept={handleAcceptMerge}
                                    >
                                        <DatePicker
                                            value={
                                                task?.resourcedFromTask?.due
                                                    ? dayjs(
                                                          task.resourcedFromTask
                                                              .due
                                                      ).toDate()
                                                    : undefined
                                            }
                                            disabled={true}
                                        />
                                    </MergeControlContainer>
                                )}
                            </div>
                        )}
                    </div>
                </InputShimmer>
            )}
            {(isLoading || isImpliedTask || enableMerge) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    <div className={classNames.fieldRow}>
                        {task && (
                            <div>
                                <DatePicker
                                    label="Done"
                                    placeholder="Done date"
                                    strings={{
                                        ...DatePickerStrings,
                                        isOutOfBoundsErrorMessage:
                                            'The done date must be after the start date.',
                                    }}
                                    ariaLabel="Select a done date"
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    value={
                                        task.done
                                            ? dayjs(task.done).toDate()
                                            : undefined
                                    }
                                    allowTextInput={true}
                                    onSelectDate={(
                                        date: Date | null | undefined
                                    ): void => {
                                        setTask({
                                            ...task,
                                            done: date
                                                ? dayjs(date).format(
                                                      'YYYY-MM-DD'
                                                  )
                                                : null,
                                        });
                                    }}
                                    minDate={
                                        task.start
                                            ? dayjs(task.start).toDate()
                                            : undefined
                                    }
                                    disabled={
                                        taskEditRules.isDoneDateReadOnly ||
                                        isTaskReadOnly
                                    }
                                />
                            </div>
                        )}

                        {enableMerge && (
                            <div>
                                {allowMerge('done') && (
                                    <MergeControlContainer
                                        fieldName="done"
                                        allowAccept={
                                            !taskEditRules.isDoneDateReadOnly
                                        }
                                        isDismissed={isDismissed('done')}
                                        resourcedFromTask={resourcedFromTask}
                                        onDismiss={handleDismiss}
                                        onAccept={handleAcceptMerge}
                                    >
                                        <DatePicker
                                            value={
                                                task?.resourcedFromTask?.done
                                                    ? dayjs(
                                                          task.resourcedFromTask
                                                              .done
                                                      ).toDate()
                                                    : undefined
                                            }
                                            disabled={true}
                                        />
                                    </MergeControlContainer>
                                )}
                            </div>
                        )}
                    </div>
                </InputShimmer>
            )}

            {(isLoading || isImpliedTask || enableMerge) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    <div className={classNames.fieldRow}>
                        {task && (
                            <div>
                                <TaskStatusDropDown
                                    task={task}
                                    label="Status"
                                    infoContent={
                                        !isImpliedTask &&
                                        !task.parentTaskId &&
                                        task.missionId &&
                                        taskEditRules.isStatusReadOnly
                                            ? 'The status of this specified task is based on the dates of any implied tasks.'
                                            : undefined
                                    }
                                    isReadOnly={
                                        taskEditRules.isStatusReadOnly ||
                                        isTaskReadOnly
                                    }
                                    onChange={(taskUpdates) =>
                                        setTask({
                                            ...task,
                                            ...taskUpdates,
                                        })
                                    }
                                />
                            </div>
                        )}
                        {enableMerge && (
                            <div>
                                {allowMerge('status') && (
                                    <MergeControlContainer
                                        fieldName="status"
                                        allowAccept={
                                            !taskEditRules.isStatusReadOnly
                                        }
                                        isDismissed={isDismissed('status')}
                                        resourcedFromTask={resourcedFromTask}
                                        onDismiss={handleDismiss}
                                        onAccept={handleAcceptMerge}
                                    >
                                        {!!resourcedFromTask && (
                                            <TaskStatusDropDown
                                                task={resourcedFromTask}
                                                isReadOnly={true}
                                            />
                                        )}
                                    </MergeControlContainer>
                                )}
                            </div>
                        )}
                    </div>
                </InputShimmer>
            )}

            {(isLoading || !isImpliedTask) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    {/* Show if its a task on a mission or a brand new task */}
                    {(task?.missionId || (!task?.id && props.missionId)) && (
                        <div className={classNames.fieldRow}>
                            <div>
                                <Label>
                                    {t(
                                        'specified-task.linked-measures-of-success'
                                    )}
                                </Label>
                                <LinkedMeasuresSelector
                                    missionId={
                                        task?.missionId || props.missionId
                                    }
                                    linkedMeasureIds={linkedMeasureIds || []}
                                    onSelectionChanged={
                                        onLinkedMeasureSelectionChanged
                                    }
                                    disabled={
                                        isTaskReadOnly || task?.isDuplicate
                                    }
                                />
                            </div>
                            {enableMerge && <div></div>}
                        </div>
                    )}
                </InputShimmer>
            )}

            {(isLoading || isImpliedTask) && (
                <InputShimmer isDataLoaded={!isLoading}>
                    <div className={classNames.fieldRow}>
                        <div>
                            <Stack>
                                <Label>Percentage complete</Label>
                                <Stack horizontal tokens={{ childrenGap: 16 }}>
                                    <Stack.Item>
                                        {task && (
                                            <PercentageCompleteInput
                                                disabled={
                                                    taskEditRules.isPercentageReadOnly ||
                                                    task.isPercentageCompleteFromSubTasks ||
                                                    task.isPercentageCompleteFromResources ||
                                                    task.isDuplicate
                                                }
                                                value={
                                                    task.percentComplete || 0
                                                }
                                                onChange={(
                                                    newValue: number
                                                ): void => {
                                                    const percentComplete =
                                                        Number(newValue);

                                                    const isDone =
                                                        percentComplete >=
                                                        0.9999;

                                                    setTask({
                                                        ...task,
                                                        done:
                                                            isDone &&
                                                            !taskEditRules.isDoneDateReadOnly &&
                                                            !task.done
                                                                ? dayjs().format(
                                                                      'YYYY-MM-DD'
                                                                  )
                                                                : task.done,
                                                        percentComplete:
                                                            percentComplete,
                                                    });
                                                }}
                                            />
                                        )}
                                    </Stack.Item>
                                    <Stack.Item>
                                        {task && !task.isDuplicate && (
                                            <RollupToggle
                                                disabled={
                                                    isTaskReadOnly ||
                                                    task.isDuplicate ||
                                                    // Don't allow it to be switch on if they don't have resources in resource mode.
                                                    (selectedResources.length ===
                                                        0 &&
                                                        !isTaskChecklistEnabled &&
                                                        !task.isPercentageCompleteFromResources &&
                                                        !task.isPercentageCompleteFromSubTasks)
                                                }
                                                isRollUp={
                                                    task.isPercentageCompleteFromResources ||
                                                    task.isPercentageCompleteFromSubTasks
                                                }
                                                isRollUpChanged={(isRollup) => {
                                                    setTask({
                                                        ...task,
                                                        isPercentageCompleteFromSubTasks:
                                                            isTaskChecklistEnabled
                                                                ? isRollup
                                                                : false,
                                                        isPercentageCompleteFromResources:
                                                            !isTaskChecklistEnabled
                                                                ? isRollup
                                                                : false,
                                                    });
                                                }}
                                                infoTooltipContent={
                                                    isTaskChecklistEnabled
                                                        ? 'Automatically calculates the percentage complete based on the completion of checklist items.'
                                                        : 'Automatically calculates the percentage complete based on the progress of resources.'
                                                }
                                            />
                                        )}
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        </div>
                        {enableMerge && <div></div>}
                    </div>
                </InputShimmer>
            )}

            {(isLoading || (isImpliedTask && task?.parentTaskId)) && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 32,
                    }}
                >
                    <InputShimmer isDataLoaded={!isLoading}>
                        <div>
                            <InfoLabel
                                infoContent={
                                    <div>
                                        The relative amount of work, time, and
                                        resources required to complete this
                                        task. This rating helps to weight the
                                        contribution to the overall specified
                                        task&apos;s progress.
                                    </div>
                                }
                            >
                                Effort
                            </InfoLabel>

                            <EffortWeightingRating
                                value={task?.effortWeight || 1}
                                onChange={(newValue: number) => {
                                    if (task) {
                                        setTask({
                                            ...task,
                                            effortWeight: newValue,
                                        });
                                    }
                                }}
                                showRatingText
                            />
                        </div>
                    </InputShimmer>

                    <InputShimmer isDataLoaded={!isLoading}>
                        <div>
                            <InfoLabel
                                infoContent={
                                    <div>
                                        The relative financial investment
                                        required to complete this task.
                                    </div>
                                }
                            >
                                Cost
                            </InfoLabel>

                            <CostWeightingRating
                                value={task?.costWeight || 1}
                                onChange={(newValue: number) => {
                                    if (task) {
                                        setTask({
                                            ...task,
                                            costWeight: newValue,
                                        });
                                    }
                                }}
                                showRatingText
                            />
                        </div>
                    </InputShimmer>
                </div>
            )}

            {isImpliedTask && (
                <Stack.Item>
                    <Separator styles={{ root: { padding: 0 } }} />
                </Stack.Item>
            )}

            <InputShimmer isDataLoaded={!isLoading && !props.isSaving}>
                <Stack>
                    {task && isImpliedTask && !task.isDuplicate && (
                        <TaskTypeToggle
                            taskType={
                                isTaskChecklistEnabled
                                    ? 'checklist'
                                    : 'resourced'
                            }
                            disabled={isTaskReadOnly}
                            allowChange={taskTypeAllowChange}
                            hideSwitch={!allowResources}
                            onTaskTypeOptionChanged={(taskType) => {
                                setIsTaskChecklistEnabled(
                                    taskType === 'checklist'
                                );
                                if (
                                    taskType === 'checklist' &&
                                    checklistTasks.length === 0
                                ) {
                                    checklistTasks.push({
                                        id: Guid.create().toString(),
                                        name: null,
                                        due: null,
                                        done: null,
                                        sequence: 0,
                                        isNew: true,
                                    });
                                }
                            }}
                        />
                    )}
                    <React.Fragment>
                        {!!task && isImpliedTask && isTaskChecklistEnabled && (
                            <TaskChecklist
                                impliedTask={task}
                                readonly={isTaskReadOnly}
                                items={checklistTasks}
                                onChange={onChecklistTasksChange}
                            />
                        )}
                        {allowResources &&
                            isImpliedTask &&
                            task &&
                            !task.isDuplicate &&
                            !isTaskChecklistEnabled && (
                                <ResourcePicker
                                    teamCode={currentTeamCode}
                                    selectedResources={selectedResources}
                                    alreadyResourced={alreadyResourced}
                                    onChange={onResourcePickerChange}
                                    disabled={
                                        isTaskReadOnly ||
                                        task.isDuplicate ||
                                        isTaskChecklistEnabled
                                    }
                                />
                            )}
                    </React.Fragment>
                </Stack>
            </InputShimmer>
            {currentTenantId &&
                props.taskId &&
                task?.mission?.id &&
                (duplicateTasks || []).length > 0 && (
                    <div>
                        <Label>Merged Tasks</Label>
                        <DuplicateTaskList
                            tenantId={currentTenantId}
                            missionId={task?.mission?.id}
                            missionAccess={task?.mission?.rights}
                            onTaskClick={props.onSwapTask}
                            onTaskUnmerged={handleTaskUnmerged}
                            duplicateTasks={duplicateTasks || []}
                        />
                    </div>
                )}
        </div>
    );

    const handleActionClick = async (actionName: string) => {
        const action = actions.find((a) => a.name === actionName);
        if (!action) {
            return;
        }
        if (action.updateInput) {
            if (task) {
                setTask((t) => {
                    if (action.updateInput && t) {
                        return action.updateInput(t);
                    } else {
                        return t;
                    }
                });
            }
        } else if (action.executeAction) {
            await action?.executeAction();
            if (action.isDestructive) {
                props.onDismiss();
            } else if (action.isRefetchRequired) {
                const result = await refetch();
                if (result.data) {
                    loadTaskData(result.data.task);
                }
            }
        }
    };

    return (
        <Stack tokens={{ childrenGap: 8 }}>
            {missionAccess?.write && !isLoading && task && hasWarning && (
                <div style={{ marginTop: 8 }}>
                    <TaskWarningPanel
                        warning={warning}
                        onActionClick={handleActionClick}
                    />
                </div>
            )}

            {form}
        </Stack>
    );
}
