import { useStateContext } from '../services/contextProvider';

export const useIsTaskImplied = (
    task:
        | {
              missionId: string | null;
              parentTaskId: string | null;
              resource: {
                  userId: string | null;
                  userMissionFYs: {
                      code: string | null;
                  }[];
              } | null;
              parentTask: {
                  parentTaskId: string | null;
              } | null;
              resourcedFromTask: {
                  mission: {
                      userId: string | null;
                  } | null;
              } | null;
              isDuplicate: boolean;
          }
        | null
        | undefined
): boolean => {
    let isImpliedTask: boolean;

    const { currentFinancialYearCode } = useStateContext();

    if (task?.isDuplicate) {
        // If this is a duplicate of as specified or implied?
        isImpliedTask = task?.parentTask?.parentTaskId != null;
    } else if (task?.missionId === null) {
        // This is a resourced task, treat as implied.
        isImpliedTask = true;
    } else if (
        task?.resource &&
        !task?.resource?.userMissionFYs.find(
            (fy) =>
                fy.code?.toUpperCase() ===
                currentFinancialYearCode?.toUpperCase()
        )
    ) {
        // This is a task that has been resourced but the user hasn't got a mission
        isImpliedTask = true;
    } else if (!task?.parentTaskId) {
        isImpliedTask = false;
    } else {
        isImpliedTask = true;
    }

    return isImpliedTask;
};
