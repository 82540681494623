import React, { useState } from 'react';
import {
    IColumn,
    Link,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Stack,
    Text,
} from '@fluentui/react';
import { GetAllTasksQuery, GetMissionTasksQuery } from '../data/types';
import { useTaskFilters } from '../hooks/useTaskFilters';
import { TaskFilters } from '../scenes/MissionBuilder/components/TaskFilterBar';
import {
    Access,
    ExtractQueryArrayType,
    TaskStatusNames,
    taskStatusUtil,
} from '../data/extendedTypes';
import { ImpliedTaskListDateColumn } from './ImpliedTaskListDateColumn';
import { useFormatters } from '../hooks/useFormatters';
import TaskProgressIndicator from './TaskProgressIndicator';
import { taskListExport } from '../services/exporter';
import { useStateContext } from '../services/contextProvider';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { TaskCategoryLabel } from './TaskCategoryLabel';
import { FlatList } from './FlatList';
import {
    RatingTexts,
    EffortWeightingRating,
    CostWeightingRating,
} from './WeightingRating';
import { useComments } from '../hooks/useComments';

export type ImpliedTaskType =
    | ExtractQueryArrayType<GetMissionTasksQuery, ['tasks']>
    | ExtractQueryArrayType<GetAllTasksQuery, ['tasks']>;

type TaskFlatListItem = ImpliedTaskType & {
    mission?: {
        title: string | null;
        owner: string | null;
        team: {
            id: string | null;
            code: string | null;
            name: string | null;
        } | null;
        leaderOfTeams: {
            id: string | null;
            code: string | null;
            name: string | null;
        }[];
        rights: Access;
    } | null;
};

type ResultType = TaskFlatListItem & {
    impliedTaskId: string | null;
    specifiedTaskId: string | null;
    specifiedTaskName: string | null;
    specifiedTaskPercentComplete: number;
    sortSequence: number;
    displaySequence: number;
    resources: string;
    lastCommentText: string | null;
    status: TaskStatusNames | null;
    missionName?: string | null;
    team?: string | null;
    teamCode?: string | null;
    teamName?: string | null;
    categoryId?: string | null;
    categoryName?: string | null;
    categoryColourHex?: string | null;
    isLeader: boolean;
    effortRating: string | null;
    costRating: string | null;
    classification: string | null;
    linkedMeasureCount: number;
};

export type TaskFlatListProps = {
    teamId?: string | undefined;
    missionId?: string | undefined;
    shimmerLines?: number | undefined;
    missionOwner?: string | null | undefined;
    onTaskClick: (taskId: string) => void;
    onTaskEditClick?: (taskId: string) => void;
    onCommentsClick?: (taskId: string) => void;
    onAttachmentsClick?: (taskId: string) => void;
    onRejectedTaskClick?: (rejectedTaskId: string) => void;
    tasksLoading: boolean;
    tasks: TaskFlatListItem[] | null | undefined;
    filters?: TaskFilters;
    setForDateTime?: (forDateTime: string | null) => void;
    defaultColumns?: (keyof ResultType)[];
    allowExport?: boolean;
    initialSort?: string[];
    initialSortOrders?: (boolean | 'asc' | 'desc')[];
};

export function TaskFlatList(props: TaskFlatListProps): JSX.Element {
    const { currentTenantId, currentFinancialYearCode, configuration } =
        useStateContext();
    const formatters = useFormatters();

    const { renderCommentText } = useComments();

    const specifiedTasks = props.tasks?.filter((t) => t.parentTaskId === null);

    const tasks: ResultType[] =
        props.tasks
            ?.filter(
                (t) =>
                    !t.isDuplicate &&
                    t.parentTaskId !== null &&
                    specifiedTasks?.some((t2) => t2.id === t.parentTaskId)
            )
            .map((t) => {
                const st = specifiedTasks?.find(
                    (t2) => t2.id === t.parentTaskId
                );

                const classification =
                    st?.tags.find(
                        (t) =>
                            t.name === 'Main Effort' ||
                            t.name === 'Enabling Activity' ||
                            t.name === 'Flanking Activity'
                    )?.name || null;

                return {
                    ...t,
                    impliedTaskId: t.id,
                    specifiedTaskId: st?.id || null,
                    specifiedTaskName: st?.name || null,
                    specifiedTaskPercentComplete: st?.percentComplete || 0,
                    sortSequence: (st?.sequence || 0) * 1000 + t.sequence,
                    displaySequence: 0, // Set later
                    resources: t.resourcedTasks
                        .map((t) => t.resource?.displayName)
                        .join(', '),
                    lastCommentText: t.lastComment?.text || null,
                    status: taskStatusUtil.GetTaskStatus(
                        t,
                        currentFinancialYearCode
                    ),
                    team: `${t.mission?.team?.code} (${t.mission?.team?.name})`,
                    teamName: t.mission?.team?.name,
                    teamCode: t.mission?.team?.code,
                    missionName: t.mission
                        ? [t.mission.owner, t.mission.title].join(' - ')
                        : null,
                    categoryId: st?.taskCategory?.id,
                    categoryName: st?.taskCategory?.name,
                    categoryColourHex: st?.taskCategory?.colourHex,
                    isLeader:
                        t.mission?.leaderOfTeams?.some(
                            (te) => te.id === props.teamId
                        ) || false,
                    effortRating:
                        t.effortWeight > 0 &&
                        t.effortWeight <= RatingTexts.length
                            ? RatingTexts[t.effortWeight - 1]
                            : null,
                    costRating:
                        t.costWeight > 0 && t.costWeight <= RatingTexts.length
                            ? RatingTexts[t.costWeight - 1]
                            : null,
                    classification: classification,
                    linkedMeasureCount: st?.linkedMeasures.length || 0,
                };
            }) || [];

    const [selectedColumnNames, setSelectedColumnNames] = useState(
        props.defaultColumns
    );

    const handleColumnsChanged = (selectedColumns: (keyof ResultType)[]) => {
        setSelectedColumnNames(selectedColumns);
    };

    const generateUniqueKey = (item: ResultType) => {
        const uniqColumns = selectedColumnNames?.slice() || ['impliedTaskId'];

        // Always show all specified tasks.
        if (!uniqColumns.some((c) => c === 'specifiedTaskId')) {
            uniqColumns.push('specifiedTaskId');
        }

        return (
            uniqColumns
                .filter((column) => column !== 'displaySequence')
                .map((column) => item[column as keyof ResultType])
                .join('|') || item.id
        );
    };

    const defaultOrder = [
        ...(props.initialSort ?? []),
        'missionName',
        'sortSequence',
    ];

    // Filter out unique in case they are just looking at specified fields, but not if they've selected the main implied columns.
    const uniqueItems = selectedColumnNames?.some(
        (c) =>
            c === 'name' ||
            c === 'impliedTaskId' ||
            c === 'status' ||
            c === 'start' ||
            c === 'due' ||
            c === 'done' ||
            c === 'percentComplete' ||
            c === 'resources' ||
            c === 'lastCommentText'
    )
        ? tasks || []
        : uniqBy(tasks || [], generateUniqueKey);

    orderBy(uniqueItems, defaultOrder, props.initialSortOrders).forEach(
        (t, i) => {
            t.displaySequence = i + 1;
        }
    );

    const { filteredImpliedTasks: filteredTasks } = useTaskFilters(
        props.filters,
        uniqueItems
    );

    type TaskColumn = {
        sortFieldName?: keyof ResultType;
        fieldName: keyof ResultType;
        name: string;
        isString: boolean;
    } & Partial<IColumn>;

    const availableColumns: TaskColumn[] = [
        {
            fieldName: 'displaySequence',
            name: '#',
            isString: false,
            minWidth: 32,
            maxWidth: 32,
        },
        {
            fieldName: 'impliedTaskId',
            name: 'ID',
            isString: false,
            minWidth: 220,
            maxWidth: 220,
        },
        {
            fieldName: 'specifiedTaskName',
            name: 'Specified Task',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        },
        {
            fieldName: 'specifiedTaskPercentComplete',
            name: 'Specified Task Percent Complete',
            isString: false,
        },
        {
            fieldName: 'name',
            name: 'Implied Task',
            isString: true,
            minWidth: 200,
            isRowHeader: true,
        },
        {
            fieldName: 'resources',
            name: 'Resources',
            isString: true,
            minWidth: 200,
        },
        {
            fieldName: 'effortRating',
            sortFieldName: 'effortWeight',
            name: 'Effort',
            isString: false,
        },
        {
            fieldName: 'costRating',
            sortFieldName: 'costWeight',
            name: 'Cost',
            isString: false,
        },
        {
            fieldName: 'start',
            name: 'Start',
            isString: false,
            minWidth: 80,
            maxWidth: 80,
        },
        {
            fieldName: 'due',
            name: 'Due',
            isString: false,
            minWidth: 80,
            maxWidth: 80,
        },
        {
            fieldName: 'done',
            name: 'Done',
            isString: false,
            minWidth: 80,
            maxWidth: 80,
        },
        {
            fieldName: 'percentComplete',
            name: 'Percent Complete',
            isString: false,
        },
        {
            fieldName: 'status',
            name: 'Status',
            isString: true,
        },
        {
            fieldName: 'lastCommentText',
            name: 'Latest Comment',
            isString: true,
        },
        {
            fieldName: 'categoryName',
            name: 'Category',
            isString: true,
        },
        {
            fieldName: 'classification',
            name: 'Classification',
            isString: true,
        },
        {
            fieldName: 'linkedMeasureCount',
            name: 'Linked Measures',
            isString: false,
        },
    ];

    if (!props.teamId && !props.missionId) {
        availableColumns.splice(2, 0, {
            fieldName: 'teamName',
            sortFieldName: 'teamName',
            name: 'Team Name',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        });

        availableColumns.splice(2, 0, {
            fieldName: 'teamCode',
            sortFieldName: 'teamCode',
            name: 'Team Code',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        });

        availableColumns.splice(2, 0, {
            fieldName: 'team',
            sortFieldName: 'teamCode',
            name: 'Team',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        });
    }

    if (!props.missionId) {
        availableColumns.splice(2, 0, {
            fieldName: 'missionName',
            name: 'Mission',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        });
    }

    const renderItemColumn = (
        task?: ResultType,
        _index?: number,
        column?: IColumn
    ) => {
        if (!task) {
            return null;
        }

        let fieldContent: JSX.Element;

        switch (column?.fieldName) {
            case 'start':
            case 'due':
            case 'done':
                return (
                    <ImpliedTaskListDateColumn
                        task={task}
                        dateType={column?.fieldName}
                        onTaskEditClick={() => {
                            if (props.onTaskEditClick && task.id) {
                                props.onTaskEditClick(task.id);
                            }
                        }}
                        missionAccess={task.mission?.rights}
                    />
                );
            case 'specifiedTaskName':
                fieldContent = (
                    <TaskNameBlock
                        id={task.parentTaskId || ''}
                        name={task.specifiedTaskName || ''}
                        onClick={props.onTaskClick}
                    />
                );
                break;
            case 'name':
                fieldContent = (
                    <TaskNameBlock
                        id={task.id || ''}
                        name={task.name || ''}
                        onClick={props.onTaskClick}
                    />
                );
                break;
            case 'percentComplete':
            case 'specifiedTaskPercentComplete': {
                const percent = task[
                    column?.fieldName as keyof ImpliedTaskType
                ] as number;
                fieldContent = (
                    <Text variant="small">
                        {formatters.formatTaskPercentage(percent)}
                    </Text>
                );
                break;
            }
            case 'status':
                fieldContent = (
                    <TaskProgressIndicator
                        task={task}
                        showStatus={true}
                        hidePercentage
                        compact
                    />
                );
                break;
            case 'categoryName':
                fieldContent = (
                    <TaskCategoryLabel
                        name={task.categoryName}
                        colourHex={task.categoryColourHex}
                    />
                );
                break;

            case 'effortRating':
                fieldContent = (
                    <EffortWeightingRating value={task.effortWeight} readOnly />
                );
                break;

            case 'costRating':
                fieldContent = (
                    <CostWeightingRating value={task.costWeight} readOnly />
                );
                break;

            case 'linkedMeasureCount':
                fieldContent = (
                    <MessageBar
                        styles={{
                            text: {
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingRight: 16,
                            },
                        }}
                        messageBarType={
                            task.linkedMeasureCount > 0
                                ? MessageBarType.success
                                : MessageBarType.severeWarning
                        }
                    >
                        <Text>{task.linkedMeasureCount}</Text>
                    </MessageBar>
                );
                break;

            case 'lastCommentText':
                fieldContent = task.lastComment ? (
                    <Text variant="small">
                        {renderCommentText(task.lastComment.text)}
                    </Text>
                ) : (
                    <span />
                );
                break;

            default: {
                const text = task[
                    column?.fieldName as keyof ImpliedTaskType
                ] as string;
                fieldContent = <Text variant="small">{text}</Text>;
            }
        }

        return (
            <Stack verticalFill verticalAlign="center">
                <Stack.Item>{fieldContent}</Stack.Item>
            </Stack>
        );
    };

    const [isExporting, setIsExporting] = React.useState(false);

    const handleExportClick = async (
        columns: IColumn[],
        sortedItems: ResultType[]
    ) => {
        try {
            setIsExporting(true);
            await taskListExport(
                configuration,
                props.missionId,
                currentTenantId,
                props.missionOwner,
                columns.map((c) => {
                    return {
                        name: c.name,
                        fieldName: c.fieldName,
                        minWidth: c.minWidth,
                        maxWidth: c.maxWidth,
                    };
                }),
                sortedItems
            );
        } catch (e) {
            console.log(e);
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <FlatList<ResultType>
            {...props}
            listTypeName="Tasks"
            items={filteredTasks || []}
            onRenderItemColumn={renderItemColumn}
            isExporting={isExporting}
            onExportClick={handleExportClick}
            loading={props.tasksLoading}
            availableColumns={availableColumns}
            onColumnsChanged={handleColumnsChanged}
        />
    );
}

function TaskNameBlock(props: {
    id: string;
    name: string;
    onClick: (taskId: string) => void;
}) {
    const classNames = mergeStyleSets({
        text: {
            whiteSpace: 'pre-line',
            minHeight: '1em',
            verticalAlign: 'middle',
        },
    });

    return (
        <Link onClick={() => props.onClick(props.id)}>
            <Text
                variant="smallPlus"
                className={classNames.text}
                title={props?.name || ''}
                block
            >
                {props?.name}
            </Text>
            {!props?.name?.trim() && (
                <Text
                    variant="small"
                    styles={{ root: { fontStyle: 'italic' } }}
                    block
                >
                    Enter a task name
                </Text>
            )}
        </Link>
    );
}
