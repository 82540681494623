import React from 'react';
import { Text, Link, IRawStyle, mergeStyleSets } from '@fluentui/react';

import MeasureArrow from '../../../components/MeasureArrow';
import { useThemes } from '../../../hooks/useThemes';

export type MeasureTileProps = {
    id: string | null;
    name: string | null;
    values?:
        | {
              decimalValue: number | null;
              stringValue: string | null;
              dateValue: string | null;
              formatStr: string | null;
              seriesType: {
                  name: string | null;
              } | null;
          }[]
        | null;
    status: number;
    isStatusLimited: boolean;
    asOfLabel: string | null;
    arrowColour: 'GREEN' | 'RED' | 'YELLOW' | 'NONE';
    arrowDirection: 'UP' | 'DOWN' | 'SAME' | 'NONE';
    latestComment?: {
        timeDisplay: string;
        content: string;
        authorName: string;
    } | null;
    onClick: () => void;
};

export function MeasureTile(props: MeasureTileProps): JSX.Element {
    let statusColourBg: string;

    const { currentTheme } = useThemes();

    switch (props.arrowColour) {
        case 'GREEN':
            statusColourBg = '#dff6dd';
            break;
        case 'RED':
            statusColourBg = '#fde7e9';
            break;
        case 'YELLOW':
            statusColourBg = '#fff4ce';
            break;
        default:
            statusColourBg = currentTheme.palette.neutralLighter;
    }

    let formattedTarget = null;
    let formattedActual = null;
    let hasTarget = false;
    let hasActual = false;

    props.values?.forEach((v) => {
        if (v?.seriesType?.name === 'Target') {
            if (v?.formatStr) {
                formattedTarget = v?.formatStr;
                hasTarget = true;
            }
        } else if (v?.seriesType?.name === 'Actual') {
            if (v?.formatStr) {
                formattedActual = v?.formatStr;
                hasActual = true;
            }
        }
    });

    if (!hasTarget || !hasActual) {
        statusColourBg = currentTheme.palette.neutralLighter;
    }

    const classNames = mergeStyleSets({
        container: {
            minHeight: 50,
            paddingTop: 8,
            paddingBottom: 8,
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            alignItems: 'center',
            backgroundColor: !currentTheme.isInverted
                ? statusColourBg
                : currentTheme.palette.neutralLighterAlt,
            cursor: 'pointer',
        } as IRawStyle,
        nameContainer: {
            flexGrow: 1,
            paddingLeft: 8,
        } as IRawStyle,
        valuesContainer: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'right',
            color: currentTheme.palette.black,
        },
        arrowContainer: {
            minWidth: 50,
            borderLeft: `solid 1px ${currentTheme.palette.neutralQuaternary}`,
        },
    });

    return (
        <div className={classNames.container}>
            <div className={classNames.nameContainer}>
                <Link onClick={props.onClick}>
                    <Text
                        variant="smallPlus"
                        block
                        title={props.name?.trim()}
                        styles={{
                            root: {
                                color: currentTheme.palette.black,
                                fontStyle: !props.name?.trim()
                                    ? 'italic'
                                    : undefined,
                            },
                        }}
                    >
                        {props.name?.trim() || 'Enter measure name'}
                    </Text>
                </Link>
            </div>
            <div className={classNames.valuesContainer}>
                <Text variant="tiny" block nowrap>
                    {props.asOfLabel}
                </Text>

                <Text variant="medium" block nowrap>
                    {formattedActual ?? '-'}
                </Text>

                <Text variant="xSmall" block nowrap>
                    TARGET: {formattedTarget ?? 'N/A'}
                </Text>
            </div>
            <div className={classNames.arrowContainer}>
                <MeasureArrow
                    arrowColour={props.arrowColour}
                    arrowDirection={props.arrowDirection}
                    statusValue={props.status}
                    values={props.values}
                    isStatusLimited={props.isStatusLimited}
                    showPercentage={true}
                    percentagePosition="Bottom"
                    iconFontSize={20}
                    textFontSize={12}
                />
            </div>
        </div>
    );
}
