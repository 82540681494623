import React, { useState } from 'react';
import { defaultTheme } from '../hooks/useThemes';
import {
    IRawStyle,
    mergeStyleSets,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    Text,
} from '@fluentui/react';
import { useCookieConsent } from '../hooks/useCookieConsent';

export function CookieConsentBanner(props: {
    positionAbsolute?: boolean;
    onManageCookiesClick: () => void;
}): JSX.Element {
    const { isConsentRequired, acceptAll, rejectAll } = useCookieConsent();
    const [isDismissed, setIsDismissed] = useState(false);

    const dismissBanner = () => setIsDismissed(true);

    const handleAcceptAllClick = () => acceptAll();
    const handleRejectAllClick = () => rejectAll();

    const classNames = mergeStyleSets({
        cookieBannerAbsolute: {
            zIndex: 1000,
            position: 'absolute',
            top: 0,
            left: 0,
        } as IRawStyle,
        cookieBanner: {
            minHeight: 100,
            width: '100%',
            boxShadow: defaultTheme.effects.elevation16,
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 8,
            paddingBottom: 8,
        } as IRawStyle,
    });

    return (
        <React.Fragment>
            {isConsentRequired && !isDismissed && (
                <MessageBar
                    className={
                        classNames.cookieBanner +
                        (props.positionAbsolute
                            ? ` ${classNames.cookieBannerAbsolute}`
                            : '')
                    }
                    onDismiss={dismissBanner}
                    messageBarType={MessageBarType.info}
                    actions={
                        <div>
                            <MessageBarButton onClick={handleAcceptAllClick}>
                                Accept All
                            </MessageBarButton>
                            <MessageBarButton onClick={handleRejectAllClick}>
                                Reject All
                            </MessageBarButton>
                            <MessageBarButton
                                onClick={props.onManageCookiesClick}
                            >
                                Preferences
                            </MessageBarButton>
                        </div>
                    }
                >
                    <Text variant="medium">
                        By clicking “Accept All, you agree to the storing of
                        cookies on your device to enhance site navigation and
                        analyze site usage.
                    </Text>
                </MessageBar>
            )}
        </React.Fragment>
    );
}
