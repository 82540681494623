import { mergeStyleSets } from '@fluentui/react';
import * as React from 'react';

export function DetailsListCellItemContainer(
    props: React.PropsWithChildren<{
        textAlign?: 'right' | 'center';
    }>
): JSX.Element {
    const styles = mergeStyleSets({
        outerTable: {
            height: '100%',
            width: '100%',
            display: 'table',
        },
        innerCell: { verticalAlign: 'middle', display: 'table-cell' },
    });

    return (
        <div className={styles.outerTable}>
            <div
                className={styles.innerCell}
                style={{
                    textAlign: props.textAlign ? props.textAlign : undefined,
                }}
            >
                {props.children}
            </div>
        </div>
    );
}
