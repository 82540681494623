import React, { useState } from 'react';
import {
    DefaultButton,
    IRawStyle,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PanelType,
    Stack,
    Text,
    TextField,
} from '@fluentui/react';
import { EditPanel } from '../../../components/shared/EditPanel';
import { useStateContext } from '../../../services/contextProvider';
import { useUpdateUserApiKeyMutation } from '../../../data/types';
import { useClipboard } from 'use-clipboard-copy';

const classNames = mergeStyleSets({
    container: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    } as IRawStyle,
});

export default function AddApiKeyPanel(props: {
    showPanel: boolean;
    onCancel: () => void;
}): JSX.Element {
    const { currentUserId, currentTenantId } = useStateContext();

    const [apiKeyLabel, setApiKeyLabel] = useState<string>();
    const [labelWarning, setLabelWarning] = useState<string>();
    const [apiKey, setApiKey] = useState<string>();

    const isSaving = false;
    const hasSaved = false;
    const saveErrorMessage = undefined;
    const isValid = true;

    const [updateUserApiKeyMutation, { data, loading, error }] =
        useUpdateUserApiKeyMutation({
            onCompleted: (updateResult) => {
                console.log('update completed', updateResult);
                setApiKey(updateResult.userApiKeyAdd?.apiKey || '');
            },
            refetchQueries: ['GetApiKeys'],
        });

    const handleSave = async () => {
        if (!apiKeyLabel) {
            setLabelWarning('Please enter a label');
            return;
        } else {
            setLabelWarning(undefined);
        }

        await updateUserApiKeyMutation({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    id: null,
                    userId: currentUserId || '',
                    apiKeyLabel: apiKeyLabel || '',
                    utcIssued: new Date().toISOString(),
                    apiKey: 'ABC',
                    tenantId: currentTenantId || '',
                },
            },
        });
    };

    const clipboard = useClipboard({
        copiedTimeout: 3000, // show copy message in milliseconds
    });

    const closePanel = () => {
        setApiKeyLabel('');
        setApiKey(undefined);
        props.onCancel();
    };

    return (
        <EditPanel
            showPanel={props.showPanel}
            headerText="Add new API Key"
            onDismiss={closePanel}
            saveErrorMessage={saveErrorMessage}
            isSaving={isSaving}
            hasSaved={hasSaved}
            isValid={isValid}
            activeViewName={null}
            onUpdateClick={handleSave}
            panelType={PanelType.medium}
        >
            <div className={classNames.container}>
                <TextField
                    label="Key Label"
                    name="apiKeyLabel"
                    value={apiKeyLabel || ''}
                    autoFocus
                    readOnly={!!apiKey}
                    onChange={(_ev, newValue) => {
                        setApiKeyLabel(newValue);
                    }}
                />

                {labelWarning && (
                    <MessageBar messageBarType={MessageBarType.warning}>
                        Please enter a label
                    </MessageBar>
                )}

                <Text block variant="small">
                    A memorable name to identify your key
                </Text>

                {!apiKey && (
                    <DefaultButton
                        text="Add"
                        iconProps={{ iconName: 'Add' }}
                        onClick={handleSave}
                        style={{ width: '5em' }}
                    />
                )}

                {apiKey && (
                    <>
                        <TextField
                            label="API KEY"
                            name="apiKey"
                            value={apiKey || ''}
                            readOnly={true}
                        />

                        <Text block variant="small">
                            Make a copy of your private Key and do not share it
                            with anyone. This will only be displayed once. If
                            you lose the key you will need to delete and
                            regenerate the key.
                        </Text>

                        <Stack horizontal tokens={{ childrenGap: '12px' }}>
                            <DefaultButton
                                text={
                                    clipboard.copied
                                        ? 'Copied!'
                                        : 'Copy Key to Clipboard'
                                }
                                iconProps={{ iconName: 'Copy' }}
                                onClick={() => {
                                    const toCopy = `NAME: ${apiKeyLabel}\nAPIKEY: ${apiKey}`;
                                    clipboard.copy(toCopy);
                                }}
                                style={{ width: '18em' }}
                            />
                            <DefaultButton
                                text="Close"
                                iconProps={{ iconName: 'Exit' }}
                                onClick={closePanel}
                                style={{ width: '8em' }}
                            />
                        </Stack>
                    </>
                )}
            </div>
        </EditPanel>
    );
}
