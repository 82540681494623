import { useContext } from 'react';
import i18n, { KeyPrefix } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
    initReactI18next,
    useTranslation,
    UseTranslationResponse,
} from 'react-i18next';
import { IDatePickerStrings } from '@fluentui/react';

import base_en from '../locales/en/base.json';
import okr_en from '../locales/en/okr.json';
import topsoe_en from '../locales/en/topsoe.json';
import base_enus from '../locales/en-US/base.json';
import Context from './context';

export const defaultNS = 'base';
export const resources = {
    en: {
        base: base_en,
        okr: okr_en,
        topsoe: topsoe_en,
    },
    'en-US': {
        base: base_enus,
    },
} as const;

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        fallbackNS: 'base',
        ns: ['base', 'okr'],
        defaultNS: 'base',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources,
    });

export function useLanguage(): UseTranslationResponse<
    'base' | 'okr' | 'topsoe',
    KeyPrefix<'base'>
> {
    const context = useContext(Context);

    const baseFile =
        context?.currentTenantCode === 'topsoe' ||
        context?.currentTenantCode === 'calc'
            ? 'topsoe'
            : 'base';

    return useTranslation([baseFile]);
}

export const AvailableLanguages = [
    {
        code: 'en',
        text: 'English',
        isDefault: true,
    },
    {
        code: 'en-US',
        text: 'English (United States)',
    },
];

export const DatePickerStrings: IDatePickerStrings = {
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    shortMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ],
    days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ],
    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    prevYearRangeAriaLabel: 'Previous year range',
    nextYearRangeAriaLabel: 'Next year range',
    closeButtonAriaLabel: 'Close date picker',
    weekNumberFormatString: 'Week number {0}',
    isRequiredErrorMessage: 'Please enter a date.',
};

export default i18n;
