import React from 'react';
import dayjs from 'dayjs';
import { Link, Stack, Text } from '@fluentui/react';
import { TaskCategoryLabel } from './TaskCategoryLabel';
import { useMissionNavigation } from '../hooks/useMissionNavigation';

export type ResourcedFromTooltipContentTask = {
    utcResourceRemoved: string | null;
    utcAccepted: string | null;
    isDuplicate: boolean;
    resourcedFromTask: {
        name?: string | null;
        start?: string | null;
        due?: string | null;
        done?: string | null;
        utcPostponed: string | null;
        utcCancelled: string | null;
        utcAtRisk: string | null;
        mission?: {
            id: string | null;
            userId: string | null;
            owner?: string | null;
            title?: string | null;
            rights: {
                read: boolean;
            };
        } | null;
        parentTask?: {
            name?: string | null;
            taskCategory?: {
                name?: string | null;
                colourHex?: string | null;
            } | null;
        } | null;
        resourcedTasks?:
            | {
                  resource?: {
                      displayName: string | null;
                  } | null;
              }[]
            | null;
    } | null;
};

export function ResourcedFromTooltipContent(props: {
    task: ResourcedFromTooltipContentTask;
}): JSX.Element | null {
    const { task } = props;
    const { resourcedFromTask } = task;

    const { navigateToMission } = useMissionNavigation();

    if (!task) {
        return null;
    }

    const canNavigate = resourcedFromTask?.mission?.rights?.read;

    const navigateToResourceTaskMission = async () => {
        if (resourcedFromTask?.mission?.id && canNavigate) {
            navigateToMission(resourcedFromTask.mission?.id);
        }
    };

    const missionText = [
        resourcedFromTask?.mission?.owner,
        resourcedFromTask?.mission?.title,
    ].join(' - ');

    return (
        <Stack tokens={{ childrenGap: 8 }}>
            <Text variant="mediumPlus">
                {!task.utcResourceRemoved
                    ? task.utcAccepted
                        ? 'Accepted Task'
                        : 'Resourced Task'
                    : 'Removed Resource'}

                {task.isDuplicate && ' (Merged)'}
            </Text>

            {resourcedFromTask?.parentTask?.taskCategory && (
                <Stack.Item>
                    <TaskCategoryLabel
                        {...resourcedFromTask.parentTask.taskCategory}
                    />
                </Stack.Item>
            )}

            {resourcedFromTask?.mission && (
                <Stack.Item>
                    <strong>Mission:</strong>{' '}
                    {canNavigate ? (
                        <Link onClick={navigateToResourceTaskMission}>
                            {missionText}
                        </Link>
                    ) : (
                        <span>{missionText}</span>
                    )}
                </Stack.Item>
            )}
            {resourcedFromTask?.parentTask?.name && (
                <Stack.Item>
                    <strong>Specified Task:</strong>{' '}
                    {resourcedFromTask.parentTask?.name}
                </Stack.Item>
            )}
            {!!resourcedFromTask && (
                <Stack.Item>
                    <strong>Implied Task:</strong>{' '}
                    <span>{resourcedFromTask.name}</span>
                </Stack.Item>
            )}
            {!!resourcedFromTask?.start && (
                <Stack.Item>
                    <strong>Start:</strong>{' '}
                    <span>
                        {resourcedFromTask?.start
                            ? dayjs
                                  .utc(resourcedFromTask.start)
                                  .format('DD MMM YYYY')
                            : ' - '}
                    </span>
                </Stack.Item>
            )}
            {!!resourcedFromTask?.due && (
                <Stack.Item>
                    <strong>Due:</strong>{' '}
                    <span>
                        {resourcedFromTask.due
                            ? dayjs
                                  .utc(resourcedFromTask.due)
                                  .format('DD MMM YYYY')
                            : ' - '}
                    </span>
                </Stack.Item>
            )}
            {!!resourcedFromTask?.done && (
                <Stack.Item>
                    <strong>Done:</strong>{' '}
                    <span>
                        {resourcedFromTask.done
                            ? dayjs
                                  .utc(resourcedFromTask.done)
                                  .format('DD MMM YYYY')
                            : ' - '}
                    </span>
                </Stack.Item>
            )}
            {!!resourcedFromTask?.utcPostponed && (
                <Stack.Item>
                    <strong>Status:</strong> <span>Postponed</span>
                </Stack.Item>
            )}
            {!!resourcedFromTask?.utcCancelled && (
                <Stack.Item>
                    <strong>Status:</strong> <span>Cancelled</span>
                </Stack.Item>
            )}
            {!!resourcedFromTask?.utcAtRisk && (
                <Stack.Item>
                    <strong>Status:</strong> <span>At Risk</span>
                </Stack.Item>
            )}
            {(resourcedFromTask?.resourcedTasks || []).length > 0 && (
                <Stack.Item>
                    <strong>Resources:</strong>{' '}
                    {resourcedFromTask?.resourcedTasks
                        ?.map((rt) => rt.resource?.displayName || '')
                        .join(', ')}
                </Stack.Item>
            )}
        </Stack>
    );
}
