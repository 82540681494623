import React from 'react';
import dayjs from 'dayjs';
import {
    Stack,
    Icon,
    Dropdown,
    IDropdownOption,
    IDropdownProps,
} from '@fluentui/react';
import { TaskStatusNames, TaskWithStatus } from '../../data/extendedTypes';
import { useTaskStatus } from '../../hooks/useTaskStatus';
import { TaskStatusColours } from '../../Colours';
import { TaskStatusIcons } from '../../Icons';
import { InfoLabel } from '../InfoLabel';

export function TaskStatusDropDown(props: {
    label?: string | undefined;
    infoContent?: string | JSX.Element | undefined;
    task: TaskWithStatus;
    isReadOnly: boolean;
    onChange?: (taskUpdates: {
        utcPostponed: string | null;
        utcCancelled: string | null;
        utcAtRisk: string | null;
    }) => void;
}): JSX.Element {
    const status = useTaskStatus(props.task);
    const activeStatus = useTaskStatus({
        ...props.task,
        utcPostponed: null,
        utcCancelled: null,
        utcAtRisk: null,
    });

    const options: IDropdownOption[] = [
        {
            key: 'active',
            text: activeStatus?.text || '',
            data: {
                colourHex: activeStatus?.colour,
                iconName: activeStatus?.iconName,
            },
        },
        {
            key: 'postponed',
            text: `Postponed`,
            data: {
                colourHex: TaskStatusColours.postponed,
                iconName: TaskStatusIcons.postponed,
            },
        },
        {
            key: 'cancelled',
            text: `Cancelled`,
            data: {
                colourHex: TaskStatusColours.cancelled,
                iconName: TaskStatusIcons.cancelled,
            },
        },
        {
            key: 'atRisk',
            text: `At Risk`,
            data: {
                colourHex: TaskStatusColours.atRisk,
                iconName: TaskStatusIcons.atRisk,
            },
        },
    ];

    const selectedKey = props.task.utcPostponed
        ? 'postponed'
        : props.task.utcCancelled
          ? 'cancelled'
          : props.task.utcAtRisk
            ? 'atRisk'
            : 'active';

    const handleRenderOption = (option?: IDropdownOption): JSX.Element => {
        const iconStyles = {
            color: option?.data?.colourHex,
            marginRight: 8,
        };

        return (
            <Stack horizontal verticalAlign="center" style={{ minWidth: 10 }}>
                <Stack.Item align="center" verticalFill>
                    <Icon
                        style={iconStyles}
                        iconName={option?.data.iconName}
                        aria-hidden="true"
                    />
                </Stack.Item>
                <Stack.Item align="center" verticalFill>
                    {option?.text}
                </Stack.Item>
            </Stack>
        );
    };

    const handleRenderTitle = (
        options?: IDropdownOption[]
    ): JSX.Element | null => {
        if (!options || options.length === 0) {
            return null;
        }
        return handleRenderOption(options[0]);
    };

    const handleRenderLabel = (
        p?: IDropdownProps,
        defaultRender?: (p?: IDropdownProps) => JSX.Element | null
    ): JSX.Element | null => {
        if (props.infoContent) {
            return (
                <InfoLabel
                    disabled={p?.disabled}
                    required={p?.required}
                    infoContent={props.infoContent}
                >
                    {p?.label}
                </InfoLabel>
            );
        }

        return defaultRender ? defaultRender(p) : null;
    };

    const handleChange = (_ev?: React.FormEvent, option?: IDropdownOption) => {
        if (props.onChange && option?.key) {
            switch (option.key) {
                case 'active':
                    props.onChange({
                        utcPostponed: null,
                        utcAtRisk: null,
                        utcCancelled: null,
                    });
                    break;
                case 'postponed':
                    props.onChange({
                        utcPostponed:
                            props.task.utcPostponed ??
                            dayjs.utc().toISOString(),
                        utcCancelled: null,
                        utcAtRisk: null,
                    });
                    break;
                case 'cancelled':
                    props.onChange({
                        utcPostponed: null,
                        utcCancelled:
                            props.task.utcCancelled ??
                            dayjs.utc().toISOString(),
                        utcAtRisk: null,
                    });
                    break;
                case 'atRisk':
                    props.onChange({
                        utcPostponed: null,
                        utcCancelled: null,
                        utcAtRisk:
                            props.task.utcAtRisk ?? dayjs.utc().toISOString(),
                    });
                    break;
            }
        }
    };

    const disabled =
        props.isReadOnly ||
        status?.name === TaskStatusNames.CompletedLate ||
        status?.name === TaskStatusNames.CompletedOnTime;

    return (
        <Dropdown
            label={props.label}
            selectedKey={selectedKey}
            onChange={handleChange}
            options={options}
            disabled={disabled}
            onRenderOption={handleRenderOption}
            onRenderTitle={handleRenderTitle}
            onRenderLabel={handleRenderLabel}
        />
    );
}
