import * as React from 'react';
import {
    ShimmeredDetailsList,
    SelectionMode,
    IColumn,
    DetailsListLayoutMode,
    Stack,
    Toggle,
    CommandButton,
    Spinner,
    SpinnerSize,
    IconButton,
    Sticky,
    IDetailsHeaderProps,
    IRenderFunction,
    ScrollablePane,
    Text,
    Dropdown,
    IDropdownOption,
} from '@fluentui/react';
import { useSorter } from '../../hooks/useSorter';
import {
    GetUsageStatsQuery,
    UsageReportRowQl,
    useGetUsageStatsQuery,
} from '../../data/types';
import { SetupPageContainer } from './components/SetupPageContainer';
import { usageStatsExport } from '../../services/exporter';
import { useStateContext } from '../../services/contextProvider';
import orderBy from 'lodash/orderBy';
import { AdvanceCard } from '../../components/AdvanceCard';
import { useRefetch } from '../../hooks/useRefetch';

export function UsageStats(): JSX.Element {
    const { configuration } = useStateContext();
    const [includeLogins, setIncludeLogins] = React.useState<boolean>(false);
    const {
        data,
        loading,
        refetch: _refetch,
    } = useGetUsageStatsQuery({
        variables: { includeLogins: includeLogins },
    });
    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(data?.usageStats.tenants, 'id', false, true);
    const [isExporting, setIsExporting] = React.useState(false);
    const [selectedTenant, setSeletedTenant] = React.useState('ALL');

    const [isExportingTenant, setIsExportingTenant] = React.useState<{
        [code: string]: boolean;
    }>({});

    const [refetch, isRefetching] = useRefetch(_refetch);

    const columns: IColumn[] = [
        {
            key: 'id',
            name: 'Code',
            fieldName: 'id',
            minWidth: 100,
            maxWidth: 200,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            isSorted: sortColumn === 'id',
            isSortedDescending: sortColumn === 'id' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('id', true),
        },
        {
            key: 'tenantName',
            name: 'Tenant',
            fieldName: 'tenantName',
            minWidth: 100,
            maxWidth: 200,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            isSorted: sortColumn === 'tenantName',
            isSortedDescending: sortColumn === 'tenantName' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('tenantName', true),
        },
        {
            key: 'fyStart',
            name: 'FY Start',
            fieldName: 'fyStart',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            isSorted: sortColumn === 'fyStart',
            isSortedDescending: sortColumn === 'fyStart' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('fyStart', true),
            onRender: (value): string => value.fyStart.split('T')[0],
        },
        {
            key: 'divisionCount',
            name: 'Divisions',
            fieldName: 'divisionCount',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            isSorted: sortColumn === 'divisionCount',
            isSortedDescending: sortColumn === 'divisionCount' && sortIsDesc,
            onColumnClick: (): void =>
                setSortColumnName('divisionCount', false),
        },
        {
            key: 'teams',
            name: 'Teams',
            fieldName: 'teamCount',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            isSorted: sortColumn === 'teamCount',
            isSortedDescending: sortColumn === 'teamCount' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('teamCount', false),
        },
        {
            key: 'missions',
            name: 'Missions',
            fieldName: 'missionCount',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            isSorted: sortColumn === 'missionCount',
            isSortedDescending: sortColumn === 'missionCount' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('missionCount', false),
        },
        {
            key: 'missionOwners',
            name: 'Mission Owners',
            fieldName: 'missionOwners',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            isSorted: sortColumn === 'missionOwners',
            isSortedDescending: sortColumn === 'missionOwners' && sortIsDesc,
            onColumnClick: (): void =>
                setSortColumnName('missionOwners', false),
        },
        {
            key: 'contributors',
            name: 'Contributors',
            fieldName: 'contributors',
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            isSorted: sortColumn === 'contributors',
            isSortedDescending: sortColumn === 'contributors' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('contributors', false),
        },
        // {
        //     key: 'viewers',
        //     name: 'Viewers',
        //     fieldName: 'viewers',
        //     minWidth: 100,
        //     isMultiline: true,
        //     isResizable: true,
        //     isPadded: true,
        //     isSorted: sortColumn === 'viewers',
        //     isSortedDescending: sortColumn === 'viewers' && sortIsDesc,
        //     onColumnClick: (): void => setSortColumnName('viewers', false),
        // },
    ];
    if (includeLogins) {
        columns.push(
            {
                key: 'login7',
                name: 'Login 7',
                fieldName: 'login7',
                minWidth: 100,
                maxWidth: 100,
                isMultiline: true,
                isResizable: true,
                isPadded: true,
                isSorted: sortColumn === 'login7',
                isSortedDescending: sortColumn === 'login7' && sortIsDesc,
                onColumnClick: (): void => setSortColumnName('login7', false),
                onRender: (value): number => (value.login7 ? value.login7 : ''),
            },
            {
                key: 'login30',
                name: 'Login 30',
                fieldName: 'login30',
                minWidth: 100,
                maxWidth: 100,
                isMultiline: true,
                isResizable: true,
                isPadded: true,
                isSorted: sortColumn === 'login30',
                isSortedDescending: sortColumn === 'login30' && sortIsDesc,
                onColumnClick: (): void => setSortColumnName('login30', false),
                onRender: (value): number =>
                    value.login30 ? value.login30 : '',
            },
            {
                key: 'login90',
                name: 'Login 90',
                fieldName: 'login90',
                minWidth: 100,
                maxWidth: 100,
                isMultiline: true,
                isResizable: true,
                isPadded: true,
                isSorted: sortColumn === 'login90',
                isSortedDescending: sortColumn === 'login90' && sortIsDesc,
                onColumnClick: (): void => setSortColumnName('login90', false),
                onRender: (value): number =>
                    value.login90 ? value.login90 : '',
            }
        );
    }

    columns.push({
        key: 'export',
        name: 'Export',
        minWidth: 32,
        isMultiline: false,
        isPadded: true,
        onRender: function renderActions(item: { id: string }): JSX.Element {
            return (
                <IconButton
                    key={`export-${item.id}`}
                    text={'Export'}
                    disabled={isExporting}
                    iconProps={{ iconName: 'ExcelDocument' }}
                    onRenderIcon={(_props, defaultRenderer) => {
                        const usageRow = item as UsageReportRowQl;
                        if (isExportingTenant[usageRow.tenantCode]) {
                            return <Spinner size={SpinnerSize.small} />;
                        }

                        return defaultRenderer ? defaultRenderer() : null;
                    }}
                    onClick={(): void => {
                        const usageRow = item as UsageReportRowQl;
                        handleTenantExportButtonClick(usageRow.tenantCode);
                    }}
                />
            );
        },
    });

    const columnsUser: IColumn[] = [
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 100,
            // maxWidth: 300,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
        },
        {
            key: 'email',
            name: 'Email Address',
            fieldName: 'email',
            minWidth: 200,
            // maxWidth: 300,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
        },
        {
            key: 'divisionNames',
            name: 'Divisions',
            fieldName: 'divisionNames',
            minWidth: 200,
            // maxWidth: 300,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
        },
        {
            key: 'leaderOfTeamNames',
            name: 'Team Leader',
            fieldName: 'leaderOfTeamNames',
            minWidth: 200,
            // maxWidth: 300,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            onRender: (value): string =>
                (value.leaderOfTeamNames || []).join(', '),
        },
        {
            key: 'teamNames',
            name: 'Teams',
            fieldName: 'teamNames',
            minWidth: 200,
            // maxWidth: 300,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            onRender: (value): string => (value.teamNames || []).join(', '),
        },
        {
            key: 'isMissionOwner',
            name: 'MissionOwner',
            fieldName: 'isMissionOwner',
            minWidth: 100,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            onRender: (value): string => (value.isMissionOwner ? 'true' : ''),
        },
        {
            key: 'isContributor',
            name: 'Contributor',
            fieldName: 'isContributor',
            minWidth: 100,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            onRender: (value): string => (value.isContributor ? 'true' : ''),
        },
        // {
        //     key: 'isViewer',
        //     name: 'Viewer',
        //     fieldName: 'isViewer',
        //     minWidth: 100,
        //     isMultiline: true,
        //     isResizable: true,
        //     isPadded: true,
        //     onRender: (value): string => (value.isViewer ? 'true' : ''),
        // },
        {
            key: 'utcCreated',
            name: 'Created',
            fieldName: 'utcCreated',
            minWidth: 100,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            onRender: (value): string => value.utcCreated.split('T')[0],
        },
        {
            key: 'missionUtcCreated',
            name: 'Mission Created',
            fieldName: 'missionUtcCreated',
            minWidth: 100,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
            onRender: (value): string => value.utcCreated.split('T')[0],
        },
        {
            key: 'missionCount',
            name: 'Missions',
            fieldName: 'missionCount',
            minWidth: 100,
            isMultiline: true,
            isResizable: true,
            isPadded: true,
        },
    ];

    if (includeLogins) {
        columnsUser.push(
            ...[
                {
                    key: 'lastLogin',
                    name: 'Last Login',
                    fieldName: 'lastLogin',
                    minWidth: 100,
                    isMultiline: true,
                    isResizable: true,
                    isPadded: true,
                    isSorted: sortColumn === 'lastLogin',
                    isSortedDescending:
                        sortColumn === 'lastLogin' && sortIsDesc,
                    onColumnClick: (): void =>
                        setSortColumnName('lastLogin', true),
                    onRender: (value: { lastLogin: string }): string =>
                        (value.lastLogin ?? '').split('T')[0],
                },
                {
                    key: 'loginDays',
                    name: 'Login Days',
                    fieldName: 'loginDays',
                    minWidth: 50,
                    isMultiline: true,
                    isResizable: true,
                    isPadded: true,
                    isSorted: sortColumn === 'loginDays',
                    isSortedDescending:
                        sortColumn === 'loginDays' && sortIsDesc,
                    onColumnClick: (): void =>
                        setSortColumnName('loginDays', true),
                    onRender: (value: {
                        lastLogin: string;
                        loginDays: string;
                    }): string => (value.lastLogin ? value.loginDays : ''),
                },
            ]
        );
    }

    const tokens = {
        gapStack: {
            padding: 0,
            marginBottom: 32,
            childrenGap: 0,
        },
    };

    function _onChange() {
        setIncludeLogins(!includeLogins);
    }

    async function handleRefreshClick(): Promise<void> {
        await refetch();
    }

    async function handleExportButtonClick(): Promise<void> {
        try {
            setIsExporting(true);
            await usageStatsExport(configuration, includeLogins, data);
        } catch (e) {
            console.log(e);
        } finally {
            setIsExporting(false);
        }
    }

    async function handleTenantExportButtonClick(
        tenantCode: string
    ): Promise<void> {
        try {
            setIsExportingTenant({
                ...isExportingTenant,
                [tenantCode]: true,
            });

            if (data) {
                const filtered: GetUsageStatsQuery = {
                    __typename: 'Queries',
                    usageStats: {
                        tenants: data?.usageStats.tenants.filter(
                            (t) => t.tenantCode == tenantCode
                        ),
                        usersByTenant: data?.usageStats.usersByTenant.filter(
                            (t) => t.tenantCode == tenantCode
                        ),
                        error: null,
                        __typename: 'UsageReportQL',
                    },
                };

                await usageStatsExport(configuration, includeLogins, filtered);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsExportingTenant({
                ...isExportingTenant,
                [tenantCode]: false,
            });
        }
    }

    const dropDownOptions: IDropdownOption[] = [
        {
            key: 'ALL',
            text: '-- All Tenants --',
        },
        ...orderBy(
            data?.usageStats.usersByTenant || [],
            ['tenantCode'],
            ['asc']
        ).map((tenant) => ({
            key: tenant.tenantCode,
            text: tenant.tenantName || tenant.tenantCode,
        })),
    ];

    return (
        <SetupPageContainer>
            <Stack tokens={tokens.gapStack}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            minWidth: 200,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 8,
                        }}
                    >
                        <Text block variant="xLarge">
                            Usage Stats
                        </Text>

                        <Dropdown
                            options={dropDownOptions}
                            selectedKey={selectedTenant}
                            onChange={(_ev, option) =>
                                setSeletedTenant(
                                    (option?.key as string) || 'ALL'
                                )
                            }
                        />
                    </div>

                    <div>
                        <Stack tokens={{ childrenGap: 4 }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 4,
                                    alignSelf: 'end',
                                }}
                            >
                                <CommandButton
                                    text="Refresh"
                                    iconProps={{ iconName: 'Refresh' }}
                                    onClick={handleRefreshClick}
                                    allowDisabledFocus
                                    disabled={loading || isRefetching}
                                />
                                <CommandButton
                                    text="Export"
                                    iconProps={{ iconName: 'ExcelDocument' }}
                                    disabled={isExporting}
                                    onRenderIcon={(_props, defaultRenderer) => {
                                        if (isExporting) {
                                            return (
                                                <Spinner
                                                    size={SpinnerSize.small}
                                                />
                                            );
                                        }

                                        return defaultRenderer
                                            ? defaultRenderer()
                                            : null;
                                    }}
                                    onClick={handleExportButtonClick}
                                />
                            </div>
                            <Toggle
                                label="Include Login Information"
                                onText="On"
                                offText="Off"
                                defaultChecked={includeLogins}
                                onChange={_onChange}
                                inlineLabel
                            />
                        </Stack>
                    </div>
                </div>
            </Stack>
            <div className="content-container">
                <AdvanceCard
                    style={{
                        height: '100%',
                    }}
                >
                    <ScrollablePane>
                        {selectedTenant === 'ALL' ? (
                            <ShimmeredDetailsList
                                compact
                                items={sortedItems}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                layoutMode={DetailsListLayoutMode.justified}
                                shimmerLines={15}
                                enableShimmer={loading || isRefetching}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details"
                                listProps={{
                                    renderedWindowsAhead: 0,
                                    renderedWindowsBehind: 0,
                                }}
                                detailsListStyles={{
                                    root: {
                                        overflow: 'visible',
                                    },
                                }}
                                onRenderDetailsHeader={(
                                    detailsHeaderProps?: IDetailsHeaderProps,
                                    defaultRender?: IRenderFunction<IDetailsHeaderProps>
                                ) => (
                                    <Sticky>
                                        {defaultRender
                                            ? defaultRender(detailsHeaderProps)
                                            : null}
                                    </Sticky>
                                )}
                            />
                        ) : (
                            <ShimmeredDetailsList
                                compact={true}
                                items={orderBy(
                                    data?.usageStats.usersByTenant.find(
                                        (t) => t.tenantCode === selectedTenant
                                    )?.users || [],
                                    [
                                        'divisionNames',
                                        'leaderOfTeamNames',
                                        'teamNames',
                                    ],
                                    ['asc', 'asc', 'asc']
                                )}
                                columns={columnsUser}
                                selectionMode={SelectionMode.none}
                                layoutMode={DetailsListLayoutMode.justified}
                                shimmerLines={15}
                                enableShimmer={loading || isRefetching}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details"
                                listProps={{
                                    renderedWindowsAhead: 0,
                                    renderedWindowsBehind: 0,
                                }}
                                detailsListStyles={{
                                    root: {
                                        overflow: 'visible',
                                    },
                                }}
                                onRenderDetailsHeader={(
                                    detailsHeaderProps?: IDetailsHeaderProps,
                                    defaultRender?: IRenderFunction<IDetailsHeaderProps>
                                ) => (
                                    <Sticky>
                                        {defaultRender
                                            ? defaultRender(detailsHeaderProps)
                                            : null}
                                    </Sticky>
                                )}
                            />
                        )}
                    </ScrollablePane>
                </AdvanceCard>
            </div>
            {/* {!loading &&
                data?.usageStats.usersByTenant &&
                orderBy(
                    data?.usageStats.usersByTenant,
                    ['tenantCode'],
                    ['asc']
                ).map((tenant) => {
                    return (
                        <AreaContainer
                            key={`users-${tenant.tenantCode}-area`}
                            title={`${tenant.tenantCode} - ${tenant.tenantName}`}
                            isCollapsable={true}
                            initialState="collapsed"
                        >
                            <div>
                                <AdvanceCard
                                    key={`users-${tenant.tenantCode}-users`}
                                    style={{
                                        position: 'relative',
                                        height: 500,
                                    }}
                                >
                                    <ScrollablePane>
                                        <ShimmeredDetailsList
                                            compact={true}
                                            items={orderBy(
                                                tenant.users,
                                                [
                                                    'divisionNames',
                                                    'leaderOfTeamNames',
                                                    'teamNames',
                                                ],
                                                ['asc', 'asc', 'asc']
                                            )}
                                            columns={columnsUser}
                                            selectionMode={SelectionMode.none}
                                            layoutMode={
                                                DetailsListLayoutMode.justified
                                            }
                                            shimmerLines={15}
                                            enableShimmer={
                                                loading || isRefetching
                                            }
                                            ariaLabelForShimmer="Content is being fetched"
                                            ariaLabelForGrid="Item details"
                                            listProps={{
                                                renderedWindowsAhead: 0,
                                                renderedWindowsBehind: 0,
                                            }}
                                            detailsListStyles={{
                                                root: {
                                                    overflow: 'visible',
                                                },
                                            }}
                                            onRenderDetailsHeader={(
                                                detailsHeaderProps?: IDetailsHeaderProps,
                                                defaultRender?: IRenderFunction<IDetailsHeaderProps>
                                            ) => (
                                                <Sticky>
                                                    {defaultRender
                                                        ? defaultRender(
                                                              detailsHeaderProps
                                                          )
                                                        : null}
                                                </Sticky>
                                            )}
                                        />
                                    </ScrollablePane>
                                </AdvanceCard>
                            </div>
                        </AreaContainer>
                    );
                })} */}
        </SetupPageContainer>
    );
}
