import React, { useCallback, useEffect, useState } from 'react';
import { useParams, generatePath } from 'react-router-dom';
import { useGetMissionQuery } from '../../data/types';
import { useStateContext } from '../../services/contextProvider';
import { PresentationFrame } from './components/PresentationFrame';
import { paths } from '../../services/navigation';
import { ActiveViews, useActiveView } from '../../hooks/useActiveView';

export function Present(): JSX.Element | null {
    const params = useParams();

    const { missionId, presentation, templateReportViewId } = params;

    const { currentTenantId } = useStateContext();

    const { data } = useGetMissionQuery({
        skip: !missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: missionId || '',
        },
    });

    const [docTitle, setDocTitle] = useState<string>();
    const [path, setPath] = useState<string>();
    const [activeView, setActiveView] = useState<keyof typeof ActiveViews>();

    const mission = data?.mission;

    const getUrl = useCallback(
        (path: string): string => {
            return generatePath(path, params);
        },
        [params]
    );

    useEffect(() => {
        switch (presentation) {
            case 'mission-analysis': {
                setPath(`${getUrl(paths.missionAnalysisPresentation)}#/title`);
                setActiveView('MissionPresentation');
                break;
            }
            case 'mission-summary': {
                setPath(`${getUrl(paths.missionSummaryPresentation)}`);
                setActiveView('MissionPresentation');
                break;
            }
            case 'status-report': {
                setPath(`${getUrl(paths.statusReportPresentation)}#/title`);
                setActiveView('StatusReportPresentation');
                break;
            }
            case 'template-report': {
                const path = templateReportViewId
                    ? paths.templateReportPresentationView
                    : paths.templateReportPresentation;

                setPath(`${getUrl(path)}#/title`);
                setActiveView('TemplateReportPresentation');
                break;
            }
            default:
                console.log(`Unknown presentation type ${presentation}`);
        }
    }, [presentation, templateReportViewId, getUrl]);

    useEffect(() => {
        if ((data?.mission, presentation)) {
            setDocTitle(`${mission?.owner} (${mission?.title}) Presentation`);
        }
    }, [data, presentation, mission]);

    useActiveView(activeView, docTitle);

    return path ? <PresentationFrame url={path} /> : null;
}
