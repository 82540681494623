import React, { useRef } from 'react';

import {
    SelectionMode,
    Selection,
    IDetailsGroupDividerProps,
    IGroup,
    DetailsListLayoutMode,
    DetailsRow,
    GroupHeader,
    IGroupHeaderProps,
    Stack,
    Text,
    PersonaSize,
    Persona,
    IDragDropEvents,
    IDragDropContext,
    mergeStyles,
    IColumn,
    ConstrainMode,
    Link,
    PersonaCoin,
    ShimmeredDetailsList,
    IDetailsHeaderProps,
    IRenderFunction,
    Icon,
    mergeStyleSets,
    IPersonaCoinStyles,
    ActionButton,
    FontSizes,
    IButtonStyles,
    Shimmer,
    Coachmark,
} from '@fluentui/react';

import { GetMissionTasksQuery, Task } from '../data/types';

import TaskProgressIndicator from './TaskProgressIndicator';
import { Access, ExtractQueryArrayType } from '../data/extendedTypes';
import { sorters } from '../data/sorters';
import { useStateContext } from '../services/contextProvider';
import { photoService } from '../services/photo.service';
import { InternalLink } from './navigation';
import { useThemes } from '../hooks/useThemes';
import { TaskStatusColours } from '../Colours';
import {
    useTaskExpandActionsContext,
    useTaskExpandContext,
} from '../TaskExpandContext';
import orderBy from 'lodash/orderBy';
import { ResourceTooltip } from './ResourceTooltip';
import { useSorter } from '../hooks/useSorter';
import { useViewport } from '../hooks/useViewport';
import { ResourcedFromCoin } from './ResourcedFromCoin';
import { TaskFilters } from '../scenes/MissionBuilder/components/TaskFilterBar';
import { useTaskFilters } from '../hooks/useTaskFilters';
import { ImpliedTaskListDateColumn } from './ImpliedTaskListDateColumn';
import { ImpliedTaskListProgressColumn } from './ImpliedTaskListProgressColumn';
import { DetailsListCellItemContainer } from './shared/DetailsListCellItemContainer';
import { useTaskEditRules } from '../hooks/useTaskEditRules';
import { EditibleText } from './EditibleText';
import { useInlineTaskUpdater } from '../hooks/useInlineTaskUpdater';
import { ImpliedTaskMoreOptionsButton } from './ImpliedTaskMoreOptionsButton';
import { ImpliedTaskListResources } from './ImpliedTaskListResources';
import { TaskSummaryItem } from '../scenes/MissionBuilder/components/TaskList';
import { CostWeightingRating, EffortWeightingRating } from './WeightingRating';
import { TaskTileColumn } from '../hooks/useColumnPreferences';
import { CommentTooltipHost } from './CommentTooltipHost';

export type ImpliedTaskType = ExtractQueryArrayType<
    GetMissionTasksQuery,
    ['tasks']
>;

export type ResourceTaskType = ExtractQueryArrayType<
    GetMissionTasksQuery,
    ['tasks', 'resourcedTasks']
>;

export interface SubTaskType extends ResourceTaskType {
    parentImpliedTaskId: string | null;
    isResourcedTask: boolean;
    resourcedFromTask: {
        mission: {
            userId: string | null;
            rights: {
                write: boolean;
            };
        } | null;
    } | null;
}

type ImpliedTaskListProps = {
    missionId: string;
    missionUserId: string | null;
    specifiedTask: {
        id: string | null;
    } | null;
    impliedTasks: GetMissionTasksQuery['tasks'] | null;
    impliedSubTasks: GetMissionTasksQuery['tasks'] | null;
    onTaskClick: (taskId: string) => void;
    onTaskEditClick: (taskId: string) => void;
    onRejectedTaskClick: (rejectedTaskId: string) => void;
    shimmerLines: number;
    onImpliedTaskDrag: (dragged: TaskSummaryItem) => void;
    onImpliedTaskDrop: (
        targetSpecifiedId: string,
        targetImpliedId: string | null
    ) => void;
    onImpliedTaskMove: (
        source: TaskSummaryItem,
        targetSpecifiedId: string,
        targetImpliedId: string | null
    ) => void;
    onCommentsClick: (taskId: string) => void;
    onAttachmentsClick: (taskId: string) => void;
    missionAccess: Access;
    filters?: TaskFilters;
    highlightImpliedTaskId?: string | null | undefined;
    tileColumnNames: TaskTileColumn[];
};

function ImpliedTaskList(props: ImpliedTaskListProps): JSX.Element {
    const { currentTenantCode, currentFinancialYearCode } = useStateContext();

    const { expandTask, collapseTask } = useTaskExpandActionsContext();
    const expandedTaskIds = useTaskExpandContext();

    const { currentTheme } = useThemes();
    const { width } = useViewport();

    const { getStylesForResourceCoin } = useResourceCoinStyles(
        props.missionUserId
    );

    const { hasFilters, filteredImpliedTasks } = useTaskFilters(
        props.filters,
        props.impliedTasks
    );

    const { updateTask, busyTaskIds } = useInlineTaskUpdater(
        props.missionUserId
    );

    const {
        sortColumn,
        sortIsDesc,
        sortedItems: sortedImpliedTasks,
        setSortColumnName,
    } = useSorter(filteredImpliedTasks, 'sequence', false, false);

    const canReorder =
        props.missionAccess.write &&
        sortColumn === 'sequence' &&
        !sortIsDesc &&
        !hasFilters;

    const handleTaskClick = (taskId: string | null): void => {
        if (props.onTaskClick && taskId) {
            props.onTaskClick(taskId);
        }
    };

    const handleImpliedTaskEditClick = (taskId: string | null): void => {
        if (props.onTaskEditClick && taskId) {
            props.onTaskEditClick(taskId);
        }
    };

    const onRenderPersonaCoin = (
        resourceTask: SubTaskType,
        size: PersonaSize
    ): JSX.Element => {
        return (
            <InternalLink
                scene="Mission"
                financialYearCode={currentFinancialYearCode}
                tenantCode={currentTenantCode}
                teamCode={resourceTask.mission?.team?.code}
                missionId={resourceTask.mission?.id}
            >
                <ResourceTooltip
                    resourcedTask={{
                        ...resourceTask,
                    }}
                    showStatus
                >
                    <PersonaCoin
                        onRenderPersonaCoin={undefined}
                        onClick={(ev) => {
                            if (resourceTask.id && resourceTask.utcRejected) {
                                ev.preventDefault();
                                props.onRejectedTaskClick(resourceTask.id);
                            }
                        }}
                        hidePersonaDetails
                        size={size}
                        text={resourceTask.resource?.displayName || ''}
                        imageUrl={photoService.getImageUrl(
                            resourceTask.resource?.userId
                        )}
                        styles={getStylesForResourceCoin(resourceTask)}
                    />
                </ResourceTooltip>
            </InternalLink>
        );
    };

    const sortedBySequence = (props.impliedTasks || []).sort(
        sorters.sequenceSorter
    );

    const displaySequences: Record<string, number> = {};
    for (let i = 0; i < sortedBySequence.length; i++) {
        const id = sortedBySequence[i as number].id;
        if (id) {
            displaySequences[id as string] = i + 1;
        }
    }

    const renderSequence = (
        impliedTask: ImpliedTaskType
    ): JSX.Element | string | null => {
        const isSubTask = impliedTask.parentTaskId !== props?.specifiedTask?.id;
        return !isSubTask && impliedTask.id !== null ? (
            <DetailsListCellItemContainer>
                <Text variant="small">{displaySequences[impliedTask.id]}</Text>
            </DetailsListCellItemContainer>
        ) : null;
    };

    const renderNameColumn = (
        impliedTask: ImpliedTaskType | SubTaskType
    ): JSX.Element => {
        const isSubTask =
            (impliedTask as ImpliedTaskType)?.parentTaskId !==
            props?.specifiedTask?.id;

        const subTask = isSubTask ? (impliedTask as SubTaskType) : null;

        const missionAccess =
            (impliedTask as ResourceTaskType)?.mission?.rights ||
            props.missionAccess;

        let onClick: (() => void) | undefined = undefined;

        if (isSubTask && impliedTask.utcRejected) {
            onClick = () => {
                if (impliedTask?.id) {
                    props.onRejectedTaskClick(impliedTask.id);
                }
            };
        } else {
            onClick = () => handleTaskClick(impliedTask.id);
        }

        const handleNameChanged = async (newName: string | null) => {
            await updateTask(impliedTask.id, {
                ...impliedTask,
                name: newName,
            });
        };

        if (isSubTask) {
            return (
                <Stack
                    horizontal
                    verticalFill
                    tokens={{ childrenGap: 16 }}
                    verticalAlign="center"
                >
                    <Stack.Item align="center">
                        {subTask?.isResourcedTask && subTask.resource && (
                            <Persona
                                hidePersonaDetails
                                size={PersonaSize.size24}
                                onRenderPersonaCoin={() =>
                                    onRenderPersonaCoin(
                                        subTask,
                                        PersonaSize.size24
                                    )
                                }
                            />
                        )}

                        {!(subTask?.isResourcedTask && subTask.resource) && (
                            <Icon
                                iconName="Combine"
                                title="Merged task"
                                styles={{
                                    root: { marginLeft: 8, marginRight: 6 },
                                }}
                            />
                        )}
                    </Stack.Item>

                    <Stack.Item align="center" grow>
                        <ImpliedTaskNameBlock
                            impliedTask={impliedTask}
                            missionAccess={missionAccess}
                            onClick={onClick}
                            onNameChanged={handleNameChanged}
                            isBusy={busyTaskIds.includes(impliedTask.id || '')}
                        />
                    </Stack.Item>
                </Stack>
            );
        } else {
            return (
                <DetailsListCellItemContainer>
                    <ImpliedTaskNameBlock
                        highlight={
                            props.highlightImpliedTaskId === impliedTask.id
                        }
                        impliedTask={impliedTask}
                        missionAccess={missionAccess}
                        onClick={onClick}
                        onNameChanged={handleNameChanged}
                        isBusy={busyTaskIds.includes(impliedTask.id || '')}
                    />
                </DetailsListCellItemContainer>
            );
        }
    };

    const buttonStyles: IButtonStyles = {
        root: {
            color: currentTheme.palette.themeLighter,
            selectors: {
                '&:hover': {
                    color: currentTheme.palette.themePrimary,
                },
            },
        },
        icon: {
            margin: 0,
        },
    };

    const renderResourcedFrom = (
        impliedTask: ImpliedTaskType
    ): JSX.Element | null => {
        const containerClassName = mergeStyles({
            minWidth: 42,
        });

        return impliedTask.missionId === props.missionId ? (
            <DetailsListCellItemContainer>
                <div className={containerClassName}>
                    <ResourcedFromCoin
                        task={impliedTask}
                        missionAccess={props.missionAccess}
                        onTaskEditClick={
                            props.missionAccess.write
                                ? () =>
                                      props.onTaskEditClick(
                                          impliedTask.id || ''
                                      )
                                : null
                        }
                    />
                </div>
            </DetailsListCellItemContainer>
        ) : null;
    };

    const renderCommentIcon = (
        impliedTask: ImpliedTaskType
    ): JSX.Element | null => {
        const commentButton = (
            <ActionButton
                text={impliedTask.commentCount.toFixed(0)}
                iconProps={{ iconName: 'Message' }}
                onClick={() =>
                    impliedTask.id
                        ? props.onCommentsClick(impliedTask.id)
                        : null
                }
                styles={{
                    label: {
                        whiteSpace: 'nowrap',
                        fontSize: FontSizes.xSmall,
                        color: impliedTask.commentCount
                            ? undefined
                            : currentTheme.palette.themeLighter,
                    },
                    root: {
                        height: 32, // Same as IconButton
                        padding: 0,
                    },
                    icon: {
                        margin: 0,
                        color: impliedTask.commentCount
                            ? undefined
                            : currentTheme.palette.themeLighter,
                    },
                }}
            />
        );

        return (
            <DetailsListCellItemContainer>
                {impliedTask.lastComment ? (
                    <CommentTooltipHost
                        authorName={impliedTask.lastComment.username}
                        content={impliedTask.lastComment.text}
                        dateTime={impliedTask.lastComment.utcCreated}
                    >
                        {commentButton}
                    </CommentTooltipHost>
                ) : (
                    commentButton
                )}
            </DetailsListCellItemContainer>
        );
    };

    const renderMoreOptions = (
        impliedTask: ImpliedTaskType
    ): JSX.Element | null => {
        const isSubTask = impliedTask.parentTaskId !== props?.specifiedTask?.id;

        return props.missionAccess.write && !isSubTask && !!impliedTask ? (
            <DetailsListCellItemContainer>
                <ImpliedTaskMoreOptionsButton
                    impliedTask={impliedTask}
                    missionUserId={props.missionUserId}
                    buttonStyles={buttonStyles}
                    onEditClick={props.onTaskEditClick}
                    onImpliedTaskMove={props.onImpliedTaskMove}
                />
            </DetailsListCellItemContainer>
        ) : null;
    };

    const renderResources = (impliedTask: ImpliedTaskType): JSX.Element => {
        const checklistTasks =
            props.impliedSubTasks?.filter(
                (t) => !t.isDuplicate && t.parentTaskId === impliedTask.id
            ) || [];

        return (
            <ImpliedTaskListResources
                impliedTask={impliedTask}
                missionUserId={props.missionUserId}
                isReadOnly={!props.missionAccess.write}
                hasChecklist={checklistTasks.length > 0}
                onTaskEdit={() => props.onTaskEditClick(impliedTask.id || '')}
            />
        );
    };

    const renderEffortWeight = (task: ImpliedTaskType): JSX.Element | null => {
        if (task.missionId !== props.missionId) {
            // Don't render for resources
            return null;
        }

        const handleWeightChanged = (weight: number) =>
            updateTask(task.id, { ...task, effortWeight: weight });

        return (
            <DetailsListCellItemContainer>
                <EffortWeightingRating
                    isBusy={busyTaskIds.includes(task.id || '')}
                    readOnly={!props.missionAccess.write}
                    value={task.effortWeight}
                    onChange={handleWeightChanged}
                />
            </DetailsListCellItemContainer>
        );
    };

    const renderCostWeight = (task: ImpliedTaskType): JSX.Element | null => {
        if (task.missionId !== props.missionId) {
            // Don't render for resources
            return null;
        }

        const handleWeightChanged = (weight: number) =>
            updateTask(task.id, { ...task, costWeight: weight });

        return (
            <DetailsListCellItemContainer>
                <CostWeightingRating
                    isBusy={busyTaskIds.includes(task.id || '')}
                    readOnly={!props.missionAccess.write}
                    value={task.costWeight}
                    onChange={handleWeightChanged}
                />
            </DetailsListCellItemContainer>
        );
    };

    const renderProgressColumn = (
        task: ImpliedTaskType | SubTaskType
    ): JSX.Element | null => {
        const mission = (task as ResourceTaskType)?.mission || {
            rights: props.missionAccess,
        };

        const onTaskProgressChanged = (taskUpdates: {
            utcPostponed: string | null;
            utcCancelled: string | null;
            utcAtRisk: string | null;
            percentComplete: number;
            done: string | null;
        }) => updateTask(task.id, { ...task, ...taskUpdates });

        return (
            <ImpliedTaskListProgressColumn
                allowInlineEdit
                task={task}
                mission={mission}
                onChange={onTaskProgressChanged}
                isBusy={!!task.id && busyTaskIds.includes(task.id)}
            />
        );
    };

    const renderStatusIndicator = (
        task: ImpliedTaskType | SubTaskType
    ): JSX.Element | null => {
        return (
            <Stack verticalAlign="center" verticalFill>
                <TaskProgressIndicator
                    task={task}
                    showStatus={false}
                    hidePercentage={false}
                    compact
                />
            </Stack>
        );
    };

    let columns: IColumn[] = [
        {
            key: 'sequence',
            name: '#',
            fieldName: 'sequence',
            minWidth: 24,
            maxWidth: 24,
            isSorted: sortColumn === 'sequence',
            isSortedDescending: sortColumn === 'sequence' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('sequence'),
            isMultiline: false,
            onRender: renderSequence,
        },
        {
            key: 'name',
            name: 'Implied Tasks',
            fieldName: 'name',
            isMultiline: true,
            isRowHeader: true,
            minWidth: 100,
            isResizable: true,
            isSorted: sortColumn === 'name',
            isSortedDescending: sortColumn === 'name' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('name', true),
            onRender: renderNameColumn,
        },
        {
            key: 'resources',
            name: 'Resources',
            fieldName: 'resources',
            minWidth: 90,
            maxWidth: 140,
            isResizable: false,
            isMultiline: false,
            isPadded: false,
            onRender: renderResources,
        },
        {
            key: 'effortWeight',
            name: 'Effort',
            fieldName: 'effortWeight',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            isMultiline: false,
            isPadded: false,
            isSorted: sortColumn === 'effortWeight',
            isSortedDescending: sortColumn === 'effortWeight' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('effortWeight', false),
            onRender: renderEffortWeight,
        },
        {
            key: 'costWeight',
            name: 'Cost',
            fieldName: 'costWeight',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            isMultiline: false,
            isPadded: false,
            isSorted: sortColumn === 'costWeight',
            isSortedDescending: sortColumn === 'costWeight' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('costWeight', false),
            onRender: renderCostWeight,
        },
        {
            key: 'start',
            name: 'Start',
            fieldName: 'start',
            minWidth: 80,
            maxWidth: 80,
            isMultiline: false,
            isSorted: sortColumn === 'start',
            isSortedDescending: sortColumn === 'start' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('start'),
            onRender: (task: ImpliedTaskType | SubTaskType): JSX.Element => {
                return (
                    <ImpliedTaskListDateColumn
                        task={task}
                        dateType="start"
                        allowInlineEdit
                        onTaskEditClick={() =>
                            handleImpliedTaskEditClick(task.id)
                        }
                        missionAccess={
                            // If this task doesn't have a mission, it still belongs to this mission
                            (task as ResourceTaskType)?.mission?.rights ||
                            props.missionAccess
                        }
                        onDateChanged={(date: string | null) =>
                            updateTask(task.id, { ...task, start: date })
                        }
                        isBusy={!!task.id && busyTaskIds.includes(task.id)}
                    />
                );
            },
        },
        {
            key: 'due',
            name: 'Due',
            fieldName: 'due',
            minWidth: 80,
            maxWidth: 80,
            isMultiline: false,
            isSorted: sortColumn === 'due',
            isSortedDescending: sortColumn === 'due' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('due'),
            onRender: (task: ImpliedTaskType | SubTaskType): JSX.Element => {
                return (
                    <ImpliedTaskListDateColumn
                        task={task}
                        dateType="due"
                        allowInlineEdit
                        onTaskEditClick={() =>
                            handleImpliedTaskEditClick(task.id)
                        }
                        missionAccess={
                            (task as SubTaskType)?.mission?.rights ||
                            props.missionAccess
                        }
                        onDateChanged={(date: string | null) =>
                            updateTask(task.id, { ...task, due: date })
                        }
                        isBusy={!!task.id && busyTaskIds.includes(task.id)}
                    />
                );
            },
        },
        {
            key: 'done',
            name: 'Done',
            fieldName: 'done',
            minWidth: 120,
            maxWidth: 120,
            isMultiline: false,
            isPadded: true,
            isSorted: sortColumn === 'percentComplete',
            isSortedDescending: sortColumn === 'percentComplete' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('percentComplete'),
            onRender: renderProgressColumn,
        },
        {
            key: 'resourcedFrom',
            name: 'Resourced From',
            fieldName: 'resourcedFrom',
            minWidth: 42,
            maxWidth: 42,
            isIconOnly: true,
            isPadded: false,
            onRender: renderResourcedFrom,
        },
        {
            key: 'comment',
            name: 'Comment',
            fieldName: 'comment',
            minWidth: 32,
            maxWidth: 32,
            isIconOnly: true,
            isPadded: false,
            onRender: renderCommentIcon,
        },
        {
            key: 'moreOptions',
            name: 'More Options',
            fieldName: 'moreOptions',
            minWidth: 24,
            maxWidth: 24,
            isIconOnly: true,
            isPadded: true,
            onRender: renderMoreOptions,
        },
    ];

    if (!props.tileColumnNames.some((c) => c === 'effortWeight')) {
        columns = columns.filter((c) => c.key !== 'effortWeight');
    }

    if (!props.tileColumnNames.some((c) => c === 'costWeight')) {
        columns = columns.filter((c) => c.key !== 'costWeight');
    }

    if (width < 1000) {
        columns = columns.filter((c) => c.key !== 'resourcedFrom');
    }

    if (width < 900) {
        columns = columns.filter((c) => c.key !== 'start');
        columns = columns.filter((c) => c.key !== 'comment');
    }

    if (width < 720) {
        columns = columns.filter((c) => c.key !== 'resources');
    }

    if (width < 620) {
        columns = columns.filter(
            (c) => c.key === 'name' || c.key === 'moreOptions'
        );

        columns.splice(1, 0, {
            key: 'statusIndicator',
            name: '',
            fieldName: '',
            minWidth: 32,
            isIconOnly: true,
            isPadded: false,
            onRender: renderStatusIndicator,
        });
    }

    const impliedAndResourcedTasks: SubTaskType[] = [];

    if (props.impliedSubTasks) {
        // Add duplicate tasks to the sub tasks array.
        props.impliedSubTasks
            .sort(sorters.parentIdSorter)
            .filter((t) => t.isDuplicate)
            .forEach((t) => {
                if (t.isDuplicate) {
                    impliedAndResourcedTasks.push({
                        ...t,
                        isResourcedTask: false,
                        parentImpliedTaskId: t?.parentTaskId,
                        resource: null,
                        mission: null,
                    });
                }
            });
    }

    // Add the resourced tasks to the sub tasks array.
    sortedImpliedTasks.sort(sorters.parentIdSorter).forEach((t) => {
        (t?.resourcedTasks || [])
            .slice()
            .filter((rt) => !rt.utcResourceRemoved)
            .sort((r1, r2) =>
                r1.resourceIsPrimary === r2.resourceIsPrimary
                    ? 0
                    : r1.resourceIsPrimary
                      ? -1
                      : 1
            )
            .forEach((rt) => {
                impliedAndResourcedTasks.push({
                    ...rt,
                    isResourcedTask: true,
                    parentImpliedTaskId: t?.id,
                    resourcedFromTask: {
                        mission: {
                            userId: props.missionUserId,
                            rights: {
                                write: false, // TODO
                            },
                        },
                    },
                });
            });
    });

    const subTasksSorted = orderBy(
        impliedAndResourcedTasks,
        'parentImpliedTaskId'
    );

    // The sorted doesn't support a secondasy sort, so we need to add the done sort in here.
    const extendedSort =
        sortColumn === 'percentComplete'
            ? orderBy(
                  sortedImpliedTasks,
                  ['done', 'percentComplete'],
                  [sortIsDesc ? 'desc' : 'asc', sortIsDesc ? 'desc' : 'asc']
              )
            : sortedImpliedTasks;

    const groups: IGroup[] = extendedSort?.map((t) => {
        const firstSubTaskIndex = subTasksSorted.findIndex(
            (st) => st.parentImpliedTaskId === t.id
        );

        const subTaskCount = subTasksSorted?.filter(
            (st) => st.parentImpliedTaskId === t.id
        ).length;

        return {
            key: `group${t.id}`,
            name: t.name,
            columns: columns,
            startIndex: firstSubTaskIndex,
            isDropEnabled: true,
            count: subTaskCount,
            level: 0,
            isCollapsed: t.id ? !expandedTaskIds.includes(t.id) : false,
            children: [],
            data: t,
        };
    }) as IGroup[];

    const dragEnterClass = mergeStyles({
        backgroundColor: currentTheme.palette.neutralLight,
    });

    const getDragDropEvents = (): IDragDropEvents => {
        return {
            canDragGroups: canReorder,
            canDrop: (dropContext?: IDragDropContext): boolean => {
                return dropContext?.isGroup || false;
            },
            canDrag: (item?: IGroup): boolean => {
                if (!item) {
                    return false;
                }
                return (
                    (item as IGroup).data?.parentTaskId ===
                    props.specifiedTask?.id
                );
            },
            onDragEnter: (): string => {
                return dragEnterClass;
            },
            onDragLeave: (): void => {
                return;
            },
            onDrop: (item?: IGroup): void => {
                if (props.specifiedTask?.id) {
                    props.onImpliedTaskDrop(
                        props.specifiedTask?.id,
                        item?.data
                            ? ((item as IGroup)?.data as Task)?.id || null
                            : null
                    );
                }
            },
            onDragStart: (item?: IGroup): void => {
                props.onImpliedTaskDrag((item as IGroup)?.data as Task);
            },
        };
    };

    // This is the table header. We need to hide the expand/collapse button as it's confusing
    const onRenderDetailsHeader = (
        detailsHeaderProps?: IDetailsHeaderProps,
        defaultRender?: IRenderFunction<IDetailsHeaderProps>
    ): JSX.Element | null => {
        if (defaultRender && detailsHeaderProps) {
            const newProps = Object.assign({}, detailsHeaderProps);
            newProps.styles = {
                collapseButton: {
                    display: 'none !important',
                },
                root: {
                    paddingTop: 4,
                },
            };
            return defaultRender({ ...newProps });
        }

        return null;
    };

    // This is an implied task row
    const onRenderGroupHeader = (
        groupDividerProps: IDetailsGroupDividerProps | undefined
    ) => {
        if (!groupDividerProps?.group?.data) {
            // This is the empty group to show the placeholder for drag/drop.
            return (
                <GroupHeader
                    {...groupDividerProps}
                    groupLevel={0}
                    styles={{
                        expandIsCollapsed: { display: 'none' },
                    }}
                    onRenderTitle={(a) => {
                        return (
                            <Text
                                variant="small"
                                style={{
                                    fontStyle: 'italic',
                                    padding: '6px 12px',
                                }}
                            >
                                {a?.group?.name}
                            </Text>
                        );
                    }}
                />
            );
        }

        const showExpand = impliedAndResourcedTasks.some(
            (sub) =>
                sub.parentImpliedTaskId === groupDividerProps?.group?.data?.id
        );

        return (
            <ImpliedTaskGroupHeader
                rowProps={groupDividerProps}
                showExpand={showExpand}
                dragDropEvents={getDragDropEvents()}
            />
        );
    };

    // const handleRenderRow = (
    //     detailsRowProps?: IDetailsRowProps
    // ): JSX.Element | null => {
    //     const customStyles: Partial<IDetailsRowStyles> = {};
    //     if (detailsRowProps) {
    //         customStyles.root = {
    //             borderBottom: `1px solid ${currentTheme.semanticColors.bodyDivider}`,
    //         };
    //         return <DetailsRow {...detailsRowProps} styles={customStyles} />;
    //     }
    //     return null;
    // };

    const selection = new Selection();
    selection.setItems([]);

    const blankGroup = {
        key: 'notasks',
        name: hasFilters
            ? 'No implied tasks found matching the current filter.'
            : 'No implied tasks found.',
        startIndex: 0,
        isDropEnabled: true,
        count: 0,
        level: 0,
        columns: [],
        isCollapsed: true,
        children: [],
        data: null,
    } as IGroup;

    const onToggleCollapse = (group: IGroup) => {
        if (group.data?.id) {
            if (expandedTaskIds.includes(group.data?.id)) {
                collapseTask(group.data?.id);
            } else {
                expandTask(group.data?.id);
            }
        }
    };

    const groupsToDisplay =
        !groups || groups.length > 0 ? groups : [blankGroup];

    return (
        <ShimmeredDetailsList
            shimmerLines={props.shimmerLines}
            enableShimmer={props.impliedTasks === null}
            items={subTasksSorted}
            groups={groupsToDisplay}
            columns={columns}
            onRenderDetailsHeader={onRenderDetailsHeader}
            dragDropEvents={getDragDropEvents()}
            cellStyleProps={{
                cellLeftPadding: 4,
                cellRightPadding: 4,
                cellExtraRightPadding: 4,
            }}
            groupProps={{
                showEmptyGroups: true,
                onRenderHeader: onRenderGroupHeader,
                headerProps: {
                    onToggleCollapse: onToggleCollapse,
                    styles: {
                        groupHeaderContainer: {
                            height: 'auto',
                        },
                    },
                },
            }}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            constrainMode={ConstrainMode.unconstrained}
            selection={selection}
            compact={true}
            //onRenderRow={handleRenderRow}
            onShouldVirtualize={(): boolean => false}
        />
    );
}

function ImpliedTaskGroupHeader(props: {
    rowProps: IDetailsGroupDividerProps | undefined;
    dragDropEvents: IDragDropEvents;
    showExpand: boolean;
}): JSX.Element {
    const newProps = Object.assign({}, props.rowProps);
    newProps.groupLevel = 0;
    newProps.indentWidth = 0;
    newProps.selectionMode = SelectionMode.none;

    const { currentTheme } = useThemes();

    return (
        <GroupHeader
            {...newProps}
            styles={{
                groupHeaderContainer: {
                    height: 'auto',

                    borderBottom: `1px solid ${currentTheme.semanticColors.bodyDivider}`,
                },
                expandIsCollapsed: {
                    display: props.showExpand ? 'block' : 'none',
                },
                title: {
                    padding: 0,
                },
            }}
            onRenderTitle={(
                titleProps: IGroupHeaderProps | undefined
            ): JSX.Element => (
                <DetailsRow
                    {...newProps}
                    styles={{
                        root: {
                            minWidth: 'none',
                        },
                    }}
                    dragDropEvents={props.dragDropEvents}
                    item={titleProps?.group?.data}
                    compact={true}
                    itemIndex={titleProps?.groupIndex || 0}
                    aria-busy={false}
                />
            )}
        />
    );
}

function ImpliedTaskNameBlock(props: {
    impliedTask: ImpliedTaskType | SubTaskType;
    missionAccess: Access;
    onClick: () => void;
    onNameChanged: (newName: string) => Promise<void>;
    isBusy: boolean;
    highlight?: boolean;
}): JSX.Element {
    const { impliedTask, missionAccess, onClick } = props;

    const { isNameReadOnly } = useTaskEditRules(impliedTask, missionAccess);

    const classNames = mergeStyleSets({
        link: {
            display: 'flex',
            justifyContent: 'center',
        },
    });

    const textRef = useRef<HTMLElement>(null);

    return (
        <Shimmer isDataLoaded={!props.isBusy} width="100%" height={16}>
            {props.highlight && (
                <Coachmark target={textRef.current} styles={{}} />
            )}

            <EditibleText
                isReadOnly={isNameReadOnly}
                text={impliedTask.name?.trim() || ''}
                dialogTitle="Edit Task Name"
                onUpdateClick={props.onNameChanged}
                disableOnMobile
            >
                <Link
                    disabled={!onClick}
                    onClick={onClick}
                    className={classNames.link}
                >
                    <Text
                        variant="smallPlus"
                        className="line-clamp3"
                        style={{
                            whiteSpace: 'pre-line',
                            minHeight: 16,
                            fontStyle: !impliedTask.name?.trim()
                                ? 'italic'
                                : 'normal',
                        }}
                        title={impliedTask?.name || ''}
                        block
                    >
                        <span ref={textRef}>
                            {impliedTask.name?.trim()
                                ? impliedTask.name?.trim()
                                : 'Untitled'}
                        </span>
                    </Text>
                </Link>
            </EditibleText>
        </Shimmer>
    );
}

export function useResourceCoinStyles(missionUserId: string | null) {
    const { currentTheme } = useThemes();
    const { currentFinancialYearCode } = useStateContext();

    const getStylesForResourceCoin = (
        resourceTask: {
            utcRejected: string | null;
            utcAccepted: string | null;
            resource: {
                userId: string | null;
                userMissionFYs: {
                    code: string | null;
                }[];
            } | null;
        } | null,
        withBorder?: boolean
    ): Partial<IPersonaCoinStyles> => {
        return {
            coin: {
                cursor: resourceTask?.utcRejected ? 'pointer' : undefined,
                borderRadius: '50%',
                border: withBorder
                    ? `solid 2px ${currentTheme.palette.white}`
                    : undefined,
                backgroundColor: resourceTask?.utcRejected
                    ? TaskStatusColours.rejected
                    : undefined,
            },
            imageArea: {
                opacity:
                    resourceTask?.resource?.userId &&
                    resourceTask?.resource?.userId !== missionUserId &&
                    resourceTask?.resource?.userMissionFYs.find(
                        (fy) =>
                            fy.code?.toUpperCase() ===
                            currentFinancialYearCode?.toUpperCase()
                    ) &&
                    !resourceTask?.utcAccepted
                        ? '0.7'
                        : '1',
            },
            initials: {},
        };
    };

    return { getStylesForResourceCoin };
}

export default ImpliedTaskList;
