import React, { FormEvent, useState } from 'react';
import compact from 'lodash/compact';
import { ActionButton, IconButton, Stack, TextField } from '@fluentui/react';
import { sorters } from '../data/sorters';
import { SortableContainer } from './SortableContainer';
import { Access } from '../data/extendedTypes';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Guid } from 'guid-typescript';
import { DragHandlerButton } from './DragHandlerButton';

export type EditablePhrase = {
    id: string;
    text: string | null;
    sequence: number;
    isNew: boolean;
    isDeleted: boolean;
    version: string | null;
};

export function MissionPhraseEditableList(props: {
    phrases: EditablePhrase[];
    onItemsUpdated: (phrases: EditablePhrase[]) => void;
    missionAccess: Access;
    addButtonText: string;
}): JSX.Element {
    const { phrases } = props;

    const [activeDragItemId, setActiveDragItemId] = useState<string | null>();

    const handleDragging = (id: string | null) => setActiveDragItemId(id);

    const handleDropped = (id: string | null, newIndex: number) => {
        if (!id) {
            return;
        }

        const sorted = (phrases || []).slice().sort(sorters.sequenceSorter);

        const movedItem = sorted.find((m) => m.id === id);
        const remainingItems = sorted.filter((f) => f.id !== id);

        const reorderedItems = movedItem
            ? [
                  ...remainingItems.slice(0, newIndex),
                  movedItem,
                  ...remainingItems.slice(newIndex),
              ]
            : remainingItems;

        let sequence = 0;
        const updated = reorderedItems.map((g) => {
            return {
                ...g,
                sequence: sequence++,
            };
        });

        props.onItemsUpdated(updated);
    };

    const handleNameChanged = (phraseId: string, newValue: string): void => {
        const phrase = phrases?.find((g) => g.id === phraseId);
        const updated: EditablePhrase[] = phrase
            ? [
                  {
                      ...phrase,
                      text: newValue,
                  },
                  ...(phrases?.filter((g) => g.id !== phraseId) || []),
              ]
            : phrases;

        props.onItemsUpdated(updated);
    };

    const handleDeleteClick = (phraseId: string, isRestore: boolean): void => {
        const phrase = phrases?.find((g) => g.id === phraseId);
        const updated: EditablePhrase[] = phrase
            ? [
                  {
                      ...phrase,
                      isDeleted: !isRestore,
                  },
                  ...(phrases?.filter((g) => g.id !== phraseId) || []),
              ]
            : phrases;

        props.onItemsUpdated(updated);
    };

    const handleAddButtonClick = () => {
        const updated: EditablePhrase[] = [
            ...(phrases || []),
            {
                id: Guid.create().toString(),
                text: '',
                isDeleted: false,
                isNew: true,
                version: '',
                sequence:
                    (phrases || []).length > 0
                        ? Math.max(...(phrases || []).map((g) => g.sequence)) +
                          1
                        : 0,
            },
        ];

        props.onItemsUpdated(updated);
    };

    const sortedPhrases = phrases?.sort(sorters.sequenceSorter);

    return (
        <SortableContainer
            ids={compact(sortedPhrases?.map((g) => g.id))}
            onDragging={handleDragging}
            onDropped={handleDropped}
        >
            <Stack
                tokens={{ childrenGap: 8 }}
                styles={{ root: { marginTop: 8 } }}
            >
                {sortedPhrases?.map((p) => (
                    <Stack.Item key={p.id}>
                        <SortablePhrase
                            missionAccess={props.missionAccess}
                            phrase={p}
                            isActive={activeDragItemId === p.id}
                            onDeleteClick={() => handleDeleteClick(p.id, false)}
                            onRestoreClick={() => handleDeleteClick(p.id, true)}
                            onNameChanged={(newValue: string) =>
                                handleNameChanged(p.id, newValue)
                            }
                        />
                    </Stack.Item>
                ))}
                {props.missionAccess.write && (
                    <Stack.Item>
                        <ActionButton
                            text={props.addButtonText}
                            onClick={handleAddButtonClick}
                            iconProps={{ iconName: 'Add' }}
                        />
                    </Stack.Item>
                )}
            </Stack>
        </SortableContainer>
    );
}

export function SortablePhrase(props: {
    isActive: boolean;
    missionAccess: Access;
    onNameChanged: (newValue: string) => void;
    onDeleteClick: () => void;
    onRestoreClick: () => void;
    phrase: EditablePhrase;
}): JSX.Element {
    const { phrase, missionAccess } = props;

    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: props.phrase.id || '' });

    const handleNameChanged = (
        _ev: FormEvent,
        newValue?: string | undefined
    ) => {
        props.onNameChanged(newValue || '');
    };

    return (
        <div
            ref={setNodeRef}
            style={{
                transformOrigin: '0 0',
                opacity: props.isActive ? 0.4 : 1,
                transform: CSS.Translate.toString(transform),
                transition: transition || undefined,
            }}
        >
            <Stack horizontal>
                <Stack.Item>
                    <DragHandlerMemo
                        hidden={!missionAccess.write}
                        handleListeners={listeners}
                        handleAttributes={attributes}
                        iconName="GripperDotsVertical"
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        defaultValue={phrase.text || ''}
                        multiline
                        autoAdjustHeight
                        onChange={handleNameChanged}
                        readOnly={!missionAccess.write}
                        disabled={phrase.isDeleted}
                    />
                </Stack.Item>
                <Stack.Item>
                    {!phrase.isDeleted && missionAccess.write && (
                        <IconButton
                            title="Delete"
                            iconProps={{
                                iconName: 'Delete',
                            }}
                            onClick={props.onDeleteClick}
                        />
                    )}
                    {phrase.isDeleted && missionAccess.write && (
                        <IconButton
                            title="Restore"
                            iconProps={{ iconName: 'Undo' }}
                            onClick={props.onRestoreClick}
                        />
                    )}
                </Stack.Item>
            </Stack>
        </div>
    );
}

const DragHandlerMemo = React.memo(DragHandlerButton);
