import * as React from 'react';
import {
    DefaultPalette,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IRawStyle,
    SelectionMode,
    Shimmer,
    ShimmerElementType,
    ShimmerElementsGroup,
    Text,
    mergeStyleSets,
} from '@fluentui/react';
import { useCallback, useEffect, useState } from 'react';
import {
    GroupedVerticalBarChart,
    IGroupedVerticalBarChartData,
} from '@fluentui/react-charting';
import dayjs from 'dayjs';
import { ChartTableToggleButton } from './ChartTableToggleButton';
import {
    GetAllMeasuresQuery,
    useGetAllMeasuresLazyQuery,
} from '../../../data/types';
import { ExtractQueryType } from '../../../data/extendedTypes';

type Usage = {
    tenantDescription: string;
    divisionName: string;
    measureCount: number;
    updatedMeasureCount: number;
};

export function MeasureUsage(props: {
    tenants?: {
        id: string | null;
        code: string | null;
        description: string | null;
    }[];
    usagePeriodStart?: string;
    usagePeriodEnd?: string;
    groupBy: 'ByDivision' | 'ByTenant';
}): JSX.Element {
    const [getUsage] = useGetAllMeasuresLazyQuery({
        fetchPolicy: 'no-cache', // We don't want the historic results polluting the cache
    });

    const [view, setView] = useState<'Chart' | 'Table'>('Chart');
    const [isLoading, setIsLoading] = useState(true);
    const [usageData, setUsageData] = useState<Usage[]>([]);
    const [counts, setCounts] = useState<{
        measures: number;
        measuresUpdated: number;
    }>();

    const mapMeasureToUsage = useCallback(
        (
            measures: ExtractQueryType<GetAllMeasuresQuery, ['measures']>,
            tenantName: string | null | undefined,
            divisionName?: string | null | undefined
        ): Usage => {
            const updatedMeasureCount = measures.filter(
                (m) =>
                    m.lastAsOf &&
                    (dayjs(m.lastAsOf.asOfDate).isAfter(
                        props.usagePeriodStart
                    ) ||
                        dayjs(
                            m.lastAsOf.utcUpdated || m.lastAsOf.utcCreated
                        ).isAfter(props.usagePeriodStart))
            ).length;

            return {
                tenantDescription: tenantName || '',
                divisionName: divisionName || '',
                measureCount: measures.length,
                updatedMeasureCount: updatedMeasureCount,
            };
        },
        [props.usagePeriodStart]
    );

    const reloadUsage = useCallback(async () => {
        const newUsageData: Usage[] = [];

        setIsLoading(true);

        if (props.tenants && props.usagePeriodStart && props.usagePeriodEnd) {
            const results = await Promise.all(
                props.tenants?.map(async (t) => {
                    const d = await getUsage({
                        variables: {
                            tenantId: t.id || '',
                            teamId: null,
                            financialYearCode: null,
                            financialYearActiveDate: props.usagePeriodEnd
                                ? dayjs(props.usagePeriodEnd).toISOString()
                                : null,
                            forDateTime: props.usagePeriodEnd
                                ? dayjs(props.usagePeriodEnd).toISOString()
                                : null,
                            taskCategoryIds: null,
                        },
                    });

                    // Sort missions by division name
                    const measures = d.data?.measures
                        .slice()
                        .filter((m) => !m.isLinked)
                        .sort((m1, m2) =>
                            (
                                m1.mission?.team?.division?.name || ''
                            ).localeCompare(
                                m2.mission?.team?.division?.name || ''
                            )
                        );

                    if (measures?.length) {
                        if (props.groupBy === 'ByDivision') {
                            const divisionIds = [
                                ...new Set(
                                    measures.map(
                                        (m) => m.mission?.team?.division?.id
                                    )
                                ),
                            ];

                            return divisionIds
                                .filter((d) => d)
                                .map((did) => {
                                    const divMeasures = measures.filter(
                                        (d) =>
                                            d.mission?.team?.division?.id ===
                                            did
                                    );

                                    return mapMeasureToUsage(
                                        divMeasures,
                                        t.description || t.code,
                                        divMeasures[0].mission?.team?.division
                                            ?.name
                                    );
                                });
                        } else {
                            return mapMeasureToUsage(
                                measures,
                                t.description || t.code
                            );
                        }
                    }
                })
            );

            results
                .flatMap((r) => r)
                .forEach((dr) => {
                    if (dr) {
                        newUsageData.push(dr);
                    }
                });
        }

        setUsageData(newUsageData);

        setCounts({
            measures: newUsageData
                .map((u) => u.measureCount)
                .reduce((sum, current) => sum + current, 0),
            measuresUpdated: newUsageData
                .map((u) => u.updatedMeasureCount)
                .reduce((sum, current) => sum + current, 0),
        });

        setIsLoading(false);
    }, [
        getUsage,
        mapMeasureToUsage,
        props.groupBy,
        props.tenants,
        props.usagePeriodEnd,
        props.usagePeriodStart,
    ]);

    useEffect(() => {
        setUsageData([]);
        setCounts(undefined);
        reloadUsage();
    }, [
        props.tenants,
        props.usagePeriodStart,
        props.usagePeriodEnd,
        reloadUsage,
    ]);

    const classNames = mergeStyleSets({
        header: {
            paddingLeft: 8,
            paddingRight: 8,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        } as IRawStyle,
        headerLeft: {
            display: 'flex',
            flexDirection: 'column',
        } as IRawStyle,
        headerRight: {
            display: 'flex',
            flexDirection: 'row',
        } as IRawStyle,
    });

    const formatter = new Intl.NumberFormat(undefined, {
        style: 'percent',
        maximumFractionDigits: 1,
    });

    const updatedPercentage = counts?.measures
        ? formatter.format(counts.measuresUpdated / counts.measures)
        : null;

    return (
        <div>
            <div className={classNames.header}>
                <div className={classNames.headerLeft}>
                    <Text block variant="large">
                        Measures of Success
                    </Text>

                    {!isLoading && !!counts && (
                        <Text block variant="small">
                            Total Measures: {counts.measures}
                        </Text>
                    )}

                    {!isLoading && !!counts && (
                        <Text block variant="small">
                            Total Updated: {counts.measuresUpdated}{' '}
                            {updatedPercentage ? `(${updatedPercentage})` : ''}
                        </Text>
                    )}
                </div>
                <div>
                    <ChartTableToggleButton
                        selectedView={view}
                        onViewChanged={setView}
                    />
                </div>
            </div>

            <Shimmer
                styles={{
                    shimmerWrapper: {
                        marginTop: 8,
                        marginBottom: 8,
                    },
                }}
                isDataLoaded={!!usageData.length && !isLoading}
                customElementsGroup={
                    <div>
                        <ShimmerElementsGroup
                            width="100%"
                            height={380}
                            shimmerElements={[
                                {
                                    type: ShimmerElementType.gap,
                                    width: 16,
                                },
                                {
                                    type: ShimmerElementType.line,
                                    height: 500,
                                    width: '100%',
                                },
                                {
                                    type: ShimmerElementType.gap,
                                    width: 16,
                                },
                            ]}
                        />
                    </div>
                }
            >
                {!!usageData.length && !isLoading && (
                    <>
                        {view === 'Chart' ? (
                            <MissionOwnersUsageChart usageData={usageData} />
                        ) : (
                            <MissionOwnersUsageTable
                                usageData={usageData}
                                showDivision={props.groupBy === 'ByDivision'}
                            />
                        )}
                    </>
                )}
            </Shimmer>
        </div>
    );
}

function MissionOwnersUsageTable(props: {
    usageData: Usage[];
    showDivision: boolean;
}): JSX.Element {
    const { usageData } = props;

    const formatter = new Intl.NumberFormat(undefined, {
        style: 'percent',
        maximumFractionDigits: 1,
    });

    let columns: IColumn[] = [
        {
            key: 'tenantDescription',
            fieldName: 'tenantDescription',
            minWidth: 200,
            name: 'Tenant',
        },
        {
            key: 'divisionName',
            fieldName: 'divisionName',
            minWidth: 180,
            name: 'Division',
        },
        {
            key: 'measureCount',
            fieldName: 'measureCount',
            minWidth: 180,
            name: 'Measures',
        },
        {
            key: 'updatedMeasureCount',
            fieldName: 'updatedMeasureCount',
            minWidth: 180,
            name: 'Updated',
        },
        {
            key: 'updatedMeasureCountPct',
            fieldName: 'updatedMeasureCount',
            minWidth: 180,
            name: 'Updated %',
            onRender: (item: Usage) => {
                return formatter.format(
                    item.measureCount
                        ? item.updatedMeasureCount / item.measureCount
                        : 0
                );
            },
        },
    ];

    if (!props.showDivision) {
        columns = columns.filter((c) => c.key !== 'divisionName');
    }

    return (
        <DetailsList
            items={usageData}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            onShouldVirtualize={(): boolean => false}
            compact
        />
    );
}

function MissionOwnersUsageChart(props: { usageData: Usage[] }): JSX.Element {
    const { usageData } = props;

    const chartData: IGroupedVerticalBarChartData[] = usageData.map((item) => ({
        name:
            item.tenantDescription !== item.divisionName && item.divisionName
                ? `${item.tenantDescription} (${item.divisionName})`
                : item.tenantDescription,
        series: [
            {
                key: 'missionCount',
                legend: 'Total Measures',
                xAxisCalloutData: `Total Measures`,
                data: item.measureCount,
                color: DefaultPalette.accent,
            },
            {
                key: 'updatedCount',
                legend: 'Updated Measures',
                xAxisCalloutData: `Updated Measures`,
                data: item.updatedMeasureCount,
                color: DefaultPalette.blueMid,
            },
        ],
    }));

    return (
        <GroupedVerticalBarChart
            data={chartData}
            svgProps={{
                width: '100%',
            }}
            height={380}
            barwidth={64}
            wrapXAxisLables
            styles={{
                yAxis: {
                    display: 'none',
                },
            }}
        />
    );
}
