import { useCallback } from 'react';
import {
    MissionFilterType,
    ContributorFilterType,
    RightsFilterType,
    UserFilters,
} from '../components/UserFilterBar';
import { useGetUserTypes } from './useGetUserTypes';

type UserType = {
    id: string | null;
    accessEnabled: boolean;
    userRoles: {
        name: string;
        tenantId: string | null;
    }[];
};

export function useUserFilters<T extends UserType>(
    users: T[] | null,
    missions: MissionFilterType[] | null,
    contributors: ContributorFilterType[] | null,
    rights: RightsFilterType[] | null
): {
    getFilteredUsers: (filters: UserFilters) => T[];
} {
    const {
        isUserContributor,
        isUserMissionOwner,
        isClientAdmin,
        isGlobalAdmin,
        hasAssignedRights,
    } = useGetUserTypes(missions, contributors, rights);

    const getFilteredUsers = useCallback(
        (filters: UserFilters) => {
            if (!filters) {
                return users || [];
            }

            let filteredUsers = users
                ?.slice()
                ?.filter(
                    (u) =>
                        filters.isActive === null ||
                        (filters.isActive && u.accessEnabled) ||
                        (filters.isActive === false && !u.accessEnabled)
                );

            if (filters.userTypes) {
                filteredUsers = users?.filter((u) => {
                    const assignedRights = hasAssignedRights(u);
                    const isContributor = isUserContributor(u);
                    const isMissionOwner = isUserMissionOwner(u);

                    return (
                        (filters.userTypes?.isMissionOwner && isMissionOwner) ||
                        (filters.userTypes?.isContributor && isContributor) ||
                        (filters.userTypes?.isClientAdmin &&
                            isClientAdmin(u)) ||
                        (filters.userTypes?.isEditor &&
                            !isClientAdmin(u) &&
                            !isGlobalAdmin(u) &&
                            !isMissionOwner &&
                            !isContributor &&
                            assignedRights.write) ||
                        (filters.userTypes?.isViewer &&
                            !isClientAdmin(u) &&
                            !isGlobalAdmin(u) &&
                            !isMissionOwner &&
                            !isContributor &&
                            !assignedRights.write &&
                            assignedRights.read) ||
                        (filters.userTypes?.isWithoutRights &&
                            !assignedRights.read &&
                            !assignedRights.write &&
                            !isClientAdmin(u) &&
                            !isGlobalAdmin(u) &&
                            !isMissionOwner &&
                            !isContributor)
                    );
                });
            }

            if (filters.userTypes?.isWithoutRights) {
                filteredUsers = users?.filter((u) => {
                    const assignedRights = hasAssignedRights(u);
                    return (
                        !assignedRights.read &&
                        !assignedRights.write &&
                        !isUserMissionOwner(u) &&
                        !isUserContributor(u) &&
                        !isClientAdmin(u) &&
                        !isGlobalAdmin(u)
                    );
                });
            }

            return filteredUsers || [];
        },
        [
            hasAssignedRights,
            isClientAdmin,
            isGlobalAdmin,
            isUserContributor,
            isUserMissionOwner,
            users,
        ]
    );

    return {
        getFilteredUsers,
    };
}
