import React from 'react';
import {
    Link,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import dayjs from 'dayjs';
import { TaskCategoryLabel } from './TaskCategoryLabel';
import { ExtractQueryArrayType } from '../data/extendedTypes';
import { GetTaskQuery } from '../data/types';
import { useThemes } from '../hooks/useThemes';
import { useTaskStatus } from '../hooks/useTaskStatus';

import { useMissionNavigation } from '../hooks/useMissionNavigation';

type ResourcedTask = ExtractQueryArrayType<GetTaskQuery, ['task']>;

export function ResourcedFromDetailBox(props: {
    task: ResourcedTask;
}): JSX.Element | null {
    const { navigateToMission } = useMissionNavigation();

    const { currentTheme } = useThemes();

    const { task } = props;
    const { resourcedFromTask } = task;

    const taskStatus = useTaskStatus(task);
    const resourcedFromTaskStatus = useTaskStatus(
        resourcedFromTask || undefined
    );

    if (!task || !resourcedFromTask) {
        return null;
    }

    const hasChanges = task.utcAccepted && task.utcChangesPending;
    const nameWarning = hasChanges && task.name !== resourcedFromTask.name;
    const startWarning = hasChanges && task.start !== resourcedFromTask.start;
    const dueWarning = hasChanges && task.due !== resourcedFromTask.due;
    const doneWarning = hasChanges && task.done !== resourcedFromTask.done;
    const statusWarning =
        hasChanges && taskStatus?.name !== resourcedFromTaskStatus?.name;

    const styles = mergeStyleSets({
        warning: {
            fontWeight: 'bold',
            color: currentTheme.semanticColors.severeWarningIcon,
        },
    });

    const canNavigate = resourcedFromTask.mission?.rights.read;

    const navigateToResourceTaskMission = async () => {
        if (resourcedFromTask.mission?.id && canNavigate) {
            navigateToMission(resourcedFromTask.mission?.id);
        }
    };

    const missionText = [
        resourcedFromTask.mission?.owner,
        resourcedFromTask.mission?.title,
    ].join(' - ');

    return (
        <MessageBar
            messageBarType={
                hasChanges ? MessageBarType.warning : MessageBarType.info
            }
            messageBarIconProps={{ iconName: undefined }}
            styles={{
                iconContainer: { minWidth: 0, margin: 0 },
                content: {
                    margin: 0,
                },
            }}
            isMultiline
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                }}
            >
                <span>
                    <strong>Mission:</strong>{' '}
                    {canNavigate ? (
                        <Link onClick={navigateToResourceTaskMission}>
                            {missionText}
                        </Link>
                    ) : (
                        <span>{missionText}</span>
                    )}
                </span>
                <span>
                    <strong>Specified Task:</strong>{' '}
                    {resourcedFromTask.parentTask?.name}
                </span>
                <span className={nameWarning ? styles.warning : undefined}>
                    <strong>Implied Task:</strong>{' '}
                    <span>{resourcedFromTask.name}</span>
                </span>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 16,
                    }}
                >
                    <span className={startWarning ? styles.warning : undefined}>
                        <strong>Start:</strong>{' '}
                        <span>
                            {resourcedFromTask.start
                                ? dayjs
                                      .utc(resourcedFromTask.start)
                                      .format('DD MMM YYYY')
                                : ' - '}
                        </span>
                    </span>
                    <span className={dueWarning ? styles.warning : undefined}>
                        <strong>Due:</strong>{' '}
                        <span>
                            {resourcedFromTask.due
                                ? dayjs
                                      .utc(resourcedFromTask.due)
                                      .format('DD MMM YYYY')
                                : ' - '}
                        </span>
                    </span>
                    <span className={doneWarning ? styles.warning : undefined}>
                        <strong>Done:</strong>{' '}
                        <span>
                            {resourcedFromTask.done
                                ? dayjs
                                      .utc(resourcedFromTask.done)
                                      .format('DD MMM YYYY')
                                : ' - '}
                        </span>
                    </span>
                </div>
                <span className={statusWarning ? styles.warning : undefined}>
                    <strong>Status:</strong>{' '}
                    <span>{resourcedFromTaskStatus?.text}</span>
                </span>
                {resourcedFromTask.parentTask?.taskCategory && (
                    <span>
                        <strong style={{ verticalAlign: 'top' }}>
                            Category:
                        </strong>{' '}
                        <span style={{ verticalAlign: 'top' }}>
                            <TaskCategoryLabel
                                {...resourcedFromTask.parentTask.taskCategory}
                            />
                        </span>
                    </span>
                )}
                <span>
                    <strong>Resources:</strong>{' '}
                    {resourcedFromTask.resourcedTasks
                        ?.map((rt) => rt.resource?.displayName || '')
                        .join(', ')}
                </span>
            </div>
        </MessageBar>
    );
}
