import React, { PropsWithChildren } from 'react';
import {
    Callout,
    ILabelProps,
    IconButton,
    Label,
    mergeStyleSets,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';

export function InfoLabel(
    props: PropsWithChildren<
        {
            infoContent: string | JSX.Element;
        } & ILabelProps
    >
) {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
        useBoolean(false);
    const descriptionId: string = useId('description');
    const iconButtonId: string = useId('iconButton');

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
        },
        callOut: {
            maxWidth: 200,
            padding: 8,
        },
    });

    return (
        <div className={classNames.container}>
            <Label {...props}>{props.children}</Label>
            <IconButton
                id={iconButtonId}
                iconProps={{ iconName: 'Info' }}
                title="Info"
                ariaLabel="Info"
                onClick={toggleIsCalloutVisible}
                styles={{
                    root: {
                        height: 28,
                        width: 28,
                    },
                }}
            />
            {isCalloutVisible && (
                <Callout
                    target={'#' + iconButtonId}
                    setInitialFocus
                    onDismiss={toggleIsCalloutVisible}
                    ariaDescribedBy={descriptionId}
                    role="alertdialog"
                >
                    <div id={descriptionId} className={classNames.callOut}>
                        {props.infoContent}
                    </div>
                </Callout>
            )}
        </div>
    );
}
