import * as React from 'react';
import { FormEvent } from 'react';
import { ComboBox, IComboBox, IComboBoxOption } from '@fluentui/react';
import { InputShimmer } from './inputs';
import { useGetMissionSearchQuery } from '../data/types';
import { useStateContext } from '../services/contextProvider';
import orderBy from 'lodash/orderBy';

export function MissionComboBox(props: {
    divisionId?: string | null;
    financialYearCode?: string | null;
    selectedMissionId: string | null | undefined;
    onMissionChange: (mission: { id: string; name: string } | null) => void;
    label?: string;
    placeholder?: string;
}) {
    const { currentTenantId } = useStateContext();

    const { data: missionData, loading: missionsLoading } =
        useGetMissionSearchQuery({
            skip: !currentTenantId,
            variables: {
                tenantId: currentTenantId || '',
                divisionId: props.divisionId || null,
                searchText: '',
                isWritable: false,
                isImportable: false,
                includeInactive: false,
                includeDeleted: false,
                financialYearCode: props.financialYearCode || null,
            },
        });

    const missions = orderBy(missionData?.missions || [], 'owner');

    const missionOptions: IComboBoxOption[] = missions.map((m) => ({
        key: m.id || '',
        text: [m.owner, m.title].join(' - '),
    }));

    const handleChange = (
        _event?: FormEvent<IComboBox>,
        option?: IComboBoxOption
    ): void => {
        props.onMissionChange(
            option ? { id: option.key as string, name: option.text } : null
        );
    };

    return (
        <InputShimmer isDataLoaded={!missionsLoading}>
            <ComboBox
                {...props}
                options={missionOptions}
                selectedKey={props.selectedMissionId}
                onChange={handleChange}
            />
        </InputShimmer>
    );
}
