import React, { useCallback, useEffect, useState } from 'react';
import {
    ActionButton,
    Stack,
    Shimmer,
    mergeStyleSets,
    Separator,
    ShimmerElementType,
} from '@fluentui/react';
import orderBy from 'lodash/orderBy';

import { useNavigate, useParams } from 'react-router-dom';
import { navigation } from '../../services/navigation';
import { useStateContext } from '../../services/contextProvider';
import {
    MonthRow,
    FrequencyTypes,
    createSeriesValue,
    createEmptyRow,
} from './utils/measureUtils';
import { useBoolean } from '@fluentui/react-hooks';
import MeasureNameEdit from './components/MeasureNameEdit';
import {
    FrequencyPeriods,
    SeriesValue,
    useGetMeasureQuery,
    useGetFinancialYearQuery,
    useGetMeasureLazyQuery,
    useFastUpdateMeasureAsOfMutation,
    useRecalculateMeasureMutation,
    refetchGetMeasureValueHistoryQuery,
    ValueTypes,
} from '../../data/types';
import { MeasureSeriesNames } from '../../data/extendedTypes';
import { useThemes } from '../../hooks/useThemes';
import dayjs from 'dayjs';
import { AdvanceCard } from '../../components/AdvanceCard';
import MeasureLinkedTasksDonut from '../../components/MeasureLinkedTasksDonut';
import { MeasureEditPageCommandBar } from './components/MeasureCommandBar';
import { MeasureUpgradeDialog } from './components/MeasureUpgradeDialog';
import { MeasureUpgradePreviewDialog } from './components/MeasureUpgradePreviewDialog';
import { MeasureTargetSection } from './components/MeasureTargetSection';
import { Guid } from 'guid-typescript';
import { DataMeasureType, useMeasureData } from './hooks/useMeasureData';
import { MeasureValuesChartCard } from './components/MeasureValuesChartCard';
import { MeasureValuesGridCard } from './components/MeasureValuesGridCard';
import { useActiveView } from '../../hooks/useActiveView';
import { useDistributeTarget } from '../../hooks/useDistributeTarget';

export function Measure(props: {
    onEditMeasureClick: (measureId: string) => void;
    onAttachmentsClick: (measureId: string) => void;
    onCommentsClick: (measureId: string) => void;
}) {
    const {
        currentTenantId,
        currentRoles,
        currentTenantCode,
        currentFinancialYearCode,
    } = useStateContext();

    const { currentTheme } = useThemes();

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin'].includes(r)
    );

    const [isSavingValues, setIsSavingValues] = useState(false);
    const [isEditingValues, setIsEditingValues] = useState(false);

    const [data, setData] = useState<MonthRow[]>();

    const { teamCode, missionId, measureId } = useParams();
    const navigate = useNavigate();

    const [showTotals, { toggle: toggleShowTotals, setFalse: hideTotals }] =
        useBoolean(false);
    const [showCumulativeColumn, setShowCumulativeColumn] =
        useState<boolean>(true);
    const [hideUpgradeDialog, { toggle: toggleUpgradeDialog }] =
        useBoolean(true);
    const [hideUpgradePreviewDialog, { toggle: toggleUpgradePreviewDialog }] =
        useBoolean(true);
    const [upgradeFrequency, setUpgradeFrequency] = useState<FrequencyPeriods>(
        FrequencyPeriods.None
    );

    useActiveView('Measure');

    const { data: fyData } = useGetFinancialYearQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: currentFinancialYearCode || '',
        },
    });

    const [refetchMeasure] = useGetMeasureLazyQuery({
        fetchPolicy: 'network-only',
        variables: {
            tenantId: currentTenantId || '',
            id: measureId || '',
        },
    });

    const { data: measureData, loading: isLoading } = useGetMeasureQuery({
        fetchPolicy: 'network-only',
        skip: !currentTenantId || !measureId,
        variables: {
            tenantId: currentTenantId || '',
            id: measureId || '',
        },
    });

    const measure = measureData?.measure;

    const [fastUpdateMeasureAsOf, { error: saveAsOfError }] =
        useFastUpdateMeasureAsOfMutation();

    const [recalculateMeasure] = useRecalculateMeasureMutation();

    const fyStartDate = fyData?.financialYear?.startDate.substring(0, 10);
    const fyEndDate = fyData?.financialYear?.endDate.substring(0, 10);

    const { distributeTarget } = useDistributeTarget(measure, fyStartDate);

    const isLocked = (measureData?.measure?.tags || []).reduce(
        (value, tag) => value || tag.name === 'Locked',
        false
    );

    const allowUpdates =
        measure &&
        !!measure.mission?.rights.write &&
        (isAdmin || !isLocked) &&
        !measure.isLinked;

    const enableEditButton =
        !!measure && !!measure.mission?.rights.write && !isEditingValues;

    const isChartDisplayReadOnly = !measure || !measure.mission?.rights.write;

    const { buildTargetTable } = useMeasureData();

    const getTargets = useCallback(
        (m: DataMeasureType | null) => buildTargetTable(m, fyStartDate),
        [fyStartDate, buildTargetTable]
    );

    // Only reload the data if there has been a change to a field that affects data
    // Otherwise, the grid will reload when the chart type changes, etc.
    useEffect(() => {
        if (!isLoading && measureData?.measure?.id) {
            const dataMeasure: DataMeasureType = {
                id: measureData.measure.id,
                frequencyPeriod: measureData.measure.frequencyPeriod,
                isCustom: measureData.measure.isCustom,
                valueHistory: measureData.measure.valueHistory,
                valueType: measureData.measure.valueType,
                measureType: measureData.measure.measureType,
                statusType: measureData.measure.statusType,
                greenRange: measureData.measure.greenRange,
                yellowRange: measureData.measure.yellowRange,
                yellowStart: measureData.measure.yellowStart,
                isStatusLimited: measureData.measure.isStatusLimited,
            };
            const targets = getTargets(dataMeasure);
            setData(targets);
            setShowCumulativeColumn(
                dataMeasure.valueType === ValueTypes.Incremental
            );
            if (dataMeasure.valueType !== ValueTypes.Incremental) {
                hideTotals();
            }
        }
    }, [
        isLoading,
        measureData?.measure?.id,
        measureData?.measure?.frequencyPeriod,
        measureData?.measure?.isCustom,
        measureData?.measure?.valueHistory,
        measureData?.measure?.valueType,
        measureData?.measure?.measureType,
        measureData?.measure?.statusType,
        measureData?.measure?.greenRange,
        measureData?.measure?.yellowRange,
        measureData?.measure?.yellowStart,
        measureData?.measure?.isStatusLimited,
        getTargets,
        hideTotals,
    ]);

    const handleDistributeTargetClicked = () => {
        if (!data) {
            return;
        }

        const distributedData = distributeTarget(data);
        setData(distributedData);
        setIsEditingValues(true);
    };

    const handleAddRowClicked = () => {
        if (!data || !fyStartDate) {
            return;
        }

        const newIndex = Math.max(-1, ...data.map((d) => d.index)) + 1;

        const target = createSeriesValue(MeasureSeriesNames.Target);
        const actual = createSeriesValue(MeasureSeriesNames.Actual);

        const newRow: MonthRow = {
            ...createEmptyRow(newIndex, '', FrequencyTypes.Custom, fyStartDate),
            hasChanges: true,
            isNew: true,
            asOf: {
                id: Guid.EMPTY.toString(),
                asOfDate: '',
                measureId: measureId || '',
                values: [target, actual],
                version: '',
            },
        };

        if (measure?.showForecast) {
            const forecast = createSeriesValue(
                MeasureSeriesNames.PhasedForecast
            );
            newRow.forecast = 0;
            newRow.asOf?.values?.push(forecast);
        }

        if (data.length) {
            const last = data[data.length - 1];

            newRow.ytd = last.ytd;
            newRow.forecastYtd = last.forecastYtd;
            newRow.actualYtd = last.actualYtd;
        }

        data.push(newRow);

        setData([...data]);
    };

    const saveDataAsync = async () => {
        setIsSavingValues(true);

        const updatePromises = orderBy(data, 'index', ['desc'])
            .filter((r) => !(r.isNew && r.isDeleted)) // don't save new rows that have been marked as deleted.
            .filter((r) => r.hasChanges || r.isNew || r.isDeleted) // save only one with changes
            .map((r) => saveRowAsync(r));

        await Promise.all(updatePromises);

        await recalculateMeasure({
            awaitRefetchQueries: true,
            variables: {
                id: measureId || '',
                tenantId: currentTenantId || '',
            },
            refetchQueries: [
                refetchGetMeasureValueHistoryQuery({
                    tenantId: currentTenantId || '',
                    id: measureId || '',
                    historyHasActual: false,
                    historySkip: 0,
                    historyTake: 12,
                }),
            ],
        });

        const result = await refetchMeasure();
        if (result.data?.measure) {
            const targets = getTargets(result.data?.measure);
            setData(targets);
        }

        setIsSavingValues(false);
    };

    const saveRowAsync = async (row: MonthRow) => {
        const formattedAsOfDate = row.dt
            ? dayjs(row.dt).format('YYYY-MM-DD')
            : dayjs().format('YYYY-MM-DD');

        const asOf = row.asOf;

        if (!allowUpdates || !asOf) {
            return;
        }

        const seriesTypesToUpdate = [
            MeasureSeriesNames.Target,
            MeasureSeriesNames.Actual,
        ];

        if (
            row.forecast !== null ||
            asOf.values?.some(
                (v) => v.seriesType?.name === MeasureSeriesNames.PhasedForecast
            )
        ) {
            seriesTypesToUpdate.push(MeasureSeriesNames.PhasedForecast);
        }

        if (
            asOf.values?.some(
                (v) => v.seriesType?.name === MeasureSeriesNames.PhasedTarget
            )
        ) {
            seriesTypesToUpdate.push(MeasureSeriesNames.PhasedTarget);
        }

        const input = {
            id: asOf.id,
            measureId: measureId || '',
            asOfDate: formattedAsOfDate,
            version: asOf.version,
            values: seriesTypesToUpdate.map((st) => {
                const original = asOf.values?.find(
                    (v) => v.seriesType?.name === st
                );

                const value: SeriesValue = {
                    id: original ? original.id : null,
                    calcId: original?.calcId || null,
                    version: original?.version || '',
                    decimalValue: original?.decimalValue || null,
                    dateValue: original?.dateValue || null,
                    stringValue: original?.stringValue || null,
                    seriesType: {
                        id: null,
                        name: st,
                        calcSymbol: null,
                        defaultFormat: '',
                        sequence: null,
                    },
                };

                switch (st) {
                    case MeasureSeriesNames.Target: {
                        value.decimalValue = row.interval.decimalValue;
                        value.stringValue = row.interval.stringValue;
                        value.dateValue = row.interval.dateValue;
                        break;
                    }
                    case MeasureSeriesNames.Actual: {
                        value.decimalValue = row.actual.decimalValue;
                        value.stringValue = row.actual.stringValue;
                        value.dateValue = row.actual.dateValue;
                        break;
                    }
                    case MeasureSeriesNames.PhasedForecast: {
                        value.decimalValue = row.forecast;
                        value.stringValue = null;
                        value.dateValue = null;
                        break;
                    }
                }

                // To delete this as of, clear the value
                if (row.isDeleted) {
                    value.decimalValue = null;
                    value.stringValue = null;
                    value.dateValue = null;
                }

                return value;
            }),
        };

        // Don't save an asOf that's new but empty
        if (
            input.id === null &&
            !input.values.some(
                (v) =>
                    v.decimalValue !== null ||
                    v.dateValue !== null ||
                    v.stringValue !== null
            )
        ) {
            return;
        }

        await fastUpdateMeasureAsOf({
            variables: {
                tenantId: currentTenantId || '',
                input: input,
            },
        });
    };

    const handleBackClick = () => {
        const path = navigation.getPathForParams({
            tenantCode: currentTenantCode || '',
            financialYearCode: currentFinancialYearCode || '',
            teamCode: teamCode,
            missionId: missionId,
        });
        navigate(path, { replace: true });
    };

    const handleUpgradeCompleted = async () => {
        const result = await refetchMeasure();
        if (result.data?.measure) {
            const targets = getTargets(result.data?.measure);
            setData(targets);
        }
    };

    const tokens = {
        gapStack: {
            padding: 0,
            marginBottom: 32,
            childrenGap: 0,
        },
    };

    const classNames = mergeStyleSets({
        contentItem: {
            paddingLeft: 16,
        },
        separator: {
            paddingLeft: 8,
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 8,
            borderBottom: `solid 1px ${currentTheme.palette.neutralQuaternary}`,
        },
        messageBar: { marginTop: 16 },
        chartsTwoColumns: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(360px, 1fr))',
            gridGap: 16,
            margin: '16px 16px 0 16px',
        },
        tableOneColumn: {
            margin: '16px 16px 0 16px',
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        headerCommandBarContainer: {
            borderBottom: `solid 1px ${currentTheme.palette.neutralQuaternary}`,
        },
        targetSectionContainer: {
            paddingTop: 16,
            paddingLeft: 16,
            paddingRight: 16,
        },
    });

    return (
        <>
            {measure && allowUpdates && (
                <MeasureUpgradeDialog
                    measure={measure}
                    fyStartDate={fyStartDate}
                    hideDialog={hideUpgradeDialog}
                    toggleShowDialog={toggleUpgradeDialog}
                    onNext={(selectedFrequency) => {
                        setUpgradeFrequency(selectedFrequency);
                        toggleUpgradeDialog();
                        toggleUpgradePreviewDialog();
                    }}
                />
            )}
            {measure && allowUpdates && (
                <MeasureUpgradePreviewDialog
                    measure={measure}
                    selectedFrequency={upgradeFrequency}
                    hideDialog={hideUpgradePreviewDialog}
                    toggleShowDialog={toggleUpgradePreviewDialog}
                    onBack={() => {
                        toggleUpgradeDialog();
                        toggleUpgradePreviewDialog();
                    }}
                    onUpgradeCompleted={() => handleUpgradeCompleted()}
                />
            )}
            <Stack tokens={tokens.gapStack}>
                {/* Back Icon and Measure Text */}
                <div className={classNames.titleContainer}>
                    <ActionButton
                        iconProps={{
                            iconName: 'SkypeCircleArrow',
                        }}
                        styles={{
                            icon: {
                                fontSize: 28,
                            },
                        }}
                        onClick={handleBackClick}
                    />
                    <Shimmer
                        isDataLoaded={!isLoading}
                        shimmerElements={[
                            {
                                type: ShimmerElementType.line,
                                height: 32,
                            },
                        ]}
                    >
                        {measure && (
                            <MeasureNameEdit
                                measure={measure}
                                variant="xLarge"
                                isReadOnly={!allowUpdates}
                            />
                        )}
                    </Shimmer>
                </div>

                <div className={classNames.headerCommandBarContainer}>
                    <Shimmer
                        isDataLoaded={!isLoading && !!measure}
                        styles={{
                            dataWrapper: {
                                height: 44,
                            },
                        }}
                        shimmerElements={[
                            {
                                type: ShimmerElementType.line,
                                height: 44,
                            },
                        ]}
                    >
                        {measure && (
                            <MeasureEditPageCommandBar
                                measure={measure}
                                isReadOnly={!allowUpdates || isEditingValues}
                                enableEditButton={enableEditButton}
                                showTotals={showTotals}
                                toggleShowTotals={toggleShowTotals}
                                onAttachmentsClick={props.onAttachmentsClick}
                                onCommentsClick={props.onCommentsClick}
                                onEditMeasureClick={props.onEditMeasureClick}
                                onUpgradeClick={toggleUpgradeDialog}
                            />
                        )}
                    </Shimmer>
                </div>

                <div className={classNames.targetSectionContainer}>
                    <Shimmer
                        isDataLoaded={!isLoading && !!measure && !!fyStartDate}
                        shimmerElements={[
                            {
                                type: ShimmerElementType.line,
                                height: 125,
                            },
                        ]}
                    >
                        {!!measure && !!fyStartDate && (
                            <MeasureTargetSection
                                measure={measure}
                                isReadOnly={!allowUpdates || isEditingValues}
                                onChangeFrequencyClick={() => {
                                    toggleUpgradeDialog();
                                }}
                                fyStartDate={fyStartDate}
                            />
                        )}
                    </Shimmer>
                </div>

                {/* /Measure Top Area  */}

                <Separator className={classNames.separator}></Separator>

                {/* Row of Two Charts */}
                <div className={classNames.chartsTwoColumns}>
                    <MeasureValuesChartCard
                        isLoading={isLoading}
                        measure={measure || null}
                        isSavingValues={isSavingValues}
                        data={data || []}
                        fyStartDate={fyStartDate}
                        isReadOnly={isChartDisplayReadOnly}
                    />

                    <AdvanceCard title="Linked Task Status">
                        <div style={{ height: 240 }}>
                            <Shimmer
                                isDataLoaded={!isLoading && !!measure}
                                shimmerElements={[
                                    {
                                        type: ShimmerElementType.gap,
                                        width: 16,
                                        height: 232,
                                    },
                                    {
                                        type: ShimmerElementType.line,
                                        height: 232,
                                    },
                                    {
                                        type: ShimmerElementType.gap,
                                        width: 16,
                                        height: 232,
                                    },
                                ]}
                            >
                                <MeasureLinkedTasksDonut
                                    tenantId={currentTenantId ?? ''}
                                    missionId={missionId ?? ''}
                                    measureId={measureId ?? ''}
                                />
                            </Shimmer>
                        </div>
                    </AdvanceCard>
                </div>

                {/* Table */}
                <div className={classNames.tableOneColumn}>
                    <MeasureValuesGridCard
                        isLoading={isLoading}
                        measure={measure || null}
                        data={data || []}
                        showTotals={showTotals}
                        toggleShowTotals={toggleShowTotals}
                        showCumulativeColumn={showCumulativeColumn}
                        onDistributeTargetClicked={
                            handleDistributeTargetClicked
                        }
                        onDataChanged={(newData) => setData(newData)}
                        onDiscardChangesClicked={() => {
                            setIsEditingValues(false);
                            const targets = getTargets(measure || null);
                            setData(targets);
                            setIsEditingValues(false);
                        }}
                        onSaveChangesClicked={async () => {
                            await saveDataAsync();
                            setIsEditingValues(false);
                        }}
                        onUpdateValuesClicked={() => {
                            setIsEditingValues(true);
                        }}
                        onAddRowClicked={handleAddRowClicked}
                        isSavingValues={isSavingValues}
                        isEditingValues={isEditingValues}
                        isReadOnly={!allowUpdates}
                        saveErrorMessage={saveAsOfError?.message}
                        fyStartDate={fyStartDate}
                        fyEndDate={fyEndDate}
                    />
                </div>
            </Stack>
        </>
    );
}
