import * as React from 'react';
import orderBy from 'lodash/orderBy';
import {
    IGroup,
    Panel,
    PanelType,
    ShimmeredDetailsList,
    Stack,
} from '@fluentui/react';
import { useGetMissionSearchQuery, useGetTeamsQuery } from '../../data/types';
import { useStateContext } from '../../services/contextProvider';

type CopyStructurePanelProps = {
    showPanel: boolean;
    financialYearCode: string | null;
    onDismiss: () => void;
};

export function CopyStructurePanel(
    props: CopyStructurePanelProps
): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { data: teamsData, loading: teamsLoading } = useGetTeamsQuery({
        skip: !props.showPanel || !props.financialYearCode,
        variables: {
            tenantId: currentTenantId || '',
            divisionId: null,
            financialYearCode: props.financialYearCode,
        },
    });

    const { data: missionData, loading: missionLoading } =
        useGetMissionSearchQuery({
            skip: !props.showPanel || !props.financialYearCode,
            variables: {
                tenantId: currentTenantId || '',
                searchText: '',
                isWritable: false,
                isImportable: false,
                includeInactive: true,
                includeDeleted: false,
                financialYearCode: props.financialYearCode,
                divisionId: null,
            },
        });

    const items = orderBy(missionData?.missions, [
        'team.id',
        'sequence',
        'owner',
    ]);

    const groups: IGroup[] = [];

    const columns = [
        {
            key: 'title',
            name: 'Title',
            fieldName: 'title',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: 'owner',
            name: 'Owner',
            fieldName: 'owner',
            minWidth: 100,
            maxWidth: 200,
        },
    ];

    teamsData?.teams?.forEach((team) => {
        groups.push({
            key: team.id || '',
            name: team.name || team.code || '',
            startIndex: items.findIndex((m) => m.team?.id === team.id),
            count: items.filter((m) => m.team?.id === team.id).length,
            level: 0,
        });
    });

    return (
        <Panel
            isOpen={props.showPanel}
            type={PanelType.large}
            onDismiss={props.onDismiss}
            headerText="Copy Structure"
            closeButtonAriaLabel="Close"
        >
            <Stack tokens={{ childrenGap: 16 }}>
                <ShimmeredDetailsList
                    enableShimmer={teamsLoading || missionLoading}
                    items={items}
                    groups={groups}
                    columns={columns}
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    ariaLabelForSelectionColumn="Toggle selection"
                    checkButtonAriaLabel="select row"
                    checkButtonGroupAriaLabel="select section"
                    groupProps={{
                        showEmptyGroups: true,
                    }}
                    compact
                />
            </Stack>
        </Panel>
    );
}
