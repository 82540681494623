import {
    MonthRow,
    filterFiscalYearOnly,
} from '../scenes/Measure/utils/measureUtils';

export function useDistributeTarget(
    measure:
        | {
              id: string | null;
              fullYearTarget: number | null;
              fullYearString: string | null;
              isFullYearTarget: boolean;
          }
        | null
        | undefined,
    fyStartDate: string | undefined
): {
    isTargetDistributed: (data: MonthRow[]) => boolean;
    distributeTarget: (data: MonthRow[]) => MonthRow[];
} {
    const isTargetDistributed = (data: MonthRow[]): boolean => {
        let isDistibuted: boolean;

        if (
            !measure ||
            !fyStartDate ||
            !data.length ||
            measure.fullYearTarget === null
        ) {
            return true;
        }

        const compareValue = Math.round(measure.fullYearTarget * 100);

        if (measure.isFullYearTarget) {
            const targetSum = data.reduce(
                (sum, current) => sum + (current?.interval.decimalValue || 0),
                0
            );
            isDistibuted = compareValue === Math.round(targetSum * 100);
        } else {
            isDistibuted = true;
            data.filter((d) => !d.isDeleted).forEach((d) => {
                if (
                    compareValue !==
                    Math.round((d.interval?.decimalValue || 0) * 100)
                ) {
                    isDistibuted = false;
                }
            });
        }

        return isDistibuted;
    };

    const distributeTarget = (data: MonthRow[]): MonthRow[] => {
        const yearTarget =
            measure?.fullYearTarget || measure?.fullYearString || '';

        const yearTargetNumber = yearTarget as number;

        const dataInYear = filterFiscalYearOnly(data, fyStartDate).filter(
            (d) => !d.isDeleted
        );

        const rowCount = dataInYear.length;

        if (!rowCount) {
            return data;
        }

        const interval = measure?.isFullYearTarget
            ? yearTargetNumber / rowCount
            : yearTargetNumber;

        let runningYTD = 0;
        for (const row of dataInYear) {
            runningYTD += interval;

            if (row.interval.decimalValue !== interval) {
                row.interval.decimalValue = interval;
                row.hasChanges = true;
            }

            row.ytd = runningYTD;
        }

        return [...data];
    };

    return {
        isTargetDistributed,
        distributeTarget,
    };
}
