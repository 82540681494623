import * as React from 'react';
import {
    ChoiceGroup,
    DefaultButton,
    IChoiceGroupOption,
    Label,
    Stack,
} from '@fluentui/react';
import { MissionList } from './MissionList';
import { TeamList } from './TeamList';
import { TagList } from './TagList';

type Props = {
    missionIds?: string[];
    setSelectedMissionIds: (ids: string[]) => void;
    setSelectedTeamIds: (ids: string[]) => void;
    setSelectedTagIds: (ids: string[]) => void;
};

export function ImportFilter({
    setSelectedMissionIds,
    setSelectedTeamIds,
    setSelectedTagIds,
}: Props): JSX.Element {
    const DEFAULT_LABEL_TEXT = 'Everything included';
    type filterTypes = 'all' | 'mission' | 'team' | 'tag';
    const [filterKey, setFilterKey] = React.useState<filterTypes>('all');
    const [showFilter, setShowFilter] = React.useState<boolean>(true);
    const [label, setLabel] = React.useState<string>(DEFAULT_LABEL_TEXT);

    return (
        <Stack tokens={{ childrenGap: '24' }}>
            {showFilter && (
                <>
                    <ChoiceGroup
                        label="Filter"
                        selectedKey={filterKey}
                        options={[
                            {
                                key: 'all',
                                text: 'Include Everything',
                            },
                            {
                                key: 'mission',
                                text: 'Mission',
                            },
                            {
                                key: 'team',
                                text: 'Team',
                            },
                            {
                                key: 'tag',
                                text: 'Tags',
                            },
                        ]}
                        onChange={(
                            _ev?: React.FormEvent<
                                HTMLInputElement | HTMLElement
                            >,
                            option?: IChoiceGroupOption
                        ): void => {
                            if (option && option.key) {
                                const key = option?.key as filterTypes;
                                setFilterKey(key);
                                if (key == 'all') {
                                    setLabel(DEFAULT_LABEL_TEXT);
                                }
                            }
                        }}
                        required
                    />
                    {filterKey === 'mission' && (
                        <MissionList
                            onMissionIdsSelected={(
                                missionIds: string[]
                            ): void => {
                                setSelectedMissionIds(missionIds);
                                setLabel(
                                    `${missionIds.length} missions selected`
                                );
                            }}
                        />
                    )}
                    {filterKey === 'team' && (
                        <TeamList
                            onTeamIdsSelected={(teamIds: string[]): void => {
                                setSelectedTeamIds(teamIds);
                                setLabel(`${teamIds.length} teams selected`);
                            }}
                        />
                    )}
                    {filterKey === 'tag' && (
                        <TagList
                            onTagIdsSelected={(tagIds: string[]): void => {
                                setSelectedTagIds(tagIds);
                                setLabel(`${tagIds.length} tags selected`);
                            }}
                        />
                    )}
                    <DefaultButton
                        text="Apply Filter"
                        iconProps={{ iconName: 'Robot' }}
                        onClick={() => {
                            setShowFilter(!showFilter);
                        }}
                        style={{ width: '13em' }}
                    />
                </>
            )}
            {!showFilter && (
                <Stack horizontal style={{ alignItems: 'end' }}>
                    <Label style={{ marginTop: '1em' }}>{label}</Label>
                    <DefaultButton
                        text="Change"
                        iconProps={{ iconName: 'Robot' }}
                        onClick={() => {
                            setShowFilter(!showFilter);
                        }}
                        style={{
                            width: '9em',
                            marginTop: '1em',
                            marginLeft: '1em',
                        }}
                    />
                </Stack>
            )}
        </Stack>
    );
}
