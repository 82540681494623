import React from 'react';
import {
    Text,
    Stack,
    FocusZone,
    Dialog,
    DialogType,
    PrimaryButton,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    DefaultButton,
    Facepile,
    Label,
    OverflowButtonType,
} from '@fluentui/react';
import { MissionPicker } from './MissionPicker';
import {
    Measure,
    refetchGetMissionMeasuresQuery,
    useGetMeasureQuery,
    useUpdateMeasureMutation,
} from '../data/types';
import { photoService } from '../services/photo.service';

export type MeasureCopyDialogProps = {
    tenantId: string;
    financialYearCode: string | null;
    measureId: string | null | undefined;
    hidden: boolean;
    onDismiss: () => void;
    onMeasuresCreated: () => void;
    alreadyLinkedToMissions?: {
        id: string | null;
        title: string | null;
        owner: string | null;
        userId: string | null;
    }[];
};

export function MeasureCopyDialog(props: MeasureCopyDialogProps): JSX.Element {
    const [selectedMissionIds, setSelectedMissionIds] = React.useState<
        string[]
    >([]);

    const { data, loading, error } = useGetMeasureQuery({
        skip: props.hidden || !props.measureId,
        variables: {
            tenantId: props.tenantId || '',
            id: props.measureId || '',
        },
    });

    const measure = data?.measure;

    const [updateMeasure, { loading: isSaving, error: saveError }] =
        useUpdateMeasureMutation();

    const onMissionPickerChange = (
        missions: {
            id: string;
        }[]
    ): void => {
        setSelectedMissionIds(missions.map((m) => m.id));
    };

    const onCopyClick = (): void => {
        if (!measure || selectedMissionIds.length === 0) {
            return;
        }
        selectedMissionIds?.forEach(async (missionId) => {
            const inputMeasure: Measure = {
                id: null,
                missionId: missionId,
                measureGroupId: null,
                name: measure.name,
                shortName: measure.shortName,
                description: measure.description,
                measureType: measure.measureType,
                phaseType: measure.phaseType,
                currency: measure.currency,
                multiplier: measure.multiplier,
                decimalPlaces: measure.decimalPlaces,
                statusType: measure.statusType,
                yellowStart: measure.yellowStart,
                greenRange: measure.greenRange,
                yellowRange: measure.yellowRange,
                isStatusLimited: measure.isStatusLimited,
                sequence: 0,
                version: '',
                frequencyNumber: measure.frequencyNumber,
                frequencyPeriod: measure.frequencyPeriod,
                isLinked: true,
                linkedFromMeasureId: measure.isLinked
                    ? measure.linkedFromMeasureId
                    : measure.id,
                tags: measure.tags,

                previousFYMeasureId: measure.previousFYMeasureId,
                fullYearTarget: measure.fullYearTarget,
                fullYearString: measure.fullYearString,
                isFullYearTarget: measure.isFullYearTarget,
                chartDisplay: measure.chartDisplay,
                chartType: measure.chartType,
                showForecast: measure.showForecast,
                showFutureLook: measure.showFutureLook,
                isCustom: measure.isCustom,
                targetType: measure.targetType,
                valueType: measure.valueType,
                valueFormula: null,
            };
            await updateMeasure({
                variables: {
                    tenantId: props.tenantId,
                    input: inputMeasure,
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                    refetchGetMissionMeasuresQuery({
                        tenantId: props.tenantId,
                        missionId: missionId,
                    }),
                ],
            });
        });
        props.onMeasuresCreated();
    };

    const facepilePersonas =
        props.alreadyLinkedToMissions?.map((m) => {
            return {
                imageUrl: photoService.getImageUrl(m.userId),
                personaName: m.owner || m.title || '',
            };
        }) || [];

    return (
        <Dialog
            hidden={props.hidden}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Send Measure of Success',
                showCloseButton: true,
                subText:
                    'Create a linked replica measure of success in the destination missions.',
            }}
            modalProps={{
                isBlocking: true,
            }}
            onDismiss={props.onDismiss}
        >
            <FocusZone>
                <Stack tokens={{ childrenGap: 16 }}>
                    {(error || saveError) && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={true}
                        >
                            <span>{(error || saveError)?.message}</span>
                        </MessageBar>
                    )}
                    {isSaving && (
                        <Spinner
                            size={SpinnerSize.small}
                            label="Saving..."
                            labelPosition="right"
                        />
                    )}
                    <MissionPicker
                        label="Destination"
                        placeholder="Search for a mission"
                        selectedMissions={[]}
                        onChange={onMissionPickerChange}
                        showOnlyWritable={true}
                        financialYearCode={props.financialYearCode}
                        excludeMissionIds={[
                            ...(props.alreadyLinkedToMissions?.map(
                                (m) => m.id || ''
                            ) || []),
                        ]}
                    />
                    <Text variant="small" block>
                        Any changes to this primary measure of success will be
                        reflected in the destination measures of success.
                    </Text>

                    {facepilePersonas.length > 0 && (
                        <Stack.Item>
                            <Label>Already Linked</Label>
                            <Facepile
                                personas={facepilePersonas}
                                overflowButtonProps={
                                    {
                                        // Seems  to need this for the overflow button to appear
                                    }
                                }
                                maxDisplayablePersonas={7}
                                overflowButtonType={
                                    OverflowButtonType.descriptive
                                }
                            />
                        </Stack.Item>
                    )}

                    <Stack.Item align="end">
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <PrimaryButton
                                text="Create"
                                onClick={onCopyClick}
                                disabled={
                                    loading ||
                                    isSaving ||
                                    selectedMissionIds.length === 0
                                }
                            />
                            <DefaultButton
                                onClick={props.onDismiss}
                                text="Cancel"
                            />
                        </Stack>
                    </Stack.Item>
                </Stack>
            </FocusZone>
        </Dialog>
    );
}
