import React, { useEffect, useState } from 'react';
import { EditPanel } from '../../../components/shared/EditPanel';
import { Label, Persona, PersonaSize } from '@fluentui/react';
import { photoService } from '../../../services/photo.service';
import { TeamPicker } from '../../../components/inputs/TeamPicker';
import { FinancialYearPicker } from '../../../components/inputs/FinancialYearPicker';
import { useStateContext } from '../../../services/contextProvider';
import {
    refetchGetContributorsQuery,
    useDeleteContributorMutation,
    useGetContributorQuery,
    useGetFinancialYearQuery,
    useUpdateContributorMutation,
} from '../../../data/types';
import { InputShimmer } from '../../../components/inputs';
import DeleteModal from '../../../components/shared/DeleteModal';

export type ContributorPanelProps = {
    showPanel: boolean;
    user: { userId: string | null; displayName: string | null } | undefined;
    contributorId?: string | null;
    onCancel: () => void;
    onSave: () => void;
    financialYearCode?: string | undefined | null;
};

export function ContributorPanel(props: ContributorPanelProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [financialYear, setFinancialYear] = useState<{
        id: string | null;
        code: string | null;
    } | null>();

    const [teamId, setTeamId] = useState<string | null>();

    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

    const { data: fyData, loading: fyDataLoading } = useGetFinancialYearQuery({
        skip: !currentTenantId || !props.financialYearCode,
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: props.financialYearCode || '',
        },
    });

    const { data: contributorData, loading: contributorDataLoading } =
        useGetContributorQuery({
            skip:
                !currentTenantId || !props.contributorId || !props.user?.userId,
            variables: {
                tenantId: currentTenantId || '',
                userId: props.user?.userId || '',
                contributorId: props.contributorId || '',
                financialYearCode: props.financialYearCode || '',
            },
        });

    const [updateContributor, { loading: isSaving, error: saveError }] =
        useUpdateContributorMutation();

    const [deleteContributor, { loading: isDeleting, error: deleteError }] =
        useDeleteContributorMutation();

    useEffect(() => {
        if (!props.showPanel) {
            return;
        }

        if (props.contributorId && contributorData) {
            setTeamId(contributorData.contributor?.team?.id);
        } else {
            setTeamId(null);
        }

        if (fyData?.financialYear && props.financialYearCode) {
            setFinancialYear(fyData.financialYear);
        }
    }, [
        props.financialYearCode,
        props.contributorId,
        props.showPanel,
        fyData,
        contributorData,
    ]);

    const handleSubmit = async () => {
        if (props.user?.userId && financialYear?.id && currentTenantId) {
            await updateContributor({
                variables: {
                    tenantId: currentTenantId,
                    input: {
                        id: props.contributorId || null,
                        financialYearId: financialYear.id,
                        teamId: teamId || null,
                        userId: props.user?.userId,
                        version: '',
                    },
                },
                refetchQueries: [
                    refetchGetContributorsQuery({
                        tenantId: currentTenantId,
                        financialYearCode: props.financialYearCode || '',
                        teamCode: null,
                    }),
                ],
                awaitRefetchQueries: true,
            });
        }
        props.onSave();
    };

    const deleteAction = async () => {
        await deleteContributor({
            variables: {
                tenantId: currentTenantId || '',
                contributorId: props.contributorId || '',
            },
            refetchQueries: [
                refetchGetContributorsQuery({
                    tenantId: currentTenantId || '',
                    financialYearCode: props.financialYearCode || '',
                    teamCode: null,
                }),
            ],
            awaitRefetchQueries: true,
        });
        setIsConfirmingDelete(false);
        props.onSave();
    };

    const handleDeleteClick = props.contributorId
        ? () => {
              setIsConfirmingDelete(true);
          }
        : undefined;

    return (
        <React.Fragment>
            <DeleteModal
                activeViewName="ContributorDelete"
                isOpen={isConfirmingDelete}
                onDismiss={(): void => setIsConfirmingDelete(false)}
                message={'Are you sure you want to delete this resource?'}
                deleteAction={deleteAction}
                isDeleting={isDeleting}
                error={deleteError}
            />

            <EditPanel
                activeViewName="ContributorEdit"
                onDismiss={props.onCancel}
                showPanel={props.showPanel}
                headerText={'Setup Contributor'}
                isSaving={isSaving}
                isValid={true}
                saveErrorMessage={saveError?.message || null}
                onUpdateClick={handleSubmit}
                onDeleteClick={handleDeleteClick}
            >
                <div
                    style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
                >
                    {!!props.user && (
                        <div>
                            <Label>User</Label>
                            <Persona
                                size={PersonaSize.size24}
                                text={
                                    props.user.displayName ||
                                    props.user.userId ||
                                    ''
                                }
                                imageUrl={photoService.getImageUrl(
                                    props.user.userId
                                )}
                            />
                        </div>
                    )}

                    <InputShimmer
                        isDataLoaded={!fyDataLoading && !contributorDataLoading}
                    >
                        <FinancialYearPicker
                            label="Contributor FY"
                            selectedFinancialYearId={financialYear?.id}
                            onChange={(financialYear) => {
                                setFinancialYear(financialYear);
                            }}
                            disabled={!!props.financialYearCode}
                        />
                    </InputShimmer>

                    <InputShimmer
                        isDataLoaded={!fyDataLoading && !contributorDataLoading}
                    >
                        <TeamPicker
                            label="Contributor Team"
                            selectedTeamId={teamId}
                            onChange={(team): void => {
                                if (team) {
                                    setTeamId(team?.id);
                                } else {
                                    setTeamId(null);
                                }
                            }}
                            financialYearCode={
                                financialYear?.code || props.financialYearCode
                            }
                        />
                    </InputShimmer>
                </div>
            </EditPanel>
        </React.Fragment>
    );
}
