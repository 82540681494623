export const TaskStatusColours = {
    notStarted: '#818182',
    offTarget: '#a4262c',
    onTarget: '#498205',
    completedLate: '#f38420',
    completedOnTime: '#1e8aa8',
    rejected: '#a4262c',
    cancelled: '#ff0000',
    postponed: '#de5126',
    atRisk: '#c11f5a',
} as const;

export const MeasureStatusColours = {
    offTarget: '#a4262c',
    onTarget: '#498205',
    nearTarget: '#f38420',
} as const;

export const MeasureChartColours = {
    //target: '#f38420',
    targetOver: '#c11f5a',
    //actual: '#1e8aa8',
    forecast: '#818182',
    status: '#1e8aa8',
} as const;
