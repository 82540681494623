import React from 'react';
import {
    ActionButton,
    ContextualMenuItemType,
    IButtonProps,
    Icon,
    IContextualMenuItem,
    Stack,
} from '@fluentui/react';
import { TaskStatusNames } from '../../../data/extendedTypes';

export function TaskFilterStatusSelector(props: {
    taskSummary: {
        status: TaskStatusNames;
        count: number;
        name: string;
        colour: string;
    }[];
    selectedTaskStatuses: TaskStatusNames[];
    onStatusFilterChanged: (taskStatusNames: TaskStatusNames[]) => void;
}): JSX.Element {
    const { taskSummary, selectedTaskStatuses, onStatusFilterChanged } = props;

    const handleStatusFilterClick = (status: TaskStatusNames) => {
        let updated: TaskStatusNames[];
        if (selectedTaskStatuses?.includes(status)) {
            updated = selectedTaskStatuses.filter((ts) => ts !== status);
        } else {
            updated = [...(selectedTaskStatuses || []), status];
        }
        onStatusFilterChanged(updated);
    };

    const handeRenderText = (
        props?: IButtonProps,
        defaultRender?: (props?: IButtonProps) => JSX.Element | null
    ): JSX.Element => {
        return (
            <Stack horizontal tokens={{ childrenGap: 2 }}>
                {defaultRender && defaultRender(props)}

                {taskSummary
                    .filter((ts) => selectedTaskStatuses.includes(ts.status))
                    .map((ts) => (
                        <Icon
                            key={ts.name}
                            iconName="StatusCircleOuter"
                            styles={{
                                root: {
                                    color: ts.colour,
                                },
                            }}
                        />
                    ))}
            </Stack>
        );
    };

    const menuItems: IContextualMenuItem[] = taskSummary
        .filter((ts) => ts.count)
        .map((ts) => {
            return {
                key: ts.name,
                text: `${ts.name} (${ts.count})`,
                canCheck: true,
                checked: selectedTaskStatuses?.includes(ts.status),
                onClick: () => handleStatusFilterClick(ts.status),
                iconProps: {
                    iconName: 'StatusCircleOuter',
                    styles: {
                        root: {
                            color: ts.colour,
                        },
                    },
                },
            };
        });

    if (selectedTaskStatuses.length > 0) {
        menuItems.push(
            ...[
                {
                    key: 'clear-divider',
                    itemType: ContextualMenuItemType.Divider,
                },
                {
                    key: 'clear',
                    text: 'Clear',
                    iconProps: {
                        iconName: 'Cancel',
                    },
                    onClick: () => onStatusFilterChanged([]),
                },
            ]
        );
    }

    return (
        <ActionButton
            text="Status"
            onRenderText={handeRenderText}
            menuProps={{
                items: menuItems,
            }}
        />
    );
}
