import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    GetMeasurePeriodDataQuery,
    GetMeasuresForStatusReportQuery,
    GetTasksForStatusReportQuery,
    ReportPeriodTypes,
    useGetMeasurePeriodDataLazyQuery,
    useGetMeasuresForStatusReportLazyQuery,
    useGetTasksForStatusReportLazyQuery,
} from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import { sorters } from '../../../data/sorters';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';

type PeriodData = ExtractQueryArrayType<
    GetMeasurePeriodDataQuery,
    ['periodData', 'measurePeriodData']
>;

export function useTemplateReportTasks(props: {
    utcDataDate: string | null;
    utcCompletedDate: string | null;
    missionId: string | null;
    reportPeriodType: ReportPeriodTypes | null;
    reportPeriod: number | null;
}): {
    selectedTaskIds: string[];
    setSelectedTaskIds: Dispatch<SetStateAction<string[]>>;
    isLoading: boolean;
    specifiedTasks: GetTasksForStatusReportQuery['tasks'];
    impliedTasks: GetTasksForStatusReportQuery['tasks'];
    measures: GetMeasuresForStatusReportQuery['measures'];
    periodData: PeriodData[];
} {
    const { currentTenantId } = useStateContext();

    const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([]);
    const [periodData, setPeriodData] = useState<PeriodData[]>([]);

    const [loadMeasures, { data: measureData, loading: measuresLoading }] =
        useGetMeasuresForStatusReportLazyQuery();

    const [loadTasks, { data: taskData, loading: tasksLoading }] =
        useGetTasksForStatusReportLazyQuery();

    const [loadPeriodData] = useGetMeasurePeriodDataLazyQuery({
        fetchPolicy: 'no-cache',
    });

    const loadMeasurePeriodData = useCallback(async () => {
        const forDateTime = props.utcDataDate || props.utcCompletedDate;

        if (measureData?.measures && taskData?.tasks) {
            const selectedTasks = taskData?.tasks.filter((t) =>
                selectedTaskIds.some((id) => id === t.id)
            );

            const selectedTaskLinkedMeasureIds = selectedTasks.flatMap((st) =>
                st.linkedMeasures.map((lm) => lm.measureId)
            );

            const selectedMeasures = measureData?.measures.filter((m) =>
                selectedTaskLinkedMeasureIds.some((id) => id === m.id)
            );

            const functions = selectedMeasures.map((measure) =>
                loadPeriodData({
                    variables: {
                        tenantId: currentTenantId || '',
                        forDateTime: forDateTime,
                        measureId:
                            (measure.isLinked
                                ? measure.linkedFromMeasureId
                                : measure.id) || '',
                        reportPeriod: props.reportPeriod,
                        reportPeriodType:
                            props.reportPeriodType || ReportPeriodTypes.None,
                    },
                })
            );

            const results = await Promise.all(functions);

            const updatedPeriodData: PeriodData[] = [];

            for (const r of results) {
                if (r.data?.periodData?.measurePeriodData) {
                    updatedPeriodData.push(
                        r.data?.periodData?.measurePeriodData
                    );
                }
            }

            setPeriodData(updatedPeriodData);
        }
    }, [
        currentTenantId,
        props.utcDataDate,
        props.utcCompletedDate,
        props.reportPeriodType,
        props.reportPeriod,
        measureData,
        taskData,
        selectedTaskIds,
        loadPeriodData,
    ]);

    useEffect(() => {
        const forDateTime = props.utcDataDate || props.utcCompletedDate;
        loadMeasures({
            // We don't want the historic results polluting the cache
            fetchPolicy: !forDateTime ? 'cache-and-network' : 'no-cache',
            variables: {
                tenantId: currentTenantId || '',
                missionId: props.missionId,
                forDateTime: forDateTime,
                includeDeleted: true,
            },
        });
        loadTasks({
            // We don't want the historic results polluting the cache
            fetchPolicy: !forDateTime ? 'cache-and-network' : 'no-cache',
            variables: {
                tenantId: currentTenantId || '',
                missionId: props.missionId,
                forDateTime: forDateTime,
                includeDeleted: true,
            },
        });
    }, [
        props.utcDataDate,
        props.utcCompletedDate,
        props.missionId,
        currentTenantId,
        loadMeasures,
        loadTasks,
    ]);

    useEffect(() => {
        loadMeasurePeriodData();
    }, [loadMeasurePeriodData]);

    const selectedTasks: GetTasksForStatusReportQuery['tasks'] = [];

    selectedTaskIds.forEach((tid) => {
        const task = taskData?.tasks.find((t) => t.id === tid);
        if (task) {
            selectedTasks.push(task);
        }
    });

    const specifiedTasks = selectedTasks.filter(
        (t) => t && t?.parentTaskId === null && !t.isDuplicate
    );

    const impliedTasks = selectedTasks.filter(
        (t) => t && t?.parentTaskId !== null && !t.isDuplicate
    );

    const measures =
        measureData?.measures?.slice().sort(sorters.sequenceSorter) || [];

    return {
        selectedTaskIds,
        setSelectedTaskIds,
        isLoading: measuresLoading || tasksLoading,
        specifiedTasks,
        impliedTasks,
        measures,
        periodData,
    };
}
