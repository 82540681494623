import React from 'react';
import {
    Icon,
    IIconProps,
    IPersonaProps,
    mergeStyleSets,
    Persona,
    PersonaSize,
    Stack,
    useTheme,
} from '@fluentui/react';
import { photoService } from '../../services/photo.service';
import { GetNotificationsQuery, NotificationTypes } from '../../data/types';
import { ExtractQueryArrayType } from '../../data/extendedTypes';
import { useStateContext } from '../../services/contextProvider';
import dayjs from 'dayjs';
import { useComments } from '../../hooks/useComments';

export function NotificationItem(props: {
    notification: ExtractQueryArrayType<
        GetNotificationsQuery,
        ['notifications']
    > | null;
    onClick?: (() => void) | undefined;
    hideItemName?: boolean;
}): JSX.Element {
    const { currentUserId } = useStateContext();

    const currentTheme = useTheme();

    const { renderCommentText } = useComments();

    const { semanticColors, fonts } = currentTheme;

    const { notification, onClick } = props;

    const getIconName = (notificationType?: NotificationTypes | null) => {
        switch (notificationType) {
            case NotificationTypes.StatusReportPublished:
                return 'CRMReport';
            case NotificationTypes.TemplateReportPublished:
                return 'CRMReport';
            case NotificationTypes.CommentAdded:
                return 'Comment';
            case NotificationTypes.CommentMention:
                return 'CommentActive';
            case NotificationTypes.AttachmentAdded:
                return 'Attach';
            case NotificationTypes.MeasureOwnerChanged:
                return 'Remote';
            case NotificationTypes.ResourceDeactivated:
                return 'UserWarning';
            case NotificationTypes.ResourceChanged:
                return 'FollowUser';
            case NotificationTypes.ContributorTaskChanged:
                return 'ChangeEntitlements';
            case NotificationTypes.ContributorTaskAdded:
                return 'EntitlementRedemption';
            default:
                return 'Unknown';
        }
    };

    const getTitle = (
        notificationEvent:
            | ExtractQueryArrayType<
                  GetNotificationsQuery,
                  ['notifications', 'notificationEvent']
              >
            | null
            | undefined
    ): string => {
        const name = notificationEvent?.measure
            ? !props.hideItemName
                ? notificationEvent?.measure?.name?.trim()
                : 'this measure of success'
            : notificationEvent?.task
              ? !props.hideItemName
                  ? notificationEvent?.task?.name?.trim()
                  : 'this task'
              : null;

        const userName =
            notificationEvent?.originatorUsername || 'A system process';

        switch (notificationEvent?.notificationType) {
            case NotificationTypes.StatusReportPublished:
                return `${userName} published a status report`;
            case NotificationTypes.TemplateReportPublished:
                return `${userName} published a report`;
            case NotificationTypes.CommentAdded:
                return `${userName} commented on ${name}`;
            case NotificationTypes.CommentMention:
                return `${userName} mentioned you in a comment on ${name}`;
            case NotificationTypes.AttachmentAdded:
                return `${userName} added an attachment to ${name}`;
            case NotificationTypes.ResourceDeactivated:
                return `${notificationEvent.username} has been deactivated and can no longer access the system. Please review your task resourcing.`;
            case NotificationTypes.ResourceChanged:
                return `${notificationEvent.resource?.displayName} has replaced ${notificationEvent.username} for resources on your mission.`;
            case NotificationTypes.ContributorTaskChanged:
                return `${userName} made changes to ${name}.`;
            case NotificationTypes.ContributorTaskAdded:
                return `${userName} added you as a resource on ${name}.`;
            case NotificationTypes.MeasureOwnerChanged: {
                let title: string;
                if (notificationEvent.mission?.userId === currentUserId) {
                    const linkedMissions =
                        notificationEvent.measure?.linkedMeasures
                            .filter((lm) => lm.isLinked)
                            .map((lm) => lm.mission);
                    title = `You have been selected as the owner of ${name} by ${userName}. You are now responsible for any future updates for this measure of success.`;
                    if (linkedMissions?.length) {
                        title =
                            `${title}\n\nPlease note, the following mission owners are linked to this measure of success:\n` +
                            linkedMissions
                                .map((lm) => {
                                    return `- ${lm?.owner || lm?.title}`;
                                })
                                .join('\n');
                    }
                } else {
                    title = `${userName} has been designated to update ${name}`;
                }
                return title;
            }
            default:
                return '';
        }
    };

    const classNames = mergeStyleSets({
        notificationDismissed: {
            color: semanticColors.disabledBodyText,
        },
        notificationTitle: {
            ...fonts.smallPlus,
            whiteSpace: 'pre-wrap',
        },
        notificationComment: {
            fontStyle: 'italic',
            ...fonts.small,
        },
        notificationTimeStamp: {
            ...fonts.tiny,
        },
    });

    const notificationEvent = notification?.notificationEvent;
    const title = getTitle(notificationEvent);
    const isDismissed = !!notification?.utcDismissed;

    const timeStamp = dayjs
        .utc(notificationEvent?.utcCreated)
        .from(dayjs().utc());

    const comment = notificationEvent?.comment?.text
        ? renderCommentText(notificationEvent?.comment?.text)
        : undefined;
    const originatorDisplayName = notificationEvent?.originatorUsername || null;
    const originatorUserId = notificationEvent?.originatorUserId || null;

    return (
        <Stack
            horizontal
            tokens={{ padding: 0, childrenGap: 16 }}
            onClick={onClick}
            className={
                isDismissed ? classNames.notificationDismissed : undefined
            }
        >
            <Stack.Item
                align="center"
                verticalFill
                styles={{
                    root: {
                        padding: 8,
                    },
                }}
            >
                <NotificationPersonaWithIcon
                    personaProps={{
                        size: PersonaSize.size32,
                        text: originatorDisplayName || 'Unknown',
                        imageUrl: photoService.getImageUrl(originatorUserId),
                        showSecondaryText: false,
                        hidePersonaDetails: true,
                    }}
                    iconProps={{
                        iconName: getIconName(
                            notification?.notificationEvent?.notificationType
                        ),
                    }}
                    isDismissed={isDismissed || false}
                />
            </Stack.Item>
            <Stack tokens={{ childrenGap: 8 }}>
                <Stack.Item className={classNames.notificationTitle}>
                    {title}
                </Stack.Item>
                {!!comment && (
                    <Stack.Item className={classNames.notificationComment}>
                        <q>{comment}</q>
                    </Stack.Item>
                )}
                <Stack.Item className={classNames.notificationTimeStamp}>
                    {timeStamp}
                </Stack.Item>
            </Stack>
        </Stack>
    );
}

export function NotificationPersonaWithIcon(props: {
    personaProps: IPersonaProps;
    iconProps: IIconProps;
    isDismissed: boolean;
}) {
    const currentTheme = useTheme();
    const { palette } = currentTheme;

    return (
        <div>
            <Persona {...props.personaProps} />
            <span
                style={{
                    position: 'relative',
                    top: -12,
                    left: 20,
                    background: props.isDismissed
                        ? palette.neutralLighter
                        : palette.themeLight,
                    color: props.isDismissed
                        ? palette.neutralDark
                        : palette.neutralDark,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                }}
            >
                <Icon {...props.iconProps} />
            </span>
        </div>
    );
}
