import React from 'react';
import {
    Stack,
    TextField,
    DatePicker,
    DayOfWeek,
    Toggle,
} from '@fluentui/react';

import {
    FinancialYearQl,
    useUpdateFinancialYearMutation,
} from '../../../data/types';
import { EditPanel } from '../../../components/shared/EditPanel';
import { useStateContext } from '../../../services/contextProvider';
import useForm from '../../../hooks/useForm';
import dayjs from 'dayjs';
import { Guid } from 'guid-typescript';

export type FinancialYearEditPanelProps = {
    showPanel: boolean;
    financialYear?: FinancialYearQl | null;
    onCancel: () => void;
    onSave: () => void;
};

export function FinancialYearEditPanel(
    props: FinancialYearEditPanelProps
): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [updateFinancialYear, { loading: isSaving, error: saveError }] =
        useUpdateFinancialYearMutation({
            onCompleted: props.onSave,
            refetchQueries: ['GetFinancialYears'],
        });

    type formValuesType = {
        code: string;
        name: string;
        startDate: string;
        endDate: string;
        utcInactive: string | null;
    };

    const formDefaultValues: formValuesType = {
        code: props.financialYear?.code || '',
        name: props.financialYear?.name || '',
        startDate: props.financialYear?.startDate || '',
        endDate: props.financialYear?.endDate || '',
        utcInactive: props.financialYear?.utcInactive || null,
    };

    const formCallback = (values: formValuesType): void => {
        updateFinancialYear({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    id:
                        props.financialYear?.id ||
                        Guid.createEmpty().toString(),
                    code: values.code,
                    name: values.name,
                    startDate: values.startDate
                        ? dayjs.utc(values.startDate).toISOString()
                        : null,
                    endDate: values.endDate
                        ? dayjs.utc(values.endDate).toISOString()
                        : null,
                    utcInactive: values.utcInactive,
                    version: props.financialYear?.version || null,
                },
            },
        });
    };

    const formValidate = (
        values: formValuesType
    ): {
        [key: string]: string;
    } => {
        const formErrorMessages = {
            code: '',
            name: '',
            startDate: '',
            endDate: '',
        };

        if (!values.code) {
            formErrorMessages.code = 'Enter a code';
        }

        if (!values.name) {
            formErrorMessages.name = 'Enter a name';
        }

        if (!values.startDate) {
            formErrorMessages.startDate = 'Enter a start date';
        }

        if (!values.endDate) {
            formErrorMessages.endDate = 'Enter a end date';
        }

        return formErrorMessages;
    };

    const { errors, handleChange, handleSubmit, updateValue, values } = useForm(
        formDefaultValues,
        formCallback,
        formValidate
    );

    return (
        <EditPanel
            activeViewName="FinancialYearEdit"
            onDismiss={props.onCancel}
            showPanel={props.showPanel}
            headerText={
                props.financialYear?.id
                    ? 'Edit Financial Year'
                    : 'New Financial Year'
            }
            isSaving={isSaving}
            isValid={true}
            saveErrorMessage={saveError?.message || null}
            onUpdateClick={handleSubmit}
        >
            <Stack tokens={{ childrenGap: 8 }}>
                <TextField
                    label="Code"
                    required
                    name="code"
                    autoComplete="off"
                    defaultValue={formDefaultValues.code}
                    errorMessage={errors.code}
                    onChange={handleChange}
                />

                <TextField
                    label="Name"
                    required
                    name="name"
                    autoComplete="off"
                    defaultValue={formDefaultValues.name}
                    errorMessage={errors.name}
                    onChange={handleChange}
                />

                <DatePicker
                    label="Start Date"
                    placeholder="Start date"
                    ariaLabel="Select a start date"
                    firstDayOfWeek={DayOfWeek.Monday}
                    value={
                        values.startDate
                            ? new Date(values.startDate)
                            : undefined
                    }
                    onSelectDate={(date: Date | null | undefined): void => {
                        updateValue(
                            'startDate',
                            date ? dayjs(date).format('YYYY-MM-DD') : undefined
                        );
                    }}
                    isRequired
                />

                <DatePicker
                    label="End Date"
                    placeholder="End date"
                    ariaLabel="Select an end date"
                    firstDayOfWeek={DayOfWeek.Monday}
                    value={
                        values.endDate
                            ? dayjs(values.endDate).toDate()
                            : undefined
                    }
                    onSelectDate={(date: Date | null | undefined): void => {
                        updateValue(
                            'endDate',
                            date ? dayjs(date).format('YYYY-MM-DD') : undefined
                        );
                    }}
                    isRequired
                />

                <Toggle
                    label="Active Status"
                    onText="Active"
                    offText="Inactive"
                    checked={!values.utcInactive}
                    onChange={(
                        ev: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                    ): void => {
                        updateValue(
                            'utcInactive',
                            checked ? null : new Date().toISOString()
                        );
                    }}
                />
            </Stack>
        </EditPanel>
    );
}
