import dayjs from 'dayjs';
import {
    MeasureSeriesNames,
    MeasureValueHistoryItem,
} from '../data/extendedTypes';
import { useCallback } from 'react';

export const usePhasing = (): {
    hasPhasing: (valueHistory: MeasureValueHistoryItem[]) => boolean;
    getPhasingForDate: (
        valueHistory: MeasureValueHistoryItem[],
        date: Date
    ) => {
        decimalValue: number | null;
        stringValue: string | null;
        dateValue: string | null;
        formatStr: string | null;
    } | null;
    hasTargetsOutOfSync: (valueHistory: MeasureValueHistoryItem[]) => boolean;

    getTargetsOutOfSync: (valueHistory: MeasureValueHistoryItem[]) => {
        entry: MeasureValueHistoryItem;
        phasedTarget: number;
    }[];
} => {
    const hasPhasing = useCallback(
        (valueHistory: MeasureValueHistoryItem[]): boolean => {
            return valueHistory.some((h) =>
                h?.values?.some(
                    (v) =>
                        v.seriesType?.name === MeasureSeriesNames.PhasedTarget
                )
            );
        },
        []
    );

    const getPhasingForDate = useCallback(
        (
            valueHistory: MeasureValueHistoryItem[],
            date?: Date
        ): {
            decimalValue: number | null;
            stringValue: string | null;
            dateValue: string | null;
            formatStr: string | null;
        } | null => {
            if (!date) {
                return null;
            }

            const latestAsOf = valueHistory
                .filter((m) => !dayjs(date).isBefore(m.asOfDate || ''))
                .sort((m1, m2) =>
                    dayjs(m1.asOfDate || '').isBefore(m2.asOfDate || '')
                        ? 1
                        : -1
                )
                .find((m) =>
                    m.values?.some(
                        (v) =>
                            v.seriesType?.name ===
                            MeasureSeriesNames.PhasedTarget
                    )
                );

            const latestAsOfValue = latestAsOf?.values?.find(
                (v) => v.seriesType?.name === MeasureSeriesNames.PhasedTarget
            );

            return {
                decimalValue: latestAsOfValue?.decimalValue ?? null,
                stringValue: latestAsOfValue?.stringValue ?? null,
                dateValue: latestAsOfValue?.dateValue ?? null,
                formatStr: latestAsOfValue?.formatStr ?? null,
            };
        },
        []
    );

    const getTargetsOutOfSync = (
        valueHistory: MeasureValueHistoryItem[]
    ): {
        entry: MeasureValueHistoryItem;
        phasedTarget: number;
    }[] => {
        const targetsOutOfSync: {
            entry: MeasureValueHistoryItem;
            phasedTarget: number;
        }[] = [];

        valueHistory.forEach((vh) => {
            const target = vh?.values?.find(
                (v) => v.seriesType?.name === MeasureSeriesNames.Target
            );

            if (
                target?.decimalValue !== null &&
                target?.decimalValue !== undefined
            ) {
                const targetPhasing = getPhasingForDate(
                    valueHistory,
                    dayjs(vh.asOfDate).toDate()
                );
                if (
                    targetPhasing?.decimalValue !== null &&
                    targetPhasing?.decimalValue !== undefined &&
                    targetPhasing?.decimalValue !== target.decimalValue
                ) {
                    targetsOutOfSync.push({
                        entry: vh,
                        phasedTarget: targetPhasing.decimalValue,
                    });
                }
            }
        });
        return targetsOutOfSync;
    };

    const hasTargetsOutOfSync = (
        valueHistory: MeasureValueHistoryItem[]
    ): boolean => {
        const targetHistory = valueHistory?.map((v) => {
            return {
                asOfDate: v.asOfDate,
                decimalValue: v.values?.find(
                    (v) => v.seriesType?.name === MeasureSeriesNames.Target
                )?.decimalValue,
            };
        });

        return targetHistory
            .filter(
                (target) =>
                    target.decimalValue !== null &&
                    target.decimalValue !== undefined
            )
            .some((target) => {
                const targetPhasing = getPhasingForDate(
                    valueHistory,
                    dayjs(target.asOfDate).toDate()
                );
                return (
                    targetPhasing?.decimalValue !== null &&
                    targetPhasing?.decimalValue !== undefined &&
                    targetPhasing?.decimalValue !== target.decimalValue
                );
            });
    };

    return {
        hasPhasing: hasPhasing,
        getPhasingForDate: getPhasingForDate,
        hasTargetsOutOfSync: hasTargetsOutOfSync,
        getTargetsOutOfSync: getTargetsOutOfSync,
    };
};
