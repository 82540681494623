import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, Stack } from '@fluentui/react';
import { useThemes } from '../../../hooks/useThemes';
import { useTaskSummary } from '../../../hooks/useTaskSummary';
import { TaskStatusNames, TaskWithStatus } from '../../../data/extendedTypes';
import debounce from 'lodash/debounce';
import { TaskFilterSearchBox } from './TaskFilterSearchBox';
import { TaskFilterDueSelector } from './TaskFilterDueSelector';
import { TaskFilterStatusSelector } from './TaskFilterStatusSelector';
import { TaskFilterResourceSelector } from './TaskFilterResourceSelector';
import { useViewport } from '../../../hooks/useViewport';
import { TaskFilterDateSelector } from './TaskFilterDateSelector';
import { TaskFilterResourcedFromSelector } from './TaskFilterResourcedFromSelector';
import { TaskFilterCategorySelector } from './TaskFilterCategorySelector';

export type TaskFilters = {
    keyword: string | null;
    taskStatusNames: TaskStatusNames[];
    dueDateRanges: { startDate: string; endDate: string }[];
    resources: { userId: string | null; resourceId: string | null }[];
    forDateTime: { isOn: boolean; selectedDate: string | null };
    resourcedFromMissionIds: string[];
    categoryIds: string[];
};

export const DefaultTaskFilters: TaskFilters = {
    keyword: null,
    taskStatusNames: [],
    dueDateRanges: [],
    resources: [],
    forDateTime: { isOn: false, selectedDate: null },
    resourcedFromMissionIds: [],
    categoryIds: [],
};

export function TaskFilterBar(props: {
    tasks: (TaskWithStatus & {
        id: string | null;
        isDuplicate: boolean;
        parentTaskId: string | null;
        resourcedFromTaskId: string | null;
        resourcedTasks:
            | {
                  id: string | null;
                  resource: {
                      id: string | null;
                      userId: string | null;
                      displayName: string | null;
                  } | null;
              }[]
            | null;
        taskCategory: {
            id: string | null;
            name: string | null;
            colourHex: string | null;
        } | null;
        resourcedFromTask: {
            mission: {
                id: string | null;
                userId: string | null;
                owner: string | null;
            } | null;
        } | null;
    })[];
    filters: TaskFilters;
    onFiltersChanged: (filters: TaskFilters) => void;
    onDismiss?: () => void;
    searchBoxPlaceholder?: string;
    showHistoricalFilter?: boolean;
    includeResourcedTasks?: boolean; // Include resourced tasks in filter - such as those on the contributor section
    hideResourceFilter?: boolean;
    hideCategoryFilter?: boolean;
    hideResourcedFromFilter?: boolean;
}): JSX.Element {
    const { currentTheme } = useThemes();
    const { width } = useViewport();

    const hideExtraFilters = width < 620;

    const { onFiltersChanged, hideCategoryFilter } = props;

    const impliedTasks = props.tasks.filter(
        (t) =>
            (t.parentTaskId !== null && !t.isDuplicate) ||
            (props.includeResourcedTasks && t.resourcedFromTaskId)
    );

    const taskSummary = useTaskSummary(impliedTasks);

    const specifiedTasks =
        props.tasks.filter((t) => t.parentTaskId === null) || [];

    const categoryMap = new Map<
        string,
        {
            id: string | null;
            count: number;
            name: string | null;
            colourHex: string | null;
        }
    >();

    props.tasks?.forEach((t) => {
        // Is this an implied?
        if (!t.isDuplicate && t.parentTaskId !== null) {
            // Find the specified
            const parentTask = specifiedTasks.find(
                (st) => st.id === t.parentTaskId
            );

            // Extract the category
            const { id, name, colourHex } = parentTask?.taskCategory || {
                id: null,
                name: 'None',
                colourHex: null,
            };
            if (name !== undefined && colourHex !== undefined) {
                const curr = categoryMap.get(id || 'NONE');
                if (curr) {
                    curr.count += 1;
                } else {
                    categoryMap.set(id || 'NONE', {
                        id,
                        name,
                        colourHex,
                        count: 1,
                    });
                }
            }
        }
    });

    const taskCategories = Array.from(categoryMap.values());

    const [internalFilters, setInternalFilters] = useState<TaskFilters>(
        props.filters
    );

    const debouncedChange = useMemo(
        () => debounce((f) => onFiltersChanged(f), 400),
        [onFiltersChanged]
    );

    useEffect(
        () => debouncedChange(internalFilters),
        [internalFilters, debouncedChange]
    );

    // If the categories are hidden, clear that filter
    useEffect(() => {
        if (hideCategoryFilter && internalFilters.categoryIds.length) {
            setInternalFilters((f) => ({
                ...f,
                categoryIds: [],
            }));
        }
    }, [hideCategoryFilter, internalFilters.categoryIds]);

    const handeKeywordChanged = (keyword: string | null) => {
        setInternalFilters({
            ...internalFilters,
            keyword: keyword,
        });
    };

    const handleResourceFilterChanged = (
        resources: { userId: string | null; resourceId: string | null }[]
    ) => {
        setInternalFilters({
            ...internalFilters,
            resources: resources,
        });
    };

    const handleResourcedFromFilterChanged = (missionIds: string[]) => {
        setInternalFilters({
            ...internalFilters,
            resourcedFromMissionIds: missionIds,
        });
    };

    const handleSelectedDueDateRangesChanged = (
        dueDateRanges: { startDate: string; endDate: string }[]
    ) => {
        setInternalFilters({
            ...internalFilters,
            dueDateRanges: dueDateRanges,
        });
    };

    const handleStatusFilterChanged = (taskStatusNames: TaskStatusNames[]) => {
        setInternalFilters({
            ...internalFilters,
            taskStatusNames: taskStatusNames,
        });
    };

    const handleCategoryFilterChanged = (categoryIds: string[]) => {
        setInternalFilters({
            ...internalFilters,
            categoryIds: categoryIds,
        });
    };

    const handleForDateTimeChanged = (forDateTime: {
        isOn: boolean;
        selectedDate: string | null;
    }) => {
        setInternalFilters({
            ...internalFilters,
            forDateTime: forDateTime,
        });
    };

    const handleClearAndDismissClick = () => {
        props.onFiltersChanged(DefaultTaskFilters);
        if (props.onDismiss) {
            props.onDismiss();
        } else {
            setInternalFilters(DefaultTaskFilters);
        }
    };

    return (
        <Stack
            horizontal
            verticalAlign="center"
            tokens={{
                padding: 4,
                childrenGap: 8,
            }}
            styles={{
                root: {
                    paddingLeft: 8,
                    paddingRight: 8,
                    backgroundColor: currentTheme.semanticColors.infoBackground,
                },
            }}
        >
            <Stack.Item verticalFill align="center" grow>
                <TaskFilterSearchBox
                    keywordValue={internalFilters.keyword}
                    onKeywordChanged={handeKeywordChanged}
                    onSearch={() => debouncedChange.flush()}
                    placeholder={props.searchBoxPlaceholder}
                />
            </Stack.Item>

            {!hideExtraFilters && !props.hideResourceFilter && (
                <Stack.Item>
                    <TaskFilterResourceSelector
                        tasks={props.tasks}
                        selectedResources={internalFilters.resources}
                        onResourceFilterChanged={handleResourceFilterChanged}
                    />
                </Stack.Item>
            )}

            {!hideExtraFilters && (
                <Stack.Item verticalFill align="center">
                    <TaskFilterDueSelector
                        tasks={props.tasks}
                        selectedDueDateRanges={internalFilters.dueDateRanges}
                        onSelectedDueDateRangesChanged={
                            handleSelectedDueDateRangesChanged
                        }
                    />
                </Stack.Item>
            )}

            {!hideExtraFilters && !props.hideCategoryFilter && (
                <Stack.Item>
                    <TaskFilterCategorySelector
                        taskCategories={taskCategories}
                        selectedCategoryIds={internalFilters.categoryIds}
                        onCategoryFilterChanged={handleCategoryFilterChanged}
                    />
                </Stack.Item>
            )}

            <Stack.Item verticalFill align="center">
                <TaskFilterStatusSelector
                    taskSummary={taskSummary}
                    selectedTaskStatuses={internalFilters.taskStatusNames}
                    onStatusFilterChanged={handleStatusFilterChanged}
                />
            </Stack.Item>

            {!hideExtraFilters && !props.hideResourcedFromFilter && (
                <TaskFilterResourcedFromSelector
                    tasks={props.tasks}
                    selectedMissionIds={internalFilters.resourcedFromMissionIds}
                    onResourcedFromFilterChanged={
                        handleResourcedFromFilterChanged
                    }
                />
            )}

            {props.showHistoricalFilter && (
                <Stack.Item verticalFill align="center">
                    <TaskFilterDateSelector
                        forDateTime={internalFilters.forDateTime}
                        setForDateTime={handleForDateTimeChanged}
                    />
                </Stack.Item>
            )}

            <Stack.Item verticalFill align="center">
                <IconButton
                    iconProps={{ iconName: 'Cancel' }}
                    title="Clear and dismiss filters"
                    onClick={handleClearAndDismissClick}
                />
            </Stack.Item>
        </Stack>
    );
}
