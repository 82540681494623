import React from 'react';
import { Dropdown, Icon, IDropdownOption } from '@fluentui/react';
import { useStateContext } from '../../services/contextProvider';
import { InputShimmer } from '.';
import { useGetTaskCategoryOptionsQuery } from '../../data/types';

export type TaskCategoryPickerProps = {
    divisionId: string | null;
    selectedTaskCategoryId?: string | null;
    onChange: (taskCategoryId: string | null) => void;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
};

export function TaskCategoryPicker(
    props: TaskCategoryPickerProps
): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { data, loading } = useGetTaskCategoryOptionsQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            divisionId: props.divisionId,
        },
    });

    const taskCategories = data?.taskCategories || [];
    const options: IDropdownOption[] = [
        {
            key: '',
            text: 'None',
        },
        ...taskCategories.map((c) => {
            return {
                key: c.id || '',
                text: c.name || '',
                data: { colourHex: c.colourHex },
            };
        }),
    ];

    const onRenderOption = (option?: IDropdownOption): JSX.Element => {
        const iconStyles = {
            color: option?.data?.colourHex
                ? `#${option?.data.colourHex}`
                : undefined,
            marginRight: 8,
        };

        return (
            <div style={{ display: 'flex' }}>
                <Icon
                    style={iconStyles}
                    iconName={option?.key ? 'TagSolid' : 'Untag'}
                    aria-hidden="true"
                />
                <span>{option?.text}</span>
            </div>
        );
    };

    const onRenderTitle = (options?: IDropdownOption[]): JSX.Element | null => {
        if (!options || options.length === 0) {
            return null;
        }

        const option = options[0];

        const iconStyles = {
            color: option?.data?.colourHex
                ? `#${option?.data.colourHex}`
                : undefined,
            marginRight: 8,
        };

        return (
            <div style={{ display: 'flex' }}>
                <Icon
                    style={iconStyles}
                    iconName={option?.key ? 'TagSolid' : 'Untag'}
                    aria-hidden="true"
                />
                <span>{option?.text}</span>
            </div>
        );
    };

    const onChange = (
        _event?: React.FormEvent<HTMLDivElement>,
        item?: IDropdownOption
    ): void => {
        if (item) {
            props.onChange((item.key as string) || null);
        } else {
            props.onChange(null);
        }
    };

    return (
        <InputShimmer isDataLoaded={!loading}>
            <Dropdown
                placeholder={props.placeholder}
                label={props.label}
                selectedKey={props.selectedTaskCategoryId || ''}
                onChange={onChange}
                options={options || []}
                onRenderOption={onRenderOption}
                onRenderTitle={onRenderTitle}
                disabled={props.disabled}
                styles={{
                    title: {
                        paddingTop: 0,
                    },
                }}
            />
        </InputShimmer>
    );
}
