import { Label } from '@fluentui/react';
import styles from './ApiHelp.module.css';

export function ApiHelpTasks(): JSX.Element {
    return (
        <div>
            <h1>Tasks</h1>
            A mission may have zero or many Tasks.
            <br />
            <br />
            <Label>OData Query Parameters</Label>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td>
                            <code className={styles.method}>GET</code>
                            <code className={styles.code}>/Task</code>
                        </td>
                        <td>
                            Get a List of Tasks
                            <br />
                            <br />
                            Expandable properties: <br />
                            <code className={styles.code}>
                                ?$expand=Mission,TaskGroup,Currency,Dependencies,DependentOn
                            </code>
                            <br />
                            <br />
                            To expand Task Group and display only Task Group
                            name:
                            <br />
                            <code className={styles.code}>
                                ?$expand=TaskGroup(select=Name)
                            </code>
                            <p>
                                Filter combined with 'contains', 'startsWith',
                                or 'endsWith' functions can be used to query
                                Tasks by Name:
                                <br />
                                <i>
                                    <code className={styles.code}>
                                        $filter=contains(Name, 'Transform')
                                    </code>
                                </i>
                                This shows Tasks with Text starting with the
                                word 'Transform'.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <code className={styles.method}>GET</code>
                            <code className={styles.code}>
                                /Task(&#123;id&#125;)
                            </code>
                        </td>
                        <td>
                            Get single Task
                            <p>
                                Expandable properties: <br />
                                <code className={styles.code}>
                                    ?$expand=Comments(select=UserId,Text),Attachments,LastComment,TaskGroup(select=Name),Values
                                </code>
                            </p>
                            <p>
                                To expand Task Group and display only Task Group
                                name:
                                <br />
                                <code className={styles.code}>
                                    ?$expand=TaskGroup(select=Name)
                                </code>
                            </p>
                            <p>
                                To expand Comments and only display User and
                                Comment text:
                                <br />
                                <code className={styles.code}>
                                    ?$expand=Comments(select=UserId,Text)
                                </code>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <code className={styles.method}>PATCH</code>
                            <code className={styles.code}>/Task</code>
                        </td>
                        <td>
                            Update Task
                            <br />
                            <br />
                            Updatable properties: <br />
                            <code className={styles.code}>Name</code>
                            <br />
                            <code className={styles.code}>PercentComplete</code>
                            <br />
                            <code className={styles.code}>Start</code>
                            <br />
                            <code className={styles.code}>Due</code>
                            <br />
                            <code className={styles.code}>Done</code>
                            <br />
                            <code className={styles.code}>EffortWeight</code>
                            <br />
                            <code className={styles.code}>ImpactWeight</code>
                            <br />
                            <code className={styles.code}>CostWeight</code>
                            <br />
                            <code className={styles.code}>Description</code>
                            <br />
                            <br />
                            Example Body: <br />
                            <code className={styles.code}>
                                [<br />
                                &nbsp;&nbsp;&#123;
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;"Id":
                                "e7bfd902-0fd5-484e-8d3c-227d58bc8cb0",
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;"Name": "Subtask1",
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;"Description":
                                "Description",
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;"PercentComplete": 1.00,
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;"EffortWeight": 1,
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;"ImpactWeight": 2,
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;"CostWeight": 1,
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;"Start":
                                "2025-01-01T00:00:00Z",
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;"Due":
                                "2025-06-02T00:00:00Z",
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;"Done":
                                "2025-06-02T00:00:00Z"
                                <br />
                                &nbsp;&nbsp;&#125;
                                <br />]
                            </code>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
