import React from 'react';

import { MeasureArrowIcon } from '../../../components/MeasureArrowIcon';
import { MeasureSeriesNames } from '../../../data/extendedTypes';
import { PresentationSlide } from './PresentationSlide';

import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { sorters } from '../../../data/sorters';
import { useFormatters } from '../../../hooks/useFormatters';
import { ArrowDirection, Arrows } from '../../../data/types';

export function MissionPresentationSlide(props: {
    mission:
        | {
              missionStatement: string | null;
          }
        | null
        | undefined;
    measures:
        | {
              id: string | null;
              name: string | null;
              utcDeleted: string | null;
              sequence: number;
              isStatusLimited: boolean;
              group: {
                  id: string | null;
                  name: string | null;
              } | null;
              lastAsOf: {
                  statusValue: number | null;
                  arrowColour: Arrows;
                  arrowDirection: ArrowDirection;
                  values:
                      | {
                            seriesType: {
                                name: string | null;
                            } | null;
                            formatStr: string | null;
                        }[]
                      | null;
              } | null;
          }[]
        | null
        | undefined;
}) {
    const { mission, measures } = props;

    const formatters = useFormatters();

    const measureGroups = orderBy(
        uniqBy(
            measures
                ?.filter((m) => m.utcDeleted === null)
                ?.flatMap((m) => m.group),
            (g) => g?.id
        ),
        'sequence'
    );

    return (
        <PresentationSlide key="mission" sectionKey="mission" leftSize="none">
            <PresentationSlide.ListContainer>
                <div className="mintListHeader">
                    <h6>Mission: {mission?.missionStatement}</h6>
                </div>
                <div style={{ paddingRight: 16 }} className="mintListBody">
                    <table>
                        <thead>
                            <tr>
                                <th>Measure of Success</th>
                                <th style={{ textAlign: 'right' }}>Target</th>
                                <th style={{ textAlign: 'right' }}>Actual</th>
                                <th style={{ textAlign: 'right' }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {measureGroups.map((mg) => {
                                const measuresForGroup = measures
                                    ?.filter((m) => m.utcDeleted === null)
                                    ?.filter((m) => m.group?.id === mg?.id);
                                return (
                                    <React.Fragment key={mg?.id || 'UNGROUPED'}>
                                        {measureGroups.length > 1 && (
                                            <tr>
                                                <td colSpan={4}>
                                                    <strong>
                                                        {mg?.name ??
                                                            'Ungrouped'}
                                                    </strong>
                                                </td>
                                            </tr>
                                        )}
                                        {measuresForGroup
                                            ?.sort(sorters.sequenceSorter)
                                            .map((m) => {
                                                const lastAsOf = m.lastAsOf;
                                                const target =
                                                    lastAsOf?.values?.find(
                                                        (v) =>
                                                            v.seriesType
                                                                ?.name ===
                                                            MeasureSeriesNames.Target
                                                    );
                                                const actual =
                                                    lastAsOf?.values?.find(
                                                        (v) =>
                                                            v.seriesType
                                                                ?.name ===
                                                            MeasureSeriesNames.Actual
                                                    );
                                                const formattedPercentage =
                                                    target !== undefined
                                                        ? formatters.formatMeasureStatus(
                                                              lastAsOf?.statusValue,
                                                              m.isStatusLimited
                                                          )
                                                        : '';
                                                return (
                                                    <tr key={m.id}>
                                                        <td
                                                            style={{
                                                                paddingLeft:
                                                                    measureGroups.length >
                                                                    1
                                                                        ? 16
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {m.name}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {target?.formatStr}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {actual?.formatStr}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            {
                                                                formattedPercentage
                                                            }
                                                            <MeasureArrowIcon
                                                                arrowColour={
                                                                    lastAsOf?.arrowColour
                                                                }
                                                                arrowDirection={
                                                                    lastAsOf?.arrowDirection
                                                                }
                                                                iconFontSize={
                                                                    20
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </PresentationSlide.ListContainer>
        </PresentationSlide>
    );
}
