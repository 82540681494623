import React from 'react';
import { SpinButton } from '@fluentui/react';

export function PercentageCompleteInput(props: {
    disabled?: boolean;
    value: number;
    onChange: (newValue: number) => void;
}): JSX.Element {
    return (
        <SpinButton
            styles={{
                root: {
                    maxWidth: 40,
                },
            }}
            value={Math.round(props.value * 100).toString()}
            disabled={props.disabled}
            min={0}
            max={100}
            onBlur={(ev: React.FocusEvent<HTMLInputElement>): void =>
                props.onChange(Number(ev.target.value) / 100)
            }
            onIncrement={(value): void =>
                props.onChange(Math.min(100, +value + 1) / 100)
            }
            onDecrement={(value): void =>
                props.onChange(Math.max(0, +value - 1) / 100)
            }
        />
    );
}
