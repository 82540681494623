import {
    ChartDisplay,
    FrequencyPeriods,
    MeasureTypes,
    ValueTypes,
} from '../../../data/types';

export function useAvailableMeasureChartDisplays(
    measure:
        | {
              frequencyPeriod: FrequencyPeriods;
              //showYtd: boolean
              measureType: MeasureTypes;
              valueType: ValueTypes;
              valueFormula: string | null;
          }
        | undefined
        | null
): {
    chartDisplayOptions: {
        key: ChartDisplay;
        text: string;
    }[];
    isNumberMeasureType: boolean;
    isStringMeasureType: boolean;
    isDateMeasureType: boolean;
} {
    const chartDisplayOptions: {
        key: ChartDisplay;
        text: string;
    }[] = [];

    const isNumberMeasureType =
        measure?.measureType === MeasureTypes.Numeric ||
        measure?.measureType === MeasureTypes.Currency ||
        measure?.measureType === MeasureTypes.Percentage;
    const isStringMeasureType =
        measure?.measureType === MeasureTypes.Gyr ||
        measure?.measureType === MeasureTypes.OnOff ||
        measure?.measureType === MeasureTypes.YesNo;
    const isDateMeasureType =
        measure?.measureType === MeasureTypes.Date ||
        measure?.measureType === MeasureTypes.OnOff ||
        measure?.measureType === MeasureTypes.YesNo;

    const isNumeric =
        measure?.measureType === MeasureTypes.Numeric ||
        measure?.measureType === MeasureTypes.Currency ||
        measure?.measureType === MeasureTypes.Percentage;

    const allowCumulativeValueEntry =
        isNumeric && measure?.valueType === ValueTypes.Incremental;

    const isCalculated = !!measure?.valueFormula;

    switch (measure?.frequencyPeriod) {
        case FrequencyPeriods.Month: {
            chartDisplayOptions.push({
                key: ChartDisplay.ByFrequency,
                text: `Monthly Performance`,
            });
            if (allowCumulativeValueEntry) {
                chartDisplayOptions.push(
                    ...[
                        {
                            key: ChartDisplay.Ftd,
                            text: `Monthly Performance YTD`,
                        },
                        {
                            key: ChartDisplay.Qtd,
                            text: `Quarterly Performance YTD`,
                        },
                        {
                            key: ChartDisplay.Ytd,
                            text: `YTD`,
                        },
                        {
                            key: ChartDisplay.YtdVsFullYear,
                            text: `YTD vs. Full Year Target`,
                        },
                    ]
                );
            }
            if (isCalculated) {
                chartDisplayOptions.push({
                    key: ChartDisplay.Calculated,
                    text: 'Calculated',
                });
            }
            break;
        }

        case FrequencyPeriods.Quarter: {
            chartDisplayOptions.push({
                key: ChartDisplay.ByFrequency,
                text: `Quarterly Performance`,
            });
            if (allowCumulativeValueEntry) {
                chartDisplayOptions.push(
                    ...[
                        {
                            key: ChartDisplay.Ftd,
                            text: `Quarterly Performance YTD`,
                        },
                        {
                            key: ChartDisplay.Ytd,
                            text: `YTD`,
                        },
                        {
                            key: ChartDisplay.YtdVsFullYear,
                            text: `YTD vs. Full Year Target`,
                        },
                    ]
                );
            }
            if (isCalculated) {
                chartDisplayOptions.push({
                    key: ChartDisplay.Calculated,
                    text: 'Calculated',
                });
            }
            break;
        }

        case FrequencyPeriods.Year: {
            chartDisplayOptions.push({
                key: ChartDisplay.Ytd,
                text: `YTD`,
            });
            if (isCalculated) {
                chartDisplayOptions.push({
                    key: ChartDisplay.Calculated,
                    text: 'Calculated',
                });
            }
            break;
        }

        default:
            chartDisplayOptions.push({
                key: ChartDisplay.ByFrequency,
                text: `By As Of Date`,
            });
            if (allowCumulativeValueEntry) {
                chartDisplayOptions.push(
                    ...[
                        {
                            key: ChartDisplay.Ytd,
                            text: `YTD`,
                        },
                        {
                            key: ChartDisplay.YtdVsFullYear,
                            text: `YTD vs. Full Year Target`,
                        },
                    ]
                );
            }
    }

    return {
        chartDisplayOptions,
        isNumberMeasureType,
        isStringMeasureType,
        isDateMeasureType,
    };
}
