import * as React from 'react';
import { Stack } from '@fluentui/react';
import { useStateContext } from '../../../services/contextProvider';
import {
    useGetAllMeasuresQuery,
    useGetTaskCategoriesQuery,
} from '../../../data/types';
import { MeasureFlatList } from '../../../components/MeasureFlatList';
import {
    DefaultMissionFilters,
    MissionFilterBar,
    MissionFilters,
} from '../../MissionBuilder/components/MissionFilterBar';
import { useRefetch } from '../../../hooks/useRefetch';

export function ReportMeasures(props: {
    onMeasureClick: (missionId: string, MeasureId: string) => void;
}): JSX.Element {
    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const [filters, setFilters] = React.useState<MissionFilters>(
        DefaultMissionFilters
    );

    const {
        data,
        loading,
        refetch: _refetchMeasures,
    } = useGetAllMeasuresQuery({
        skip: !currentTenantId || !currentFinancialYearCode,
        variables: {
            tenantId: currentTenantId || '',
            teamId: null,
            financialYearCode: currentFinancialYearCode || '',
            financialYearActiveDate: null,
            forDateTime: null,
            taskCategoryIds: filters.taskCategoryIds,
        },
        fetchPolicy: 'network-only',
    });

    const { data: categoryData } = useGetTaskCategoriesQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            divisionId: null,
            financialYearCode: currentFinancialYearCode || '',
        },
    });

    const [refetch, isRefetching] = useRefetch(_refetchMeasures);

    const handleRefreshClick = async () => {
        await refetch();
    };

    const handleFiltersChanged = (f: MissionFilters) => setFilters(f);

    return (
        <Stack tokens={{ childrenGap: 4 }} styles={{ root: { marginTop: 8 } }}>
            {!!data?.measures && (
                <Stack.Item>
                    <MissionFilterBar
                        taskCategories={categoryData?.taskCategories || []}
                        filters={filters}
                        onFiltersChanged={handleFiltersChanged}
                        searchBoxPlaceholder={'Filter by mission or keyword'}
                    />
                </Stack.Item>
            )}
            <MeasureFlatList
                measures={data?.measures}
                measuresLoading={loading}
                onMeasureClick={props.onMeasureClick}
                filters={filters}
                defaultColumns={[
                    'displaySequence',
                    'missionName',
                    'name',
                    'target',
                    'actual',
                    'status',
                ]}
                onRefreshClick={handleRefreshClick}
                isRefreshing={isRefetching}
            />
        </Stack>
    );
}
