import {
    DeleteTaskMutation,
    DeleteTaskMutationVariables,
    GetTaskQuery,
    useDeleteTaskMutation,
    useGetDependenciesLazyQuery,
    useGetMissionLazyQuery,
    useGetMissionTasksLazyQuery,
} from '../data/types';
import { ExtractQueryArrayType } from '../data/extendedTypes';
import {
    ApolloCache,
    ApolloError,
    DefaultContext,
    MutationUpdaterFunction,
} from '@apollo/client';

export const useTaskDeleter = (
    currentTenantId: string | undefined,
    currentFinancialYearCode: string | undefined,
    task: ExtractQueryArrayType<GetTaskQuery, ['task']>
): {
    canDelete: boolean;
    deleteWillReturnToDependencies: boolean;
    deleteTaskAsync: () => Promise<void>;
    isDeleting: boolean;
    deleteError: ApolloError | undefined;
} => {
    const resourcedFromTask = task?.resourcedFromTask;

    const cacheUpdater: MutationUpdaterFunction<
        DeleteTaskMutation,
        DeleteTaskMutationVariables,
        DefaultContext,
        ApolloCache<unknown>
    > = (cache, mutationResult) => {
        const deletedObject = mutationResult.data?.taskDelete?.deletedObject;
        if (deletedObject?.utcDeleted) {
            cache.evict({ id: cache.identify(deletedObject) });
            deletedObject.subTasks
                ?.filter((st) => st.utcDeleted)
                .forEach((st) => {
                    cache.evict({ id: cache.identify(st) });
                });
            deletedObject.resourcedTasks
                ?.filter((rt) => rt.utcDeleted)
                .forEach((rt) => {
                    cache.evict({ id: cache.identify(rt) });
                });
            cache.gc();
        }
    };

    const [
        deleteTaskMutation,
        { loading: isDeletingTask, error: deleteError },
    ] = useDeleteTaskMutation({
        update: cacheUpdater,
    });

    const [refetchMissionTasksQuery, { loading: isRefetchingTasks }] =
        useGetMissionTasksLazyQuery({
            fetchPolicy: 'network-only',
        });
    const [refetchMissionQuery, { loading: isRefetchingMission }] =
        useGetMissionLazyQuery({
            fetchPolicy: 'network-only',
        });
    const [refetchGetDependenciesQuery, { loading: isRefetchingDependencies }] =
        useGetDependenciesLazyQuery({
            fetchPolicy: 'network-only',
        });

    const deleteTaskAsync = async (): Promise<void> => {
        await deleteTaskMutation({
            variables: {
                tenantId: currentTenantId || '',
                id: task.id || '',
                isTaskResource: false,
                restore: false,
            },
        });

        const refetchPromises = [];

        if (currentTenantId && task.missionId) {
            const options = {
                variables: {
                    tenantId: currentTenantId,
                    missionId: task.missionId,
                },
            };
            refetchPromises.push(refetchMissionTasksQuery(options));
            refetchPromises.push(refetchMissionQuery(options));
        }

        if (
            currentTenantId &&
            currentFinancialYearCode &&
            task.mission?.userId
        ) {
            refetchPromises.push(
                refetchGetDependenciesQuery({
                    variables: {
                        tenantId: currentTenantId,
                        userId: task.mission.userId,
                        financialYearCode: currentFinancialYearCode,
                    },
                })
            );
        }

        await Promise.all(refetchPromises);
    };

    // If this task has been resourced from another task on this mission then the user must delete it from resources (for now!).
    const canDelete =
        task.id &&
        task.mission?.rights?.write &&
        task &&
        (resourcedFromTask === null ||
            (resourcedFromTask?.mission?.id !== task.missionId &&
                !!task.utcAccepted));

    const deleteWillReturnToDependencies: boolean =
        resourcedFromTask !== null &&
        resourcedFromTask !== undefined &&
        resourcedFromTask?.mission?.id !== task.missionId &&
        !!task?.utcAccepted &&
        !task?.utcResourceRemoved;

    const isDeleting =
        isDeletingTask ||
        isRefetchingDependencies ||
        isRefetchingMission ||
        isRefetchingTasks;

    return {
        canDelete: canDelete || false,
        deleteWillReturnToDependencies: deleteWillReturnToDependencies || false,
        deleteTaskAsync,
        isDeleting,
        deleteError,
    };
};
