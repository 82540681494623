import React from 'react';
import { Link } from '@fluentui/react';
import { useStateContext } from '../services/contextProvider';

export default function AttachmentLink(props: {
    attachment: {
        id: string | null;
        href: string | null;
        name: string | null;
        isFile: boolean;
    };
}): JSX.Element {
    const { currentTenantId, configuration } = useStateContext();
    const { storageFnUrl } = configuration;
    const { attachment } = props;

    let href: string | undefined;
    if (attachment.isFile) {
        href = `${storageFnUrl}/api/Attachment?tenantId=${currentTenantId}&attachmentId=${attachment?.id}`;
    } else if (attachment.href) {
        href = attachment.href.startsWith('http')
            ? attachment.href
            : `http://${attachment.href}`;
    }

    return (
        <Link href={href} target="_blank" rel="noopener noreferrer">
            {attachment.name || attachment.href || attachment.id}
        </Link>
    );
}
