import React, { useEffect, useState } from 'react';
import { AdvanceCard } from '../../../components/AdvanceCard';
import {
    DayOfWeek,
    Icon,
    IconButton,
    mergeStyleSets,
    Separator,
    Text,
} from '@fluentui/react';
import { useThemes } from '../../../hooks/useThemes';
import { InlineDatePicker } from '../../../components/inputs/InlineDatePicker';
import { DatePickerStrings } from '../../../services/i18n';
import dayjs from 'dayjs';

type ActionItem = {
    id: string | null;
    statusReportFactId: string;
    name: string | null;
    due: string | null;
    done: string | null;
    sequence: number;
    version: string | null;
};

type StatusReportActionListProps = {
    isReadOnly: boolean;
    isLoading: boolean;
    actions: ActionItem[];
    onChanged: (
        factId: string,
        newActions: {
            id: string | null;
            name: string | null;
            due: string | null;
            done: string | null;
            sequence: number;
        }[]
    ) => void;
};

export function StatusReportActionList(
    props: StatusReportActionListProps
): JSX.Element {
    const [actionList, setActionList] = useState<ActionItem[]>(props.actions);

    useEffect(() => {
        setActionList(props.actions);
    }, [props.actions]);

    const updateActionsForFact = (
        actionId: string,
        transform: (a: ActionItem) => ActionItem
    ) => {
        const action = actionList.find((a) => a.id === actionId);
        if (!action) return;

        const updatedActionList = actionList.map((a) =>
            a.statusReportFactId === action.statusReportFactId
                ? transform(a)
                : a
        );

        const updatedActions = updatedActionList.filter(
            (a) => a.statusReportFactId === action.statusReportFactId
        );

        setActionList(updatedActionList);
        props.onChanged(action.statusReportFactId, updatedActions);
    };

    const handleDateChanged = (
        actionId: string,
        dateType: 'due' | 'done',
        dateValue: string | null
    ) => {
        updateActionsForFact(actionId, (a) => ({
            ...a,
            due: a.id === actionId && dateType === 'due' ? dateValue : a.due,
            done: a.id === actionId && dateType === 'done' ? dateValue : a.done,
        }));
    };

    const handleResetDates = (actionId: string) => {
        updateActionsForFact(actionId, (a) => ({
            ...a,
            due: a.id === actionId ? null : a.due,
            done: a.id === actionId ? null : a.done,
        }));
    };

    const { currentTheme } = useThemes();
    const classNames = mergeStyleSets({
        header: {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
        },

        actionTable: {
            width: '100%',
            backgroundColor: currentTheme.palette.white,
            borderRadius: 8,
            '& td, & th': {
                padding: 4,
            },
            '& tr:not(:last-child) td, & tr th': {
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: currentTheme.palette.neutralLighter,
            },
        },
    });

    return (
        <AdvanceCard style={{ height: '100%' }}>
            <AdvanceCard.Item>
                <div className={classNames.header}>
                    <Icon iconName="CheckList" />
                    <Text variant="mediumPlus" block>
                        Actions
                    </Text>
                </div>
            </AdvanceCard.Item>
            <AdvanceCard.Item fill>
                <Separator
                    styles={{
                        root: {
                            padding: 0,
                        },
                    }}
                />
            </AdvanceCard.Item>
            <AdvanceCard.Item fill>
                <table className={classNames.actionTable}>
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col" style={{ textAlign: 'right' }}>
                                Due
                            </th>
                            <th scope="col" style={{ textAlign: 'right' }}>
                                Done
                            </th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {actionList.map((a) => (
                            <tr key={a.id}>
                                <td>{a.name}</td>
                                <td>
                                    <ActionDatePicker
                                        dateValue={a.due}
                                        isReadOnly={props.isReadOnly}
                                        isLoading={props.isLoading}
                                        onDateChanged={(dateValue) =>
                                            handleDateChanged(
                                                a.id || '',
                                                'due',
                                                dateValue
                                            )
                                        }
                                    />
                                </td>
                                <td>
                                    <ActionDatePicker
                                        dateValue={a.done}
                                        isReadOnly={props.isReadOnly}
                                        isLoading={props.isLoading}
                                        onDateChanged={(dateValue) =>
                                            handleDateChanged(
                                                a.id || '',
                                                'done',
                                                dateValue
                                            )
                                        }
                                    />
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <IconButton
                                        iconProps={{
                                            iconName: 'More',
                                        }}
                                        text="Clear"
                                        menuProps={{
                                            items: [
                                                {
                                                    key: 'reset-dates',
                                                    text: 'Clear dates',
                                                    iconProps: {
                                                        iconName: 'RemoveEvent',
                                                    },
                                                    onClick: () => {
                                                        handleResetDates(
                                                            a.id || ''
                                                        );
                                                    },
                                                },
                                            ],
                                        }}
                                        onRenderMenuIcon={() => null}
                                        styles={{
                                            root: {
                                                height: 16,
                                                width: 16,
                                            },
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </AdvanceCard.Item>
        </AdvanceCard>
    );
}

function ActionDatePicker(props: {
    onDateChanged?: (date: string | null) => void;
    dateValue: string | null;
    isReadOnly: boolean;
    isLoading: boolean;
}): JSX.Element {
    const { onDateChanged, dateValue } = props;

    const formatDate = (date?: Date | string | null): string => {
        return dayjs
            .utc(date || undefined)
            .tz(dayjs.tz.guess())
            .format('DD MMM YYYY');
    };

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            gap: 8,
        },
    });

    if (props.isReadOnly) {
        return <Text>{formatDate(dateValue)}</Text>;
    }

    return (
        <div className={classNames.container}>
            <InlineDatePicker
                formatDate={formatDate}
                strings={DatePickerStrings}
                ariaLabel="Select a date"
                firstDayOfWeek={DayOfWeek.Monday}
                value={dateValue ? dayjs(dateValue).toDate() : undefined}
                placeholder="Enter Date"
                styles={{
                    statusMessage: {
                        display: 'none', // This adds white space at the bottom
                    },
                }}
                onSelectDate={(date: Date | null | undefined): void => {
                    const dateString = date
                        ? dayjs(date).format('YYYY-MM-DD')
                        : null;
                    if (onDateChanged) {
                        onDateChanged(dateString);
                    }
                }}
            />
        </div>
    );
}
