import dayjs from 'dayjs';

class Sorters {
    sequenceSorter = (
        t1: { sequence?: number | null | undefined } | null,
        t2: { sequence?: number | null | undefined } | null
    ): number => {
        const n1 = t1?.sequence || '';
        const n2 = t2?.sequence || '';

        if (n1 > n2) {
            return 1;
        }

        if (n1 < n2) {
            return -1;
        }

        return 0;
    };

    parentIdSorter = (
        t1: { parentTaskId: string | null | undefined },
        t2: { parentTaskId: string | null | undefined }
    ): number => {
        const n1 = t1.parentTaskId || '';
        const n2 = t2.parentTaskId || '';

        if (n1 > n2) {
            return 1;
        }

        if (n1 < n2) {
            return -1;
        }

        return 0;
    };

    asOfDateSorter = (
        m1: { asOfDate: string | null },
        m2: { asOfDate: string | null }
    ): number => {
        if (m1.asOfDate === m2.asOfDate) {
            return 0;
        } else {
            return dayjs(m1.asOfDate || '').isAfter(m2.asOfDate || '') ? 1 : -1;
        }
    };
}

export const sorters = new Sorters();
