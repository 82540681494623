import React from 'react';
import {
    Link,
    Text,
    IColumn,
    DetailsListLayoutMode,
    Selection,
    SelectionMode,
    ConstrainMode,
    DetailsList,
    IDragDropEvents,
    Stack,
    IconButton,
    TooltipHost,
    IButtonStyles,
    FontSizes,
    ActionButton,
    Callout,
} from '@fluentui/react';
import { AdvanceCard } from './AdvanceCard';
import { TaskSummaryItem } from '../scenes/MissionBuilder/components/TaskList';
import { ImpliedTaskListDateColumn } from './ImpliedTaskListDateColumn';
import { ImpliedTaskListProgressColumn } from './ImpliedTaskListProgressColumn';
import TaskProgressIndicator from './TaskProgressIndicator';
import { useViewport } from '../hooks/useViewport';
import { Access } from '../data/extendedTypes';
import { useTaskEditRules } from '../hooks/useTaskEditRules';
import { useInlineTaskUpdater } from '../hooks/useInlineTaskUpdater';
import { ContributorResourcedFromCoin } from './ResourcedFromCoin';
import CommentItem from './CommentItem';
import { useThemes } from '../hooks/useThemes';
import { ContributorTask } from '../scenes/Contributor/hooks/useContributorTasks';
import CommentButton from './CommentButton';
import { useSorter } from '../hooks/useSorter';
import { useTaskFilters } from '../hooks/useTaskFilters';
import { TaskFilters } from '../scenes/MissionBuilder/components/TaskFilterBar';
import {
    GetNotificationsQuery,
    useDismissNotificationMutation,
    useGetNotificationsQuery,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { NotificationItem } from './navigation/NotificationItem';
import { DetailsListCellItemContainer } from './shared/DetailsListCellItemContainer';

export function ContributorTaskList(props: {
    contributorTasks: ContributorTask[];
    onTaskClick: (taskId: string) => void;
    onTaskEditClick: (taskId: string) => void;
    onTaskHistoryClick?: (taskId: string) => void;
    onImpliedTaskDrag?: ((task: TaskSummaryItem) => void) | undefined;
    onCommentsClick?: (taskId: string) => void;
    missionAccess: Access;
    title?: string;
    filters?: TaskFilters;
}): JSX.Element {
    const { currentTenantId, currentUserId } = useStateContext();

    const { width } = useViewport();

    const { currentTheme } = useThemes();

    const { updateTask, busyTaskIds } = useInlineTaskUpdater(null);

    const { data: notificationData } = useGetNotificationsQuery({
        skip: !currentTenantId || !currentUserId,
        variables: {
            tenantId: currentTenantId || '',
            userId: currentUserId || '',
        },
    });

    const taskNotifications = notificationData?.notifications?.filter(
        (n) => n.notificationEvent?.taskId && !n.utcDismissed
    );

    const { filteredImpliedTasks } = useTaskFilters(
        props.filters,
        props.contributorTasks,
        true
    );

    const {
        sortColumn,
        sortIsDesc,
        sortedItems: sortedTasks,
        setSortColumnName,
    } = useSorter(filteredImpliedTasks, 'start', false, false);

    const buttonStyles: IButtonStyles = {
        root: {
            color: currentTheme.palette.themeLighter,
            selectors: {
                '&:hover': {
                    color: currentTheme.palette.themePrimary,
                },
            },
        },
        icon: {
            margin: 0,
        },
    };

    const renderStatusIndicator = (
        task: ContributorTask
    ): JSX.Element | null => {
        return (
            <Stack verticalAlign="center" verticalFill>
                <TaskProgressIndicator
                    task={task}
                    showStatus={false}
                    hidePercentage={false}
                    compact
                />
            </Stack>
        );
    };

    const renderActions = (
        impliedTask: ContributorTask
    ): JSX.Element | null => {
        const renderCommentToolTipContent = () => {
            if (impliedTask.lastComment) {
                return (
                    <CommentItem
                        id={impliedTask.lastComment.id}
                        authorName={impliedTask.lastComment.username}
                        authorUserId={impliedTask.lastComment.userId}
                        content={impliedTask.lastComment.text}
                        dateTime={impliedTask.lastComment.utcCreated}
                    />
                );
            }
            return null;
        };

        const onCommentClick = props.onCommentsClick
            ? () => {
                  if (props.onCommentsClick && impliedTask.id) {
                      props.onCommentsClick(impliedTask.id);
                  }
              }
            : null;

        return (
            <Stack
                horizontal
                horizontalAlign="end"
                verticalFill
                verticalAlign="center"
                tokens={{ childrenGap: 0 }}
                styles={{
                    root: {
                        marginRight: 0,
                    },
                }}
            >
                <Stack.Item align="center" style={{ minWidth: 48 }}>
                    <ContributorResourcedFromCoin task={impliedTask} />
                </Stack.Item>

                {!!onCommentClick && (
                    <Stack.Item align="center">
                        {impliedTask.lastComment ? (
                            <TooltipHost
                                tooltipProps={{
                                    onRenderContent:
                                        renderCommentToolTipContent,
                                }}
                            >
                                <CommentButton
                                    onClick={onCommentClick}
                                    commentCount={impliedTask.commentCount}
                                />
                            </TooltipHost>
                        ) : (
                            <CommentButton
                                onClick={onCommentClick}
                                commentCount={impliedTask.commentCount}
                            />
                        )}
                    </Stack.Item>
                )}

                {impliedTask.resource?.userId === currentUserId && (
                    <Stack.Item align="center">
                        {!!impliedTask.id && !!taskNotifications && (
                            <ContributorNotificationIcon
                                taskId={impliedTask.id}
                                buttonStyles={buttonStyles}
                                notificationData={taskNotifications}
                                onTaskHistoryClick={props.onTaskHistoryClick}
                            />
                        )}
                    </Stack.Item>
                )}
            </Stack>
        );
    };

    let columns: IColumn[] = [
        {
            key: 'name',
            name: 'Task',
            fieldName: 'name',
            isMultiline: true,
            isRowHeader: true,
            minWidth: 100,
            isResizable: true,
            isSorted: sortColumn === 'name',
            isSortedDescending: sortColumn === 'name' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('name', true),
            onRender: function renderName(task: {
                id: string | null;
                name: string | null;
            }) {
                const onClick = () => {
                    if (task.id) {
                        props.onTaskEditClick(task.id);
                    }
                };

                return (
                    <DetailsListCellItemContainer>
                        <Link onClick={onClick}>
                            <Text
                                variant="smallPlus"
                                className="line-clamp3"
                                style={{
                                    whiteSpace: 'pre-line',
                                    minHeight: 16,
                                    verticalAlign: 'middle',
                                }}
                                title={task?.name || ''}
                                block
                            >
                                {task?.name}
                            </Text>
                        </Link>
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'start',
            name: 'Start',
            fieldName: 'start',
            minWidth: 80,
            maxWidth: 80,
            isMultiline: false,
            isSorted: sortColumn === 'start',
            isSortedDescending: sortColumn === 'start' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('start'),
            onRender: (task: ContributorTask): JSX.Element => {
                return (
                    <ImpliedTaskListDateColumn
                        task={task}
                        dateType="start"
                        onTaskEditClick={() =>
                            task.id ? props.onTaskEditClick(task.id) : undefined
                        }
                        missionAccess={props.missionAccess}
                    />
                );
            },
        },
        {
            key: 'due',
            name: 'Due',
            fieldName: 'due',
            minWidth: 80,
            maxWidth: 80,
            isMultiline: false,
            isSorted: sortColumn === 'due',
            isSortedDescending: sortColumn === 'due' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('due'),
            onRender: (task: ContributorTask): JSX.Element => {
                return (
                    <ImpliedTaskListDateColumn
                        task={task}
                        dateType="due"
                        onTaskEditClick={() =>
                            task.id ? props.onTaskEditClick(task.id) : undefined
                        }
                        missionAccess={props.missionAccess}
                    />
                );
            },
        },
        {
            key: 'done',
            name: 'Done',
            fieldName: 'done',
            minWidth: 120,
            maxWidth: 120,
            isMultiline: false,
            isSorted: sortColumn === 'percentComplete',
            isSortedDescending: sortColumn === 'percentComplete' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('percentComplete'),
            onRender: (task: ContributorTask): JSX.Element => {
                const onTaskProgressChanged = (taskUpdates: {
                    utcPostponed: string | null;
                    utcCancelled: string | null;
                    utcAtRisk: string | null;
                    percentComplete: number;
                    done: string | null;
                }) => updateTask(task.id, { ...task, ...taskUpdates });

                return (
                    <ImpliedTaskListProgressColumn
                        allowInlineEdit
                        onChange={onTaskProgressChanged}
                        task={task}
                        mission={{
                            rights: props.missionAccess,
                        }}
                        isBusy={busyTaskIds.includes(task.id || '')}
                    />
                );
            },
        },
        {
            key: 'actions',
            name: 'Actions',
            fieldName: 'actions',
            minWidth: 132,
            isIconOnly: true,
            isPadded: false,
            onRender: renderActions,
        },
    ];

    if (width < 620) {
        columns = columns.filter((c) => c.key === 'name');

        columns.push({
            key: 'statusIndicator',
            name: '',
            fieldName: '',
            minWidth: 32,
            isIconOnly: true,
            isPadded: false,
            onRender: renderStatusIndicator,
        });
    }

    if (props.onImpliedTaskDrag) {
        columns.push({
            key: 'drag',
            name: 'Drag',
            fieldName: 'drag',
            minWidth: 120,
            isIconOnly: true,
            isPadded: false,
            onRender: (task: ContributorTask) => {
                return (
                    <ContributorTaskDragIcon
                        task={task}
                        missionAccess={props.missionAccess}
                    />
                );
            },
        });
    }

    if (width < 900) {
        columns = columns.filter((c) => c.key !== 'actions');
    }

    if (width < 620) {
        columns = columns.filter((c) => c.key === 'name');

        columns.push({
            key: 'statusIndicator',
            name: '',
            fieldName: '',
            minWidth: 32,
            isIconOnly: true,
            isPadded: false,
            onRender: (task): JSX.Element | null => {
                return (
                    <Stack verticalAlign="center" verticalFill>
                        <TaskProgressIndicator
                            task={task}
                            showStatus={false}
                            hidePercentage={false}
                            compact
                        />
                    </Stack>
                );
            },
        });
    }

    const getDragDropEvents = (): IDragDropEvents => {
        return {
            canDrop: () => false,
            canDrag: () => true,
            onDragEnter: (): string => {
                return '';
            },
            onDragLeave: (): void => {
                return;
            },
            onDragStart: (item?: {
                id: string | null;
                name: string | null;
            }): void => {
                if (item?.id && props.onImpliedTaskDrag) {
                    props.onImpliedTaskDrag({
                        ...item,
                        parentTaskId: null,
                        sequence: 0,
                        isDuplicate: false,
                    });
                }
            },
        };
    };

    const selection = new Selection();
    selection.setItems([]);

    return (
        <AdvanceCard style={{ paddingBottom: 0 }}>
            {!!props.title && (
                <AdvanceCard.Item style={{ padding: '8px 0 0 25px' }}>
                    <Text variant="mediumPlus">{props.title}</Text>
                </AdvanceCard.Item>
            )}
            <AdvanceCard.Item fill>
                <DetailsList
                    dragDropEvents={getDragDropEvents()}
                    columns={columns}
                    items={sortedTasks}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    constrainMode={ConstrainMode.unconstrained}
                    selection={selection}
                />
            </AdvanceCard.Item>
        </AdvanceCard>
    );
}

function ContributorNotificationIcon(props: {
    taskId: string;
    onTaskHistoryClick?: (taskId: string) => void;
    notificationData: GetNotificationsQuery['notifications'];
    buttonStyles: IButtonStyles;
}) {
    const { currentTheme } = useThemes();
    const { currentTenantId } = useStateContext();

    const [dismissNotificationMutation] = useDismissNotificationMutation();

    const buttonId = useId('notificationIconButton');
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
        useBoolean(false);

    const notifications = props.notificationData?.filter(
        (t) => t.notificationEvent?.taskId === props.taskId && !t.utcDismissed
    );

    const onNotificationClick = async (
        notificationId: string | null | undefined,
        taskId: string | null | undefined
    ) => {
        if (notificationId && currentTenantId) {
            await dismissNotificationMutation({
                variables: {
                    tenantId: currentTenantId,
                    notificationId: notificationId,
                },
            });
        }
        if (taskId && props.onTaskHistoryClick) {
            props.onTaskHistoryClick(taskId);
        }
    };

    return (
        <React.Fragment>
            <ActionButton
                id={buttonId}
                iconProps={{ iconName: 'Ringer' }}
                text={notifications?.length.toFixed(0)}
                styles={{
                    ...props.buttonStyles,
                    label: {
                        whiteSpace: 'nowrap',
                        fontSize: FontSizes.xSmall,
                        color: notifications?.length
                            ? undefined
                            : currentTheme.palette.themeLighter,
                    },
                    root: {
                        height: 32, // Same as IconButton
                    },
                    icon: {
                        margin: 0,
                        color: notifications?.length
                            ? undefined
                            : currentTheme.palette.themeLighter,
                    },
                }}
                onClick={toggleIsCalloutVisible}
            />
            {isCalloutVisible && !!notifications?.length && (
                <Callout
                    role="dialog"
                    gapSpace={0}
                    target={`#${buttonId}`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                    styles={{
                        root: { maxWidth: 320, padding: 8, cursor: 'pointer' },
                    }}
                >
                    {notifications?.map((n) => (
                        <NotificationItem
                            key={n.id}
                            notification={n}
                            onClick={() =>
                                onNotificationClick(
                                    n.id,
                                    n.notificationEvent?.taskId
                                )
                            }
                            hideItemName
                        />
                    ))}
                </Callout>
            )}
        </React.Fragment>
    );
}

function ContributorTaskDragIcon(props: {
    task: ContributorTask;
    missionAccess: Access;
}) {
    const taskEditRules = useTaskEditRules(props.task, props.missionAccess);

    return (
        <Stack horizontal horizontalAlign="stretch" verticalAlign="center">
            <Stack.Item grow>
                <div />
            </Stack.Item>
            <Stack.Item>
                <IconButton
                    iconProps={{ iconName: 'GripperDotsVertical' }}
                    disabled={!taskEditRules.canDragToMission}
                    styles={{
                        root: {
                            padding: 0,
                            height: 16,
                            cursor: 'grab',
                        },
                        icon: {
                            marginRight: 0,
                        },
                    }}
                />
            </Stack.Item>
        </Stack>
    );
}
