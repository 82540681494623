import React from 'react';
import { Panel, PanelType } from '@fluentui/react';
import MeasureHistoryFeed from '../../../components/MeasureHistoryFeed';

export default function MeasureHistoryPanel(props: {
    measureId: string | null;
    showPanel: boolean;
    onDismiss: () => void;
}): JSX.Element {
    return (
        <Panel
            isOpen={props.showPanel}
            type={PanelType.medium}
            onDismiss={props.onDismiss}
            headerText="History"
            closeButtonAriaLabel="Close"
        >
            {!!props.measureId && (
                <MeasureHistoryFeed measureId={props.measureId} />
            )}
        </Panel>
    );
}
