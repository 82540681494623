import React from 'react';
import {
    IBasePickerSuggestionsProps,
    IPeoplePickerItemSuggestionStyles,
    IPersonaProps,
    IPersonaStyles,
    NormalPeoplePicker,
    PeoplePickerItemSuggestion,
    PersonaSize,
} from '@fluentui/react';
import {
    GetMeasureSearchDocument,
    GetMeasureSearchQuery,
    GetMeasureSearchQueryVariables,
} from '../data/types';
import { useApolloClient } from '@apollo/client';
import { useStateContext } from '../services/contextProvider';
import { photoService } from '../services/photo.service';

export function MeasurePicker(props: {
    excludeMeasureIds?: string[];
    onMeasureSelected: (measureId: string | null) => void;
    suggestedMeasuresMissionId?: string | null;
}): JSX.Element {
    interface IMeasurePersonaProps extends IPersonaProps {
        measureId: string;
    }

    const client = useApolloClient();
    const { currentTenantId, currentFinancialYearCode } = useStateContext();
    const measureQueryAbortController = React.useRef<AbortController>();

    const personaStyles: Partial<IPersonaStyles> = {
        root: {
            height: 'auto',
        },
        secondaryText: {
            height: 'auto',
        },
        primaryText: {
            height: 'auto',
            whiteSpace: 'normal',
        },
    };

    const peoplePickerItemSuggestionStyles: Partial<IPeoplePickerItemSuggestionStyles> =
        {
            personaWrapper: {
                width: 'auto',
                maxWidth: 300,
            },
        };

    const handleRenderSuggestionItem = (
        personaProps: IPersonaProps,
        suggestionsProps: IBasePickerSuggestionsProps
    ) => {
        return (
            <PeoplePickerItemSuggestion
                styles={peoplePickerItemSuggestionStyles}
                personaProps={{ ...personaProps, styles: personaStyles }}
                suggestionsProps={suggestionsProps}
            />
        );
    };

    const getPersonas = (queryResult: {
        data: GetMeasureSearchQuery;
    }): IMeasurePersonaProps[] => {
        return (
            queryResult.data.measures
                ?.filter(
                    (m) => !props.excludeMeasureIds?.some((e) => e === m.id)
                )
                ?.filter(
                    (m) =>
                        !(
                            // Exclude linked measures if the linked from measure has been deleted
                            (
                                m.isLinked &&
                                (m.linkedFromMeasure?.utcDeleted ||
                                    m.linkedFromMeasure?.mission?.utcDeleted ||
                                    m.linkedFromMeasure?.mission?.utcInactive ||
                                    !m.linkedFromMeasure?.mission?.rights.read)
                            )
                        )
                )
                .map(
                    (m) =>
                        ({
                            measureId: m.id || '',
                            text: m.name || 'Untitled',
                            secondaryText: m.mission
                                ? [m.mission.owner, m.mission.title].join(' - ')
                                : undefined,
                            imageUrl: photoService.getImageUrl(
                                m.mission?.userId
                            ),
                            optionalText: m.description || undefined,
                            size: PersonaSize.size24,
                        } as IMeasurePersonaProps)
                ) || []
        );
    };

    const handleResolveSuggestions = async (
        filterText: string
    ): Promise<IPersonaProps[]> => {
        measureQueryAbortController.current?.abort();
        measureQueryAbortController.current = new window.AbortController();

        const results: IMeasurePersonaProps[] = [];
        const measureQuery = client.query<
            GetMeasureSearchQuery,
            GetMeasureSearchQueryVariables
        >({
            query: GetMeasureSearchDocument,
            fetchPolicy: 'network-only',
            variables: {
                tenantId: currentTenantId || '',
                financialYearCode: currentFinancialYearCode || '',
                searchText: filterText,
                missionId: null,
            },
            context: {
                queryDeduplication: false,
                fetchOptions: {
                    signal: measureQueryAbortController.current?.signal,
                },
            },
        });

        await measureQuery.then((queryResult) => {
            results.push(...getPersonas(queryResult));
        });

        return results;
    };

    const handleEmptyResolveSuggestions = async (): Promise<
        IMeasurePersonaProps[]
    > => {
        const suggestedPersonas: IMeasurePersonaProps[] = [];

        if (props.suggestedMeasuresMissionId) {
            const measureQuery = client.query<
                GetMeasureSearchQuery,
                GetMeasureSearchQueryVariables
            >({
                query: GetMeasureSearchDocument,
                variables: {
                    tenantId: currentTenantId || '',
                    financialYearCode: currentFinancialYearCode || '',
                    missionId: props.suggestedMeasuresMissionId,
                    searchText: null,
                },
            });

            await measureQuery.then((queryResult) => {
                suggestedPersonas.push(...getPersonas(queryResult));
            });
        }

        return suggestedPersonas;
    };

    const handleChange = (items?: IPersonaProps[] | undefined): void => {
        if (items?.length) {
            const measureItem = items[0] as IMeasurePersonaProps;
            props.onMeasureSelected(measureItem.measureId || null);
        } else {
            props.onMeasureSelected(null);
        }
    };

    const suggestionProps: IBasePickerSuggestionsProps | undefined =
        props.suggestedMeasuresMissionId
            ? {
                  suggestionsHeaderText: 'Measures of Success',
                  mostRecentlyUsedHeaderText: 'Team leader measures of success',
                  noResultsFoundText: 'No results found',
                  loadingText: 'Loading...',
                  showRemoveButtons: false,
                  suggestionsAvailableAlertText: 'Available',
                  suggestionsContainerAriaLabel: 'Measures of Success',
              }
            : undefined;

    return (
        <NormalPeoplePicker
            itemLimit={1}
            onResolveSuggestions={handleResolveSuggestions}
            onRenderSuggestionsItem={handleRenderSuggestionItem}
            onChange={handleChange}
            pickerSuggestionsProps={suggestionProps}
            onEmptyResolveSuggestions={handleEmptyResolveSuggestions}
            inputProps={{
                placeholder: 'Search',
            }}
        />
    );
}
