import React from 'react';
import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';

export default function TaskWarningPanel(props: {
    warning: {
        hasWarning: boolean;
        warningMessage: string | null;
        warningDetail?: string | null;
        actions?: {
            name: string;
            disabled?: boolean;
        }[];
    };
    onActionClick?: (actionName: string) => void | Promise<void>;
}): JSX.Element | null {
    if (!props.warning.hasWarning) {
        return null;
    }

    return (
        <MessageBar
            messageBarType={MessageBarType.warning}
            messageBarIconProps={{
                iconName: 'AlertSolid',
            }}
            actions={
                props.warning.actions ? (
                    <div>
                        {props.warning.actions.map((a, index) => {
                            const onClick =
                                props.onActionClick !== undefined
                                    ? async () => {
                                          if (props.onActionClick) {
                                              await props.onActionClick(a.name);
                                          }
                                      }
                                    : undefined;
                            return (
                                <MessageBarButton
                                    key={index}
                                    onClick={onClick}
                                    disabled={a.disabled}
                                >
                                    {a.name}
                                </MessageBarButton>
                            );
                        })}
                    </div>
                ) : undefined
            }
        >
            {props.warning.warningMessage}

            <p style={{ whiteSpace: 'pre-wrap' }}>
                {props.warning.warningDetail}
            </p>
        </MessageBar>
    );
}
