import React from 'react';
import { ActionButton, IContextualMenuItem } from '@fluentui/react';

export function TaskFilterDateSelector(props: {
    forDateTime: { isOn: boolean; selectedDate: string | null };
    setForDateTime: (forDateTime: {
        isOn: boolean;
        selectedDate: string | null;
    }) => void;
}): JSX.Element | null {
    const menuItems: IContextualMenuItem[] = !props.forDateTime.isOn
        ? [
              {
                  key: 'enableForDateTimeFilter',
                  iconProps: { iconName: 'Clock' },
                  text: 'Show Historical',
                  onClick: () => {
                      props.setForDateTime({
                          ...props.forDateTime,
                          isOn: true,
                      });
                  },
              },
          ]
        : [
              {
                  key: 'clear',
                  text: 'Clear',
                  iconProps: {
                      iconName: 'Cancel',
                  },
                  onClick: () => {
                      props.setForDateTime({
                          ...props.forDateTime,
                          isOn: false,
                      });
                  },
              },
          ];

    const text = `${
        props.forDateTime.isOn ? 'Show Historical' : 'Most Recent'
    } `;

    return (
        <ActionButton
            text={text}
            menuProps={{
                items: menuItems,
            }}
        />
    );
}
