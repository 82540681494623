import React from 'react';
import { Panel, PanelType } from '@fluentui/react';
import TaskHistoryFeed from './TaskHistoryFeed';

export default function TaskHistoryPanel(props: {
    taskId: string | null | undefined;
    showPanel: boolean;
    onDismiss: () => void;
}): JSX.Element {
    return (
        <Panel
            isOpen={props.showPanel}
            type={PanelType.medium}
            onDismiss={props.onDismiss}
            headerText="History"
            closeButtonAriaLabel="Close"
        >
            {!!props.taskId && <TaskHistoryFeed taskId={props.taskId} />}
        </Panel>
    );
}
