import { useState } from 'react';
import { Link, Text } from '@fluentui/react';
import { ReportPeriodTypes } from '../../data/types';
import { useReportPeriods } from '../../hooks/useReportPeriods';
import { ReportPeriodChangeDialog } from './ReportPeriodChangeDialog';

export function ReportPeriodLabel(props: {
    report: {
        reportPeriod: number | null;
        reportPeriodType: ReportPeriodTypes | null;
    };
    fyStartDate: string | null | undefined;
    fyEndDate: string | null | undefined;
    isReadOnly: boolean;
    onPeriodSaveClick: (
        reportPeriodType: ReportPeriodTypes,
        reportPeriod: number
    ) => void;
}): JSX.Element | null {
    const { report, isReadOnly, fyStartDate, fyEndDate, onPeriodSaveClick } =
        props;

    const [hidePeriodChangeDialog, setHidePeriodChangeDialog] = useState(true);

    const { formatPeriod } = useReportPeriods(fyStartDate, fyEndDate);

    const reportPeriodText = formatPeriod(
        report.reportPeriodType,
        report.reportPeriod
    );

    const handlePeriodSaveClick = (
        reportPeriodType: ReportPeriodTypes,
        reportPeriod: number
    ) => {
        onPeriodSaveClick(reportPeriodType, reportPeriod);
        setHidePeriodChangeDialog(true);
    };

    if (
        report?.reportPeriod &&
        !!report?.reportPeriodType &&
        report?.reportPeriodType !== ReportPeriodTypes.None
    ) {
        return (
            <>
                <div>
                    <Text variant="smallPlus" style={{ fontWeight: 600 }}>
                        Reporting Period:{' '}
                    </Text>
                    {!isReadOnly ? (
                        <Link onClick={() => setHidePeriodChangeDialog(false)}>
                            {reportPeriodText}
                        </Link>
                    ) : (
                        <Text>{reportPeriodText}</Text>
                    )}
                </div>

                <ReportPeriodChangeDialog
                    fyStartDate={fyStartDate}
                    fyEndDate={fyEndDate}
                    hidden={hidePeriodChangeDialog}
                    onPeriodSaveClick={handlePeriodSaveClick}
                    onDismiss={() => setHidePeriodChangeDialog(true)}
                    reportPeriodType={report.reportPeriodType}
                    reportPeriod={report.reportPeriod}
                />
            </>
        );
    }

    return null;
}
