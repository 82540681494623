import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { Gantt, GanttProps, Task, ViewMode } from 'gantt-task-react';
import {
    Access,
    ExtractQueryArrayType,
    taskStatusUtil,
} from '../data/extendedTypes';
import {
    GetMissionTasksQuery,
    TaskStatusInput,
    UpdateTaskStatusMutation,
    useUpdateTaskStatusMutation,
} from '../data/types';
import { TaskFilters } from '../scenes/MissionBuilder/components/TaskFilterBar';

import 'gantt-task-react/dist/index.css';

import dayjs from 'dayjs';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    ITextProps,
    Stack,
    Text,
} from '@fluentui/react';
import { useThemes } from '../hooks/useThemes';
import TaskProgressIndicator from './TaskProgressIndicator';
import { useStateContext } from '../services/contextProvider';
import { useResourceUpdater } from '../hooks/useResourceUpdater';
import { useTaskFilters } from '../hooks/useTaskFilters';
import { useTaskWarning } from '../hooks/useTaskWarning';
import TaskWarningPanel from './TaskWarningPanel';

type ImpliedTaskGanttProps = {
    impliedTasks: GetMissionTasksQuery['tasks'] | null;
    missionUserId: string | null;
    missionAccess: Access;
    specifiedTask: {
        id: string | null;
    } | null;
    filters?: TaskFilters;
    ganttStartDate: string | null;
    ganttEndDate: string | null;
    onTaskEditClick: (taskId: string) => void;
};

export function ImpliedTaskGantt(props: ImpliedTaskGanttProps): JSX.Element {
    const { currentTenantId, currentFinancialYearCode } = useStateContext();
    const { currentTheme } = useThemes();
    const [showProgressDialog, setShowProgressDialog] = useState(false);

    const minColumnWidth = 60;

    const resourceUpdater = useResourceUpdater(
        currentTenantId || null,
        currentFinancialYearCode || null,
        props.missionUserId
    );

    const containerRef = useRef<HTMLDivElement>(null);
    const [columnWidth, setColumnWidth] = useState(minColumnWidth);

    const { hasFilters, filteredImpliedTasks } = useTaskFilters(
        props.filters,
        props.impliedTasks
    );

    const startDates = props.impliedTasks
        ?.filter((d) => d.start)
        .map((d) => dayjs(d.start));
    const endDates = props.impliedTasks
        ?.filter((d) => d.due)
        .map((d) => dayjs(d.due));
    const startDate = startDates?.length
        ? startDates.length === 1
            ? startDates[0]
            : dayjs.min(...startDates) || dayjs()
        : dayjs();
    const endDate = endDates?.length
        ? endDates.length === 1
            ? endDates[0]
            : dayjs.max(...endDates)
        : dayjs();

    // Gantt library needs to have 1 month before the first date.
    const preStepsCount =
        1 +
        dayjs(props.ganttStartDate)
            .date(1)
            .hour(0)
            .minute(0)
            .second(0)
            .diff(startDate.date(1).hour(0).minute(0).second(0), 'month') *
            -1;

    const setWidth = useCallback(() => {
        if (containerRef.current) {
            const ganttStart = dayjs(
                `${dayjs(startDate).subtract(1, 'month')}`
            ).set('date', 1);
            const ganttEnd = dayjs(`${dayjs(endDate).year() + 1}-01-31`);

            const months = dayjs(ganttEnd).diff(ganttStart, 'month') + 1;

            let newWidth = months
                ? containerRef.current.offsetWidth / months
                : minColumnWidth;

            newWidth = newWidth < minColumnWidth ? minColumnWidth : newWidth;

            setColumnWidth(newWidth);
        }
    }, [startDate, endDate]);

    useLayoutEffect(() => {
        window.addEventListener('resize', setWidth);
        return () => window.removeEventListener('resize', setWidth);
    }, [setWidth]);

    useLayoutEffect(() => setWidth(), [setWidth]);

    useEffect(() => setWidth(), [props.impliedTasks, setWidth]);

    const [updateTaskStatusMutation] = useUpdateTaskStatusMutation();

    const tasks: Task[] =
        filteredImpliedTasks?.map((it) => {
            const status = taskStatusUtil.GetTaskStatus(
                it,
                currentFinancialYearCode
            );
            const statusColour = taskStatusUtil.GetStatusColour(status);
            const task: Task = {
                id: it.id || '',
                name: it.name || '',
                type: 'task',
                start: it.start ? new Date(it.start) : new Date(),
                end:
                    it.due || it.done
                        ? new Date(it.due || it.done || '')
                        : new Date(),
                isDisabled: !props.missionAccess.write || it.done !== null,
                progress: Math.round((it.percentComplete ?? 0) * 100),
                styles: {
                    progressColor: statusColour,
                    progressSelectedColor: statusColour,
                },
            };
            return task;
        }) || [];

    if (tasks.length === 0) {
        const message = hasFilters
            ? 'No implied tasks found matching the current filter.'
            : 'No implied tasks found.';
        return (
            <Text
                variant="small"
                styles={{ root: { paddingLeft: 48, fontStyle: 'italic' } }}
            >
                {message}
            </Text>
        );
    }

    const renderTooltipContent = (toolTipProps: {
        task: Task;
    }): JSX.Element | null => {
        const { task } = toolTipProps;
        const impliedTask = props.impliedTasks?.find((t) => t.id === task.id);

        if (!impliedTask) {
            return null;
        }

        const handleEditClick = () => {
            props.onTaskEditClick(task.id);
        };

        return (
            <ImpliedTaskGanttToolTip
                task={{
                    ...impliedTask,
                    // Use the values from the task as these could have changed
                    start: dayjs(task.start).format('YYYY-MM-DD'),
                    due: dayjs(task.end).format('YYYY-MM-DD'),
                    percentComplete: task.progress / 100,
                }}
                onTaskEditClick={handleEditClick}
            />
        );
    };

    const handleDoubleClick = (task: Task) => {
        props.onTaskEditClick(task.id);
    };

    const handleTaskUpdate = async (
        task: Task,
        syncResources = false
    ): Promise<boolean> => {
        const impliedTask = props.impliedTasks?.find((t) => t.id === task.id);

        if (!impliedTask) {
            return false;
        }

        const input: TaskStatusInput = {
            id: impliedTask.id,
            version: impliedTask.version,
            percentComplete: task.progress / 100,
            start: dayjs(task.start).format('YYYY-MM-DD'),
            due: dayjs(task.end).format('YYYY-MM-DD'),
            done: impliedTask.done
                ? dayjs(impliedTask.done).format('YYYY-MM-DD')
                : null,
        };

        if (syncResources) {
            await resourceUpdater.syncResources({
                ...impliedTask,
                ...input,
            });
        }

        const optimisticResponse: UpdateTaskStatusMutation = {
            __typename: 'Mutations',
            taskStatusUpdate: {
                ...impliedTask,
                ...input,
                utcUpdated: null,
                subTasks: [],
                parentTask: null,
                resourcedFromTask: null,
                __typename: 'TaskQL',
            },
        };

        await updateTaskStatusMutation({
            variables: {
                tenantId: currentTenantId || '',
                input: input,
            },
            optimisticResponse: optimisticResponse,
        });

        return true;
    };

    const handleDateChange = async (task: Task): Promise<boolean> => {
        return handleTaskUpdate(task, true);
    };

    const handleProgressChange = async (task: Task): Promise<boolean> => {
        const impliedTask = props.impliedTasks?.find((t) => t.id === task.id);

        if (
            !impliedTask ||
            impliedTask.isPercentageCompleteFromResources ||
            impliedTask.isPercentageCompleteFromSubTasks
        ) {
            if (impliedTask?.isPercentageCompleteFromResources) {
                setShowProgressDialog(true);
            }

            return false;
        }

        return handleTaskUpdate(task);
    };

    const ganttProps: GanttProps = {
        tasks: tasks,
        viewMode: ViewMode.Month,
        rowHeight: 36,
        columnWidth: columnWidth,
        fontFamily:
            '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif',
        fontSize: '12px',
        listCellWidth: '',
        todayColor: currentTheme.palette.themeLighterAlt,
        TooltipContent: renderTooltipContent,
        onDoubleClick: handleDoubleClick,
        onDateChange: props.missionAccess.write ? handleDateChange : undefined,
        onProgressChange: props.missionAccess.write
            ? handleProgressChange
            : undefined,
        preStepsCount: preStepsCount,
    };

    return (
        <React.Fragment>
            <Dialog
                hidden={!showProgressDialog}
                onDismiss={() => setShowProgressDialog(false)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Completion restricted',
                    closeButtonAriaLabel: 'Close',
                    subText:
                        'The percentage complete is rolled up from the resources and cannot be changed.',
                }}
                modalProps={{
                    isBlocking: true,
                }}
            >
                <DialogFooter>
                    <DefaultButton
                        onClick={() => setShowProgressDialog(false)}
                        text="OK"
                    />
                </DialogFooter>
            </Dialog>
            <div ref={containerRef} style={{ marginTop: 16 }}>
                <Gantt {...ganttProps} />
            </div>
        </React.Fragment>
    );
}

function ImpliedTaskGanttToolTip(props: {
    task: ExtractQueryArrayType<GetMissionTasksQuery, ['tasks']>;
    onTaskEditClick: () => void;
}): JSX.Element {
    const { currentTheme } = useThemes();
    const { task } = props;

    const { hasWarning, warningMessage, warningDetail } = useTaskWarning(task, {
        isEditing: false,
        onOpenEditTask: () => props.onTaskEditClick,
    });

    const resourceList = task.resourcedTasks
        ?.map((rt) => rt.resource?.displayName)
        .join(', ');

    const labelProps: ITextProps = {
        variant: 'smallPlus',
        styles: { root: { fontWeight: 'bold' } },
    };

    const labelValueProps: ITextProps = {
        variant: 'small',
    };

    return (
        <Stack
            tokens={{ padding: 8, childrenGap: 8 }}
            styles={{
                root: {
                    maxWidth: 400,
                    backgroundColor: currentTheme.palette.white,
                    boxShadow: currentTheme.effects.elevation16,
                },
            }}
        >
            {!!task.resourcedFromTask?.mission?.owner && (
                <Stack.Item>
                    <Text {...labelProps}>Accepted Task:</Text>{' '}
                    <Text {...labelValueProps}>
                        {task.resourcedFromTask?.mission?.owner} -{' '}
                        {task.resourcedFromTask?.mission?.title}
                    </Text>
                </Stack.Item>
            )}

            <Text variant="medium" block>
                {task.name}
            </Text>

            <Stack.Item>
                <TaskProgressIndicator task={task} showStatus />
            </Stack.Item>

            <Stack.Item>
                <TaskWarningPanel
                    warning={{
                        hasWarning: hasWarning,
                        warningMessage: warningMessage,
                        warningDetail: warningDetail,
                    }}
                />
            </Stack.Item>

            <Stack.Item>
                <Text {...labelProps}>Start:</Text>{' '}
                <Text {...labelValueProps}>
                    {task.start
                        ? dayjs.utc(task.start).format('DD MMM YYYY')
                        : ' - '}
                </Text>
            </Stack.Item>
            <Stack.Item>
                <Text {...labelProps}>Due:</Text>{' '}
                <Text {...labelValueProps}>
                    {task.due
                        ? dayjs.utc(task.due).format('DD MMM YYYY')
                        : ' - '}
                </Text>
            </Stack.Item>
            {task.done && (
                <Stack.Item>
                    <Text {...labelProps}>Done:</Text>{' '}
                    <Text {...labelValueProps}>
                        {dayjs.utc(task.done).format('DD MMM YYYY')}
                    </Text>
                </Stack.Item>
            )}

            {!!resourceList && (
                <Stack.Item>
                    <Text {...labelProps}>Resources:</Text>{' '}
                    <Text {...labelValueProps}>{resourceList}</Text>
                </Stack.Item>
            )}

            <Stack.Item>
                <Text
                    variant="tiny"
                    styles={{
                        root: {
                            fontStyle: 'italic',
                        },
                    }}
                >
                    Double click to open
                </Text>
            </Stack.Item>
        </Stack>
    );
}
