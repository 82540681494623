import React, { useCallback, useEffect, useState } from 'react';
import {
    useGetImporterTimestampsQuery,
    useRollbackMeasureImportMutation,
} from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import {
    ShimmeredDetailsList,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    Stack,
    Persona,
    PersonaSize,
    Selection,
    DefaultButton,
    ActivityItem,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
} from '@fluentui/react';
import { useSorter } from '../../../hooks/useSorter';
import { photoService } from '../../../services/photo.service';
import dayjs from 'dayjs';
import { useThemes } from '../../../hooks/useThemes';
import { HistoryCoin } from '../../../components/HistoryCoin';

type BatchDetailsProps = {
    selectedBatchId?: string;
    setSelectedBatchId?: (batchId: string | undefined) => void;
};

interface FeedItem {
    key: string;
    type: string;
    person: string | undefined;
    actionText: string;
    text: string | React.ReactNode;
    timeStamp: string;
    userId: string | undefined;
    iconName: string | undefined;
}

export function BatchDetails({
    selectedBatchId,
    setSelectedBatchId,
}: BatchDetailsProps): JSX.Element {
    const { currentTenantId } = useStateContext();
    const [isPreview, setIsPreview] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const showTabs = false;

    const { currentTheme } = useThemes();

    const { data: timestamps, loading: timestampsLoading } =
        useGetImporterTimestampsQuery({
            skip: !currentTenantId,
            variables: {
                tenantId: currentTenantId || '',
                batchId: selectedBatchId ?? null,
            },
        });

    const handleUpdatedCompleted = async () => {
        console.log('useRollbackMeasureImportMutation completed');
        // setHasSaved(true);
        // await new Promise((res) => setTimeout(res, 1000));
        // if (props.onSave) {
        //     props.onSave();
        // }
    };

    const [
        rollbackMeasure,
        { data, loading: rollbackLoading, error: rollbackError },
    ] = useRollbackMeasureImportMutation({
        onCompleted: handleUpdatedCompleted,
    });

    const handleUpdateClick = useCallback(async (): Promise<void> => {
        if (currentTenantId && selectedBatchId) {
            console.log('calling rollback, isPreview=true');
            await rollbackMeasure({
                variables: {
                    tenantId: currentTenantId,
                    batchId: selectedBatchId,
                    preview: true,
                },
            });
        }
    }, [currentTenantId, rollbackMeasure, selectedBatchId]);

    useEffect(() => {
        if (selectedBatchId) {
            handleUpdateClick();
        }
    }, [selectedBatchId, handleUpdateClick]);

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(timestamps?.importerTimestamps, 'owner', false, true);

    const _selection = new Selection({
        onSelectionChanged: () => {
            // if (props.onMissionIdsSelected) {
            //     const missionIds = _selection
            //         .getSelection()
            //         .map((s) => {
            //             const m = s as MissionQl;
            //             return m.id;
            //         })
            //         .filter(notNull);
            //     props.onMissionIdsSelected(missionIds);
            // }
            // if (props.onMissionsSelected) {
            //     const missions = _selection
            //         .getSelection()
            //         .map((s) => s as MissionQl);
            //     props.onMissionsSelected(missions);
            // }
        },
        // getKey: (item: MissionQl) => item.id || 0,
    });

    const columns: IColumn[] = [
        {
            key: 'action',
            name: 'Action',
            fieldName: 'action',
            minWidth: 100,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: sortColumn === 'action',
            isSortedDescending: sortColumn === 'action' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('owner', true),
        },
        {
            key: 'table',
            name: 'Entity',
            fieldName: 'table',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            isSorted: sortColumn === 'table',
            isSortedDescending: sortColumn === 'table' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('table', true),
        },
        {
            key: 'username',
            name: 'User',
            fieldName: 'username',
            minWidth: 200,
            isResizable: true,
            isSorted: sortColumn === 'username',
            isSortedDescending: sortColumn === 'username' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('username', true),
            onRender: function renderDisplayName(item: {
                updatedByUserId: string | null;
                username: string | null;
            }): JSX.Element {
                if (item.updatedByUserId) {
                    return (
                        <Persona
                            text={item.username || item.updatedByUserId}
                            imageUrl={photoService.getImageUrl(
                                item.updatedByUserId
                            )}
                            size={PersonaSize.size24}
                        />
                    );
                } else {
                    return <span />;
                }
            },
        },
    ];

    const unknownUserDisplayName = 'A system process';

    const feedItems: FeedItem[] = [];

    timestamps?.importerTimestamps?.forEach((after) => {
        const valueChanges: string[] = [];

        const lastAsOf = {
            asOfDate: '01 Mar 2022',
            arrowDirection: 'Up',
            values: [
                {
                    seriesType: { name: 'Actual' },
                    formatStr: '50%',
                },
                {
                    seriesType: { name: 'Target' },
                    formatStr: '100%',
                },
            ],
        };

        (lastAsOf.values || []).forEach((v) => {
            valueChanges.push(
                `The ${v?.seriesType?.name} was updated to ${v?.formatStr}.`
            );
        });

        const iconName =
            lastAsOf?.arrowDirection?.toUpperCase() === 'UP'
                ? 'StockUp'
                : lastAsOf?.arrowDirection?.toUpperCase() === 'DOWN'
                  ? 'StockDown'
                  : 'StatusCircleInner';

        feedItems.push({
            // key: `lastAsOf_${after.utcUpdated?.toString()}`,
            key: `lastAsOf_${after.utcBatchDate?.toString()}`,
            type: 'asOf',
            person: after?.username || unknownUserDisplayName,
            userId: after?.updatedByUserId || undefined,
            iconName: iconName,
            actionText: 'updated the values',
            // text: `The measure values were updated with an as of date of ${after?.lastAsOf.asOfDate}. ` + valueChanges.join(' '),
            text:
                `The measure values were updated with an as of date of ${lastAsOf.asOfDate}. ` +
                valueChanges.join(' '),
            timeStamp: after.sysStartTime ?? '',
        });
    });

    const feedItems2: FeedItem[] = [];

    data?.measureImporterRollback?.items.forEach((after, index) => {
        feedItems2.push({
            key: `measureUpdate_${index}`,
            type: 'asOf',
            person: after?.batch.username || unknownUserDisplayName,
            userId: after?.batch.updatedByUserId || undefined,
            iconName: 'StatusCircleInner',
            actionText: 'updated a measure',
            text: after?.text,
            timeStamp: after?.batch.utcBatchDate ?? '',
        });
    });

    return (
        <Stack
            tokens={{
                childrenGap: '24',
            }}
        >
            <Stack.Item>
                <DefaultButton
                    text="Back"
                    iconProps={{
                        iconName: 'Undo',
                    }}
                    onClick={() => {
                        if (setSelectedBatchId) {
                            setSelectedBatchId(undefined);
                        }
                        if (!isPreview) {
                            setIsPreview(true);
                        }
                    }}
                />
            </Stack.Item>

            {showTabs && (
                <ShimmeredDetailsList
                    setKey="items"
                    items={sortedItems || []}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    selection={_selection}
                    layoutMode={DetailsListLayoutMode.justified}
                    enableShimmer={timestampsLoading}
                    ariaLabelForShimmer="Content is being fetched"
                    ariaLabelForGrid="Item details"
                    listProps={{
                        renderedWindowsAhead: 0,
                        renderedWindowsBehind: 0,
                    }}
                />
            )}

            {showTabs &&
                feedItems.map((item, index) => {
                    return (
                        <ActivityItem
                            key={`activity-${index}`}
                            styles={{
                                // workaround for ActivityItem not using the theme
                                root: {
                                    color: currentTheme.palette
                                        .neutralSecondary,
                                },
                                commentText: {
                                    color: currentTheme.palette.neutralPrimary,
                                    whiteSpace: 'pre-line',
                                },
                                timeStamp: {
                                    color: currentTheme.palette
                                        .neutralSecondary,
                                },
                            }}
                            activityDescription={`${item.person} ${item.actionText}`}
                            activityIcon={
                                item.iconName && (
                                    <HistoryCoin
                                        iconName={item.iconName}
                                        userDisplayName={
                                            item.person ||
                                            unknownUserDisplayName
                                        }
                                        userId={item.userId}
                                    />
                                )
                            }
                            comments={item.text}
                            timeStamp={dayjs.utc(item.timeStamp).fromNow()}
                        />
                    );
                })}

            {rollbackLoading && <Spinner size={SpinnerSize.large} />}

            {!rollbackLoading && feedItems2.length == 0 && <div>No items </div>}

            {feedItems2.map((item, index) => {
                return (
                    <ActivityItem
                        key={`activity-${index}`}
                        styles={{
                            // workaround for ActivityItem not using the theme
                            root: {
                                color: currentTheme.palette.neutralSecondary,
                            },
                            commentText: {
                                color: currentTheme.palette.neutralPrimary,
                                whiteSpace: 'pre-line',
                            },
                            timeStamp: {
                                color: currentTheme.palette.neutralSecondary,
                            },
                        }}
                        activityDescription={`${item.person} ${item.actionText}`}
                        activityIcon={
                            item.iconName && (
                                <HistoryCoin
                                    iconName={item.iconName}
                                    userDisplayName={
                                        item.person || unknownUserDisplayName
                                    }
                                    userId={item.userId}
                                />
                            )
                        }
                        comments={item.text}
                        timeStamp={dayjs.utc(item.timeStamp).fromNow()}
                    />
                );
            })}

            <Stack.Item>
                {!showConfirm &&
                    !rollbackLoading &&
                    feedItems2.length > 0 &&
                    !data?.measureImporterRollback?.isComplete && (
                        <DefaultButton
                            text={`Rollback ${feedItems2.length} items`}
                            iconProps={{
                                iconName: 'Previous',
                            }}
                            onClick={() => {
                                setShowConfirm(true);
                            }}
                        />
                    )}

                {showConfirm && (
                    <DefaultButton
                        text={
                            isProcessing
                                ? 'Performing Rollback'
                                : 'Confirm Rollback'
                        }
                        disabled={isProcessing}
                        iconProps={{
                            iconName: isProcessing
                                ? 'Clock'
                                : 'SurveyQuestions',
                        }}
                        onClick={async () => {
                            setIsProcessing(true);
                            // setTimeout(() => {
                            //     setShowConfirm(false);
                            //     setIsProcessing(false);
                            //     setErrorMessage(
                            //         'There was an error performing the rollback'
                            //     );
                            // }, 2000);

                            try {
                                await rollbackMeasure({
                                    variables: {
                                        tenantId: currentTenantId || '',
                                        batchId: selectedBatchId || '',
                                        preview: false,
                                    },
                                }).catch((reason) => {
                                    console.log('reason:', reason);
                                });
                            } catch (e) {
                                console.log('catch', e);
                            } finally {
                                setShowConfirm(false);
                                setIsProcessing(false);
                            }
                        }}
                    />
                )}
            </Stack.Item>

            {data?.measureImporterRollback?.isError && (
                <MessageBar messageBarType={MessageBarType.error}>
                    {data?.measureImporterRollback?.errorText}
                </MessageBar>
            )}

            {rollbackError && (
                <MessageBar messageBarType={MessageBarType.error}>
                    {rollbackError.message}
                </MessageBar>
            )}

            {data?.measureImporterRollback?.isComplete && (
                <MessageBar messageBarType={MessageBarType.success}>
                    {data?.measureImporterRollback?.completeText}
                </MessageBar>
            )}

            <hr />
        </Stack>
    );
}
