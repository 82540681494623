import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { MeasureTypes } from '../../data/types';

export function MeasureTypeInput(props: {
    defaultValue: MeasureTypes | undefined | null;
    errorMessage: string | undefined;
    disabled?: boolean;
    onChange: (newValue: MeasureTypes) => void;
}): JSX.Element {
    const measureTypes: IDropdownOption[] = [
        { key: MeasureTypes.Numeric, text: 'Numeric' },
        { key: MeasureTypes.Currency, text: 'Currency' },
        { key: MeasureTypes.Percentage, text: 'Percentage' },
        //{ key: MeasureTypes.Date, text: 'Date' },
        { key: MeasureTypes.OnOff, text: 'On/Off' },
        { key: MeasureTypes.YesNo, text: 'Yes/No' },
        { key: MeasureTypes.Gyr, text: 'Manual (Green/Yellow/Red)' },
    ];
    return (
        <Dropdown
            label="Data Type"
            disabled={props.disabled}
            defaultSelectedKey={props.defaultValue || MeasureTypes.Numeric}
            options={measureTypes}
            errorMessage={props.errorMessage}
            onChange={(ev: React.FormEvent, option?): void => {
                props.onChange(
                    option ? (option.key as MeasureTypes) : MeasureTypes.Numeric
                );
            }}
        />
    );
}
