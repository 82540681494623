import React, { useCallback, useEffect, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import {
    Stack,
    Dropdown,
    IDropdownOption,
    Toggle,
    Checkbox,
    Label,
    PanelType,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    Selection,
    IconButton,
    SelectionMode,
    Persona,
    PersonaSize,
    IToggleStyles,
} from '@fluentui/react';
import { EditPanel } from '../../../components/shared/EditPanel';
import useForm from '../../../hooks/useForm';
import { useStateContext } from '../../../services/contextProvider';
import {
    UserQl,
    useUpdateUserMutation,
    useGetTenantsQuery,
    UserTenantQl,
    UserTenant,
    RoleQl,
    useGetRoleTypesQuery,
    UserRole,
    UserRoleQl,
    useUpdateRightMutation,
    useGetDivisionsQuery,
    DivisionQl,
    useRightDeleteMutation,
    useGetTeamsQuery,
    TeamQl,
    MissionQl,
    useGetMissionSearchQuery,
    useGetRightsQuery,
} from '../../../data/types';
import { Guid } from 'guid-typescript';
import { InputShimmer } from '../../../components/inputs';
import { DetailsListCellItemContainer } from '../../../components/shared/DetailsListCellItemContainer';
import { photoService } from '../../../services/photo.service';
import { useThemes } from '../../../hooks/useThemes';
import { useRefetch } from '../../../hooks/useRefetch';

export type UserAccessPanelProps = {
    showPanel: boolean;
    showGlobalRights: boolean;
    user?: UserQl | null;
    onUpdate: () => void;
    onCancel: () => void;
    fyCodeFilter: string | null;
};

function CastUserRole(input: UserRoleQl): UserRole {
    return {
        id: input.id,
        userId: input.userId,
        roleId: input.roleId,
        tenantId: input.tenantId,
    };
}

function IsGlobalRole(input: RoleQl): boolean {
    return input.name === 'Developer' || input.name === 'GlobalAdmin';
}

export interface IDetailsListCompactExampleItem {
    id: string;
    name: string;
    read: boolean;
    write: boolean;
    export: boolean;
    import: boolean;
    divisionId: string | null;
    teamId: string | null;
    missionId: string | null;
}

export interface IDetailsListCompactExampleState {
    items: IDetailsListCompactExampleItem[];
    selectionDetails: string;
}

export function UserAccessPanel(props: UserAccessPanelProps): JSX.Element {
    const { currentTheme } = useThemes();

    const { currentTenantId, currentRoles } = useStateContext();
    const [showPanel, setShowPanel] = React.useState(false);

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin'].includes(r)
    );

    const { data: tenantData } = useGetTenantsQuery({
        skip: !props.showPanel,
    });
    const tenants = tenantData?.tenants;
    const [selectedDivisionId, setSelectedDivisionId] = useState<
        string | null
    >();
    const [selectedTeamId, setSelectedTeamId] = useState<string | null>();
    const [selectedMissionId, setSelectedMissionId] = useState<string | null>();

    const {
        data: rightsQuery,
        loading: rightsLoading,
        refetch: _refetchRights,
    } = useGetRightsQuery({
        skip: !currentTenantId || !props.user?.id || !props.showPanel,
        variables: {
            userId: props.user?.id || '',
            divisionId: null,
            teamId: null,
            missionId: null,
            tenantId: currentTenantId || '',
            financialYearCode: props.fyCodeFilter,
        },
    });

    const [refetchRights] = useRefetch(_refetchRights);

    const { data: divisions, loading: divisionsLoading } = useGetDivisionsQuery(
        {
            skip: !currentTenantId || !props.showPanel,
            variables: {
                tenantId: currentTenantId || '',
                financialYearCode: null,
            },
        }
    );

    const { data: teams, loading: teamsLoading } = useGetTeamsQuery({
        skip: !currentTenantId || !props.showPanel,
        variables: {
            tenantId: currentTenantId || '',
            divisionId: null,
            financialYearCode: null,
        },
    });

    const { data: missions, loading: missionsLoading } =
        useGetMissionSearchQuery({
            skip: !currentTenantId || !props.showPanel,
            variables: {
                tenantId: currentTenantId || '',
                divisionId: null,
                searchText: '',
                isWritable: false,
                isImportable: false,
                includeInactive: false,
                includeDeleted: false,
                financialYearCode: null,
            },
        });

    const { data: roleTypes_ } = useGetRoleTypesQuery({
        skip: !props.showPanel,
    });
    const roleTypes = roleTypes_?.roleTypes?.filter((r) =>
        props.showGlobalRights ? IsGlobalRole(r) : !IsGlobalRole(r)
    );

    const [updateUser, { loading: isSaving, error: saveError }] =
        useUpdateUserMutation({
            onCompleted: () => {
                if (props.user?.id) {
                    props.onUpdate();
                }
            },
        });

    const [updateRight, { loading: updateRightLoading }] =
        useUpdateRightMutation();

    const [rightDeleteMutation, { loading: deleteRightLoading }] =
        useRightDeleteMutation();

    type formValuesType = {
        displayName: string;
        emailAddress: string;
        accessEnabled: boolean;
        selectedTenants: UserTenantQl[];
    };

    const formDefaultValues: formValuesType = {
        displayName: props.user?.displayName || '',
        emailAddress: props.user?.emailAddress || '',
        selectedTenants: props.user?.userTenants || [],
        accessEnabled: props.user?.accessEnabled || false,
    };

    const formValidate = (
        values: formValuesType
    ): {
        [key: string]: string;
    } => {
        const formErrorMessages = {
            displayName: '',
            emailAddress: '',
        };

        if (!values.displayName) {
            formErrorMessages.displayName = 'Enter a display name';
        }

        if (!values.emailAddress) {
            formErrorMessages.emailAddress = 'Enter an email address';
        } else {
            const emailRegex = /\S+@\S+\.\S+/;
            const isValidEmail = emailRegex.test(values.emailAddress || '');
            if (!isValidEmail) {
                formErrorMessages.emailAddress = 'Enter a valid email address';
            }
        }

        return formErrorMessages;
    };

    const formCallback = async (values: formValuesType): Promise<void> => {
        const userTenants = values.selectedTenants.map((t) => {
            return {
                id: t.id,
                isDefault: t.isDefault ?? false,
                tenantId: t.tenantId,
                userId: props.user?.id,
            } as UserTenant;
        });

        const userRoles = [...excludedUserRoles, ...selectedUserRoles];

        await updateUser({
            variables: {
                input: {
                    id: props.user?.id || Guid.createEmpty().toString(),
                    displayName: values.displayName,
                    emailAddress: values.emailAddress,
                    accessEnabled: values.accessEnabled,
                    utcSelfHelpEnabledUntil:
                        props.user?.utcSelfHelpEnabledUntil || null,
                    userRoles: userRoles,
                    userTenants: userTenants,
                    version: props.user?.version || '',
                },
            },
        });
    };

    const { errors, handleSubmit, values, updateValue, reset } = useForm(
        formDefaultValues,
        formCallback,
        formValidate
    );

    useEffect(() => {
        if (props.showPanel !== showPanel) {
            reset();
            setShowPanel(props.showPanel);
            // setIsConfirmingDelete(false);
        }
    }, [props.showPanel, reset, showPanel]);

    const tenantOptions: IDropdownOption[] = (tenants || [])
        .slice()
        .sort((a, b) =>
            (a.description || '').localeCompare(b.description || '')
        )
        .map((t) => {
            return {
                key: t.id || '',
                text: t.description || t.code || t.id || '',
            };
        });

    function onRoleChanged(role: RoleQl, isChecked: boolean) {
        if (!currentTenantId || !props.user?.id) {
            return;
        }
        const exists = selectedUserRoles.some(
            (selected) => selected.roleId === role.id
        );
        if (isChecked) {
            if (!exists) {
                const userRole: UserRole = {
                    id: null,
                    userId: props.user?.id,
                    roleId: role.id,
                    tenantId: IsGlobalRole(role) ? null : currentTenantId,
                };
                setSelectedUserRoles([...selectedUserRoles, userRole]);
            }
        } else {
            if (exists) {
                const newRoles = selectedUserRoles.filter(
                    (selected) => selected.roleId !== role.id
                );
                setSelectedUserRoles(newRoles);
            }
        }
    }

    const getDivisionName = (id: string | null) => {
        if (!id) return 'Not found';
        if (divisionsLoading) return 'Loading...';

        const division = divisions?.divisions?.find((d) => d.id === id);

        const fyPrefix = !props.fyCodeFilter
            ? `${division?.financialYear?.code} - `
            : '';

        return `${fyPrefix}${division?.name || division?.id || ''}`;
    };

    const getTeamName = (id: string | null) => {
        if (!id) return 'Not found';
        if (teamsLoading) return 'Loading...';

        const team = teams?.teams?.find((t) => t.id === id);

        const fyPrefix = !props.fyCodeFilter
            ? `${team?.division?.financialYear?.code} - `
            : '';

        return `${fyPrefix}${team?.division?.name} - ${team?.name || team?.id || ''}`;
    };

    const getMissionName = (id: string | null) => {
        if (!id) return 'Not found';
        if (missionsLoading) return 'Loading...';
        const mission = missions?.missions?.find((m) => m.id === id);

        const fyPrefix = !props.fyCodeFilter
            ? `${mission?.team?.division?.financialYear?.code} - `
            : '';

        return `${fyPrefix}${mission?.owner} - ${mission?.title}`;
    };

    const divisionRights: IDetailsListCompactExampleItem[] =
        rightsQuery?.rights
            .filter((r) => r.divisionId != null)
            .map((r) => {
                return {
                    ...r,
                    name: getDivisionName(r.divisionId),
                };
            }) || [];

    const teamRights: IDetailsListCompactExampleItem[] =
        rightsQuery?.rights
            .filter((r) => r.teamId != null)
            .map((r) => {
                return {
                    ...r,
                    name: getTeamName(r.teamId),
                };
            }) || [];

    const missionRights: IDetailsListCompactExampleItem[] =
        rightsQuery?.rights
            .filter((r) => r.missionId != null)
            .map((r) => {
                return {
                    ...r,
                    name: getMissionName(r.missionId),
                };
            }) || [];

    const divisionsCanAdd = useMemo(
        () =>
            orderBy(
                divisions?.divisions
                    ?.filter((d) => {
                        const some = rightsQuery?.rights.some((r) => {
                            return r.divisionId === d.id;
                        });
                        return !some;
                    })
                    .filter(
                        (d) =>
                            !props.fyCodeFilter ||
                            d.financialYear?.code === props.fyCodeFilter
                    )
                    .map((d) => d as DivisionQl),
                ['financialYear.code', 'name'],
                ['desc', 'asc']
            ),
        [divisions?.divisions, props.fyCodeFilter, rightsQuery?.rights]
    );

    const teamsCanAdd = useMemo(
        () =>
            orderBy(
                teams?.teams
                    ?.filter((t) => {
                        const some = rightsQuery?.rights.some((r) => {
                            return r.teamId === t.id;
                        });
                        return !some;
                    })
                    .filter(
                        (t) =>
                            !props.fyCodeFilter ||
                            t.division?.financialYear?.code ===
                                props.fyCodeFilter
                    )
                    .map((t) => t as TeamQl),
                ['division.financialYear.code', 'division.name', 'name'],
                ['desc', 'asc', 'asc']
            ),
        [props.fyCodeFilter, rightsQuery?.rights, teams?.teams]
    );

    const missionsCanAdd = useMemo(
        () =>
            orderBy(
                missions?.missions
                    ?.filter((m) => {
                        const some = rightsQuery?.rights.some((r) => {
                            return r.missionId === m.id;
                        });
                        return !some;
                    })
                    .filter(
                        (m) =>
                            !props.fyCodeFilter ||
                            m.team?.division?.financialYear?.code ===
                                props.fyCodeFilter
                    )
                    .map((m) => m as MissionQl),
                [
                    'team.division.financialYear.code',
                    'team.division.name',
                    'title',
                    'owner',
                ],
                ['desc', 'asc', 'asc', 'asc']
            ),
        [missions?.missions, props.fyCodeFilter, rightsQuery?.rights]
    );

    const toggleRight = async (
        item: IDetailsListCompactExampleItem,
        field: string
    ) => {
        if (
            field === 'read' ||
            field === 'write' ||
            field === 'export' ||
            field === 'import'
        ) {
            const rightUpdate = {
                id: item.id || Guid.createEmpty().toString(),
                read: field === 'read' ? !item.read : item.read,
                write: field === 'write' ? !item.write : item.write,
                export: field === 'export' ? !item.export : item.export,
                import: field === 'import' ? !item.import : item.import,
                divisionId: item.divisionId,
                teamId: item.teamId,
                missionId: item.missionId,
                userId: props.user?.id || '',
            };

            await updateRight({
                variables: {
                    tenantId: currentTenantId || '',
                    right: rightUpdate,
                },
                optimisticResponse: {
                    __typename: 'Mutations',
                    rightUpdate: {
                        ...rightUpdate,
                        __typename: 'RightQL',
                    },
                },
            });
        }
    };

    const addRight = useCallback(
        async (
            divisionId: string | null,
            teamId: string | null,
            missionId: string | null,
            read = true,
            write = true,
            exportRights = true,
            importRights = true
        ) => {
            await updateRight({
                variables: {
                    tenantId: currentTenantId || '',
                    right: {
                        id: Guid.createEmpty().toString(),
                        read: read,
                        write: write,
                        export: exportRights,
                        import: importRights,
                        divisionId: divisionId,
                        teamId: teamId,
                        missionId: missionId,
                        userId: props.user?.id || '',
                    },
                },
            });
            refetchRights();
        },
        [currentTenantId, props.user?.id, refetchRights, updateRight]
    );

    const autoAddParentRight = useCallback(
        async (leaderMissionId: string) => {
            // Auto Add Mission Right to Leader's Mission
            // Check that the right hasn't been added already
            const missionCanAdd = missionsCanAdd?.filter(
                (m) => m.id === leaderMissionId
            )[0];
            if (missionCanAdd) {
                await addRight(
                    null,
                    null,
                    leaderMissionId,
                    true,
                    false,
                    true,
                    false
                );
            }
        },
        [addRight, missionsCanAdd]
    );

    useEffect(() => {
        if (selectedDivisionId) {
            addRight(selectedDivisionId, null, null);

            // Auto add a Mission Right:

            // Lookup all teams for this division
            const divisionTeams = teamsCanAdd?.filter(
                (t) => t.division?.id === selectedDivisionId
            );

            // Lookup all leader mission Ids
            const leaderMissionIds = divisionTeams
                ?.map((d) => d.leaderMission?.id)
                .filter((m) => m); //Filter out undefined.

            // Lookup full leader missions (includes team, division)
            const leaderMissions =
                missionsCanAdd?.filter((m) =>
                    leaderMissionIds?.includes(m.id)
                ) || [];

            // Find Leader Missions with a different division
            const cuspMissions = leaderMissions.filter(
                (m) => m.team?.division?.id !== selectedDivisionId
            );

            for (const cuspMission of cuspMissions) {
                if (cuspMission.id) {
                    autoAddParentRight(cuspMission.id);
                }
            }
            setSelectedDivisionId(null);
        }
    }, [
        addRight,
        autoAddParentRight,
        missionsCanAdd,
        selectedDivisionId,
        teamsCanAdd,
    ]);

    useEffect(() => {
        if (selectedTeamId) {
            addRight(null, selectedTeamId, null);
            const selectedTeam = teamsCanAdd?.filter(
                (t) => t.id === selectedTeamId
            )[0];
            const leaderMissionId = selectedTeam?.leaderMission?.id;
            if (leaderMissionId) {
                autoAddParentRight(leaderMissionId);
            }

            setSelectedTeamId(null);
        }
    }, [addRight, autoAddParentRight, selectedTeamId, teamsCanAdd]);

    useEffect(() => {
        if (selectedMissionId) {
            addRight(null, null, selectedMissionId);

            setSelectedMissionId(null);
        }
    }, [selectedMissionId, addRight]);

    const onDeleteClick = async (right: IDetailsListCompactExampleItem) => {
        await rightDeleteMutation({
            variables: {
                tenantId: currentTenantId || '',
                id: right.id,
                restore: false,
            },
        });
        refetchRights();
    };

    // Filter out global and roles for other tenants
    const selected = props.showGlobalRights
        ? (props.user?.userRoles
              .filter((r) => r.tenantId === null)
              .map(CastUserRole) ?? [])
        : (props.user?.userRoles
              .filter((r) => r.tenantId === currentTenantId)
              .map(CastUserRole) ?? []);

    const excludedUserRoles = props.showGlobalRights
        ? (props.user?.userRoles
              .filter((r) => r.tenantId !== null)
              .map(CastUserRole) ?? [])
        : (props.user?.userRoles
              .filter((r) => r.tenantId !== currentTenantId)
              .map(CastUserRole) ?? []);
    const [selectedUserRoles, setSelectedUserRoles] = React.useState(selected);

    const buildColumns = (name: string): IColumn[] => {
        const rightsTable: IColumn[] = [];
        rightsTable.push({
            key: 'column1',
            name: name,
            onRender: (item: IDetailsListCompactExampleItem) => {
                return (
                    <DetailsListCellItemContainer>
                        {item.name}
                    </DetailsListCellItemContainer>
                );
            },
            fieldName: 'name',
            minWidth: 92,
            isResizable: true,
            isMultiline: true,
            isPadded: false,
        });

        const names = ['Read', 'Write', 'Export', 'Import'];
        const fieldNames = ['read', 'write', 'export', 'import'];
        for (let i = 0; i < 4; i++) {
            rightsTable.push({
                key: `column${i + 2}`,
                name: names[Number(i)],
                fieldName: fieldNames[Number(i)],
                minWidth: 42,
                maxWidth: 50,
                isPadded: false,
            });
        }

        rightsTable.push({
            key: 'column6',
            name: '',
            fieldName: 'delete',
            minWidth: 30,
            maxWidth: 30,
            isIconOnly: true,
            isPadded: false,
        });
        return rightsTable;
    };

    const getToggleStyles = (isChecked: boolean): Partial<IToggleStyles> => ({
        root: {
            marginBottom: 0,
        },
        pill: {
            background: isChecked
                ? currentTheme.palette.green
                : currentTheme.palette.red,
            borderColor: 'transparent',
        },
        thumb: {
            backgroundColor: currentTheme.palette.white,
        },
    });

    function renderItemColumn(
        item: IDetailsListCompactExampleItem,
        index?: number,
        column?: IColumn
    ) {
        const fieldContent = item[
            column?.fieldName as keyof IDetailsListCompactExampleItem
        ] as string;

        switch (column?.key) {
            case 'column2':
            case 'column3':
            case 'column4':
            case 'column5': {
                const isChecked = item[
                    column?.fieldName as keyof IDetailsListCompactExampleItem
                ] as boolean;

                return (
                    <DetailsListCellItemContainer>
                        <Toggle
                            checked={isChecked}
                            onClick={() => {
                                if (column.fieldName) {
                                    toggleRight(item, column.fieldName);
                                }
                            }}
                            styles={getToggleStyles(isChecked)}
                        />
                    </DetailsListCellItemContainer>
                );
            }
            case 'column6':
                return (
                    <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        onClick={async () => {
                            onDeleteClick(item);
                        }}
                    />
                );
            default:
                return <span>{fieldContent}</span>;
        }
    }

    return (
        <React.Fragment>
            <EditPanel
                activeViewName="UserAccess"
                onDismiss={props.onCancel}
                showPanel={showPanel}
                headerText={'User Access'}
                isSaving={isSaving || updateRightLoading || deleteRightLoading}
                isValid={true}
                saveErrorMessage={saveError?.message || null}
                onUpdateClick={handleSubmit}
                panelType={PanelType.medium}
                // panelType={PanelType.smallFluid}
            >
                <Stack tokens={{ childrenGap: 8 }}>
                    <div
                        style={{
                            paddingTop: 8,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Persona
                            size={PersonaSize.size40}
                            text={props.user?.displayName || ''}
                            secondaryText={props.user?.emailAddress || ''}
                            imageUrl={photoService.getImageUrl(props.user?.id)}
                        />

                        <Toggle
                            onText="Access Enabled"
                            offText="Access Disabled"
                            checked={values.accessEnabled}
                            onChange={(
                                _ev: React.MouseEvent<HTMLElement>,
                                checked?: boolean
                            ): void => {
                                updateValue('accessEnabled', checked || false);
                            }}
                            styles={getToggleStyles(values.accessEnabled)}
                        />
                    </div>

                    <Stack>
                        <InputShimmer isDataLoaded={!rightsLoading}>
                            <DetailsList
                                compact={true}
                                items={divisionRights || []}
                                columns={buildColumns(
                                    props.fyCodeFilter
                                        ? `${props.fyCodeFilter.toLocaleUpperCase()} Divisions`
                                        : 'Divisions'
                                )}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                selectionMode={SelectionMode.none}
                                onRenderItemColumn={renderItemColumn}
                                onShouldVirtualize={(): boolean => false}
                            />
                        </InputShimmer>

                        {divisionsCanAdd && divisionsCanAdd?.length > 0 && (
                            <InputShimmer isDataLoaded={!divisionsLoading}>
                                <Dropdown
                                    options={
                                        divisionsCanAdd?.map((d) => {
                                            const fyPrefix = !props.fyCodeFilter
                                                ? `${d.financialYear?.code} - `
                                                : '';

                                            return {
                                                key: d.id || '',
                                                text: `${fyPrefix}${d.name || d.id || ''}`,
                                            };
                                        }) || []
                                    }
                                    selectedKey={selectedDivisionId}
                                    placeholder="Add Division"
                                    errorMessage={errors.divisionId}
                                    onChange={(
                                        _event: React.FormEvent,
                                        option?: IDropdownOption | undefined
                                    ): void => {
                                        if (option) {
                                            setSelectedDivisionId(
                                                option.key as string
                                            );
                                        }
                                    }}
                                />
                            </InputShimmer>
                        )}
                    </Stack>

                    <Stack>
                        <InputShimmer isDataLoaded={!rightsLoading}>
                            <DetailsList
                                compact={true}
                                items={teamRights || []}
                                columns={buildColumns(
                                    props.fyCodeFilter
                                        ? `${props.fyCodeFilter.toLocaleUpperCase()} Teams`
                                        : 'Teams'
                                )}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                selectionMode={SelectionMode.none}
                                onRenderItemColumn={renderItemColumn}
                                onShouldVirtualize={(): boolean => false}
                            />
                        </InputShimmer>

                        {teamsCanAdd && teamsCanAdd?.length > 0 && (
                            <InputShimmer isDataLoaded={!teamsLoading}>
                                <Dropdown
                                    options={
                                        teamsCanAdd?.map((t) => {
                                            const fyPrefix = !props.fyCodeFilter
                                                ? `${t?.division?.financialYear?.code} - `
                                                : '';

                                            return {
                                                key: t.id || '',
                                                text: `${fyPrefix}${t?.division?.name} - ${t?.name || t?.id || ''}`,
                                            };
                                        }) || []
                                    }
                                    placeholder="Add Team"
                                    errorMessage={errors.teamId}
                                    selectedKey={selectedTeamId}
                                    onChange={(
                                        _event: React.FormEvent,
                                        option?: IDropdownOption
                                    ): void => {
                                        if (option) {
                                            setSelectedTeamId(
                                                option.key as string
                                            );
                                        }
                                    }}
                                />
                            </InputShimmer>
                        )}
                    </Stack>

                    <Stack>
                        <InputShimmer isDataLoaded={!rightsLoading}>
                            <DetailsList
                                compact={true}
                                items={missionRights || []}
                                columns={buildColumns(
                                    props.fyCodeFilter
                                        ? `${props.fyCodeFilter.toLocaleUpperCase()} Missions`
                                        : 'Missions'
                                )}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                selectionMode={SelectionMode.none}
                                selection={new Selection()}
                                selectionPreservedOnEmptyClick={true}
                                onRenderItemColumn={renderItemColumn}
                                onShouldVirtualize={(): boolean => false}
                            />
                        </InputShimmer>

                        {missionsCanAdd && missionsCanAdd?.length > 0 && (
                            <InputShimmer isDataLoaded={!missionsLoading}>
                                <Dropdown
                                    options={
                                        missionsCanAdd?.map((m) => {
                                            const fyPrefix = !props.fyCodeFilter
                                                ? `${m.team?.division?.financialYear?.code} - `
                                                : '';

                                            return {
                                                key: m.id || '',
                                                text: `${fyPrefix}${m.owner} - ${m.title}`,
                                            };
                                        }) || []
                                    }
                                    selectedKey={selectedMissionId}
                                    placeholder="Add Mission"
                                    errorMessage={errors.missionId}
                                    onChange={(
                                        _event: React.FormEvent,
                                        option?: IDropdownOption
                                    ) => {
                                        if (option) {
                                            setSelectedMissionId(
                                                option.key as string
                                            );
                                        }
                                    }}
                                />
                            </InputShimmer>
                        )}
                    </Stack>

                    {roleTypes && roleTypes.length && (
                        <Stack tokens={{ childrenGap: 8 }}>
                            <Label>User Roles</Label>
                            {roleTypes.map((r) => {
                                return (
                                    <Checkbox
                                        key={r.name}
                                        label={r.name
                                            .replace(/([A-Z])/g, ' $1') // Format PascalCase with spaces
                                            .trim()}
                                        title={r.description}
                                        checked={selectedUserRoles.some(
                                            (selected) =>
                                                selected.roleId === r.id
                                        )}
                                        disabled={!isAdmin}
                                        onChange={(
                                            _ev?: React.FormEvent,
                                            isChecked?: boolean
                                        ) =>
                                            onRoleChanged(r, isChecked || false)
                                        }
                                    />
                                );
                            })}
                        </Stack>
                    )}

                    {props.showGlobalRights && (
                        <Dropdown
                            placeholder="Select tenants"
                            label="Tenants"
                            selectedKeys={values.selectedTenants.map(
                                (t) => t.tenantId || ''
                            )}
                            multiSelect
                            options={tenantOptions}
                            onChange={(
                                _event: React.FormEvent,
                                item?: IDropdownOption
                            ): void => {
                                if (item) {
                                    const tenant = (tenants || [])
                                        .map((t) => {
                                            return {
                                                tenantId: t.id,
                                            };
                                        })
                                        .find(
                                            (t) =>
                                                t.tenantId ===
                                                (item.key as string)
                                        );

                                    updateValue(
                                        'selectedTenants',
                                        item.selected
                                            ? [
                                                  ...values.selectedTenants,
                                                  tenant,
                                              ]
                                            : values.selectedTenants.filter(
                                                  (t) => t.tenantId !== item.key
                                              )
                                    );
                                }
                            }}
                        />
                    )}
                </Stack>
            </EditPanel>
        </React.Fragment>
    );
}
