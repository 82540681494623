import { authService } from '../services/auth.service';
import {
    GetCurrentUserDocument,
    GetCurrentUserQuery,
    UserQl,
    GetDefaultMissionDocument,
    GetDefaultMissionQuery,
    UserTenantQl,
    UserRoleQl,
} from '../data/types';

import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    Operation,
} from '@apollo/client';

import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { IConfiguration, configurationService } from './configuration.service';
import { Kind, OperationDefinitionNode } from 'graphql';
import dayjs from 'dayjs';

type SetupConfiguration = {
    configuration: IConfiguration;
    client: ApolloClient<unknown>;
    currentUserId: string | undefined;
    currentUserDisplayName: string | null | undefined;
    currentUserIsSelfHelpEnabled: boolean | undefined;
    currentUserIsNew: boolean | undefined;
    userTenants: UserTenantQl[];
    userRoles: UserRoleQl[];
};

class GraphService {
    apolloClient?: ApolloClient<unknown> = undefined;

    public async initialiseAsync(): Promise<SetupConfiguration> {
        const configuration =
            await configurationService.getConfigurationAsync();

        this.apolloClient = await this.getClientAsync(configuration);

        const currentUser = await this.getCurrentUserAsync();

        const isSelfHelpEnabled = currentUser?.utcSelfHelpEnabledUntil
            ? dayjs.utc(currentUser?.utcSelfHelpEnabledUntil) >= dayjs()
            : false;

        return {
            currentUserId: currentUser?.id ? currentUser.id : undefined,
            currentUserDisplayName: currentUser?.displayName,
            currentUserIsSelfHelpEnabled: isSelfHelpEnabled,
            currentUserIsNew: currentUser?.isNewUser,
            configuration: configuration,
            client: this.apolloClient,
            userTenants: currentUser?.userTenants || [],
            userRoles: currentUser?.userRoles || [],
        };
    }

    public async getDefaultMissionAsync(
        userId: string,
        tenantId: string,
        financialYearCode: string | null
    ): Promise<GetDefaultMissionQuery | null> {
        if (!this.apolloClient) {
            throw new Error('The apollo client is not initialised.');
        }

        return this.apolloClient
            .query<GetDefaultMissionQuery>({
                query: GetDefaultMissionDocument,
                variables: {
                    tenantId: tenantId,
                    userId: userId,
                    financialYearCode: financialYearCode,
                },
            })
            .then(
                (result) => {
                    return result.data;
                },
                (error) => {
                    console.log(error);
                    return null;
                }
            );
    }

    omitDeep = (
        obj: { [index: string]: unknown },
        key: string | number
    ): Record<string, unknown> => {
        const keys: (string | number)[] = Object.keys(obj);
        const newObj: { [index: string]: unknown } = {};
        keys.forEach((i: string | number) => {
            if (i !== key) {
                if (typeof i === 'string') {
                    const val = obj[`${i}`];
                    if (val instanceof Date) newObj[`${i}`] = val;
                    else if (Array.isArray(val))
                        newObj[`${i}`] = this.omitDeepArrayWalk(val as [], key);
                    else if (typeof val === 'object' && val !== null)
                        newObj[`${i}`] = this.omitDeep(
                            val as { [index: string]: unknown },
                            key
                        );
                    else newObj[`${i}`] = val;
                } else {
                    const val = obj[Number(i)];
                    if (val instanceof Date) newObj[Number(i)] = val;
                    else if (Array.isArray(val))
                        newObj[Number(i)] = this.omitDeepArrayWalk(
                            val as [],
                            key
                        );
                    else if (typeof val === 'object' && val !== null)
                        newObj[Number(i)] = this.omitDeep(
                            val as { [index: string]: unknown },
                            key
                        );
                    else newObj[Number(i)] = val;
                }
            }
        });
        return newObj;
    };

    omitDeepArrayWalk(arr: [], key: string | number): unknown {
        return arr.map((val) => {
            if (Array.isArray(val)) return this.omitDeepArrayWalk(val, key);
            else if (typeof val === 'object') return this.omitDeep(val, key);
            return val;
        });
    }

    async getClientAsync(
        configuration: IConfiguration
    ): Promise<ApolloClient<unknown>> {
        const cleanTypenameLink = new ApolloLink((operation, forward) => {
            if (operation.variables) {
                operation.variables = this.omitDeep(
                    operation.variables,
                    '__typename'
                );
            }
            return forward(operation).map((data) => {
                return data;
            });
        });

        const cache = new InMemoryCache({
            typePolicies: {
                CurrencyQL: {
                    keyFields: ['code'],
                },
                // This is a grouping of queries so can be merged
                Reports: {
                    merge: true,
                },
            },
        });

        const customFetch = async (
            uri: RequestInfo | URL,
            options?: RequestInit
        ) => {
            const token = await authService.getTokenAsync();
            if (token) {
                const headers = options?.headers as Record<string, string>;
                headers['Authorization'] = `Bearer ${token}`;
            }
            return fetch(uri, options);
        };

        const httpLinkOptions: BatchHttpLink.Options = {
            uri: `${configuration.apiUrl}graphql`,
            fetch: customFetch,
        };

        const onErrorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                console.log(graphQLErrors);
                // throw new Error(graphQLErrors.join(','));
            }
            if (networkError) {
                console.log(networkError);
                //throw new Error(networkError.message);
            }
        });

        const retryLink = new RetryLink();

        const isOperationMutation = (operation: Operation): boolean => {
            return operation.query.definitions.some(
                (d) =>
                    d.kind === Kind.OPERATION_DEFINITION &&
                    (d as OperationDefinitionNode).operation === 'mutation'
            );
        };

        const isOperationSubscription = (operation: Operation): boolean => {
            return operation.query.definitions.some(
                (d) =>
                    d.kind === Kind.OPERATION_DEFINITION &&
                    (d as OperationDefinitionNode).operation === 'subscription'
            );
        };

        const wsLink = new GraphQLWsLink(
            createClient({
                url: `${configuration.apiUrl.replace('http', 'ws')}graphql`,
                connectionParams: async () => {
                    const token = await authService.getTokenAsync();
                    return {
                        authToken: token,
                    };
                },
            })
        );

        return new ApolloClient({
            connectToDevTools: true,
            link: ApolloLink.from([
                retryLink,
                onErrorLink,
                cleanTypenameLink.split(
                    (operation) => isOperationSubscription(operation),
                    wsLink,
                    cleanTypenameLink.split(
                        (operation) => isOperationMutation(operation),
                        new BatchHttpLink(httpLinkOptions),
                        new HttpLink(httpLinkOptions)
                    )
                ),
            ]),
            cache,
        });
    }

    async getCurrentUserAsync(): Promise<UserQl | null> {
        if (!this.apolloClient) {
            throw new Error('The apollo client is not initialised.');
        }
        return this.apolloClient
            .query<GetCurrentUserQuery>({
                query: GetCurrentUserDocument,
            })
            .then((result) => {
                return result.data?.current_user as UserQl;
            });
    }
}

export const graphService = new GraphService();
