export function useStrategicDirection(
    mission:
        | {
              id: string | null;
              customMissionStatement1Up: string | null;
              customMissionStatement2Up: string | null;
              leaderOfTeams: {
                  id: string | null;
                  code: string | null;
                  division: {
                      id: string | null;
                      vision: string | null;
                      purpose: string | null;
                      values: string | null;
                  } | null;
              }[];
              team: {
                  leaderMission: {
                      id: string | null;
                      missionStatement: string | null;
                      team: {
                          leaderMission: {
                              id: string | null;
                              missionStatement: string | null;
                          } | null;
                          division: {
                              id: string | null;
                          } | null;
                      } | null;
                  } | null;
                  division: {
                      id: string | null;
                      vision: string | null;
                      purpose: string | null;
                      values: string | null;
                  } | null;
              } | null;
          }
        | null
        | undefined,
    teamCode: string | undefined | null
): {
    onLevelUpStatement: string | null;
    twoLevelsUpStatement: string | null;
    vision: string | null;
    purpose: string | null;
    values: string | null;
} {
    let vision = mission?.team?.division?.vision;
    let purpose = mission?.team?.division?.purpose;
    let values = mission?.team?.division?.values;

    // Check if we are looking at a leaders team, and use that vision/purpose.
    if (teamCode) {
        const thisTeam = mission?.leaderOfTeams.find(
            (lm) => lm.code?.toLowerCase() === teamCode?.toLowerCase()
        );
        if (thisTeam?.division) {
            vision = thisTeam.division.vision;
            purpose = thisTeam.division.purpose;
            values = thisTeam.division.values;
        }
    }

    const oneUpMission =
        mission?.team?.leaderMission &&
        mission?.team.leaderMission.id !== mission.id
            ? mission.team?.leaderMission
            : null;

    // Two up must also be from the same division.
    const twoUpMission =
        oneUpMission?.team?.leaderMission &&
        oneUpMission.team.leaderMission.id !== oneUpMission.id &&
        oneUpMission.team.division?.id === mission?.team?.division?.id
            ? oneUpMission.team.leaderMission
            : null;

    return {
        onLevelUpStatement:
            mission?.customMissionStatement1Up ||
            oneUpMission?.missionStatement ||
            null,
        twoLevelsUpStatement:
            mission?.customMissionStatement2Up ||
            twoUpMission?.missionStatement ||
            null,
        vision: vision || null,
        purpose: purpose || null,
        values: values || null,
    };
}
