import * as React from 'react';
import { IconButton } from '@fluentui/react';

export function ChartTableToggleButton(props: {
    selectedView: 'Chart' | 'Table';
    onViewChanged: (view: 'Chart' | 'Table') => void;
}) {
    return (
        <IconButton
            iconProps={{
                iconName:
                    props.selectedView === 'Chart'
                        ? 'StackColumnChart'
                        : 'Table',
            }}
            menuProps={{
                items: [
                    {
                        key: 'chart',
                        text: 'Chart',
                        iconProps: { iconName: 'StackColumnChart' },
                        onClick: () => props.onViewChanged('Chart'),
                    },
                    {
                        key: 'table',
                        text: 'Table',
                        iconProps: { iconName: 'Table' },
                        onClick: () => props.onViewChanged('Table'),
                    },
                ],
            }}
        />
    );
}
