import React, { useEffect, useState } from 'react';
import {
    Link,
    mergeStyleSets,
    PrimaryButton,
    Shimmer,
    ShimmerElementType,
    Text,
} from '@fluentui/react';
import { AdvanceCard } from '../../../components/AdvanceCard';

import {
    GetMeasurePeriodDataQuery,
    GetMeasuresForStatusReportQuery,
    ReportPeriodTypes,
} from '../../../data/types';
import { sorters } from '../../../data/sorters';
import {
    ExtractQueryArrayType,
    ExtractQueryType,
} from '../../../data/extendedTypes';

import { TemplateReportElementType } from '../TemplateReport';
import { MeasurePickerPanel } from './MeasurePickerPanel';
import { SortableContainer } from '../../../components/SortableContainer';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandlerButtonMemo } from '../../../components/DragHandlerButton';
import MeasureArrow from '../../../components/MeasureArrow';
import { useThemes } from '../../../hooks/useThemes';
import { useReportingMeasures } from '../../../hooks/useReportingMeasures';
import { ReportPeriodDataActualBox } from '../../../components/reports/ReportPeriodDataActualBox';
import { ReportPeriodDataTargetBoxes } from '../../../components/reports/ReportPeriodDataTargetBoxes';

type TemplateReportMeasureListElementProps = {
    missionId: string;
    fyStartDate: string | null | undefined;
    utcDataDate: string | null;
    utcCompletedDate: string | null;
    reportPeriodType: ReportPeriodTypes | null;
    reportPeriod: number | null;
    element: TemplateReportElementType;
    sourceElement: TemplateReportElementType | null;
    onElementChanged: (
        updated: TemplateReportElementType,
        forceImmediateSave: boolean
    ) => void;
    isReadOnly: boolean;
    onMeasureNavigate: ((measureId: string) => void) | null;
    hideStatus?: boolean;
    hideTarget?: boolean;
};

export function TemplateReportMeasureListElement(
    props: TemplateReportMeasureListElementProps
): JSX.Element {
    const { element, fyStartDate } = props;

    const [isPicking, setIsPicking] = useState(false);
    const [activeDragItemId, setActiveDragItemId] = useState<string | null>();

    const {
        measures,
        selectedMeasureIds,
        setSelectedMeasureIds,
        isLoading,
        periodData,
    } = useReportingMeasures({ ...props });

    useEffect(() => {
        setSelectedMeasureIds(
            (props.sourceElement
                ? props.sourceElement.measureLinks
                : props.element.measureLinks
            )
                .slice()
                .sort(sorters.sequenceSorter)
                .map((sm) => sm.measureId)
        );
    }, [
        props.sourceElement,
        props.element.measureLinks,
        setSelectedMeasureIds,
    ]);

    const classNames = mergeStyleSets({
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
        title: {
            flexGrow: 1,
            margin: 0,
            alignSelf: 'end',
            width: '100%',
            h4: {
                margin: '4px 0',
            },
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
        },
    });

    const updateLinks = (ids: string[]) => {
        props.onElementChanged(
            {
                ...props.element,
                measureLinks: [
                    ...props.element.measureLinks
                        .filter((ml) => ids.indexOf(ml.measureId) > -1)
                        .map((ml) => ({
                            ...ml,
                            sequence: ids.indexOf(ml.measureId),
                        })),
                    ...ids
                        .filter(
                            (id) =>
                                !props.element.measureLinks.some(
                                    (ml) => ml.measureId === id
                                )
                        )
                        .map((id) => ({
                            id: null,
                            measureId: id,
                            sequence: ids.indexOf(id),
                        })),
                ],
            },
            true
        );
    };

    const handleMeasurePickerUpdateClick = (ids: string[]) => {
        setSelectedMeasureIds(ids);
        setIsPicking(false);

        updateLinks(ids);
    };

    const measureCards = selectedMeasureIds.map((measureId) => {
        const measure = measures?.find((m) => m.id === measureId);

        const handleNameClick =
            props.onMeasureNavigate != null
                ? () => {
                      if (props.onMeasureNavigate) {
                          props.onMeasureNavigate(measureId);
                      }
                  }
                : null;

        // If this measure was not found - it may have been deleted. Save white space.
        if (!measure && !isLoading) {
            return null;
        }

        const measurePeriodData = periodData.find(
            (pd) =>
                pd.measureId === measure?.id ||
                (measure?.isLinked &&
                    measure.linkedFromMeasureId === pd.measureId)
        );

        return (
            <Shimmer
                key={`shimmer_${measureId}`}
                shimmerElements={[
                    {
                        type: ShimmerElementType.line,
                        height: 100,
                    },
                ]}
                isDataLoaded={!isLoading}
            >
                {!!measure && (
                    <TemplateReportMeasureListElementMeasureCard
                        isReadOnly={props.isReadOnly}
                        isActive={activeDragItemId === measureId}
                        measure={measure}
                        onNameClick={handleNameClick}
                        fyStartDate={fyStartDate}
                        periodData={measurePeriodData}
                        reportPeriod={props.reportPeriod}
                        reportPeriodType={props.reportPeriodType}
                        hideStatus={props.hideStatus}
                        hideTarget={props.hideTarget}
                    />
                )}
            </Shimmer>
        );
    });

    const togglePickMeasures = () => setIsPicking(!isPicking);

    const handleDragging = (id: string | null) => setActiveDragItemId(id);

    const handleDropped = (id: string | null, newIndex: number) => {
        if (!id) {
            return;
        }

        const movedItem = selectedMeasureIds.find((m) => m === id);
        const remainingItems = selectedMeasureIds.filter((r) => r !== id);

        const reorderedItems = movedItem
            ? [
                  ...remainingItems.slice(0, newIndex),
                  movedItem,
                  ...remainingItems.slice(newIndex),
              ]
            : remainingItems;

        setSelectedMeasureIds(reorderedItems);
        updateLinks(reorderedItems);
    };

    return (
        <React.Fragment>
            <div className={classNames.container}>
                <div className={classNames.header}>
                    <div className={classNames.title}>
                        {!!element.title && <h4>{element.title}</h4>}
                        {selectedMeasureIds.length === 0 && !isLoading && (
                            <Text
                                variant="small"
                                styles={{ root: { fontStyle: 'italic' } }}
                                block
                            >
                                No measures of success selected
                            </Text>
                        )}
                    </div>

                    {!props.isReadOnly && (
                        <PrimaryButton
                            text="Pick measures..."
                            styles={{
                                textContainer: {
                                    whiteSpace: 'nowrap',
                                },
                            }}
                            disabled={isLoading}
                            onClick={togglePickMeasures}
                        />
                    )}
                </div>
                <SortableContainer
                    ids={selectedMeasureIds || []}
                    onDragging={handleDragging}
                    onDropped={handleDropped}
                >
                    {measureCards}
                </SortableContainer>
            </div>
            <MeasurePickerPanel
                isOpen={isPicking}
                onDismiss={togglePickMeasures}
                onUpdateClick={handleMeasurePickerUpdateClick}
                missionId={props.missionId}
                selectedMeasureIds={selectedMeasureIds}
            />
        </React.Fragment>
    );
}

type TemplateReportMeasureListElementMeasureCardProps = {
    isActive: boolean;
    isReadOnly: boolean;
    fyStartDate: string | null | undefined;
    reportPeriodType: ReportPeriodTypes | null;
    reportPeriod: number | null;
    measure: ExtractQueryArrayType<
        GetMeasuresForStatusReportQuery,
        ['measures']
    >;
    onNameClick: (() => void) | null;
    hideStatus?: boolean;
    hideTarget?: boolean;
    periodData:
        | ExtractQueryType<
              GetMeasurePeriodDataQuery,
              ['periodData', 'measurePeriodData']
          >
        | undefined
        | null;
};

function TemplateReportMeasureListElementMeasureCard(
    props: TemplateReportMeasureListElementMeasureCardProps
): JSX.Element {
    const { measure, isReadOnly, periodData } = props;

    const { currentTheme } = useThemes();

    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: props.measure.id || '' });

    const classNames = mergeStyleSets({
        titleBar: {
            display: 'flex',
            flexDirection: 'row',
        },
        nameContainer: {
            flexGrow: 1,
        },
        main: {},
        arrowContainer: {
            minWidth: 40,
        },
        targetsAndActuals: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            alignItems: 'center',
        },
        actual: {
            alignSelf: 'stretch',
            flexGrow: 1,
            flexBasis: 0,
            paddingTop: 4,
            paddingBottom: 4,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            gap: 0,
            paddingLeft: 8,

            borderWidth: 1,
            borderColor: currentTheme.palette.neutralLight,
            borderStyle: 'solid',
            borderRadius: 4,
        },
        targets: {
            flexGrow: 1,
            flexBasis: 0,
            alignSelf: 'stretch',

            display: 'flex',
            flexDirection: 'row',

            gap: 2,
            '> div': {
                flexGrow: 1,
                flexBasis: 0,
            },
        },
    });

    return (
        <div
            ref={setNodeRef}
            style={{
                transformOrigin: '0 0',
                opacity: props.isActive ? 0.4 : 1,
                transform: CSS.Translate.toString(transform),
                transition: transition || undefined,
                breakInside: 'avoid',
            }}
        >
            <AdvanceCard childrenGap={4}>
                <AdvanceCard.Item>
                    <div className={classNames.titleBar}>
                        <div className={classNames.nameContainer}>
                            <Text variant="medium">
                                {props.onNameClick ? (
                                    <Link onClick={props.onNameClick}>
                                        {measure?.name}
                                    </Link>
                                ) : (
                                    measure?.name
                                )}
                            </Text>
                        </div>
                        <DragHandlerButtonMemo
                            hidden={isReadOnly}
                            handleListeners={listeners}
                            handleAttributes={attributes}
                            iconName="GripperDotsVertical"
                        />
                    </div>
                </AdvanceCard.Item>
                <AdvanceCard.Item>
                    <div className={classNames.targetsAndActuals}>
                        {!props.hideStatus && (
                            <div className={classNames.arrowContainer}>
                                {periodData && (
                                    <MeasureArrow
                                        {...periodData}
                                        isStatusLimited={
                                            measure.isStatusLimited
                                        }
                                        showPercentage={
                                            periodData?.actualFormatted !==
                                                null ||
                                            periodData?.targetFormatted !== null
                                        }
                                        percentagePosition="Bottom"
                                        textFontSize={10}
                                        iconFontSize={20}
                                        skipValuesCheck
                                    />
                                )}
                            </div>
                        )}
                        <div className={classNames.actual}>
                            <ReportPeriodDataActualBox
                                {...props}
                                periodData={periodData}
                                onWarningClick={props.onNameClick}
                            />
                        </div>
                        <div className={classNames.targets}>
                            <ReportPeriodDataTargetBoxes
                                periodData={periodData}
                            />
                        </div>
                    </div>
                </AdvanceCard.Item>
            </AdvanceCard>
        </div>
    );
}
