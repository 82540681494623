import React, { ReactNode } from 'react';
import { MessageBar, MessageBarType, Stack, Text } from '@fluentui/react';
import { useStateContext } from '../services/contextProvider';
import { ExtractQueryArrayType } from '../data/extendedTypes';
import { GetTaskQuery } from '../data/types';
import DeleteModal from './shared/DeleteModal';
import { useTaskDeleter } from '../hooks/useTaskDeleter';
import { useIsTaskImplied } from '../hooks/useIsTaskImplied';

export function TaskDeleteModal(props: {
    isOpen: boolean;
    task: ExtractQueryArrayType<GetTaskQuery, ['task']>;
    onCancelClick: () => void;
    onDeleted: () => void;
}): JSX.Element {
    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const {
        canDelete,
        deleteWillReturnToDependencies,
        deleteTaskAsync,
        isDeleting,
        deleteError,
    } = useTaskDeleter(currentTenantId, currentFinancialYearCode, props.task);

    const isImpliedTask = useIsTaskImplied(props.task);

    const handleDeleteConfirmed = async (): Promise<void> => {
        if (canDelete) {
            await deleteTaskAsync();
            props.onDeleted();
        }
    };

    let message: string;
    let content: ReactNode | undefined = undefined;

    if (isImpliedTask) {
        message = deleteWillReturnToDependencies
            ? 'Are you sure you want to unaccept this implied task?'
            : 'Are you sure you want to delete this implied task?';

        content = (
            <Stack tokens={{ childrenGap: 16 }}>
                {deleteWillReturnToDependencies && (
                    <Text variant="small">
                        &ldquo;Unaccepting&rdquo; returns the task to your Task
                        Notifications panel, where you can re-accept or reject.
                    </Text>
                )}

                {props.task.resourcedTasks.length > 0 && (
                    <MessageBar
                        messageBarType={MessageBarType.info}
                        messageBarIconProps={{ iconName: 'Info' }}
                        isMultiline
                    >
                        NOTE: Resources assigned to this task will be notified
                        accordingly.
                    </MessageBar>
                )}
            </Stack>
        );
    } else {
        message = deleteWillReturnToDependencies
            ? 'Are you sure you want to unaccept this specified task?'
            : 'Are you sure you want to delete this specified task?';

        content = (
            <Stack tokens={{ childrenGap: 16 }}>
                {deleteWillReturnToDependencies && (
                    <Text variant="small">
                        &ldquo;Unaccepting&rdquo; returns the task to your Task
                        Notifications panel, where you can re-accept or reject.
                    </Text>
                )}
                {props.task.subTasks.length > 0 && (
                    <MessageBar
                        messageBarType={MessageBarType.warning}
                        messageBarIconProps={{ iconName: 'Warning' }}
                        isMultiline
                    >
                        WARNING: All subsequent, manually-written, implied tasks
                        will be deleted - if you want to preserve any of these
                        tasks, cancel and move the implied tasks accordingly.
                    </MessageBar>
                )}
            </Stack>
        );
    }

    return (
        <DeleteModal
            activeViewName="TaskDelete"
            isOpen={props.isOpen}
            onDismiss={props.onCancelClick}
            message={message}
            primaryButtonText={
                deleteWillReturnToDependencies ? 'Unaccept' : undefined
            }
            content={content}
            deleteAction={handleDeleteConfirmed}
            isDeleting={isDeleting}
            error={deleteError}
        />
    );
}
