import React from 'react';
import { Text } from '@fluentui/react';
import {
    DonutChart,
    IChartDataPoint,
    IChartProps,
} from '@fluentui/react-charting';
import { useGetMissionTasksQuery } from '../data/types';
import { sorters } from '../data/sorters';
import { useTaskSummary } from '../hooks/useTaskSummary';
import { useFormatters } from '../hooks/useFormatters';

function MeasureLinkedTasksDonut(props: {
    tenantId: string;
    missionId: string;
    measureId: string;
    showPercentageInDonut?: boolean;
}): JSX.Element {
    const formatters = useFormatters();

    const { data: taskData } = useGetMissionTasksQuery({
        skip: !props.missionId || !props.tenantId,
        variables: {
            tenantId: props.tenantId,
            missionId: props.missionId,
        },
    });

    const linkedTasks = (taskData?.tasks || [])
        .slice()
        .filter((t) => t.parentTaskId === null && !t.isDuplicate)
        .filter((t) =>
            t.linkedMeasures.some((lm) => lm.measureId === props.measureId)
        );

    const impliedTasks = (taskData?.tasks || [])
        .slice()
        .sort(sorters.sequenceSorter)
        .filter(
            (it) =>
                linkedTasks.some((lt) => lt.id === it.parentTaskId) &&
                !it.isDuplicate
        );

    const taskSummary = useTaskSummary(impliedTasks);

    const averagePercentage = linkedTasks.length
        ? linkedTasks.reduce(
              (sum, current) => sum + current.percentComplete,
              0
          ) / linkedTasks.length
        : null;

    const valueInsideDonut = averagePercentage
        ? formatters.formatTaskPercentage(averagePercentage)
        : undefined;

    const chartData: IChartDataPoint[] = taskSummary
        .filter((ts) => ts.count)
        .map((ts) => {
            return {
                legend: ts.name,
                data: ts.count,
                color: ts.colour,
            };
        });

    const data: IChartProps = {
        chartTitle: 'Linked Tasks',
        chartData: chartData,
    };

    if (!data.chartData?.length) {
        return (
            <div
                style={{
                    width: '100%',
                    height: 210,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Text
                    variant="small"
                    styles={{ root: { fontStyle: 'italic' } }}
                    block
                >
                    No linked tasks selected
                </Text>
            </div>
        );
    }

    return (
        <DonutChart
            data={data}
            innerRadius={40}
            height={210}
            valueInsideDonut={
                props.showPercentageInDonut ? valueInsideDonut : undefined
            }
            legendProps={{
                styles: {
                    root: {
                        paddingTop: 30,
                    },
                },
                allowFocusOnLegends: true,
            }}
        />
    );
}

export default React.memo(MeasureLinkedTasksDonut);
