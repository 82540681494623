import * as React from 'react';

import { useNavigate, generatePath } from 'react-router-dom';
import { Link, ILinkProps } from '@fluentui/react';
import { paths } from '../../services/navigation';

export interface InternalLinkProps extends ILinkProps {
    scene?: 'Team' | 'Mission' | 'Contributor' | 'Present' | 'StatusReport';
    tenantCode?: string | undefined;
    financialYearCode?: string | undefined;
    teamCode?: string | null | undefined;
    missionId?: string | null | undefined;
    contributorId?: string | null | undefined;
    presentation?: string | null | undefined;
}

export function InternalLink(props: InternalLinkProps): JSX.Element {
    const navigate = useNavigate();

    const params = {
        tenantCode: props.tenantCode?.toLowerCase() || '',
        financialYearCode: props.financialYearCode?.toLowerCase() || '',
        teamCode: props.teamCode?.toLowerCase() || undefined,
        missionId: props.missionId || undefined,
        statusReportId: props.statusReportId || undefined,
        contributorId: props.contributorId || undefined,
        presentation: props.presentation || undefined,
    };

    let href: string | undefined;

    if (props.scene) {
        switch (props.scene) {
            case 'Mission': {
                href =
                    params.missionId && params.teamCode
                        ? generatePath(paths.missionBuilder, params)
                        : undefined;
                break;
            }
            case 'Contributor': {
                href = params.contributorId
                    ? params.teamCode
                        ? generatePath(paths.contributorWithTeam, params)
                        : generatePath(paths.contributor, params)
                    : undefined;
                break;
            }
            case 'Team': {
                href = params.teamCode
                    ? generatePath(paths.team, params)
                    : undefined;
                break;
            }
            case 'Present': {
                href =
                    params.missionId && params.teamCode
                        ? generatePath(paths.present, params)
                        : undefined;
                break;
            }
            case 'StatusReport': {
                href =
                    params.missionId && params.teamCode && params.statusReportId
                        ? generatePath(paths.statusReport, params)
                        : undefined;
                break;
            }
            default: {
                href = props.href;
            }
        }
    }

    const onClick = (ev: React.MouseEvent<HTMLElement>): void => {
        if (href) {
            navigate(href);
        }
        if (ev) {
            ev.preventDefault();
        }
    };

    if (href) {
        return (
            <Link
                style={props.style}
                className={props.className}
                disabled={props.disabled}
                href={href}
                onClick={onClick}
            >
                {props.children}
            </Link>
        );
    } else {
        return <span>{props.children}</span>;
    }
}

InternalLink.propTypes = Link.propTypes;
