import { Guid } from 'guid-typescript';
import {
    ReportPeriodTypes,
    TemplateReportCreateInput,
    TemplateReportElementInput,
    TemplateReportSectionCreateInput,
    TemplateReportViewCreateInput,
    useCreateTemplateReportMutation,
    useGetTemplateReportLazyQuery,
} from '../../../data/types';
import { sorters } from '../../../data/sorters';

export type CreateTemplateReportParams = {
    templateId: string;
    title: string;
    missionId: string;
    reportingPeriod: {
        periodType: ReportPeriodTypes;
        period: number | null;
    } | null;
};

export function useCreateTemplateReport(tenantId: string | undefined): {
    createTemplateReport: (
        params: CreateTemplateReportParams
    ) => Promise<string | null>;
} {
    const [loadTemplateReportMutation] = useGetTemplateReportLazyQuery();

    const [createTemplateReportMutation] = useCreateTemplateReportMutation();

    const createTemplateReport = async (
        params: CreateTemplateReportParams
    ): Promise<string | null> => {
        const { templateId, title, missionId, reportingPeriod } = params;

        if (!tenantId) {
            return null;
        }

        const template = await loadTemplateReportMutation({
            variables: {
                tenantId: tenantId,
                id: templateId,
            },
        });

        const templateReport = template.data?.reports?.templateReport;

        if (!templateReport) {
            return null;
        }

        // Map of the template item id to the id created for the users report.
        const idMapping: Record<string, string> = {};

        const newViews: TemplateReportViewCreateInput[] = templateReport.views
            .slice()
            .map(({ id, ...view }) => {
                const newViewId = Guid.create().toString();
                idMapping[id || ''] = newViewId;
                return {
                    ...view,
                    id: newViewId,
                    version: '',
                };
            });

        const newSections: TemplateReportSectionCreateInput[] =
            templateReport.sections
                .slice()
                .sort(sorters.sequenceSorter)
                .sort((a, b) => {
                    // Do the non view fields first
                    if (a.templateReportViewId && !b.templateReportViewId)
                        return +1;
                    if (!a.templateReportViewId && b.templateReportViewId)
                        return -1;
                    return 0;
                })
                .map((s) => {
                    const groups = s.groups.map((g) => {
                        const groupId = Guid.create().toString();
                        idMapping[g.id || ''] = groupId;
                        return {
                            id: groupId,
                            sequence: g.sequence,
                            version: '',
                            layoutGrow: g.layoutGrow,
                            layoutDirection: g.layoutDirection,
                        };
                    });

                    const sectionId = Guid.create().toString();
                    idMapping[s.id || ''] = sectionId;

                    const elements: TemplateReportElementInput[] = s.elements
                        .slice()
                        .sort(sorters.sequenceSorter)
                        .map((e) => {
                            const elementId = Guid.create().toString();
                            idMapping[e.id || ''] = elementId;
                            return {
                                id: elementId,
                                sourceTemplateReportSectionElementId:
                                    e.sourceTemplateReportSectionElementId
                                        ? idMapping[
                                              e
                                                  .sourceTemplateReportSectionElementId
                                          ]
                                        : null,
                                sectionId: sectionId,
                                title: e.title,
                                attachmentId: null,
                                sequence: e.sequence,
                                layoutGrow: e.layoutGrow,
                                textContent: e.textContent,
                                type: e.type,
                                measureLinks: e.measureLinks,
                                taskLinks: e.taskLinks,
                                columns: e.columns
                                    .slice()
                                    .sort(sorters.sequenceSorter)
                                    .map((c) => ({
                                        ...c,
                                        id: Guid.create().toString(),
                                        cells: c.cells.slice().map((cell) => ({
                                            ...cell,
                                            id: Guid.create().toString(),
                                        })),
                                    })),
                                groupId: e.groupId
                                    ? idMapping[e.groupId]
                                    : null,
                                version: '',
                            };
                        });

                    return {
                        id: sectionId,
                        templateReportViewId: s.templateReportViewId
                            ? idMapping[s.templateReportViewId]
                            : null,
                        sequence: s.sequence,
                        title: s.title,
                        layoutDirection: s.layoutDirection,
                        availableElementTypes: s.availableElementTypes,
                        elements: elements,
                        groups: groups,
                        version: '',
                    };
                }) || [];

        const newReport: TemplateReportCreateInput = {
            id: null,
            missionId: missionId,
            title: title,
            reportDate: null,
            reportPeriodType: reportingPeriod?.periodType ?? null,
            reportPeriod: reportingPeriod?.period ?? null,
            utcCompletedDate: null,
            utcDataDate: null,
            version: '',
            sections: newSections,
            views: newViews,
        };

        const result = await createTemplateReportMutation({
            variables: {
                tenantId: tenantId,
                input: newReport,
            },
        });

        return result.data?.templateReportCreate.id || null;
    };

    return {
        createTemplateReport,
    };
}
