import React, { useEffect, useState } from 'react';
import {
    SelectionMode,
    ShimmeredDetailsList,
    MessageBar,
    MessageBarType,
    DetailsListLayoutMode,
    IconButton,
    Stack,
    Icon,
    PrimaryButton,
} from '@fluentui/react';

import { useStateContext } from '../services/contextProvider';

import {
    useGetTaskAttachmentsQuery,
    useGetMeasureAttachmentsQuery,
    useAttachmentDeleteMutation,
    GetTaskAttachmentsQuery,
    GetMeasureAttachmentsQuery,
    useGetTemplateReportAttachmentsQuery,
} from '../data/types';
import { ExtractQueryArrayType } from '../data/extendedTypes';
import { useAttachmentIcons } from '../hooks/useAttachmentIcons';
import AttachmentLink from './AttachmentLink';
import DeleteModal from './shared/DeleteModal';
import { useThemes } from '../hooks/useThemes';

type AttachmentsListProps = {
    measureId?: string | null | undefined;
    taskId?: string | null | undefined;
    templateReportId?: string | null | undefined;
    showImagePreview?: boolean;
    allowSelection?: boolean;
    onAttachmentSelected?: (attachmentId: string) => void;
    onAttachmentDeleted?: (attachmentId: string) => void;
};

type AttachmentQuery =
    | ExtractQueryArrayType<GetTaskAttachmentsQuery, ['task', 'attachments']>
    | ExtractQueryArrayType<
          GetMeasureAttachmentsQuery,
          ['measure', 'attachments']
      >;

export function AttachmentsList(props: AttachmentsListProps): JSX.Element {
    const { currentTenantId } = useStateContext();
    const { currentTheme } = useThemes();

    const {
        loading: taskDataLoading,
        data: taskData,
        error: taskDataError,
        refetch: tasksRefetch,
    } = useGetTaskAttachmentsQuery({
        skip: !props.taskId,
        variables: {
            tenantId: currentTenantId || '',
            taskId: props.taskId || '',
        },
    });

    const {
        loading: measureDataLoading,
        data: measureData,
        error: measureDataError,
        refetch: measuresRefetch,
    } = useGetMeasureAttachmentsQuery({
        skip: !props.measureId,
        variables: {
            tenantId: currentTenantId || '',
            measureId: props.measureId || '',
        },
    });

    const {
        loading: templateReportDataLoading,
        data: templateReportData,
        error: templateReportDataError,
        refetch: templateReportsRefetch,
    } = useGetTemplateReportAttachmentsQuery({
        skip: !props.templateReportId,
        variables: {
            tenantId: currentTenantId || '',
            templateReportId: props.templateReportId || '',
        },
    });

    const attachmentIcons = useAttachmentIcons();

    const attachments =
        measureData?.measure?.attachments ||
        taskData?.task?.attachments ||
        templateReportData?.reports?.templateReport?.attachments ||
        [];

    const reload = () => {
        if (props.measureId) {
            measuresRefetch();
        } else if (props.taskId) {
            tasksRefetch();
        } else if (props.templateReportId) {
            templateReportsRefetch();
        }
    };

    const [selectedAttachment, setSelectedAttachment] =
        React.useState<AttachmentQuery>();
    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [deleteErrorResult, setDeleteErrorResult] = React.useState<{
        message: string | null;
    } | null>();
    const [deleteAttachment, { error: deleteError }] =
        useAttachmentDeleteMutation({
            variables: {
                tenantId: currentTenantId || '',
                id: selectedAttachment?.id || '',
                restore: false,
            },
        });

    const loading =
        taskDataLoading || measureDataLoading || templateReportDataLoading;
    const error = taskDataError || measureDataError || templateReportDataError;

    if (error) {
        return (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={true}
            >
                <span>{error.message}</span>
            </MessageBar>
        );
    }

    const deleteAction = async (): Promise<void> => {
        setIsDeleting(true);

        const result = await deleteAttachment();
        if (!result.data?.attachmentDelete?.success) {
            setDeleteErrorResult(result.data?.attachmentDelete || null);
        }
        if (props.onAttachmentDeleted && selectedAttachment?.id) {
            props.onAttachmentDeleted(selectedAttachment?.id);
        }
        setIsConfirmingDelete(false);
        setIsDeleting(false);
        reload();
    };

    const renderAttachment = (attachment: AttachmentQuery): JSX.Element => {
        return (
            <Stack
                horizontal
                tokens={{ padding: 8, childrenGap: 16 }}
                verticalAlign="center"
            >
                <Stack.Item align="center">
                    <Icon iconName={attachmentIcons.getIconName(attachment)} />
                </Stack.Item>
                <Stack.Item align="center" grow>
                    {props.showImagePreview &&
                    attachment.id &&
                    attachment.isFile ? (
                        <div>
                            <AttachmentImagePreview
                                attachmentId={attachment.id}
                            />
                        </div>
                    ) : (
                        <AttachmentLink attachment={attachment} />
                    )}

                    {props.allowSelection && props.onAttachmentSelected && (
                        <PrimaryButton
                            text="Select"
                            onClick={() => {
                                if (
                                    props.onAttachmentSelected &&
                                    attachment.id
                                ) {
                                    props.onAttachmentSelected(attachment.id);
                                }
                            }}
                        />
                    )}
                </Stack.Item>
            </Stack>
        );
    };

    const renderDeleteIcon = (attachment: AttachmentQuery): JSX.Element => {
        return (
            <IconButton
                iconProps={{
                    styles: {
                        root: {
                            color: currentTheme.semanticColors.errorIcon,
                        },
                    },
                    iconName: 'Delete',
                }}
                onClick={async () => {
                    setSelectedAttachment(attachment);
                    setIsConfirmingDelete(true);
                    setIsDeleting(false);
                    setDeleteErrorResult(null);
                }}
            />
        );
    };

    return (
        <>
            <ShimmeredDetailsList
                items={attachments}
                enableShimmer={loading}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                isHeaderVisible={loading || attachments.length > 0}
                onShouldVirtualize={(): boolean => false}
                columns={[
                    {
                        key: 'name',
                        name: 'Name',
                        fieldName: 'name',
                        minWidth: 60,
                        onRender: renderAttachment,
                    },
                    {
                        key: 'del',
                        name: '',
                        fieldName: 'del',
                        minWidth: 20,
                        onRender: renderDeleteIcon,
                    },
                ]}
            />

            <DeleteModal
                activeViewName={
                    props.measureId
                        ? 'MeasureAttachmentDelete'
                        : props.taskId
                          ? 'TaskAttachmentDelete'
                          : null
                }
                isOpen={isConfirmingDelete}
                onDismiss={(): void => setIsConfirmingDelete(false)}
                message={'Are you sure you want to delete this attachment?'}
                deleteAction={deleteAction}
                isDeleting={isDeleting}
                error={deleteError || deleteErrorResult}
            />
        </>
    );
}

function AttachmentImagePreview(props: { attachmentId: string }) {
    const { currentTenantId, configuration } = useStateContext();

    const { storageFnUrl } = configuration;

    const [imgSrc, setImgSrc] = useState<string>();
    const [errorCount, setErrorCount] = useState(0);
    const maxRetries = 3;

    useEffect(() => {
        setImgSrc(
            `${storageFnUrl}/api/Attachment?tenantId=${currentTenantId}&attachmentId=${props.attachmentId}`
        );
    }, [props.attachmentId, storageFnUrl, currentTenantId]);

    const handleError = () => {
        if (errorCount < maxRetries) {
            setErrorCount((prev) => prev + 1);
            setTimeout(() => {
                setImgSrc(
                    `${storageFnUrl}/api/Attachment?tenantId=${currentTenantId}&attachmentId=${props.attachmentId}?${new Date().getTime()}`
                );
            }, 1000);
        } else {
            setImgSrc(undefined);
        }
    };

    return imgSrc ? (
        <img
            src={imgSrc}
            style={{ maxWidth: '100%' }}
            onError={handleError}
            alt="Image Preview"
        />
    ) : null;
}
