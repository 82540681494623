import { Label } from '@fluentui/react';
import styles from './ApiHelp.module.css';

export function ApiHelpMeasures(): JSX.Element {
    return (
        <div>
            <h1>Measures</h1>
            A mission may have zero or many Measures.
            <br />
            <br />
            <Label>OData Query Parameters</Label>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td>
                            <code className={styles.method}>GET</code>
                            <code className={styles.code}>/Measure</code>
                        </td>
                        <td>
                            Get a List of Measures
                            <br />
                            <br />
                            Expandable properties: <br />
                            <code className={styles.code}>
                                ?$expand=Mission,MeasureGroup,Currency,Dependencies,DependentOn
                            </code>
                            <br />
                            <br />
                            To expand Measure Group and display only Measure
                            Group name:
                            <br />
                            <code className={styles.code}>
                                ?$expand=MeasureGroup(select=Name)
                            </code>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <code className={styles.method}>GET</code>
                            <code className={styles.code}>
                                /Measure(&#123;id&#125;)
                            </code>
                        </td>
                        <td>
                            Get single Measure
                            <p>
                                Expandable properties: <br />
                                <code className={styles.code}>
                                    ?$expand=Comments(select=UserId,Text),Attachments,LastComment,MeasureGroup(select=Name),Values
                                </code>
                            </p>
                            <p>
                                To expand Measure Group and display only Measure
                                Group name:
                                <br />
                                <code className={styles.code}>
                                    ?$expand=MeasureGroup(select=Name)
                                </code>
                            </p>
                            <p>
                                To expand Comments and only display User and
                                Comment text:
                                <br />
                                <code className={styles.code}>
                                    ?$expand=Comments(select=UserId,Text)
                                </code>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <code className={styles.method}>PATCH</code>
                            <code className={styles.code}>/Measure</code>
                        </td>
                        <td>
                            Update Measure.
                            <br />
                            <br />
                            Updatable properties: <br />
                            <code className={styles.code}>Name</code>
                            <br />
                            <code className={styles.code}>ShortName</code>
                            <br />
                            <code className={styles.code}>Description</code>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
