import {
    MonthRow,
    convertToChartData,
    filterFiscalYearOnly,
    convertQuarterData,
    convertToYTDChartData,
    convertStatusToChartData,
    recalcYtd,
    ChartData,
    convertYearData,
    getQuarter,
} from '../utils/measureUtils';
import {
    ChartDisplay,
    FrequencyPeriods,
    MeasureTypes,
    StatusTypes,
    ValueTypes,
} from '../../../data/types';
import { useAvailableMeasureChartDisplays } from './useAvailableMeasureChartDisplays';
import { useMeasureStatusCalc } from '../../../hooks/useMeasureStatusCalc';
import dayjs from 'dayjs';

export function useChartData(
    data: MonthRow[] | null | undefined,
    measure:
        | {
              id: string | null;
              frequencyPeriod: FrequencyPeriods;
              measureType: MeasureTypes;
              valueType: ValueTypes;
              chartDisplay: ChartDisplay | null;
              statusType: StatusTypes;
              greenRange: number;
              yellowRange: number;
              yellowStart: number;
              isStatusLimited: boolean;
              fullYearTarget: number | null;
              isFullYearTarget: boolean;
              valueFormula: string | null;
              lastAsOf: {
                  asOfDate: string | null;
              } | null;
          }
        | undefined
        | null,
    fyStartDate: string | undefined
): {
    chartDisplay: {
        key: ChartDisplay;
        text: string;
    };
    chartData: ChartData[] | null;
    chartActualMode: 'bar' | 'line';
    chartTooltipTitle: string | undefined;
    isStatusOnly: boolean;
} {
    const { calcStatus, isCalcSupported } = useMeasureStatusCalc();

    let chartData: ChartData[] | null = null;

    let chartActualMode: 'bar' | 'line' = 'bar';
    let chartTooltipTitle: string | undefined = undefined;

    const { chartDisplayOptions, isStringMeasureType } =
        useAvailableMeasureChartDisplays(measure);

    const filterToLastActualDateBeforeNow = (data: MonthRow[]): MonthRow[] => {
        const endDate = dayjs();

        return data
            .filter((d) => !d.isDeleted)
            .filter(
                (d) =>
                    d.actual.decimalValue !== null ||
                    d.actual.dateValue !== null ||
                    d.actual.stringValue !== null
            )
            .filter((d) => {
                const asOfDate = dayjs(d.dt);
                return asOfDate <= endDate;
            });
    };

    // Get the chart display, check its legit
    const chartDisplay = measure?.chartDisplay
        ? chartDisplayOptions.find((cd) => cd.key === measure.chartDisplay) ||
          chartDisplayOptions[0]
        : chartDisplayOptions[0];

    if (data && fyStartDate) {
        if (isStringMeasureType) {
            chartData = convertStatusToChartData(data);
        } else if (chartDisplay.key === ChartDisplay.Qtd) {
            const quarterData = convertQuarterData(
                data,
                fyStartDate,
                'add',
                measure?.id ?? null
            ).rows;
            recalcYtd(quarterData);
            chartData = convertToYTDChartData(quarterData);
            chartActualMode = 'line';
            chartTooltipTitle = 'Quarter to date';
        } else if (
            chartDisplay.key === ChartDisplay.Ytd ||
            chartDisplay.key === ChartDisplay.YtdVsFullYear
        ) {
            const fiscalData = filterFiscalYearOnly(data, fyStartDate);

            const yearToDateData =
                chartDisplay.key === ChartDisplay.Ytd
                    ? filterToLastActualDateBeforeNow(fiscalData)
                    : fiscalData;

            recalcYtd(yearToDateData);

            const yearData = convertYearData(
                yearToDateData,
                fyStartDate,
                'add',
                measure?.id || null
            );

            if (yearData.rows.length) {
                chartData = convertToChartData([yearData.rows[0]]);

                if (
                    chartDisplay.key === ChartDisplay.YtdVsFullYear &&
                    measure?.isFullYearTarget &&
                    measure.fullYearTarget !== null
                ) {
                    chartData[0].Target = measure.fullYearTarget;
                    chartTooltipTitle = 'Year to Date vs Full Year Target';
                } else {
                    const latestLabel = yearToDateData.length
                        ? yearToDateData[yearToDateData.length - 1].label
                        : null;

                    chartTooltipTitle = latestLabel
                        ? `Year to Date (${latestLabel})`
                        : 'Year to Date';
                }

                // force status recalc
                chartData[0].StatusValue = undefined;
            } else {
                chartData = null;
            }
        } else if (measure?.frequencyPeriod === FrequencyPeriods.None) {
            chartData = convertToChartData(data);
        } else {
            const fiscalData = filterFiscalYearOnly(data, fyStartDate);

            if (chartDisplay.key === ChartDisplay.Ftd) {
                chartData = convertToYTDChartData(fiscalData);
                chartActualMode = 'line';
                chartTooltipTitle = `Year to date`;
            } else {
                chartData = convertToChartData(fiscalData);
            }
        }

        let prevStatusValue: number | null | undefined = undefined;
        if (chartData && measure && isCalcSupported(measure.measureType)) {
            chartData.forEach((cd) => {
                if (cd.StatusValue === null || cd.StatusValue === undefined) {
                    const status =
                        cd.Target !== null && cd.Actual !== null && measure
                            ? calcStatus(
                                  Number(cd.Target),
                                  Number(cd.Actual),
                                  measure,
                                  prevStatusValue
                              )
                            : null;
                    if (status) {
                        cd.StatusValue = status.statusValue;
                        cd.ArrowColour = status.arrowColour;
                        cd.ArrowDirection = status.arrowDirection;
                    }
                    prevStatusValue = status?.statusValue;
                }
            });
        }

        if (measure?.lastAsOf?.asOfDate) {
            let currentPeriod: ChartData | null = null;

            switch (chartDisplay.key) {
                case ChartDisplay.ByFrequency:
                case ChartDisplay.Calculated:
                case ChartDisplay.Ftd: {
                    currentPeriod =
                        chartData?.find(
                            (cd) => cd.Date === measure?.lastAsOf?.asOfDate
                        ) || null;
                    break;
                }

                case ChartDisplay.Qtd: {
                    const quarter = getQuarter(
                        fyStartDate,
                        measure?.lastAsOf?.asOfDate
                    );
                    currentPeriod =
                        chartData?.find((cd) =>
                            cd.Date
                                ? getQuarter(fyStartDate, cd.Date) === quarter
                                : null
                        ) || null;
                    break;
                }

                default:
                    // Don't set for year
                    break;
            }

            if (currentPeriod) {
                currentPeriod.isCurrentPeriod = true;
            }
        }
    }

    return {
        chartDisplay,
        chartData,
        chartActualMode,
        chartTooltipTitle,
        isStatusOnly: isStringMeasureType,
    };
}
