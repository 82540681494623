import React from 'react';
import { Panel, PanelType, Stack } from '@fluentui/react';
import { AttachmentsList } from './AttachmentsList';
import { NewAttachment } from './NewAttachment';
import { useActiveView } from '../hooks/useActiveView';

type AttachmentsPanelProps = {
    showPanel: boolean;
    measureId?: string | null | undefined;
    taskId?: string | null | undefined;
    onDismiss: () => void;
};

export default function AttachmentsPanel(
    props: AttachmentsPanelProps
): JSX.Element {
    const activeView =
        props.showPanel && props.measureId
            ? 'MeasureAttachments'
            : props.showPanel && props.taskId
            ? 'TaskAttachments'
            : null;

    useActiveView(activeView);

    return (
        <Panel
            isOpen={props.showPanel}
            type={PanelType.smallFixedFar}
            onDismiss={props.onDismiss}
            headerText="Attachments"
            closeButtonAriaLabel="Close"
        >
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item>
                    <NewAttachment {...props} />
                </Stack.Item>
                <Stack.Item grow>
                    <AttachmentsList {...props} />
                </Stack.Item>
            </Stack>
        </Panel>
    );
}
