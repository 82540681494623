import React from 'react';

import {
    Stack,
    MessageBar,
    Spinner,
    SpinnerSize,
    IconButton,
    MessageBarType,
} from '@fluentui/react';
import { useActionButtons } from '../../hooks/useActionButtons';

type ActionBarProps = {
    isSaving?: boolean;
    isSaved?: boolean;
    isValid?: boolean;
    disabled: boolean;
    errorMessage: string | undefined | null;
    additionalButtons?: JSX.Element[];
    onSaveButtonClick?: () => void | Promise<void>;
    onDiscardButtonClick?: () => void | Promise<void>;
    onDeleteButtonClick?: () => void | Promise<void> | null | undefined;
    commandBar?: JSX.Element;
    deleteIconName?: string | null;
    deleteText?: string | null;
};

export function ActionBar(props: ActionBarProps): JSX.Element {
    const actionButtons = useActionButtons(props);

    const buttons: JSX.Element[] = [];

    props.additionalButtons?.forEach((b) => {
        buttons.push(<div style={{ marginRight: 8 }}>{b}</div>);
    });

    actionButtons.forEach((buttonProps) => {
        buttons.push(<IconButton {...buttonProps} />);
    });

    return (
        <Stack
            horizontal
            horizontalAlign="stretch"
            styles={{
                root: {
                    width: '100%',
                },
            }}
        >
            <Stack.Item grow align="center">
                <Stack
                    horizontal
                    horizontalAlign="start"
                    verticalAlign="center"
                    verticalFill
                >
                    {props.errorMessage && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={true}
                            styles={{
                                root: {
                                    width: 'auto',
                                    marginLeft: 16,
                                    marginRight: 16,
                                },
                            }}
                        >
                            <span>{props.errorMessage}</span>
                        </MessageBar>
                    )}

                    {!props.errorMessage && props.isSaved && (
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            styles={{
                                root: { marginLeft: 16, marginRight: 16 },
                            }}
                        >
                            <span>Saved</span>
                        </MessageBar>
                    )}

                    {props.isSaving && !props.isSaved && (
                        <Spinner
                            styles={{
                                root: { marginLeft: 16, marginRight: 16 },
                            }}
                            size={SpinnerSize.small}
                            label="Saving..."
                            labelPosition="right"
                        />
                    )}

                    {!props.isSaved &&
                        !props.isSaving &&
                        !props.errorMessage &&
                        props.commandBar}
                </Stack>
            </Stack.Item>

            {buttons.map((button, index) => (
                <Stack.Item key={`actionbutton-${index}`} align="center">
                    {button}
                </Stack.Item>
            ))}
        </Stack>
    );
}
