import React from 'react';
import {
    FontSizes,
    IButtonStyles,
    IconButton,
    Spinner,
    SpinnerSize,
} from '@fluentui/react';

type ExpandCollapseProps = {
    isExpanded: boolean;
    isBusy?: boolean;
    onExpand: () => void;
    onCollapse: () => void;
    compact?: boolean;
};

export default function ExpandCollapse(
    props: ExpandCollapseProps
): JSX.Element {
    const styles = {
        expandIsCollapsed: {
            root: props.compact
                ? {
                      width: 24,
                      height: 24,
                  }
                : undefined,
            icon: props.isExpanded
                ? {
                      transform: 'rotate(90deg)',
                      transformOrigin: '50% 50%',
                      transition: 'transform .1s linear',
                      fontSize: props.compact ? FontSizes.small : undefined,
                  }
                : {
                      transform: 'rotate(0deg)',
                      transformOrigin: '50% 50%',
                      transition: 'transform .1s linear',
                      fontSize: props.compact ? FontSizes.small : undefined,
                  },
        } as IButtonStyles,
    };

    if (props.isBusy) {
        return (
            <div style={{ padding: 6 }}>
                <Spinner size={SpinnerSize.medium} />
            </div>
        );
    }

    return (
        <IconButton
            styles={styles.expandIsCollapsed}
            className="no-print"
            iconProps={{
                iconName: 'ChevronRightMed',
            }}
            title={props.isExpanded ? 'Collapse' : 'Expand'}
            ariaLabel={props.isExpanded ? 'Collapse' : 'Expand'}
            onClick={(): void => {
                if (props.isExpanded) {
                    props.onCollapse();
                } else {
                    props.onExpand();
                }
            }}
        />
    );
}
