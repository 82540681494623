import React, { useState } from 'react';
import {
    ActionButton,
    IRenderFunction,
    ITextFieldProps,
    mergeStyleSets,
} from '@fluentui/react';
import { MissionMentorDialog } from '../MissionMentorDialog';
import { useThemes } from '../../hooks/useThemes';

export function MissionMentorLabel(props: {
    textFieldProps: ITextFieldProps | undefined;
    defaultRender: IRenderFunction<ITextFieldProps> | undefined;
    onPromptSelected: (userPromptKey?: string) => void;
    content: JSX.Element | undefined;
    actions?: { name: string; action: () => void }[];
}): JSX.Element {
    const { buttonStyles } = useThemes();

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'end',
        },
    });

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const openDialog = () => setIsDialogOpen(true);
    const dismissDialog = () => setIsDialogOpen(false);

    return (
        <>
            <div className={classNames.container}>
                <div>
                    {!!props.defaultRender &&
                        props.defaultRender(props.textFieldProps)}
                </div>
                <div>
                    <ActionButton
                        text="Ask MAI"
                        iconProps={{ iconName: 'Feedback' }}
                        styles={buttonStyles.mai}
                        onClick={openDialog}
                    />
                </div>
            </div>
            <MissionMentorDialog
                hidden={!isDialogOpen}
                onDismiss={dismissDialog}
                onPromptSelected={props.onPromptSelected}
                content={props.content}
                actions={props.actions}
            />
        </>
    );
}
