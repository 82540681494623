import React from 'react';
import {
    PersonaCoin,
    PersonaSize,
    Shimmer,
    Text,
    mergeStyleSets,
} from '@fluentui/react';
import { useGetMeasureValueFormulaQuery } from '../../../data/types';
import { useThemes } from '../../../hooks/useThemes';
import { useStateContext } from '../../../services/contextProvider';
import { photoService } from '../../../services/photo.service';

type Props = {
    isShown?: boolean;
    measureId: string | null | undefined;
};

// type Item = {
//     id: string;
//     imageUrl?: string;
//     userName?: string;
//     value?: string;
//     missionTitle?: string;
//     measureName?: string;
//     isTotal?: boolean;
// };

function MeasureCalcTooltipContent(props: Props): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { currentTheme } = useThemes();

    // const measureFormatter = useMeasureValueFormatter(props.formatOptions);

    const { data, loading } = useGetMeasureValueFormulaQuery({
        skip: !currentTenantId || !props.measureId || !props.isShown,
        variables: {
            tenantId: currentTenantId || '',
            measureId: props.measureId || '',
        },
    });

    const measureList = (data?.measureValueFormula?.measures || []).map(
        (measure) => {
            const formattedValue = '';

            // if (
            //     props.showValues &&
            //     props.seriesTypeName &&
            //     props.asOf?.asOfDate
            // ) {
            //     const asOf = measure.valueHistory?.find(
            //         (a) => a.asOfDate === props.asOf?.asOfDate
            //     );

            //     const series = asOf?.values?.find(
            //         (v) => v.seriesType?.name === props.seriesTypeName
            //     );

            //     if (series?.decimalValue) {
            //         formattedValue =
            //             measureFormatter.formatDecimalValue(
            //                 series?.decimalValue
            //             ) ?? '';
            //     }
            // }

            return {
                id: measure.id || '',
                userName: measure.mission?.owner || '',
                imageUrl: photoService.getImageUrl(measure.mission?.userId),
                missionTitle: measure.mission?.title || '',
                measureName: measure.name || '',
                value: formattedValue,
            };
        }
    );

    const classNames = mergeStyleSets({
        container: {
            padding: 8,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        title: {
            paddingBottom: 0,
            marginBottom: 0,
        },
        underline: {
            paddingBottom: 8,
            borderBottom: `solid 1px ${currentTheme.palette.neutralLight}`,
            marginBottom: 8,
        },
        overline: {
            paddingTop: 8,
            borderTop: `solid 1px ${currentTheme.palette.neutralLight}`,
            marginBottom: 8,
        },
        sectionHeader: {
            paddingTop: 14,
        },
        measureContainer: {
            display: 'flex',
            gap: 8,
            flexDirection: 'row',
            paddingBottom: 8,
            borderBottom: `solid 1px ${currentTheme.palette.neutralLight}`,
            marginBottom: 8,
        },
        sourceText: {
            width: 250,
        },
        measureText: {
            fontWeight: 600,
        },
        valueText: {
            minWidth: 100,
            textAlign: 'right',
        },
        total: {
            display: 'flex',
            gap: 8,
            flexDirection: 'row',
        },
        step: {
            display: 'flex',
            gap: 8,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    });

    return (
        <div className={classNames.container}>
            <Text variant="mediumPlus" className={classNames.title}>
                Calculated Value
            </Text>

            <Shimmer isDataLoaded={!loading}>
                {/* Formula Formatted */}
                <div
                    className={`${classNames.sectionHeader} ${classNames.underline}`}
                    style={{ paddingTop: 6 }}
                >
                    <div>
                        <Text
                            block
                            variant="smallPlus"
                            className={`${classNames.measureText}`}
                        >
                            Formula
                        </Text>
                    </div>
                </div>

                <div>
                    {data?.measureValueFormula?.formulaPP
                        ?.split('\n')
                        .map((line, index) => (
                            <Text key={`line-${index}`} variant="small" block>
                                {line}
                            </Text>
                        ))}
                </div>

                {measureList && measureList.length > 0 && (
                    <div
                        className={`${classNames.sectionHeader} ${classNames.underline}`}
                    >
                        <div>
                            <Text
                                block
                                variant="smallPlus"
                                className={`${classNames.measureText}`}
                            >
                                Measures
                            </Text>
                        </div>
                    </div>
                )}

                {/* Measure Lookups from Initial Parsing */}
                {measureList.map((m) => {
                    return (
                        <div className={classNames.measureContainer} key={m.id}>
                            <div>
                                <PersonaCoin
                                    imageUrl={m.imageUrl}
                                    size={PersonaSize.size32}
                                    text={m.userName}
                                />
                            </div>
                            <div className={classNames.sourceText}>
                                <Text
                                    block
                                    variant="smallPlus"
                                    className={classNames.measureText}
                                >
                                    {m.measureName}
                                </Text>
                                <Text block variant="tiny">
                                    {m.userName} - {m.missionTitle}
                                </Text>
                            </div>
                            {/* {props.showValues && (
                            <div className={classNames.valueText}>
                                <Text block variant="small">
                                    {m.value}
                                </Text>
                            </div>
                        )} */}
                        </div>
                    );
                })}
            </Shimmer>
        </div>
    );
}

export default MeasureCalcTooltipContent;
