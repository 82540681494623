import React, { PropsWithChildren } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import StatusReportFactCard, {
    StatusReportFactCardProps,
} from './StatusReportFactCard';
import { DragHandlerButton } from '../../../components/DragHandlerButton';

const DragHandlerMemo = React.memo(DragHandlerButton);

export function StatusReportSortableItemContainer(
    props: PropsWithChildren<StatusReportFactCardProps & { isActive: boolean }>
): JSX.Element {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: props.fact.id || '' });

    return (
        <div
            ref={setNodeRef}
            style={{
                transformOrigin: '0 0',
                opacity: props.isActive ? 0.4 : 1,
                transform: CSS.Translate.toString(transform),
                transition: transition || undefined,
            }}
        >
            <StatusReportFactCard
                {...props}
                dragHandler={
                    <DragHandlerMemo
                        hidden={!props.missionAccess.write}
                        handleListeners={listeners}
                        handleAttributes={attributes}
                    />
                }
            />
        </div>
    );
}
