import { EditorView } from 'prosemirror-view';
import { Plugin } from 'prosemirror-state';

export default function PlaceholderPlugin(text: string) {
    const update = (view: EditorView) => {
        if (
            view.state.doc.textContent ||
            view.state.doc.firstChild?.content.size
        ) {
            view.dom.removeAttribute('data-placeholder');
        } else {
            view.dom.setAttribute('data-placeholder', text);
        }
    };

    return new Plugin({
        view(view) {
            update(view);

            return { update };
        },
    });
}
