import React, { useCallback, useEffect, useState } from 'react';
import {
    IRawStyle,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PanelType,
    Stack,
} from '@fluentui/react';
import { GetTaskQuery, TagTypes, useGetTaskQuery } from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { ExtractQueryArrayType, TaskStatusNames } from '../data/extendedTypes';
import { useClipboard } from 'use-clipboard-copy';
import EditTaskPanelSection from './TaskPanelEditSection';
import { useIsTaskImplied } from '../hooks/useIsTaskImplied';
import { ResourcePickerResource } from './ResourcePicker';
import { EditPanel } from './shared/EditPanel';
import { TaskCommandBar } from './TaskCommandBar';
import { useTaskUpdater } from '../hooks/useTaskUpdater';
import { ResourcedFromTooltipContent } from './ResourcedFromTooltipContent';
import TaskHistoryFeed from './TaskHistoryFeed';
import { TaskOverviewMap } from './TaskOverviewMap';
import { useTaskStatus } from '../hooks/useTaskStatus';
import { TaskMap } from './TaskMap';
import { ChecklistTask } from './TaskChecklist';
import { Chip } from './Chip';
import { useLanguage } from '../services/i18n';
import { useThemes } from '../hooks/useThemes';
import { ActiveViews } from '../hooks/useActiveView';

export enum TaskPanelSections {
    Edit = 'Edit',
    Alignment = 'Alignment',
    Map = 'Map',
    History = 'History',
    Comments = 'Comments',
    Attachments = 'Attachments',
}

export default function TaskPanel(props: {
    showPanel: boolean;
    sectionHint: TaskPanelSections;
    taskId: string | null | undefined;
    newImpliedSpecifiedTaskId: string | null | undefined; // If this is a new task, use this a the specified ID
    missionId: string | null | undefined;
    onDismiss: () => void;
    onCommentsButtonClick?: () => void;
    onAttachmentsButtonClick: () => void;
    onSwapTask: (taskId: string | null) => void;
    hideDelete?: boolean;
}): JSX.Element {
    const {
        currentTenantId,
        currentTenantCode,
        currentFinancialYearCode,
        currentTeamCode,
        currentUserId,
    } = useStateContext();

    const { currentTheme } = useThemes();

    const { t } = useLanguage();

    const [inputTask, setInputTask] = useState<ExtractQueryArrayType<
        GetTaskQuery,
        ['task']
    > | null>(null);
    const [inputResources, setInputResources] = useState<
        ResourcePickerResource[]
    >([]);

    const [inputChecklistTasks, setInputChecklistTasks] = useState<
        ChecklistTask[]
    >([]);

    const [isValid, setIsValid] = useState(false);

    const clipboard = useClipboard({
        copiedTimeout: 3000, // show copy message in milliseconds
    });

    const [section, setSection] = useState(TaskPanelSections.Edit);

    const { data, loading } = useGetTaskQuery({
        skip: !props.taskId || !currentTenantId,
        fetchPolicy: 'cache-and-network',
        variables: {
            id: props.taskId || '',
            tenantId: currentTenantId || '',
        },
        onCompleted: (d) => {
            if (props.showPanel) {
                // If this task does not have its own mission is still belongs to the parent.
                const owningMission =
                    d.task?.mission ?? d.task?.resourcedFromTask?.mission;

                const isCurrentUserTask =
                    d?.task?.resource?.userId &&
                    d?.task?.resource?.userId === currentUserId;

                if (!owningMission?.rights.write && !isCurrentUserTask) {
                    setSection(TaskPanelSections.Alignment);
                }
            }
        },
    });

    const task = data?.task || null;

    const taskStatus = useTaskStatus(task || undefined);

    const isDataImpliedTask = useIsTaskImplied(task);

    const isImpliedTask = props.taskId
        ? isDataImpliedTask
        : !!props.newImpliedSpecifiedTaskId;

    const { hasSaved, updateTaskAsync, isSaving, saveError, reset } =
        useTaskUpdater(
            currentTenantId,
            currentTenantCode,
            currentFinancialYearCode,
            currentTeamCode,
            inputTask,
            inputResources,
            inputChecklistTasks
        );

    useEffect(() => {
        if (props.showPanel && !props.taskId) {
            setSection(TaskPanelSections.Edit);
        } else {
            setSection(props.sectionHint);
        }
        reset();
    }, [props.showPanel, props.taskId, props.sectionHint, reset]);

    const handleCopyTaskIdButtonClick = () => clipboard.copy(task?.id || '');
    const handleSectionChange = (s: TaskPanelSections) => setSection(s);

    const commandBar =
        task && taskStatus ? (
            <TaskCommandBar
                section={section}
                onSectionChange={handleSectionChange}
                onCopyTaskIdButtonClick={handleCopyTaskIdButtonClick}
                onCloseButtonClick={props.onDismiss}
                onCommentsButtonClick={props.onCommentsButtonClick}
                onAttachmentsButtonClick={props.onAttachmentsButtonClick}
                task={task}
                taskStatus={taskStatus}
                hideDelete={
                    task.missionId !== props.missionId || !!props.hideDelete
                }
            />
        ) : undefined;

    const taskCategory = task?.parentTask?.taskCategory ?? task?.taskCategory;

    const handleTaskInputChanged = useCallback(
        (
            updatedTask: ExtractQueryArrayType<GetTaskQuery, ['task']>,
            selectedResources: ResourcePickerResource[],
            checklistTasks: ChecklistTask[],
            isValid: boolean
        ) => {
            setInputTask(updatedTask);
            setInputResources(selectedResources);
            setInputChecklistTasks(checklistTasks);
            setIsValid(isValid);
        },
        []
    );

    const handleUpdateClick =
        section === TaskPanelSections.Edit
            ? async () => {
                  if (isValid) {
                      await updateTaskAsync();
                      props.onDismiss();
                  }
              }
            : undefined;

    let headerText: string;

    if (!props.taskId && !props.newImpliedSpecifiedTaskId) {
        headerText = t('specified-task.specified-task_one');
    } else if (!props.taskId) {
        headerText = t('implied-task.implied-task_one');
    } else if (loading || !task) {
        headerText = 'Loading...';
    } else if (
        task.resource &&
        !task.resource.userMissionFYs.find(
            (fy) =>
                fy.code?.toUpperCase() ===
                currentFinancialYearCode?.toUpperCase()
        )
    ) {
        headerText = 'Resourced Task';
    } else if (taskStatus?.name === TaskStatusNames.Pending) {
        headerText = taskStatus.text;
    } else if (isImpliedTask) {
        headerText = t('implied-task.implied-task_one');
    } else {
        headerText = t('specified-task.specified-task_one');
    }

    const infoTooltipContent =
        section === TaskPanelSections.Edit && task && task.resourcedFromTask ? (
            <ResourcedFromTooltipContent task={task} />
        ) : undefined;

    const sectionContainerStyle: IRawStyle = {
        border: `solid 1px ${currentTheme.palette.neutralLight}`,
        padding: 8,
    };

    const classNames = mergeStyleSets({
        sections: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        sectionContainer: sectionContainerStyle,
        historySectionContainer: {
            ...sectionContainerStyle,
            backgroundColor: currentTheme.palette.white,
            padding: 0,
        },
    });

    let activeViewName: keyof typeof ActiveViews | null;

    switch (section) {
        case TaskPanelSections.Edit:
            activeViewName = 'TaskEdit';
            break;
        case TaskPanelSections.Alignment:
            activeViewName = 'TaskAlignment';
            break;
        case TaskPanelSections.Map:
            activeViewName = 'TaskMap';
            break;
        case TaskPanelSections.History:
            activeViewName = 'TaskHistory';
            break;
        default:
            activeViewName = null;
    }

    return (
        <EditPanel
            activeViewName={activeViewName}
            showPanel={props.showPanel}
            commandBar={commandBar}
            panelType={
                section === TaskPanelSections.Map
                    ? PanelType.large
                    : PanelType.medium
            }
            onDismiss={props.onDismiss}
            headerText={headerText}
            isValid={isValid}
            isSaving={isSaving}
            saveErrorMessage={saveError?.message}
            hasSaved={hasSaved}
            onUpdateClick={handleUpdateClick}
            infoTooltipContent={infoTooltipContent}
            forceScrollbar
        >
            <Stack tokens={{ childrenGap: 8 }} style={{ marginTop: 4 }}>
                {taskCategory && (
                    <Stack.Item align="start">
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <Chip
                                text={taskCategory.name}
                                iconName="TagSolid"
                                backgroundColor={`#${taskCategory.colourHex}`}
                            />

                            {task?.tags
                                .filter((t) => t.tagType === TagTypes.UserTag)
                                .map((t) => (
                                    <Chip
                                        key={t.id}
                                        text={t.name}
                                        iconName={t.icon || undefined}
                                        backgroundColor={`#${t.colourHex}`}
                                    />
                                ))}
                        </Stack>
                    </Stack.Item>
                )}

                {task?.isDuplicate && !!task.parentTask && (
                    <MessageBar
                        messageBarType={MessageBarType.info}
                        isMultiline
                    >
                        This task has been merged into the task &ldquo;
                        {task.parentTask.name}&rdquo;.
                    </MessageBar>
                )}

                {section === TaskPanelSections.Alignment && props.taskId && (
                    <div className={classNames.sections}>
                        <TaskOverviewMap task={task} />
                    </div>
                )}

                {section === TaskPanelSections.History && props.taskId && (
                    <div className={classNames.sections}>
                        <div className={classNames.historySectionContainer}>
                            <TaskHistoryFeed taskId={props.taskId} />
                        </div>
                    </div>
                )}

                {section === TaskPanelSections.Map && props.taskId && (
                    <TaskMap taskId={props.taskId} />
                )}

                {section === TaskPanelSections.Edit && (
                    <EditTaskPanelSection
                        missionId={props.missionId}
                        taskId={props.taskId}
                        onDismiss={props.onDismiss}
                        isSaving={isSaving}
                        hasSaved={hasSaved}
                        onTaskInputChanged={handleTaskInputChanged}
                        onSwapTask={props.onSwapTask}
                        newImpliedSpecifiedTaskId={
                            props.newImpliedSpecifiedTaskId
                        }
                    />
                )}
            </Stack>
        </EditPanel>
    );
}
