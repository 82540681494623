import React, { useCallback, useEffect, useState } from 'react';
import {
    ActionButton,
    ContextualMenu,
    DefaultButton,
    Dialog,
    DialogType,
    FontSizes,
    MessageBar,
    Persona,
    PersonaSize,
    Text,
    ZIndexes,
    mergeStyleSets,
} from '@fluentui/react';
import { useViewport } from '../hooks/useViewport';

import mai from '../images/mai.png';
import { photoService } from '../services/photo.service';
import { useStateContext } from '../services/contextProvider';
import { ChatBubble } from './mai/ChatBubble';

export function MissionMentorDialog(props: {
    hidden: boolean;
    onDismiss: () => void;
    userPrompts?: { key: string; text: string }[];
    content?: JSX.Element;
    onPromptSelected: (userPromptKey?: string) => void;
    actions?: { name: string; action: () => void }[];
    isStreaming?: boolean;
}) {
    const { content, actions, userPrompts, onPromptSelected, hidden } = props;

    const { currentUserId, currentUserDisplayName } = useStateContext();

    const [isGenerating, setIsGenerating] = useState(false);

    const [selectedUserPromptKey, setSelectedUserPromptKey] =
        useState<string>();

    const { width } = useViewport();

    const isMobile = width < 620;

    const fetchData = useCallback(
        async (userPromptKey?: string) => {
            if (!userPrompts?.length || userPromptKey) {
                setIsGenerating(true);
                await onPromptSelected(userPromptKey);
                setIsGenerating(false);
            }
        },
        [userPrompts, onPromptSelected]
    );

    useEffect(() => {
        if (!hidden) {
            setSelectedUserPromptKey(undefined);
            fetchData();
        }
    }, [hidden, fetchData]);

    const classNames = mergeStyleSets({
        titleContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        contentContainer: {
            maxHeight: 'min(50vw, 400px)',
            paddingRight: 20,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse', // reverses content, allows scrolling down
            scrollBehavior: 'smooth',
            '& H1': {
                fontSize: FontSizes.large,
                margin: 0,
            },
            '& H2': {
                fontSize: FontSizes.mediumPlus,
                margin: 0,
            },
        },
    });

    return (
        <Dialog
            {...props}
            dialogContentProps={{
                type: DialogType.largeHeader,
                styles: {
                    title: {
                        paddingRight: 24,
                    },
                },
                title: (
                    <div className={classNames.titleContainer}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                                alignItems: 'center',
                            }}
                        >
                            <span>Ask MAI</span>
                            <sup
                                style={{
                                    alignSelf: 'start',
                                    fontSize: '0.6em',
                                }}
                            >
                                BETA
                            </sup>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 4,
                            }}
                        >
                            {!!content && !props.isStreaming && (
                                <ActionButton
                                    text="Regenerate"
                                    onClick={() =>
                                        fetchData(selectedUserPromptKey)
                                    }
                                    iconProps={{
                                        iconName: 'Sync',
                                    }}
                                />
                            )}

                            <ActionButton
                                onClick={props.onDismiss}
                                iconProps={{
                                    iconName: 'Cancel',
                                }}
                            />
                        </div>
                    </div>
                ),
                closeButtonAriaLabel: 'Close',
            }}
            modalProps={{
                isBlocking: false,
                dragOptions: {
                    moveMenuItemText: 'Move',
                    closeMenuItemText: 'Close',
                    menu: ContextualMenu,
                    keepInBounds: true,
                },
                styles: {
                    main: !isMobile
                        ? {
                              maxWidth: '800px !important',
                              minWidth: '500px !important',
                          }
                        : undefined,
                    layer: {
                        zIndex: ZIndexes.Layer + 1, // Keep this modal on top of any panels, etc
                    },
                },
                isModeless: true,
                forceFocusInsideTrap: false, // Required, otherwise the context menus behind the modal won't open.
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                }}
            >
                <div className={classNames.contentContainer}>
                    {(isGenerating || !!content) && (
                        <ChatBubble
                            isGenerating={isGenerating}
                            content={content}
                            tailPosition="topLeft"
                            persona={
                                <Persona
                                    imageUrl={mai}
                                    size={PersonaSize.size32}
                                    hidePersonaDetails
                                />
                            }
                        />
                    )}

                    {selectedUserPromptKey && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'end',
                                paddingBottom: 8,
                            }}
                        >
                            <ChatBubble
                                content={
                                    <div style={{ padding: 10 }}>
                                        {
                                            props.userPrompts?.find(
                                                (up) =>
                                                    up.key ===
                                                    selectedUserPromptKey
                                            )?.text
                                        }
                                    </div>
                                }
                                tailPosition="topRight"
                                persona={
                                    <Persona
                                        imageUrl={photoService.getImageUrl(
                                            currentUserId
                                        )}
                                        text={currentUserDisplayName}
                                        size={PersonaSize.size32}
                                        hidePersonaDetails
                                    />
                                }
                            />
                        </div>
                    )}
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        gap: 8,
                    }}
                >
                    {actions?.map((a) => {
                        return (
                            <DefaultButton
                                key={a.name}
                                text={a.name}
                                onClick={() => {
                                    a.action();
                                    props.onDismiss();
                                }}
                            />
                        );
                    })}
                </div>

                {!selectedUserPromptKey && props.userPrompts?.length && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 8,
                            justifyContent: 'end',
                        }}
                    >
                        {props.userPrompts?.map((up) => {
                            return (
                                <ChatBubble
                                    key={up.key}
                                    content={
                                        <div style={{ padding: 10 }}>
                                            {up.text}
                                        </div>
                                    }
                                    tailPosition="bottomRight"
                                    onClick={() => {
                                        setSelectedUserPromptKey(up.key);
                                        fetchData(up.key);
                                    }}
                                />
                            );
                        })}
                    </div>
                )}

                <MessageBar
                    styles={{
                        root: {
                            marginTop: 16,
                        },
                    }}
                >
                    <Text variant="tiny">
                        The content and suggestions provided by MAI are
                        generated through artificial intelligence algorithms.
                        While we strive for accuracy, the recommendations may
                        not be suitable for all situations and may contain
                        inaccuracies or errors. Please use your discretion and
                        seek human consultation when making critical decisions
                        based on this content.
                    </Text>
                </MessageBar>
            </div>
        </Dialog>
    );
}
