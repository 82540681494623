import { useMemo, useState } from 'react';
import groupBy from 'lodash/groupBy';
import { Access } from '../../../data/extendedTypes';
import { ArrowDirection, Arrows, GetMissionQuery } from '../../../data/types';
import { useThemes } from '../../../hooks/useThemes';
import { useLanguage } from '../../../services/i18n';
import { useLastYear } from '../../../hooks/useLastYear';
import { MeasureStatusColours } from '../../../Colours';
import { AreaContainer, AreaContainerButton } from './AreaContainer';
import HeaderSummaryBar from './HeaderSummaryBar';
import MeasureList from './MeasureList';
import MeasureAddLinkedPanel from './MeasureAddLinkedPanel';
import MeasureCopyFromLastYearPanel from './MeasureCopyFromLastYearPanel';
import { useViewModeDropDownButton } from '../../../hooks/useViewModeDropDownButton';

type MeasureViewModes = 'Tile' | 'Compact' | 'List';
const defaultMeasureView = 'Tile';
const measureViewModes: {
    [key in MeasureViewModes]: {
        text: string;
        iconName: string;
    };
} = {
    ['Tile']: {
        text: 'Tile',
        iconName: 'GridViewLarge',
    },
    ['Compact']: {
        text: 'Compact',
        iconName: 'TransitionPush',
    },
    ['List']: {
        text: 'List',
        iconName: 'ViewList',
    },
};

export function MissionBuilderMeasureArea(props: {
    missionId: string | undefined;
    missionUserId: string | null | undefined;
    oneUpMissionId: string | null | undefined;
    fyStartDate: string | undefined;
    fyEndDate: string | undefined;
    missionAccess: Access;
    measureSummary: GetMissionQuery['measureSummary'];
    measureGroups: GetMissionQuery['measureGroups'];
    onMeasureGroupingClick: () => void;
    onAddMeasureButtonClick: () => void;
    onMeasureCardClick: (measureId: string) => void;
    onAddNewMeasureButtonClick: (
        measureGroupId: string | null | undefined
    ) => void;
    onMeasureCountChanged: (count: number) => void;
    onEditMeasureActualClick: (measureId: string) => void;
    onAttachmentsClick: (measureId: string) => void;
    onCommentsClick: (measureId: string) => void;
    onMeasureLinkClick: (measureId: string) => void;
}) {
    const {
        fyStartDate,
        fyEndDate,
        missionAccess,
        missionId,
        missionUserId,
        oneUpMissionId,
        measureSummary,
        measureGroups,
        onMeasureGroupingClick,
        onAddMeasureButtonClick,
        onMeasureCardClick,
        onAddNewMeasureButtonClick,
        onMeasureCountChanged,
        onEditMeasureActualClick,
        onAttachmentsClick,
        onCommentsClick,
        onMeasureLinkClick,
    } = props;

    const { currentTheme } = useThemes();
    const { t } = useLanguage();

    const [showMeasureAddLinkedPanel, setShowMeasureAddLinkedPanel] =
        useState(false);

    const [
        showMeasureCopyFromLastYearPanel,
        setShowMeasureCopyFromLastYearPanel,
    ] = useState(false);

    const measureArrows = groupBy(measureSummary, function (m) {
        return m.lastAsOf?.arrowColour;
    });

    const measureDirections = groupBy(measureSummary, function (m) {
        return m.lastAsOf?.arrowDirection;
    });

    const { missionId: lastYearsMissionId } = useLastYear(missionUserId);

    const { button: measureViewModeButton, activeViewMode: measureViewMode } =
        useViewModeDropDownButton(
            'ADVANCE_MISSIONBUILDER_MEASUREVIEW',
            measureViewModes,
            defaultMeasureView
        );

    const measureSummaryItems = [
        {
            count: measureArrows[Arrows.Green]?.length || 0,
            text: 'ON TARGET',
            iconName: 'SquareShapeSolid',
            iconColour: MeasureStatusColours.onTarget,
        },
        {
            count: measureArrows[Arrows.Yellow]?.length || 0,
            text: 'NEAR TARGET',
            iconName: 'SquareShapeSolid',
            iconColour: MeasureStatusColours.nearTarget,
        },
        {
            count: measureArrows[Arrows.Red]?.length || 0,
            text: 'OFF TARGET',
            iconName: 'SquareShapeSolid',
            iconColour: MeasureStatusColours.offTarget,
        },
        {
            count: measureDirections[ArrowDirection.Up]?.length || 0,
            text: 'UP',
            iconName: 'StockUp',
            iconColour: currentTheme.palette.themePrimary,
        },
        {
            count: measureDirections[ArrowDirection.Down]?.length || 0,
            text: 'Down',
            iconName: 'StockDown',
            iconColour: currentTheme.palette.themePrimary,
        },
    ];

    const measureAreaButtons: AreaContainerButton[] = useMemo(() => {
        const measureButtons: AreaContainerButton[] = [measureViewModeButton];

        if (missionAccess.write) {
            measureButtons.push({
                key: 'Grouping',
                text: 'Grouping',
                iconProps: {
                    iconName: 'FabricFolder',
                },
                onClick: onMeasureGroupingClick,
                hideOnCollapse: true,
            });

            const items = [
                {
                    key: 'new-measure',
                    text: 'Create new',
                    onClick: onAddMeasureButtonClick,
                },
                {
                    key: 'find-measure',
                    text: 'Link from...',
                    onClick: () => setShowMeasureAddLinkedPanel(true),
                },
            ];

            if (lastYearsMissionId) {
                items.push({
                    key: 'copy-measures',
                    text: 'Copy from last year...',
                    onClick: () => setShowMeasureCopyFromLastYearPanel(true),
                });
            }

            measureButtons.push({
                key: 'AddMeasure',
                text: t('measure-of-success.measure-of-success', {
                    count: 1,
                }),
                subMenuProps: {
                    items: items,
                },
                iconProps: {
                    iconName: 'Add',
                },
                hideOnCollapse: true,
                engagementTargetKey: 'CreateMeasureButton',
            });
        }

        return measureButtons;
    }, [
        onMeasureGroupingClick,
        onAddMeasureButtonClick,
        lastYearsMissionId,
        measureViewModeButton,
        missionAccess.write,
        t,
    ]);

    return (
        <>
            <AreaContainer
                title={t('measure-of-success.measure-of-success', {
                    count: 10,
                })}
                isCollapsable={true}
                subTitle={<HeaderSummaryBar items={measureSummaryItems} />}
                commandBarButtons={measureAreaButtons}
            >
                <MeasureList
                    measureSummary={measureSummary}
                    measureGroups={measureGroups}
                    missionId={missionId}
                    missionAccess={missionAccess}
                    onMeasureCardClick={onMeasureCardClick}
                    onEditMeasureActualClick={onEditMeasureActualClick}
                    onAttachmentsClick={onAttachmentsClick}
                    onCommentsClick={onCommentsClick}
                    onLinkClick={onMeasureLinkClick}
                    onMeasureCountChanged={onMeasureCountChanged}
                    viewMode={measureViewMode}
                    onAddNewMeasureButtonClick={onAddNewMeasureButtonClick}
                    fyStartDate={fyStartDate}
                    fyEndDate={fyEndDate}
                />
            </AreaContainer>

            <MeasureAddLinkedPanel
                missionId={missionId || ''}
                oneUpMissionId={oneUpMissionId || null}
                showPanel={showMeasureAddLinkedPanel}
                onCancel={() => {
                    setShowMeasureAddLinkedPanel(false);
                }}
                fyStartDate={fyStartDate}
                fyEndDate={fyEndDate}
            />

            <MeasureCopyFromLastYearPanel
                missionId={missionId || ''}
                showPanel={showMeasureCopyFromLastYearPanel}
                onCancel={() => {
                    setShowMeasureCopyFromLastYearPanel(false);
                }}
            />
        </>
    );
}
