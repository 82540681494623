import React, { useCallback, useEffect, useState } from 'react';
import { Icon, Label, Text } from '@fluentui/react';
import {
    SortableTextInputItem,
    SortableTextInputList,
} from '../../../components/SortableTextInputList';
import { Guid } from 'guid-typescript';

type ActionItem = {
    id: string | null;
    name: string | null;
    due: string | null;
    done: string | null;
    sequence: number;
    version: string | null;
};

type StatusReportFactActionsProps = {
    isReadOnly: boolean;
    isLoading: boolean;
    actions: ActionItem[];
    onChanged: (newActions: ActionItem[]) => void;
};

export function StatusReportFactActions(
    props: StatusReportFactActionsProps
): JSX.Element {
    const { actions, onChanged } = props;

    const [inputActions, setInputActions] = useState<SortableTextInputItem[]>(
        []
    );

    useEffect(() => {
        setInputActions(
            actions.map((a) => ({
                ...a,
                id: a.id || Guid.create().toString(),
                name: a.name || '',
                isNew: false,
                isDeleted: false,
            }))
        );
    }, [actions]);

    const handleInputListChanged = useCallback(
        (updatedListItems: SortableTextInputItem[]) => {
            setInputActions(updatedListItems);

            const originalActionsMap = new Map<string, ActionItem>();
            for (const a of actions) {
                originalActionsMap.set(a.id || '', a);
            }

            const newActions: ActionItem[] = updatedListItems
                // Filter out any items that are marked deleted
                .filter((item) => !item.isDeleted)
                .map((item) => {
                    const original = originalActionsMap.get(item.id) || {
                        id: item.id,
                        name: null,
                        due: null,
                        done: null,
                        sequence: item.sequence,
                        version: null,
                    };
                    return {
                        ...original,
                        name: item.name,
                        sequence: item.sequence,
                    };
                });

            onChanged(newActions);
        },
        [actions, onChanged]
    );

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 4,
                    alignItems: 'center',
                }}
            >
                <Icon iconName="DoubleChevronRight8" />
                <Label>Action</Label>
            </div>
            {!inputActions.length && props.isReadOnly && !props.isLoading && (
                <Text variant="tiny" style={{ fontStyle: 'italic' }}>
                    No action items
                </Text>
            )}
            <SortableTextInputList
                inputItems={inputActions}
                onChange={handleInputListChanged}
                isLoading={props.isLoading}
                isReadOnly={props.isReadOnly}
                addButtonText="Add Action Item"
                multiline
                textFieldStyles={{
                    fieldGroup: {
                        border: 'none',
                    },
                }}
                placeholder="Does our analysis require any actions? If so, what are they?"
                deleteMode="popup"
            />
        </div>
    );
}
