import { useMemo } from 'react';
import { useThemes } from '../../../hooks/useThemes';
import { useStateContext } from '../../../services/contextProvider';
import { Shade, getColorFromString, getShade } from '@fluentui/react';

import contours from '../../../images/contours.jpg';

export function usePresentationColours(): {
    highlightColour: string;
    highlightColourLight: string;
    highlightColourTransparent: string;
    highlightTextColour: string;
    backgroundImage: string | undefined;
    backgroundColour: string | undefined;
    titleBackgroundColour: string | undefined;
} {
    const { lightTheme, getContrastColourHex } = useThemes();
    const { themePrimaryColourHex } = useStateContext();

    const defaultHighlightColor = '#ffbd59';

    const highlightColour = themePrimaryColourHex
        ? `#${themePrimaryColourHex}`
        : defaultHighlightColor;

    const highlightTextColour = useMemo(() => {
        return getContrastColourHex(highlightColour, [
            '#fff',
            '#363636', // GP Charcoal
        ]);
    }, [highlightColour, getContrastColourHex]);

    const highlightColourC = getColorFromString(highlightColour);

    const highlightColourLight = highlightColourC
        ? getShade(highlightColourC, Shade.Shade1)
        : null;

    return {
        highlightColour,
        highlightColourLight: highlightColourLight?.str || '#eee',
        highlightColourTransparent: `${highlightColour}10`,
        highlightTextColour,
        backgroundImage: !themePrimaryColourHex ? contours : undefined,
        backgroundColour: themePrimaryColourHex
            ? lightTheme.palette.white
            : lightTheme.palette.neutralDark,
        titleBackgroundColour: themePrimaryColourHex
            ? lightTheme.palette.themeDarker
            : lightTheme.palette.neutralDark,
    };
}
