import React, {
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { mergeStyleSets, Stack } from '@fluentui/react';

import { useStateContext } from '../../../services/contextProvider';

import {
    GetMissionTasksQuery,
    useGetMissionLazyQuery,
    useGetMissionTasksQuery,
    useUpdateTaskSequenceMutation,
} from '../../../data/types';
import { Access } from '../../../data/extendedTypes';

import NewTaskCard from '../../../components/NewTaskCard';
import TaskCard, { TaskCardProps } from '../../../components/TaskCard';
import TaskNotifications from '../../../components/TaskNotifications';
import Loading from '../../../components/Loading';
import { gql, NetworkStatus, useApolloClient } from '@apollo/client';
import { sorters } from '../../../data/sorters';
import { SortableContainer } from '../../../components/SortableContainer';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandlerButtonMemo } from '../../../components/DragHandlerButton';
import { DragOverlay } from '@dnd-kit/core';
import {
    DefaultTaskFilters,
    TaskFilterBar,
    TaskFilters,
} from './TaskFilterBar';
import { TaskFlatList } from '../../../components/TaskFlatList';
import { AdvanceCard } from '../../../components/AdvanceCard';
import dayjs from 'dayjs';
import { TaskTileColumn } from '../../../hooks/useColumnPreferences';

export type TaskSummaryItem = {
    id: string | null;
    parentTaskId: string | null;
    name: string | null;
    sequence: number;
    isDuplicate: boolean;
};

export type TaskListProps = {
    divisionId: string | null;
    missionId: string;
    missionUserId: string | null;
    missionOwner: string | null;
    financialYearCode: string;
    showAdd: boolean;
    tasksSummary: TaskSummaryItem[];
    onAddSpecifiedButtonClick: () => void;
    onAddImpliedButtonClick: (specifiedTaskId: string) => void;
    onTaskClick: (taskId: string) => void;
    onTaskEditClick: (taskId: string) => void;
    onCommentsClick: (taskId: string) => void;
    onAttachmentsClick: (taskId: string) => void;
    onRejectedTaskClick: (rejectedTaskId: string) => void;
    missionAccess: Access;
    scrollToSpecifiedTaskId?: string | null | undefined;
    highlightImpliedTaskId?: string | null | undefined;
    showFilter: boolean;
    onFiltersDismiss: () => void;
    onFiltersActiveChanged: (isActive: boolean) => void;
    viewMode: 'Tile' | 'Gantt' | 'List';
    onTaskNotificationCountChanged?: (count: number) => void;
    tileColumnNames: TaskTileColumn[];
};

function TaskList(props: TaskListProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const client = useApolloClient();

    const { data, loading, refetch, networkStatus } = useGetMissionTasksQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId,
        },
    });

    const [refetchMissionQuery, { loading: refetchingMission }] =
        useGetMissionLazyQuery({
            fetchPolicy: 'network-only',
        });

    const refs = data?.tasks
        ?.filter((t) => t.parentTaskId === null)
        .reduce<Record<string, RefObject<HTMLDivElement>>>((acc, value) => {
            if (value.id) {
                acc[value.id] = React.createRef();
            }
            return acc;
        }, {});

    const [filters, setFilters] = useState<TaskFilters>(DefaultTaskFilters);

    const scrollToTask = useCallback(
        (id: string) => {
            if (refs) {
                refs[id as string]?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        },
        [refs]
    );

    useEffect(() => {
        if (props.scrollToSpecifiedTaskId && !loading) {
            scrollToTask(props.scrollToSpecifiedTaskId);
        }
    }, [props.scrollToSpecifiedTaskId, scrollToTask, loading]);

    const [updateTaskSequence, { loading: isSequencing }] =
        useUpdateTaskSequenceMutation();

    const resequenceTask = useCallback(
        async (
            task: {
                id: string | null;
                sequence: number;
                parentTaskId: string | null;
            } | null,
            sequence: number,
            targetSpecifiedId: string | null
        ) => {
            if (
                task?.id &&
                (task?.sequence !== sequence ||
                    task?.parentTaskId !== targetSpecifiedId)
            ) {
                await updateTaskSequence({
                    variables: {
                        tenantId: currentTenantId || '',
                        taskId: task.id,
                        sequence: sequence,
                        targetParentTaskId: targetSpecifiedId,
                    },
                });
            }
        },
        [currentTenantId, updateTaskSequence]
    );

    const draggedImpliedTask = useRef<TaskSummaryItem | undefined>(undefined);

    const onImpliedTaskDrag = useCallback(
        (draggedItem: TaskSummaryItem | undefined) => {
            draggedImpliedTask.current = draggedItem;
        },
        []
    );

    const onImpliedTaskDrop = useCallback(
        async (targetSpecifiedId: string, targetImpliedId: string | null) => {
            if (!draggedImpliedTask?.current || !targetSpecifiedId) {
                return;
            }

            const draggedFromSpecifiedId =
                draggedImpliedTask.current?.parentTaskId;

            const targetImplieds = props.tasksSummary
                .filter((t) => t.parentTaskId === targetSpecifiedId)
                .filter((t) => !t.isDuplicate)
                .sort(sorters.sequenceSorter);

            const draggedToIndex = targetImpliedId
                ? targetImplieds.findIndex((t) => t.id === targetImpliedId)
                : 0;

            const reorderedItems = targetImplieds.filter(
                (value) => value.id !== draggedImpliedTask.current?.id
            );

            reorderedItems.splice(
                draggedToIndex,
                0,
                draggedImpliedTask.current
            );

            if (draggedFromSpecifiedId === targetSpecifiedId) {
                // update the cache before saving for speedy UI update, but only if we aren't changing specifieds as it gets glitchy.
                reorderedItems.forEach((task, index) => {
                    client.writeFragment({
                        id: `TaskQL:${task.id}`,
                        fragment: gql`
                            fragment TaskSequenceUpdate on TaskQL {
                                sequence
                            }
                        `,
                        data: {
                            sequence: index,
                        },
                    });
                });
            }

            const promises: Promise<void>[] = [];

            reorderedItems.forEach((task, index) => {
                promises.push(resequenceTask(task, index, targetSpecifiedId));
            });

            await Promise.all(promises);

            if (draggedFromSpecifiedId !== targetSpecifiedId) {
                await refetch();

                await refetchMissionQuery({
                    variables: {
                        tenantId: currentTenantId || '',
                        missionId: props.missionId,
                    },
                });
            }
        },
        [
            props.missionId,
            props.tasksSummary,
            currentTenantId,
            client,
            refetch,
            refetchMissionQuery,
            resequenceTask,
        ]
    );

    const onImpliedTaskMove = React.useCallback(
        async (
            source: TaskSummaryItem,
            targetSpecifiedId: string,
            targetImpliedId: string | null
        ) => {
            onImpliedTaskDrag(source);
            await onImpliedTaskDrop(targetSpecifiedId, targetImpliedId);
        },
        [onImpliedTaskDrag, onImpliedTaskDrop]
    );

    const handleTaskSequenceChanged = (
        taskId: string,
        newSequence: number,
        parentTaskId: string | null
    ) => {
        updateTaskSequence({
            variables: {
                tenantId: currentTenantId || '',
                taskId: taskId,
                sequence: newSequence,
                targetParentTaskId: parentTaskId,
            },
            optimisticResponse: {
                __typename: 'Mutations',
                taskSequenceUpdate: {
                    id: taskId,
                    missionId: props.missionId,
                    sequence: newSequence,
                    version: null,
                    parentTaskId: parentTaskId,
                    parentTask: null,
                    utcUpdated: null,
                    __typename: 'TaskQL',
                },
            },
        });
    };

    const handleFiltersChanged = (f: TaskFilters) => {
        setFilters(f);
        props.onFiltersActiveChanged(
            !!f.keyword ||
                f.taskStatusNames.length > 0 ||
                f.dueDateRanges.length > 0 ||
                f.resources.length > 0
        );
    };

    if (!props.tasksSummary || !props.missionId) {
        return <Loading />;
    }

    const classNames = mergeStyleSets({
        container: {
            padding: 8,
            display: 'flex',
            gap: 16,
            flexDirection: 'column',
        },
    });

    return (
        <div className={classNames.container}>
            {props.missionUserId && props.missionId && data?.tasks && (
                <TaskNotifications
                    missionId={props.missionId}
                    missionUserId={props.missionUserId}
                    missionAccess={props.missionAccess}
                    financialYearCode={props.financialYearCode}
                    tasks={data?.tasks}
                    onTaskClick={props.onTaskClick}
                    onTaskEditClick={props.onTaskEditClick}
                    onImpliedTaskDrag={onImpliedTaskDrag}
                    onNotificationCountChanged={
                        props.onTaskNotificationCountChanged
                    }
                />
            )}

            {!!data?.tasks && props.showFilter && (
                <TaskFilterBar
                    filters={filters}
                    tasks={data.tasks}
                    onFiltersChanged={handleFiltersChanged}
                    onDismiss={props.onFiltersDismiss}
                    hideResourcedFromFilter
                    hideCategoryFilter={props.viewMode !== 'List'}
                />
            )}

            {props.showAdd && props.missionId && (
                <NewTaskCard
                    onAddButtonClick={props.onAddSpecifiedButtonClick}
                />
            )}

            {props.viewMode === 'List' && (
                <AdvanceCard>
                    <TaskFlatList
                        {...props}
                        shimmerLines={
                            props.tasksSummary?.filter(
                                (t) =>
                                    !t.isDuplicate &&
                                    t.parentTaskId !== null &&
                                    props.tasksSummary?.some(
                                        (t2) => t2.id === t.parentTaskId
                                    )
                            ).length || undefined
                        }
                        tasks={data?.tasks}
                        tasksLoading={loading}
                        filters={filters}
                        defaultColumns={[
                            'displaySequence',
                            'specifiedTaskName',
                            'name',
                            'start',
                            'due',
                            'done',
                            'percentComplete',
                        ]}
                    />
                </AdvanceCard>
            )}

            {props.viewMode !== 'List' && (
                <SortableTasks
                    {...props}
                    isLoading={
                        loading ||
                        networkStatus === NetworkStatus.refetch ||
                        refetchingMission ||
                        isSequencing
                    }
                    data={data}
                    refs={refs}
                    onTaskSequenceChange={handleTaskSequenceChanged}
                    onImpliedTaskDrag={onImpliedTaskDrag}
                    onImpliedTaskDrop={onImpliedTaskDrop}
                    onImpliedTaskMove={onImpliedTaskMove}
                    filters={filters}
                />
            )}
        </div>
    );
}

function SortableTasks(
    props: TaskListProps & {
        isLoading: boolean;
        data?: GetMissionTasksQuery;
        onImpliedTaskDrag: (draggedItem: TaskSummaryItem) => void;
        onImpliedTaskDrop: (
            targetSpecifiedId: string,
            targetImpliedId: string | null
        ) => Promise<void>;
        onTaskSequenceChange: (
            id: string,
            newSequece: number,
            parentTaskId: string | null
        ) => void;
        onImpliedTaskMove: (
            source: TaskSummaryItem,
            targetSpecifiedId: string,
            targetImpliedId: string | null
        ) => void;
        refs: Record<string, RefObject<HTMLDivElement>> | undefined;
        filters?: TaskFilters;
    }
): JSX.Element {
    const specifiedTasks = (
        props.tasksSummary?.filter((t) => t.parentTaskId === null) || []
    )?.sort(sorters.sequenceSorter);

    const { data } = props;

    const [activeDragItemId, setActiveDragItemId] = useState<string | null>();

    const handleDragging = (id: string | null) => setActiveDragItemId(id);

    const handleSendToTop = (id: string) => handleDropped(id, 0);

    const handleDropped = (id: string | null, newIndex: number) => {
        if (!id) {
            return;
        }

        const specifiedTasks = props.tasksSummary
            .slice()
            .filter((t) => t.parentTaskId === null)
            .sort(sorters.sequenceSorter);

        const movedItem = specifiedTasks.find((m) => m.id === id);
        const remainingItems = specifiedTasks.filter((f) => f.id !== id);

        const reorderedItems = [
            ...remainingItems.slice(0, newIndex),
            movedItem,
            ...remainingItems.slice(newIndex),
        ];

        let index = 0;
        for (const task of reorderedItems) {
            if (task?.id && task?.sequence !== index) {
                props.onTaskSequenceChange(task.id, index, task.parentTaskId);
            }
            index++;
        }
    };

    const allImpliedTasks = data?.tasks?.filter(
        (t) =>
            !t.isDuplicate &&
            t.parentTaskId !== null &&
            data?.tasks?.some((t2) => t2.id === t.parentTaskId)
    );

    const startDates = allImpliedTasks
        ?.filter((t) => t.start)
        ?.map((d) => dayjs(d.start || null));
    const endDates = allImpliedTasks
        ?.filter((t) => t.due)
        ?.map((d) => dayjs(d.due || null));
    const ganttStartDate = startDates ? dayjs.min(...startDates) : dayjs();
    const ganttEndDate = endDates ? dayjs.max(...endDates) : dayjs();

    const getTaskCardProps = (task: {
        id: string | null;
        parentTaskId: string | null;
        name: string | null;
        sequence: number;
        isDuplicate: boolean;
    }): TaskCardProps => {
        const specifiedTask = data?.tasks?.find((t) => t.id === task?.id) || {
            ...task,
            missionId: props.missionId || null,
            name: task.name,
            percentComplete: 0,
            lastAttachment: null,
            taskCategory: null,
            description: null,
            done: null,
            due: null,
            start: null,
            review: null,
            resourcedFromTaskId: null,
            resourceId: null,
            utcAccepted: null,
            utcRejected: null,
            rejectedReason: null,
            utcResourceRemoved: null,
            utcChangesPending: null,
            utcCancelled: null,
            utcPostponed: null,
            utcAtRisk: null,
            isPercentageCompleteFromResources: false,
            isPercentageCompleteFromSubTasks: false,
            taskCategoryId: null,
            version: null,
            linkedMeasures: [],
            resource: null,
            resourcedFromTask: null,
            resourcedTasks: [],
            resourceIsPrimary: false,
            isDuplicate: false,
            effortWeight: 1,
            effortResourceWeight: 1,
            costWeight: 1,
            commentCount: 0,
            attachmentCount: 0,
            lastComment: null,
            tags: [],
        };

        const specifiedTaskDuplicates = data?.tasks
            ?.filter((t) => t.parentTaskId === task?.id && t.isDuplicate)
            .sort(sorters.sequenceSorter);

        const impliedTasks = data?.tasks
            ?.filter((t) => t.parentTaskId === task?.id && !t.isDuplicate)
            .sort(sorters.sequenceSorter);

        const impliedSubTasks = data?.tasks
            ?.filter((t) =>
                impliedTasks?.some((it) => it?.id === t.parentTaskId)
            )
            .sort(sorters.sequenceSorter);

        const shimmerLines = impliedTasks?.length || 0;

        // Find the correct task if the full data is loaded, otherwise use the summary.
        const taskCardProps: TaskCardProps = {
            divisionId: props.divisionId,
            missionId: props.missionId,
            missionUserId: props.missionUserId,
            isLoading: props.isLoading,
            shimmerLines: shimmerLines,
            specifiedTask: specifiedTask,
            specifiedTaskDuplicates: specifiedTaskDuplicates || [],
            impliedTasks: impliedTasks || null,
            impliedSubTasks: impliedSubTasks || [],
            onTaskClick: props.onTaskClick,
            onTaskEditClick: props.onTaskEditClick,
            onCommentsClick: props.onCommentsClick,
            onAttachmentsClick: props.onAttachmentsClick,
            missionAccess: props.missionAccess,
            onImpliedTaskDrag: props.onImpliedTaskDrag,
            onImpliedTaskDrop: props.onImpliedTaskDrop,
            onImpliedTaskMove: props.onImpliedTaskMove,
            onRejectedTaskClick: props.onRejectedTaskClick,
            onAddImpliedButtonClick: props.onAddImpliedButtonClick,
            filters: props.filters,
            viewMode: props.viewMode,
            ganttStartDate: dayjs(ganttStartDate).format('YYYY-MM-DD'),
            ganttEndDate: dayjs(ganttEndDate).format('YYYY-MM-DD'),
            onSendToTop: handleSendToTop,
            onSpecifiedTaskMove: handleDropped,
            highlightImpliedTaskId: props.highlightImpliedTaskId,
            tileColumnNames: props.tileColumnNames,
        };

        return taskCardProps;
    };

    const activeDragTask = activeDragItemId
        ? props.tasksSummary.find((t) => t.id === activeDragItemId)
        : null;

    const dragItemTaskProps = activeDragTask
        ? getTaskCardProps(activeDragTask)
        : null;

    return (
        <SortableContainer
            ids={specifiedTasks.map((t) => t.id || '')}
            onDragging={handleDragging}
            onDropped={handleDropped}
            dragOverlay={
                <DragOverlay adjustScale={false}>
                    {dragItemTaskProps ? (
                        <TaskCard
                            {...dragItemTaskProps}
                            dragHandler={
                                <DragHandlerButtonMemo
                                    hidden={!props.missionAccess.write}
                                />
                            }
                        />
                    ) : null}
                </DragOverlay>
            }
        >
            <Stack tokens={{ childrenGap: 16 }}>
                {specifiedTasks.map((task) => {
                    const taskCardProps = getTaskCardProps(task);
                    return (
                        <div
                            key={`item-${task.id}`}
                            ref={props.refs ? props.refs[task.id || ''] : null}
                        >
                            <SortableTask
                                taskCardProps={taskCardProps}
                                isActive={activeDragItemId === task.id}
                            />
                        </div>
                    );
                })}
            </Stack>
        </SortableContainer>
    );
}

export function SortableTask(props: {
    taskCardProps: TaskCardProps;
    isActive: boolean;
}): JSX.Element {
    const { taskCardProps } = props;

    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: taskCardProps.specifiedTask.id || '' });

    return (
        <div
            ref={setNodeRef}
            style={{
                transformOrigin: '0 0',
                opacity: props.isActive ? 0.4 : 1,
                transform: CSS.Translate.toString(transform),
                transition: transition || undefined,
            }}
        >
            <TaskCard
                {...props.taskCardProps}
                dragHandler={
                    <DragHandlerButtonMemo
                        hidden={!taskCardProps.missionAccess.write}
                        handleListeners={listeners}
                        handleAttributes={attributes}
                    />
                }
            />
        </div>
    );
}

export default React.memo(TaskList);
