import React, { useEffect, useState } from 'react';
import {
    Text,
    Stack,
    Callout,
    DefaultButton,
    DirectionalHint,
    MessageBar,
    MessageBarType,
    FontSizes,
    CommandButton,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import dayjs from 'dayjs';
import { useClipboard } from 'use-clipboard-copy';
import { Markdown } from '../../Presentations/Markdown';

export function StatusReportGridPreviousReportButton(props: {
    previousTextTitle?: string | null;
    previousText?: string | null;
    previousStatusReport?: {
        id: string | null;
        title: string | null;
        reportDate: string | null;
    } | null;
    isMarkdown?: boolean;
}): JSX.Element | null {
    const [isCalloutVisible, setIsCalloutVisible] = useState(false);
    const [showCopy, setShowCopy] = useState(false);
    const descriptionId: string = useId('factDescription');
    const iconButtonId: string = useId('factIconButton');

    const clipboard = useClipboard({
        copiedTimeout: 3000, // show copy message in milliseconds
    });

    useEffect(() => {
        if (isCalloutVisible) {
            setShowCopy(clipboard.isSupported());
        } else {
            setShowCopy(false);
        }
    }, [clipboard, isCalloutVisible]);

    if (!props.previousText || !props.previousStatusReport) {
        return null;
    }

    return (
        <React.Fragment>
            <CommandButton
                id={iconButtonId}
                text="Previous"
                iconProps={{
                    iconName: 'History',
                }}
                styles={{
                    textContainer: {
                        fontSize: FontSizes.xSmall,
                    },
                    label: {
                        margin: 0,
                    },
                    icon: {
                        fontSize: FontSizes.xSmall,
                    },
                    root: {
                        height: 16,
                    },
                }}
                ariaLabel="See Previous"
                onClick={() => setIsCalloutVisible(!isCalloutVisible)}
            />
            {isCalloutVisible && (
                <Callout
                    target={`#${iconButtonId}`}
                    setInitialFocus
                    onDismiss={() => setIsCalloutVisible(false)}
                    ariaDescribedBy={descriptionId}
                    role="alertdialog"
                    directionalHint={DirectionalHint.topCenter}
                    styles={{ root: { maxWidth: 320 } }}
                >
                    <Stack
                        horizontalAlign="start"
                        tokens={{ padding: 16, childrenGap: 8 }}
                    >
                        <Text block variant="xLarge">
                            Previous Published Report
                        </Text>
                        <Text block variant="mediumPlus">
                            {props.previousStatusReport.title ||
                                'Status Report'}

                            {!!props.previousStatusReport.reportDate && (
                                <>
                                    {' '}
                                    (
                                    {dayjs
                                        .utc(
                                            props.previousStatusReport
                                                .reportDate
                                        )
                                        .format('DD MMM YYYY')}
                                    )
                                </>
                            )}
                        </Text>

                        {!!props.previousTextTitle && (
                            <Text block variant="medium">
                                {props.previousTextTitle}
                            </Text>
                        )}

                        <Text block variant="small" id={descriptionId}>
                            {props.isMarkdown ? (
                                <Markdown source={props.previousText} />
                            ) : (
                                props.previousText
                            )}
                        </Text>
                        <Stack.Item align="start">
                            {showCopy && clipboard.copied && (
                                <MessageBar
                                    messageBarType={MessageBarType.success}
                                >
                                    Copied to clipboard
                                </MessageBar>
                            )}
                            {showCopy &&
                                !clipboard.copied &&
                                !props.isMarkdown && (
                                    <DefaultButton
                                        text="Copy"
                                        onClick={() =>
                                            clipboard.copy(props.previousText)
                                        }
                                        iconProps={{
                                            iconName: 'ClipboardSolid',
                                        }}
                                    />
                                )}
                        </Stack.Item>
                    </Stack>
                </Callout>
            )}
        </React.Fragment>
    );
}
