import React from 'react';
import { DatePicker, mergeStyleSets, IDatePickerProps } from '@fluentui/react';

export function InlineDatePicker(
    props: IDatePickerProps & { fontSize?: number | string }
): JSX.Element {
    const styles = mergeStyleSets({
        inlineDatePicker: {
            selectors: {
                '.ms-TextField-fieldGroup': {
                    backgroundColor: 'transparent',
                    height: 'auto',
                },
                '.ms-TextField-field': {
                    fontSize: props.fontSize || 12,
                    padding: 0,
                    height: 'auto',
                    lineHeight: 'normal',
                },
                '.ms-TextField-field:hover': {
                    textDecoration: 'underline',
                },
                i: {
                    display: 'none',
                },
            },
        },
    });

    return (
        <DatePicker className={styles.inlineDatePicker} {...props} borderless />
    );
}
