import React from 'react';
import { Guid } from 'guid-typescript';
import {
    TextField,
    Stack,
    ColorPicker,
    IColor,
    Label,
    DialogType,
    MessageBar,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    Dialog,
    MessageBarType,
    Text,
    Dropdown,
    IDropdownOption,
    Icon,
    Toggle,
} from '@fluentui/react';
import useForm from '../../../hooks/useForm';
import {
    TagQl,
    TagTypes,
    useDeleteTagMutation,
    useUpdateTagMutation,
} from '../../../data/types';
import { EditPanel } from '../../../components/shared/EditPanel';
import { useStateContext } from '../../../services/contextProvider';
import { NeutralColors } from '@fluentui/theme';

export type TagEditPanelProps = {
    showPanel: boolean;
    tag?: TagQl | null;
    onCancel: () => void;
    onSave: () => void;
};

export function TagEditPanel(props: TagEditPanelProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [deleteErrorResult, setDeleteErrorResult] = React.useState<{
        message: string | null;
    } | null>();
    // const [moveToTaskCategoryId, setMoveToTaskCategoryId] = React.useState<
    //     string | null
    // >();

    const [updateTag, { loading: isSaving, error: saveError }] =
        useUpdateTagMutation({
            onCompleted: props.onSave,
            refetchQueries: ['GetTags'],
        });

    const [deleteTag, { error: deleteError }] = useDeleteTagMutation({
        variables: {
            id: props.tag?.id || '',
            tenantId: currentTenantId || '',
            restore: false,
        },
        refetchQueries: ['GetTags'],
    });

    // const [swapCategory] = useSwapTaskCategoryMutation({
    //     variables: {
    //         id: props.tag?.id || '',
    //         swapId: moveToTaskCategoryId || null,
    //         tenantId: currentTenantId || '',
    //     },
    //     refetchQueries: ['GetTaskCategories'],
    // });

    type formValuesType = {
        name: string;
        colourHex: string;
        icon: string;
        tagType: TagTypes;
        isMeasureTag: boolean;
        isTaskTag: boolean;
        // divisionIds: string[];
    };

    const formDefaultValues: formValuesType = {
        name: props.tag?.name || '',
        colourHex: props.tag?.colourHex || 'ffffff',
        icon: props.tag?.icon || '',
        tagType: props.tag?.tagType || TagTypes.UserTag,
        isMeasureTag: props.tag ? props.tag.isMeasureTag : false,
        isTaskTag: props.tag ? props.tag.isTaskTag : false,
        // divisionIds: props.tag?.divisions?.map((d) => d.id || '') || [],
    };

    const formCallback = (values: formValuesType): void => {
        updateTag({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    id: props.tag?.id || Guid.createEmpty().toString(),
                    name: values.name,
                    colourHex: values.colourHex,
                    icon: values.icon,
                    tagType: values.tagType,
                    isMeasureTag: values.isMeasureTag || false,
                    isTaskTag: values.isTaskTag || false,
                    // divisionIds: values.divisionIds,
                    // version: props.tag?.version || '',
                },
            },
        });
    };

    const formValidate = (
        values: formValuesType
    ): {
        [key: string]: string;
    } => {
        const formErrorMessages = {
            name: '',
            divisionId: '',
        };

        if (!values.name) {
            formErrorMessages.name = 'Enter a name';
        }

        return formErrorMessages;
    };

    const { errors, handleChange, handleSubmit, updateValue, values } = useForm(
        formDefaultValues,
        formCallback,
        formValidate
    );

    const handleTagTypeChange = (
        _event?: React.FormEvent<HTMLDivElement>,
        tag?: IDropdownOption
    ): void => {
        if (tag) {
            updateValue('tagType', tag.key);
        }
    };

    const onDeleteClick = props.tag?.id
        ? (): void => {
              setIsConfirmingDelete(true);
              setIsDeleting(false);
              setDeleteErrorResult(null);
          }
        : undefined;

    const deleteAction = async (): Promise<void> => {
        setIsDeleting(true);

        // if (props.tag?.taskCount) {
        //     const swapResult = await swapCategory();
        //     if (!swapResult.data?.taskCategorySwap?.success) {
        //         setDeleteErrorResult(swapResult.data?.taskCategorySwap || null);
        //         return;
        //     }
        // }

        const result = await deleteTag();
        if (!result.data?.tagDelete?.success) {
            setDeleteErrorResult(result.data?.tagDelete || null);
        }
        setIsDeleting(false);

        if (props.onCancel) {
            props.onCancel();
        }
    };

    const updateColor = (_ev: React.SyntheticEvent, colorObj: IColor) => {
        updateValue('colourHex', colorObj.hex);
    };

    // const onDivisionPickerChange = (
    //     divisionId: string,
    //     isSelected: boolean
    // ) => {
    //     const selectedDivisionIds = isSelected
    //         ? [...values.divisionIds, divisionId]
    //         : values.divisionIds.filter((is) => is !== divisionId);
    //     updateValue('divisionIds', selectedDivisionIds);
    // };

    // Renaming these will stop them showing on the task page.
    const isNameReadOnly =
        formDefaultValues.name === 'Main Effort' ||
        formDefaultValues.name === 'Enabling Activity' ||
        formDefaultValues.name === 'Flanking Activity';

    return (
        <EditPanel
            activeViewName="TagEdit"
            onDismiss={props.onCancel}
            showPanel={props.showPanel}
            headerText={props?.tag?.id ? 'Edit Tag' : 'New Tag'}
            isSaving={isSaving}
            isValid={true}
            saveErrorMessage={saveError?.message || null}
            onUpdateClick={handleSubmit}
            onDeleteClick={onDeleteClick}
        >
            <Dialog
                hidden={!isConfirmingDelete}
                onDismiss={(): void => setIsConfirmingDelete(false)}
                modalProps={{
                    isBlocking: true,
                }}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Confirm',
                    closeButtonAriaLabel: 'Close',
                }}
            >
                <Stack
                    tokens={{ childrenGap: 12 }}
                    style={{ marginTop: '0', marginBottom: '24px' }}
                >
                    <Text
                        variant="medium"
                        block
                        className="ms-color-gray10"
                        style={{ color: NeutralColors.gray110 }}
                    >
                        Are you sure you want to delete this tag?
                    </Text>

                    {/* {props.tag?.taskCount && (
                        <>
                            <Text
                                variant="medium"
                                block
                                className="ms-color-gray10"
                                style={{ color: NeutralColors.gray110 }}
                            >
                                Currently associated with {props.tag?.taskCount}{' '}
                                tasks.
                            </Text>

                            <TaskCategoryPicker
                                label="Replace this Category with:"
                                divisionId={null}
                                selectedTaskCategoryId={moveToTaskCategoryId}
                                onChange={function (
                                    taskCategoryId: string | null
                                ): void {
                                    setMoveToTaskCategoryId(taskCategoryId);
                                }}
                            />
                        </>
                    )} */}
                </Stack>

                {(deleteError || deleteErrorResult) && (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={true}
                    >
                        {deleteError?.message ?? deleteErrorResult?.message}
                    </MessageBar>
                )}

                <DialogFooter>
                    <PrimaryButton
                        text="Delete"
                        disabled={isDeleting}
                        // disabled={
                        //     isDeleting || moveToTaskCategoryId === props.tag?.id
                        // }
                        onClick={deleteAction}
                    />
                    <DefaultButton
                        text="Cancel"
                        disabled={isDeleting}
                        onClick={(): void => {
                            setIsConfirmingDelete(false);
                        }}
                    />
                </DialogFooter>
            </Dialog>

            <Stack tokens={{ childrenGap: 8 }}>
                <TextField
                    readOnly={isNameReadOnly}
                    label="Name"
                    required
                    name="name"
                    autoComplete="off"
                    defaultValue={formDefaultValues.name}
                    errorMessage={errors.name}
                    onChange={handleChange}
                />

                <Label>Colour</Label>
                <ColorPicker
                    color={`#${values.colourHex}`}
                    onChange={updateColor}
                    alphaType="none"
                    showPreview={true}
                />

                <Toggle
                    label="Measure Tag"
                    onText="Shown on Measures"
                    offText="Not shown on Measures"
                    checked={values.isMeasureTag}
                    onChange={(
                        ev: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                    ): void => {
                        updateValue('isMeasureTag', checked || false);
                    }}
                />

                <Toggle
                    label="Task Tag"
                    onText="Shown on Tasks"
                    offText="Not shown on Tasks"
                    checked={values.isTaskTag}
                    onChange={(
                        ev: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                    ): void => {
                        updateValue('isTaskTag', checked || false);
                    }}
                />

                <Dropdown
                    placeholder="Choose Tag Type"
                    label="Tag Type"
                    selectedKey={values.tagType}
                    onChange={handleTagTypeChange}
                    multiSelect={false}
                    options={[
                        {
                            key: TagTypes.UserTag,
                            text: 'User',
                        },
                        {
                            key: TagTypes.AdminTag,
                            text: 'Admin',
                        },
                    ]}
                />

                <TextField
                    label="Override Icon"
                    name="icon"
                    autoComplete="off"
                    defaultValue={formDefaultValues.icon}
                    errorMessage={errors.icon}
                    onChange={handleChange}
                />

                <Label>Preview</Label>
                <Icon
                    style={{
                        color: values.colourHex
                            ? `#${values.colourHex}`
                            : undefined,
                        fontSize: 40,
                        width: '40px',
                        height: '40px',
                        margin: '12px 0',
                    }}
                    iconName={
                        values.icon
                            ? values.icon
                            : values.tagType === TagTypes.AdminTag
                            ? 'TagAdmin'
                            : 'TagSolid'
                    }
                    aria-label="Tag"
                />
            </Stack>
        </EditPanel>
    );
}
