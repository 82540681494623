import * as React from 'react';
import styles from './../Importer.module.css';

export type ImportResult = {
    success: string;
    rows: {
        total: number;
        success: number;
        skipped: number;
        failed: number;
    };
    exception: string;
    errors: { index: number; error: string }[];
};

export type ImportResultsProps = {
    results: ImportResult;
};

export function ImportResults(props: ImportResultsProps): JSX.Element {
    return (
        <div className={styles.importResults}>
            <div className={styles.resultHeader}>Results:</div>
            <div>Total Rows: {props.results.rows.total}</div>
            <div>Success: {props.results.rows.success}</div>
            <div>Skipped: {props.results.rows.skipped}</div>
            <div>Failed: {props.results.rows.failed}</div>
            {props.results.exception && (
                <div className={styles.errorMessage}>
                    Error: {props.results.exception}
                </div>
            )}
            {props.results.success && props.results.errors.length == 0 && (
                <div className={styles.successHeader}>Success</div>
            )}
            {props.results.errors && props.results.errors.length > 0 && (
                <>
                    <div className={styles.errorDetailHeader}>
                        Detailed Results:
                    </div>
                    {props.results.errors.map((errorInfo, index) => {
                        return (
                            <div key={`error-${index}`}>
                                Error (Row: {errorInfo.index}):{' '}
                                {errorInfo.error}
                            </div>
                        );
                    })}
                </>
            )}
        </div>
    );
}
