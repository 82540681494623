import {
    DragCancelEvent,
    DragEndEvent,
    DragOverEvent,
    DragStartEvent,
} from '@dnd-kit/core';
import { Dispatch, SetStateAction } from 'react';

type Items = Record<string, string[]>;

export const useSortableGroups = (
    items: Items,
    onUpdateItems: Dispatch<SetStateAction<Record<string, string[]>>>,
    onSetActiveId: Dispatch<SetStateAction<string | null>>,
    onUpdateSequence: (
        itemId: string,
        groupId: string | null,
        sequence: number
    ) => Promise<void>
): {
    handleDragStart: (event: DragStartEvent) => void;
    handleDragCancel: (event: DragCancelEvent) => void;
    handleDragOver: (event: DragOverEvent) => void;
    handleDragEnd: (event: DragEndEvent) => void;
} => {
    const UNGROUPED_ID = 'UNGROUPED';
    const VOID_ID = 'void';

    const findContainer = (id: string) => {
        if (id in items) {
            return id;
        }

        return Object.keys(items).find((key: string) =>
            items[`${key}`].includes(id)
        );
    };

    async function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        const activeContainer = findContainer(active.id as string);

        if (!activeContainer) {
            onSetActiveId(null);
            return;
        }

        const overId = over?.id || VOID_ID;

        const overContainer = findContainer(overId as string);

        if (activeContainer && overContainer) {
            const activeIndex = items[`${activeContainer}`].indexOf(
                active.id as string
            );
            const overIndex = items[`${overContainer}`].indexOf(
                overId as string
            );

            if (activeIndex !== overIndex || overContainer !== active.id) {
                await onUpdateSequence(
                    active.id as string,
                    overContainer === UNGROUPED_ID ? null : overContainer,
                    overIndex
                );
            }
        }

        onSetActiveId(null);
    }

    function handleDragOver(event: DragOverEvent) {
        const { active, over } = event;

        const overId = over?.id || VOID_ID;
        const overContainer = findContainer(overId as string);
        const activeContainer = findContainer(active.id as string);

        if (!overContainer || !activeContainer) {
            return;
        }

        if (activeContainer !== overContainer) {
            onUpdateItems((items: Items) => {
                const activeItems = items[`${activeContainer}`];
                const overItems = items[`${overContainer}`];
                const overIndex = overItems.indexOf(overId as string);
                const activeIndex = activeItems.indexOf(active.id as string);

                let newIndex: number;

                if (overId in items) {
                    newIndex = overItems.length + 1;
                } else {
                    const isBelowLastItem =
                        over &&
                        overIndex === overItems.length - 1 &&
                        active.rect.current.translated &&
                        active.rect.current.translated.top >
                            over.rect.top + over.rect.height;

                    const modifier = isBelowLastItem ? 1 : 0;

                    newIndex =
                        overIndex >= 0
                            ? overIndex + modifier
                            : overItems.length + 1;
                }

                return {
                    ...items,
                    [activeContainer]: [
                        ...items[`${activeContainer}`].filter(
                            (item) => item !== active.id
                        ),
                    ],
                    [overContainer]: [
                        ...items[`${overContainer}`].slice(0, newIndex),
                        items[`${activeContainer}`][Number(activeIndex)],
                        ...items[`${overContainer}`].slice(
                            newIndex,
                            items[`${overContainer}`].length
                        ),
                    ],
                };
            });
        }
    }

    const handleDragStart = (event: DragStartEvent) => {
        onSetActiveId(event.active.id as string);
    };

    const handleDragCancel = () => {
        onSetActiveId(null);
    };

    return {
        handleDragStart: handleDragStart,
        handleDragCancel: handleDragCancel,
        handleDragOver: handleDragOver,
        handleDragEnd: handleDragEnd,
    };
};
