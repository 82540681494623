import React from 'react';

import { Slider } from '@fluentui/react';
import { MeasureStatusColours } from '../../Colours';

export function YellowStatusInput(props: {
    defaultValue: number | undefined | null;
    onChange: (newValue: number) => void;
    disabled?: boolean;
}): JSX.Element {
    return (
        <Slider
            label="Yellow Status Start"
            max={1}
            step={0.01}
            defaultValue={props.defaultValue || 0.9}
            valueFormat={(value: number): string =>
                `${(value * 100).toFixed(0)}%`
            }
            onChange={(value: number): void => {
                props.onChange(value);
            }}
            showValue={true}
            disabled={props.disabled}
            styles={{
                titleLabel: {
                    padding: '5px 0', // consitant with other labels
                },
                container: {
                    paddingBottom: 4,
                },
                activeSection: {
                    backgroundColor: MeasureStatusColours.offTarget,
                },
                inactiveSection: {
                    backgroundColor: MeasureStatusColours.nearTarget,
                },
            }}
        />
    );
}
