import React, { useState } from 'react';
import {
    Icon,
    IconButton,
    Label,
    Link,
    mergeStyleSets,
    Modal,
    Pivot,
    PivotItem,
    ScrollablePane,
    Shimmer,
    ShimmerElementsGroup,
    ShimmerElementType,
    Stack,
    Text,
} from '@fluentui/react';
import { AdvanceCard } from '../../../components/AdvanceCard';
import { StatusReportGridMeasureDetail } from './StatusReportGridMeasureDetail';
import { StatusReportGridTaskDetail } from './StatusReportGridTaskDetail';
import { StatusReportGridTextBox } from './StatusReportGridTextBox';
import {
    Access,
    ExtractQueryArrayType,
    TaskWithStatus,
} from '../../../data/extendedTypes';
import {
    GetMeasuresForStatusReportQuery,
    GetTasksForStatusReportQuery,
    ReportPeriodTypes,
} from '../../../data/types';
import { sorters } from '../../../data/sorters';
import { TaskGanttBar } from './TaskGanttBar';
import { useFormatters } from '../../../hooks/useFormatters';
import { useThemes } from '../../../hooks/useThemes';
import { useTaskStatus } from '../../../hooks/useTaskStatus';
import { useViewport } from '../../../hooks/useViewport';
import ExpandCollapse from '../../../components/ExpandCollapse';
import dayjs from 'dayjs';
import { StatusReportLinkedTasksExpandablePanel } from './StatusReportLinkedTasksExpandablePanel';
import { StatusReportFactActions } from './StatusReportFactActions';

export type StatusReportFactCardProps = {
    dragHandler?: JSX.Element | null;
    isLoading: boolean;
    missionAccess: Access;
    missionUserId: string | null;
    isReadOnly: boolean;
    fyStartDate: string;
    utcDataDate: string | null;
    utcCompletedDate: string | null;
    reportPeriodType: ReportPeriodTypes | null;
    reportPeriod: number | null;
    fact: {
        id: string | null;
        measureId: string | null;
        taskId: string | null;
        factText: string | null;
        soWhatText: string | null;
        insightText: string | null;
        actionText: string | null;
        sequence: number;
        isIncluded: boolean;
        actions: {
            id: string | null;
            name: string | null;
            due: string | null;
            done: string | null;
            sequence: number;
            version: string | null;
        }[];
    };
    measure: ExtractQueryArrayType<
        GetMeasuresForStatusReportQuery,
        ['measures']
    > | null;
    task: ExtractQueryArrayType<GetTasksForStatusReportQuery, ['tasks']> | null;
    tasks: GetTasksForStatusReportQuery['tasks'] | null | undefined;
    measures: GetMeasuresForStatusReportQuery['measures'] | null | undefined;
    onFactTextChanged: (newValue: string) => void;
    onFactActionsChanged: (
        newActions: {
            id: string | null;
            name: string | null;
            due: string | null;
            done: string | null;
            sequence: number;
            version: string | null;
        }[]
    ) => void;
    onSoWhatTextChanged: (newValue: string) => void;
    onInsightTextChanged: (newValue: string) => void;
    onActionTextChanged: (newValue: string) => void;
    onRemoveFactButtonClick: () => void;
    onMeasureNavigate: (measureId: string) => void;
    onTaskNavigate: (taskId: string) => void;
    actionMode: 'FreeText' | 'List';
};

function StatusReportFactCard(props: StatusReportFactCardProps): JSX.Element {
    const { measure, task, fact, isReadOnly, isLoading } = props;

    const { width } = useViewport();
    const breakpoint = 620;
    const isMobileMode = width < breakpoint;

    const [activeView, setActiveView] = useState<
        'ImpliedTasks' | 'LinkedTasks' | 'LinkedMeasures' | null
    >(null);

    const [isExpanded, setIsExpanded] = useState(true);

    // For specified task facts
    let impliedTasks: GetTasksForStatusReportQuery['tasks'] = [];
    let linkedMeasures: GetMeasuresForStatusReportQuery['measures'] = [];

    // For measure facts
    let linkedSpecifiedTasksAndImplied: GetTasksForStatusReportQuery['tasks'] =
        [];

    if (measure) {
        const specifiedTaskIdsToDisplay =
            props.tasks
                ?.filter((t) => t.utcDeleted === null)
                ?.filter((t) =>
                    t.linkedMeasures?.some((lm) => lm.measureId === measure.id)
                )
                .map((t) => t.id) || [];

        linkedSpecifiedTasksAndImplied =
            props.tasks
                ?.filter((t) => t.utcDeleted === null)
                ?.filter((t) => !t.isDuplicate)
                ?.filter((t) =>
                    specifiedTaskIdsToDisplay.some(
                        (id) => id === t.parentTaskId || id === t.id
                    )
                )
                .sort(sorters.sequenceSorter) || [];
    } else if (task) {
        impliedTasks =
            props.tasks
                ?.filter((t) => t.utcDeleted === null)
                ?.filter((t) => !t.isDuplicate)
                ?.filter((t) => t.parentTaskId === task.id)
                .sort(sorters.sequenceSorter) || [];

        linkedMeasures =
            props.measures
                ?.filter((m) =>
                    task.linkedMeasures.some((t) => t.measureId === m.id)
                )
                .sort(sorters.sequenceSorter) || [];
    } else {
        impliedTasks = [];
    }

    const linkedTaskCount = linkedSpecifiedTasksAndImplied.filter(
        (t) => !t.parentTaskId
    ).length;

    const modalActions = (
        <Stack horizontal tokens={{ childrenGap: 16 }}>
            {!!linkedMeasures.length && (
                <Text variant="small">
                    <Link onClick={() => setActiveView('LinkedMeasures')}>
                        {linkedMeasures.length} Linked Measures of Success
                    </Link>
                </Text>
            )}
            {!!linkedTaskCount && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 4,
                    }}
                >
                    <ExpandCollapse
                        isExpanded={activeView === 'LinkedTasks'}
                        onExpand={() => setActiveView('LinkedTasks')}
                        onCollapse={() => setActiveView(null)}
                        compact
                    />
                    <Link
                        onClick={() =>
                            setActiveView((v) =>
                                v !== 'LinkedTasks' ? 'LinkedTasks' : null
                            )
                        }
                    >
                        {linkedTaskCount} Linked Tasks
                    </Link>
                </div>
            )}
            {!!impliedTasks.length && (
                <Text variant="small">
                    <Link onClick={() => setActiveView('ImpliedTasks')}>
                        {impliedTasks.length} Implied Tasks
                    </Link>
                </Text>
            )}
        </Stack>
    );

    const previousFact =
        measure?.latestStatusReportFact || task?.latestStatusReportFact;

    const { currentTheme } = useThemes();

    const classNames = mergeStyleSets({
        inputContainer: {
            padding: 8,
            backgroundColor: currentTheme.palette.neutralLight,
            borderRadius: 8,
            boxShadow: currentTheme.effects.elevation4,
        },
    });

    return (
        <React.Fragment>
            <AdvanceCard key={fact.id}>
                <AdvanceCard.Item
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 8,
                        }}
                    >
                        <ExpandCollapse
                            isExpanded={isExpanded}
                            onExpand={() => setIsExpanded(true)}
                            onCollapse={() => setIsExpanded(false)}
                        />

                        {fact.measureId ? (
                            <Icon iconName="Trending12"></Icon>
                        ) : fact.taskId ? (
                            <Icon iconName="TaskGroup"></Icon>
                        ) : (
                            <Icon iconName="TextDocument"></Icon>
                        )}

                        {!!fact.measureId &&
                            !!measure &&
                            !!measure?.utcDeleted && (
                                <Label>
                                    (Deleted{' '}
                                    {dayjs(measure?.utcDeleted).format(
                                        'DD MMM YYYY'
                                    )}
                                    )
                                </Label>
                            )}

                        {!!fact.taskId && !!task && !!task?.utcDeleted && (
                            <Label>
                                (Deleted{' '}
                                {dayjs(task?.utcDeleted).format('DD MMM YYYY')})
                            </Label>
                        )}

                        <Text
                            variant="mediumPlus"
                            block
                            style={{
                                textDecoration:
                                    measure?.utcDeleted || task?.utcDeleted
                                        ? 'line-through'
                                        : undefined,
                            }}
                        >
                            {!!measure && measure.name}
                            {!!task && task.name}
                            {!fact.taskId && !fact.measureId && 'Fact'}
                        </Text>
                    </div>

                    {!isLoading && fact.id && !isReadOnly && (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div>{props.dragHandler}</div>
                            <div>
                                <IconButton
                                    iconProps={{ iconName: 'Delete' }}
                                    onClick={props.onRemoveFactButtonClick}
                                    title="Hide Fact"
                                />
                            </div>
                        </div>
                    )}
                </AdvanceCard.Item>

                {isExpanded && (
                    <AdvanceCard.Item>
                        <Stack
                            horizontal={!isMobileMode}
                            tokens={{ childrenGap: 16, padding: 4 }}
                        >
                            <Stack.Item grow={2} style={{ flexBasis: 0 }}>
                                <Stack
                                    tokens={{
                                        padding: 0,
                                        childrenGap: 8,
                                    }}
                                    verticalFill
                                >
                                    {fact.measureId && (
                                        <Stack.Item grow>
                                            <Shimmer
                                                isDataLoaded={!isLoading}
                                                customElementsGroup={
                                                    <StatusReportFactCardShimmerElements type="Measure" />
                                                }
                                            >
                                                <StatusReportGridMeasureDetail
                                                    allowNavigation={
                                                        !props.utcCompletedDate &&
                                                        !props.utcDataDate
                                                    }
                                                    forDateTime={
                                                        props.utcDataDate ||
                                                        props.utcCompletedDate
                                                    }
                                                    fyStartDate={
                                                        props.fyStartDate
                                                    }
                                                    reportPeriodType={
                                                        props.reportPeriodType
                                                    }
                                                    reportPeriod={
                                                        props.reportPeriod
                                                    }
                                                    onNavigate={() =>
                                                        props.onMeasureNavigate(
                                                            fact.measureId || ''
                                                        )
                                                    }
                                                    measure={measure || null}
                                                />
                                            </Shimmer>
                                        </Stack.Item>
                                    )}

                                    {fact.taskId && (
                                        <Stack.Item grow>
                                            <Shimmer
                                                isDataLoaded={!isLoading}
                                                customElementsGroup={
                                                    <StatusReportFactCardShimmerElements type="Task" />
                                                }
                                            >
                                                <StatusReportGridTaskDetail
                                                    task={task || null}
                                                    allowNavigation={
                                                        !props.utcCompletedDate &&
                                                        !props.utcDataDate
                                                    }
                                                    onNavigate={() =>
                                                        props.onTaskNavigate(
                                                            fact.taskId || ''
                                                        )
                                                    }
                                                />
                                            </Shimmer>
                                        </Stack.Item>
                                    )}

                                    {!fact.taskId && !fact.measureId && (
                                        <Stack.Item
                                            grow
                                            className={
                                                classNames.inputContainer
                                            }
                                        >
                                            <StatusReportGridTextBox
                                                defaultValue={
                                                    fact.factText || ''
                                                }
                                                isReadOnly={isReadOnly}
                                                placeholder="What are the facts? Not assumptions hearsay or otherwise."
                                                onChange={
                                                    props.onFactTextChanged
                                                }
                                            />
                                        </Stack.Item>
                                    )}

                                    {!isLoading &&
                                        !isMobileMode &&
                                        (fact.taskId || fact.measureId) &&
                                        modalActions}
                                </Stack>
                            </Stack.Item>
                            <Stack.Item
                                grow={1}
                                className={classNames.inputContainer}
                                style={{ flexBasis: 0, paddingTop: 0 }}
                            >
                                <StatusReportGridTextBox
                                    label="So What"
                                    previousText={previousFact?.soWhatText}
                                    previousStatusReport={
                                        previousFact?.statusReport
                                    }
                                    placeholder="What is the deduction? What does this mean to us?"
                                    defaultValue={fact.soWhatText || ''}
                                    isReadOnly={isReadOnly}
                                    onChange={props.onSoWhatTextChanged}
                                />
                            </Stack.Item>
                            <Stack.Item
                                grow={1}
                                className={classNames.inputContainer}
                                style={{ flexBasis: 0, paddingTop: 0 }}
                            >
                                <StatusReportGridTextBox
                                    label="Insight"
                                    previousText={previousFact?.insightText}
                                    previousStatusReport={
                                        previousFact?.statusReport
                                    }
                                    placeholder="What is the cause?"
                                    defaultValue={fact.insightText || ''}
                                    isReadOnly={isReadOnly}
                                    onChange={props.onInsightTextChanged}
                                />
                            </Stack.Item>
                            <Stack.Item
                                grow={1}
                                className={classNames.inputContainer}
                                style={{ flexBasis: 0, paddingTop: 0 }}
                            >
                                {props.actionMode === 'FreeText' ? (
                                    <StatusReportGridTextBox
                                        label="Action"
                                        previousText={previousFact?.actionText}
                                        previousStatusReport={
                                            previousFact?.statusReport
                                        }
                                        placeholder="Does our analysis require any actions? If so, what are they?"
                                        defaultValue={fact.actionText || ''}
                                        isReadOnly={isReadOnly}
                                        onChange={props.onActionTextChanged}
                                    />
                                ) : (
                                    <StatusReportFactActions
                                        isReadOnly={isReadOnly}
                                        isLoading={isLoading}
                                        actions={fact.actions}
                                        onChanged={props.onFactActionsChanged}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </AdvanceCard.Item>
                )}
                {isExpanded && activeView === 'LinkedTasks' && (
                    <AdvanceCard.Item>
                        <StatusReportLinkedTasksExpandablePanel
                            {...props}
                            linkedSpecifiedTasksAndImplied={
                                linkedSpecifiedTasksAndImplied
                            }
                            onNavigate={props.onTaskNavigate}
                        />
                    </AdvanceCard.Item>
                )}
            </AdvanceCard>
            <StatusReportFactCardInfoModal
                {...props}
                title={measure?.name || task?.name || ''}
                activeView={activeView}
                onActiveViewChanged={(v) => {
                    setActiveView(v);
                }}
                isOpen={!!activeView && activeView !== 'LinkedTasks'}
                onClose={() => setActiveView(null)}
                impliedTasks={impliedTasks}
                linkedMeasures={linkedMeasures}
                linkedSpecifiedTasksAndImplied={linkedSpecifiedTasksAndImplied}
            />
        </React.Fragment>
    );
}

export default React.memo(StatusReportFactCard);

type StatusReportFactCardInfoModalViews =
    | 'ImpliedTasks'
    | 'LinkedTasks'
    | 'LinkedMeasures';

export function StatusReportFactCardInfoModal(props: {
    title: string;
    isOpen?: boolean;
    onClose: () => void;
    activeView: StatusReportFactCardInfoModalViews | null;
    onActiveViewChanged: (newView: StatusReportFactCardInfoModalViews) => void;
    impliedTasks: GetTasksForStatusReportQuery['tasks'];
    linkedMeasures: GetMeasuresForStatusReportQuery['measures'];
    linkedSpecifiedTasksAndImplied: GetTasksForStatusReportQuery['tasks'];
    utcDataDate: string | null;
    utcCompletedDate: string | null;
    reportPeriodType: ReportPeriodTypes | null;
    reportPeriod: number | null;
    fyStartDate: string;
    onMeasureNavigate: (measureId: string) => void;
    onTaskNavigate: (taskId: string) => void;
    missionAccess: Access;
    missionUserId: string | null;
    isReadOnly: boolean;
}): JSX.Element {
    const classNames = mergeStyleSets({
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            gap: 16,
        },
        title: {
            marginTop: 0,
        },
        container: {
            maxWidth: 800,
            minWidth: 800,
            padding: 16,
            paddingRight: 8,
        },
        body: {},
        pivot: {
            height: 300,
            position: 'relative',
        },
    });

    return (
        <Modal {...props} containerClassName={classNames.container}>
            <div className={classNames.header}>
                <div>
                    <h2 className={classNames.title}>{props.title}</h2>
                </div>
                <div>
                    <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={props.onClose}
                    />
                </div>
            </div>

            <div className={classNames.body}>
                <Pivot
                    selectedKey={props.activeView}
                    onLinkClick={(item) => {
                        props.onActiveViewChanged(
                            item?.props
                                .itemKey as StatusReportFactCardInfoModalViews
                        );
                    }}
                >
                    {!!props.linkedMeasures?.length && (
                        <PivotItem
                            headerText="Linked Measures of Success"
                            itemKey="LinkedMeasures"
                            className={classNames.pivot}
                        >
                            <ScrollablePane
                                styles={{
                                    contentContainer: { paddingRight: 16 },
                                }}
                            >
                                <StatusReportLinkedMeasuresExpandablePanel
                                    {...props}
                                    onNavigate={props.onMeasureNavigate}
                                />
                            </ScrollablePane>
                        </PivotItem>
                    )}

                    {!!props.impliedTasks.length && (
                        <PivotItem
                            headerText="Implied Tasks"
                            itemKey="ImpliedTasks"
                            className={classNames.pivot}
                        >
                            <ScrollablePane
                                styles={{
                                    contentContainer: { paddingRight: 16 },
                                }}
                            >
                                <StatusReportImpliedTaskExpandablePanel
                                    {...props}
                                    onNavigate={props.onTaskNavigate}
                                    allowNavigation={
                                        !props.utcCompletedDate &&
                                        !props.utcDataDate
                                    }
                                />
                            </ScrollablePane>
                        </PivotItem>
                    )}

                    {!!props.linkedSpecifiedTasksAndImplied.length && (
                        <PivotItem
                            headerText="Linked Tasks"
                            itemKey="LinkedTasks"
                            className={classNames.pivot}
                        >
                            <ScrollablePane
                                styles={{
                                    contentContainer: { paddingRight: 16 },
                                }}
                            >
                                <StatusReportLinkedTasksExpandablePanel
                                    {...props}
                                    onNavigate={props.onTaskNavigate}
                                    isReadOnly={props.isReadOnly}
                                    missionAccess={props.missionAccess}
                                />
                            </ScrollablePane>
                        </PivotItem>
                    )}
                </Pivot>
            </div>
        </Modal>
    );
}

export function StatusReportLinkedMeasuresExpandablePanel(props: {
    linkedMeasures: GetMeasuresForStatusReportQuery['measures'];
    utcDataDate: string | null;
    utcCompletedDate: string | null;
    reportPeriodType: ReportPeriodTypes | null;
    reportPeriod: number | null;
    fyStartDate: string;
    onNavigate: (measureId: string) => void;
}): JSX.Element {
    const { currentTheme } = useThemes();

    const measures = (props.linkedMeasures || []).sort(sorters.sequenceSorter);

    const classNames = mergeStyleSets({
        container: {
            paddingTop: 16,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
        measure: {
            backgroundColor: currentTheme.palette.neutralLighterAlt,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: currentTheme.palette.neutralLight,
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
    });

    return (
        <div className={classNames.container}>
            {measures?.map((m) => {
                return (
                    <div key={m.id} className={classNames.measure}>
                        <Text variant="medium">{m.name}</Text>

                        <StatusReportGridMeasureDetail
                            allowNavigation={
                                !props.utcCompletedDate && !props.utcDataDate
                            }
                            forDateTime={
                                props.utcDataDate || props.utcCompletedDate
                            }
                            fyStartDate={props.fyStartDate}
                            reportPeriodType={props.reportPeriodType}
                            reportPeriod={props.reportPeriod}
                            onNavigate={() => props.onNavigate(m.id || '')}
                            measure={m}
                            hideLabel
                        />
                    </div>
                );
            })}
        </div>
    );
}

function TaskStatusIcon(props: {
    task: TaskWithStatus;
    statusDate: string;
}): JSX.Element {
    const status = useTaskStatus(props.task, props.statusDate);

    return (
        <Icon
            iconName="SquareShapeSolid"
            title={status?.text || undefined}
            styles={{
                root: {
                    color: status?.colour,
                },
            }}
        />
    );
}

export function StatusReportImpliedTaskExpandablePanel(props: {
    impliedTasks: GetTasksForStatusReportQuery['tasks'];
    fyStartDate: string;
    reportDate?: string;
    allowNavigation: boolean;
    onNavigate: (taskId: string) => void;
    utcDataDate: string | null;
    utcCompletedDate: string | null;
}): JSX.Element {
    const { formatTaskPercentage } = useFormatters();

    const { currentTheme } = useThemes();

    const classNames = mergeStyleSets({
        container: {
            paddingTop: 16,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
        impliedTask: {
            backgroundColor: currentTheme.palette.neutralLighterAlt,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: currentTheme.palette.neutralLight,
            padding: 16,
        },
    });

    const impliedTasks = (props.impliedTasks || []).map((t) => {
        return {
            ...t,
            resourcedFromTask: null, // This isn't needed for status reports.
        };
    });

    return (
        <div className={classNames.container}>
            {impliedTasks.map((t) => {
                return (
                    <div className={classNames.impliedTask} key={t.id}>
                        <Stack horizontal tokens={{ childrenGap: 4 }}>
                            <Stack.Item
                                align="center"
                                grow={5}
                                style={{ flexBasis: 0 }}
                            >
                                <Stack horizontal tokens={{ childrenGap: 8 }}>
                                    <TaskStatusIcon
                                        task={t}
                                        statusDate={
                                            props.utcDataDate ||
                                            props.utcCompletedDate ||
                                            ''
                                        }
                                    />
                                    <Text variant="small">
                                        {props.allowNavigation && t.id ? (
                                            <Link
                                                onClick={() =>
                                                    props.onNavigate(t.id || '')
                                                }
                                            >
                                                {t?.name}
                                            </Link>
                                        ) : (
                                            t?.name
                                        )}
                                    </Text>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item
                                align="center"
                                grow={1}
                                style={{
                                    flexBasis: 0,
                                    textAlign: 'right',
                                }}
                            >
                                <Text variant="small">
                                    {formatTaskPercentage(t.percentComplete)}
                                </Text>
                            </Stack.Item>
                            <Stack.Item
                                grow={6}
                                style={{
                                    flexBasis: 0,
                                    paddingTop: 8,
                                    display: 'flex',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                }}
                                align="center"
                            >
                                <TaskGanttBar
                                    task={t}
                                    fyStartDate={props.fyStartDate}
                                    statusDate={props.reportDate}
                                />
                            </Stack.Item>
                        </Stack>
                    </div>
                );
            })}
        </div>
    );
}

function StatusReportFactCardShimmerElements(props: {
    type: 'Task' | 'Measure';
}): JSX.Element {
    return (
        <React.Fragment>
            <ShimmerElementsGroup
                width="100%"
                shimmerElements={[
                    {
                        type: ShimmerElementType.gap,
                        height: 5,
                        width: '100%',
                    },
                ]}
            />
            <ShimmerElementsGroup
                width="100%"
                shimmerElements={[
                    {
                        type: ShimmerElementType.line,
                        height: 20,
                        width: '100%',
                    },
                ]}
            />
            <ShimmerElementsGroup
                width="100%"
                shimmerElements={[
                    {
                        type: ShimmerElementType.gap,
                        height: 8,
                        width: '100%',
                    },
                ]}
            />
            <ShimmerElementsGroup
                width="100%"
                shimmerElements={[
                    {
                        type: ShimmerElementType.line,
                        height: props.type === 'Task' ? 60 : 32,
                        width: '100%',
                    },
                ]}
            />
            <ShimmerElementsGroup
                width="100%"
                shimmerElements={[
                    {
                        type: ShimmerElementType.gap,
                        height: 16,
                        width: '100%',
                    },
                ]}
            />
            <ShimmerElementsGroup
                width="100%"
                shimmerElements={[
                    {
                        type: ShimmerElementType.line,
                        height: 16,
                        width: '100%',
                    },
                ]}
            />
        </React.Fragment>
    );
}
