import React from 'react';

import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import { ApolloError } from '@apollo/client';

export function ErrorMessageBar(props: {
    error: Error | null | undefined;
}): JSX.Element | null {
    const { error } = props;

    const reloadPage = () => {
        window.location.reload();
    };

    const isConcurrencyError = !!(error as ApolloError)?.graphQLErrors?.find(
        (e) => e.extensions && e.extensions['code'] === 'DB_UPDATE_CONCURRENCY'
    );

    if (!error) {
        return null;
    } else if (isConcurrencyError) {
        return (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                actions={
                    <div>
                        <MessageBarButton onClick={reloadPage}>
                            Reload
                        </MessageBarButton>
                    </div>
                }
            >
                <span>
                    Another user has performed an update. Your changes were not
                    saved.
                </span>
            </MessageBar>
        );
    } else {
        return (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
            >
                <span>{error.message}</span>
            </MessageBar>
        );
    }
}
