import { TaskStatusColours } from '../Colours';
import {
    TaskStatusNames,
    taskStatusUtil,
    TaskWithStatus,
} from '../data/extendedTypes';
import { useStateContext } from '../services/contextProvider';

export const useTaskSummary = (
    impliedTasks: TaskWithStatus[],
    statusDate?: string | null
): {
    status: TaskStatusNames;
    count: number;
    name: string;
    colour: string;
}[] => {
    const { currentFinancialYearCode } = useStateContext();

    const status = impliedTasks.map((it) =>
        taskStatusUtil.GetTaskStatus(it, currentFinancialYearCode, statusDate)
    );

    const results: {
        status: TaskStatusNames;
        count: number;
        name: string;
        colour: string;
    }[] = [
        {
            status: TaskStatusNames.NotStarted,
            count: status.filter((s) => s === TaskStatusNames.NotStarted)
                .length,
            name: 'Not Started',
            colour: TaskStatusColours.notStarted,
        },
        {
            status: TaskStatusNames.OffTarget,
            count: status.filter((s) => s === TaskStatusNames.OffTarget).length,
            name: 'Off Target',
            colour: TaskStatusColours.offTarget,
        },
        {
            status: TaskStatusNames.OnTarget,
            count: status.filter((s) => s === TaskStatusNames.OnTarget).length,
            name: 'On Target',
            colour: TaskStatusColours.onTarget,
        },
        {
            status: TaskStatusNames.CompletedLate,
            count: status.filter((s) => s === TaskStatusNames.CompletedLate)
                .length,
            name: 'Completed Late',
            colour: TaskStatusColours.completedLate,
        },
        {
            status: TaskStatusNames.CompletedOnTime,
            count: status.filter((s) => s === TaskStatusNames.CompletedOnTime)
                .length,
            name: 'Completed On Time',
            colour: TaskStatusColours.completedOnTime,
        },
        {
            status: TaskStatusNames.Postponed,
            count: status.filter((s) => s === TaskStatusNames.Postponed).length,
            name: 'Postponed',
            colour: TaskStatusColours.postponed,
        },
        {
            status: TaskStatusNames.Cancelled,
            count: status.filter((s) => s === TaskStatusNames.Cancelled).length,
            name: 'Cancelled',
            colour: TaskStatusColours.cancelled,
        },
        {
            status: TaskStatusNames.AtRisk,
            count: status.filter((s) => s === TaskStatusNames.AtRisk).length,
            name: 'At Risk',
            colour: TaskStatusColours.atRisk,
        },
    ];

    return results;
};
