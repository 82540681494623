import React, { FormEvent, useState } from 'react';
import {
    Checkbox,
    DefaultButton,
    Dialog,
    DialogType,
    Icon,
    MessageBar,
    MessageBarType,
    Persona,
    PersonaSize,
    PrimaryButton,
    Spinner,
    Stack,
} from '@fluentui/react';
import { useStateContext } from '../services/contextProvider';
import {
    refetchGetMeasureQuery,
    refetchGetMissionMeasuresQuery,
    refetchGetMissionQuery,
    TransferMeasureMutation,
    useGetMeasureQuery,
    useTransferMeasureMutation,
} from '../data/types';
import { MissionPicker } from './MissionPicker';
import { photoService } from '../services/photo.service';
import { MutationUpdaterFn, PureQueryOptions } from '@apollo/client';

export type MeasureTransferDialogProps = {
    measureId: string | null | undefined;
    hidden: boolean;
    onDismiss: () => void;
    onMeasureTransferred: () => void;
};

export function MeasureTransferDialog(
    props: MeasureTransferDialogProps
): JSX.Element {
    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const [targetMissionId, setTargetMissionId] = useState<string | null>(null);

    const [keepLinkedCopy, setKeepLinkedCopy] = useState(false);

    const { data } = useGetMeasureQuery({
        skip: !props.measureId || props.hidden,
        variables: {
            tenantId: currentTenantId || '',
            id: props.measureId || '',
        },
    });

    const [transferMeasure, { loading: isTransferring, error: transferError }] =
        useTransferMeasureMutation();

    const currentMission = data?.measure?.isLinked
        ? data.measure.linkedFromMeasure?.mission
        : data?.measure?.mission;

    const handleMissionPickerChange = (missions: { id: string }[]): void => {
        const selectedId = missions.length > 0 ? missions[0].id : null;
        if (selectedId !== currentMission?.id) {
            setTargetMissionId(selectedId);
        }
    };

    const handleTransferButtonClick = async () => {
        if (!targetMissionId || !props.measureId || !currentTenantId) {
            return;
        }

        const cacheUpdater: MutationUpdaterFn<TransferMeasureMutation> = (
            cache,
            mutationResult
        ) => {
            const deletedObject = mutationResult.data?.measureTransfer;
            if (deletedObject?.utcDeleted) {
                cache.evict({ id: cache.identify(deletedObject) });
                cache.gc();
            }
        };

        const refetchMissionIds = [currentMission?.id, targetMissionId];

        await transferMeasure({
            variables: {
                tenantId: currentTenantId,
                measureId: props.measureId,
                targetMissionId: targetMissionId,
                keepLinkedCopy: keepLinkedCopy,
            },
            update: cacheUpdater,
            refetchQueries: (result) => {
                const refetch: PureQueryOptions[] = refetchMissionIds.map(
                    (id) => {
                        return refetchGetMissionMeasuresQuery({
                            tenantId: currentTenantId || '',
                            missionId: id || '',
                        });
                    }
                );

                refetch.push(
                    refetchGetMissionQuery({
                        tenantId: currentTenantId || '',
                        missionId: currentMission?.id || '',
                    })
                );

                if (
                    !result.data?.measureTransfer?.utcDeleted &&
                    props.measureId
                ) {
                    refetch.push(
                        refetchGetMeasureQuery({
                            tenantId: currentTenantId || '',
                            id: props.measureId,
                        })
                    );
                }
                return refetch;
            },
        });

        props.onMeasureTransferred();
    };

    const handleKeepLinkedCopyChange = (_ev?: FormEvent, checked?: boolean) =>
        setKeepLinkedCopy(checked || false);

    return (
        <Dialog
            hidden={props.hidden}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Transfer Ownership',
                showCloseButton: true,
                subText: data?.measure?.name || '',
            }}
            modalProps={{
                isBlocking: true,
            }}
            onDismiss={props.onDismiss}
        >
            <Stack tokens={{ childrenGap: 16 }}>
                {transferError?.message && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {transferError.message}
                    </MessageBar>
                )}

                {currentMission && !currentMission.rights.write && (
                    <MessageBar messageBarType={MessageBarType.warning}>
                        You do not have permission to transfer this measure
                    </MessageBar>
                )}

                {currentMission?.rights.write && (
                    <Stack horizontal>
                        <Stack.Item align="end">
                            <Persona
                                size={PersonaSize.size24}
                                imageUrl={photoService.getImageUrl(
                                    currentMission?.userId
                                )}
                                text={currentMission?.owner || ''}
                                styles={{ root: { paddingBottom: 8 } }}
                                hidePersonaDetails
                            />
                        </Stack.Item>
                        <Stack.Item align="end">
                            <Icon
                                iconName="DoubleChevronRight"
                                styles={{ root: { padding: 8 } }}
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <MissionPicker
                                selectedMissions={[]}
                                financialYearCode={currentFinancialYearCode}
                                onChange={handleMissionPickerChange}
                                itemLimit={1}
                                label=""
                                showOnlyWritable
                                placeholder="Search for a target mission..."
                                disabled={isTransferring}
                            />
                        </Stack.Item>
                    </Stack>
                )}

                {currentMission?.rights.write && (
                    <Checkbox
                        label="Keep a linked copy"
                        checked={keepLinkedCopy}
                        disabled={isTransferring}
                        onChange={handleKeepLinkedCopyChange}
                    />
                )}

                {currentMission?.rights.write && (
                    <div
                        style={{
                            display: 'flex',
                            gap: 8,
                            alignItems: 'center',
                        }}
                    >
                        {isTransferring && (
                            <div>
                                <Spinner />
                            </div>
                        )}
                        <div style={{ flexGrow: '1', textAlign: 'end' }}>
                            <PrimaryButton
                                text="Transfer"
                                disabled={!targetMissionId || isTransferring}
                                onClick={handleTransferButtonClick}
                            />
                        </div>
                        <div>
                            <DefaultButton
                                onClick={props.onDismiss}
                                disabled={isTransferring}
                                text="Cancel"
                            />
                        </div>
                    </div>
                )}
            </Stack>
        </Dialog>
    );
}
