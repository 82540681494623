import React from 'react';
import TaskProgressIndicator from './TaskProgressIndicator';
import { Access, TaskWithStatus } from '../data/extendedTypes';
import { useTaskEditRules } from '../hooks/useTaskEditRules';
import { DetailsListCellItemContainer } from './shared/DetailsListCellItemContainer';

export function ImpliedTaskListProgressColumn(props: {
    task: TaskWithStatus & {
        utcAccepted: string | null;
        utcRejected: string | null;
        utcAtRisk: string | null;
        percentComplete: number;
        isDuplicate: boolean;
        missionId: string | null;
        parentTaskId: string | null;
        resourcedFromTaskId: string | null;
        isPercentageCompleteFromResources: boolean;
        isPercentageCompleteFromSubTasks: boolean;
        tags: {
            id: string | null;
            name: string | null;
        }[];
        resource: {
            userId: string | null;
        } | null;
        resourcedFromTask: {
            mission: {
                userId: string | null;
                rights: {
                    write: boolean;
                };
            } | null;
        } | null;
    };
    mission: {
        rights: Access;
    } | null;
    allowInlineEdit?: boolean;
    onChange?: (taskUpdates: {
        utcPostponed: string | null;
        utcCancelled: string | null;
        utcAtRisk: string | null;
        percentComplete: number;
        done: string | null;
    }) => void;
    isBusy?: boolean;
}): JSX.Element {
    const { task, mission } = props;

    const taskEditRules = useTaskEditRules(task, mission?.rights);

    return (
        <DetailsListCellItemContainer>
            <TaskProgressIndicator
                task={task}
                showStatus={true}
                hidePercentage={false}
                allowDoneDateInlineEdit={
                    props.allowInlineEdit && !taskEditRules.isDoneDateReadOnly
                }
                allowPercentageInlineEdit={
                    props.allowInlineEdit && !taskEditRules.isPercentageReadOnly
                }
                allowStatusInlineEdit={
                    props.allowInlineEdit && !taskEditRules.isStatusReadOnly
                }
                onChange={props.onChange}
                isBusy={props.isBusy}
            />
        </DetailsListCellItemContainer>
    );
}
