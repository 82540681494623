import React, { useCallback, useEffect, useMemo, useState } from 'react';
import compact from 'lodash/compact';

import {
    Stack,
    DatePicker,
    PanelType,
    Label,
    Link,
    TooltipHost,
    Icon,
    PrimaryButton,
    Pivot,
    PivotItem,
    IPivotItemProps,
    MessageBarType,
    MessageBar,
    Panel,
    IPanelProps,
    IPanelHeaderRenderer,
} from '@fluentui/react';

import {
    Currency,
    MeasureTypes,
    Multipliers,
    useUpdateMeasureAsOfMutation,
    MeasureAsOf,
    SeriesValue,
    refetchGetMeasureValueHistoryQuery,
    useGetMeasureLazyQuery,
    useGetFinancialYearQuery,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { ValueInput, InputShimmer } from './inputs';
import {
    MeasureSeriesNames,
    MeasureValueHistoryItem,
} from '../data/extendedTypes';
import { MeasureValuesHistoryList } from './MeasureValuesHistoryList';
import dayjs from 'dayjs';
import Phasing from './Phasing';
import { usePhasing } from '../hooks/usePhasing';
import DeleteModal from './shared/DeleteModal';
import { DefaultProps } from '../DefaultProps';
import { NetworkStatus } from '@apollo/client';
import { useClipboard } from 'use-clipboard-copy';
import { MeasureCommandBar } from './EditMeasureCommandBar';
import { MeasureCopyDialog } from './MeasureCopyDialog';
import { useLatestValueWarning } from '../hooks/useLatestValueWarning';
import { useActiveView } from '../hooks/useActiveView';
import { LegacyMeasureBar } from './LegacyMeasureBar';
import { useFeatures } from '../hooks/useFeatures';
import { generatePath, useParams } from 'react-router-dom';
import { paths } from '../services/navigation';

type EditMeasureActualPanelProps = {
    measureId: string | null | undefined;
    showPanel: boolean;
    onCancel: () => void;
    onEditMeasureButtonClick: () => void;
    onMeasureOverviewButtonClick: () => void;
    onMeasureLockToggleClick?: () => void;
};

export default function EditMeasureActualPanel(
    props: EditMeasureActualPanelProps
): JSX.Element {
    const { currentTenantId, currentFinancialYearCode, currentRoles } =
        useStateContext();

    const features = useFeatures();

    const params = useParams();

    useActiveView(props.showPanel ? 'MeasureValues' : null);

    const clipboard = useClipboard({
        copiedTimeout: 3000, // show copy message in milliseconds
    });

    const [isDateValid, setIsDateValid] = React.useState(true);
    const [isValueValid, setIsValueValid] = React.useState(false);
    const [currency, setCurrency] = useState<Currency>();
    const [multiplier, setMultiplier] = useState<Multipliers>();
    const [decimalPlaces, setDecimalPlaces] = useState<number>();
    const [measureType, setMeasureType] = useState<MeasureTypes>();
    const [isStatusLimited, setIsStatusLimited] = useState<boolean>(false);
    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);
    const [selectedAsOf, setSelectedAsOf] = useState<MeasureValueHistoryItem>();

    //const [loadedMeasure, setLoadedMeasure] = useState<MeasureQl>();
    const [measureAsOfInput, setMeasureAsOfInput] = useState<MeasureAsOf>();

    const [allowEdit, setAllowEdit] = useState<boolean>(false);

    const [asOfDate, setAsOfDate] = useState<string | null>(
        dayjs().format('YYYY-MM-DD')
    );

    // Default target value for input
    const [targetValue, setTargetValue] = useState<{
        decimalValue: number | null;
        stringValue: string | null;
        dateValue: string | null;
    }>({
        decimalValue: null,
        stringValue: null,
        dateValue: null,
    });

    // Default target value for input
    const [actualValue, setActualValue] = useState<{
        decimalValue: number | null;
        stringValue: string | null;
        dateValue: string | null;
    }>({
        decimalValue: null,
        stringValue: null,
        dateValue: null,
    });

    const [isMeasureCopyModalOpen, setIsMeasureCopyModalOpen] =
        React.useState(false);

    const { hasPhasing, getPhasingForDate } = usePhasing();

    const [getMeasureLazyQuery, { data, loading: isLoading, networkStatus }] =
        useGetMeasureLazyQuery({
            fetchPolicy: 'network-only',
        });

    const { data: fyData } = useGetFinancialYearQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: currentFinancialYearCode || '',
        },
    });

    const isAdmin = useMemo(
        () =>
            currentRoles.some((r) =>
                ['GlobalAdmin', 'ClientAdmin'].includes(r)
            ),
        [currentRoles]
    );

    const isLocked = useMemo(
        () =>
            (data?.measure?.tags || []).reduce(
                (value, tag) => value || tag.name === 'Locked',
                false
            ),
        [data?.measure]
    );

    const loadMeasure = useCallback(
        async (measureId: string): Promise<void> => {
            const result = await getMeasureLazyQuery({
                variables: {
                    tenantId: currentTenantId || '',
                    id: measureId,
                },
            });

            const loadedMeasure = result?.data?.measure;

            if (loadedMeasure) {
                setCurrency(loadedMeasure.currency || undefined);
                setDecimalPlaces(loadedMeasure.decimalPlaces);
                setMeasureType(loadedMeasure.measureType || undefined);
                setMultiplier(loadedMeasure.multiplier || Multipliers.None);
                setIsStatusLimited(loadedMeasure.isStatusLimited);

                const dayToday = dayjs();

                const phasingTarget = hasPhasing(
                    loadedMeasure.valueHistory || []
                )
                    ? getPhasingForDate(
                          loadedMeasure.valueHistory || [],
                          dayToday.toDate()
                      )
                    : null;

                const latestTarget =
                    phasingTarget ??
                    getValueFromAsOf(
                        loadedMeasure.lastAsOf,
                        MeasureSeriesNames.Target
                    );

                const latestActual = getValueFromAsOf(
                    loadedMeasure.lastAsOf,
                    MeasureSeriesNames.Actual
                );

                const input: MeasureAsOf = {
                    id: null,
                    measureId: loadedMeasure.id,
                    asOfDate: dayToday.format('YYYY-MM-DD'),
                    version: '',
                    values: [
                        {
                            id: null,
                            decimalValue: latestTarget.decimalValue,
                            stringValue: latestTarget.stringValue,
                            dateValue: latestTarget.dateValue,
                            calcId: null,
                            seriesType: {
                                id: null,
                                name: MeasureSeriesNames.Target,
                                calcSymbol: null,
                                defaultFormat: '',
                                sequence: null,
                            },
                            version: '',
                        },
                        {
                            id: null,
                            decimalValue: latestActual.decimalValue,
                            stringValue: latestActual.stringValue,
                            dateValue: latestActual.dateValue,
                            calcId: null,
                            seriesType: {
                                id: null,
                                name: MeasureSeriesNames.Actual,
                                calcSymbol: null,
                                defaultFormat: '',
                                sequence: null,
                            },
                            version: '',
                        },
                    ],
                };

                setMeasureAsOfInput(input);

                setTargetValue(latestTarget);

                setActualValue(latestActual);

                setAsOfDate(dayToday.format('YYYY-MM-DD'));

                const hasLockedTag = (loadedMeasure.tags || []).reduce(
                    (value, tag) => value || tag.name === 'Locked',
                    false
                );

                setAllowEdit(
                    loadedMeasure.mission?.rights.write === true &&
                        loadedMeasure.isCustom &&
                        (isAdmin || !hasLockedTag) &&
                        !loadedMeasure.isLinked
                );
            }
        },
        [
            currentTenantId,
            isAdmin,
            getMeasureLazyQuery,
            hasPhasing,
            getPhasingForDate,
        ]
    );

    const { hasWarning, warningMessage } = useLatestValueWarning(
        {
            frequencyPeriod: data?.measure?.frequencyPeriod,
            asOfDate: data?.measure?.lastAsOf?.asOfDate,
        },
        fyData?.financialYear
    );

    const valueHistory = useMemo(
        () => data?.measure?.valueHistory || [],
        [data?.measure?.valueHistory]
    );

    const getValueInputted = useCallback(
        (
            inputValues: SeriesValue[] | null | undefined,
            seriesName: MeasureSeriesNames
        ): SeriesValue | undefined => {
            return inputValues?.find((v) => v.seriesType?.name === seriesName);
        },
        []
    );

    const [updateMeasureAsOf, { loading: isSaving, error: saveError }] =
        useUpdateMeasureAsOfMutation({
            refetchQueries: [
                refetchGetMeasureValueHistoryQuery({
                    tenantId: currentTenantId || '',
                    id: props.measureId || '',
                    historyHasActual: false,
                    historySkip: 0,
                    historyTake: 12,
                }),
            ],
            onCompleted: () => {
                setTargetValue({
                    decimalValue: null,
                    stringValue: null,
                    dateValue: null,
                });

                setActualValue({
                    decimalValue: null,
                    stringValue: null,
                    dateValue: null,
                });
            },
        });

    useEffect(() => {
        if (!props.showPanel) {
            setTargetValue({
                decimalValue: null,
                stringValue: null,
                dateValue: null,
            });
            setActualValue({
                decimalValue: null,
                stringValue: null,
                dateValue: null,
            });

            setAsOfDate(dayjs().format('YYYY-MM-DD'));

            setMeasureAsOfInput(undefined);
            setCurrency(undefined);
            setDecimalPlaces(undefined);
            setMultiplier(undefined);
            setMeasureType(undefined);
            setAllowEdit(false);
        } else if (props.measureId) {
            loadMeasure(props.measureId);
        }
    }, [props, currentTenantId, loadMeasure]);

    useEffect(() => {
        const targetInput = getValueInputted(
            measureAsOfInput?.values,
            MeasureSeriesNames.Target
        );
        const actualInput = getValueInputted(
            measureAsOfInput?.values,
            MeasureSeriesNames.Actual
        );

        let isValid = false;
        if (
            targetInput &&
            (targetInput.dateValue ||
                targetInput.decimalValue !== null ||
                targetInput.stringValue)
        ) {
            isValid = true;
        }
        if (
            actualInput &&
            (actualInput.dateValue ||
                actualInput.decimalValue !== null ||
                actualInput.stringValue)
        ) {
            isValid = true;
        }
        setIsValueValid(isValid);
    }, [measureAsOfInput, getValueInputted]);

    const updateTargetFromPhasing = useCallback(
        (
            asOf: Date | null,
            inputtedValues: SeriesValue[] | null | undefined,
            history: MeasureValueHistoryItem[]
        ): void => {
            const phasingTarget =
                hasPhasing(history) && asOf
                    ? getPhasingForDate(history, new Date(asOf))
                    : null;

            if (
                phasingTarget?.decimalValue !== null &&
                phasingTarget?.decimalValue !== undefined
            ) {
                const targetInput = getValueInputted(
                    inputtedValues,
                    MeasureSeriesNames.Target
                );
                const actualInput = getValueInputted(
                    inputtedValues,
                    MeasureSeriesNames.Actual
                );

                if (
                    targetInput &&
                    actualInput &&
                    targetInput.decimalValue !== phasingTarget?.decimalValue
                ) {
                    setMeasureAsOfInput((curr) => {
                        return curr
                            ? {
                                  ...curr,
                                  values: [
                                      {
                                          ...targetInput,
                                          decimalValue:
                                              phasingTarget?.decimalValue,
                                          stringValue: null,
                                          dateValue: null,
                                      },
                                      actualInput,
                                  ],
                              }
                            : undefined;
                    });
                }

                setTargetValue({
                    decimalValue: phasingTarget.decimalValue,
                    dateValue: null,
                    stringValue: null,
                });
            }
        },
        [hasPhasing, getValueInputted, getPhasingForDate]
    );

    useEffect(() => {
        if (measureAsOfInput?.asOfDate) {
            updateTargetFromPhasing(
                dayjs(measureAsOfInput?.asOfDate).toDate(),
                measureAsOfInput?.values,
                valueHistory
            );
        }
    }, [
        valueHistory,
        measureAsOfInput?.asOfDate,
        measureAsOfInput?.values,
        updateTargetFromPhasing,
    ]);

    const addActualMeasure = async (): Promise<void> => {
        if (asOfDate && props.measureId && measureAsOfInput) {
            await updateMeasureAsOf({
                variables: {
                    tenantId: currentTenantId || '',
                    input: measureAsOfInput,
                },
            });

            await loadMeasure(props.measureId);
        }
    };

    const targetActualHistory = valueHistory?.filter((a) =>
        a.values?.some(
            (v) =>
                v.seriesType?.name === MeasureSeriesNames.Target ||
                v.seriesType?.name === MeasureSeriesNames.Actual
        )
    );

    const handleGetPhasingForDate = (
        asOfDate: string | null
    ): {
        decimalValue: number | null;
        stringValue: string | null;
        dateValue: string | null;
        formatStr: string | null;
    } | null => {
        return asOfDate
            ? getPhasingForDate(valueHistory || [], dayjs(asOfDate).toDate())
            : null;
    };

    const handleSyncTargetWithPhasingConfirm = async (asOfDate: string) => {
        const asOf = (valueHistory || []).find((h) => h.asOfDate === asOfDate);

        const phasedTarget = getPhasingForDate(
            valueHistory || [],
            dayjs(asOfDate).toDate()
        );

        if (!allowEdit || !asOf || phasedTarget?.decimalValue === null) {
            return;
        }

        await updateMeasureAsOf({
            awaitRefetchQueries: true,
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    id: asOf.id,
                    measureId: props.measureId || '',
                    asOfDate: asOf.asOfDate,
                    version: asOf.version,
                    values: (asOf.values || []).map((v) => {
                        const decimalValue =
                            v.seriesType?.name === MeasureSeriesNames.Target
                                ? phasedTarget?.decimalValue
                                : v.decimalValue;
                        return {
                            id: v.id,
                            seriesType: v.seriesType,
                            calcId: v.calcId,
                            version: v.version,
                            decimalValue: decimalValue,
                            dateValue: v.dateValue,
                            stringValue: v.stringValue,
                        } as SeriesValue;
                    }),
                },
            },
        });
    };

    const handleDeleteValueClick = (asOfId: string): void => {
        setSelectedAsOf(valueHistory?.find((h) => h.id === asOfId));
        setIsConfirmingDelete(true);
    };

    const getValueFromAsOf = (
        item: MeasureValueHistoryItem | null,
        seriesName: MeasureSeriesNames
    ): {
        decimalValue: number | null;
        stringValue: string | null;
        dateValue: string | null;
    } => {
        const seriesValues = item?.values?.find(
            (v) => v.seriesType?.name === seriesName
        );

        return {
            decimalValue:
                seriesValues !== undefined ? seriesValues.decimalValue : null,
            stringValue:
                seriesValues !== undefined ? seriesValues.stringValue : null,
            dateValue:
                seriesValues !== undefined ? seriesValues.dateValue : null,
        };
    };

    const selectAsOfForEdit = (editAsOf: MeasureValueHistoryItem) => {
        const editTarget = getValueFromAsOf(
            editAsOf,
            MeasureSeriesNames.Target
        );

        const editActual = getValueFromAsOf(
            editAsOf,
            MeasureSeriesNames.Actual
        );

        const phasingTarget =
            editAsOf.asOfDate && hasPhasing(valueHistory || [])
                ? getPhasingForDate(
                      valueHistory || [],
                      dayjs(editAsOf.asOfDate).toDate()
                  )
                : null;

        setAsOfDate(editAsOf.asOfDate);

        setTargetValue(
            phasingTarget?.decimalValue ? phasingTarget : editTarget
        );

        setActualValue(editActual);

        setMeasureAsOfInput({
            id: editAsOf.id,
            asOfDate: editAsOf.asOfDate,
            version: editAsOf.version,
            measureId: props.measureId || '',
            values: [
                {
                    id: null,
                    decimalValue:
                        phasingTarget?.decimalValue ?? editTarget.decimalValue,
                    stringValue: editTarget.stringValue,
                    dateValue: editTarget.dateValue,
                    calcId: null,
                    seriesType: {
                        id: null,
                        name: MeasureSeriesNames.Target,
                        calcSymbol: null,
                        defaultFormat: '',
                        sequence: null,
                    },
                    version: '',
                },
                {
                    id: null,
                    decimalValue: editActual.decimalValue,
                    stringValue: editActual.stringValue,
                    dateValue: editActual.dateValue,
                    calcId: null,
                    seriesType: {
                        id: null,
                        name: MeasureSeriesNames.Actual,
                        calcSymbol: null,
                        defaultFormat: '',
                        sequence: null,
                    },
                    version: '',
                },
            ],
        });
    };

    const handleEditValueClick = (asOfId: string): void => {
        const editAsOf = (valueHistory || []).find((h) => h.id === asOfId);

        if (!editAsOf) {
            return;
        }

        selectAsOfForEdit(editAsOf);
    };

    const isMeasurePhased = hasPhasing(valueHistory);
    const isMeasurePhasedAtSelectedDate =
        isMeasurePhased &&
        asOfDate &&
        getPhasingForDate(valueHistory, dayjs(asOfDate).toDate())
            ?.decimalValue !== null;

    const allowHistoryDelete = !data?.measure?.isLinked && allowEdit;

    const allowHistoryEdit = allowEdit;

    const deleteAction = async (): Promise<void> => {
        if (!selectedAsOf?.id || !props.measureId) {
            return;
        }

        await updateMeasureAsOf({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    id: selectedAsOf.id,
                    measureId: props.measureId || '',
                    asOfDate: selectedAsOf.asOfDate,
                    version: selectedAsOf.version,
                    values: (selectedAsOf.values || [])
                        .filter(
                            (v) =>
                                v.seriesType?.name ===
                                    MeasureSeriesNames.Target ||
                                v.seriesType?.name === MeasureSeriesNames.Actual
                        )
                        .map((v) => {
                            return {
                                id: v.id,
                                seriesType: v.seriesType,
                                calcId: v.calcId,
                                version: v.version,
                                decimalValue: null,
                                dateValue: null,
                                stringValue: null,
                            } as SeriesValue;
                        }),
                },
            },
        });

        setIsConfirmingDelete(false);

        await loadMeasure(props.measureId);
    };

    const handleTargetInputChange = (
        decimalValue: number | null,
        stringValue: string | null,
        dateValue: string | null
    ): void => {
        const targetInput = getValueInputted(
            measureAsOfInput?.values,
            MeasureSeriesNames.Target
        );
        const actualInput = getValueInputted(
            measureAsOfInput?.values,
            MeasureSeriesNames.Actual
        );

        if (measureAsOfInput && targetInput && actualInput) {
            setMeasureAsOfInput({
                ...measureAsOfInput,
                values: [
                    {
                        ...targetInput,
                        decimalValue: decimalValue,
                        stringValue: stringValue,
                        dateValue: dateValue,
                    },
                    actualInput,
                ],
            });
        }
    };

    const handleActualInputChange = (
        decimalValue: number | null,
        stringValue: string | null,
        dateValue: string | null
    ): void => {
        const targetInput = getValueInputted(
            measureAsOfInput?.values,
            MeasureSeriesNames.Target
        );
        const actualInput = getValueInputted(
            measureAsOfInput?.values,
            MeasureSeriesNames.Actual
        );

        if (measureAsOfInput && targetInput && actualInput) {
            setMeasureAsOfInput({
                ...measureAsOfInput,
                values: [
                    targetInput,
                    {
                        ...actualInput,
                        decimalValue: decimalValue,
                        stringValue: stringValue,
                        dateValue: dateValue,
                    },
                ],
            });
        }
    };

    const handleSelectDate = (date: Date | null | undefined): void => {
        const formatted = date ? dayjs(date).format('YYYY-MM-DD') : null;

        setAsOfDate(formatted);

        setIsDateValid(!!date);

        // if there is an existing as of at this date, select it.
        const editAsOf = (valueHistory || []).find(
            (h) => h.asOfDate === formatted
        );

        if (editAsOf) {
            selectAsOfForEdit(editAsOf);
        } else if (measureAsOfInput) {
            setMeasureAsOfInput({
                ...measureAsOfInput,
                id: null,
                asOfDate: formatted,
            });
        }
    };

    const handleOnPhasingChanged = async () => {
        if (props.measureId) {
            await loadMeasure(props.measureId);
        }
    };

    const handleDeleteModalDismiss = () => {
        setIsConfirmingDelete(false);
    };

    const formDisabled =
        isLoading || isSaving || networkStatus === NetworkStatus.refetch;

    const form = (
        <Stack tokens={{ childrenGap: 8 }}>
            {hasWarning && (
                <MessageBar
                    messageBarType={MessageBarType.warning}
                    messageBarIconProps={{
                        iconName: 'AlertSolid',
                    }}
                    isMultiline={true}
                >
                    <span>{warningMessage}</span>
                </MessageBar>
            )}

            <Stack tokens={{ childrenGap: 8 }} horizontal>
                <Stack.Item grow={1} style={{ flexBasis: 0 }}>
                    <InputShimmer isDataLoaded={!isLoading}>
                        <DatePicker
                            {...DefaultProps.DatePickerProps}
                            label="As Of"
                            value={
                                asOfDate ? dayjs(asOfDate).toDate() : undefined
                            }
                            onSelectDate={handleSelectDate}
                            disabled={formDisabled}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                        />
                    </InputShimmer>
                </Stack.Item>
                <Stack.Item grow={2} style={{ flexBasis: 0 }}>
                    <div />
                </Stack.Item>
            </Stack>
            <Stack tokens={{ childrenGap: 8 }} horizontal>
                <Stack.Item grow={1} style={{ flexBasis: 0 }}>
                    <InputShimmer isDataLoaded={!isLoading}>
                        <Stack horizontal tokens={{ childrenGap: 4 }}>
                            <Label htmlFor="targetValueInput">Target</Label>
                            {isMeasurePhasedAtSelectedDate && (
                                <TooltipHost content="When phased targets exist, you will not be able to manually enter target values.">
                                    <Icon
                                        style={{ cursor: 'pointer' }}
                                        iconName="Info"
                                        aria-label="Rollup Info"
                                    />
                                </TooltipHost>
                            )}
                        </Stack>
                        {measureType && (
                            <ValueInput
                                id="targetValueInput"
                                measureType={measureType}
                                disabled={
                                    isMeasurePhasedAtSelectedDate ||
                                    formDisabled
                                }
                                multiplier={multiplier || Multipliers.None}
                                currencySymbol={currency?.symbol}
                                decimalPlaces={decimalPlaces}
                                value={targetValue}
                                onChange={handleTargetInputChange}
                            />
                        )}
                    </InputShimmer>
                </Stack.Item>
                <Stack.Item grow={1} style={{ flexBasis: 0 }}>
                    <InputShimmer isDataLoaded={!isLoading}>
                        {measureType && (
                            <ValueInput
                                label="Actual"
                                measureType={measureType}
                                disabled={formDisabled}
                                multiplier={multiplier || Multipliers.None}
                                currencySymbol={currency?.symbol}
                                decimalPlaces={decimalPlaces}
                                value={actualValue}
                                onChange={handleActualInputChange}
                            />
                        )}
                    </InputShimmer>
                </Stack.Item>
                <Stack.Item grow={1} style={{ flexBasis: 0 }} align="end">
                    <PrimaryButton
                        text="Save"
                        disabled={formDisabled || !isDateValid || !isValueValid}
                        onClick={addActualMeasure}
                    />
                </Stack.Item>
            </Stack>
        </Stack>
    );

    const handleCopyMeasureIdButtonClick = () =>
        clipboard.copy(data?.measure?.id || '');

    // const menuItems: IContextualMenuItem[] = [];

    // if (allowEdit && data?.measure) {
    //     menuItems.push({
    //         key: 'Edit',
    //         text: 'Edit measure',
    //         iconProps: { iconName: 'Edit' },
    //         onClick: props.onEditMeasureButtonClick
    //     });
    // }

    // if (isAdmin && data?.measure?.mission?.rights.import) {
    //     menuItems.push({
    //         key: 'CopyMeasureId',
    //         text: 'Copy measure ID to clipboard',
    //         iconProps: { iconName: 'ClipboardSolid' },
    //         onClick: copyIdToClipboard
    //     });
    // }

    const handleLinkButtonClick = () => setIsMeasureCopyModalOpen(true);

    const measure = data?.measure;

    const linkedToMissions = compact(
        measure?.linkedMeasures?.filter((m) => m.isLinked).map((m) => m.mission)
    ).filter(
        (value, index, self) =>
            self.findIndex((s) => value.id === s.id) === index
    );

    const onRenderNavigationContent = (): JSX.Element => (
        <div style={{ width: '100%', marginTop: 6 }}>
            {!!measure && (
                <MeasureCommandBar
                    measureId={props.measureId}
                    activeButton="TargetsAndActuals"
                    missionAccess={data?.measure?.mission?.rights}
                    isLinked={measure.isLinked}
                    isCustom={measure.isCustom}
                    onOverviewButtonClick={props.onMeasureOverviewButtonClick}
                    onEditMeasureButtonClick={props.onEditMeasureButtonClick}
                    onCloseButtonClick={props.onCancel}
                    onSendLinkedButtonClick={handleLinkButtonClick}
                    onCopyMeasureIdButtonClick={handleCopyMeasureIdButtonClick}
                    onMeasureLockToggleClick={props.onMeasureLockToggleClick}
                    isLocked={isLocked}
                />
            )}
        </div>
    );

    const handleRenderPanelHeader = (
        panelProps?: IPanelProps,
        defaultRender?: IPanelHeaderRenderer
    ): JSX.Element | null => {
        const href =
            measure && !measure.isCustom
                ? generatePath(paths.missionBuilderMeasure, {
                      ...params,
                      measureId: measure.id,
                      missionId: measure.missionId,
                  })
                : undefined;

        return href ? (
            <Link href={href}>
                {defaultRender && defaultRender(panelProps)}
            </Link>
        ) : (
            <div>{defaultRender && defaultRender(panelProps)}</div>
        );
    };

    return (
        <React.Fragment>
            <Panel
                onDismiss={props.onCancel}
                isOpen={props.showPanel}
                headerText={measure ? measure.name || '' : 'Loading...'}
                type={PanelType.medium}
                onRenderNavigationContent={onRenderNavigationContent}
                onRenderHeader={handleRenderPanelHeader}
                styles={{
                    commands: {
                        zIndex: 1,
                    },
                    scrollableContent: {
                        // Always show the scrollbar
                        height: '100%',
                        overflowY: 'scroll',
                    },
                }}
            >
                <Pivot styles={{ root: { marginTop: 8 } }}>
                    <PivotItem headerText="Target & Actuals">
                        <Stack
                            tokens={{ childrenGap: 8 }}
                            styles={{ root: { marginTop: 8 } }}
                        >
                            {clipboard.copied && (
                                <Stack.Item>
                                    <MessageBar
                                        messageBarType={MessageBarType.success}
                                    >
                                        <span>
                                            {data?.measure?.id ?? ''} copied to
                                            clipboard!
                                        </span>
                                    </MessageBar>
                                </Stack.Item>
                            )}

                            {saveError && (
                                <MessageBar
                                    messageBarType={MessageBarType.error}
                                    isMultiline={true}
                                >
                                    <span>{saveError.message}</span>
                                </MessageBar>
                            )}

                            <Stack.Item>
                                {measure?.isCustom &&
                                    !measure.isLinked &&
                                    features.Measures2023Upgrade && (
                                        <div style={{ marginTop: 12 }}>
                                            <LegacyMeasureBar
                                                measure={measure}
                                                onUpgradeCompleted={
                                                    props.onCancel
                                                }
                                            />
                                        </div>
                                    )}
                            </Stack.Item>

                            <Stack.Item>
                                {allowEdit && <div>{form}</div>}
                            </Stack.Item>
                            <Stack.Item>
                                {data?.measure && (
                                    <MeasureValuesHistoryList
                                        valueHistory={targetActualHistory || []}
                                        isDataLoading={isLoading}
                                        isStatusLimited={isStatusLimited}
                                        frequencyPeriod={
                                            data?.measure?.frequencyPeriod
                                        }
                                        isCustomMeasure={data?.measure.isCustom}
                                        onDeleteValueClick={
                                            handleDeleteValueClick
                                        }
                                        onEditValueClick={handleEditValueClick}
                                        allowDelete={allowHistoryDelete}
                                        allowEdit={allowHistoryEdit}
                                        onGetPhasingForDate={
                                            handleGetPhasingForDate
                                        }
                                        onSyncTargetWithPhasingConfirm={
                                            handleSyncTargetWithPhasingConfirm
                                        }
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </PivotItem>
                    {!isLoading &&
                        measure?.isCustom &&
                        (measureType === MeasureTypes.Numeric ||
                            measureType === MeasureTypes.Currency ||
                            measureType === MeasureTypes.Percentage) && (
                            <PivotItem
                                headerText={
                                    isMeasurePhased && allowEdit
                                        ? 'Edit phasing'
                                        : 'Phase Targets'
                                }
                                onRenderItemLink={(
                                    link?: IPivotItemProps,
                                    defaultRenderer?: (
                                        link?: IPivotItemProps
                                    ) => JSX.Element | null
                                ) => {
                                    if (!link || !defaultRenderer) {
                                        return null;
                                    }

                                    return (
                                        <Stack
                                            horizontal
                                            tokens={{ childrenGap: 4 }}
                                        >
                                            {defaultRenderer({
                                                ...link,
                                                itemIcon: undefined,
                                            })}
                                            <TooltipHost content="Preset your targets for the year.">
                                                <Icon
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    iconName="Info"
                                                    aria-label="Rollup Info"
                                                />
                                            </TooltipHost>
                                        </Stack>
                                    );
                                }}
                            >
                                <Stack styles={{ root: { marginTop: 8 } }}>
                                    <Phasing
                                        isLoading={isLoading}
                                        measure={data?.measure}
                                        onPhasingChanged={
                                            handleOnPhasingChanged
                                        }
                                        allowEdit={allowEdit}
                                    />
                                </Stack>
                            </PivotItem>
                        )}
                </Pivot>
                <DeleteModal
                    activeViewName="MeasureValueDelete"
                    isOpen={isConfirmingDelete}
                    onDismiss={handleDeleteModalDismiss}
                    message={
                        'Are you sure you want to delete this value entry?'
                    }
                    deleteAction={deleteAction}
                    isDeleting={isSaving}
                    error={saveError}
                />
                <MeasureCopyDialog
                    tenantId={currentTenantId || ''}
                    measureId={props.measureId}
                    hidden={!isMeasureCopyModalOpen}
                    onDismiss={() => setIsMeasureCopyModalOpen(false)}
                    onMeasuresCreated={() => {
                        setIsMeasureCopyModalOpen(false);
                    }}
                    financialYearCode={currentFinancialYearCode || null}
                    alreadyLinkedToMissions={linkedToMissions}
                />
            </Panel>
        </React.Fragment>
    );
}
