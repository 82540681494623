import React, { useState } from 'react';
import {
    IPersonaProps,
    ValidationState,
    IBasePickerSuggestionsProps,
    IContextualMenuItem,
    Icon,
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton,
    PersonaPresence,
    ListPeoplePicker,
    Persona,
    PersonaSize,
    Text,
    IPickerItemProps,
    Stack,
    IconButton,
    DirectionalHint,
} from '@fluentui/react';
import {
    useGetTeamUsersQuery,
    GetUsersSearchQuery,
    GetUsersSearchQueryVariables,
    GetUsersSearchDocument,
    GetResourcesSearchDocument,
    GetResourcesSearchQuery,
    GetResourcesSearchQueryVariables,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { useApolloClient } from '@apollo/client';
import { photoService } from '../services/photo.service';
import { useThemes } from '../hooks/useThemes';
import { TaskStatusColours } from '../Colours';
import { ResourceTooltip } from './ResourceTooltip';
import { TaskWithStatus } from '../data/extendedTypes';

export type ResourcePickerResource = {
    resourceId: string | null;
    userId: string | null;
    userHasMission: boolean;
    displayName: string | null;
    resourceIsPrimary: boolean;
    isAssignedToSelf: boolean;
    requestAgain?: boolean;
    task:
        | (TaskWithStatus & {
              id: string | null;
              missionId: string | null;
              percentComplete?: number | null;
              rejectedReason: string | null;
          })
        | null
        | undefined;
};

export type ResourcePickerProps = {
    teamCode: string | undefined;
    selectedResources: ResourcePickerResource[];
    alreadyResourced: {
        userId: string | null | undefined;
    }[];
    onChange: (resources: ResourcePickerResource[]) => void;
    disabled?: boolean;
};

interface IResourcePersonaProps extends IPersonaProps, ResourcePickerResource {}

export function ResourcePicker(props: ResourcePickerProps): JSX.Element {
    const { currentTenantId, currentFinancialYearCode } = useStateContext();
    const { currentTheme } = useThemes();

    const [alreadyResourcedDialogHidden, setAlreadyResourcedDialogHidden] =
        useState(true);

    const showAlreadyResourcedDialog = () =>
        setAlreadyResourcedDialogHidden(false);
    const hideAlreadyResourcedDialog = () =>
        setAlreadyResourcedDialogHidden(true);

    const currentSelectedItems = props.selectedResources
        .sort((r1, r2) =>
            r1.resourceIsPrimary === r2.resourceIsPrimary
                ? 0
                : r1.resourceIsPrimary
                  ? -1
                  : 1
        )
        .map((r) => {
            return {
                ...r,
                text: r.displayName || '',
                imageUrl: photoService.getImageUrl(r.userId),
            } as IResourcePersonaProps;
        });

    const updateSelectedResources = (
        personas?: IResourcePersonaProps[] | undefined
    ): void => {
        let resources: ResourcePickerResource[];
        if (personas) {
            resources = personas.map((persona) => {
                return {
                    ...persona,
                    displayName: persona.text || '',
                };
            });
        } else {
            resources = [];
        }
        if (props.onChange) {
            props.onChange(resources);
        }
    };

    const onSetPrimaryClicked = (
        resourceId?: string | null,
        userId?: string | null,
        name?: string | null
    ) => {
        const updatedSelectedItems: IResourcePersonaProps[] = [];

        let primaryResource =
            resourceId &&
            currentSelectedItems.find((i) => i.resourceId === resourceId);

        // If this is a new resource that doesn't have an ID yet, find by the name.
        if (!primaryResource) {
            if (userId) {
                primaryResource = currentSelectedItems.find(
                    (i) => i.userId === userId
                );
            } else if (name) {
                primaryResource = currentSelectedItems.find(
                    (i) => i.text === name && !i.userId && !i.resourceId
                );
            }
        }

        if (primaryResource) {
            updatedSelectedItems.push({
                ...primaryResource,
                resourceIsPrimary: true,
            });

            currentSelectedItems
                .filter((i) => i !== primaryResource)
                .forEach((i) => {
                    updatedSelectedItems.push({
                        ...i,
                        resourceIsPrimary: false,
                    });
                });

            updateSelectedResources(updatedSelectedItems);
        }
    };

    const handleRequestAgain = (
        resourceId?: string | null,
        isCancel?: boolean
    ) => {
        const updatedResources: IResourcePersonaProps[] =
            currentSelectedItems.map((r) => {
                if (r.resourceId === resourceId) {
                    return {
                        ...r,
                        requestAgain: !isCancel,
                    };
                } else {
                    return r;
                }
            });

        updateSelectedResources(updatedResources);
    };

    const skip = !props.teamCode;
    const variables = !skip
        ? {
              tenantId: currentTenantId || '',
              teamCode: props.teamCode || '',
              financialYearCode: currentFinancialYearCode || '',
          }
        : undefined;

    const { data: teamMembersData } = useGetTeamUsersQuery({
        skip,
        variables,
    });

    const client = useApolloClient();

    const filterPromise = (
        personasToReturn: IResourcePersonaProps[]
    ): IResourcePersonaProps[] | Promise<IResourcePersonaProps[]> => {
        return personasToReturn;
    };

    const listContainsPersona = (
        persona: IResourcePersonaProps,
        personas: IResourcePersonaProps[]
    ): boolean => {
        if (!personas || !personas.length || personas.length === 0) {
            return false;
        }
        return personas.some(
            (item) =>
                (item.resourceId !== null &&
                    item.resourceId === persona.resourceId) ||
                (item.userId !== null && item.userId === persona.userId) ||
                (!item.userId && !item.resourceId && item.text === persona.text)
        );
    };

    const removeDuplicates = (
        personas: IResourcePersonaProps[],
        possibleDupes: IResourcePersonaProps[]
    ): IResourcePersonaProps[] => {
        return personas.filter(
            (persona) => !listContainsPersona(persona, possibleDupes)
        );
    };

    const userQueryAbortController = React.useRef<AbortController>();
    const resourcesQueryAbortController = React.useRef<AbortController>();

    const abortLatest = () => {
        userQueryAbortController.current?.abort();
        resourcesQueryAbortController.current?.abort();
    };

    const onResolveSuggestions = async (
        filterText: string,
        currentPersonas: IPersonaProps[] | undefined
    ): Promise<IResourcePersonaProps[]> => {
        const results: IResourcePersonaProps[] = [];
        const limitResults = 8;

        if (filterText) {
            userQueryAbortController.current = new window.AbortController();
            const usersQuery = client.query<
                GetUsersSearchQuery,
                GetUsersSearchQueryVariables
            >({
                query: GetUsersSearchDocument,
                fetchPolicy: 'network-only',
                variables: {
                    tenantId: currentTenantId || '',
                    searchText: filterText,
                    useCache: false,
                },
                context: {
                    queryDeduplication: false, // Stops the query depuplication from kicking in allowing previous aborted searches to rerun.
                    fetchOptions: {
                        signal: userQueryAbortController.current?.signal,
                    },
                },
            });

            resourcesQueryAbortController.current =
                new window.AbortController();
            const resourcesQuery = client.query<
                GetResourcesSearchQuery,
                GetResourcesSearchQueryVariables
            >({
                query: GetResourcesSearchDocument,
                fetchPolicy: 'network-only',
                variables: {
                    tenantId: currentTenantId || '',
                    searchText: filterText,
                },
                context: {
                    queryDeduplication: false, // Stops the query depuplication from kicking in allowing previous aborted searches to rerun.
                    fetchOptions: {
                        signal: resourcesQueryAbortController.current?.signal,
                    },
                },
            });

            // Users
            await usersQuery.then(
                (queryResult: { data: GetUsersSearchQuery }) => {
                    if (queryResult.data && queryResult.data.userSearch) {
                        let filteredPersonas: IResourcePersonaProps[] =
                            queryResult.data.userSearch.map((u) => {
                                const blocked = isUserAlreadyResourced(u?.id);
                                return {
                                    resourceId: null,
                                    userId: u?.id,
                                    userHasMission: u?.missionFYs.some(
                                        (fy) =>
                                            fy.code?.toUpperCase() ===
                                            currentFinancialYearCode?.toUpperCase()
                                    ),
                                    text: u?.displayName || '',
                                    displayName: u?.displayName || '',
                                    imageUrl: photoService.getImageUrl(u?.id),
                                    resourceIsPrimary: false,
                                    isAssignedToSelf: false,
                                    presence: blocked
                                        ? PersonaPresence.blocked
                                        : undefined,
                                    presenceTitle: blocked
                                        ? 'This resource is already assigned to this task cascade..'
                                        : undefined,
                                    task: null,
                                };
                            });

                        filteredPersonas = removeDuplicates(
                            filteredPersonas,
                            (currentPersonas || []) as IResourcePersonaProps[]
                        );

                        filteredPersonas = limitResults
                            ? filteredPersonas.splice(0, limitResults)
                            : filteredPersonas;

                        results.push(...filteredPersonas);
                    }
                }
            );

            // Non user resources
            await resourcesQuery.then(
                (queryResult: { data: GetResourcesSearchQuery }) => {
                    if (queryResult.data && queryResult.data.resource_search) {
                        let filteredPersonas: IResourcePersonaProps[] =
                            queryResult.data.resource_search.map((r) => {
                                return {
                                    resourceId: r.id,
                                    userId: null,
                                    userHasMission: false,
                                    id: r?.id || '',
                                    text: r?.displayName || '',
                                    displayName: r?.displayName || '',
                                    resourceIsPrimary: false,
                                    isAssignedToSelf: false,
                                    task: null,
                                };
                            });

                        filteredPersonas = removeDuplicates(
                            filteredPersonas,
                            (currentPersonas || []) as IResourcePersonaProps[]
                        );

                        filteredPersonas = limitResults
                            ? filteredPersonas.splice(0, limitResults)
                            : filteredPersonas;

                        results.push(...filteredPersonas);
                    }
                }
            );

            if (
                results.length === 0 ||
                !results.some(
                    (r) => r.text?.toUpperCase() === filterText.toUpperCase()
                )
            ) {
                results.push({
                    userId: null,
                    resourceId: null,
                    userHasMission: false,
                    text: filterText,
                    displayName: filterText,
                    showUnknownPersonaCoin: true,
                    resourceIsPrimary: false,
                    isAssignedToSelf: false,
                    task: null,
                });
            }
        }

        return results;
    };

    const validateInput = (): ValidationState => {
        // Do not allow manual resources to be added like this.
        return ValidationState.invalid;
    };

    const returnMostRecentlyUsed = (
        currentPersonas: IPersonaProps[] | undefined
    ): IResourcePersonaProps[] | Promise<IResourcePersonaProps[]> => {
        const teamPersonas: IResourcePersonaProps[] = [];

        if (teamMembersData?.teams) {
            teamMembersData?.teams.forEach((p) => {
                if (p?.leaderMission?.userId) {
                    teamPersonas.push({
                        userId: p.leaderMission.userId,
                        userHasMission: true,
                        resourceId: null,
                        text: p.leaderMission.username || '',
                        displayName: p.leaderMission.username || '',
                        imageUrl: photoService.getImageUrl(
                            p.leaderMission.userId
                        ),
                        resourceIsPrimary: false,
                        isAssignedToSelf: false,
                        task: null,
                    });
                }

                p?.missions?.forEach((m) => {
                    if (m?.userId) {
                        if (!teamPersonas.find((p) => p.userId === m.userId)) {
                            teamPersonas.push({
                                userId: m.userId,
                                userHasMission: true,
                                resourceId: null,
                                text: m.username || '',
                                displayName: m.username || '',
                                imageUrl: photoService.getImageUrl(m.userId),
                                resourceIsPrimary: false,
                                isAssignedToSelf: false,
                                task: null,
                            });
                        }
                    }
                });
            });
        }

        const mostRecentlyUsed = removeDuplicates(
            teamPersonas,
            (currentPersonas || []) as IResourcePersonaProps[]
        );

        teamPersonas.forEach((tp) => {
            if (isUserAlreadyResourced(tp?.userId)) {
                tp.presence = PersonaPresence.blocked;
                tp.presenceTitle =
                    'This resource is already assigned to this task cascade.';
            }
        });

        return filterPromise(mostRecentlyUsed);
    };

    const isUserAlreadyResourced = (userId: string | null | undefined) => {
        return (
            userId && props.alreadyResourced.some((ar) => ar.userId === userId)
        );
    };

    const getTextFromItem = (persona: IPersonaProps): string => {
        return persona.text as string;
    };

    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Team Members',
        mostRecentlyUsedHeaderText: 'Team Members',
        noResultsFoundText: 'No results found',
        loadingText: 'Loading...',
        showRemoveButtons: false,
        suggestionsAvailableAlertText: 'Team members available',
        suggestionsContainerAriaLabel: 'Team Members',
    };

    const onChange = (items?: IPersonaProps[] | undefined): void => {
        updateSelectedResources(items as IResourcePersonaProps[]);
    };

    const onItemSelected = (item?: IPersonaProps): IPersonaProps | null => {
        const resourceItem = item as IResourcePersonaProps;
        if (!resourceItem) {
            return null;
        } else if (isUserAlreadyResourced(resourceItem.userId)) {
            showAlreadyResourcedDialog();
            return null;
        }
        return resourceItem;
    };

    const onRenderSuggestionsItem = (
        personaProps: IPersonaProps
    ): JSX.Element => {
        return (
            <div style={{ padding: 8 }}>
                <Persona
                    {...personaProps}
                    size={PersonaSize.size24}
                    hidePersonaDetails={false}
                />
            </div>
        );
    };

    const onRenderSecondaryText = (
        personaProps?: IPersonaProps
    ): JSX.Element => {
        const resourcePersonaProps = personaProps as IResourcePersonaProps;

        let iconName: string | undefined;
        let iconColour: string | undefined;
        let text: string;

        if (resourcePersonaProps.task?.utcAccepted) {
            iconName = 'CheckMark';
            iconColour = currentTheme.semanticColors.successIcon;
            text = 'Accepted';
        } else if (resourcePersonaProps.requestAgain) {
            iconName = 'Clock';
            text = 'Request pending';
        } else if (resourcePersonaProps.task?.utcRejected) {
            iconName = 'Blocked';
            iconColour = TaskStatusColours.rejected;
            text = 'Rejected';
        } else if (!resourcePersonaProps.resourceId) {
            text = 'New';
        } else if (
            resourcePersonaProps.userId &&
            resourcePersonaProps.userHasMission
        ) {
            iconName = 'Clock';
            text = 'Requested';
        } else {
            text = 'Resource';
        }

        return (
            <Stack horizontal tokens={{ childrenGap: 2 }}>
                {iconName && (
                    <Icon
                        iconName={iconName}
                        style={{
                            color: iconColour,
                        }}
                    />
                )}
                <Text
                    variant="tiny"
                    styles={{
                        root: {
                            textTransform: 'uppercase',
                            color: iconColour,
                        },
                    }}
                >
                    {text}
                </Text>
            </Stack>
        );
    };

    const onRenderItem = (
        pickerItemProps: IPickerItemProps<IPersonaProps>
    ): JSX.Element => {
        const personaProps = pickerItemProps.item as IResourcePersonaProps;

        const primaryText = personaProps.resourceIsPrimary
            ? `${personaProps.text} (PRIMARY)`
            : personaProps.text;

        const task = personaProps.task;

        const hideSecondaryText =
            !personaProps.userId || personaProps.isAssignedToSelf;

        return (
            <Stack
                horizontal
                tokens={{ padding: '16px 0 0 0' }}
                key={pickerItemProps.key}
            >
                <Stack.Item grow>
                    <ResourceTooltip
                        resourcedTask={{
                            id: task?.id || null,
                            utcAccepted: task?.utcAccepted || null,
                            utcRejected: task?.utcRejected || null,
                            resourceIsPrimary: personaProps.resourceIsPrimary,
                            rejectedReason: task?.rejectedReason,
                            percentComplete: task?.percentComplete || 0,
                            start: task?.start || null,
                            due: task?.due || null,
                            done: task?.done || null,
                            utcPostponed: task?.utcPostponed || null,
                            utcCancelled: task?.utcCancelled || null,
                            utcAtRisk: task?.utcAtRisk || null,
                            resourcedFromTask: task?.resourcedFromTask || null,
                            resource: {
                                displayName: personaProps.text || null,
                                userId: personaProps.userId || null,
                                userMissionFYs:
                                    personaProps.userHasMission &&
                                    currentFinancialYearCode
                                        ? [{ code: currentFinancialYearCode }]
                                        : [],
                                userContributorFYs: [],
                            },
                        }}
                        showStatus={!!personaProps.resourceId}
                        directionalHint={DirectionalHint.leftCenter}
                    >
                        <Persona
                            {...personaProps}
                            size={PersonaSize.size32}
                            hidePersonaDetails={false}
                            showSecondaryText={!hideSecondaryText}
                            text={primaryText}
                            onRenderSecondaryText={onRenderSecondaryText}
                        />
                    </ResourceTooltip>
                </Stack.Item>
                {!props.disabled && (
                    <Stack.Item>
                        <ResourceMoreIconButton
                            {...personaProps}
                            onSetPrimaryClicked={() =>
                                onSetPrimaryClicked(
                                    personaProps.resourceId,
                                    personaProps.userId,
                                    personaProps.text
                                )
                            }
                            onRemoveClicked={() => {
                                updateSelectedResources(
                                    currentSelectedItems.filter(
                                        (i) => i !== personaProps
                                    )
                                );
                            }}
                            onRequestAgainClicked={() => {
                                handleRequestAgain(personaProps.resourceId);
                            }}
                            onCancelRequestAgainClicked={() => {
                                handleRequestAgain(
                                    personaProps.resourceId,
                                    true
                                );
                            }}
                        />
                    </Stack.Item>
                )}
            </Stack>
        );
    };

    return (
        <div>
            <ListPeoplePicker
                disabled={props.disabled}
                onResolveSuggestions={async (
                    filter: string,
                    selectedItems?: IPersonaProps[] | undefined
                ) => {
                    abortLatest();
                    return onResolveSuggestions(filter, selectedItems);
                }}
                onEmptyInputFocus={returnMostRecentlyUsed}
                getTextFromItem={getTextFromItem}
                selectedItems={currentSelectedItems}
                pickerSuggestionsProps={suggestionProps}
                onChange={onChange}
                onItemSelected={onItemSelected}
                onValidateInput={validateInput}
                inputProps={{
                    'aria-label': 'Resource Picker',
                    placeholder: 'Add new...',
                }}
                onRenderSuggestionsItem={onRenderSuggestionsItem}
                onRenderItem={onRenderItem}
            />
            <Dialog
                hidden={alreadyResourcedDialogHidden}
                onDismiss={hideAlreadyResourcedDialog}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Already Resourced',
                    closeButtonAriaLabel: 'Close',
                    subText:
                        'This resource is already assigned to this task cascade..',
                }}
                modalProps={{
                    isBlocking: true,
                }}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={hideAlreadyResourcedDialog}
                        text="OK"
                    />
                </DialogFooter>
            </Dialog>
        </div>
    );
}

function ResourceMoreIconButton(props: {
    resourceIsPrimary: boolean;
    requestAgain?: boolean;
    task:
        | {
              utcRejected?: string | null | undefined;
          }
        | null
        | undefined;
    onSetPrimaryClicked: () => void;
    onRemoveClicked: () => void;
    onRequestAgainClicked: () => void;
    onCancelRequestAgainClicked: () => void;
}): JSX.Element {
    const menuItems: IContextualMenuItem[] = [
        {
            key: 'setAsPrimary',
            iconProps: { iconName: 'PartyLeader' },
            text: 'Set as primary resource',
            onClick: props.onSetPrimaryClicked,
            disabled: props.resourceIsPrimary,
        },
        {
            key: 'removeResource',
            iconProps: { iconName: 'UserRemove' },
            text: 'Remove',
            onClick: props.onRemoveClicked,
        },
    ];

    if (props.task?.utcRejected && !props.requestAgain) {
        menuItems.push({
            key: 'requestAgain',
            iconProps: { iconName: 'UserSync' },
            text: 'Request Again',
            onClick: props.onRequestAgainClicked,
        });
    }

    if (props.requestAgain) {
        menuItems.push({
            key: 'cancelRequestAgain',
            iconProps: { iconName: 'UserWarning' },
            text: 'Cancel Request Again',
            onClick: props.onCancelRequestAgainClicked,
        });
    }

    return (
        <React.Fragment>
            <IconButton
                iconProps={{ iconName: 'More' }}
                onRenderMenuIcon={() => null}
                menuProps={{
                    items: menuItems,
                }}
            />
        </React.Fragment>
    );
}
