import React from 'react';
import { Text, mergeStyleSets } from '@fluentui/react';
import { TooltipProps } from 'recharts';
import { MeasureChartColours } from '../../../Colours';
import { useThemes } from '../../../hooks/useThemes';
import {
    NameType,
    ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { ChartData } from '../utils/measureUtils';
import { MeasureArrowIcon } from '../../../components/MeasureArrowIcon';
import { MeasureSeriesNames } from '../../../data/extendedTypes';
import { useLanguage } from '../../../services/i18n';

export type MeasureComposedRechartTooltipStackBreakDown = {
    asOf: string;
    seriesName: string | null;
    seriesValue: number | string | null;
    measureName: string;
    hexColour: string | null;
};

type MeasureComposedRechartTooltipProps = TooltipProps<ValueType, NameType> & {
    stackBreakdown?: MeasureComposedRechartTooltipStackBreakDown[];
};

export function MeasureComposedRechartTooltip(
    props: MeasureComposedRechartTooltipProps
): JSX.Element {
    const { currentTheme } = useThemes();

    const { t } = useLanguage();

    const styles = mergeStyleSets({
        chartTooltip: {
            fontFamily:
                '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif',
            padding: 8,
            fontSize: 12,
            backgroundColor: currentTheme.palette.white,
            boxShadow: currentTheme.effects.elevation4,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            textAlign: 'left',
            minWidth: 200,
        },
        chartTooltipActual: {
            padding: 0,
            margin: 0,
            fontSize: 18,
            fontWeight: 'bold',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justiyContent: 'space-between',
            gap: 16,
        },
        chartTooltipStatus: {
            padding: 0,
            margin: 0,
            fontSize: 16,
            fontWeight: 'normal',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
        },
        chartTooltipLabel: {
            padding: 0,
            margin: 0,
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
            alignItems: 'center',
        },
    });

    const payload = props.payload ? props.payload[0]?.payload : null;

    const chartData = payload ? (payload as ChartData) : null;

    const actualText =
        props.payload &&
        chartData?.Actual !== undefined &&
        chartData?.Actual !== null
            ? props.formatter
                ? props.formatter(
                      chartData.Actual,
                      'Actual',
                      payload,
                      0,
                      props.payload
                  )
                : payload.Actual
            : null;

    const targetText =
        props.payload &&
        chartData?.Target !== undefined &&
        chartData?.Target !== null
            ? props.formatter
                ? props.formatter(
                      chartData.Target,
                      'Target',
                      payload,
                      0,
                      props.payload
                  )
                : chartData.Target
            : null;

    const targetRemainingText =
        props.payload && chartData?.TargetRemaining
            ? props.formatter
                ? props.formatter(
                      chartData.TargetRemaining,
                      'Target',
                      payload,
                      0,
                      props.payload
                  )
                : chartData.Target
            : null;

    const targetOverText =
        props.payload && chartData?.TargetOver
            ? props.formatter
                ? props.formatter(
                      chartData.TargetOver,
                      'Target',
                      payload,
                      0,
                      props.payload
                  )
                : chartData.TargetOver
            : null;

    const statusText =
        props.payload &&
        chartData?.StatusValue !== undefined &&
        chartData?.StatusValue !== null
            ? props.formatter
                ? props.formatter(
                      chartData.StatusValue,
                      'Status',
                      payload,
                      0,
                      props.payload
                  )
                : chartData.StatusValue
            : null;

    const forecastTitle = t('measure-of-success.forecast');
    const forecastText =
        props.payload &&
        chartData?.Forecast !== undefined &&
        chartData?.Forecast !== null
            ? props.formatter
                ? props.formatter(
                      chartData.Forecast,
                      t('measure-of-success.forecast'),
                      payload,
                      0,
                      props.payload
                  )
                : chartData.Forecast
            : null;

    const breakdownSeries = props.stackBreakdown
        ? [...new Set(props.stackBreakdown.map((b) => b.seriesName))]
        : [];

    const targetHex = currentTheme.palette.themeLight;
    const actualHex = currentTheme.palette.themePrimary;

    return (
        <div className={styles.chartTooltip}>
            <div>
                {props.labelFormatter
                    ? props.labelFormatter(props.label, payload)
                    : props.label}
            </div>

            {!!props.stackBreakdown?.length && (
                <MeasureComposedRechartTooltipBreakdownTable
                    {...props}
                    totals={[
                        {
                            seriesName: MeasureSeriesNames.Actual,
                            content: actualText,
                            hexColour: actualHex,
                        },
                        {
                            seriesName: MeasureSeriesNames.Target,
                            content: targetText,
                            hexColour: targetHex,
                        },
                        {
                            seriesName: MeasureSeriesNames.PhasedForecast,
                            content: forecastText,
                            hexColour: MeasureChartColours.forecast,
                        },
                    ]}
                />
            )}

            {!!actualText &&
                !breakdownSeries.some(
                    (bs) => bs === MeasureSeriesNames.Actual
                ) && (
                    <div className={styles.chartTooltipActual}>
                        <Text variant="large" style={{ color: actualHex }}>
                            {actualText}
                        </Text>

                        <span className={styles.chartTooltipStatus}>
                            {!!statusText && (
                                <Text variant="mediumPlus">{statusText}</Text>
                            )}

                            {!!chartData?.ArrowColour && (
                                <MeasureArrowIcon
                                    arrowColour={chartData?.ArrowColour}
                                    arrowDirection={chartData?.ArrowDirection}
                                    iconFontSize={16}
                                />
                            )}
                        </span>
                    </div>
                )}

            {!!targetRemainingText && (
                <div className={styles.chartTooltipLabel}>
                    <Text variant="small">{`TARGET REMAINING: ${targetRemainingText}`}</Text>
                </div>
            )}

            {!!targetOverText && (
                <div
                    className={styles.chartTooltipLabel}
                    style={{ color: MeasureChartColours.targetOver }}
                >
                    <Text variant="small">{`OVER TARGET: ${targetOverText}`}</Text>
                </div>
            )}

            {!targetRemainingText &&
                !!targetText &&
                !breakdownSeries.some(
                    (bs) => bs === MeasureSeriesNames.Target
                ) && (
                    <div className={styles.chartTooltipLabel}>
                        <Text variant="small">{`TARGET: ${targetText}`}</Text>
                    </div>
                )}

            {!!forecastText &&
                !breakdownSeries.some(
                    (bs) => bs === MeasureSeriesNames.PhasedForecast
                ) && (
                    <div className={styles.chartTooltipLabel}>
                        {`${forecastTitle}: ${forecastText}`}
                    </div>
                )}
        </div>
    );
}

function MeasureComposedRechartTooltipBreakdownTable(
    props: MeasureComposedRechartTooltipProps & {
        totals: {
            seriesName: MeasureSeriesNames;
            content: string | React.ReactNode | null;
            hexColour: string | null;
        }[];
    }
): JSX.Element {
    const payload = props.payload ? props.payload[0]?.payload : null;

    const chartData = payload ? (payload as ChartData) : null;

    const breakdown: MeasureComposedRechartTooltipStackBreakDown[] =
        chartData?.Date && props.stackBreakdown
            ? props.stackBreakdown.filter((b) => b.asOf === chartData?.Date)
            : [];

    const breakdownMeasures = [...new Set(breakdown.map((b) => b.measureName))];
    const breakdownSeries = [...new Set(breakdown.map((b) => b.seriesName))];

    return (
        <table>
            <thead>
                <tr>
                    <th></th>
                    {breakdownSeries?.map((sn) => {
                        return (
                            <th align="right" key={sn}>
                                {sn}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {breakdownMeasures.map((mn) => {
                    return (
                        <tr key={mn}>
                            <td style={{ paddingRight: 8 }}>{mn}</td>
                            {breakdownSeries?.map((sn) => {
                                const series = breakdown
                                    .filter((b) => b.measureName === mn)
                                    .find((b) => b.seriesName === sn);

                                const formatted =
                                    props.formatter &&
                                    series?.seriesValue &&
                                    sn &&
                                    props.payload
                                        ? props.formatter(
                                              series?.seriesValue,
                                              sn,
                                              payload,
                                              0,
                                              props.payload
                                          )
                                        : series?.seriesValue;

                                return (
                                    <td
                                        align="right"
                                        style={{ paddingLeft: 16 }}
                                        key={`${sn}_${mn}`}
                                    >
                                        <div
                                            style={{
                                                borderLeft: `solid 16px ${series?.hexColour}`,
                                                paddingLeft: 8,
                                                minWidth: 40,
                                            }}
                                        >
                                            {formatted}
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                {!!props.totals && (
                    <tr>
                        <td></td>
                        {breakdownSeries?.map((sn) => {
                            const total = props.totals.find(
                                (t) => t.seriesName === sn
                            );

                            return (
                                <td
                                    align="right"
                                    key={sn}
                                    style={{ paddingLeft: 16 }}
                                >
                                    <div
                                        style={{
                                            fontWeight: 600,
                                            minWidth: 40,
                                        }}
                                    >
                                        {total?.content}
                                    </div>
                                </td>
                            );
                        })}
                    </tr>
                )}
            </tbody>
        </table>
    );
}
