import React, { useState } from 'react';
import {
    DirectionalHint,
    Stack,
    TooltipDelay,
    TooltipHost,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import MeasureCalcTooltipContent from './MeasureCalcTooltipContent';

type Props = {
    measureId?: string | null;
    valueFormula?: string | null;
    style?: React.CSSProperties;
    children?: React.ReactNode;
};

function MeasureCalcTooltip(props: Props): JSX.Element {
    const tooltipId = useId('tooltip');
    const [isShown, setIsShown] = useState<boolean>(false);

    return props.valueFormula ? (
        <Stack
            horizontal
            tokens={{
                childrenGap: 36,
            }}
            style={props.style}
        >
            <TooltipHost
                content={
                    <MeasureCalcTooltipContent
                        measureId={props.measureId}
                        isShown={isShown}
                    />
                }
                delay={TooltipDelay.zero}
                id={tooltipId}
                directionalHint={DirectionalHint.bottomCenter}
                styles={{ root: { display: 'inline-block' } }}
                onTooltipToggle={(isTooltipVisible: boolean) => {
                    setIsShown(isTooltipVisible);
                }}
            >
                {props.children}
            </TooltipHost>
        </Stack>
    ) : (
        <></>
    );
}

export default MeasureCalcTooltip;
