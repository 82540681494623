import React from 'react';
import { useActiveView } from '../../hooks/useActiveView';

import { paths } from '../../services/navigation';
import { LegalContainer } from './components/LegalContainer';

export function TermsOfUse(): JSX.Element {
    useActiveView('TermsOfUse', 'Terms of Use');

    return (
        <LegalContainer>
            <strong>Terms and Conditions of Use</strong>
            <br />
            <br />
            This document (together with the documents referred to in it)
            constitutes the terms and conditions for the web site{' '}
            <a href="http://app.advancedashboard.com/">
                http://app.advancedashboard.com/
            </a>
            , including all related McKinney Rogers, a division of GP Strategies
            web sites (&quot;Site&quot;) and the terms and conditions
            (&quot;Terms of Use&quot;) on which you may make use of the Site
            whether as a guest or as a member/account holder in respect of the
            Site.
            <br />
            <br />
            The Site is wholly owned and is operated by, or on behalf of,
            McKinney Rogers, a division of GP Strategies, together with its
            subsidiaries (collectively referred to hereinafter as &quot;McKinney
            Rogers, a division of GP Strategies&quot;, &quot;we&quot;,
            &quot;us&quot; or &quot;our&quot;). Our full details, together with
            contact information are set out below. It is important to remember
            that by using the Site and any of the products, services or other
            sites available via the Site, you agree to be bound by and act in
            accordance with these terms and conditions (&quot;Terms of
            Use&quot;) without limitations or qualifications. If you do not wish
            to be bound by these Terms of Use, you should not access or use the
            Site.
            <br />
            <br />
            <strong>Copyrights and Trademarks</strong>
            <br />
            <br />
            All copyrights and any other intellectual property rights in the
            contents of the Site including, but not limited to, the design,
            text, graphics, software and other materials (&quot;Materials&quot;)
            contained therein and their arrangement are owned exclusively by
            McKinney Rogers, a division of GP Strategies and its licensors.
            <br />
            <br />
            The Site and all Materials provided via the Site, including, but not
            limited to, graphic images, audio, video, html code, buttons, text,
            and course materials may not be copied, reproduced, republished,
            uploaded, posted, transmitted, or distributed in any way, without
            our prior written consent. However, you may download, display, and
            print one (1) copy of such Materials on any single computer solely
            for your personal, non-commercial use, provided that you do not
            modify the Materials in any way and you keep intact all copyright,
            Trademark, and other proprietary notices.
            <br />
            <br />
            Unless otherwise specified, the trademarks, service marks, designs,
            and logos (collectively, the &quot;Trademarks&quot;) displayed on
            the Site are the registered or unregistered Trademarks of GP
            Strategies, McKinney Rogers, its licensors, or customers. You agree
            that you will not refer to or attribute any information to GP
            Strategies, McKinney Rogers, its licensors or customers in any
            public medium (e.g., press release, web sites, etc.) for advertising
            or promotion purposes, or for the purpose of informing or
            influencing any third party. You also agree not to use or reproduce
            any Trademark of, or imply any endorsement by or relationship with,
            GP Strategies, McKinney Rogers, its licensors or customers.
            <br />
            <br />
            If you are found at any time to be in breach of these Terms of Use,
            your right to use the Site will cease immediately and you must, at
            our option, return or destroy copies of any Materials you have made.
            <br />
            <br />
            <strong>Infringement</strong>
            <br />
            <br />
            Unauthorized copying, distribution, modification, public display, or
            public performance of copyrighted works is an infringement of the
            copyright holder&apos;s rights. GP Strategies prohibits the posting
            of any information that infringes or violates the copyright rights
            and/or other intellectual property rights (including rights of
            privacy and publicity) of any person or entity. You agree that you
            will not use the Site to infringe the copyright rights and/or other
            intellectual property rights of GP Strategies or others in any way.
            <br />
            <br />
            <strong>Use of the Site</strong>
            <br />
            <br />
            This Site and any services or Materials accessible via the Site is
            provided &lsquo;as is&rsquo;. We make no representation or warranty
            of any kind with respect to the Site or any site, service or
            Materials available through the Site, and explicitly disclaim all
            warranties of any kind, whether expressed or implied, including but
            not limited to, the implied warranties of merchantability, fitness
            for a particular purpose, title, and non-infringement. In no event
            will we be liable to any party for any direct, indirect, incidental,
            special, exemplary, consequential, or other damages (including, but
            not limited to, lost profits, business interruption, loss of
            programs or data) without regard to the form of action and whether
            in contract, tort, negligence, strict liability, or otherwise,
            arising out of or in connection with the Site, any Materials on or
            accessed through the Site or any site or service linked thereto, or
            any copying, displaying, or use thereof. Your statutory rights are
            not affected by this disclaimer.
            <br />
            <br />
            The Internet has inherited dangers; we take reasonable measures to
            ensure a fast, reliable and secure service. However, we will not be
            responsible for the compatibility of the Site with your computer
            system, software and/or hardware. Likewise, we will not be held
            responsible for any damage, loss or corruption of any data,
            information or material or viruses that may infect your computer
            equipment or other property due to your use of the Site or to your
            downloading of any Materials posted on the Site, or on any web site
            linked to the Site.
            <br />
            <br />
            Access to the Site is permitted on a temporary basis and we reserve
            the right to terminate or make modifications to any Materials or
            services that we provide through the Site (or any part thereof)
            without notice. We will not be liable if for any reason the Site (or
            any part thereof) is unavailable at any time or for any period.
            <br />
            <br />
            Occasionally, we may need to maintain the software and other
            equipment that runs the Site. During these times, you may not be
            able to access the Site or the Site may be slower than normal.
            <br />
            <br />
            You are responsible for making all arrangements necessary for you to
            have access to the Site and for ensuring that all persons who access
            the Site through your internet connection are aware of and comply
            with these Terms of Use.
            <br />
            <br />
            We make no promises of any kind about the suitability, reliability,
            timeliness, and accuracy of the services offered or Materials posted
            on the Site for any purpose.
            <br />
            <br />
            From time to time you may be asked to register your details with us
            in order to receive products or services or to enter contests. When
            you do, additional terms and conditions may apply.
            <br />
            <br />
            <strong>Accuracy</strong>
            <br />
            <br />
            While every effort is made to ensure that the Materials contained on
            the Site is correct, no warranties (express or implied) are made by
            us as to the nature or accuracy of any such Materials. Accordingly,
            we disclaim all liability and responsibility arising from any
            reliance placed on such Materials by any visitor to the Site, or by
            anyone who may be informed of any of its contents to the extent
            permitted by applicable law.
            <br />
            <br />
            We may make changes to the Materials contained on the Site at any
            time without notice. However, we are not obliged to update the
            Site&apos;s Materials and, although we aim to keep the Site as up to
            date as possible, Materials on the Site may be out of date at any
            given time.
            <br />
            <br />
            <strong>Misuse of the Site</strong>
            <br />
            <br />
            You may only use the Site for lawful purposes.
            <br />
            <br />
            You must not misuse or in any way attempt to damage the Site (or any
            part of it) including, but not limited to, by knowingly introducing
            viruses, trojans, worms, logic bombs or other material which is
            malicious or technologically harmful. You must not attempt to gain
            unauthorized access to the Site (or any part of it), the server on
            which the Site is stored or any server, computer or database
            connected to the Site. You must not attack the Site via a
            denial-of-service attack or a distributed denial-of service attack.
            <br />
            <br />
            By breaching the above provisions, you would commit a criminal
            offense under applicable law. We will report any such breach to the
            relevant law enforcement authorities and cooperate with those
            authorities by disclosing your identity to them. In the event of
            such a breach, your right to use the Site will cease immediately.
            <br />
            <br />
            <strong>No Obligations of Confidentiality</strong>
            <br />
            <br />
            Unless expressly stated otherwise herein, any information submitted
            by you through the Site shall be deemed non-confidential and
            non-proprietary. You represent that you have the lawful right to
            submit such information and agree that you will not submit any
            information unless you are legally entitled to do so. Because of the
            open nature of the Internet, we recommend that you not submit
            information that you consider confidential.
            <br />
            <br />
            ALL UPLOADS BY YOU TO THE SITE, WHETHER INTO A PUBLIC FORUM OR
            NON-PUBLIC FORUM, SHALL BE AT YOUR OWN RISK AND GP STRATEGIES TAKES
            NO RESPONSIBILITY FOR THE USE OR MISUSE OF ANY SUCH UPLOADED
            INFORMATION BY ANY OTHER USER OF THIS SITE.
            <br />
            <br />
            <strong>Framing/Linking</strong>
            <br />
            <br />
            Any framing of or linking to the Site is prohibited without our
            prior written permission and such permission would only be given on
            conditions as we see fit under the circumstances.
            <br />
            <br />
            <strong>Updates to these Terms of Use</strong>
            <br />
            <br />
            These Terms of Use may be amended, updated or modified by us from
            time to time. You agree to remain bound at all times by these Terms
            of Use as amended, updated or modified in connection with your use
            of the Site or any site or service accessible via the Site.The
            current version of these Terms of Use will always be available at
            our web site,{' '}
            <a href="https://www.gpstrategies.com/">
                https://www.gpstrategies.com/
            </a>
            , by clicking on the Legal Information link which is included on the
            bottom of each web page comprising the Site.
            <br />
            <br />
            <strong>Compliance with Laws</strong>
            <br />
            <br />
            You are responsible for complying with the laws of the jurisdiction
            from which you are accessing the Site and you agree not to access or
            use the information on the Site in violation of such laws.
            <br />
            <br />
            <strong>Miscellaneous</strong>
            <br />
            <br />
            We reserve the right to monitor and track your visits to the Site.
            <br />
            <br />
            Our use of your personal information is subject to our{' '}
            <a href={paths.privacyPolicy}>Privacy Policy</a>
            . By using the Site, you consent to such use and warrant that all
            data provided by you is accurate.
            <br />
            <br />A failure or delay by us in enforcing compliance with these
            Terms of Use shall not be a waiver of that or any other provision of
            these Terms of Use.
            <br />
            <br />
            These Terms of Use constitute the entire agreement between you and
            us as to your use of the Site and shall supersede any prior
            agreement or representation in respect thereof.
            <br />
            <br />
            The express provisions of these Terms of Use are in place of all
            warranties, conditions, terms, undertakings and obligations implied
            by statute, common law, custom, trade usage, course of dealing or
            otherwise, all of which are excluded to the fullest extent permitted
            by law.
            <br />
            <br />
            No changes to these Terms of Use shall be valid except where they
            have been agreed to by us and confirmed in writing.
            <br />
            <br />
            All notices to be given by us to you in connection with these Terms
            of Use shall be sufficiently given when posted on the Site,
            forwarded to you via the email address you have provided to us, or
            by updating these Terms of Use which will be available to you when
            you click on the Legal Information link included on the bottom of
            each web page comprising the Site.
            <br />
            <br />
            We maintain the Site in the United States and you agree that these
            Terms of Use and any legal action or proceeding relating to this
            site shall be governed by the laws of the State of Maryland without
            reference to its choice of law rules. If you attempt to bring any
            legal proceedings against us, you specifically acknowledge that we
            are free to choose the jurisdiction of our preference as to where
            such action against us may be held. As you have agreed by using the
            Site to choose Maryland law to govern any such proceedings, we will
            probably choose to defend any such action in the courts located in
            Howard County, Maryland and we can make this decision entirely as it
            suits us, without regard to where in the world you are located, or
            from where in the world you visited the Site.
            <br />
            <br />
            If you have any queries in connection with these Terms of Use, or if
            you should feel the need to address a complaint to us in connection
            with the Site or the provision of any services provided by us to
            you, please send the same to our address for correspondence set out
            below.
        </LegalContainer>
    );
}
