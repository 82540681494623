import React from 'react';
import {
    GetMeasurePeriodDataQuery,
    GetMeasuresForStatusReportQuery,
} from '../../../data/types';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import { useFormatters } from '../../../hooks/useFormatters';
import { MeasureArrowIcon } from '../../../components/MeasureArrowIcon';

export function PresentationMeasureBox(props: {
    measure: ExtractQueryArrayType<
        GetMeasuresForStatusReportQuery,
        ['measures']
    >;
    periodData: ExtractQueryArrayType<
        GetMeasurePeriodDataQuery,
        ['periodData', 'measurePeriodData']
    >;
}): JSX.Element {
    const { measure, periodData } = props;
    const formatters = useFormatters();

    const formattedPercentage =
        periodData?.targetFormatted !== undefined
            ? formatters.formatMeasureStatus(
                  periodData.statusValue,
                  measure?.isStatusLimited || false
              )
            : '';

    return (
        <div
            className="contentAreaRaised"
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    fontSize: '1em',
                    textAlign: 'left',
                    paddingBottom: 4,
                }}
                title={
                    measure?.shortName && measure?.name
                        ? measure?.name
                        : undefined
                }
            >
                {measure?.shortName || measure?.name}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'end',
                }}
            >
                {!!periodData && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: '3.8vw',
                        }}
                    >
                        <MeasureArrowIcon
                            {...periodData}
                            iconFontSize="1.2em"
                        />
                        {(periodData?.actualFormatted !== null ||
                            periodData?.targetFormatted !== null) && (
                            <span
                                style={{
                                    fontSize: '0.7em',
                                }}
                            >
                                {formattedPercentage}
                            </span>
                        )}
                    </div>
                )}

                <div
                    style={{
                        textAlign: 'left',
                        display: 'flex',
                        fontSize: '1em',
                        flexDirection: 'row',
                        gap: 'min(0.8vw, 16px)',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'start',
                            gap: '0.6vh',
                        }}
                    >
                        <div>
                            <div style={{ fontSize: '0.6em' }}>
                                Actual{' '}
                                {periodData?.period
                                    ? `(${periodData?.period})`
                                    : null}
                            </div>
                        </div>
                        <div
                            style={{
                                fontWeight: 'bold',
                                fontSize: '1em',
                                lineHeight: 'normal',
                            }}
                        >
                            {periodData?.actualFormatted
                                ? periodData?.actualFormatted
                                : 'None'}
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        flexGrow: 1,
                        flexBasis: 0,

                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'right',
                        gap: '0.6vh',
                    }}
                >
                    {!!periodData?.targetFormatted && (
                        <span style={{ fontSize: '0.6em' }}>
                            Target ({periodData?.period}) ·{' '}
                            <span style={{ whiteSpace: 'nowrap' }}>
                                {periodData?.targetFormatted}
                            </span>
                        </span>
                    )}

                    {!!periodData?.nextTargetFormatted &&
                        !!periodData?.nextPeriod && (
                            <span style={{ fontSize: '0.6em' }}>
                                Next Target ({periodData?.nextPeriod}) ·{' '}
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    {periodData?.nextTargetFormatted}
                                </span>
                            </span>
                        )}
                </div>
            </div>
        </div>
    );
}
