import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { Currency, useGetCurrenciesQuery } from '../../data/types';
import { InputShimmer } from '.';
import { useStateContext } from '../../services/contextProvider';

export function CurrencyInput(props: {
    defaultValue: Currency | undefined | null;
    errorMessage: string | undefined;
    onChange: (newValue?: Currency | undefined) => void;
    disabled?: boolean;
}): JSX.Element {
    const { currentTenantId } = useStateContext();
    const { data, loading } = useGetCurrenciesQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
        },
    });

    const options: IDropdownOption[] | undefined = data?.currencies.map((c) => {
        return {
            key: c.code,
            text: `${c.code} - ${c.descr} (${c.symbol})`,
        };
    });

    const onChange = (_ev: React.FormEvent, option?: IDropdownOption): void => {
        const newValue = option
            ? data?.currencies.find((c) => c.code === option.key) ||
              ({ code: option.key as string } as Currency)
            : undefined;
        props.onChange(newValue);
    };

    return (
        <InputShimmer isDataLoaded={!loading}>
            <Dropdown
                disabled={props.disabled}
                label="Currency"
                defaultSelectedKey={props.defaultValue?.code}
                options={options || []}
                errorMessage={props.errorMessage}
                onChange={onChange}
                placeholder="Select a currency"
            />
        </InputShimmer>
    );
}
