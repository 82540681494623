import React, { useEffect, useState } from 'react';
import { IconButton, Text } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react';
import { SharedColors, NeutralColors } from '@fluentui/theme';
import dayjs from 'dayjs';

import {
    Tooltip,
    ResponsiveContainer,
    TooltipProps,
    Area,
    XAxis,
    YAxis,
    Line,
    ComposedChart,
    Legend,
    Bar,
    Cell,
} from 'recharts';

import {
    ChartDisplay,
    FrequencyPeriods,
    MeasureTypes,
    Multipliers,
    StatusTypes,
    ValueTypes,
    useGetFinancialYearQuery,
    useGetMeasureValueFormulaQuery,
} from '../data/types';
import {
    MeasureSeriesNames,
    MeasureValueHistoryItem,
} from '../data/extendedTypes';
import { useThemes } from '../hooks/useThemes';
import { MeasureStatusColours } from '../Colours';
import orderBy from 'lodash/orderBy';
import { MeasureArrowIcon } from './MeasureArrowIcon';
import {
    NameType,
    ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { getLabelForDate } from '../scenes/Measure/utils/measureUtils';
import { YearProgressChart } from '../scenes/Measure/components/MeasureYearProgressChart';
import MeasureComposedRechart from '../scenes/Measure/components/MeasureComposedRechart';
import { useMeasureData } from '../scenes/Measure/hooks/useMeasureData';
import { useStateContext } from '../services/contextProvider';
import { useChartData } from '../scenes/Measure/hooks/useChartData';

type HistoryChartV1Props = {
    measureId: string | null | undefined;
    recentHistory: MeasureValueHistoryItem[];
    limitHistoryCount?: number | undefined;
    status: number;
    isStatusLimited: boolean;
    chartType?: 'line' | 'bar';
    arrowColour: 'RED' | 'GREEN' | 'YELLOW' | 'NONE';
    measureType: MeasureTypes | null | undefined;
    multiplier?: Multipliers | null;
    currencySymbol?: string | null;
    decimalPlaces?: number;
    frequencyPeriod: FrequencyPeriods | null | undefined;
    isCustom: boolean;
    width?: string | number | undefined;
    height?: string | number | undefined;
    hideActual?: boolean;
    showTarget: boolean;
    showTargetPhasing?: boolean;
    showForecast: boolean;
    showAxis: boolean;
    showLegend: boolean;
    hideDots?: boolean;
    hideFill?: boolean;
    showPhasingAsBar?: boolean;
};

type HistoryChartProps = {
    width?: string | number | undefined;
    height?: string | number | undefined;
    showAxis?: boolean;
    showLegend?: boolean;
    measure: {
        id: string | null;
        name: string | null;
        showForecast: boolean;
        chartDisplay: ChartDisplay | null;
        currency: { symbol: string | null } | null;
        decimalPlaces: number;
        measureType: MeasureTypes;
        statusType: StatusTypes;
        valueType: ValueTypes;
        multiplier: Multipliers | null;
        frequencyPeriod: FrequencyPeriods;
        isCustom: boolean;
        isStatusLimited: boolean;
        greenRange: number;
        yellowRange: number;
        yellowStart: number;
        fullYearTarget: number | null;
        isFullYearTarget: boolean;
        valueFormula: string | null;
        lastAsOf: {
            asOfDate: string | null;
        } | null;
    };
    recentHistory: MeasureValueHistoryItem[];
    onChartNameChanged?: (chartName: string) => void;
};

function HistoryChart(props: HistoryChartProps): JSX.Element {
    const { measure, onChartNameChanged } = props;

    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const [chartDateOffset, setChartDateOffset] = useState(0);

    const { data: fyData } = useGetFinancialYearQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: currentFinancialYearCode || '',
        },
    });

    const fyStartDate = fyData?.financialYear?.startDate.substring(0, 10);

    const { buildTargetTable } = useMeasureData();

    const data = buildTargetTable(
        { ...measure, valueHistory: props.recentHistory },
        fyStartDate
    );

    const {
        chartDisplay,
        chartData,
        chartActualMode,
        isStatusOnly,
        chartTooltipTitle,
    } = useChartData(data, measure, fyStartDate);

    const { data: calculatedData } = useGetMeasureValueFormulaQuery({
        skip:
            !currentTenantId ||
            !measure?.id ||
            chartDisplay.key != ChartDisplay.Calculated,
        variables: {
            tenantId: currentTenantId || '',
            measureId: measure?.id || '',
        },
    });

    useEffect(() => {
        if (onChartNameChanged) {
            onChartNameChanged(chartDisplay.text);
        }
    }, [onChartNameChanged, chartDisplay]);

    const measureFormat = measure
        ? {
              currencySymbol: measure.currency?.symbol || null,
              decimalPlaces: measure.decimalPlaces,
              measureType: measure.measureType,
              multiplier: measure.multiplier,
              statusType: measure.statusType,
              isStatusLimited: measure.isStatusLimited,
          }
        : null;

    if (props.recentHistory.length === 0) {
        return (
            <div
                style={{
                    width: props.width,
                    height: props.height,
                    position: 'relative',
                    textAlign: 'center',
                }}
            >
                <Text
                    block
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                    variant={'smallPlus'}
                />
            </div>
        );
    }

    let restrictedChartData = chartData ? chartData.slice() : [];

    const MaxDataPoints = 6;

    const IdeallyBefore = 3; // including current

    if (chartData && restrictedChartData.length > MaxDataPoints) {
        restrictedChartData = [];

        const currentAsOf = measure.lastAsOf?.asOfDate
            ? dayjs(measure.lastAsOf?.asOfDate)
            : dayjs();

        const itemsBeforeNow = chartData
            .slice()
            .reverse()
            .filter((d) => dayjs(d.Date).isSameOrBefore(currentAsOf, 'date'));

        const itemsAfterNow = chartData
            .slice()
            .filter((d) => dayjs(d.Date).isAfter(currentAsOf, 'date'));

        if (chartDateOffset > 0) {
            itemsBeforeNow.splice(
                0,
                0,
                ...itemsAfterNow.splice(0, chartDateOffset).reverse()
            );
        } else if (chartDateOffset < 0) {
            itemsAfterNow.splice(
                0,
                0,
                ...itemsBeforeNow.splice(0, -chartDateOffset).reverse()
            );
        }

        let dataPointsBefore = 0;
        let dataPointsAfter = 0;

        while (dataPointsBefore + dataPointsAfter < MaxDataPoints) {
            if (
                (dataPointsBefore < IdeallyBefore || !itemsAfterNow.length) &&
                itemsBeforeNow.length
            ) {
                restrictedChartData.splice(
                    0,
                    0,
                    ...itemsBeforeNow.splice(0, 1)
                );
                dataPointsBefore++;
            } else if (itemsAfterNow.length) {
                restrictedChartData.push(...itemsAfterNow.splice(0, 1));
                dataPointsAfter++;
            } else {
                break;
            }
        }
    }

    const canShift = chartData && chartData.length > MaxDataPoints;

    const shiftBackEnabled =
        chartData &&
        chartData.length &&
        chartData[0].Date !== restrictedChartData[0].Date;

    const shiftForwardEnabled =
        chartData &&
        chartData.length &&
        chartData[chartData.length - 1].Date !==
            restrictedChartData[restrictedChartData.length - 1].Date;

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'row',
            height: props.height,
            width: '100%',
            alignItems: 'center',
        },
        shiftButtonContainer: {
            width: 32,
        },
        chartContainer: {
            flexGrow: 1,
        },
    });

    return (
        <div className={classNames.container}>
            {canShift && (
                <div className={classNames.shiftButtonContainer}>
                    {shiftBackEnabled && (
                        <IconButton
                            disabled={!shiftBackEnabled}
                            onClick={() => setChartDateOffset((o) => o - 1)}
                            iconProps={{ iconName: 'ChevronLeftSmall' }}
                        />
                    )}
                </div>
            )}
            <div className={classNames.chartContainer}>
                {(chartDisplay.key === ChartDisplay.ByFrequency ||
                    chartDisplay.key === ChartDisplay.Ftd) &&
                    measure &&
                    measureFormat && (
                        <MeasureComposedRechart
                            width={props.width}
                            height={props.height}
                            actualMode={chartActualMode}
                            data={restrictedChartData}
                            showForecast={measure.showForecast}
                            measureFormat={measureFormat}
                            hideLegend={!props.showLegend}
                            hideYAxis={!props.showAxis}
                            tooltipTitle={chartTooltipTitle}
                            statusOnly={isStatusOnly}
                            compact
                        />
                    )}
                {chartDisplay.key === ChartDisplay.Qtd &&
                    measure &&
                    measureFormat && (
                        <MeasureComposedRechart
                            width={props.width}
                            height={props.height}
                            actualMode={chartActualMode}
                            data={restrictedChartData}
                            showForecast={measure.showForecast}
                            measureFormat={measureFormat}
                            hideLegend={!props.showLegend}
                            hideYAxis={!props.showAxis}
                            tooltipTitle={chartTooltipTitle}
                            compact
                        />
                    )}
                {(chartDisplay.key === ChartDisplay.Ytd ||
                    chartDisplay.key === ChartDisplay.YtdVsFullYear) &&
                    !!chartData?.length &&
                    measureFormat && (
                        <YearProgressChart
                            width={props.width}
                            height={props.height}
                            yearChartData={chartData[0]}
                            measureFormat={measureFormat}
                            hideLegend={!props.showLegend}
                            hideAxis={!props.showAxis}
                            tooltipTitle={chartTooltipTitle}
                            showForecast={measure?.showForecast || false}
                            statusOnly={isStatusOnly}
                        />
                    )}
                {chartDisplay.key === ChartDisplay.Calculated &&
                    !!chartData &&
                    !!measure &&
                    !!measureFormat && (
                        <MeasureComposedRechart
                            width={props.width}
                            height={props.height}
                            actualMode={chartActualMode}
                            data={restrictedChartData}
                            showForecast={measure.showForecast}
                            measureFormat={measureFormat}
                            hideLegend={!props.showLegend}
                            hideYAxis={!props.showAxis}
                            tooltipTitle={chartTooltipTitle}
                            compact
                            isStacked={true}
                            formulaData={calculatedData?.measureValueFormula}
                        />
                    )}
            </div>
            {canShift && (
                <div className={classNames.shiftButtonContainer}>
                    {shiftForwardEnabled && (
                        <IconButton
                            disabled={!shiftForwardEnabled}
                            onClick={() => setChartDateOffset((o) => o + 1)}
                            iconProps={{ iconName: 'ChevronRightSmall' }}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export function HistoryChartV1(props: HistoryChartV1Props): JSX.Element {
    const chartType = props.chartType || 'line';

    const { hideActual, showTarget, showForecast } = props;

    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const { data: fyData } = useGetFinancialYearQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: currentFinancialYearCode || '',
        },
    });

    const fyStartDate = fyData?.financialYear?.startDate.substring(0, 10);

    const { currentTheme } = useThemes();

    const hasTargetPhasing = props.recentHistory.some((h) =>
        h.values?.some(
            (v) => v.seriesType?.name === MeasureSeriesNames.PhasedTarget
        )
    );

    const hasForecastPhasing = props.recentHistory.some((h) =>
        h.values?.some(
            (v) => v.seriesType?.name === MeasureSeriesNames.PhasedForecast
        )
    );

    let firstDataPoint: string | null = null;

    if (props.showTargetPhasing || props.showForecast) {
        const targetActualDates = orderBy(
            props.recentHistory
                .filter((h) =>
                    h.values?.some(
                        (v) =>
                            v.seriesType?.name === MeasureSeriesNames.Target ||
                            v.seriesType?.name === MeasureSeriesNames.Actual
                    )
                )
                .map((v) => v.asOfDate)
        );

        // If there are target / actuals use this as the first point
        firstDataPoint = targetActualDates.find((v) => v !== null) || null;
    }

    let statusColour: string;
    let statusColourLight: string;

    if (props.arrowColour === 'GREEN') {
        statusColour = MeasureStatusColours.onTarget;
        statusColourLight = '#dff6dd';
    } else if (props.arrowColour === 'RED') {
        statusColour = MeasureStatusColours.offTarget;
        statusColourLight = '#fde7e9';
    } else if (props.arrowColour === 'YELLOW') {
        statusColour = MeasureStatusColours.nearTarget;
        statusColourLight = '#fff4ce';
    } else {
        statusColour = currentTheme.palette.neutralTertiary;
        statusColourLight = currentTheme.palette.neutralLight;
    }

    if (props.recentHistory.length === 0) {
        return (
            <div
                style={{
                    width: props.width,
                    height: props.height,
                    position: 'relative',
                    textAlign: 'center',
                }}
            >
                <Text
                    block
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                    variant={'smallPlus'}
                />
            </div>
        );
    }

    const yAxisFormatter = (value: unknown): string => {
        const numberValue = value as number;
        if (numberValue) {
            switch (props.measureType) {
                case MeasureTypes.Percentage:
                    return (numberValue * 100).toFixed() + '%';
                case MeasureTypes.Numeric:
                case MeasureTypes.Currency: {
                    switch (props.multiplier) {
                        case Multipliers.K:
                            return `${props.currencySymbol || ''}${(
                                numberValue / 1000
                            ).toFixed(props.decimalPlaces)}K`;
                        case Multipliers.M:
                            return `${props.currencySymbol || ''}${(
                                numberValue / 1000000
                            ).toFixed(props.decimalPlaces)}M`;
                        case Multipliers.B:
                            return `${props.currencySymbol || ''}${(
                                numberValue / 1000000000
                            ).toFixed(props.decimalPlaces)}B`;
                        default:
                            return `${
                                props.currencySymbol || ''
                            }${numberValue.toFixed(props.decimalPlaces)}`;
                    }
                }
                default: {
                    // Status is used for graph
                    return (numberValue * 100).toFixed() + '%';
                }
            }
        } else {
            return '';
        }
    };

    const isMeasureTypeNumeric =
        props.measureType === MeasureTypes.Currency ||
        props.measureType === MeasureTypes.Percentage ||
        props.measureType === MeasureTypes.Numeric;

    const getValueForSeries = (
        h: MeasureValueHistoryItem,
        seriesName: MeasureSeriesNames
    ): number | string | null => {
        let value: number | string | null = null;

        if (isMeasureTypeNumeric) {
            const seriesValue = h.values?.find(
                (v) => v?.seriesType?.name === seriesName
            );
            if (seriesValue) {
                value =
                    seriesValue.decimalValue !== null
                        ? seriesValue.decimalValue
                        : seriesValue.stringValue;
            }
        } else {
            // Use status as target / actual for non numeric types.
            if (seriesName === MeasureSeriesNames.Target) {
                value = 1;
            } else {
                value = h.statusValue;
            }
        }

        return value;
    };

    const getStatusColourForItem = (h: MeasureValueHistoryItem): string => {
        if (h.arrowColour === 'GREEN') {
            return MeasureStatusColours.onTarget;
        } else if (h.arrowColour === 'RED') {
            return MeasureStatusColours.offTarget;
        } else {
            return MeasureStatusColours.nearTarget;
        }
    };

    const getTarget = (h: MeasureValueHistoryItem): number | string | null => {
        return getValueForSeries(h, MeasureSeriesNames.Target);
    };

    const getTargetPhasing = (
        h: MeasureValueHistoryItem
    ): number | string | null => {
        return getValueForSeries(h, MeasureSeriesNames.PhasedTarget);
    };

    const getForecastPhasing = (
        h: MeasureValueHistoryItem
    ): number | string | null => {
        return getValueForSeries(h, MeasureSeriesNames.PhasedForecast);
    };

    const getActual = (h: MeasureValueHistoryItem): number | string | null => {
        return getValueForSeries(h, MeasureSeriesNames.Actual);
    };

    let unDuplicated: MeasureValueHistoryItem[] = [];
    props.recentHistory.forEach((element) => {
        if (!unDuplicated.find((u) => u.asOfDate === element.asOfDate)) {
            unDuplicated.push(element);
        }
    });

    // We only want values with actuals
    if (!props.showTargetPhasing) {
        unDuplicated = unDuplicated.filter((d) =>
            d.values?.find(
                (v) => v.seriesType?.name === MeasureSeriesNames.Actual
            )
        );
    }

    let limitedHistory = props.limitHistoryCount
        ? unDuplicated.slice(
              Math.max(unDuplicated.length - props.limitHistoryCount, 0)
          )
        : unDuplicated;

    if (firstDataPoint) {
        limitedHistory = limitedHistory.filter((h) =>
            dayjs(firstDataPoint || '').isSameOrBefore(h.asOfDate || '')
        );
    }

    return (
        <ResponsiveContainer
            id={`historychart_${props.measureId}_${props.width}_${props.height}`}
            width={props.width}
            height={props.height}
            minWidth={0}
        >
            <ComposedChart data={limitedHistory}>
                {!!fyStartDate && (
                    <Tooltip
                        wrapperStyle={{ zIndex: 1000, outline: 'none' }}
                        content={(toolTipProps) => (
                            <HistoryChartToolTip
                                {...toolTipProps}
                                isCustom={props.isCustom}
                                frequencyPeriod={props.frequencyPeriod}
                                showForecast={showForecast}
                                hideActual={hideActual || false}
                                fyStartDate={fyStartDate}
                                showTarget={showTarget}
                            />
                        )}
                    />
                )}

                {!props.hideActual && chartType === 'line' && (
                    <Area
                        isAnimationActive={false}
                        type="monotone"
                        dataKey={getActual}
                        name="Actual"
                        stroke={statusColour}
                        strokeWidth="2"
                        fillOpacity={
                            props.hideFill || currentTheme.isInverted ? 0 : 1
                        }
                        fill={statusColourLight}
                        connectNulls={true}
                        dot={
                            !props.hideDots
                                ? {
                                      stroke: statusColour,
                                      strokeWidth: 2,
                                  }
                                : undefined
                        }
                        // dot={(
                        //     p: DotProps & { payload: MeasureValueHistoryItem }
                        // ) => {
                        //     const colour = getStatusColourForItem(p.payload);
                        //     return (
                        //         <CustomizedDot
                        //             {...p}
                        //             stroke={colour}
                        //             strokeWidth={2}
                        //         />
                        //     );
                        // }}
                        activeDot={{ r: 4 }}
                    ></Area>
                )}

                {showTarget && (
                    <Line
                        type="monotone"
                        dataKey={getTarget}
                        name="Target"
                        stroke={NeutralColors.gray100}
                        strokeWidth="2"
                        connectNulls={true}
                    />
                )}

                {/* {props.showTarget && chartType === 'bar' && (
                    <Bar
                        dataKey={getTarget}
                        name="Target"
                        fill={NeutralColors.gray100}
                        strokeWidth="2"
                    />
                )} */}

                {!props.hideActual && chartType === 'bar' && (
                    <Bar
                        dataKey={getActual}
                        name="Actual"
                        strokeWidth="2"
                        barSize={20}
                        fill={statusColour}
                    >
                        {limitedHistory.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={getStatusColourForItem(entry)}
                            />
                        ))}
                    </Bar>
                )}

                {props.showTargetPhasing &&
                    props.showPhasingAsBar &&
                    hasTargetPhasing && (
                        <Bar
                            dataKey={getTargetPhasing}
                            name="Target Phasing"
                            fill={SharedColors.cyan10}
                            barSize={20}
                            strokeWidth="2"
                        />
                    )}

                {props.showForecast &&
                    props.showPhasingAsBar &&
                    hasForecastPhasing && (
                        <Bar
                            dataKey={getForecastPhasing}
                            name="Forecast"
                            fill={SharedColors.orange10}
                            barSize={20}
                            strokeWidth="2"
                        />
                    )}

                {props.showTargetPhasing &&
                    !props.showPhasingAsBar &&
                    hasTargetPhasing && (
                        <Line
                            type="monotone"
                            dataKey={getTargetPhasing}
                            name="Target Phasing"
                            stroke={SharedColors.cyan10}
                            strokeWidth="2"
                            connectNulls={true}
                        />
                    )}

                {props.showForecast &&
                    !props.showPhasingAsBar &&
                    hasForecastPhasing && (
                        <Line
                            type="monotone"
                            dataKey={getForecastPhasing}
                            name="Forecast"
                            stroke={SharedColors.orange10}
                            strokeWidth="2"
                            connectNulls={true}
                        />
                    )}

                <XAxis
                    hide={!props.showAxis}
                    dataKey="asOfDate"
                    scale={chartType === 'bar' ? 'auto' : 'point'}
                    tick={{ fontSize: 12 }}
                    tickFormatter={(d): string =>
                        dayjs(d).format('DD MMM YYYY')
                    }
                />

                <YAxis
                    hide={!props.showAxis}
                    tick={{ fontSize: 12 }}
                    tickFormatter={yAxisFormatter}
                />

                {props.showLegend && <Legend />}
            </ComposedChart>
        </ResponsiveContainer>
    );
}

function HistoryChartToolTip({
    active,
    payload,
    isCustom,
    frequencyPeriod,
    hideActual,
    showTarget,
    showForecast,
    fyStartDate,
}: TooltipProps<ValueType, NameType> & {
    isCustom: boolean;
    frequencyPeriod: FrequencyPeriods | null | undefined;
    hideActual: boolean;
    showTarget: boolean;
    showForecast: boolean;
    fyStartDate: string;
}): JSX.Element {
    const { currentTheme } = useThemes();

    const styles = mergeStyleSets({
        chartTooltip: {
            fontFamily:
                '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif',
            padding: 16,
            fontSize: 12,
            backgroundColor: currentTheme.palette.white,
            boxShadow: currentTheme.effects.elevation4,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            textAlign: 'left',
        },
        chartTooltipActual: {
            padding: 0,
            margin: 0,
            fontSize: 18,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
        },
        chartTooltipLabel: {
            padding: 0,
            margin: 0,
        },
    });

    const getFormattedValueForSeries = (
        historyItem: MeasureValueHistoryItem,
        seriesName: MeasureSeriesNames
    ): string => {
        let formattedValue: string | null = null;

        historyItem.values?.forEach((v) => {
            if (v?.seriesType?.name === seriesName) {
                formattedValue =
                    v.formatStr ||
                    v.decimalValue?.toString() ||
                    v.stringValue ||
                    '';
            }
        });

        return formattedValue || '';
    };

    if (active && payload?.length && payload[0] && fyStartDate) {
        const dataItem = (
            payload[0] as {
                payload: MeasureValueHistoryItem;
            }
        ).payload;

        const label = getLabelForDate(
            fyStartDate,
            isCustom
                ? FrequencyPeriods.None
                : frequencyPeriod || FrequencyPeriods.None,
            dataItem?.asOfDate
        );

        const actualText = getFormattedValueForSeries(
            dataItem,
            MeasureSeriesNames.Actual
        );

        const targetText = getFormattedValueForSeries(
            dataItem,
            MeasureSeriesNames.Target
        );

        const phasingTargetText = getFormattedValueForSeries(
            dataItem,
            MeasureSeriesNames.PhasedTarget
        );

        const phasingForecastText = getFormattedValueForSeries(
            dataItem,
            MeasureSeriesNames.PhasedForecast
        );

        return (
            <div className={styles.chartTooltip}>
                {!!actualText && !hideActual && (
                    <p className={styles.chartTooltipActual}>
                        {actualText}
                        {!!targetText && (
                            <MeasureArrowIcon
                                arrowDirection={dataItem.arrowDirection}
                                arrowColour={dataItem.arrowColour}
                            />
                        )}
                    </p>
                )}

                {!!phasingTargetText && (
                    <div className={styles.chartTooltipLabel}>
                        {`PHASED TARGET: ${phasingTargetText}`}
                    </div>
                )}

                {!!phasingForecastText && showForecast && (
                    <div className={styles.chartTooltipLabel}>
                        {`FORECAST: ${phasingForecastText}`}
                    </div>
                )}

                {!!targetText && showTarget && (
                    <div className={styles.chartTooltipLabel}>
                        {`TARGET: ${targetText}`}
                    </div>
                )}

                <div className={styles.chartTooltipLabel}>
                    {isCustom ? `As Of: ${label}` : label}
                </div>
            </div>
        );
    }

    return <div />;
}

// function CustomizedDot(
//     props: DotProps & { stroke: string; strokeWidth: number }
// ): JSX.Element {
//     const { cx, cy, r, stroke, strokeWidth } = props;

//     return (
//         <circle
//             cx={cx}
//             cy={cy}
//             r={r}
//             stroke={stroke}
//             strokeWidth={strokeWidth}
//             fill="#fff"
//         />
//     );
// }

//export default HistoryChart;
export default React.memo(HistoryChart);
