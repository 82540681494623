import React, { CSSProperties } from 'react';
import { photoService } from '../../../services/photo.service';
import { useThemes } from '../../../hooks/useThemes';

export function PresentationUserImage(props: {
    userId: string | null | undefined;
    style?: CSSProperties;
}): JSX.Element | null {
    const { lightTheme } = useThemes();

    const userImageUrl = props.userId
        ? photoService.getImageUrl(props.userId)
        : null;

    let style: CSSProperties = {
        borderRadius: '50%',
        border: `solid 0.5vw ${lightTheme.palette.neutralDark}`,
    };

    if (props.style) {
        style = {
            ...style,
            ...props.style,
        };
    }

    return userImageUrl ? (
        <img
            src={userImageUrl}
            style={style}
            onError={(ev) => {
                ev.currentTarget.style.display = 'none';
            }}
        />
    ) : null;
}
