import React, { useEffect, useState } from 'react';
import {
    ChoiceGroup,
    IChoiceGroupOption,
    Label,
    MessageBar,
    MessageBarType,
    TextField,
    CompoundButton,
    mergeStyleSets,
    DefaultButton,
    PrimaryButton,
} from '@fluentui/react';
import { GetMissionTasksQuery, useGetMissionTasksQuery } from '../data/types';
import { TaskDropDown } from './inputs/TaskDropDown';
import { Access } from '../data/extendedTypes';

export type DependencyAcceptTypes = 'specified-task' | 'implied-task' | null;

export type DependencyAcceptModes =
    | 'assign-to'
    | 'duplicate-task'
    | 'new-task'
    | null;

export function DependencyAcceptForm(props: {
    tenantId: string | null | undefined;
    missionId: string | null;
    missionAccess: Access;
    saveError: string | undefined;
    isSaving: boolean;
    dependencyTaskName: string | null;
    onAcceptClicked: (
        acceptMode: DependencyAcceptModes,
        taskId: string | null,
        taskName: string | null
    ) => void;
}): JSX.Element {
    const { dependencyTaskName, onAcceptClicked } = props;

    const [acceptMode, setAcceptMode] = useState<DependencyAcceptModes>(null);

    const [step, setStep] = useState(1);

    const [acceptType, setAcceptType] = useState<DependencyAcceptTypes>(null);

    const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
    const [replaceTaskName, setReplaceTaskName] = useState(true);
    const [taskName, setTaskName] = useState(dependencyTaskName?.trim() || '');

    const { data: tasksData, loading: tasksLoading } = useGetMissionTasksQuery({
        skip: !props.missionId || !props.tenantId,
        variables: {
            tenantId: props.tenantId || '',
            missionId: props.missionId || '',
        },
    });

    useEffect(() => {
        if (acceptMode === 'assign-to') {
            const updatedTaskName =
                !replaceTaskName && selectedTaskId
                    ? tasksData?.tasks.find((t) => t.id === selectedTaskId)
                          ?.name
                    : dependencyTaskName;

            setTaskName(updatedTaskName?.trim() || '');
        }
    }, [
        dependencyTaskName,
        acceptMode,
        replaceTaskName,
        selectedTaskId,
        tasksData?.tasks,
    ]);

    useEffect(() => {
        // Reset task when the accept mode changes.
        setAcceptType(null);
        setSelectedTaskId(null);
        setReplaceTaskName(true);
        setTaskName(dependencyTaskName?.trim() || '');

        if (acceptMode) {
            setStep(2);
        }
    }, [acceptMode, dependencyTaskName]);

    // useEffect(() => {
    //     onChange(acceptMode, acceptType, selectedTaskId, taskName);
    // }, [acceptMode, acceptType, selectedTaskId, taskName, onChange]);

    const [steps, setSteps] = useState({
        showNext: false,
        showBack: false,
        showComplete: false,
    });

    useEffect(() => {
        if (step === 1) {
            setSteps({
                showNext: false,
                showBack: false,
                showComplete: false,
            });
        } else if (step === 2) {
            const taskSelected =
                acceptType === 'specified-task' ||
                (acceptType === 'implied-task' && !!selectedTaskId);

            switch (acceptMode) {
                case 'new-task':
                case 'assign-to':
                    setSteps({
                        showNext: taskSelected,
                        showBack: true,
                        showComplete: false,
                    });
                    break;
                case 'duplicate-task':
                    setSteps({
                        showNext: false,
                        showBack: true,
                        showComplete: taskSelected,
                    });
            }
        } else if (step === 3) {
            setSteps({
                showNext: false,
                showBack: true,
                showComplete: !!taskName?.length,
            });
        }
    }, [step, acceptMode, acceptType, selectedTaskId, taskName]);

    const completeButtonText =
        acceptMode === 'new-task'
            ? 'Accept & Add Task'
            : acceptMode === 'assign-to'
              ? 'Accept & Replace Task'
              : 'Accept & Merge Task';

    const completeButtonIcon =
        acceptMode === 'new-task'
            ? 'EventAccepted'
            : acceptMode === 'assign-to'
              ? 'Switch'
              : 'Combine';

    const handleBackClick = () => {
        if (step === 2) {
            setAcceptMode(null);
        }

        setStep((s) => s - 1);
    };

    const handleNextClick = () => {
        setStep((s) => s + 1);
    };

    const handleCompleteClicked = () => {
        onAcceptClicked(acceptMode, selectedTaskId, taskName);
    };

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    });

    const selectedTaskName = tasksData?.tasks.find(
        (t) => t.id === selectedTaskId
    )?.name;

    return (
        <div className={classNames.container}>
            {props.saveError && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                >
                    <span>{props.saveError}</span>
                </MessageBar>
            )}

            {step === 1 && (
                <DependencyAcceptOptions
                    tasks={tasksData?.tasks}
                    disabled={
                        !props.missionAccess.write ||
                        tasksLoading ||
                        props.isSaving
                    }
                    setAcceptMode={setAcceptMode}
                />
            )}

            {step === 2 && (
                <DependencyTaskSelection
                    acceptMode={acceptMode}
                    disabled={
                        !props.missionAccess.write ||
                        tasksLoading ||
                        props.isSaving
                    }
                    acceptType={acceptType}
                    isLoading={tasksLoading}
                    tasks={tasksData?.tasks}
                    selectedTaskId={selectedTaskId}
                    setAcceptType={setAcceptType}
                    setSelectedTaskId={setSelectedTaskId}
                />
            )}

            {step === 3 && (
                <div>
                    {acceptMode === 'assign-to' && (
                        <Label>
                            Replacing the task &quot;{selectedTaskName}&quot;
                            with &quot;{dependencyTaskName}&quot;.
                        </Label>
                    )}
                    <DependencyTaskNameInput
                        acceptMode={acceptMode}
                        acceptType={acceptType}
                        disabled={
                            !props.missionAccess.write ||
                            tasksLoading ||
                            props.isSaving
                        }
                        taskName={taskName}
                        replaceTaskName={replaceTaskName}
                        setReplaceTaskName={setReplaceTaskName}
                        setTaskName={setTaskName}
                    />
                </div>
            )}

            <div className={classNames.buttonContainer}>
                {steps.showBack && (
                    <DefaultButton text="Back" onClick={handleBackClick} />
                )}

                {steps.showNext && (
                    <PrimaryButton text="Next" onClick={handleNextClick} />
                )}

                {steps.showComplete && (
                    <PrimaryButton
                        text={completeButtonText}
                        iconProps={{ iconName: completeButtonIcon }}
                        disabled={!props.missionAccess.write || props.isSaving}
                        onClick={handleCompleteClicked}
                    />
                )}
            </div>
        </div>
    );
}

function DependencyTaskNameInput(props: {
    acceptMode: DependencyAcceptModes;
    acceptType: DependencyAcceptTypes;
    disabled: boolean;
    taskName: string;
    replaceTaskName: boolean;
    setReplaceTaskName: (replace: boolean) => void;
    setTaskName: (taskName: string) => void;
}) {
    const {
        acceptMode,
        acceptType,
        disabled,
        replaceTaskName,
        taskName,
        setReplaceTaskName,
        setTaskName,
    } = props;

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
    });

    return (
        <div className={classNames.container}>
            {acceptMode === 'assign-to' && (
                <ChoiceGroup
                    selectedKey={replaceTaskName ? 'replace' : 'keep'}
                    name="replaceTaskName"
                    disabled={disabled}
                    options={[
                        {
                            key: 'keep',
                            text: 'Keep existing task name',
                        },
                        {
                            key: 'replace',
                            text: 'Replace task name',
                        },
                    ]}
                    onChange={(
                        _ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
                        option?: IChoiceGroupOption
                    ): void => {
                        setReplaceTaskName(option?.key === 'replace');
                    }}
                    required
                />
            )}

            {(acceptMode === 'new-task' || acceptMode === 'assign-to') && (
                <TextField
                    value={taskName}
                    label={
                        acceptMode === 'new-task'
                            ? `What would you like to name this ${acceptType === 'specified-task' ? 'Specified Task' : 'Implied Task'}?`
                            : undefined
                    }
                    onChange={(_ev, newValue?: string): void => {
                        setTaskName(newValue || '');
                    }}
                    multiline
                    autoAdjustHeight
                    disabled={disabled || !replaceTaskName}
                />
            )}
        </div>
    );
}

function DependencyAcceptOptions(props: {
    tasks: GetMissionTasksQuery['tasks'] | null | undefined;
    disabled: boolean;
    setAcceptMode: (acceptType: DependencyAcceptModes) => void;
}) {
    const { tasks, disabled, setAcceptMode } = props;

    const acceptOptions = [
        {
            key: 'new-task' as DependencyAcceptModes,
            text: 'Accept as a New Task',
            secondaryText: 'Accept and add as a new specified or implied task.',
            iconProps: { iconName: 'EventAccepted' },
        },
    ];

    if (tasks?.filter((t) => t.missionId && !t.resourcedFromTask).length) {
        acceptOptions.push({
            key: 'assign-to',
            text: 'Replace Existing Task',
            secondaryText:
                'Accept and assign to any existing, manually written task already on your mission.',
            iconProps: { iconName: 'Switch' },
        });
    }

    if (tasks?.length) {
        acceptOptions.push({
            key: 'duplicate-task',
            text: 'Merge as Duplicate Request',
            secondaryText:
                'Accept and merge as a duplicate of a request that has already been accepted into your mission.',
            iconProps: { iconName: 'Combine' },
        });
    }

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
    });

    return (
        <div className={classNames.container}>
            <Label>How would you like to accept this task?</Label>

            {acceptOptions.map((a) => (
                <CompoundButton
                    key={a.key}
                    iconProps={a.iconProps}
                    secondaryText={a.secondaryText}
                    onClick={() => {
                        setAcceptMode(a.key);
                    }}
                    styles={{
                        root: {
                            width: '100%',
                            maxWidth: '100%',
                        },
                    }}
                    disabled={disabled}
                >
                    {a.text}
                </CompoundButton>
            ))}
        </div>
    );
}

function DependencyTaskSelection(props: {
    acceptMode: DependencyAcceptModes;
    acceptType: DependencyAcceptTypes;
    disabled: boolean;
    isLoading: boolean;
    tasks: GetMissionTasksQuery['tasks'] | null | undefined;
    selectedTaskId: string | null;
    setAcceptType: (acceptType: DependencyAcceptTypes) => void;
    setSelectedTaskId: (taskId: string | null) => void;
}) {
    const {
        acceptMode,
        acceptType,
        disabled,
        selectedTaskId,
        setSelectedTaskId,
        setAcceptType,
        isLoading,
        tasks,
    } = props;

    let taskDropdownLabel: string | undefined = undefined;
    let taskDropdownMode: 'specified-task' | 'implied-task' | 'both' | 'none' =
        'none';

    switch (acceptMode) {
        case 'new-task': {
            if (acceptType === 'specified-task') {
                taskDropdownMode = 'none';
            } else if (acceptType === 'implied-task') {
                taskDropdownMode = 'specified-task';
                taskDropdownLabel =
                    'What specified task would you like to contain this task?';
            }
            break;
        }
        case 'assign-to':
            taskDropdownMode = 'both';
            taskDropdownLabel =
                'What task would you like to replace with this request?';
            // if (acceptType === 'specified-task') {
            //     taskDropdownMode = 'specified-task';
            //     taskDropdownLabel =
            //         'What specified task would you like to replace with this request?';
            // } else if (acceptType === 'implied-task') {
            //     taskDropdownMode = 'implied-task';
            //     taskDropdownLabel =
            //         'What implied task would you like to replace with this request?';
            // }
            break;
        case 'duplicate-task': {
            taskDropdownMode = 'both';
            taskDropdownLabel = 'What task would you like to merge?';
            // if (acceptType === 'specified-task') {
            //     taskDropdownMode = 'specified-task';
            //     taskDropdownLabel =
            //         'What specified task would you like to merge?';
            // } else if (acceptType === 'implied-task') {
            //     taskDropdownMode = 'implied-task';
            //     taskDropdownLabel =
            //         'What implied task would you like to merge?';
            // }
            break;
        }
        default:
            taskDropdownMode = 'none';
    }

    let label = '';

    switch (acceptMode) {
        case 'new-task':
            label = 'What type of task would you like to add to your mission?';
            break;
        case 'assign-to':
            label = 'What type of task would you like to replace?';
            break;
        case 'duplicate-task':
            label = 'What type of task would you like to merge to?';
            break;
    }

    return (
        <div
            style={{
                display: 'flex',
                gap: 8,
                flexDirection: 'column',
            }}
        >
            {acceptMode === 'new-task' && (
                <>
                    <Label>{label}</Label>
                    <CompoundButton
                        iconProps={{ iconName: 'NewTeamProject' }}
                        primary={acceptType === 'specified-task'}
                        secondaryText="A critical project or initiative."
                        onClick={() => {
                            setAcceptType('specified-task');
                            setSelectedTaskId(null);
                        }}
                        styles={{
                            root: {
                                width: '100%',
                                maxWidth: '100%',
                            },
                        }}
                        disabled={disabled}
                    >
                        New Specified Task
                    </CompoundButton>

                    <CompoundButton
                        iconProps={{ iconName: 'AddEvent' }}
                        primary={acceptType === 'implied-task'}
                        secondaryText="An action required to complete a given specified task."
                        onClick={() => {
                            setAcceptType('implied-task');
                            setSelectedTaskId(null);
                        }}
                        styles={{
                            root: {
                                width: '100%',
                                maxWidth: '100%',
                            },
                        }}
                        disabled={disabled}
                    >
                        New Implied Task
                    </CompoundButton>
                </>
            )}

            {taskDropdownMode === 'specified-task' && (
                <TaskDropDown
                    canSelectSpecifiedTasks={true}
                    canSelectImpliedTasks={false}
                    disableResourcedFromTasks={acceptMode === 'assign-to'}
                    isDataLoaded={!isLoading}
                    missionTasks={tasks}
                    selectedKey={selectedTaskId}
                    onTaskChange={(taskId: string | null): void =>
                        setSelectedTaskId(taskId)
                    }
                    placeholder="Select a specified task"
                    label={taskDropdownLabel}
                    disabled={disabled}
                />
            )}

            {taskDropdownMode === 'both' && (
                <TaskDropDown
                    canSelectSpecifiedTasks={true}
                    canSelectImpliedTasks={true}
                    disableResourcedFromTasks={acceptMode === 'assign-to'}
                    isDataLoaded={!isLoading}
                    missionTasks={tasks}
                    selectedKey={selectedTaskId}
                    onTaskChange={(taskId, taskType): void => {
                        setSelectedTaskId(taskId);
                        setAcceptType(taskType);
                    }}
                    label={taskDropdownLabel || ''}
                    placeholder="Select a task"
                    disabled={disabled}
                />
            )}
        </div>
    );
}
