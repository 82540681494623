import * as React from 'react';
import { Stack } from '@fluentui/react';
import { useStateContext } from '../../../services/contextProvider';
import { useGetAllTasksQuery } from '../../../data/types';
import { TaskFlatList } from '../../../components/TaskFlatList';
import {
    DefaultTaskFilters,
    TaskFilterBar,
    TaskFilters,
} from '../../MissionBuilder/components/TaskFilterBar';

export function ReportTasks(props: {
    onTaskClick: (missionId: string, taskId: string) => void;
}): JSX.Element {
    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const [filters, setFilters] =
        React.useState<TaskFilters>(DefaultTaskFilters);

    const { data, loading } = useGetAllTasksQuery({
        skip: !currentTenantId || !currentFinancialYearCode,
        variables: {
            tenantId: currentTenantId || '',
            teamId: null,
            financialYearCode: currentFinancialYearCode || '',
            forDateTime: filters?.forDateTime.isOn
                ? filters.forDateTime.selectedDate || null
                : null,
        },
        fetchPolicy: filters?.forDateTime.isOn ? 'no-cache' : 'network-only',
    });

    const handleTaskClick = (taskId: string) => {
        const missionId = data?.tasks?.find((t) => t.id === taskId)?.missionId;
        if (missionId) {
            props.onTaskClick(missionId, taskId);
        }
    };

    const handleSetForDateTime = (forDateTime: string | null) => {
        if (filters) {
            setFilters({
                ...filters,
                forDateTime: {
                    isOn: filters?.forDateTime.isOn,
                    selectedDate: forDateTime,
                },
            });
        }
    };

    const handleFiltersChanged = (f: TaskFilters) => setFilters(f);

    return (
        <Stack tokens={{ childrenGap: 4 }} styles={{ root: { marginTop: 8 } }}>
            {!!data?.tasks && (
                <Stack.Item>
                    <TaskFilterBar
                        tasks={data?.tasks}
                        filters={filters}
                        onFiltersChanged={handleFiltersChanged}
                        searchBoxPlaceholder={'Filter by mission or keyword'}
                        showHistoricalFilter
                    />
                </Stack.Item>
            )}
            <TaskFlatList
                tasks={data?.tasks}
                tasksLoading={loading}
                onTaskClick={handleTaskClick}
                filters={filters}
                setForDateTime={handleSetForDateTime}
                allowExport
                defaultColumns={[
                    'displaySequence',
                    'missionName',
                    'specifiedTaskName',
                    'name',
                    'start',
                    'due',
                    'done',
                    'percentComplete',
                ]}
            />
        </Stack>
    );
}
