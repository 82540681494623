import React from 'react';
import {
    Text,
    IconButton,
    mergeStyleSets,
    Icon,
    IContextualMenuProps,
    Shimmer,
    ShimmerElementType,
    IButtonStyles,
} from '@fluentui/react';
import { useViewport } from '../hooks/useViewport';

export function Chip(props: {
    text?: string | null;
    iconName?: string;
    backgroundColor?: string;
    menuProps?: IContextualMenuProps | undefined;
    disabled?: boolean;
    hideText?: boolean;
    chipTextWidth?: number;
    enableShimmer?: boolean;
    collapseOnMobile?: boolean;
}): JSX.Element {
    const { width } = useViewport();

    const isMobile = width < 620;

    const hideText =
        props.hideText || !props.text || (props.collapseOnMobile && isMobile);

    const hideSelect = !props.menuProps;

    // On mobile don't have a separate button for the menu.
    const showMenuUnderMainIcon =
        !!props.menuProps && props.collapseOnMobile && isMobile;

    const styles = mergeStyleSets({
        chip: {
            display: 'flex',
            textAlign: 'left',
            verticalAlign: 'top',
            background: `${props.backgroundColor}36`,
            paddingLeft: 4,
            paddingRight: 4,
            color: 'white',
            borderRadius: 8,
            lineHeight: 24,
        },
        chipIcon: {
            color: props.backgroundColor,
            marginLeft: 4,
            marginRight: 4,
        },
        chipIconCollapsed: {
            color: props.backgroundColor,
            marginLeft: 0,
            marginRight: 0,
        },
        chipText: {
            width: props.chipTextWidth,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textTransform: 'uppercase',
        },
    });

    const buttonStyles: IButtonStyles = {
        root: {
            height: 24,
            width: 24,
        },
        menuIcon: {
            fontSize: 14,
            color: props.backgroundColor,
        },
    };

    const shimmerWidth =
        (!hideText ? props.chipTextWidth ?? 0 : 0) +
        (!hideSelect ? 30 : 0) +
        (props.iconName ? 20 : 0) +
        8;

    return (
        <Shimmer
            isDataLoaded={!props.enableShimmer}
            width={shimmerWidth}
            shimmerElements={[
                {
                    type: ShimmerElementType.line,
                    height: 24,
                },
            ]}
        >
            <span
                className={styles.chip}
                style={{
                    paddingLeft:
                        isMobile && props.collapseOnMobile ? 0 : undefined,
                    paddingRight:
                        isMobile && props.collapseOnMobile ? 0 : undefined,
                }}
            >
                {!!props.iconName && (
                    <>
                        {showMenuUnderMainIcon && props.menuProps ? (
                            <IconButton
                                className={styles.chipIconCollapsed}
                                iconProps={{ iconName: props.iconName }}
                                disabled={props.disabled}
                                onRenderMenuIcon={() => null}
                                menuProps={props.menuProps}
                            />
                        ) : (
                            <Icon
                                iconName={props.iconName}
                                className={styles.chipIcon}
                                title={props.text || undefined}
                            />
                        )}
                    </>
                )}
                {!hideText && (
                    <Text
                        variant="xSmall"
                        className={styles.chipText}
                        style={{
                            marginRight: hideSelect ? 8 : 0,
                            lineHeight: '24px',
                        }}
                    >
                        {props.text}
                    </Text>
                )}
                {!hideSelect && !showMenuUnderMainIcon && (
                    <IconButton
                        disabled={props.disabled}
                        styles={buttonStyles}
                        menuProps={props.menuProps}
                    />
                )}
            </span>
        </Shimmer>
    );
}
