import React, { PropsWithChildren, useEffect, useState } from 'react';

import {
    Text,
    ICommandBarItemProps,
    CommandBar,
    ICommandBarData,
    useTheme,
    mergeStyleSets,
    AnimationStyles,
    IButtonProps,
    ActionButton,
    FontSizes,
    FontWeights,
    IButtonStyles,
} from '@fluentui/react';
import ExpandCollapse from '../../../components/ExpandCollapse';
import { useViewport } from '../../../hooks/useViewport';
import { useEngagement } from '../../../components/engagement/EngagementContext';
import { EngagementTargetKeys } from '../../../components/engagement/Engagements';
import { EngagementTarget } from '../../../components/engagement/EngagementTarget';

export interface AreaContainerButton extends ICommandBarItemProps {
    hideOnCollapse?: boolean;
    allowOverflow?: boolean;
    engagementTargetKey?: EngagementTargetKeys;
}

type AreaContainerProps = PropsWithChildren<{
    title?: string | JSX.Element | null;
    subTitle?: string | JSX.Element | null;
    isCollapsable: boolean;
    initialState?: 'expanded' | 'collapsed';
    onStateChanged?: (state: 'expanded' | 'collapsed') => void;
    commandBarButtons?: AreaContainerButton[];
}>;

export function AreaContainer({
    title = null,
    subTitle = null,
    isCollapsable,
    initialState = 'expanded',
    onStateChanged,
    commandBarButtons,
    children,
}: AreaContainerProps): JSX.Element {
    const [isExpanded, setIsExpanded] = useState<boolean>(
        initialState === 'expanded'
    );

    const currentTheme = useTheme();
    const { width } = useViewport();

    const mobileBreakpoint = 560;

    useEffect(() => setIsExpanded(initialState === 'expanded'), [initialState]);

    useEffect(() => {
        if (onStateChanged) {
            onStateChanged(isExpanded ? 'expanded' : 'collapsed');
        }
    }, [isExpanded, onStateChanged]);

    const classNames = mergeStyleSets({
        areaContainer: {
            backgroundColor: currentTheme.palette.neutralLighter,
            width: '100%',
            '@media print': {
                backgroundColor: '#fff',
            },
        },
        areaContainerTitle: {
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipses',
            lineHeight: 32,
            marginTop: 8,
            height: 28,
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            fontSize:
                width > mobileBreakpoint
                    ? FontSizes.xLarge
                    : FontSizes.mediumPlus,
            fontWeight: FontWeights.semibold,
            marginLeft: isCollapsable ? 0 : 8,
        },
        headerContainer: {
            paddingLeft: 8,
            paddingTop: 0,
            paddingBottom: 8,
            paddingRight: 8,
            borderBottom: `solid 1px ${currentTheme.palette.neutralQuaternary}`,
        },
        titleAndCommandBar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        expandAndTitle: {
            minWidth: '10%',
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'row',
        },
        commandBarContainer: {
            minWidth: '10%',
            flexGrow: 1,
        },
        expandedContainer: {
            paddingLeft: 8,
            paddingTop: 4,
            paddingRight: 8,
            paddingBottom: 4,
        },
        collapsedContainer: {
            display: 'none',
            ...AnimationStyles.slideUpOut10,
        },
    });

    const onReduceData = (
        data: ICommandBarData
    ): ICommandBarData | undefined => {
        const overflowKeys =
            commandBarButtons
                ?.filter((b) => b.allowOverflow)
                .map((b) => b.key) || [];

        if (data.farItems?.some((fi) => !fi.iconOnly)) {
            return {
                ...data,
                farItems: data.farItems.map((fi) => {
                    return {
                        ...fi,
                        iconOnly: true,
                    };
                }),
            };
        } else if (data.overflowItems.length < overflowKeys.length) {
            return {
                ...data,
                farItems: data.farItems?.filter(
                    (fi) => overflowKeys.indexOf(fi.key) < 0
                ),
                overflowItems:
                    data.farItems?.filter(
                        (fi) => overflowKeys.indexOf(fi.key) > -1
                    ) || [],
            };
        }

        // Return this when when can't reduce anymore.
        return undefined;
    };

    const onGrowData = (data: ICommandBarData): ICommandBarData | undefined => {
        if (data.farItems?.some((fi) => fi.iconOnly)) {
            return {
                ...data,
                farItems: data.farItems.map((fi) => {
                    return {
                        ...fi,
                        iconOnly: false,
                    };
                }),
            };
        }
        // Return this when when can't grow anymore.
        return undefined;
    };

    return (
        <React.Fragment>
            <div className={`${classNames.areaContainer} sticky`}>
                <div className={classNames.headerContainer}>
                    <div className={classNames.titleAndCommandBar}>
                        <div className={classNames.expandAndTitle}>
                            {isCollapsable && (
                                <div style={{ paddingTop: 8 }}>
                                    <ExpandCollapse
                                        isExpanded={isExpanded}
                                        onExpand={(): void =>
                                            setIsExpanded(true)
                                        }
                                        onCollapse={(): void =>
                                            setIsExpanded(false)
                                        }
                                    />
                                </div>
                            )}

                            <Text
                                block
                                variant="mediumPlus"
                                className={classNames.areaContainerTitle}
                                title={typeof title === 'string' ? title : ''}
                            >
                                {title ?? ' '}
                            </Text>
                        </div>

                        <div className={classNames.commandBarContainer}>
                            <CommandBar
                                styles={{
                                    root: {
                                        padding: 0,
                                        paddingLeft: isCollapsable ? 0 : 8,
                                        backgroundColor: 'transparent',
                                    },
                                    primarySet: {
                                        alignItems: 'flex-end',
                                    },
                                    secondarySet: {
                                        marginTop: 8,
                                    },
                                }}
                                items={[]}
                                overflowButtonAs={AreaContainerButton}
                                buttonAs={AreaContainerButton}
                                farItems={commandBarButtons?.map((b) => {
                                    return {
                                        ...b,
                                        buttonStyles: {
                                            root: {
                                                backgroundColor: 'transparent',
                                            },
                                        },
                                        className: 'no-print',
                                    };
                                })}
                                overflowItems={[]}
                                onReduceData={onReduceData}
                                onGrowData={onGrowData}
                            />
                        </div>
                    </div>

                    {!!subTitle && (
                        <Text
                            variant="medium"
                            block
                            style={{
                                marginLeft: isCollapsable ? 32 : 8,
                            }}
                        >
                            {subTitle}
                        </Text>
                    )}
                </div>
            </div>
            <div
                className={
                    isExpanded
                        ? classNames.expandedContainer
                        : classNames.collapsedContainer
                }
            >
                {children}
            </div>
        </React.Fragment>
    );
}

function AreaContainerButton(
    props: IButtonProps & { engagementTargetKey?: EngagementTargetKeys }
): JSX.Element {
    const { dismissEngagementForTarget } = useEngagement();

    const styles: IButtonStyles = {
        root: {
            height: 32, // Same as IconButton
        },
    };

    if (props.engagementTargetKey) {
        return (
            <EngagementTarget engagementTargetKey={props.engagementTargetKey}>
                <ActionButton
                    {...props}
                    styles={styles}
                    onClick={(ev) => {
                        if (props.engagementTargetKey) {
                            dismissEngagementForTarget(
                                props.engagementTargetKey
                            );
                        }
                        if (props.onClick) {
                            props.onClick(ev);
                        }
                    }}
                />
            </EngagementTarget>
        );
    } else {
        return <ActionButton {...props} styles={styles} />;
    }
}
