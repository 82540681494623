import { useState, useEffect } from 'react';

const useGeoLocation = (options?: {
    ipAddress: string | null | undefined;
}): {
    country?: string;
    error: boolean;
    isLoading: boolean;
    ipAddress?: string | null;
} => {
    const [country, setCountry] = useState<string>();
    const [ipAddress, setIpAddress] = useState(options?.ipAddress);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isCancelled = false;
        async function fetchAPI() {
            setIsLoading(true);

            const api = options?.ipAddress
                ? `https://api.country.is/${options?.ipAddress}`
                : 'https://api.country.is';

            await fetch(api)
                .then((res) => {
                    if (!res.ok) {
                        throw Error(res.statusText);
                        return null;
                    }
                    return res.json();
                })
                .then((res) => {
                    if (res && !isCancelled) {
                        setCountry(res.country);
                        setIpAddress(res.ip);
                    }
                })
                .catch((err) => setError(err))
                .finally(() => setIsLoading(false));
        }
        fetchAPI();
        return () => {
            isCancelled = true;
        };
    }, [options?.ipAddress]);

    return { country, error, isLoading, ipAddress };
};

export default useGeoLocation;
