import * as React from 'react';
import { authService } from '../../../services/auth.service';
import { useStateContext } from '../../../services/contextProvider';
import { Stack, DefaultButton, Label, FontSizes } from '@fluentui/react';
import { ImportResults, ImportResult } from './ImportResults';
import { convertDataUrlToBlob, readFile } from '../../Measure/utils/fileUtils';

export function ImportPivot(): JSX.Element {
    const [importReady, setImportReady] = React.useState<boolean>(false);
    const [importResults, setImportResults] =
        React.useState<ImportResult | null>();
    const [importMessage, setImportMessage] = React.useState<string | null>('');
    const [importError, setImportError] = React.useState<string | null>('');
    const [importFileData, setImportFileData] = React.useState<
        string | ArrayBuffer | null
    >();
    const [isExcel, setIsExcel] = React.useState<boolean>(false);
    const { currentTenantId, configuration } = useStateContext();

    const host = configuration.importerHost;
    const endpoint = configuration.importerEndpoint;
    const code = configuration.importerFnKey;

    const onFileChange = async (
        e: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
        setImportMessage(null);
        setImportError(null);
        setImportReady(false);
        setImportResults(null);
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.name.toLowerCase().endsWith('.xlsx')) {
                setIsExcel(true);
            } else if (!file.name.toLowerCase().endsWith('.json')) {
                setImportError('Only .xslx and .json files are supported');
                setImportFileData(null);
                return;
            }
            const importDataUrl = await readFile(file);
            setImportFileData(importDataUrl);
        }
    };
  
    async function uploadTemplate() {
        setImportMessage('Importing...');
        setImportResults(null);
        setImportError(null);

        const token = await authService.getTokenAsync();
        const method = isExcel ? 'excel_import' : 'json_import';
        const url = `${host}/api/${method}?endpoint=${endpoint}&tenantId=${currentTenantId}`;
        const blob = convertDataUrlToBlob(importFileData);

        try {
            const response = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    'x-functions-key': `${code}`,
                },
                method: 'POST',
                body: blob,
            });

            if (response.ok) {
                const result = await response.json();
                setImportMessage(null);
                setImportResults(result);
            } else {
                const result = await response.text();
                setImportMessage(null);
                setImportError(result);
                // setImportError(`${response.status}: ${response.statusText}`);
            }
        } catch (error) {
            console.log(error);
            setImportMessage(null);
            setImportError('Failure to connect to importer');
        }
    }

    return (
        <Stack
            tokens={{
                childrenGap: '24',
            }}
            styles={{
                root: { paddingTop: '24px' },
            }}
        >
            <Label>Upload a Template File</Label>
            <div
                style={{
                    fontSize: FontSizes.size12,
                    marginTop: '0',
                }}
            >
                (either in .xlsx or .json format)
            </div>

            <input type="file" onChange={onFileChange} />

            {importFileData && (
                <Stack.Item align="start">
                    <DefaultButton
                        iconProps={{
                            iconName: 'Upload',
                        }}
                        text="Upload File"
                        onClick={(): void => {
                            setImportReady(true);
                        }}
                    />
                </Stack.Item>
            )}
            {importReady && (
                <Stack.Item align="start">
                    <DefaultButton
                        iconProps={{
                            iconName: 'UnstackSelected',
                        }}
                        text="Run Import"
                        onClick={uploadTemplate}
                    />
                </Stack.Item>
            )}
            {importMessage && (
                <Stack.Item
                    styles={{
                        root: {
                            color: 'black',
                            margin: '10px 0',
                        },
                    }}
                >
                    {importMessage}
                </Stack.Item>
            )}
            {importResults && <ImportResults results={importResults} />}
            {importError && (
                <Stack.Item
                    styles={{
                        root: {
                            color: 'red',
                            margin: '10px 0',
                        },
                    }}
                >
                    {importError}
                </Stack.Item>
            )}
        </Stack>
    );
}
