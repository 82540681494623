import React from 'react';
import { Guid } from 'guid-typescript';
import {
    DivisionQl,
    refetchGetDivisionsQuery,
    useUpdateDivisionMutation,
} from '../../../data/types';
import { EditPanel } from '../../../components/shared/EditPanel';
import useForm from '../../../hooks/useForm';
import { useStateContext } from '../../../services/contextProvider';
import {
    TextField,
    Stack,
    Toggle,
    ColorPicker,
    IColor,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import { FinancialYearPicker } from '../../../components/inputs/FinancialYearPicker';
import { defaultTheme, useThemes } from '../../../hooks/useThemes';

export type DivisionEditPanelProps = {
    showPanel: boolean;
    division?: DivisionQl | null;
    onCancel: () => void;
    onSave: () => void;
};

export function DivisionEditPanel(props: DivisionEditPanelProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const defaultPrimaryColour = defaultTheme.palette.themePrimary.replace(
        '#',
        ''
    );

    const { checkAccessibility } = useThemes();

    const [updateDivision, { loading: isSaving, error: saveError }] =
        useUpdateDivisionMutation({
            onCompleted: props.onSave,
            refetchQueries: [
                refetchGetDivisionsQuery({
                    tenantId: currentTenantId || '',
                    financialYearCode: null,
                }),
            ],
        });

    type formValuesType = {
        code: string;
        name: string;
        purpose: string;
        vision: string;
        values: string;
        primaryColourHex: string | null;
        canUnpublishReport: boolean;
        financialYearId: string;
        utcInactive: string | null;
    };

    const formDefaultValues: formValuesType = {
        code: props.division?.code || '',
        name: props.division?.name || '',
        purpose: props.division?.purpose || '',
        vision: props.division?.vision || '',
        values: props.division?.values || '',
        primaryColourHex: props.division?.primaryColourHex || null,
        canUnpublishReport: props.division?.canUnpublishReport || false,
        financialYearId: props.division?.financialYear?.id || '',
        utcInactive: props.division?.utcInactive || null,
    };

    const formCallback = (values: formValuesType): void => {
        updateDivision({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    id: props.division?.id || Guid.createEmpty().toString(),
                    code: values.code,
                    name: values.name,
                    purpose: values.purpose,
                    vision: values.vision,
                    values: values.values,
                    primaryColourHex: values.primaryColourHex,
                    canUnpublishReport: values.canUnpublishReport,
                    financialYearId: values.financialYearId,
                    utcInactive: values.utcInactive,
                    version: props.division?.version || '',
                },
            },
        });
    };

    const formValidate = (
        values: formValuesType
    ): {
        [key: string]: string;
    } => {
        const formErrorMessages = {
            code: '',
            name: '',
            purpose: '',
            vision: '',
            values: '',
            financialYearId: '',
        };

        if (!values.code) {
            formErrorMessages.code = 'Enter a code';
        }

        if (!values.name) {
            formErrorMessages.name = 'Enter a name';
        }

        if (!values.financialYearId) {
            formErrorMessages.financialYearId = 'Select a financial year';
        }

        return formErrorMessages;
    };

    const { errors, handleChange, handleSubmit, updateValue, values } = useForm(
        formDefaultValues,
        formCallback,
        formValidate
    );

    const updateColor = (_ev: React.SyntheticEvent, colorObj: IColor) => {
        updateValue('primaryColourHex', colorObj.hex);
    };

    const accessibility = values.primaryColourHex
        ? checkAccessibility(`#${values.primaryColourHex}`)
        : null;

    return (
        <EditPanel
            activeViewName="DivisionEdit"
            onDismiss={props.onCancel}
            showPanel={props.showPanel}
            headerText={props.division?.id ? 'Edit Division' : 'New Division'}
            isSaving={isSaving}
            isValid={true}
            saveErrorMessage={saveError?.message || null}
            onUpdateClick={handleSubmit}
        >
            <Stack tokens={{ childrenGap: 8 }}>
                <TextField
                    label="Code"
                    required
                    name="code"
                    autoComplete="off"
                    defaultValue={formDefaultValues.code}
                    errorMessage={errors.code}
                    onChange={handleChange}
                />
                <TextField
                    label="Name"
                    required
                    name="name"
                    autoComplete="off"
                    defaultValue={formDefaultValues.name}
                    errorMessage={errors.name}
                    onChange={handleChange}
                />

                <FinancialYearPicker
                    required
                    label="Select a financial year"
                    placeholder="Select a financial year"
                    selectedFinancialYearId={values.financialYearId}
                    errorMessage={errors.financialYearId}
                    onChange={(financialYear): void => {
                        updateValue('financialYearId', financialYear?.id);
                    }}
                />

                <TextField
                    label="Purpose"
                    required
                    multiline
                    autoAdjustHeight
                    name="purpose"
                    autoComplete="off"
                    defaultValue={formDefaultValues.purpose}
                    errorMessage={errors.purpose}
                    onChange={handleChange}
                />

                <TextField
                    label="Vision"
                    required
                    multiline
                    autoAdjustHeight
                    name="vision"
                    autoComplete="off"
                    defaultValue={formDefaultValues.vision}
                    errorMessage={errors.vision}
                    onChange={handleChange}
                />

                <TextField
                    label="Values"
                    required
                    multiline
                    autoAdjustHeight
                    name="values"
                    autoComplete="off"
                    defaultValue={formDefaultValues.values}
                    errorMessage={errors.values}
                    onChange={handleChange}
                />

                <Toggle
                    label="Primary Colour"
                    onText="Custom"
                    offText="Default"
                    checked={!!values.primaryColourHex}
                    onChange={(_ev, checked): void => {
                        updateValue(
                            'primaryColourHex',
                            checked ? defaultPrimaryColour : null
                        );
                    }}
                />

                {values.primaryColourHex !== null && (
                    <ColorPicker
                        color={`#${values.primaryColourHex}`}
                        onChange={updateColor}
                        alphaType="none"
                        showPreview={true}
                    />
                )}

                {values.primaryColourHex !== null && accessibility !== null && (
                    <MessageBar
                        messageBarType={
                            accessibility.lightIsAccessible
                                ? MessageBarType.success
                                : MessageBarType.warning
                        }
                    >
                        {accessibility.lightIsAccessible
                            ? "The light theme doesn't have any accessibility issues."
                            : 'The light theme has accessibility errors. Each pair of colors below should produce legible text and have a minimum contrast of 4.5'}
                    </MessageBar>
                )}

                {values.primaryColourHex !== null && accessibility !== null && (
                    <MessageBar
                        messageBarType={
                            accessibility.darkIsAccessible
                                ? MessageBarType.success
                                : MessageBarType.warning
                        }
                    >
                        {accessibility.darkIsAccessible
                            ? "The dark theme doesn't have any accessibility issues."
                            : 'The dark theme has accessibility errors. Each pair of colors below should produce legible text and have a minimum contrast of 4.5'}
                    </MessageBar>
                )}

                <Toggle
                    label="Active Status"
                    onText="Active"
                    offText="Inactive"
                    checked={!values.utcInactive}
                    onChange={(
                        _ev: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                    ): void => {
                        updateValue(
                            'utcInactive',
                            checked ? null : new Date().toISOString()
                        );
                    }}
                />

                <Toggle
                    label="Can users unpublish reports?"
                    onText="Yes"
                    offText="No"
                    checked={values.canUnpublishReport}
                    onChange={(
                        _ev: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                    ): void => {
                        updateValue('canUnpublishReport', !!checked);
                    }}
                />
            </Stack>
        </EditPanel>
    );
}
