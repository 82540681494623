import { TaskWithStatus } from '../../../data/extendedTypes';
import { useGetDependenciesQuery } from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';

export type ContributorTask = TaskWithStatus & {
    id: string | null;
    parentTaskId: string | null;
    name: string | null;
    percentComplete: number;
    isDuplicate: boolean;
    missionId: string | null;
    resourcedFromTaskId: string | null;
    utcAccepted: string | null;
    utcRejected: string | null;
    utcResourceRemoved: string | null;
    isPercentageCompleteFromResources: boolean;
    isPercentageCompleteFromSubTasks: boolean;
    effortWeight: number;
    costWeight: number;
    tags: {
        id: string | null;
        name: string | null;
    }[];
    resource: {
        userId: string | null;
        userContributorFYs: {
            code: string | null;
        }[];
    } | null;
    taskCategory: {
        id: string | null;
        name: string | null;
        colourHex: string | null;
    } | null;
    commentCount: number;
    lastComment: {
        id: string | null;
        username: string | null;
        userId: string | null;
        text: string | null;
        utcCreated: string;
    } | null;
    resourcedTasks: {
        id: string | null;
        resource: {
            id: string | null;
            userId: string | null;
            displayName: string | null;
        } | null;
    }[];
    resourcedFromTask: {
        name?: string | null;
        start?: string | null;
        due?: string | null;
        done?: string | null;
        utcPostponed: string | null;
        utcCancelled: string | null;
        utcAtRisk: string | null;
        mission?: {
            id: string | null;
            userId: string | null;
            owner: string | null;
            title?: string | null;
            rights: {
                read: boolean;
                write: boolean;
            };
        } | null;
        parentTask?: {
            name?: string | null;
            taskCategory?: {
                name?: string | null;
                colourHex?: string | null;
            } | null;
        } | null;
        resourcedTasks?:
            | {
                  resource?: {
                      displayName: string | null;
                  } | null;
              }[]
            | null;
    } | null;
};

export function useContributorTasks(
    userId: string | null | undefined
): ContributorTask[] {
    const { currentFinancialYearCode, currentTenantId } = useStateContext();

    const { data } = useGetDependenciesQuery({
        skip: !currentTenantId || !currentFinancialYearCode || !userId,
        variables: {
            tenantId: currentTenantId || '',
            userId: userId || '',
            financialYearCode: currentFinancialYearCode || '',
        },
    });

    const resourcedTasks =
        data?.dependencies?.filter((d) =>
            // Does the task have an outstanding dependency for this mission user?
            (d?.resourcedTasks || []).some(
                (rt) =>
                    (!rt?.utcAccepted || rt?.missionId === null) &&
                    !rt?.utcRejected &&
                    rt?.resource?.userId &&
                    rt?.resource?.userId.toUpperCase() === userId?.toUpperCase()
            )
        ) || [];

    const contributorTasks = resourcedTasks
        .map((t) => {
            // Add the resourcing task on to the resourced tasks so that this can be used later on.
            return {
                ...t,
                resourcedTasks: t.resourcedTasks.map((rt) => {
                    return {
                        isPercentageCompleteFromResources: false,
                        isPercentageCompleteFromSubTasks: false,
                        isDuplicate: false,
                        resourcedFromTaskId: t.id,
                        resourcedFromTask: t,
                        taskCategory: t.parentTask?.taskCategory || null,
                        ...rt,
                    };
                }),
            };
        })
        .flatMap((d) => d.resourcedTasks)
        .filter(
            (rt) =>
                rt?.resource?.userId &&
                rt.resource?.userId.toUpperCase() === userId?.toUpperCase()
        );

    return contributorTasks;
}
