import React, { useState } from 'react';
import {
    ActionButton,
    ConstrainMode,
    DatePicker,
    DetailsListLayoutMode,
    FontSizes,
    IColumn,
    IconButton,
    MessageBar,
    MessageBarType,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
} from '@fluentui/react';
import {
    MonthRow,
    FrequencyTypes,
    recalcYtd,
    getLabelFreq,
} from '../utils/measureUtils';
import {
    FrequencyPeriods,
    MeasureTypes,
    Multipliers,
} from '../../../data/types';
import { useMeasureValueFormatter } from '../../../hooks/useMeasureValueFormatter';
import {
    MeasureAsOfEditPanel,
    MeasureAsOfEditPanelMeasureAsOfType,
} from './MeasureAsOfEditPanel';
import { ValueInput } from '../../../components/inputs';
import MeasureArrow from '../../../components/MeasureArrow';
import dayjs from 'dayjs';
import { DetailsListCellItemContainer } from '../../../components/shared/DetailsListCellItemContainer';
import { useViewport } from '../../../hooks/useViewport';
import { DefaultProps } from '../../../DefaultProps';
import ValueCalcToolTip from '../../MeasureFormulaEditor/components/ValueCalcTooltip';
import { useLanguage } from '../../../services/i18n';

export function MeasureValuesGrid(props: {
    fyStartDate: string | undefined;
    fyEndDate: string | undefined;
    measure: {
        id: string | null;
        measureType: MeasureTypes;
        multiplier: Multipliers;
        currency: { symbol: string | null | undefined } | null;
        decimalPlaces: number;
        isCustom: boolean;
        isStatusLimited: boolean;
        frequencyPeriod: FrequencyPeriods;
        valueFormulaData: {
            calculatedSeries: (string | null)[];
        } | null;
        valueFormula: string | null;
        showForecast: boolean;
        valueHistory: MeasureAsOfEditPanelMeasureAsOfType[] | null;
    } | null;
    data: MonthRow[];
    showEditButton?: boolean;
    showTotals: boolean;
    showCumulativeColumn: boolean;
    isLoading: boolean;
    isEditing: boolean;
    isSaving: boolean;
    isReadOnly: boolean;
    onDataChanged: (newData: MonthRow[]) => void;
    onAddRowClicked: () => void;
    saveErrorMessage?: string | null;
}): JSX.Element {
    const { data, measure, showTotals, showCumulativeColumn } = props;

    const { t } = useLanguage();

    const { width } = useViewport();

    const isMobile = width < 620;

    const isCustom =
        measure?.isCustom || measure?.frequencyPeriod === FrequencyPeriods.None;

    const [editAsOf, setEditAsOf] = useState<{
        id: string | null;
        asOfDate: string | null;
    } | null>();

    const measureFormatter = useMeasureValueFormatter(measure);

    const isNumberMeasureType = measureFormatter.isNumeric;

    // Only show for Reocurring:
    // const fullYearTargetIsNone =
    //     measure.fullYearString === null && measure.fullYearTarget === null;
    // const showCumulativeColumn = !measure.isFullYearTarget && !fullYearTargetIsNone;

    // Database TODO:
    // const showCumulativeColumn = measure?.valueType === ValueTypes.Incremental;

    let frequencyType: FrequencyTypes = FrequencyTypes.Custom;

    if (isCustom) {
        frequencyType = FrequencyTypes.Custom;
    } else if (measure?.frequencyPeriod === FrequencyPeriods.Month) {
        frequencyType = FrequencyTypes.Monthly;
    } else if (measure?.frequencyPeriod === FrequencyPeriods.Quarter) {
        frequencyType = FrequencyTypes.Quarterly;
    } else if (measure?.frequencyPeriod === FrequencyPeriods.Year) {
        frequencyType = FrequencyTypes.Yearly;
    }

    let labelTitle = 'As Of Date';
    let intervalTitle = 'Target';
    const forecastIntervalTitle = t('measure-of-success.forecast');
    const forecastYtdTitle = t('measure-of-success.forecastYtd');

    if (frequencyType === FrequencyTypes.Monthly) {
        labelTitle = 'Month';
        intervalTitle = 'Target';
    } else if (frequencyType === FrequencyTypes.Quarterly) {
        labelTitle = 'Quarter';
        intervalTitle = 'Target';
    } else if (frequencyType === FrequencyTypes.Yearly) {
        labelTitle = 'Year';
        intervalTitle = 'Target';
    }

    const ytdTitle = 'Target (YTD)';
    const actualYtdTitle = 'Actual (YTD)';

    const canEditTarget =
        !measure?.valueFormulaData?.calculatedSeries?.includes('Target');
    const canEditForecast =
        !measure?.valueFormulaData?.calculatedSeries?.includes(
            'PhasedForecast'
        );
    const canEditActual =
        !measure?.valueFormulaData?.calculatedSeries?.includes('Actual');

    const updateInterval = (
        index: number,
        newValue: {
            decimalValue: number | null;
            stringValue: string | null;
            dateValue: string | null;
        }
    ) => {
        if (isNumberMeasureType) {
            const intervalValue = newValue.decimalValue;
            data[index as number].interval.decimalValue = intervalValue;

            if (intervalValue) {
                const previousYTD = index > 0 ? data[index - 1].ytd ?? 0 : 0;
                data[index as number].ytd = intervalValue + previousYTD;
            }

            recalcYtd(data);
        } else {
            data[index as number].interval.decimalValue = newValue.decimalValue;
            data[index as number].interval.stringValue = newValue.stringValue;
            data[index as number].interval.dateValue = newValue.dateValue;
        }

        data[index as number].hasChanges = true;
        props.onDataChanged([...data]);
    };

    const updateYTD = (index: number, newValue: number | null) => {
        data[index as number].ytd = newValue;

        const ytdValue = newValue ?? 0;
        const previousYTD = index > 0 ? data[index - 1].ytd ?? 0 : 0;
        data[index as number].interval.decimalValue = ytdValue - previousYTD;
        if (newValue !== null) {
            recalcYtd(data);
        }

        data[index as number].hasChanges = true;
        props.onDataChanged([...data]);
    };

    const updateForecastInterval = (index: number, newValue: number | null) => {
        if (isNumberMeasureType) {
            data[index as number].forecast = newValue;

            recalcYtd(data);
        }

        data[index as number].hasChanges = true;
        props.onDataChanged([...data]);
    };

    const updateForecastYTD = (index: number, newValue: number | null) => {
        data[index as number].forecastYtd = newValue;

        const ytdValue = newValue ?? 0;
        const previousYTD = index > 0 ? data[index - 1].forecastYtd ?? 0 : 0;
        data[index as number].forecast = ytdValue - previousYTD;

        if (newValue !== null) {
            recalcYtd(data);
        }

        data[index as number].hasChanges = true;
        props.onDataChanged([...data]);
    };

    const updateActual = (
        index: number,
        newValue: {
            decimalValue: number | null;
            stringValue: string | null;
            dateValue: string | null;
        }
    ) => {
        if (isNumberMeasureType) {
            const actualValue = newValue.decimalValue;
            data[index as number].actual.decimalValue = actualValue;
            recalcYtd(data);
        } else {
            data[index as number].actual.decimalValue = newValue.decimalValue;
            data[index as number].actual.stringValue = newValue.stringValue;
            data[index as number].actual.dateValue = newValue.dateValue;
        }

        data[index as number].hasChanges = true;
        props.onDataChanged([...data]);
    };

    const updateActualYtd = (index: number, newValue: number | null) => {
        data[index as number].actualYtd = newValue;
        const ytdValue = newValue ?? 0;

        const previousYTD = index > 0 ? data[index - 1].actualYtd ?? 0 : 0;
        data[index as number].actual.decimalValue = ytdValue - previousYTD;

        if (newValue !== null) {
            recalcYtd(data);
        }

        data[index as number].hasChanges = true;
        props.onDataChanged([...data]);
    };

    const updateDate = (index: number, date: Date | null | undefined) => {
        data[index as number].dt = date ? dayjs(date).format('YYYY-MM-DD') : '';

        data[index as number].label = getLabelFreq(
            index,
            measure?.frequencyPeriod || FrequencyPeriods.None,
            data[index as number].dt
        );

        props.onDataChanged([...data]);
    };

    const toggleDelete = (index: number) => {
        data[index as number].isDeleted = !data[index as number].isDeleted;

        recalcYtd(data);

        props.onDataChanged([...data]);
    };

    const valueInputProps = measure
        ? {
              measureType: measure.measureType,
              multiplier: measure.multiplier,
              currencySymbol: measure.currency?.symbol,
              decimalPlaces: measure.decimalPlaces,
          }
        : null;

    const minColumnWidth = isMobile ? 60 : 100;

    const columns: IColumn[] = [
        {
            key: 'label',
            name: labelTitle,
            fieldName: 'label',
            minWidth: minColumnWidth,
            flexGrow: 1,
            isMultiline: true,
            isRowHeader: true,
            onRender: (d: MonthRow) => {
                return props.isEditing && d.isNew ? (
                    <div style={{ maxWidth: 200 }}>
                        <DatePicker
                            {...DefaultProps.DatePickerProps}
                            value={d.dt ? dayjs(d.dt).toDate() : undefined}
                            onSelectDate={(date: Date | null | undefined) =>
                                updateDate(d.index, date)
                            }
                            minDate={
                                props.fyStartDate
                                    ? dayjs(props.fyStartDate).toDate()
                                    : undefined
                            }
                            maxDate={
                                props.fyEndDate
                                    ? dayjs(props.fyEndDate).toDate()
                                    : undefined
                            }
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            disabled={d.isDeleted}
                        />
                    </div>
                ) : (
                    <DetailsListCellItemContainer>
                        <span
                            style={{
                                fontStyle: d.isDeleted ? 'italic' : undefined,
                                textDecoration: d.isDeleted
                                    ? 'line-through'
                                    : undefined,
                            }}
                        >
                            {isCustom || frequencyType === FrequencyTypes.Custom
                                ? d.dt
                                    ? dayjs(d.dt).format('DD MMM YYYY')
                                    : ''
                                : d.label}
                        </span>
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'targetInterval',
            name: intervalTitle,
            fieldName: 'targetInterval',
            minWidth: props.isEditing && !showTotals ? 180 : minColumnWidth,
            flexGrow: 1,
            isMultiline: true,
            onRender: (d: MonthRow) => {
                return props.isEditing &&
                    !showTotals &&
                    valueInputProps &&
                    canEditTarget ? (
                    <ValueInput
                        {...valueInputProps}
                        defaultValue={{
                            decimalValue: d.interval.decimalValue,
                            stringValue: d.interval.stringValue,
                            dateValue: d.interval.dateValue,
                        }}
                        onChange={(decimalValue, stringValue, dateValue) => {
                            updateInterval(d.index, {
                                decimalValue: decimalValue,
                                stringValue: stringValue,
                                dateValue: dateValue,
                            });
                        }}
                        disabled={d.isDeleted}
                    />
                ) : (
                    <DetailsListCellItemContainer>
                        {isNumberMeasureType &&
                        d.interval?.decimalValue !== null ? (
                            <Stack horizontal style={{ alignItems: 'center' }}>
                                {measure?.valueFormulaData?.calculatedSeries.includes(
                                    'Target'
                                ) ? (
                                    <ValueCalcToolTip
                                        measureId={props.measure?.id}
                                        valueFormula={
                                            props.measure?.valueFormula
                                        }
                                        showValues={true}
                                        asOf={d.asOf}
                                        seriesTypeName="Target"
                                        total={d.interval?.decimalValue}
                                        formatOptions={props.measure}
                                    >
                                        {measureFormatter.formatDecimalValue(
                                            d.interval?.decimalValue
                                        )}
                                    </ValueCalcToolTip>
                                ) : (
                                    measureFormatter.formatDecimalValue(
                                        d.interval?.decimalValue
                                    )
                                )}
                            </Stack>
                        ) : (
                            d.interval.dateValue || d.interval.stringValue
                        )}
                    </DetailsListCellItemContainer>
                );
            },
        },
    ];

    if (isNumberMeasureType && !isMobile && showCumulativeColumn) {
        columns.push({
            key: 'targetYtd',
            name: ytdTitle,
            fieldName: 'targetYtd',
            minWidth: props.isEditing && showTotals ? 180 : minColumnWidth,
            flexGrow: 1,
            isMultiline: true,
            onRender: (d: MonthRow) => {
                return props.isEditing &&
                    showTotals &&
                    valueInputProps &&
                    canEditTarget ? (
                    <ValueInput
                        {...valueInputProps}
                        defaultValue={{
                            decimalValue: d.ytd,
                        }}
                        onChange={(decimalValue) => {
                            updateYTD(d.index, decimalValue);
                        }}
                        disabled={d.isDeleted}
                    />
                ) : (
                    <DetailsListCellItemContainer>
                        {d.ytd !== null
                            ? measureFormatter.formatDecimalValue(d.ytd)
                            : ''}
                    </DetailsListCellItemContainer>
                );
            },
        });
    }

    if (measure?.showForecast) {
        columns.push({
            key: 'forecastInterval',
            name: forecastIntervalTitle,
            fieldName: 'forecastInterval',
            minWidth: props.isEditing && !showTotals ? 180 : minColumnWidth,
            flexGrow: 1,
            isMultiline: true,
            onRender: (d: MonthRow) => {
                return props.isEditing &&
                    !showTotals &&
                    valueInputProps &&
                    canEditForecast ? (
                    <ValueInput
                        {...valueInputProps}
                        defaultValue={{
                            decimalValue:
                                d.forecast !== undefined ? d.forecast : null,
                        }}
                        onChange={(decimalValue) => {
                            updateForecastInterval(d.index, decimalValue);
                        }}
                        disabled={d.isDeleted}
                    />
                ) : (
                    <DetailsListCellItemContainer>
                        {isNumberMeasureType &&
                        d.forecast !== null &&
                        d.forecast !== undefined ? (
                            <Stack horizontal style={{ alignItems: 'center' }}>
                                {measure?.valueFormulaData?.calculatedSeries.includes(
                                    'PhasedForecast'
                                ) ? (
                                    <ValueCalcToolTip
                                        measureId={props.measure?.id}
                                        valueFormula={
                                            props.measure?.valueFormula
                                        }
                                        showValues={true}
                                        asOf={d.asOf}
                                        seriesTypeName="PhasedForecast"
                                        total={d.forecast}
                                        formatOptions={props.measure}
                                    >
                                        {measureFormatter.formatDecimalValue(
                                            d.forecast
                                        )}
                                    </ValueCalcToolTip>
                                ) : (
                                    measureFormatter.formatDecimalValue(
                                        d.forecast
                                    )
                                )}
                            </Stack>
                        ) : (
                            ''
                        )}
                    </DetailsListCellItemContainer>
                );
            },
        });

        if (isNumberMeasureType && !isMobile && showCumulativeColumn) {
            columns.push({
                key: 'forecastYtd',
                name: forecastYtdTitle,
                fieldName: 'forecastYtd',
                minWidth: props.isEditing && showTotals ? 180 : minColumnWidth,
                flexGrow: 1,
                isMultiline: true,
                onRender: (d: MonthRow) => {
                    return props.isEditing &&
                        showTotals &&
                        valueInputProps &&
                        canEditForecast ? (
                        <ValueInput
                            {...valueInputProps}
                            defaultValue={{
                                decimalValue: d.forecastYtd,
                            }}
                            onChange={(decimalValue) => {
                                updateForecastYTD(d.index, decimalValue);
                            }}
                            disabled={d.isDeleted}
                        />
                    ) : (
                        <DetailsListCellItemContainer>
                            {isNumberMeasureType &&
                            d.forecastYtd !== null &&
                            d.forecastYtd !== undefined
                                ? measureFormatter.formatDecimalValue(
                                      d.forecastYtd
                                  )
                                : ''}
                        </DetailsListCellItemContainer>
                    );
                },
            });
        }
    }

    columns.push({
        key: 'actualInterval',
        name: 'Actual',
        fieldName: 'actualInterval',
        minWidth: props.isEditing && !showTotals ? 180 : minColumnWidth,
        flexGrow: 1,
        isMultiline: true,
        onRender: (d: MonthRow) => {
            return props.isEditing &&
                !showTotals &&
                valueInputProps &&
                canEditActual ? (
                <ValueInput
                    {...valueInputProps}
                    defaultValue={{
                        decimalValue: d.actual.decimalValue,
                        stringValue: d.actual.stringValue,
                        dateValue: d.actual.dateValue,
                    }}
                    onChange={(decimalValue, stringValue, dateValue) => {
                        updateActual(d.index, {
                            decimalValue: decimalValue,
                            stringValue: stringValue,
                            dateValue: dateValue,
                        });
                    }}
                    disabled={d.isDeleted}
                />
            ) : (
                <DetailsListCellItemContainer>
                    {isNumberMeasureType && d.actual?.decimalValue !== null ? (
                        <Stack horizontal style={{ alignItems: 'center' }}>
                            {measure?.valueFormulaData?.calculatedSeries.includes(
                                'Actual'
                            ) ? (
                                <ValueCalcToolTip
                                    measureId={props.measure?.id}
                                    valueFormula={props.measure?.valueFormula}
                                    showValues={true}
                                    asOf={d.asOf}
                                    seriesTypeName="Actual"
                                    total={d.actual?.decimalValue}
                                    formatOptions={props.measure}
                                >
                                    {measureFormatter.formatDecimalValue(
                                        d.actual?.decimalValue
                                    )}
                                </ValueCalcToolTip>
                            ) : (
                                measureFormatter.formatDecimalValue(
                                    d.actual?.decimalValue
                                )
                            )}
                        </Stack>
                    ) : (
                        d.actual.dateValue || d.actual.stringValue
                    )}
                </DetailsListCellItemContainer>
            );
        },
    });

    if (isNumberMeasureType && !isMobile && showCumulativeColumn) {
        columns.push({
            key: 'actualYtd',
            name: actualYtdTitle,
            fieldName: 'actualYtd',
            minWidth: props.isEditing && showTotals ? 180 : minColumnWidth,
            flexGrow: 1,
            isMultiline: true,
            onRender: (d: MonthRow) => {
                return props.isEditing &&
                    showTotals &&
                    valueInputProps &&
                    canEditActual ? (
                    <ValueInput
                        {...valueInputProps}
                        defaultValue={{
                            decimalValue: d.actualYtd,
                        }}
                        onChange={(decimalValue) => {
                            updateActualYtd(d.index, decimalValue);
                        }}
                        disabled={d.isDeleted}
                    />
                ) : (
                    <DetailsListCellItemContainer>
                        {d.actual.decimalValue !== null ||
                        d.actual.stringValue !== null ||
                        d.actual.dateValue !== null
                            ? measureFormatter.formatDecimalValue(d.actualYtd)
                            : ''}
                    </DetailsListCellItemContainer>
                );
            },
        });
    }

    if (!props.isEditing && !isMobile) {
        columns.push({
            key: 'status',
            name: 'Status',
            fieldName: 'status',
            minWidth: minColumnWidth,
            flexGrow: 1,
            isMultiline: true,
            styles: {
                cellTitle: { justifyContent: 'flex-end' },
            },
            onRender: (d: MonthRow) => {
                return (
                    <DetailsListCellItemContainer textAlign="right">
                        {(d.actual.decimalValue !== null ||
                            d.actual.stringValue !== null ||
                            d.actual.dateValue !== null) && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <MeasureArrow
                                    {...d.status}
                                    isStatusLimited={
                                        measure?.isStatusLimited || false
                                    }
                                    values={d.asOf?.values}
                                    showPercentage
                                    percentagePosition="Left"
                                    textFontSize={FontSizes.small}
                                />
                            </div>
                        )}
                    </DetailsListCellItemContainer>
                );
            },
        });
    }

    if (
        !props.isEditing &&
        isNumberMeasureType &&
        !isMobile &&
        showCumulativeColumn
    ) {
        columns.push({
            key: 'statusYTD',
            name: 'Status (YTD)',
            fieldName: 'statusYTD',
            minWidth: minColumnWidth,
            flexGrow: 1,
            isMultiline: true,
            styles: {
                cellTitle: { justifyContent: 'flex-end' },
            },
            onRender: (d: MonthRow) => {
                return (
                    <DetailsListCellItemContainer textAlign="right">
                        {d.statusYtd && d.actual.decimalValue !== null && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <MeasureArrow
                                    {...d.statusYtd}
                                    isStatusLimited={
                                        measure?.isStatusLimited || false
                                    }
                                    values={d.asOf?.values}
                                    showPercentage
                                    percentagePosition="Left"
                                    textFontSize={FontSizes.small}
                                />
                            </div>
                        )}
                    </DetailsListCellItemContainer>
                );
            },
        });
    }

    if (!props.isReadOnly && !props.isSaving && props.showEditButton) {
        columns.push({
            key: 'edit',
            name: '',
            fieldName: 'Edit',
            minWidth: 32,
            flexGrow: 1,
            isMultiline: true,
            onRender: (d: MonthRow) => {
                return (
                    <DetailsListCellItemContainer textAlign="right">
                        <IconButton
                            iconProps={{ iconName: 'Edit' }}
                            title="Edit"
                            disabled={props.isSaving}
                            onClick={() => {
                                if (d.asOf) {
                                    setEditAsOf({
                                        id: d.asOf.id,
                                        asOfDate: d.asOf.asOfDate,
                                    });
                                }
                            }}
                        />
                    </DetailsListCellItemContainer>
                );
            },
        });
    }

    if (
        props.isEditing &&
        !props.isSaving &&
        (isCustom || frequencyType === FrequencyTypes.Custom)
    ) {
        columns.push({
            key: 'delete',
            name: '',
            fieldName: 'delete',
            minWidth: 32,
            flexGrow: 1,
            isMultiline: true,
            onRender: (d: MonthRow) => {
                return (
                    <DetailsListCellItemContainer textAlign="right">
                        <IconButton
                            iconProps={{
                                iconName: d.isDeleted ? 'Undo' : 'Delete',
                            }}
                            title={d.isDeleted ? 'Restore' : 'Delete'}
                            disabled={props.isSaving}
                            onClick={() => toggleDelete(d.index)}
                        />
                    </DetailsListCellItemContainer>
                );
            },
        });
    }

    return (
        <>
            {props.saveErrorMessage && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                >
                    <span>{props.saveErrorMessage}</span>
                </MessageBar>
            )}

            <ShimmeredDetailsList
                items={data}
                enableShimmer={props.isSaving || props.isLoading}
                shimmerLines={props.data.length || 12}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={(): boolean => false}
                compact={isMobile || props.isEditing}
            />

            <div style={{ textAlign: 'right' }}>
                {(isCustom || frequencyType === FrequencyTypes.Custom) &&
                    props.isEditing && (
                        <ActionButton
                            text="Add Row"
                            iconProps={{ iconName: 'Add' }}
                            onClick={props.onAddRowClicked}
                        />
                    )}
            </div>

            {measure && props.fyStartDate && (
                <MeasureAsOfEditPanel
                    asOfId={editAsOf?.id}
                    asOfDate={editAsOf?.asOfDate}
                    fyStartDate={props.fyStartDate}
                    showPanel={!!editAsOf}
                    onDismiss={() => setEditAsOf(null)}
                    measure={measure}
                    frequencyType={frequencyType}
                />
            )}
        </>
    );
}
