import dayjs from 'dayjs';

export const useUtcDates = (): {
    fromNow: (utcDateTime?: string | null) => string | null;
} => {
    const fromNow = (utcDateTime?: string | null): string | null => {
        return utcDateTime ? dayjs.utc(utcDateTime).fromNow() : null;
    };

    return {
        fromNow,
    };
};
