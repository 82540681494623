import React, { useCallback, useEffect, useState } from 'react';

import {
    ActionButton,
    ContextualMenuItemType,
    IButtonProps,
    IContextualMenuItem,
    Icon,
    Stack,
} from '@fluentui/react';
import { useUserFilters } from '../hooks/useUserFilters';
import { FilterBar } from '../../../components/FilterBar';

type UserTypesFilter = {
    isMissionOwner: boolean;
    isContributor: boolean;
    isEditor: boolean;
    isViewer: boolean;
    isClientAdmin: boolean;
    isWithoutRights: boolean;
};

export type UserFilters = {
    keyword: string | null;
    isActive: boolean | null;
    userTypes: UserTypesFilter | null;
};

export const DefaultUserFilters: UserFilters = {
    keyword: null,
    isActive: null,
    userTypes: null,
};

export type MissionFilterType = {
    id: string | null;
    userId: string | null;
    owner: string | null;
    title: string | null;
};
export type ContributorFilterType = {
    id: string | null;
    userId: string | null;
    team: {
        name: string | null;
    } | null;
};
export type RightsFilterType = {
    userId: string | null;
    write: boolean;
    read: boolean;
    import: boolean;
};

type UserType = {
    id: string | null;
    accessEnabled: boolean;
    userRoles: {
        name: string;
        tenantId: string | null;
    }[];
};

export function UserFilterBar<T extends UserType>(props: {
    filters: UserFilters;
    onFiltersChanged: (filters: UserFilters) => void;
    unfilteredUsers: T[];
    missions: MissionFilterType[] | null;
    contributors: ContributorFilterType[] | null;
    rights: RightsFilterType[] | null;
    showFyFilters: boolean;
}): JSX.Element {
    const [internalFilters, setInternalFilters] = useState<UserFilters>(
        props.filters
    );

    const { onFiltersChanged } = props;
    useEffect(
        () => onFiltersChanged(internalFilters),
        [internalFilters, onFiltersChanged]
    );

    const handleSearchTextChanged = useCallback((keyword?: string | null) => {
        setInternalFilters((f) => ({
            ...f,
            keyword: keyword || null,
        }));
    }, []);

    const handleUserTypesChanged = (userTypes: UserTypesFilter | null) => {
        if (
            userTypes === null ||
            Object.values(userTypes).every((v) => v === false)
        )
            setInternalFilters({
                ...internalFilters,
                userTypes: null,
            });
        else {
            setInternalFilters({
                ...internalFilters,
                userTypes: userTypes,
            });
        }
    };

    const handleClearAndDismissClick = () => {
        props.onFiltersChanged(DefaultUserFilters);
        setInternalFilters(DefaultUserFilters);
    };

    return (
        <FilterBar
            onSearchTextChanged={handleSearchTextChanged}
            searchPlaceholder="Filter by name or email address"
            onClearAndDismissClick={handleClearAndDismissClick}
        >
            {props.showFyFilters &&
                !!props.unfilteredUsers &&
                !!props.missions &&
                !!props.contributors &&
                !!props.rights && (
                    <UserTypeFilter
                        users={props.unfilteredUsers}
                        missions={props.missions}
                        contributors={props.contributors}
                        rights={props.rights}
                        userTypes={internalFilters.userTypes}
                        onChange={handleUserTypesChanged}
                    />
                )}

            <div>
                <UserActiveFilter
                    isActive={internalFilters.isActive}
                    onChange={(isActive) => {
                        setInternalFilters({
                            ...internalFilters,
                            isActive: isActive,
                        });
                    }}
                />
            </div>
        </FilterBar>
    );
}

function UserTypeFilter<T extends UserType>(props: {
    users: T[];
    missions: MissionFilterType[];
    contributors: ContributorFilterType[];
    rights: RightsFilterType[];
    userTypes: UserTypesFilter | null;
    onChange: (userTypes: UserTypesFilter | null) => void;
}) {
    const DefaultUserTypesFilters: UserTypesFilter = {
        isClientAdmin: false,
        isMissionOwner: false,
        isContributor: false,
        isEditor: false,
        isViewer: false,
        isWithoutRights: false,
    };

    const userTypes: UserTypesFilter =
        props.userTypes || DefaultUserTypesFilters;

    const { getFilteredUsers } = useUserFilters(
        props.users,
        props.missions,
        props.contributors,
        props.rights
    );

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'contributor',
            text: `Contributor  (${
                getFilteredUsers({
                    ...DefaultUserFilters,
                    userTypes: {
                        ...DefaultUserTypesFilters,
                        isContributor: true,
                    },
                })?.length
            })`,
            canCheck: true,
            checked: userTypes.isContributor,
            onClick: () =>
                props.onChange({
                    ...userTypes,
                    isContributor: !userTypes.isContributor,
                }),
            iconProps: {
                iconName: 'CustomList',
            },
        },
        {
            key: 'missionowner',
            text: `Mission Owner  (${
                getFilteredUsers({
                    ...DefaultUserFilters,
                    userTypes: {
                        ...DefaultUserTypesFilters,
                        isMissionOwner: true,
                    },
                })?.length
            })`,
            canCheck: true,
            checked: userTypes.isMissionOwner,
            onClick: () =>
                props.onChange({
                    ...userTypes,
                    isMissionOwner: !userTypes.isMissionOwner,
                }),
            iconProps: {
                iconName: 'Trending12',
            },
        },
        {
            key: 'clientadmin',
            text: `Client Admin (${
                getFilteredUsers({
                    ...DefaultUserFilters,
                    userTypes: {
                        ...DefaultUserTypesFilters,
                        isClientAdmin: true,
                    },
                })?.length
            })`,
            canCheck: true,
            checked: userTypes.isClientAdmin,
            onClick: () =>
                props.onChange({
                    ...userTypes,
                    isClientAdmin: !userTypes.isClientAdmin,
                }),
            iconProps: {
                iconName: 'Crown',
            },
        },
        {
            key: 'editors',
            text: `Importers / Editors (${
                getFilteredUsers({
                    ...DefaultUserFilters,
                    userTypes: {
                        ...DefaultUserTypesFilters,
                        isEditor: true,
                    },
                })?.length
            })`,
            canCheck: true,
            checked: userTypes.isEditor,
            onClick: () =>
                props.onChange({
                    ...userTypes,
                    isEditor: !userTypes.isEditor,
                }),
            iconProps: {
                iconName: 'PenWorkspace',
            },
        },
        {
            key: 'viewers',
            text: `Viewers (${
                getFilteredUsers({
                    ...DefaultUserFilters,
                    userTypes: {
                        ...DefaultUserTypesFilters,
                        isViewer: true,
                    },
                })?.length
            })`,
            canCheck: true,
            checked: userTypes.isViewer,
            onClick: () =>
                props.onChange({
                    ...userTypes,
                    isViewer: !userTypes.isViewer,
                }),
            iconProps: {
                iconName: 'SeeDo',
            },
        },
        {
            key: 'noaccess',
            text: `No Access (${
                getFilteredUsers({
                    ...DefaultUserFilters,
                    userTypes: {
                        ...DefaultUserTypesFilters,
                        isWithoutRights: true,
                    },
                })?.length
            })`,
            canCheck: true,
            checked: userTypes.isWithoutRights,
            onClick: () =>
                props.onChange({
                    ...userTypes,
                    isWithoutRights: !userTypes.isWithoutRights,
                }),
            iconProps: {
                iconName: 'Blocked',
            },
        },
    ];

    if (props.userTypes !== null) {
        menuItems.push(
            {
                key: 'clear-divider',
                itemType: ContextualMenuItemType.Divider,
            },
            {
                key: 'clear',
                text: 'Clear',
                iconProps: {
                    iconName: 'Cancel',
                },
                onClick: () => props.onChange(null),
            }
        );
    }

    const handeRenderText = (
        p?: IButtonProps,
        defaultRender?: (props?: IButtonProps) => JSX.Element | null
    ): JSX.Element => {
        return (
            <Stack horizontal tokens={{ childrenGap: 2 }}>
                {defaultRender && defaultRender(p)}

                {menuItems
                    .filter((mi) => mi.checked)
                    .map((mi) => (
                        <Icon key={mi.text} iconName={mi.iconProps?.iconName} />
                    ))}
            </Stack>
        );
    };

    return (
        <ActionButton
            text={!props.userTypes ? 'User Types' : 'User Types: '}
            onRenderText={handeRenderText}
            menuProps={{
                items: menuItems,
            }}
        />
    );
}

function UserActiveFilter(props: {
    isActive: boolean | null;
    onChange: (isActive: boolean | null) => void;
}) {
    const menuItems: IContextualMenuItem[] = [
        {
            key: 'yes',
            text: 'Yes',
            canCheck: true,
            checked: props.isActive === true,
            onClick: () => props.onChange(true),
        },
        {
            key: 'no',
            text: 'No',
            canCheck: true,
            checked: props.isActive === false,
            onClick: () => props.onChange(false),
        },
    ];

    if (props.isActive !== null) {
        menuItems.push(
            {
                key: 'clear-divider',
                itemType: ContextualMenuItemType.Divider,
            },
            {
                key: 'clear',
                text: 'Clear',
                iconProps: {
                    iconName: 'Cancel',
                },
                onClick: () => props.onChange(null),
            }
        );
    }

    const text = `Active${
        props?.isActive === true
            ? `: Yes`
            : props.isActive === false
              ? `: No`
              : '?'
    }`;

    return (
        <ActionButton
            text={text}
            menuProps={{
                items: menuItems,
            }}
        />
    );
}
