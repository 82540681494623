import { ObservableQuery, OperationVariables } from '@apollo/client';
import React from 'react';

type RefetchFnType<
    TData,
    TVariables extends OperationVariables
> = ObservableQuery<TData, TVariables>['refetch'];

export function useRefetch<TData, TVariables extends OperationVariables>(
    refetch: RefetchFnType<TData, TVariables>
): [RefetchFnType<TData, TVariables>, boolean] {
    const [refetching, setRefetching] = React.useState(false);
    const refetchWithLoading: RefetchFnType<TData, TVariables> =
        React.useCallback(
            (...args) => {
                setRefetching(true);
                return refetch(...args).finally(() => {
                    setRefetching(false);
                });
            },
            [refetch]
        );

    return [refetchWithLoading, refetching];
}
