import * as React from 'react';
import { useGetTeamsQuery, TeamQl } from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import {
    ShimmeredDetailsList,
    IconButton,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    Selection,
} from '@fluentui/react';
import { navigation } from '../../../services/navigation';
import { useNavigate } from 'react-router-dom';
import { useSorter } from '../../../hooks/useSorter';

type TeamListProps = {
    onTeamIdsSelected?: (teamIds: string[]) => void;
    onTeamsSelected?: (teams: TeamQl[]) => void;
};

export function TeamList(props: TeamListProps): JSX.Element {
    const { currentTenantId, currentTenantCode, currentFinancialYearCode } =
        useStateContext();

    const navigate = useNavigate();

    const { data, loading } = useGetTeamsQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            divisionId: null,
            financialYearCode: currentFinancialYearCode || null,
        },
    });

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(data?.teams, 'code', false, true);

    function notNull<T>(val: T | null): val is T {
        return val !== null;
    }

    const _selection = new Selection({
        onSelectionChanged: () => {
            if (props.onTeamIdsSelected) {
                const teamIds = _selection
                    .getSelection()
                    .map((s) => {
                        const t = s as TeamQl;
                        return t.id;
                    })
                    .filter(notNull);
                props.onTeamIdsSelected(teamIds);
            }
            if (props.onTeamsSelected) {
                const teams = _selection.getSelection().map((s) => s as TeamQl);
                props.onTeamsSelected(teams);
            }
        },
        // getKey: (item: MissionQl) => item.id || 0,
    });

    const columns: IColumn[] = [
        {
            key: 'financialYear',
            name: 'FY',
            fieldName: 'financialYear.code',
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: function renderFinancialYear(item: TeamQl): JSX.Element {
                return <span>{item?.division?.financialYear?.code}</span>;
            },
            isSorted: sortColumn === 'division.financialYear.code',
            isSortedDescending:
                sortColumn === 'division.financialYear.code' && sortIsDesc,
            onColumnClick: (): void =>
                setSortColumnName('division.financialYear.code', false),
        },
        {
            key: 'code',
            name: 'Code',
            fieldName: 'code',
            isRowHeader: true,
            isResizable: true,
            minWidth: 100,
            maxWidth: 200,
            isSorted: sortColumn === 'code',
            isSortedDescending: sortColumn === 'code' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('code', true),
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            isResizable: true,
            minWidth: 100,
            maxWidth: 200,
            isSorted: sortColumn === 'name',
            isSortedDescending: sortColumn === 'name' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('name', true),
        },
        {
            key: 'leaderMission',
            name: 'Leader Mission',
            fieldName: 'leaderMission.owner',
            isResizable: true,
            minWidth: 150,
            maxWidth: 300,
            isSorted: sortColumn === 'leaderMission.owner',
            isSortedDescending:
                sortColumn === 'leaderMission.owner' && sortIsDesc,
            onColumnClick: (): void =>
                setSortColumnName('leaderMission.owner', false),
            onRender: function renderLeaderMissionName(
                item: TeamQl
            ): JSX.Element {
                if (item?.leaderMission) {
                    return (
                        <span>
                            {`${item?.leaderMission?.owner} - ${item?.leaderMission?.title}`}
                        </span>
                    );
                } else {
                    return <span />;
                }
            },
        },
        {
            key: 'division',
            name: 'Division',
            fieldName: 'division.name',
            minWidth: 100,
            isResizable: true,
            onRender: function renderFinancialYear(item: TeamQl): JSX.Element {
                return <span>{item?.division?.name}</span>;
            },
            isSorted: sortColumn === 'division.name',
            isSortedDescending: sortColumn === 'division.name' && sortIsDesc,
            onColumnClick: (): void =>
                setSortColumnName('division.name', false),
        },
        {
            key: 'actions',
            name: '',
            minWidth: 64,
            isIconOnly: true,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderActions(item: TeamQl): JSX.Element {
                return (
                    <IconButton
                        iconProps={{ iconName: 'NavigateForward' }}
                        onClick={(): void => {
                            const path = navigation.getPathForParams({
                                tenantCode: currentTenantCode || '',
                                financialYearCode:
                                    currentFinancialYearCode || '',
                                teamCode: item.code || undefined,
                            });
                            navigate(path);
                        }}
                    />
                );
            },
        },
    ];

    return (
        <ShimmeredDetailsList
            setKey="items"
            items={sortedItems || []}
            columns={columns}
            selectionMode={SelectionMode.multiple}
            selection={_selection}
            layoutMode={DetailsListLayoutMode.justified}
            enableShimmer={loading}
            ariaLabelForShimmer="Content is being fetched"
            ariaLabelForGrid="Item details"
            listProps={{
                renderedWindowsAhead: 0,
                renderedWindowsBehind: 0,
            }}
        />
    );
}
