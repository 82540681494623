import React from 'react';
import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import {
    ExtractQueryArrayType,
    MeasureSeriesNames,
} from '../data/extendedTypes';
import {
    GetMeasureValueHistoryQuery,
    SeriesValue,
    useUpdateMeasureAsOfMutation,
} from '../data/types';
import { usePhasing } from '../hooks/usePhasing';

type MeasureValueHistoryItem = ExtractQueryArrayType<
    GetMeasureValueHistoryQuery,
    ['measure', 'valueHistory']
>;

export default function PhasingWarnings(props: {
    currentTenantId: string;
    measureId: string;
    valueHistory: MeasureValueHistoryItem[] | null | undefined;
    onHistoryChanged: () => void;
}): JSX.Element | null {
    const { valueHistory } = props;

    const { hasTargetsOutOfSync, getTargetsOutOfSync } = usePhasing();

    const [updateMeasureAsOf, { loading: isSaving, error: saveError }] =
        useUpdateMeasureAsOfMutation();

    if (!valueHistory || valueHistory.length === 0) {
        return null;
    }

    const showOutOfSyncMessage = hasTargetsOutOfSync(valueHistory);

    const handleSyncPhasings = async () => {
        const targetsToUpdate = getTargetsOutOfSync(valueHistory);

        // Execute sequentially to avoid concurrency errors
        for (const { entry: asOf, phasedTarget } of targetsToUpdate) {
            await updateMeasureAsOf({
                variables: {
                    tenantId: props.currentTenantId,
                    input: {
                        id: asOf.id,
                        measureId: props.measureId || '',
                        asOfDate: asOf.asOfDate,
                        version: asOf.version,
                        values: (asOf.values || []).map((v) => {
                            const decimalValue =
                                v.seriesType?.name === MeasureSeriesNames.Target
                                    ? phasedTarget
                                    : v.decimalValue;
                            return {
                                id: v.id,
                                seriesType: v.seriesType,
                                calcId: v.calcId,
                                version: v.version,
                                decimalValue: decimalValue,
                                dateValue: v.dateValue,
                                stringValue: v.stringValue,
                            } as SeriesValue;
                        }),
                    },
                },
            });
        }

        props.onHistoryChanged();
    };

    return (
        <React.Fragment>
            {saveError && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                >
                    <span>{saveError.message}</span>
                </MessageBar>
            )}
            {showOutOfSyncMessage && (
                <MessageBar
                    messageBarType={MessageBarType.warning}
                    messageBarIconProps={{
                        iconName: 'AlertSolid',
                    }}
                    actions={
                        <div>
                            <MessageBarButton
                                onClick={handleSyncPhasings}
                                disabled={isSaving}
                            >
                                Update Targets
                            </MessageBarButton>
                        </div>
                    }
                >
                    There are target values in the history that do not match the
                    phased target values. Would you like to update the target
                    history?
                </MessageBar>
            )}
        </React.Fragment>
    );
}
