import React from 'react';
import { Stack, Label, Toggle } from '@fluentui/react';
import { InfoLabel } from './InfoLabel';

export function RollupToggle(props: {
    disabled: boolean;
    isRollUp: boolean;
    isRollUpChanged: (isRollUp: boolean) => void;
    infoTooltipContent: string;
}): JSX.Element {
    const onChange = (_ev?: React.MouseEvent<HTMLElement>, checked?: boolean) =>
        props.isRollUpChanged(checked || false);

    const gap = 8;

    return (
        <Stack horizontal tokens={{ childrenGap: gap }}>
            <Stack.Item align="center">
                <Label>Manual</Label>
            </Stack.Item>
            <Toggle
                disabled={props.disabled}
                checked={props.isRollUp}
                styles={{
                    root: {
                        marginBottom: 0,
                    },
                    label: {
                        marginLeft: gap,
                    },
                }}
                inlineLabel
                onChange={onChange}
            />
            <Stack.Item align="center">
                <InfoLabel infoContent={props.infoTooltipContent}>
                    Roll Up
                </InfoLabel>
            </Stack.Item>
        </Stack>
    );
}
