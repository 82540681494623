import React, { useState } from 'react';
import {
    Dialog,
    DialogType,
    DialogFooter,
    DefaultButton,
    SpinButton,
    PrimaryButton,
} from '@fluentui/react';

export default function MoveToPositionDialog(props: {
    hidden: boolean;
    onDismiss: () => void;
    onMoveClicked: (newPosition: number) => void;
    maxValue: number;
}): JSX.Element {
    const [value, setValue] = useState<number>(1);

    const handleChange = (newValue: number) => {
        setValue(newValue);
    };

    const handleConfirmMove = () => {
        props.onMoveClicked(value);
    };

    return (
        <Dialog
            hidden={props.hidden}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Move to position...',
                showCloseButton: true,
                subText: `Enter a value between 1 and ${props.maxValue}.`,
            }}
            modalProps={{
                isBlocking: true,
            }}
            onDismiss={props.onDismiss}
        >
            <SpinButton
                min={1}
                max={props.maxValue}
                step={1}
                value={value?.toString()}
                incrementButtonAriaLabel="Increase value by 1"
                decrementButtonAriaLabel="Decrease value by 1"
                onChange={(_ev, newValue) => {
                    handleChange(Number(newValue));
                }}
            />

            <DialogFooter>
                <PrimaryButton onClick={handleConfirmMove} text={'Move'} />
                <DefaultButton onClick={props.onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
}
