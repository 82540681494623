import React from 'react';
import { IconButton } from '@fluentui/react';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useThemes } from '../hooks/useThemes';

export type DragHandlerButtonProps = {
    hidden: boolean;
    handleListeners?: DraggableSyntheticListeners;
    iconName?: string;
    handleAttributes?: {
        role: string;
        tabIndex: number;
        'aria-pressed': boolean | undefined;
        'aria-roledescription': string;
        'aria-describedby': string;
    };
};

export function DragHandlerButton(props: DragHandlerButtonProps): JSX.Element {
    const iconProps = { iconName: props.iconName || 'GripperDotsVertical' };
    const { currentTheme } = useThemes();

    return (
        <React.Fragment>
            {!props.hidden && (
                <IconButton
                    {...props.handleListeners}
                    {...props.handleAttributes}
                    style={{ padding: 8, cursor: 'grab' }}
                    className="no-print"
                    styles={{
                        root: {
                            color: currentTheme.palette.themeLighter,
                            selectors: {
                                '&:hover': {
                                    color: currentTheme.palette.themePrimary,
                                },
                            },
                            touchAction: 'manipulation',
                        },
                    }}
                    iconProps={iconProps}
                    title="Move"
                    ariaLabel="Move"
                />
            )}
        </React.Fragment>
    );
}

export const DragHandlerButtonMemo = React.memo(DragHandlerButton);
