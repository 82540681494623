import React from 'react';
import icon from '../../images/advance-icon-charcoal.svg';

import { DefaultButton } from '@fluentui/react';

export type EngagementTargetKeys =
    | 'HelpIcon'
    | 'CreateMeasureButton'
    | 'TaskRatingsVisibilityDropdown'
    | 'PresentButton'
    | 'TaskNotificationsTitle';

export type Engagements = {
    key: string;
    targetKey?: EngagementTargetKeys;
    type: 'Announcement' | 'Popup' | 'Beacon';
    headline?: string;
    content: string | JSX.Element;
    activeFrom: string;
    activeTo: string;
    sequence: number;
    backgroundColour?: string;
    headlineColour?: string;
};

function EngagementIcon() {
    return (
        <img
            src={icon}
            alt="Advance"
            style={{
                width: 50,
                filter: 'invert(100%) brightness(200%)',
            }}
        />
    );
}

export const engagements: Engagements[] = [
    {
        key: 'RoundUp2024',
        type: 'Popup',
        headline: "See what's new",
        backgroundColour: '#363636',
        headlineColour: '#ffbd59',
        content: (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                    textAlign: 'left',
                    color: '#fff',
                }}
            >
                <div>
                    <strong style={{ color: '#ffbd59' }}>
                        Stay connected with &lsquo;@&rsquo;
                    </strong>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingTop: 16,
                            gap: 16,
                        }}
                    >
                        <EngagementIcon />
                        <div>
                            Keep your team connected and informed with our new
                            &lsquo;@&rsquo; feature. Send in-app notifications
                            to Advance users from any comment box.
                        </div>
                    </div>
                </div>
                <div>
                    <strong style={{ color: '#ffbd59' }}>
                        Mission Summary View
                    </strong>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingTop: 16,
                            gap: 16,
                        }}
                    >
                        <EngagementIcon />
                        <div>
                            A new presentation option displaying critical
                            information from your mission in a condensed,
                            3-slide view.
                        </div>
                    </div>
                </div>
                <div>
                    <strong style={{ color: '#ffbd59' }}>
                        Managing Task Notifications
                    </strong>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingTop: 16,
                            gap: 16,
                        }}
                    >
                        <EngagementIcon />
                        <div>
                            Enjoy a more efficient process coupled with our new
                            &lsquo;Replace Existing&rsquo; feature which lets
                            you easily swap manually written tasks with
                            dependency requests assigned by your team.
                        </div>
                    </div>
                </div>

                <div style={{ textAlign: 'center' }}>
                    <DefaultButton
                        styles={{
                            root: {
                                backgroundColor: '#ffbd59 ',
                                color: '#363636',
                            },
                            rootHovered: {
                                backgroundColor: '#ffbd59 ',
                                color: '#363636',
                            },
                        }}
                        text="Let's go!"
                    />
                </div>

                <div style={{ textAlign: 'center' }}>
                    Check out our knowledge base for more details or contact
                    Support for a demo.
                </div>
            </div>
        ),
        activeFrom: '2024-12-01',
        activeTo: '2024-12-31',
        sequence: 1,
    },

    // {
    //     key: 'TaskNotificationsAssignTo',
    //     targetKey: 'TaskNotificationsTitle',
    //     type: 'Announcement',
    //     headline: 'New ‘Replace Existing’ Option!',
    //     content:
    //         'You can now assign incoming resource requests to replace your manually written tasks.',
    //     activeFrom: '2024-05-09',
    //     activeTo: '2024-05-31',
    //     sequence: 1,
    // },
    // {
    //     key: 'NewPresentOptions',
    //     targetKey: 'PresentButton',
    //     type: 'Beacon',
    //     headline: 'Present live or download your mission.',
    //     content:
    //         'Redesigned presentation views, now including a new Mission Summary, with a concise overview of critical mission components.',
    //     activeFrom: '2024-05-09',
    //     activeTo: '2024-05-31',
    //     sequence: 2,
    // },
    // {
    //     key: 'NewHelp',
    //     targetKey: 'HelpIcon',
    //     type: 'Announcement',
    //     headline: 'Explore Our New Knowledge Base!',
    //     content:
    //         'This extensive resource is your one-stop destination for all queries and information about our platform.',
    //     activeFrom: '2024-03-27',
    //     activeTo: '2024-05-01',
    //     sequence: 1,
    // },
    // {
    //     key: 'NewMeasureImportFeature',
    //     targetKey: 'CreateMeasureButton',
    //     type: 'Beacon',
    //     headline: 'New Feature!',
    //     content:
    //         'You can now import measures of success from a mission you had last year.',
    //     activeFrom: '2024-03-27',
    //     activeTo: '2024-05-01',
    //     sequence: 2,
    // },
    // {
    //     key: 'NewRatingsFeature',
    //     targetKey: 'TaskRatingsVisibilityDropdown',
    //     type: 'Beacon',
    //     headline: 'Reinforce task impact with Ratings!',
    //     content:
    //         'Easily set effort and cost ratings on implied tasks.\n\nNote: tasks with a higher effort rating will have a weighted affect on the overall specified task percent complete.',
    //     activeFrom: '2024-03-27',
    //     activeTo: '2024-05-01',
    //     sequence: 3,
    // },
];
