import * as React from 'react';
import { TagTypes, useGetTagsQuery } from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import {
    TagPicker,
    IBasePicker,
    ITag,
    mergeStyles,
    TagItem,
    Icon,
    Label,
    Stack,
    Spinner,
} from '@fluentui/react';

type TeamListProps = {
    onTagIdsSelected?: (tagIds: string[]) => void;
};

export function TagList(props: TeamListProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { data, loading } = useGetTagsQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
        },
    });

    const testTags = (data?.tags ?? []).map((item) => ({
        ...item,
        key: item.id ?? '',
        name: item.name ?? '',
    }));

    const listContainsTagList = (tag: ITag | undefined, tagList?: ITag[]) => {
        if (!tag || !tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.some((compareTag) => compareTag.key === tag.key);
    };

    const filterSuggestedTags = (
        filterText: string,
        selectedItems: ITag[] | undefined
    ): ITag[] => {
        return filterText
            ? testTags.filter(
                  (tag) =>
                      tag.name
                          .toLowerCase()
                          .indexOf(filterText.toLowerCase()) === 0 &&
                      !listContainsTagList(tag, selectedItems)
              )
            : [];
    };

    const getTextFromItem = (item: ITag) => item.name;

    const picker = React.useRef<IBasePicker<ITag>>(null);

    const onPickerChanged = (items: ITag[] | undefined) => {
        console.log('onChange', items);
        if (props.onTagIdsSelected) {
            const tagIds = (items ?? []).map((tag) => tag.key as string);
            props.onTagIdsSelected(tagIds);
        }
    };

    const adminTagStyles = {
        marginRight: 4,
        width: '20px',
        height: '20px',
    };

    return (
        <>
            <Stack>
                <Label htmlFor={'tag-picker'}>Include Any:</Label>
                <TagPicker
                    removeButtonAriaLabel="Remove"
                    selectionAriaLabel="Selected tags"
                    componentRef={picker}
                    onResolveSuggestions={filterSuggestedTags}
                    onChange={onPickerChanged}
                    getTextFromItem={getTextFromItem}
                    pickerSuggestionsProps={{
                        suggestionsHeaderText: 'Suggested tags',
                        noResultsFoundText: 'No tags found',
                    }}
                    // itemLimit={1}
                    inputProps={{
                        id: 'tag-picker',
                    }}
                />
            </Stack>

            <Stack>
                <Label>Available Tags:</Label>

                <Stack
                    horizontal
                    wrap={true}
                    styles={{ root: { marginTop: 8 } }}
                >
                    {testTags.map((tag, index) => {
                        return (
                            <Stack.Item key={`tag-${index}`}>
                                <TagItem
                                    item={tag}
                                    index={index}
                                    title={tag.name}
                                    styles={{
                                        text: {
                                            display: 'flex',
                                            alignItems: 'center',
                                        },
                                    }}
                                >
                                    <Icon
                                        className={mergeStyles(adminTagStyles, {
                                            color: tag.colourHex
                                                ? `#${tag.colourHex}`
                                                : undefined,
                                        })}
                                        iconName={
                                            tag.icon
                                                ? tag.icon
                                                : tag.tagType ===
                                                  TagTypes.AdminTag
                                                ? 'TagAdmin'
                                                : 'TagSolid'
                                        }
                                        aria-hidden="true"
                                    />
                                    <span>{tag.name}</span>
                                </TagItem>
                            </Stack.Item>
                        );
                    })}
                </Stack>
                {loading && <Spinner />}
            </Stack>
        </>
    );
}
