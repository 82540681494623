import React from 'react';

const EngagementPortal: React.FC = () => {
    return (
        <>
            <div id="engagement-portal"></div>
        </>
    );
};

export default EngagementPortal;
