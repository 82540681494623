class CustomAuth {
    getAuthorityForIssuer(issuer: string): {
        authorityType: 'Local' | 'External';
        domainHint: string;
    } | null {
        if (issuer.toLocaleLowerCase().startsWith('https://atni.okta.com')) {
            return {
                authorityType: 'External',
                domainHint: 'atni.okta.com',
            };
        }

        if (
            issuer.toLocaleLowerCase().startsWith('cablebahamas.onelogin.com')
        ) {
            return {
                authorityType: 'External',
                domainHint: 'cablebahamas.onelogin.com',
            };
        }

        return null;
    }

    public getEmailSuffixForTenant = (tenantCode: string): string | null => {
        switch (tenantCode.toLocaleLowerCase()) {
            case 'gps':
            case 'gp-strategies':
            case 'gpstrategies':
            case 'mckinneyrogers':
            case 'mckinney-rogers':
                return '@gpstrategies.com';
            default:
                return null;
        }
    };

    public getAuthorityTypeForEmail = (
        emailAddress: string
    ): 'Local' | 'External' => {
        // If this has a domain hint - its external
        const domainHint = this.getDomainHintForEmail(emailAddress);
        if (domainHint) {
            return 'External';
        } else {
            return 'Local';
        }
    };

    public getDomainHintForEmail = (
        emailAddress: string
    ): string | undefined => {
        let domainHint: string | undefined;

        if (emailAddress.toLocaleLowerCase().endsWith('gpstrategies.com')) {
            domainHint = 'gpstrategies.com';
        } else if (
            emailAddress.toLocaleLowerCase().endsWith('@cablebahamas.com') ||
            emailAddress.toLocaleLowerCase().endsWith('@bealiv.com') ||
            emailAddress.toLocaleLowerCase().endsWith('@newcomobile.com')
        ) {
            domainHint = 'cablebahamas.onelogin.com';
        } else if (emailAddress.toLocaleLowerCase().endsWith('topsoe.com')) {
            domainHint = 'topsoe.com';
        } else if (
            emailAddress.toLocaleLowerCase().endsWith('@atni.com') ||
            emailAddress.toLocaleLowerCase().endsWith('@gtt.co.gy') ||
            emailAddress.toLocaleLowerCase().endsWith('@acsalaska.com') ||
            emailAddress.toLocaleLowerCase().endsWith('@viya.vi') ||
            emailAddress.toLocaleLowerCase().endsWith('@logic.ky') ||
            emailAddress.toLocaleLowerCase().endsWith('@mmg.gy') ||
            emailAddress
                .toLocaleLowerCase()
                .endsWith('@commnetbroadband.com') ||
            emailAddress.toLocaleLowerCase().endsWith('@onecomm.bm') ||
            emailAddress.toLocaleLowerCase().endsWith('@onecommgroup.com') ||
            emailAddress.toLocaleLowerCase().endsWith('@fireminds.com') ||
            emailAddress.toLocaleLowerCase().endsWith('@wesolvecx.com') ||
            emailAddress.toLocaleLowerCase().endsWith('@brava.solutions')
        ) {
            domainHint = 'atni.okta.com';
        } else if (
            emailAddress.toLocaleLowerCase().endsWith('@safaricom.co.ke')
        ) {
            domainHint = 'safaricom.co.ke';
        } else if (emailAddress.toLocaleLowerCase().endsWith('@safaricom.et')) {
            domainHint = 'safaricom.et';
        } else if (
            emailAddress.toLocaleLowerCase().endsWith('@m-pesa.africa')
        ) {
            domainHint = 'm-pesa.africa';
        } else if (
            emailAddress.toLocaleLowerCase().endsWith('@ascentco.com') ||
            emailAddress.toLocaleLowerCase().endsWith('@ascenttubular.com') ||
            emailAddress.toLocaleLowerCase().endsWith('@ascentchem.com')
        ) {
            domainHint = 'ascentco.com';
        }

        return domainHint;
    };
}

export const customAuth = new CustomAuth();
