import * as React from 'react';

import { TaskCategoryQl, useGetTaskCategoriesQuery } from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import {
    ShimmeredDetailsList,
    IconButton,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    Stack,
    Icon,
} from '@fluentui/react';
import { useSorter } from '../../../hooks/useSorter';
import { DivisionPicker } from './DivisionPicker';
import { useState } from 'react';

type TaskCategoryListProps = {
    onItemEdit: (team: TaskCategoryQl) => void;
    fyCodeFilter?: string | null;
};

export function TaskCategoryList(props: TaskCategoryListProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [selectedDivisionId, setSelectedDivisionId] = useState<string>();

    const { data, loading } = useGetTaskCategoriesQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            divisionId: selectedDivisionId || null,
            financialYearCode: props.fyCodeFilter || null,
        },
    });

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(data?.taskCategories, 'name', false, true);

    const onEditIconClick = (item: TaskCategoryQl): void => {
        props.onItemEdit(item);
    };

    const columns: IColumn[] = [
        {
            key: 'colour',
            name: '',
            fieldName: 'colourHex',
            isIconOnly: true,
            isResizable: false,
            minWidth: 24,
            maxWidth: 24,

            onRender: function renderIcon(item: TaskCategoryQl): JSX.Element {
                const iconStyles = {
                    color: item.colourHex ? `#${item.colourHex}` : undefined,
                    fontSize: 18,
                };
                return (
                    <Icon
                        style={iconStyles}
                        iconName="TagSolid"
                        aria-hidden="true"
                    />
                );
            },
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            isRowHeader: true,
            isResizable: true,
            minWidth: 100,
            maxWidth: 250,
            isSorted: sortColumn === 'name',
            isSortedDescending: sortColumn === 'name' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('name', true),
        },
        {
            key: 'divisions',
            name: 'Divisions',
            fieldName: 'divisions',
            minWidth: 100,
            isResizable: true,
            onRender: function renderFinancialYear(
                item: TaskCategoryQl
            ): JSX.Element {
                const divisions = item.divisions || [];
                return <span>{divisions.map((d) => d.name).join(', ')}</span>;
            },
        },
        {
            key: 'actions',
            name: '',
            minWidth: 32,
            isIconOnly: true,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderActions(
                item: TaskCategoryQl
            ): JSX.Element {
                return (
                    <IconButton
                        key={item.id}
                        iconProps={{ iconName: 'Edit' }}
                        onClick={() => onEditIconClick(item)}
                    />
                );
            },
        },
    ];

    const onSelectedDivisionIdsChange = (
        divisionId: string,
        isSelected: boolean
    ) => {
        if (divisionId && isSelected) {
            setSelectedDivisionId(divisionId);
        }
    };

    return (
        <Stack>
            <Stack.Item>
                <div
                    style={{
                        minWidth: 200,
                        maxWidth: 300,
                        marginLeft: 8,
                        marginRight: 8,
                    }}
                >
                    <DivisionPicker
                        placeholder="Filter by division"
                        selectedDivisionId={selectedDivisionId}
                        onChange={onSelectedDivisionIdsChange}
                        fyCodeFilter={props.fyCodeFilter}
                    />
                </div>
            </Stack.Item>
            <ShimmeredDetailsList
                setKey="items"
                items={sortedItems}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={loading}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details"
                listProps={{
                    renderedWindowsAhead: 0,
                    renderedWindowsBehind: 0,
                }}
                onItemInvoked={(item?: TaskCategoryQl): void => {
                    if (item) {
                        props.onItemEdit(item);
                    }
                }}
            />
        </Stack>
    );
}
