import React from 'react';
import { TemplateReportCustomTablePanel } from './TemplateReportCustomTablePanel';
import { TemplateReportElementTypes } from '../../../data/types';
import {
    TemplateReportElementType,
    TemplateReportGroupType,
} from '../TemplateReport';

export function TemplateReportAddPanel(props: {
    elementType?: TemplateReportElementTypes | null;
    sectionId: string | null;
    isOpen: boolean;
    onDismiss: () => void;
    onElementsAdded?: (
        elements: TemplateReportElementType[],
        group?: TemplateReportGroupType | null
    ) => void;
    isReadOnly: boolean;
    missionId: string | null | undefined;
}) {
    const newElement: TemplateReportElementType = {
        id: null,
        sourceTemplateReportSectionElementId: null,
        textContent: '',
        groupId: null,
        sectionId: props.sectionId || '',
        attachmentId: null,
        title: 'New Data Table',
        type: TemplateReportElementTypes.CustomTable,
        columns: [],
        layoutGrow: '1',
        sequence: 1,
        utcUpdated: null,
        version: '',
        measureLinks: [],
        taskLinks: [],
    };

    return (
        <TemplateReportCustomTablePanel
            {...props}
            element={newElement}
            onElementsAdded={props.onElementsAdded}
        />
    );
}
