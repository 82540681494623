import React, { createContext } from 'react';

type NavigationContextType = {
    isNavBarExpanded: boolean;
    toggleNavBar: () => void;
};

export const NavigationContext = createContext<NavigationContextType>({
    isNavBarExpanded: true,
    toggleNavBar: () => {
        /* defined below */
    },
});

export class NavigationProvider extends React.Component<{
    children?: React.ReactNode;
}> {
    toggleNavBar = (): void => {
        this.setState({ isNavBarExpanded: !this.state.isNavBarExpanded });
    };

    state = {
        isNavBarExpanded: true,
        toggleNavBar: this.toggleNavBar,
    };

    render(): JSX.Element {
        return (
            <NavigationContext.Provider value={this.state}>
                {this.props.children}
            </NavigationContext.Provider>
        );
    }
}
