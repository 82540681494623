import { useCallback, useMemo, useState } from 'react';
import { IContextualMenuItem } from '@fluentui/react';

/// Provides a button with a drop down to toggle the view mode, saving the preference in storage.
export function useViewModeDropDownButton<T extends string>(
    storageKey: string,
    viewModes: {
        [key in T]: {
            text: string;
            iconName: string;
        };
    },
    defaultMode: T
): { button: IContextualMenuItem; activeViewMode: T } {
    const getSavedViewMode = useCallback((): T => {
        const storedViewMode = localStorage.getItem(storageKey) as
            | T
            | null
            | undefined;

        if (!storedViewMode) {
            localStorage.setItem(storageKey, defaultMode);
        }

        if (storedViewMode && viewModes[storedViewMode as T]) {
            return storedViewMode;
        }
        return defaultMode;
    }, [defaultMode, storageKey, viewModes]);

    const [viewMode, setViewMode] = useState<T>(getSavedViewMode());

    const changeViewMode = useCallback(
        (mode: T): void => {
            localStorage.setItem(storageKey, mode);
            setViewMode(mode);
        },
        [storageKey]
    );

    const button: IContextualMenuItem = useMemo(() => {
        const currentViewMode = viewModes[viewMode as T];
        return {
            key: 'ViewMode',
            iconProps: { iconName: currentViewMode.iconName },
            text: currentViewMode.text,
            subMenuProps: {
                items: Object.keys(viewModes).map((key) => {
                    const vm = viewModes[key as T];
                    return {
                        key,
                        text: vm.text,
                        iconProps: { iconName: vm.iconName },
                        onClick: () => changeViewMode(key as T),
                    };
                }),
            },
        };
    }, [changeViewMode, viewMode, viewModes]);

    return {
        activeViewMode: viewMode,
        button: button,
    };
}
