import React from 'react';
import { Position, SpinButton } from '@fluentui/react';

export function DecimalPlacesInput(props: {
    defaultValue: number | undefined | null;
    onChange: (newValue: number) => void;
    disabled?: boolean;
}): JSX.Element {
    const minValue = 0;
    const maxValue = 4;

    return (
        <SpinButton
            labelPosition={Position.top}
            value={(props.defaultValue || 0).toString()}
            label="Decimal Places"
            min={minValue}
            max={maxValue}
            step={1}
            onBlur={(ev: React.FocusEvent<HTMLInputElement>): void =>
                props.onChange(Number(ev.target.value))
            }
            onIncrement={(value): void =>
                props.onChange(Math.min(maxValue, +value + 1))
            }
            onDecrement={(value): void =>
                props.onChange(Math.max(minValue, +value - 1))
            }
            disabled={props.disabled}
        />
    );
}
