import React from 'react';
import { IButtonStyles, IContextualMenuItem } from '@fluentui/react';
import { TaskTileColumn } from '../../../hooks/useColumnPreferences';
import { EngagementActionButton } from '../../../components/engagement/EngagementTarget';

export function TaskRatingsActionButton(props: {
    styles: IButtonStyles;
    selectedColumnNames: TaskTileColumn[];
    toggleColumn: (columnName: TaskTileColumn) => void;
}): JSX.Element {
    const ratings: {
        columnName: TaskTileColumn;
        iconOn: string;
        iconOff: string;
        text: string;
    }[] = [
        {
            columnName: 'effortWeight',
            iconOn: 'EffortSolid',
            iconOff: 'Effort',
            text: 'Effort',
        },
        {
            columnName: 'costWeight',
            iconOn: 'OfflineStorageSolid',
            iconOff: 'OfflineStorage',
            text: 'Cost',
        },
    ];

    const menuItems: IContextualMenuItem[] = ratings.map((r) => {
        const isSelected = props.selectedColumnNames.some(
            (c) => c === r.columnName
        );
        const iconName = isSelected ? r.iconOn : r.iconOff;
        const onClick = () => props.toggleColumn(r.columnName);
        return {
            key: r.columnName,
            iconProps: {
                iconName: iconName,
            },
            text: r.text,
            canCheck: true,
            isChecked: isSelected,
            onClick: onClick,
        };
    });

    return (
        <EngagementActionButton
            engagementTargetKey="TaskRatingsVisibilityDropdown"
            text="Ratings"
            iconProps={{ iconName: 'Equalizer' }}
            styles={props.styles}
            menuProps={{
                items: menuItems,
            }}
        />
    );
}
