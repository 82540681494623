import React from 'react';
import { Text, PrimaryButton } from '@fluentui/react';
import { ErrorContainer } from './components/ErrorContainer';
import { authService } from '../../services/auth.service';
import { useActiveView } from '../../hooks/useActiveView';

export function AccountNotFound(props: {
    emailAddress?: string | null;
}): JSX.Element {
    const logout = (): void => {
        authService.logoutAsync();
    };

    useActiveView('Error', 'Error');

    return (
        <ErrorContainer emailAddress={props.emailAddress}>
            <Text variant="xLargePlus" block>
                Account not found
            </Text>
            <Text variant="medium" block>
                We could not find an account setup for{' '}
                {props.emailAddress || 'this email address'}.
            </Text>
            <Text variant="medium" block>
                Please try another email address or contact our support team.
            </Text>
            <PrimaryButton text="Log Out" onClick={logout}></PrimaryButton>
        </ErrorContainer>
    );
}
