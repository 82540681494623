import React from 'react';

export function PresentationSectionIndicator(props: {
    label?: string | JSX.Element;
    alignDot?: 'start' | 'center';
}): JSX.Element {
    return (
        <div
            className="mintLeftPanelIndicator"
            style={{
                alignItems: props.alignDot || 'center',
            }}
        >
            <div className="mintLeftLabel">{props.label}</div>

            <div className="mintLeftLabelDot">&nbsp;</div>
        </div>
    );
}
