import React, { useCallback, useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import {
    Stack,
    Link,
    Icon,
    PersonaSize,
    Persona,
    TooltipHost,
    FontSizes,
    MessageBar,
    MessageBarType,
    mergeStyleSets,
    DefaultButton,
} from '@fluentui/react';

import {
    GetDependenciesQuery,
    GetMissionTasksQuery,
    useGetDependenciesQuery,
} from '../data/types';
import DependencyActionPanel from './DependencyActionPanel';
import { useStateContext } from '../services/contextProvider';
import { photoService } from '../services/photo.service';
import { Access, ExtractQueryArrayType } from '../data/extendedTypes';
import { useThemes } from '../hooks/useThemes';

import { useTaskWarnings } from '../hooks/useTaskWarning';
import { TaskSummaryItem } from '../scenes/MissionBuilder/components/TaskList';
import { ContributorTaskList } from './ContributorTaskList';
import { EngagementTarget } from './engagement/EngagementTarget';
import { useId } from '@fluentui/react-hooks';

type TaskNotificationsProps = {
    missionId: string;
    missionUserId: string;
    financialYearCode: string;
    missionAccess: Access;
    tasks: GetMissionTasksQuery['tasks'];
    onTaskClick: (taskId: string) => void;
    onTaskEditClick: (taskId: string) => void;
    onImpliedTaskDrag: (task: TaskSummaryItem) => void;
    onNotificationCountChanged?: (count: number) => void;
};

type DependencyType = ExtractQueryArrayType<
    GetDependenciesQuery,
    ['dependencies']
>;

export default function TaskNotifications(
    props: TaskNotificationsProps
): JSX.Element | null {
    const { tasks, onNotificationCountChanged } = props;

    const { currentTenantId } = useStateContext();
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

    const { currentTheme } = useThemes();

    const [currentDependencyTaskId, setCurrentDependencyTaskId] =
        React.useState<string | null>(null);

    const [showPanel, setShowPanel] = React.useState<boolean>(false);

    const [dependencyAction, setDependencyAction] = React.useState<
        'accept' | 'reject'
    >('accept');

    const { loading, data } = useGetDependenciesQuery({
        skip:
            !props.missionUserId ||
            !currentTenantId ||
            !props.financialYearCode,
        variables: {
            tenantId: currentTenantId || '',
            userId: props.missionUserId,
            financialYearCode: props.financialYearCode,
        },
    });

    const getMissionUserId = (): string => {
        return props.missionUserId;
    };

    const taskWarnings = useTaskWarnings(tasks || [], props.onTaskEditClick);

    const notifications: {
        key: string;
        date: string;
        description: JSX.Element;
        iconName: string;
        persona?: {
            userId: string;
            name: string | null;
        } | null;
    }[] = [];

    const resourcedTasks =
        data?.dependencies
            ?.filter((t) => !t.utcCancelled && !t.utcPostponed)
            ?.filter((d) =>
                // Does the task have an outstanding dependency for this mission user?
                (d?.resourcedTasks || []).some(
                    (rt) =>
                        (!rt?.utcAccepted || rt?.missionId === null) &&
                        !rt?.utcRejected &&
                        rt?.resource?.userId &&
                        rt?.resource?.userId.toUpperCase() ===
                            getMissionUserId().toUpperCase()
                )
            ) || [];

    const outstandingDependencies =
        resourcedTasks.filter((d) =>
            (d?.resourcedTasks || []).some(
                (rt) =>
                    !rt?.utcAccepted &&
                    rt?.resource?.userId &&
                    rt?.resource?.userId.toUpperCase() ===
                        getMissionUserId().toUpperCase()
            )
        ) || [];

    // Tasks that the user may have been adding % and dates to before having a mission.
    const contributorTasks = resourcedTasks
        .map((t) => {
            // Add the resourcing task on to the resourced tasks so that this can be used later on.
            return {
                ...t,
                resourcedTasks: t.resourcedTasks.map((rt) => {
                    return {
                        isPercentageCompleteFromResources: false,
                        isPercentageCompleteFromSubTasks: false,
                        isDuplicate: false,
                        resourcedFromTaskId: t.id,
                        resourcedFromTask: t,
                        taskCategory: t.parentTask?.taskCategory || null,
                        ...rt,
                    };
                }),
            };
        })
        .flatMap((d) => d.resourcedTasks)
        .filter(
            (rt) =>
                rt?.utcAccepted &&
                rt?.missionId === null &&
                rt?.resource?.userId &&
                rt.resource?.userId.toUpperCase() ===
                    getMissionUserId().toUpperCase()
        );

    const dependencyClick = (task: DependencyType): void => {
        // This is the task that has been resourced to the current mission's user.
        const resourcedTask = (task?.resourcedTasks || []).find(
            (rt) =>
                (!rt?.utcAccepted || rt?.missionId === null) &&
                !rt?.utcRejected &&
                rt?.resource?.userId &&
                rt?.resource?.userId.toUpperCase() ===
                    getMissionUserId().toUpperCase()
        );

        if (resourcedTask) {
            setDependencyAction('accept');
            setCurrentDependencyTaskId(resourcedTask.id);
            setShowPanel(true);
        }
    };

    outstandingDependencies.forEach((d) => {
        const requestedTask = d.resourcedTasks?.find(
            (t) => t.resource?.userId === props.missionUserId
        );

        const renderTooltipContent = () => (
            <Persona
                imageUrl={photoService.getImageUrl(d.mission?.userId)}
                text={d.mission?.username || d.mission?.owner || 'Unknown'}
                secondaryText={d.mission?.title || undefined}
                size={PersonaSize.size40}
            />
        );

        notifications.push({
            key: `dependency_${d.id}`,
            date: requestedTask?.utcCreated || d.utcCreated,
            description: (
                <div>
                    <Link onClick={() => dependencyClick(d)} as="a">
                        <TooltipHost
                            tooltipProps={{
                                onRenderContent: renderTooltipContent,
                            }}
                        >
                            <span>
                                {d.mission?.username ||
                                    d.mission?.owner ||
                                    'Unknown'}
                            </span>
                        </TooltipHost>{' '}
                        requested you as a resource on the task{' '}
                        <strong>{d.name}</strong>
                    </Link>
                </div>
            ),
            iconName: 'FollowUser',
            persona: d.mission?.userId
                ? {
                      name: d.mission.username,
                      userId: d.mission.userId,
                  }
                : null,
        });
    });

    taskWarnings
        .filter((w) => w.hasWarning && w.createNotification)
        .forEach((w) => {
            const task = tasks?.find((t) => t.id === w.taskId);
            const handleClick = () => props.onTaskEditClick(task?.id || '');

            const content =
                w.createNotification &&
                w
                    .createNotification(<strong>{task?.name || '?'}</strong>)
                    .map((f, index) => (
                        <React.Fragment key={index}>{f}</React.Fragment>
                    ));

            const description = (
                <div>
                    <Link onClick={handleClick} as="a">
                        {content}
                    </Link>
                </div>
            );

            notifications.push({
                key: `warning_${w.taskId}`,
                date: w.date || '',
                description: description,
                iconName: 'Warning',
                persona: null,
            });
        });

    useEffect(() => {
        if (!loading && onNotificationCountChanged) {
            onNotificationCountChanged(notifications.length);
        }
    }, [notifications.length, loading, tasks, onNotificationCountChanged]);

    const onExpandToggle = () => setIsExpanded(!isExpanded);

    const handlePanelClose = useCallback(() => setShowPanel(false), []);

    const classNames = mergeStyleSets({
        icon: {
            color: currentTheme.semanticColors.warningIcon,
            fontSize: FontSizes.size12,
            lineHeight: FontSizes.size12,
        },
        description: {
            color: currentTheme.semanticColors.messageText,
            fontSize: FontSizes.size12,
            lineHeight: FontSizes.size12,
        },
    });

    const notificationMessageBarId = useId('engagementButton');

    if (loading || (!notifications.length && !contributorTasks.length)) {
        return null;
    }

    return (
        <div>
            <DependencyActionPanel
                missionId={props.missionId}
                missionUserId={props.missionUserId}
                missionAccess={props.missionAccess}
                taskId={currentDependencyTaskId}
                action={dependencyAction}
                onChangeAction={setDependencyAction}
                showPanel={showPanel}
                onCancel={handlePanelClose}
                onSave={handlePanelClose}
            />

            <EngagementTarget
                engagementTargetKey="TaskNotificationsTitle"
                targetId={notificationMessageBarId}
            />

            <Stack tokens={{ childrenGap: 16 }}>
                <MessageBar
                    messageBarType={MessageBarType.warning}
                    isMultiline
                    id={notificationMessageBarId}
                >
                    <Stack tokens={{ childrenGap: 16 }} verticalAlign="start">
                        <Stack.Item styles={{ root: { marginBottom: 8 } }}>
                            Task Notifications
                        </Stack.Item>

                        {orderBy(notifications, 'date', 'desc').map(
                            (n, index) => {
                                if (!isExpanded && index > 1) {
                                    return null;
                                }

                                return (
                                    <Stack.Item key={n.key}>
                                        <Stack
                                            horizontal
                                            tokens={{ childrenGap: 8 }}
                                        >
                                            <Stack.Item>
                                                <Icon
                                                    iconName={n.iconName}
                                                    className={classNames.icon}
                                                />
                                            </Stack.Item>
                                            <Stack.Item grow>
                                                <div
                                                    className={
                                                        classNames.description
                                                    }
                                                >
                                                    {n.description}
                                                </div>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>
                                );
                            }
                        )}

                        {notifications.length > 2 && (
                            <Stack.Item>
                                <DefaultButton
                                    onClick={onExpandToggle}
                                    iconProps={{
                                        iconName: isExpanded
                                            ? 'CalculatorSubtract'
                                            : 'CalculatorAddition',
                                    }}
                                >
                                    {isExpanded
                                        ? 'Show less'
                                        : `${notifications.length - 2} more`}
                                </DefaultButton>
                            </Stack.Item>
                        )}
                    </Stack>
                </MessageBar>

                {contributorTasks.length > 0 && (
                    <ContributorTaskList
                        {...props}
                        contributorTasks={contributorTasks}
                        title="Contributor Tasks"
                    />
                )}
            </Stack>
        </div>
    );
}
