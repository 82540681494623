import React, { PropsWithChildren } from 'react';
import {
    Stack,
    Text,
    Icon,
    TooltipHost,
    DirectionalHint,
} from '@fluentui/react';
import TaskProgressIndicator from './TaskProgressIndicator';
import { TaskWithStatus } from '../data/extendedTypes';

type ResourceTooltipTask = TaskWithStatus & {
    id: string | null;
    resourceIsPrimary: boolean;
    rejectedReason?: string | null;
    percentComplete: number;
    resource: {
        displayName: string | null;
        userId: string | null;
        userMissionFYs: {
            code: string | null;
        }[];
    } | null;
};

export function ResourceTooltip(
    props: PropsWithChildren<{
        showStatus: boolean;
        resourcedTask?: ResourceTooltipTask;
        resourcedTasks?: ResourceTooltipTask[];
        directionalHint?: DirectionalHint;
    }>
): JSX.Element {
    const { resourcedTask, resourcedTasks } = props;

    const renderCoolTipContent = (): JSX.Element => {
        const tasks = resourcedTask ? [resourcedTask] : resourcedTasks || [];

        return (
            <Stack tokens={{ childrenGap: 16 }}>
                {tasks.map((rt) => {
                    return (
                        <Stack
                            key={rt.id}
                            tokens={{ childrenGap: 8 }}
                            style={{ minWidth: 120 }}
                        >
                            {rt.resourceIsPrimary && (
                                <Stack.Item>
                                    <Stack
                                        horizontal
                                        tokens={{ childrenGap: 4 }}
                                    >
                                        <Icon iconName="PartyLeader" />
                                        <Text variant="smallPlus">Primary</Text>
                                    </Stack>
                                </Stack.Item>
                            )}
                            {rt.resource?.displayName && (
                                <Stack.Item>
                                    <Text variant="mediumPlus">
                                        {rt.resource?.displayName}
                                    </Text>
                                </Stack.Item>
                            )}
                            <Stack.Item>
                                <TaskProgressIndicator
                                    task={rt}
                                    showStatus={props.showStatus}
                                />
                            </Stack.Item>

                            {rt.utcRejected && rt.rejectedReason && (
                                <Stack.Item>
                                    <Text variant="small">
                                        <q>{rt.rejectedReason}</q>
                                    </Text>
                                </Stack.Item>
                            )}
                        </Stack>
                    );
                })}
            </Stack>
        );
    };

    return (
        <TooltipHost
            tooltipProps={{
                onRenderContent: renderCoolTipContent,
            }}
            directionalHint={props.directionalHint}
        >
            {props.children}
        </TooltipHost>
    );
}
