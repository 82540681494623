import React, { ReactNode } from 'react';
import {
    MessageBar,
    PrimaryButton,
    MessageBarType,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
} from '@fluentui/react';
import { ActiveViews, useActiveView } from '../../hooks/useActiveView';

type DeleteModalProps = {
    isOpen: boolean;
    isDeleting: boolean;
    message: string;
    error: { message: string | null } | undefined | null;
    primaryButtonText?: string | null;
    deleteAction: () => void | Promise<void>;
    onDismiss: () => void;
    content?: ReactNode;
    activeViewName: keyof typeof ActiveViews | null;
};

export default function DeleteModal(props: DeleteModalProps): JSX.Element {
    useActiveView(props.isOpen ? props.activeViewName : null);

    return (
        <Dialog
            hidden={!props.isOpen}
            onDismiss={props.onDismiss}
            modalProps={{
                isBlocking: true,
            }}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Confirm',
                closeButtonAriaLabel: 'Close',
                subText: props.message,
            }}
        >
            {props.error && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                >
                    {props.error.message}
                </MessageBar>
            )}

            {props.content}

            <DialogFooter>
                <PrimaryButton
                    text={props.primaryButtonText || 'Delete'}
                    disabled={props.isDeleting}
                    onClick={(): void => {
                        props.deleteAction();
                    }}
                />
                <DefaultButton
                    text="Cancel"
                    disabled={props.isDeleting}
                    onClick={(): void => {
                        props.onDismiss();
                    }}
                />
            </DialogFooter>
        </Dialog>
    );
}
