import * as React from 'react';

import { Stack, mergeStyleSets, ThemeProvider } from '@fluentui/react';
import { TopNav } from '../../../components/navigation';
import { useThemes } from '../../../hooks/useThemes';
import { EngagementProvider } from '../../../components/engagement/EngagementContext';

export function SetupPageContainer(props: {
    children: React.ReactNode;
}): JSX.Element {
    const { currentTheme } = useThemes();

    const styles = mergeStyleSets({
        container: {
            backgroundColor: currentTheme.palette.neutralLighter,
            height: '100%',
        },
    });
    return (
        <ThemeProvider theme={currentTheme} style={{ height: '100%' }}>
            <EngagementProvider>
                <Stack className={styles.container}>
                    <TopNav showSearch={false} showBreadcrumbs={false} />

                    <Stack
                        tokens={{ padding: 'm', childrenGap: 'm' }}
                        styles={{
                            root: {
                                height: '100%',
                                backgroundColor:
                                    currentTheme.palette.neutralLighter,
                            },
                        }}
                    >
                        {props.children}
                    </Stack>
                </Stack>
            </EngagementProvider>
        </ThemeProvider>
    );
}
