import * as React from 'react';
import { Pivot, PivotItem, Stack } from '@fluentui/react';
import { Introduction } from './apiHelp/Introduction';
import { Authentication } from './apiHelp/Authentication';
import { Rest } from './apiHelp/Rest';
import { ApiHelpFinancialYears } from './apiHelp/ApiHelpFinancialYears';
import { ApiHelpDivisions } from './apiHelp/ApiHelpDivisions';
import { ApiKeysPivot } from './ApiKeysPivot';
import { ApiHelpTeams } from './apiHelp/ApiHelpTeams';
import { ApiHelpMissions } from './apiHelp/ApiHelpMissions';
import { ApiHelpMeasures } from './apiHelp/ApiHelpMeasures';
import { ApiHelpMeasureValues } from './apiHelp/ApiHelpMeasureValues';
import { ApiHelpTasks } from './apiHelp/ApiHelpTasks';

type Props = {};

export function ApiHelpPivot(props: Props): JSX.Element {
    return (
        <Stack>
            <Pivot>
                <PivotItem itemKey="apiKeyManager" headerText="API Keys">
                    <ApiKeysPivot />
                </PivotItem>
                <PivotItem itemKey="apiHelpIntro" headerText="Help">
                    <Introduction />
                </PivotItem>
                <PivotItem itemKey="apiHelpAuth" headerText="Authentication">
                    <Authentication />
                </PivotItem>
                <PivotItem itemKey="apiHelpRest" headerText="OData">
                    <Rest />
                </PivotItem>
                <PivotItem itemKey="apiHelpFY" headerText="FY">
                    <ApiHelpFinancialYears />
                </PivotItem>
                <PivotItem itemKey="apiHelpDivisions" headerText="Divisions">
                    <ApiHelpDivisions />
                </PivotItem>
                <PivotItem itemKey="apiHelpTeams" headerText="Teams">
                    <ApiHelpTeams />
                </PivotItem>
                <PivotItem itemKey="apiHelpMissions" headerText="Missions">
                    <ApiHelpMissions />
                </PivotItem>
                <PivotItem itemKey="apiHelpMeasures" headerText="Measures">
                    <ApiHelpMeasures />
                </PivotItem>
                <PivotItem
                    itemKey="apiHelpMeasureValues"
                    headerText="Measure Values"
                >
                    <ApiHelpMeasureValues />
                </PivotItem>
                <PivotItem itemKey="apiHelpTasks" headerText="Tasks">
                    <ApiHelpTasks />
                </PivotItem>
            </Pivot>
        </Stack>
    );
}
