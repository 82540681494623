import React from 'react';
import { MeasureTypes, Multipliers } from '../../data/types';
import { ValueInput } from './ValueInput';

export function TargetRangeInput(props: {
    defaultValue: number | null;
    onChange: (newValue: number | null) => void;
    disabled?: boolean;
    label: string;
    measureType: MeasureTypes;
    multiplier: Multipliers;
    currencySymbol: string | null | undefined;
    decimalPlaces: number | undefined;
    minValue?: number | undefined;
}): JSX.Element {
    return (
        <ValueInput
            {...props}
            defaultValue={{ decimalValue: props.defaultValue }}
            label={props.label}
            disabled={props.disabled}
        />
    );
}
