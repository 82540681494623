import * as React from 'react';
import {
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    Spinner,
    Stack,
    TextField,
} from '@fluentui/react';
import { useStateContext } from '../../services/contextProvider';
import { useJsonUploadMutation } from '../../data/types';
import { useState } from 'react';

type JsonUploadPanelProps = {
    showPanel: boolean;
    divisionId: string | null;
    onDismiss: () => void;
};

export function JsonUploadPanel(props: JsonUploadPanelProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [json, setJson] = useState<string>('');

    const [errorMessage, setErrorMessage] = useState<string>();

    const [jsonImport, { loading }] = useJsonUploadMutation();

    React.useEffect(() => {
        setErrorMessage(undefined);
        setJson('');
    }, [props.showPanel]);

    const handleUploadClick = async () => {
        if (currentTenantId && props.divisionId && json) {
            setErrorMessage(undefined);

            const result = await jsonImport({
                variables: {
                    tenantId: currentTenantId,
                    divisionId: props.divisionId,
                    json: json,
                },
                onError: (err) => {
                    setErrorMessage(err.message);
                },
            });

            const message = result.data?.jsonUpload?.message;

            if (result.data?.jsonUpload?.success) {
                props.onDismiss();
            } else if (message) {
                setErrorMessage(message);
            }
        }
    };

    const onRenderFooterContent = () => (
        <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign="end">
            {!!errorMessage && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                >
                    <span>{errorMessage}</span>
                </MessageBar>
            )}

            {loading && <Spinner />}

            <PrimaryButton onClick={handleUploadClick} disabled={loading}>
                Upload
            </PrimaryButton>
        </Stack>
    );

    return (
        <Panel
            isOpen={props.showPanel}
            type={PanelType.medium}
            onDismiss={props.onDismiss}
            headerText="Upload Json"
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom
            styles={{
                scrollableContent: {
                    height: '100%',
                },
                content: {
                    height: '100%',
                },
            }}
        >
            <Stack
                tokens={{ childrenGap: 16 }}
                verticalFill
                verticalAlign="stretch"
            >
                <Stack.Item align="stretch" verticalFill>
                    <TextField
                        value={json}
                        onChange={(_ev, newValue) => {
                            setJson(newValue || '');
                        }}
                        multiline
                        styles={{
                            root: { height: '100%' },
                            fieldGroup: { height: '100%' },
                            field: { height: '100%' },
                            wrapper: { height: '100%' },
                        }}
                    />
                </Stack.Item>
            </Stack>
        </Panel>
    );
}
