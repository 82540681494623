export class TaskStatusIcons {
    public static readonly notStarted = 'StatusCircleOuter';
    public static readonly offTarget = 'StatusCircleOuter';
    public static readonly onTarget = 'StatusCircleOuter';
    public static readonly completedLate = 'SkypeCircleCheck';
    public static readonly completedOnTime = 'SkypeCircleCheck';
    public static readonly pending = 'TemporaryUser';
    public static readonly rejected = 'BlockContact';
    public static readonly cancelled = 'Blocked';
    public static readonly postponed = 'Recent';
    public static readonly atRisk = 'ReportWarning';
}
