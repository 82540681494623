import { useCurrentTenant } from './useCurrentTenant';

type FeatureList = {
    Measures2023Default: boolean;
    Measures2023Upgrade: boolean;
};

export const useFeatures = (): FeatureList => {
    const tenant = useCurrentTenant();

    const legacyMeasureTenants = ['NONE-DELETE-ME'];

    const useLegacyMeasures = legacyMeasureTenants.some(
        (t) => t === tenant?.code
    );

    const featureList: FeatureList = {
        Measures2023Default: !useLegacyMeasures,
        Measures2023Upgrade: !useLegacyMeasures,
    };

    return featureList;
};
