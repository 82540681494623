import React, { useEffect, useState } from 'react';
import {
    Stack,
    TooltipHost,
    IconButton,
    Dialog,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    DialogType,
    ChoiceGroup,
    IChoiceGroupOption,
    IDialogContentProps,
} from '@fluentui/react';

import { useThemes } from '../hooks/useThemes';

function WarningButton(props: {
    warning: {
        hasWarning: boolean;
        warningMessage: string | null;
        warningDetail?: string | null;
        actions: {
            name: string;
            executeAction: (() => Promise<void>) | null;
            isSelected: boolean;
            disabled?: boolean;
        }[];
    };
    showActions: boolean;
    noOptionsAction?: {
        actionName: string;
        executeAction: () => void;
    };
}): JSX.Element | null {
    const [hideDialog, setHideDialog] = useState(true);
    const [selectedActionIndex, setSelectedActionIndex] = useState<number>();

    const { actions, hasWarning, warningMessage, warningDetail } =
        props.warning;

    useEffect(() => {
        if (actions) {
            setSelectedActionIndex(actions.findIndex((a) => a.isSelected));
        }
    }, [actions]);

    const { currentTheme } = useThemes();

    if (!hasWarning) {
        return null;
    }

    const toggleHideDialog = (): void => {
        setHideDialog(!hideDialog);
    };

    const styles = {
        stackStyle: {
            root: {
                textAlign: 'center',
                fontSize: 16,
            },
        },
        iconStyle: {
            root: {
                color: currentTheme.semanticColors.severeWarningIcon,
                height: 16,
            },
            rootHovered: {
                backgroundColor: 'transparent',
            },
        },
        tooltipStyle: {
            root: {
                height: 16,
                fontSize: 0,
            },
        },
    };

    const dialogContentProps: IDialogContentProps = {
        type: DialogType.largeHeader,
        title: warningMessage || 'Warning',
        closeButtonAriaLabel: 'Close',
        showCloseButton: true,
        subText: warningDetail || '',
        styles: {
            subText: {
                whiteSpace: 'pre-line',
            },
        },
    };

    const options: IChoiceGroupOption[] = actions.map((a, index) => {
        return { key: index.toString(), text: a.name, disabled: a.disabled };
    });

    function optionChange(
        _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ): void {
        setSelectedActionIndex(Number(option?.key));
    }

    const update = (): void => {
        const action =
            selectedActionIndex !== undefined
                ? actions[Number(selectedActionIndex)]
                : null;

        if (action?.executeAction) {
            action.executeAction();
        }
        toggleHideDialog();
    };

    const handleWarningClick = () => {
        // If there are no choices execute the no options action.
        if (options.length === 0 && props.noOptionsAction?.executeAction) {
            props.noOptionsAction?.executeAction();
        } else {
            toggleHideDialog();
        }
    };

    return (
        <React.Fragment>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{
                    isBlocking: true,
                }}
            >
                {props.showActions && options.length > 0 && (
                    <ChoiceGroup
                        onChange={optionChange}
                        selectedKey={selectedActionIndex?.toString()}
                        options={options}
                    />
                )}

                <DialogFooter>
                    {props.showActions && options.length > 0 && (
                        <PrimaryButton text="Confirm" onClick={update} />
                    )}

                    {/* {props.showActions &&
                        options.length === 0 &&
                        props.noOptionsAction?.executeAction && (
                            <PrimaryButton
                                text={props.noOptionsAction.actionName}
                                onClick={() => {
                                    props.noOptionsAction?.executeAction();
                                    toggleHideDialog();
                                }}
                            />
                        )} */}

                    <DefaultButton
                        text={
                            props.showActions && options.length > 0
                                ? 'Cancel'
                                : 'Close'
                        }
                        onClick={toggleHideDialog}
                    />
                </DialogFooter>
            </Dialog>
            <Stack
                horizontal
                verticalFill
                verticalAlign={'center'}
                styles={styles.stackStyle}
            >
                <TooltipHost
                    content={warningMessage || ''}
                    styles={styles.tooltipStyle}
                    calloutProps={{
                        dismissOnTargetClick: true,
                    }}
                >
                    <IconButton
                        iconProps={{ iconName: 'AlertSolid' }}
                        styles={styles.iconStyle}
                        onClick={handleWarningClick}
                    />
                </TooltipHost>
            </Stack>
        </React.Fragment>
    );
}

export default WarningButton;
