import React from 'react';

import { Text, Link, IFontStyles } from '@fluentui/react';

import { EditibleText } from '../../../components/EditibleText';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import { useStateContext } from '../../../services/contextProvider';
import { useInputMappers } from '../../../hooks/useInputMappers';
import { GetMeasureQuery, useUpdateMeasureMutation } from '../../../data/types';

function MeasureNameEdit(props: {
    measure: ExtractQueryArrayType<GetMeasureQuery, ['measure']>;
    onClick?: () => void;
    isReadOnly: boolean;
    variant?: keyof IFontStyles;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [updateMeasureMutation] = useUpdateMeasureMutation();

    const { getMeasureInput } = useInputMappers();

    const updateMeasureName = async (id: string | null, name: string) => {
        if (!id || !currentTenantId) {
            return;
        }

        const measureForInput = {
            ...props.measure,
            name: name,
        };

        const input = getMeasureInput(measureForInput);

        await updateMeasureMutation({
            variables: {
                tenantId: currentTenantId,
                input: input,
            },
        });
    };

    const handleTextUpdateClick = async (newText: string) => {
        await updateMeasureName(props.measure?.id, newText);
    };

    const text = (
        <Text
            variant={props.variant}
            style={{
                whiteSpace: 'pre-line',
                fontStyle: !props.measure?.name?.trim() ? 'italic' : 'normal',
            }}
        >
            {props.measure?.name?.trim() ? props.measure?.name : 'Untitled'}
        </Text>
    );

    return (
        <EditibleText
            isReadOnly={props.isReadOnly}
            text={props.measure?.name || ''}
            dialogTitle="Edit Measure Name"
            onUpdateClick={handleTextUpdateClick}
        >
            {props.onClick ? <Link onClick={props.onClick}>{text}</Link> : text}
        </EditibleText>
    );
}

export default React.memo(MeasureNameEdit);
