import { useApolloClient } from '@apollo/client';
import React, { useCallback } from 'react';
import { useMatch, matchPath } from 'react-router-dom';
import {
    GetMissionDocument,
    GetMissionNavDocument,
    GetMissionNavQuery,
    GetMissionNavQueryVariables,
    GetMissionQuery,
    GetMissionQueryVariables,
} from './data/types';

import { InitialState } from './services/context';
import { useDispatchContext } from './services/contextProvider';
import { PathParams, paths } from './services/navigation';

export function RouteContext(
    props: React.PropsWithChildren<{ children?: React.ReactNode }>
): JSX.Element {
    const dispatch = useDispatchContext();
    const client = useApolloClient();

    const switchTenantMatch = useMatch({
        path: paths.switchTenant,
        end: true,
    });

    const defaultMatch = useMatch({
        path: '*',
    });

    let routeParams: PathParams | undefined = undefined;

    for (const p of Object.values(paths)) {
        const match = matchPath(
            { path: p, end: true },
            defaultMatch?.pathname || ''
        );
        if (match) {
            routeParams = match.params;
            break;
        }
    }

    const prefetchMission = useCallback(
        (tenantCode: string, missionId: string) => {
            const tenantId = InitialState.userTenants?.find(
                (t) =>
                    t &&
                    t.tenant.code?.toUpperCase() === tenantCode.toUpperCase()
            )?.tenant?.id;

            if (tenantId) {
                // prefetch mission (this is so we start loading it ASAP before other components like breadcrumbs, etc take the bandwidth)
                client.query<GetMissionNavQuery, GetMissionNavQueryVariables>({
                    query: GetMissionNavDocument,
                    variables: {
                        tenantId: tenantId,
                        missionId: missionId,
                    },
                });
                client.query<GetMissionQuery, GetMissionQueryVariables>({
                    query: GetMissionDocument,
                    variables: {
                        tenantId: tenantId,
                        missionId: missionId,
                    },
                });
            }
        },
        [client]
    );

    React.useEffect(() => {
        const updateContext = (params: PathParams): void => {
            dispatch({
                type: 'SetupContext',
                payload: {
                    currentTenantCode: params.tenantCode || '',
                    currentFinancialYearCode:
                        params.financialYearCode || undefined,
                    currentTeamCode: params.teamCode || undefined,
                    currentMissionId: params.missionId || undefined,
                    themePrimaryColourHex: undefined,
                },
            });
        };

        if (!routeParams?.tenantCode) {
            if (
                InitialState?.userTenants &&
                InitialState.userTenants.length > 0
            ) {
                const defaultUserTenant =
                    InitialState.userTenants.find(
                        (ut) => ut && ut.isDefault && ut.tenant.isEnabled
                    ) ||
                    InitialState.userTenants.find((ut) => ut.tenant.isEnabled);

                updateContext({
                    tenantCode: defaultUserTenant?.tenant.code || '',
                    financialYearCode: '',
                    teamCode: '',
                    missionId: '',
                });
            }
        } else if (routeParams) {
            if (routeParams.missionId && routeParams.tenantCode) {
                prefetchMission(routeParams.tenantCode, routeParams.missionId);
            }
            updateContext(routeParams);
        }

        if (switchTenantMatch) {
            updateContext({
                tenantCode: '',
                financialYearCode: '',
                teamCode: '',
                missionId: '',
            });
        }
    }, [dispatch, routeParams, switchTenantMatch, prefetchMission]);

    return <React.Fragment>{props.children}</React.Fragment>;
}
