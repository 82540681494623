import React, { useState } from 'react';
import {
    Stack,
    DefaultButton,
    PrimaryButton,
    Dialog,
    DialogFooter,
    DialogType,
    IconButton,
    TextField,
    IButtonStyles,
} from '@fluentui/react';
import { useThemes } from '../../hooks/useThemes';
import { useViewport } from '../../hooks/useViewport';

export function StatusReportTitle(props: {
    title: string | null | undefined;
    isReadOnly: boolean;
    onTitleUpdated: (title: string) => void;
}): JSX.Element {
    const { currentTheme } = useThemes();

    const { isTouchDevice } = useViewport();

    const [isEditIconShown, setIsEditIconShown] = useState(false);
    const [isEditDialogShown, setIsEditDialogShown] = useState(false);
    const [editTitleText, setEditTitleText] = useState('');

    const handleEditIconClick = () => {
        setEditTitleText(props.title || 'Status Report');
        setIsEditDialogShown(true);
    };

    const handleUpdateTitleClick = () => {
        props.onTitleUpdated(editTitleText);
        setIsEditDialogShown(false);
    };

    const buttonStyle: IButtonStyles = {
        root: {
            color: currentTheme.palette.themeLighter,
            selectors: {
                '&:hover': {
                    color: currentTheme.palette.themePrimary,
                },
            },
        },
    };

    return (
        <React.Fragment>
            <Stack
                horizontal
                tokens={{ childrenGap: 16 }}
                onMouseEnter={() => setIsEditIconShown(true)}
                onMouseLeave={() => setIsEditIconShown(false)}
            >
                {props.title || 'Status Report'}
                {(isEditIconShown || isTouchDevice) && !props.isReadOnly && (
                    <IconButton
                        iconProps={{ iconName: 'Rename' }}
                        onClick={handleEditIconClick}
                        styles={buttonStyle}
                        title="Rename"
                    />
                )}
            </Stack>
            <Dialog
                hidden={!isEditDialogShown}
                onDismiss={() => setIsEditDialogShown(false)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Update Title',
                    closeButtonAriaLabel: 'Close',
                }}
            >
                <TextField
                    value={editTitleText}
                    onChange={(_ev, newValue) => {
                        setEditTitleText(newValue || '');
                    }}
                />

                <DialogFooter>
                    <PrimaryButton
                        text="Update"
                        onClick={handleUpdateTitleClick}
                    />
                    <DefaultButton
                        onClick={() => setIsEditDialogShown(false)}
                        text="Cancel"
                    />
                </DialogFooter>
            </Dialog>
        </React.Fragment>
    );
}
