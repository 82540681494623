import React from 'react';

import {
    ShimmeredDetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    IColumn,
    IconButton,
    Link,
    Stack,
} from '@fluentui/react';

import dayjs from 'dayjs';

import {
    MeasureSeriesNames,
    MeasureValueHistoryItem,
} from '../data/extendedTypes';

import MeasureArrow from './MeasureArrow';
import { useSorter } from '../hooks/useSorter';
import WarningButton from './WarningButton';
import { useThemes } from '../hooks/useThemes';
import { getLabelForDate } from '../scenes/Measure/utils/measureUtils';
import { useStateContext } from '../services/contextProvider';
import { FrequencyPeriods, useGetFinancialYearQuery } from '../data/types';

export function MeasureValuesHistoryList(props: {
    valueHistory: MeasureValueHistoryItem[];
    isDataLoading: boolean;
    isStatusLimited: boolean;
    frequencyPeriod: FrequencyPeriods;
    isCustomMeasure: boolean;
    onDeleteValueClick: (asOfId: string) => void;
    onEditValueClick: (asOfId: string) => void;
    allowDelete: boolean;
    allowEdit: boolean;
    onGetPhasingForDate: (asOfDate: string) => {
        decimalValue: number | null;
        stringValue: string | null;
        dateValue: string | null;
        formatStr: string | null;
    } | null;
    onSyncTargetWithPhasingConfirm: (asOfDate: string) => Promise<void>;
}): JSX.Element {
    const { currentTheme } = useThemes();

    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const { data: fyData } = useGetFinancialYearQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: currentFinancialYearCode || '',
        },
    });

    const fyStartDate = fyData?.financialYear?.startDate;

    const getValueFormatted = (
        value:
            | {
                  formatStr: string | null;
                  decimalValue: number | null;
                  stringValue: string | null;
              }
            | null
            | undefined
    ): string => {
        return (
            value?.formatStr ||
            value?.decimalValue?.toString() ||
            value?.stringValue ||
            ''
        );
    };

    const getTargetElement = (asOf?: MeasureValueHistoryItem): JSX.Element => {
        const value = asOf?.values?.find(
            (v) => v?.seriesType?.name === MeasureSeriesNames.Target
        );

        const phasedValue = asOf?.asOfDate
            ? props.onGetPhasingForDate(asOf.asOfDate)
            : null;

        const formattedValue = getValueFormatted(value);

        const hasPhasingWarning =
            props.allowEdit &&
            phasedValue?.decimalValue !== null &&
            phasedValue?.decimalValue !== value?.decimalValue;

        const formattedPhasedValue = phasedValue
            ? getValueFormatted(phasedValue)
            : null;

        const syncTargetWithPhasing = async (): Promise<void> => {
            if (asOf) {
                await props.onSyncTargetWithPhasingConfirm(asOf.asOfDate || '');
            }
        };

        return hasPhasingWarning ? (
            <Stack horizontal tokens={{ padding: 0 }} verticalAlign="center">
                <span>{formattedValue}</span>

                <WarningButton
                    warning={{
                        hasWarning: true,
                        warningMessage: `The target value does not match the phased target value of ${formattedPhasedValue}.`,
                        actions: [
                            {
                                name: `Change target to ${formattedPhasedValue}.`,
                                executeAction: syncTargetWithPhasing,
                                isSelected: true,
                            },
                        ],
                    }}
                    showActions
                />
            </Stack>
        ) : (
            <span>{formattedValue}</span>
        );
    };

    const getElementForSeries = (
        seriesName: MeasureSeriesNames,
        asOf?: MeasureValueHistoryItem
    ): JSX.Element => {
        const value = asOf?.values?.find(
            (v) => v?.seriesType?.name === seriesName
        );
        const formattedValue = getValueFormatted(value);
        return <span>{formattedValue}</span>;
    };

    const getElementForDate = (
        value?: MeasureValueHistoryItem
    ): JSX.Element => {
        const formatted =
            props.isCustomMeasure ||
            props.frequencyPeriod == FrequencyPeriods.None ||
            props.frequencyPeriod == FrequencyPeriods.Week ||
            !fyStartDate
                ? dayjs.utc(value?.asOfDate).format('DD MMM YYYY')
                : getLabelForDate(
                      fyStartDate,
                      props.frequencyPeriod,
                      value?.asOfDate || '',
                      false
                  );

        if (props.allowEdit && value?.id) {
            return (
                <Link onClick={() => props.onEditValueClick(value.id || '')}>
                    {formatted}
                </Link>
            );
        } else {
            return <span>{formatted}</span>;
        }
    };

    const getElementForStatus = (
        value?: MeasureValueHistoryItem
    ): JSX.Element => {
        return value ? (
            <MeasureArrow
                {...value}
                isStatusLimited={props.isStatusLimited}
                showPercentage={true}
                iconFontSize={12}
                textFontSize={12}
            />
        ) : (
            <span />
        );
    };

    const renderDeleteButton = (item: MeasureValueHistoryItem): JSX.Element => {
        return (
            <IconButton
                iconProps={{
                    styles: {
                        root: {
                            color: currentTheme.semanticColors.errorIcon,
                        },
                    },
                    iconName: 'Delete',
                }}
                onClick={(): void => {
                    if (item?.id) {
                        props.onDeleteValueClick(item.id);
                    }
                }}
            />
        );
    };

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(props.valueHistory as [], 'asOfDate', false, true);

    const columns: IColumn[] = [
        {
            key: 'asOfDate',
            name: 'As Of',
            fieldName: 'asOfDate',
            minWidth: 60,
            isResizable: true,
            isSorted: sortColumn === 'asOfDate',
            isSortedDescending: sortColumn === 'asOfDate' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('asOfDate'),
            onRender: (value?: MeasureValueHistoryItem): JSX.Element =>
                getElementForDate(value),
        },
        {
            key: 'target',
            name: 'Target',
            fieldName: 'target',
            minWidth: 100,
            isResizable: true,
            onRender: (value?: MeasureValueHistoryItem): JSX.Element =>
                getTargetElement(value),
        },
        {
            key: 'actual',
            name: 'Actual',
            fieldName: 'actual',
            minWidth: 100,
            isResizable: true,
            onRender: (value?: MeasureValueHistoryItem): JSX.Element =>
                getElementForSeries(MeasureSeriesNames.Actual, value),
        },
        {
            key: 'status',
            name: 'Status',
            fieldName: 'status',
            minWidth: 80,
            isResizable: true,
            onRender: (value?: MeasureValueHistoryItem): JSX.Element =>
                getElementForStatus(value),
        },
    ];

    if (props.allowDelete) {
        columns.push({
            key: 'actions',
            name: '',
            minWidth: 32,
            isIconOnly: true,
            isPadded: false,
            className: 'iconCell',
            onRender: renderDeleteButton,
        });
    }

    return (
        <ShimmeredDetailsList
            items={sortedItems}
            enableShimmer={props.isDataLoading}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
        />
    );
}
