import React from 'react';
import { Link, Text, mergeStyleSets } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { paths } from '../../services/navigation';

export default function Footer(props: {
    onManageCookiesClick?: () => void;
    alignment?: 'center' | 'stretch';
}): JSX.Element {
    const { t } = useTranslation();

    const classNames = mergeStyleSets({
        footerContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            textAlign: 'start',
            justifyContent: 'start',
            margin: 8,
            '@media(max-width: 768px)': {
                textAlign: 'center',
                justifyContent: 'center',
            },
        },
        footerContainerCenter: {
            textAlign: 'center',
            justifyContent: 'center',
        },
        footerItem: {
            margin: 8,
            '@media(max-width: 768px)': {
                width: '100%',
                margin: 4,
            },
        },
    });

    return (
        <div
            className={`${classNames.footerContainer} ${props.alignment === 'center' ? classNames.footerContainerCenter : ''} no-print`}
        >
            <div
                className={classNames.footerItem}
                style={{
                    flexGrow: props.alignment === 'center' ? undefined : 1,
                }}
            >
                <Text style={{ whiteSpace: 'nowrap' }}>
                    {t('footer.copyright', { year: new Date().getFullYear() })}
                </Text>
            </div>
            <div className={classNames.footerItem}>
                <Link
                    href={paths.termsOfUse}
                    target="_blank"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {t('footer.termsOfUse')}
                </Link>
            </div>
            <div className={classNames.footerItem}>
                <Link
                    href={paths.privacyPolicy}
                    target="_blank"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {t('footer.privacyPolicy')}
                </Link>
            </div>
            {!!props.onManageCookiesClick && (
                <div className={classNames.footerItem}>
                    <Link
                        onClick={props.onManageCookiesClick}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {t('footer.manageCookies')}
                    </Link>
                </div>
            )}
        </div>
    );
}
