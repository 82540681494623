import React from 'react';
import {
    getColorFromString,
    getShade,
    IColor,
    Shade,
    Text,
} from '@fluentui/react';

import orderBy from 'lodash/orderBy';

import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    DotProps,
} from 'recharts';
import { MeasureChartColours, MeasureStatusColours } from '../../../Colours';
import {
    GetMeasureValueFormulaQuery,
    MeasureTypes,
    Multipliers,
} from '../../../data/types';
import { useFormatters } from '../../../hooks/useFormatters';
import {
    ContentType,
    Payload,
} from 'recharts/types/component/DefaultLegendContent';
import { ILegend, Legends } from '@fluentui/react-charting';
import { useThemes } from '../../../hooks/useThemes';
import {
    MeasureComposedRechartTooltip,
    MeasureComposedRechartTooltipStackBreakDown,
} from './MeasureComposedRechartTooltip';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import { ChartDataRaw } from '../utils/measureUtils';
import { useLanguage } from '../../../services/i18n';

interface Props {
    width?: number | string;
    height?: number | string;
    data: {
        Label: string;
        Date: string | null;
        Target: number | string | null;
        Actual: number | string | null;
        Forecast: number | null;
        StatusValue?: number | null;
        isDeleted?: boolean;
        isCurrentPeriod?: boolean;
    }[];
    showForecast: boolean;
    actualMode: 'bar' | 'line';
    measureFormat: {
        measureType: MeasureTypes;
        multiplier: Multipliers | null;
        currencySymbol: string | null;
        decimalPlaces: number;
        isStatusLimited: boolean;
    };
    hideLegend?: boolean;
    hideXAxis?: boolean;
    hideYAxis?: boolean;
    tooltipTitle?: string | null;
    statusOnly?: boolean;
    compact?: boolean;
    isStacked?: boolean;
    formulaData?: ExtractQueryArrayType<
        GetMeasureValueFormulaQuery,
        ['measureValueFormula']
    > | null;
}

function MeasureComposedRechart({
    width = 850,
    height = 350,
    data = [],
    showForecast = false,
    actualMode = 'line',
    measureFormat,
    hideLegend,
    hideXAxis,
    hideYAxis,
    tooltipTitle,
    statusOnly = false,
    compact,
    isStacked = false,
    formulaData,
}: Props): JSX.Element {
    const { currentTheme } = useThemes();

    const { t } = useLanguage();
    const legendRemapping: { [index: string]: string } = {
        Forecast: t('measure-of-success.forecast'),
        ForecastYTD: t('measure-of-success.forecastYtd'),
    };

    const { formatMeasureValue, formatMeasureStatus } = useFormatters();

    const targetHex = currentTheme.palette.themeLight;
    const actualHex = currentTheme.palette.themePrimary;

    const targetColour = getColorFromString(targetHex);
    const actualColour = getColorFromString(actualHex);
    const forecastColour = getColorFromString(MeasureChartColours.forecast);

    const actualShade = actualColour
        ? getShade(actualColour, Shade.Shade2)?.hex
        : undefined;

    const forecastShade = forecastColour
        ? getShade(forecastColour, Shade.Shade3)?.hex
        : undefined;

    const yAxisFormatter = (value: unknown): string =>
        formatMeasureValue(measureFormat, value);

    const yAxisStatusFormatter = (value: number | null): string =>
        formatMeasureStatus(value, measureFormat.isStatusLimited);

    const renderLegend: ContentType = (props: { payload?: Payload[] }) => {
        const { payload } = props;

        const legends: ILegend[] = payload
            ? payload.map((p) => {
                  const title =
                      p.value in legendRemapping
                          ? legendRemapping[p.value]
                          : p.value;
                  return {
                      title: title || '',
                      color: p.color || '',
                  };
              })
            : [];

        return (
            <div style={{ display: 'flex', height: 40, alignItems: 'end' }}>
                <Legends
                    legends={legends}
                    overflowText={'Overflow Items'}
                    allowFocusOnLegends={false}
                    centerLegends
                />
            </div>
        );
    };

    const orderedData = orderBy(
        data.filter((d: { isDeleted?: boolean }) => !d.isDeleted),
        'dt'
    );

    const toolTipStackBreakdown: MeasureComposedRechartTooltipStackBreakDown[] =
        [];

    const chartData: ChartDataRaw[] = orderedData.map((d) => {
        return {
            Label: d.Label,
            Date: d.Date,
            Target: d.Target,
            Actual: d.Actual,
            StatusValue: d.StatusValue,
            Forecast: showForecast ? d.Forecast : null,
        };
    });

    const actualDataKeys: {
        field: string;
        symbol: string;
        hexColour: string;
    }[] = [];

    if (isStacked && formulaData?.measures) {
        const added: { [id: string]: boolean } = {};

        const registerField = (
            fieldName: string,
            stackedSymbol: string,
            baseColour: IColor | undefined
        ) => {
            const shades = [
                Shade.Shade6,
                Shade.Shade5,
                Shade.Shade4,
                Shade.Shade7,
                Shade.Shade8,
            ];

            if (!added[fieldName as string]) {
                const existingInStack = actualDataKeys.filter(
                    (k) => k.symbol === stackedSymbol
                );

                const shade = shades[existingInStack.length % 5];

                const colour = baseColour
                    ? getShade(baseColour, shade)
                    : undefined;

                actualDataKeys.push({
                    field: fieldName,
                    symbol: stackedSymbol,
                    hexColour: colour ? `#${colour.hex}` : '',
                });
                added[fieldName as string] = true;
            }
        };

        for (const d of chartData) {
            if (!d.Date) {
                continue;
            }

            if (formulaData && formulaData.measures) {
                for (const series of [
                    { name: 'Target', symbol: 'T', baseColour: targetColour },
                    { name: 'Actual', symbol: 'A', baseColour: actualColour },
                ]) {
                    for (const m of formulaData.measures) {
                        const asOf = m.valueHistory?.find(
                            (a) => a.asOfDate === d.Date
                        );
                        if (asOf) {
                            const seriesValue = asOf.values?.find(
                                (v) => v.seriesType?.name === series.name
                            );
                            if (seriesValue) {
                                const fieldName: string = `${m.name} ${series.name}`;
                                registerField(
                                    fieldName,
                                    series.symbol,
                                    series.baseColour
                                );
                                d[fieldName as string] =
                                    seriesValue.decimalValue;

                                if (
                                    m.name &&
                                    seriesValue.decimalValue !== null
                                ) {
                                    toolTipStackBreakdown.push({
                                        asOf: asOf.asOfDate,
                                        seriesName: series.name,
                                        seriesValue: seriesValue.decimalValue,
                                        measureName: m.name,
                                        hexColour:
                                            actualDataKeys.find(
                                                (a) => a.field === fieldName
                                            )?.hexColour || null,
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    let axisWidth = 100;

    if (
        measureFormat.measureType === MeasureTypes.Currency ||
        measureFormat.measureType === MeasureTypes.Numeric
    ) {
        const largestNumber = Math.max(
            0,
            ...data.map((d) => Number(d.Target ?? 0)),
            ...data.map((d) => Number(d.Actual ?? 0))
        );

        const largestNumberFormatted = formatMeasureValue(
            measureFormat,
            largestNumber
        );

        if (largestNumberFormatted.length > 6) {
            axisWidth = 60 + largestNumberFormatted.length * 6;
        }
    }

    const currentLabel = data.find((d) => d.isCurrentPeriod)?.Label;

    const customTick = (props: { x: number; y: number; payload: Payload }) => {
        const { x, y, payload } = props;

        const { value } = payload;

        const isCurrent = value === currentLabel;

        const fill = currentTheme.palette.neutralPrimary;

        const fontSize = compact ? 12 : undefined;

        const fontWeight = value === currentLabel ? '600' : undefined;

        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={-6}
                    dy={14}
                    fill={fill}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    textAnchor="middle"
                >
                    {value}
                </text>
                {isCurrent && (
                    <polygon points="-6,20 6,20 0,14" style={{ fill: fill }} />
                )}
            </g>
        );
    };

    return (
        <ResponsiveContainer width={width} height={height}>
            <ComposedChart
                data={chartData}
                margin={{
                    top: 8,
                    right: hideYAxis ? 4 : 32,
                    bottom: 4,
                    left: 4,
                }}
                barGap={1}
            >
                <XAxis
                    hide={hideXAxis}
                    dataKey="Label"
                    scale="auto"
                    tick={customTick}
                    axisLine={!compact}
                    tickLine={false}
                    padding={
                        statusOnly && hideYAxis
                            ? { left: 32, right: 32 }
                            : undefined
                    }
                />
                <YAxis
                    hide={hideYAxis}
                    tickFormatter={
                        statusOnly ? yAxisStatusFormatter : yAxisFormatter
                    }
                    tick={{ fill: currentTheme.palette.neutralPrimary }}
                    width={axisWidth}
                />
                <Tooltip
                    wrapperStyle={{
                        zIndex: 1000,
                        outline: 'none',
                    }}
                    contentStyle={{
                        backgroundColor: currentTheme.palette.white,
                    }}
                    content={
                        <MeasureComposedRechartTooltip
                            stackBreakdown={toolTipStackBreakdown}
                        />
                    }
                    formatter={(value, name) => {
                        if (name === 'Status') {
                            return formatMeasureStatus(
                                Number(value) || 0,
                                measureFormat.isStatusLimited
                            );
                        }
                        return formatMeasureValue(measureFormat, value);
                    }}
                    labelFormatter={(label) => {
                        return (
                            <>
                                <Text block variant="mediumPlus">
                                    {label}
                                </Text>
                                {!!tooltipTitle && (
                                    <Text block variant="smallPlus">
                                        {tooltipTitle}
                                    </Text>
                                )}
                            </>
                        );
                    }}
                />
                {!hideLegend && <Legend content={renderLegend} />}
                {!statusOnly && !isStacked && (
                    <Bar
                        dataKey="Target"
                        barSize={16}
                        fill={targetHex}
                        radius={[4, 4, 0, 0]}
                    />
                )}
                {!statusOnly && actualMode === 'bar' && !isStacked && (
                    <Bar
                        dataKey="Actual"
                        barSize={16}
                        fill={actualHex}
                        radius={[4, 4, 0, 0]}
                    />
                )}

                {!statusOnly &&
                    actualMode === 'bar' &&
                    isStacked &&
                    actualDataKeys.map((dataKey) => (
                        <Bar
                            key={dataKey.field}
                            dataKey={dataKey.field}
                            barSize={16}
                            stackId={dataKey.symbol}
                            fill={dataKey.hexColour}
                        />
                    ))}

                {!statusOnly &&
                    actualMode === 'bar' &&
                    showForecast &&
                    actualDataKeys.map((dataKey) => {
                        <Bar
                            dataKey={dataKey.field}
                            barSize={16}
                            stackId={dataKey.symbol}
                            fill={MeasureChartColours.forecast}
                        />;
                    })}
                {!statusOnly && actualMode === 'line' && (
                    <Area
                        type="monotone"
                        dataKey="Actual"
                        fill={`#${actualShade}`}
                        stroke={actualHex}
                        strokeWidth="3"
                        dot={{
                            stroke: actualHex,
                            strokeWidth: 2,
                        }}
                    />
                )}
                {!statusOnly && showForecast && (
                    <Line
                        type="monotone"
                        dataKey="Forecast"
                        stroke={`#${forecastShade}`}
                        dot={{
                            stroke: MeasureChartColours.forecast,
                            strokeWidth: 2,
                        }}
                        color={MeasureChartColours.forecast}
                        strokeLinecap="round"
                        strokeWidth="3"
                    />
                )}
                {statusOnly && (
                    <Line
                        type="monotone"
                        dataKey="StatusValue"
                        name="Status"
                        fill={actualHex}
                        stroke={actualHex}
                        strokeWidth="3"
                        dot={<StatusLineDot />}
                    />
                )}
            </ComposedChart>
        </ResponsiveContainer>
    );
}

function StatusLineDot(props: DotProps): JSX.Element | null {
    const { cx, cy, strokeWidth, stroke, fill, r } = props;

    const payload = (props as { payload: ChartDataRaw }).payload;

    if (!payload.Actual) {
        return null;
    }

    let colourOverride: string | null = null;
    switch (payload.Actual.toString().toUpperCase()) {
        case 'GREEN':
        case 'YES':
        case 'ON':
            colourOverride = MeasureStatusColours.onTarget;
            break;
        case 'YELLOW':
            colourOverride = '#edcb00'; // GP Yellow
            break;
        case 'RED':
        case 'NO':
        case 'OFF':
            colourOverride = MeasureStatusColours.offTarget;
            break;
    }

    return (
        <circle
            cx={cx}
            cy={cy}
            r={r}
            stroke={colourOverride ? colourOverride : stroke}
            strokeWidth={strokeWidth}
            fill={colourOverride ? colourOverride : fill}
        />
    );
}

export default React.memo(MeasureComposedRechart);
