import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

let reactPlugin: ReactPlugin;
let appInsights: ApplicationInsights;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = (): {
    reactPlugin: ReactPlugin;
    appInsights: ApplicationInsights;
    initialize: (
        instrumentationKey: string,
        disableCookiesUsage: boolean
    ) => void;
} => {
    reactPlugin = new ReactPlugin();
    const browserHistory = createBrowserHistory();

    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = (
        instrumentationKey: string,
        disableCookiesUsage: boolean
    ): void => {
        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!instrumentationKey) {
            throw new Error(
                'Instrumentation key not provided in TelemetryProvider.tsx'
            );
        }

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                enableAutoRouteTracking: true,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory },
                },
                disableCookiesUsage: disableCookiesUsage,
            },
        });

        appInsights.loadAppInsights();
    };

    return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = (): ApplicationInsights => appInsights;
