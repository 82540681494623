import React from 'react';
import {
    ITheme,
    mergeStyleSets,
    Spinner,
    SpinnerSize,
    ThemeProvider,
} from '@fluentui/react';
import { defaultTheme, useThemes } from '../hooks/useThemes';

export default function Loading(props: { padding?: number }): JSX.Element {
    const styles = mergeStyleSets({
        loadingContainer: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: props.padding !== undefined ? props.padding : 16,
            backgroundColor: 'transparent !important',
        },
    });

    const { currentTheme } = useThemes();

    return (
        <ThemeProvider
            theme={currentTheme}
            className={styles.loadingContainer}
            as="div"
        >
            <Spinner size={SpinnerSize.large} />
        </ThemeProvider>
    );
}

export function UnthemedLoading(props: {
    padding?: number;
    theme?: ITheme;
}): JSX.Element {
    const styles = mergeStyleSets({
        loadingContainer: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: props.padding !== undefined ? props.padding : 16,
            backgroundColor: 'transparent !important',
        },
    });

    return (
        <ThemeProvider
            theme={props.theme ? props.theme : defaultTheme}
            className={styles.loadingContainer}
            as="div"
        >
            <Spinner size={SpinnerSize.large} />
        </ThemeProvider>
    );
}
