import React, { useCallback, useEffect, useState } from 'react';

import {
    Stack,
    MessageBar,
    MessageBarType,
    TextField,
    Toggle,
    PanelType,
    Text,
    Pivot,
    PivotItem,
    Label,
} from '@fluentui/react';

import { EditPanel } from './shared/EditPanel';
import {
    useUpdateMissionMutation,
    Mission,
    useGetMissionQuery,
    useDeleteMissionMutation,
    MissionPhrase,
    Team,
} from '../data/types';

import { useStateContext } from '../services/contextProvider';
import {
    EditablePhrase,
    MissionPhraseEditableList,
} from './MissionPhraseEditableList';
import { UserPicker } from './UserPicker';
import DeleteModal from './shared/DeleteModal';
import Loading from './Loading';
import { useThemes } from '../hooks/useThemes';
import { TeamPicker } from './inputs/TeamPicker';
import { FinancialYearPicker } from './inputs/FinancialYearPicker';
import omit from 'lodash/omit';
import { useLanguage } from '../services/i18n';
import { useInputMappers } from '../hooks/useInputMappers';
import { MissionMentorLabel } from './inputs/MissionMentorLabel';
import { useMissionMentor } from '../hooks/useMissionMentor';

type EditMissionPanelProps = {
    missionId: string | undefined;
    showPanel: boolean;
    onCancel: () => void;
    onSave: () => void;
};

export default function EditMissionPanel(
    props: EditMissionPanelProps
): JSX.Element {
    const { currentTenantId, currentRoles } = useStateContext();
    const { getMissionInput } = useInputMappers();
    const { reviewMissionStatement, isEnabled } = useMissionMentor();

    const [missionMentorContent, setMissionMentorContent] =
        useState<JSX.Element>();
    const [missionMentorActions, setMissionMentorActions] =
        useState<{ name: string; action: () => void }[]>();

    const [isValid, setIsValid] = React.useState(true);
    const [mission, setMission] = React.useState<Mission | undefined>(
        {} as Mission
    );
    const [financialYear, setFinancialYear] = React.useState<
        { id: string | null; code: string | null } | undefined
    >();
    const [formErrorMessages, setFormErrorMessages] = React.useState({
        owner: '',
        title: '',
        missionStatement: '',
    });

    const [behaviours, setBehaviours] = React.useState<EditablePhrase[]>();
    const [freedoms, setFreedoms] = React.useState<EditablePhrase[]>();
    const [constraints, setConstraints] = React.useState<EditablePhrase[]>();

    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);

    const { currentTheme } = useThemes();
    const { t } = useLanguage();

    const validate = (): void => {
        const formErrorMessages = {
            owner: !mission?.owner ? 'Please enter a value.' : '',
            title: !mission?.title ? 'Please enter a value.' : '',
            missionStatement: '',
        };

        const isValid = !formErrorMessages.owner && !formErrorMessages.title;

        setFormErrorMessages(formErrorMessages);
        setIsValid(isValid);
    };

    const handleUserInput = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void => {
        const element = ev.target as HTMLInputElement;
        const name = element.name;
        if (mission) {
            setMission({ ...mission, [name]: newValue });
        }
    };

    const {
        loading: missionLoading,
        data: missionData,
        error,
    } = useGetMissionQuery({
        skip: !currentTenantId || !props.missionId || !props.showPanel,
        fetchPolicy: 'network-only',
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId || '',
        },
    });

    const preparePhrasesForMutation = (
        phases: EditablePhrase[] | undefined
    ): MissionPhrase[] => {
        return (phases?.filter((t) => !t.isDeleted) || []).map((p) =>
            omit(p, ['isNew', 'isDeleted'])
        );
    };

    const [updateMission, { loading: isSaving, error: saveError }] =
        useUpdateMissionMutation({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    ...((mission || {}) as Mission),
                    behaviours: preparePhrasesForMutation(behaviours),
                    freedoms: preparePhrasesForMutation(freedoms),
                    constraints: preparePhrasesForMutation(constraints),
                },
            },
            onCompleted: props.onSave,
        });

    const [deleteMissionMutation, { loading: isDeleting, error: deleteError }] =
        useDeleteMissionMutation();

    useEffect(() => {
        if (props.showPanel && props.missionId && missionData?.mission) {
            setMission(getMissionInput(missionData.mission));
            if (missionData.mission.team?.division?.financialYear) {
                setFinancialYear(
                    missionData.mission.team?.division?.financialYear
                );
            }
            setFreedoms(
                missionData.mission.freedoms.map((f) => {
                    return {
                        ...f,
                        id: f.id || '',
                        isNew: false,
                        isDeleted: false,
                    };
                })
            );
            setConstraints(
                missionData.mission.constraints.map((f) => {
                    return {
                        ...f,
                        id: f.id || '',
                        isNew: false,
                        isDeleted: false,
                    };
                })
            );
            setBehaviours(
                missionData.mission.behaviours.map((f) => {
                    return {
                        ...f,
                        id: f.id || '',
                        isNew: false,
                        isDeleted: false,
                    };
                })
            );
        } else {
            setMission(undefined);
        }
    }, [props.showPanel, props.missionId, missionData, getMissionInput]);

    useEffect(validate, [mission]);

    const handleStatementPromptSelected = useCallback(async () => {
        if (missionData?.mission && mission) {
            setMissionMentorActions([]);

            const response = await reviewMissionStatement({
                ...missionData,
                mission: {
                    ...missionData.mission,
                    title: mission.title || missionData.mission.title,
                    missionStatement: mission.missionStatement,
                    customMissionStatement1Up:
                        mission.customMissionStatement1Up,
                    customMissionStatement2Up:
                        mission.customMissionStatement2Up,
                },
            });

            setMissionMentorContent(response.content);

            setMissionMentorActions(
                response.newMissionStatement
                    ? [
                          {
                              name: 'Use Suggested Mission Statement',
                              action: () => {
                                  setMission({
                                      ...mission,
                                      missionStatement:
                                          response.newMissionStatement,
                                  });
                              },
                          },
                      ]
                    : []
            );
        }
    }, [missionData, mission, reviewMissionStatement]);

    if (error) {
        return (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={true}
            >
                <span>{error.message}</span>
            </MessageBar>
        );
    }

    const oneUpMission =
        missionData?.mission?.team?.leaderMission &&
        missionData?.mission.team.leaderMission.id !== missionData?.mission.id
            ? missionData?.mission.team?.leaderMission
            : null;

    const twoUpMission =
        oneUpMission?.team?.leaderMission &&
        oneUpMission.team.leaderMission.id !== oneUpMission.id
            ? oneUpMission.team.leaderMission
            : null;

    const realOneUpStatement = oneUpMission?.missionStatement || '';

    const realTwoUpStatement = twoUpMission?.missionStatement || '';

    const confirmDelete = (): void => {
        setIsConfirmingDelete(true);
    };

    const deleteMissionAsync = async (): Promise<void> => {
        await deleteMissionMutation({
            variables: {
                tenantId: currentTenantId || '',
                id: props.missionId || '',
                restore: false,
            },
            refetchQueries: ['GetMissionSearch'],
        });

        setIsConfirmingDelete(false);

        if (props.onCancel) {
            props.onCancel();
        }
    };

    const getAdminControls = (): JSX.Element => {
        if (!mission?.id) {
            return <Loading />;
        }

        const selectedUsers = mission?.userId
            ? [
                  {
                      userId: mission?.userId,
                      displayName:
                          missionData?.mission?.username || mission?.userId,
                  },
              ]
            : [];

        const onUserChange = (
            users: {
                userId: string;
                name: string;
            }[]
        ): void => {
            setMission({
                ...((mission || {}) as Mission),
                userId: users?.length > 0 ? users[0].userId : null,
            });
        };

        return (
            <Stack tokens={{ childrenGap: 8 }}>
                <Text variant="mediumPlus">Admin Options</Text>

                <FinancialYearPicker
                    label="FY"
                    placeholder="Select a financial year"
                    selectedFinancialYearId={financialYear?.id}
                    onChange={(financialYear): void => {
                        setFinancialYear(financialYear);
                    }}
                />

                <TeamPicker
                    label="Team"
                    selectedTeamId={mission?.teamId}
                    financialYearCode={financialYear?.code}
                    onChange={(team, selected): void => {
                        if (!team) {
                            return;
                        }
                        setMission({
                            ...((mission || {}) as Mission),
                            teamId: selected ? team.id : null,
                            dottedLines: selected
                                ? mission.dottedLines.filter(
                                      (dt) => dt?.teamId !== team.id
                                  )
                                : mission.dottedLines,
                        });
                    }}
                />

                <TeamPicker
                    label="Leader Of Teams"
                    financialYearCode={financialYear?.code}
                    selectedTeamIds={mission.leaderOfTeams.map(
                        (t) => t?.id || ''
                    )}
                    multiSelect
                    onChange={(team, selected): void => {
                        if (!team) {
                            return;
                        }
                        setMission({
                            ...((mission || {}) as Mission),
                            leaderOfTeams: selected
                                ? [
                                      ...mission.leaderOfTeams,
                                      {
                                          ...team,
                                          leaderMissionId:
                                              missionData?.mission?.id || null,
                                      } as Team,
                                  ]
                                : mission.leaderOfTeams.filter(
                                      (dt) => dt.id !== team.id
                                  ),
                        });
                    }}
                />

                <TeamPicker
                    label="Dotted Line Teams"
                    financialYearCode={financialYear?.code}
                    selectedTeamIds={mission.dottedLines.map(
                        (t) => t?.teamId || ''
                    )}
                    multiSelect
                    onChange={(team, selected): void => {
                        if (!team || team?.id === mission.teamId) {
                            return;
                        }
                        setMission({
                            ...((mission || {}) as Mission),
                            dottedLines: selected
                                ? [
                                      ...mission.dottedLines,
                                      {
                                          missionId: mission.id || '',
                                          teamId: team.id || '',
                                          sequence: 999,
                                          id: null,
                                          missionGroupId: null,
                                      },
                                  ]
                                : mission.dottedLines.filter(
                                      (dt) => dt?.teamId !== team.id
                                  ),
                        });
                    }}
                />

                <UserPicker
                    onChange={onUserChange}
                    itemLimit={1}
                    selectedUsers={selectedUsers}
                />

                <Toggle
                    label={
                        mission?.utcInactive !== null ? 'Inactive' : 'Active'
                    }
                    inlineLabel
                    checked={mission?.utcInactive == null}
                    onChange={(
                        _ev?: React.FormEvent | undefined,
                        isChecked?: boolean | undefined
                    ): void => {
                        const updated = {
                            ...mission,
                            utcInactive: isChecked ? null : new Date(),
                        } as Mission;

                        setMission(updated);
                    }}
                    styles={{
                        root: { marginTop: '16px !important' },
                    }}
                />

                {!missionData?.mission?.utcInactive && mission?.utcInactive && (
                    <MessageBar>
                        Are you sure you want to deactivate this mission? <br />
                        Any users with task resources or linked measures will
                        receive warnings.
                    </MessageBar>
                )}
            </Stack>
        );
    };

    const form = !missionLoading && missionData?.mission && (
        <Stack tokens={{ childrenGap: 8 }}>
            <TextField
                label="Owner"
                required
                name="owner"
                value={mission?.owner || ''}
                errorMessage={formErrorMessages.owner}
                onChange={handleUserInput}
            />

            <TextField
                label="Title"
                required
                name="title"
                value={mission?.title || ''}
                errorMessage={formErrorMessages.title}
                onChange={handleUserInput}
            />

            <TextField
                label="Mission Statement"
                multiline
                autoAdjustHeight
                required
                name="missionStatement"
                value={mission?.missionStatement || ''}
                errorMessage={formErrorMessages.missionStatement}
                onChange={handleUserInput}
                onRenderLabel={(p, defaultRender) => {
                    if (
                        mission &&
                        missionData.mission?.rights &&
                        missionData.mission &&
                        isEnabled
                    ) {
                        return (
                            <MissionMentorLabel
                                textFieldProps={p}
                                defaultRender={defaultRender}
                                content={missionMentorContent}
                                actions={missionMentorActions}
                                onPromptSelected={handleStatementPromptSelected}
                            />
                        );
                    }

                    return defaultRender ? defaultRender(p) : null;
                }}
            />

            <Stack.Item>
                <TextField
                    label="One Up Mission Statement"
                    multiline
                    autoAdjustHeight
                    disabled={mission?.customMissionStatement1Up === null}
                    name="customMissionStatement1Up"
                    value={
                        mission?.customMissionStatement1Up ||
                        realOneUpStatement ||
                        undefined
                    }
                    onChange={handleUserInput}
                />
                <Toggle
                    label="Override"
                    inlineLabel
                    checked={mission?.customMissionStatement1Up !== null}
                    onChange={(
                        _ev?: React.FormEvent | undefined,
                        isChecked?: boolean | undefined
                    ): void => {
                        const newStatement = isChecked
                            ? realOneUpStatement
                            : null;

                        const updated = {
                            ...mission,
                            customMissionStatement1Up: newStatement,
                        } as Mission;

                        setMission(updated);
                    }}
                />
            </Stack.Item>

            <Stack.Item>
                <TextField
                    label="Two Up Mission Statement"
                    multiline
                    autoAdjustHeight
                    disabled={mission?.customMissionStatement2Up === null}
                    name="customMissionStatement2Up"
                    value={
                        mission?.customMissionStatement2Up ||
                        realTwoUpStatement ||
                        undefined
                    }
                    onChange={handleUserInput}
                />
                <Toggle
                    label="Override"
                    inlineLabel
                    checked={mission?.customMissionStatement2Up !== null}
                    onChange={(
                        _ev?: React.FormEvent | undefined,
                        isChecked?: boolean | undefined
                    ): void => {
                        const newStatement = isChecked
                            ? realTwoUpStatement
                            : null;

                        const updated = {
                            ...mission,
                            customMissionStatement2Up: newStatement,
                        } as Mission;

                        setMission(updated);
                    }}
                />
            </Stack.Item>
        </Stack>
    );

    const showAdminControls = currentRoles?.some((r) => r === 'GlobalAdmin');

    const onDeleteClick = showAdminControls ? confirmDelete : undefined;

    const headerText = missionLoading
        ? 'Loading...'
        : `${financialYear?.code?.toLocaleUpperCase()} Mission`;

    return (
        <React.Fragment>
            <EditPanel
                activeViewName="MissionEdit"
                onDismiss={props.onCancel}
                panelType={PanelType.medium}
                showPanel={props.showPanel}
                headerText={headerText}
                isSaving={isSaving}
                isValid={isValid}
                saveErrorMessage={saveError ? saveError.message : null}
                onDeleteClick={onDeleteClick}
                onUpdateClick={(): void => {
                    updateMission({
                        refetchQueries: showAdminControls
                            ? ['GetBreadcrumbs']
                            : [],
                    });
                }}
            >
                {!missionLoading && missionData?.mission && (
                    <Pivot styles={{ root: { marginTop: 8 } }}>
                        <PivotItem headerText="Strategic Direction">
                            {!showAdminControls && form}

                            {showAdminControls && (
                                <div className="panelColumns">
                                    <div className="panelColumn">{form}</div>
                                    <div className="panelColumn">
                                        <Stack>
                                            <Stack.Item
                                                styles={{
                                                    root: {
                                                        backgroundColor:
                                                            currentTheme.palette
                                                                .neutralLight,
                                                        padding: 16,
                                                    },
                                                }}
                                            >
                                                {getAdminControls()}
                                            </Stack.Item>
                                        </Stack>
                                    </div>
                                </div>
                            )}
                        </PivotItem>
                        <PivotItem headerText="Boundaries">
                            <Stack
                                tokens={{ childrenGap: 8 }}
                                styles={{ root: { marginTop: 8 } }}
                            >
                                <div className="panelColumns">
                                    <div className="panelColumn">
                                        <Label>Freedoms</Label>
                                        <MissionPhraseEditableList
                                            addButtonText="Add Freedom"
                                            missionAccess={
                                                missionData?.mission?.rights
                                            }
                                            phrases={freedoms || []}
                                            onItemsUpdated={(phrases) =>
                                                setFreedoms(phrases)
                                            }
                                        />
                                    </div>
                                    <div className="panelColumn">
                                        <Label>Constraints</Label>
                                        <MissionPhraseEditableList
                                            addButtonText="Add Constraint"
                                            missionAccess={
                                                missionData?.mission?.rights
                                            }
                                            phrases={constraints || []}
                                            onItemsUpdated={(phrases) =>
                                                setConstraints(phrases)
                                            }
                                        />
                                    </div>
                                </div>
                            </Stack>
                        </PivotItem>
                        <PivotItem
                            headerText={t('mission.behaviour', { count: 10 })}
                        >
                            <Stack
                                tokens={{ childrenGap: 8 }}
                                styles={{ root: { marginTop: 8 } }}
                            >
                                <MissionPhraseEditableList
                                    addButtonText={t(
                                        'mission.actions.add-behaviour'
                                    )}
                                    missionAccess={missionData?.mission?.rights}
                                    phrases={behaviours || []}
                                    onItemsUpdated={(phrases) =>
                                        setBehaviours(phrases)
                                    }
                                />
                            </Stack>
                        </PivotItem>
                    </Pivot>
                )}
            </EditPanel>
            <DeleteModal
                activeViewName="MissionDelete"
                isOpen={isConfirmingDelete}
                onDismiss={(): void => setIsConfirmingDelete(false)}
                message="Are you sure you want to delete this mission?"
                deleteAction={deleteMissionAsync}
                isDeleting={isDeleting}
                error={deleteError}
            />
        </React.Fragment>
    );
}
