import React, { useMemo } from 'react';
import {
    ProgressIndicator,
    Stack,
    Text,
    Icon,
    TooltipHost,
    IIconProps,
    IconButton,
    Link,
    Callout,
    Target,
    DirectionalHint,
    Shimmer,
    Slider,
    DayOfWeek,
    FontSizes,
    IProgressIndicatorStyles,
    ITextStyles,
    mergeStyleSets,
} from '@fluentui/react';
import { useTaskStatus } from '../hooks/useTaskStatus';
import { useFormatters } from '../hooks/useFormatters';
import { useThemes } from '../hooks/useThemes';
import dayjs from 'dayjs';
import { Access, TaskStatusNames, TaskWithStatus } from '../data/extendedTypes';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { TaskStatusDropDown } from './inputs/TaskStatusDropDown';
import debounce from 'lodash/debounce';
import { InlineDatePicker } from './inputs/InlineDatePicker';
import { DatePickerStrings } from '../services/i18n';

type TaskProgressIndicatorProps = {
    showStatus: boolean;
    hidePercentage?: boolean;
    compact?: boolean;
    task: TaskWithStatus & {
        percentComplete: number;
        rejectedReason?: string | null;
    };
    allowDoneDateInlineEdit?: boolean;
    allowPercentageInlineEdit?: boolean;
    allowStatusInlineEdit?: boolean;
    missionAccess?: Access | undefined;
    onChange?: (taskUpdates: {
        utcPostponed: string | null;
        utcCancelled: string | null;
        utcAtRisk: string | null;
        percentComplete: number;
        done: string | null;
    }) => void;
    isBusy?: boolean;
    statusDate?: string;
};

function TaskProgressIndicator(props: TaskProgressIndicatorProps): JSX.Element {
    const status = useTaskStatus(props.task, props.statusDate);

    const { currentTheme } = useThemes();

    const isComplete =
        status?.name === TaskStatusNames.CompletedOnTime ||
        status?.name === TaskStatusNames.CompletedLate;

    const statusLinkId = useId('status-callout-link');
    const percentageCompleteLinkId = useId('percentage-callout-link');

    const [isStatusCalloutVisible, { toggle: toggleIsStatusCalloutVisible }] =
        useBoolean(false);

    const [
        isPercentageCalloutVisible,
        { toggle: toggleIsPercentageCalloutVisible },
    ] = useBoolean(false);

    if (props.compact) {
        const iconProps: IIconProps = {
            iconName: status?.iconName,
            styles: {
                root: {
                    color: status?.colour,
                },
            },
        };

        return (
            <Stack
                horizontal
                verticalFill
                verticalAlign="center"
                horizontalAlign="stretch"
            >
                <Stack.Item align="center">
                    <TooltipHost
                        content={
                            <TaskProgressIndicator
                                {...props}
                                showStatus={true}
                                compact={false}
                            />
                        }
                    >
                        <IconButton iconProps={iconProps} />
                    </TooltipHost>
                </Stack.Item>
                {props.showStatus && (
                    <Stack.Item align="center">
                        <Text variant="small">{status?.text}</Text>
                    </Stack.Item>
                )}
            </Stack>
        );
    }

    const showProgressBar =
        status?.name !== TaskStatusNames.Pending &&
        status?.name !== TaskStatusNames.Rejected &&
        status?.name !== TaskStatusNames.Postponed &&
        status?.name !== TaskStatusNames.Cancelled;

    const progressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
        progressBar: {
            backgroundColor: props.showStatus ? status?.colour : undefined,
        },
        itemProgress: {
            padding: 0,
        },
        itemDescription: {
            fontSize: 10,
            color: currentTheme.palette.neutralPrimary,
        },
    };

    const fixedStatusTextStyles: Partial<ITextStyles> = {
        root: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
        },
    };

    const classNames = mergeStyleSets({
        statusDisplay: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 8,
            height: 32,
            paddingLeft: 8,
            paddingRight: 8,
        },
    });

    const statusTooltip =
        status?.name === TaskStatusNames.Rejected ? (
            <Text variant="small">
                {props.task.rejectedReason ? (
                    <q>{props.task.rejectedReason}</q>
                ) : (
                    'No reason given'
                )}
            </Text>
        ) : undefined;

    return (
        <Shimmer isDataLoaded={!props.isBusy} width="100%" height={32}>
            <div>
                {!showProgressBar && (
                    <TooltipHost content={statusTooltip}>
                        <div
                            className={classNames.statusDisplay}
                            style={{
                                backgroundColor:
                                    currentTheme.palette.neutralLighter,
                            }}
                        >
                            <Icon
                                iconName={status.iconName}
                                style={{ color: status.colour }}
                            />

                            {props.allowStatusInlineEdit ? (
                                <Link
                                    id={statusLinkId}
                                    onClick={toggleIsStatusCalloutVisible}
                                >
                                    <Text
                                        variant="xSmall"
                                        styles={fixedStatusTextStyles}
                                    >
                                        {status.text}
                                    </Text>
                                </Link>
                            ) : (
                                <Text
                                    variant="xSmall"
                                    styles={fixedStatusTextStyles}
                                >
                                    {status.text}
                                </Text>
                            )}
                        </div>
                    </TooltipHost>
                )}

                {showProgressBar && (
                    <ProgressIndicator
                        barHeight={4}
                        description={
                            <TaskProgressIndicatorText
                                {...props}
                                onStatusClick={toggleIsStatusCalloutVisible}
                                onPercentageClick={
                                    toggleIsPercentageCalloutVisible
                                }
                                statusLinkId={statusLinkId}
                                percentageCompleteLinkId={
                                    percentageCompleteLinkId
                                }
                            />
                        }
                        percentComplete={
                            isComplete ? 1 : props.task.percentComplete || 0
                        }
                        styles={progressIndicatorStyles}
                    />
                )}
            </div>

            {isStatusCalloutVisible && (
                <TaskStatusEditCallout
                    target={`#${statusLinkId}`}
                    onDismiss={toggleIsStatusCalloutVisible}
                    task={props.task}
                    onChange={(taskUpdates) => {
                        if (props.onChange) {
                            props.onChange({ ...props.task, ...taskUpdates });
                        }
                        toggleIsStatusCalloutVisible();
                    }}
                />
            )}

            {isPercentageCalloutVisible && (
                <TaskPercentageEditCallout
                    target={`#${percentageCompleteLinkId}`}
                    onDismiss={toggleIsPercentageCalloutVisible}
                    task={props.task}
                    onChange={(taskUpdates) => {
                        if (props.onChange) {
                            props.onChange({ ...props.task, ...taskUpdates });
                        }
                        toggleIsPercentageCalloutVisible();
                    }}
                />
            )}
        </Shimmer>
    );
}

export default TaskProgressIndicator;

function TaskProgressIndicatorText(
    props: TaskProgressIndicatorProps & {
        statusLinkId: string;
        percentageCompleteLinkId: string;
        onStatusClick: () => void;
        onPercentageClick: () => void;
    }
): JSX.Element {
    const formatters = useFormatters();
    const status = useTaskStatus(props.task);

    const isComplete =
        status?.name === TaskStatusNames.CompletedOnTime ||
        status?.name === TaskStatusNames.CompletedLate;

    const statusDisplayText = isComplete ? 'Complete' : status?.text;

    const formattedPercentage = formatters.formatTaskPercentage(
        props.task.percentComplete ?? 0
    );

    return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
            {props.showStatus && (
                <Stack.Item grow>
                    {props.allowStatusInlineEdit ? (
                        <Link
                            id={props.statusLinkId}
                            onClick={props.onStatusClick}
                        >
                            <Text variant="xSmall">{statusDisplayText}</Text>
                        </Link>
                    ) : (
                        <Text variant="xSmall">{statusDisplayText}</Text>
                    )}
                </Stack.Item>
            )}

            {(!isComplete || !props.showStatus) && !props.hidePercentage && (
                <Stack.Item>
                    {props.allowPercentageInlineEdit ? (
                        <Link
                            id={props.percentageCompleteLinkId}
                            onClick={props.onPercentageClick}
                        >
                            <Text variant="xSmall">{formattedPercentage}</Text>
                        </Link>
                    ) : (
                        <Text variant="xSmall">{formattedPercentage}</Text>
                    )}
                </Stack.Item>
            )}

            {isComplete && props.showStatus && (
                <Stack.Item>
                    {props.allowDoneDateInlineEdit ? (
                        <InlineDatePicker
                            formatDate={(date?: Date | null): string => {
                                return dayjs
                                    .utc(date || undefined)
                                    .tz(dayjs.tz.guess())
                                    .format('DD MMM YYYY');
                            }}
                            fontSize={FontSizes.xSmall}
                            strings={DatePickerStrings}
                            ariaLabel="Select a date"
                            firstDayOfWeek={DayOfWeek.Monday}
                            value={
                                props.task.done
                                    ? dayjs(props.task.done).toDate()
                                    : undefined
                            }
                            minDate={
                                props.task.start
                                    ? dayjs(props.task.start).toDate()
                                    : undefined
                            }
                            onSelectDate={(
                                date: Date | null | undefined
                            ): void => {
                                const dateString = date
                                    ? dayjs(date).format('YYYY-MM-DD')
                                    : null;

                                if (props.onChange) {
                                    props.onChange({
                                        ...props.task,
                                        done: dateString,
                                    });
                                }
                            }}
                        />
                    ) : (
                        <Text variant="xSmall">
                            {dayjs.utc(props.task.done).format('DD MMM YYYY')}
                        </Text>
                    )}
                </Stack.Item>
            )}
        </Stack>
    );
}

function TaskStatusEditCallout(props: {
    task: TaskWithStatus;
    target: Target | undefined;
    onDismiss: () => void;
    onChange: (taskUpdates: {
        utcPostponed: string | null;
        utcCancelled: string | null;
        utcAtRisk: string | null;
    }) => void;
}): JSX.Element {
    return (
        <Callout
            target={props.target}
            onDismiss={props.onDismiss}
            role="dialog"
            isBeakVisible={false}
            directionalHint={DirectionalHint.bottomLeftEdge}
        >
            <Stack tokens={{ padding: 16 }}>
                <TaskStatusDropDown
                    task={props.task}
                    label="Status"
                    onChange={props.onChange}
                    isReadOnly={false}
                />
            </Stack>
        </Callout>
    );
}

function TaskPercentageEditCallout(props: {
    task: { percentComplete: number; done: string | null };
    target: Target | undefined;
    onDismiss: () => void;
    onChange: (taskUpdates: {
        percentComplete: number;
        done: string | null;
    }) => void;
}): JSX.Element {
    const { task, target, onDismiss, onChange } = props;

    const debouncedChange = useMemo(
        () =>
            debounce((_ev, newValue: number) => {
                onChange({
                    done:
                        newValue >= 1 && !task.done
                            ? dayjs.utc().format()
                            : task.done,
                    percentComplete: newValue,
                });
            }, 200),
        [onChange, task]
    );

    return (
        <Callout
            target={target}
            onDismiss={onDismiss}
            role="dialog"
            isBeakVisible={false}
            directionalHint={DirectionalHint.bottomLeftEdge}
        >
            <Stack tokens={{ padding: 16 }}>
                <Slider
                    label="Percentage Complete"
                    max={1}
                    step={0.01}
                    defaultValue={task.percentComplete || 0}
                    valueFormat={(value: number): string =>
                        `${(value * 100).toFixed(0)}%`
                    }
                    onChanged={debouncedChange}
                    showValue={true}
                    styles={{ root: { width: 200 } }}
                />
            </Stack>
        </Callout>
    );
}
