import * as React from 'react';

import { Stack } from '@fluentui/react';
import { BatchList } from './BatchList';
import { BatchDetails } from './BatchDetails';

export function RollbackPivot(): JSX.Element {
    const [selectedBatchId, setSelectedBatchId] = React.useState<string>();

    return (
        <Stack
            tokens={{
                childrenGap: '24',
            }}
            styles={{
                root: { paddingTop: '25px' },
            }}
        >
            {!selectedBatchId && (
                <BatchList
                    onBatchIdSelected={(batchId: string | undefined): void => {
                        setSelectedBatchId(batchId);
                    }}
                />
            )}
            {selectedBatchId && (
                <BatchDetails
                    selectedBatchId={selectedBatchId}
                    setSelectedBatchId={setSelectedBatchId}
                    // onBatchIdSelected={(batchId: string | undefined): void => {
                    //     setSelectedBatchId(batchId);
                    // }}
                />
            )}
        </Stack>
    );
}
