import React from 'react';
import { Guid } from 'guid-typescript';
import {
    TextField,
    Stack,
    ColorPicker,
    IColor,
    Label,
    DialogType,
    MessageBar,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    Dialog,
    MessageBarType,
    Text,
} from '@fluentui/react';
import useForm from '../../../hooks/useForm';
import {
    TaskCategoryQl,
    useDeleteTaskCategoryMutation,
    useSwapTaskCategoryMutation,
    useUpdateTaskCategoryMutation,
} from '../../../data/types';
import { EditPanel } from '../../../components/shared/EditPanel';
import { useStateContext } from '../../../services/contextProvider';
import { DivisionPicker } from './DivisionPicker';
import { TaskCategoryPicker } from '../../../components/inputs';
import { NeutralColors } from '@fluentui/theme';

export type TaskCategoryEditPanelProps = {
    showPanel: boolean;
    category?: TaskCategoryQl | null;
    onCancel: () => void;
    onSave: () => void;
};

export function TaskCategoryEditPanel(
    props: TaskCategoryEditPanelProps
): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [deleteErrorResult, setDeleteErrorResult] = React.useState<{
        message: string | null;
    } | null>();
    const [moveToTaskCategoryId, setMoveToTaskCategoryId] = React.useState<
        string | null
    >();

    const [updateCategory, { loading: isSaving, error: saveError }] =
        useUpdateTaskCategoryMutation({
            onCompleted: props.onSave,
            refetchQueries: ['GetTaskCategories'],
        });

    const [deleteCategory, { error: deleteError }] =
        useDeleteTaskCategoryMutation({
            variables: {
                id: props.category?.id || '',
                tenantId: currentTenantId || '',
                restore: false,
            },
            refetchQueries: ['GetTaskCategories'],
        });

    const [swapCategory] = useSwapTaskCategoryMutation({
        variables: {
            id: props.category?.id || '',
            swapId: moveToTaskCategoryId || null,
            tenantId: currentTenantId || '',
        },
        refetchQueries: ['GetTaskCategories'],
    });

    type formValuesType = {
        name: string;
        colourHex: string;
        divisionIds: string[];
    };

    const formDefaultValues: formValuesType = {
        name: props.category?.name || '',
        colourHex: props.category?.colourHex || 'ffffff',
        divisionIds: props.category?.divisions?.map((d) => d.id || '') || [],
    };

    const formCallback = (values: formValuesType): void => {
        updateCategory({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    id: props.category?.id || Guid.createEmpty().toString(),
                    name: values.name,
                    colourHex: values.colourHex,
                    divisionIds: values.divisionIds,
                    version: props.category?.version || '',
                },
            },
        });
    };

    const formValidate = (
        values: formValuesType
    ): {
        [key: string]: string;
    } => {
        const formErrorMessages = {
            name: '',
            divisionId: '',
        };

        if (!values.name) {
            formErrorMessages.name = 'Enter a name';
        }

        return formErrorMessages;
    };

    const { errors, handleChange, handleSubmit, updateValue, values } = useForm(
        formDefaultValues,
        formCallback,
        formValidate,
        true
    );

    const onDeleteClick = props.category?.id
        ? (): void => {
              setIsConfirmingDelete(true);
              setIsDeleting(false);
              setDeleteErrorResult(null);
          }
        : undefined;

    const deleteAction = async (): Promise<void> => {
        setIsDeleting(true);

        if (props.category?.taskCount) {
            const swapResult = await swapCategory();
            if (!swapResult.data?.taskCategorySwap?.success) {
                setDeleteErrorResult(swapResult.data?.taskCategorySwap || null);
                return;
            }
        }

        const result = await deleteCategory();
        if (!result.data?.taskCategoryDelete?.success) {
            setDeleteErrorResult(result.data?.taskCategoryDelete || null);
        }
        setIsDeleting(false);

        if (props.onCancel) {
            props.onCancel();
        }
    };

    const updateColor = (_ev: React.SyntheticEvent, colorObj: IColor) => {
        updateValue('colourHex', colorObj.hex);
    };

    const onDivisionPickerChange = (
        divisionId: string,
        isSelected: boolean
    ) => {
        const selectedDivisionIds = isSelected
            ? [...values.divisionIds, divisionId]
            : values.divisionIds.filter((is) => is !== divisionId);
        updateValue('divisionIds', selectedDivisionIds);
    };

    return (
        <EditPanel
            activeViewName="TaskCategoryEdit"
            onDismiss={props.onCancel}
            showPanel={props.showPanel}
            headerText={
                props.category?.id ? 'Edit Task Category' : 'New Task Category'
            }
            isSaving={isSaving}
            isValid={true}
            saveErrorMessage={saveError?.message || null}
            onUpdateClick={handleSubmit}
            onDeleteClick={onDeleteClick}
        >
            <Dialog
                hidden={!isConfirmingDelete}
                onDismiss={(): void => setIsConfirmingDelete(false)}
                modalProps={{
                    isBlocking: true,
                }}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Confirm',
                    closeButtonAriaLabel: 'Close',
                    // subText: 'Are you sure you want to delete this task category?',
                }}
            >
                <Stack
                    tokens={{ childrenGap: 12 }}
                    style={{ marginTop: '0', marginBottom: '24px' }}
                >
                    <Text
                        variant="medium"
                        block
                        className="ms-color-gray10"
                        style={{ color: NeutralColors.gray110 }}
                    >
                        Are you sure you want to delete this task category?
                    </Text>

                    {props.category?.taskCount && (
                        <>
                            <Text
                                variant="medium"
                                block
                                className="ms-color-gray10"
                                style={{ color: NeutralColors.gray110 }}
                            >
                                Currently associated with{' '}
                                {props.category?.taskCount} tasks.
                            </Text>

                            <TaskCategoryPicker
                                label="Replace this Category with:"
                                divisionId={null}
                                selectedTaskCategoryId={moveToTaskCategoryId}
                                onChange={function (
                                    taskCategoryId: string | null
                                ): void {
                                    setMoveToTaskCategoryId(taskCategoryId);
                                }}
                            />
                        </>
                    )}
                </Stack>

                {(deleteError || deleteErrorResult) && (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={true}
                    >
                        {deleteError?.message ?? deleteErrorResult?.message}
                    </MessageBar>
                )}

                <DialogFooter>
                    <PrimaryButton
                        text="Delete"
                        disabled={
                            isDeleting ||
                            moveToTaskCategoryId === props.category?.id
                        }
                        onClick={deleteAction}
                    />
                    <DefaultButton
                        text="Cancel"
                        disabled={isDeleting}
                        onClick={(): void => {
                            setIsConfirmingDelete(false);
                        }}
                    />
                </DialogFooter>
            </Dialog>

            {/* <DeleteModal
                isOpen={isConfirmingDelete}
                onDismiss={(): void => setIsConfirmingDelete(false)}
                message={'Are you sure you want to delete this task category?'}
                deleteAction={deleteAction}
                isDeleting={isDeleting}
                error={deleteError || deleteErrorResult}
            /> */}

            <Stack tokens={{ childrenGap: 8 }}>
                <TextField
                    label="Name"
                    required
                    name="name"
                    autoComplete="off"
                    defaultValue={formDefaultValues.name}
                    errorMessage={errors.name}
                    onChange={handleChange}
                />

                <Label>Colour</Label>
                <ColorPicker
                    color={`#${values.colourHex}`}
                    onChange={updateColor}
                    alphaType="none"
                    showPreview={true}
                />

                <DivisionPicker
                    label="Divisions"
                    placeholder="Choose divisions"
                    selectedDivisionIds={values.divisionIds}
                    onChange={onDivisionPickerChange}
                    multiSelect
                />
            </Stack>
        </EditPanel>
    );
}
