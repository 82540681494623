import { useState } from 'react';
import { useGetTaskLazyQuery, useUpdateTaskMutation } from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { useInputMappers } from './useInputMappers';
import { useResourceUpdater } from './useResourceUpdater';

type InlineTaskUpdate = {
    name: string | null;
    percentComplete: number;
    start: string | null;
    due: string | null;
    done: string | null;
    effortWeight: number;
    costWeight: number;
    utcPostponed: string | null;
    utcCancelled: string | null;
    utcAtRisk: string | null;
};

export function useInlineTaskUpdater(missionUserId: string | null): {
    busyTaskIds: string[];
    updateTask: (id: string | null, updatedTask: InlineTaskUpdate) => void;
} {
    const { getTaskInput } = useInputMappers();

    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const [getTaskLazy] = useGetTaskLazyQuery({
        fetchPolicy: 'network-only',
    });

    const [updateTaskMutation] = useUpdateTaskMutation();

    const resourceUpdater = useResourceUpdater(
        currentTenantId || null,
        currentFinancialYearCode || null,
        missionUserId
    );

    // These are tasks that are in the process of being saved
    const [busyTaskIds, setBusyTaskIds] = useState<string[]>([]);

    const updateTask = async (
        id: string | null,
        updatedTask: InlineTaskUpdate
    ) => {
        if (!id || !currentTenantId) {
            return;
        }

        setBusyTaskIds((c) => [...c, id]);

        const getResult = await getTaskLazy({
            variables: {
                tenantId: currentTenantId,
                id: id,
            },
        });

        const resourcedTaskIds =
            getResult.data?.task?.resourcedTasks.map((t) => t.id || '') || [];

        const duplicateTaskIds =
            getResult.data?.task?.subTasks
                .filter((t) => t.isDuplicate)
                .map((t) => t.id || '') || [];

        setBusyTaskIds((c) => [...c, ...resourcedTaskIds, ...duplicateTaskIds]);

        if (getResult.data?.task) {
            const taskForInput = {
                ...getResult.data?.task,
                name: updatedTask.name,
                start: updatedTask.start,
                due: updatedTask.due,
                done: updatedTask.done,
                utcPostponed: updatedTask.utcPostponed,
                utcCancelled: updatedTask.utcCancelled,
                utcAtRisk: updatedTask.utcAtRisk,
                percentComplete: updatedTask.percentComplete,
                effortWeight: updatedTask.effortWeight,
                costWeight: updatedTask.costWeight,
            };

            const input = getTaskInput(taskForInput);

            await updateTaskMutation({
                variables: {
                    tenantId: currentTenantId,
                    input: input,
                },
            });

            await resourceUpdater.updateResourcedTasks(
                taskForInput,
                getResult.data.task.resourcedTasks.map((r) => {
                    return {
                        userId: r.resource?.userId || null,
                        resourceId: r.resource?.id || null,
                        displayName: r.resource?.displayName || null,
                        resourceIsPrimary: r.resourceIsPrimary,
                        requestAgain: false,
                    };
                })
            );
        }

        setBusyTaskIds((c) =>
            c.filter(
                (cid) =>
                    cid != id &&
                    resourcedTaskIds.indexOf(cid) < 0 &&
                    duplicateTaskIds.indexOf(cid) < 0
            )
        );
    };

    return {
        busyTaskIds,
        updateTask,
    };
}
