import {
    GetMeasureQuery,
    GetMissionQuery,
    GetMissionTasksQuery,
    GetTaskQuery,
    Measure,
    MeasureAsOf,
    Mission,
    Task,
    Team,
} from '../data/types';
import {
    ExtractQueryArrayType,
    MeasureSeriesNames,
    MeasureValueHistoryItem,
} from '../data/extendedTypes';
import dayjs from 'dayjs';
import { useCallback } from 'react';

export function useInputMappers(): {
    getDateForInput: (date: string | null) => string | null;
    getTaskInput: (
        task:
            | ExtractQueryArrayType<GetTaskQuery, ['task']>
            | ExtractQueryArrayType<GetMissionTasksQuery, ['tasks']>
            | null
    ) => Task;
    getMissionInput: (
        mission: ExtractQueryArrayType<GetMissionQuery, ['mission']> | null
    ) => Mission;
    getMeasureInput: (
        measure: ExtractQueryArrayType<GetMeasureQuery, ['measure']> | null
    ) => Measure;
    getMeasureAsOfInput: (
        measureId: string | null,
        measureAsOf: MeasureValueHistoryItem | null
    ) => MeasureAsOf;
} {
    const getDateForInput = useCallback(
        (date: string | null) =>
            date ? dayjs(date).format('YYYY-MM-DD') : null,
        []
    );

    return {
        getDateForInput: getDateForInput,
        getMissionInput: useCallback(
            (
                mission: ExtractQueryArrayType<
                    GetMissionQuery,
                    ['mission']
                > | null
            ): Mission => {
                const missionInput = {} as Mission;

                if (mission) {
                    missionInput.id = mission.id;
                    missionInput.title = mission.title;
                    missionInput.owner = mission.owner;
                    missionInput.missionDate = mission.missionDate;
                    missionInput.missionStatement = mission.missionStatement;
                    missionInput.customMissionStatement1Up =
                        mission.customMissionStatement1Up;
                    missionInput.customMissionStatement2Up =
                        mission.customMissionStatement2Up;
                    missionInput.utcInactive = mission.utcInactive;
                    missionInput.freedoms = mission.freedoms;
                    missionInput.constraints = mission.constraints;
                    missionInput.behaviours = mission.behaviours;
                    missionInput.version = mission.version;
                    missionInput.userId = mission.userId;
                    missionInput.teamId = mission.team?.id || null;
                    missionInput.dottedLines = mission.dottedTeams.map((dt) => {
                        return {
                            ...dt,
                            teamId: dt.teamId || '',
                            missionId: mission?.id || '',
                        };
                    });
                    missionInput.leaderOfTeams = mission.leaderOfTeams.map(
                        (lt) => {
                            return {
                                id: lt.id,
                                code: lt.code,
                                name: lt.name,
                                divisionId: lt.division?.id || null,
                                leaderMissionId: mission?.id || null,
                                version: lt.version,
                            } as Team;
                        }
                    );
                }

                return missionInput;
            },
            []
        ),
        getTaskInput: useCallback(
            (
                task:
                    | ExtractQueryArrayType<GetTaskQuery, ['task']>
                    | ExtractQueryArrayType<GetMissionTasksQuery, ['tasks']>
                    | null
            ): Task => {
                // If this task doesn't have a parent - and it's not a resourced task, its a specified, force % from sub tasks.
                const forcePercentageCompleteFromSubTasks =
                    !task?.parentTaskId && task?.missionId !== null;

                return task
                    ? {
                          id: task.id,
                          missionId: task.missionId,
                          name: task.name,
                          description: task.description,
                          percentComplete: task.percentComplete,
                          parentTaskId: task.parentTaskId,
                          resourcedFromTaskId: task.resourcedFromTaskId,
                          start: getDateForInput(task.start),
                          due: getDateForInput(task.due),
                          done: getDateForInput(task.done),
                          review: getDateForInput(task.review),
                          utcAccepted: task.utcAccepted,
                          utcRejected: task.utcRejected,
                          rejectedReason: task.rejectedReason,
                          taskCategoryId: task.taskCategoryId,
                          resourceId: task.resourceId,
                          utcResourceRemoved: task.utcResourceRemoved,
                          utcChangesPending: task.utcChangesPending,
                          utcCancelled: !task.done ? task.utcCancelled : null, // If it's done, it isn't cancelled
                          utcPostponed: !task.done ? task.utcPostponed : null, // If it's done, it isn't postponed
                          utcAtRisk: !task.done ? task.utcAtRisk : null, // If it's done, it isn't at risk
                          isPercentageCompleteFromResources:
                              task.isPercentageCompleteFromResources,
                          isPercentageCompleteFromSubTasks:
                              forcePercentageCompleteFromSubTasks
                                  ? true
                                  : task.isPercentageCompleteFromSubTasks,
                          sequence: task.sequence,
                          resource: task.resource
                              ? {
                                    id: task.resource.id,
                                    userId: task.resource.userId,
                                    name: task.resource.displayName,
                                    version: task.resource.version,
                                }
                              : null,
                          linkedMeasures: (task.linkedMeasures || []).map(
                              (lm) => {
                                  return {
                                      ...lm,
                                      taskId: lm.taskId || null,
                                  };
                              }
                          ),
                          isDuplicate: task.isDuplicate,
                          resourceIsPrimary: task.resourceIsPrimary,
                          effortWeight: task.effortWeight,
                          effortResourceWeight: task.effortResourceWeight,
                          costWeight: task.costWeight,
                          version: task.version,
                      }
                    : ({} as Task);
            },
            [getDateForInput]
        ),
        getMeasureInput: useCallback(
            (
                measure: ExtractQueryArrayType<
                    GetMeasureQuery,
                    ['measure']
                > | null
            ): Measure => {
                return measure
                    ? {
                          id: measure.id,
                          missionId: measure.missionId,
                          measureGroupId: measure.measureGroupId,
                          name: measure.name,
                          shortName: measure.shortName,
                          description: measure.description,
                          measureType: measure.measureType,
                          phaseType: measure.phaseType,
                          currency: measure.currency
                              ? {
                                    code: measure.currency?.code,
                                    descr: measure.currency?.descr,
                                    symbol: measure.currency?.symbol,
                                }
                              : null,
                          multiplier: measure.multiplier,
                          decimalPlaces: measure.decimalPlaces,
                          statusType: measure.statusType,
                          yellowStart: measure.yellowStart,
                          greenRange: measure.greenRange,
                          yellowRange: measure.yellowRange,
                          isStatusLimited: measure.isStatusLimited,
                          frequencyNumber: measure.frequencyNumber,
                          frequencyPeriod: measure.frequencyPeriod,
                          isLinked: measure.isLinked,
                          linkedFromMeasureId: measure.linkedFromMeasureId,
                          tags: measure.tags,
                          sequence: measure.sequence,
                          version: measure.version,

                          previousFYMeasureId: measure.previousFYMeasureId,
                          fullYearTarget: measure.fullYearTarget,
                          fullYearString: measure.fullYearString,
                          isFullYearTarget: measure.isFullYearTarget,
                          chartDisplay: measure.chartDisplay,
                          chartType: measure.chartType,
                          showForecast: measure.showForecast,
                          showFutureLook: measure.showFutureLook,
                          isCustom: measure.isCustom,
                          targetType: measure.targetType,
                          valueType: measure.valueType,
                          valueFormula: measure.valueFormula,
                      }
                    : ({} as Measure);
            },
            []
        ),
        getMeasureAsOfInput: useCallback(
            (
                measureId: string | null,
                measureAsOf: MeasureValueHistoryItem | null
            ): MeasureAsOf => {
                return measureAsOf
                    ? {
                          id: measureAsOf.id,
                          asOfDate: measureAsOf.asOfDate,
                          measureId: measureId,
                          values: (measureAsOf.values || []).map((value) => {
                              return {
                                  id: value.id,
                                  decimalValue: value.decimalValue,
                                  stringValue: value.stringValue,
                                  dateValue: value.dateValue,
                                  calcId: value.calcId,
                                  seriesType: {
                                      id: null,
                                      name:
                                          value.seriesType?.name ||
                                          MeasureSeriesNames.Target,
                                      calcSymbol: null,
                                      defaultFormat: '',
                                      sequence: null,
                                  },
                                  version: value.version,
                              };
                          }),
                          version: measureAsOf.version,
                      }
                    : ({} as MeasureAsOf);
            },
            []
        ),
    };
}
