import * as React from 'react';
import {
    Checkbox,
    Dropdown,
    IRawStyle,
    MessageBar,
    MessageBarType,
    Text,
    mergeStyleSets,
} from '@fluentui/react';
import { SetupPageContainer } from './components/SetupPageContainer';
import { useStateContext } from '../../services/contextProvider';
import { useGetTenantsQuery } from '../../data/types';
import { useState } from 'react';
import {
    PeriodDropDown,
    PeriodDropDownPeriods,
} from '../../components/PeriodDropDown';
import { AdvanceCard } from '../../components/AdvanceCard';
import dayjs from 'dayjs';
import { MissionOwnersUsage } from './components/MissionOwnersUsage';
import { MeasureUsage } from './components/MeasureUsage';

export function UsageOverview(): JSX.Element {
    const { currentRoles } = useStateContext();

    const isAccessDenied = !currentRoles.some((r) =>
        ['GlobalAdmin', 'Developer'].includes(r)
    );

    const { data: tenantData } = useGetTenantsQuery({
        skip: isAccessDenied,
    });

    const [usagePeriod, setUsagePeriod] = useState<{
        start: string;
        end: string;
    }>({
        start: dayjs.utc().startOf('month').format('YYYY-MM-DD'),
        end: dayjs.utc().endOf('month').format('YYYY-MM-DD'),
    });

    const [includeGps, setIncludeGps] = useState(false);

    const [includeAnalyticsDisabled, setIncludeAnalyticsDisabled] =
        useState(false);

    const [selectedPeriod, setSelectedPeriod] =
        useState<PeriodDropDownPeriods>('ThisMonth');

    const [groupBy, setGroupBy] = useState<'ByTenant' | 'ByDivision'>(
        'ByTenant'
    );

    const excludedTenantsSearchStrings = ['demo', 'gfs', 'sandbox', 'test'];

    if (!includeGps) {
        excludedTenantsSearchStrings.push('gps');
    }

    const tenants = tenantData?.tenants
        ?.filter((t) => t.isEnabled)
        ?.filter((t) => includeAnalyticsDisabled || t.isAnalyticsEnabled)
        .filter(
            (t) =>
                !excludedTenantsSearchStrings.some(
                    (s) =>
                        (t.description &&
                            t.description.toLowerCase().indexOf(s) > -1) ||
                        (t.code && t.code.toLowerCase().indexOf(s) > -1)
                )
        )
        .sort((t1, t2) =>
            (t1.description || '').localeCompare(t2.description || '')
        );

    if (isAccessDenied) {
        return (
            <MessageBar messageBarType={MessageBarType.error}>
                You do not have access to this page.
            </MessageBar>
        );
    }

    const classNames = mergeStyleSets({
        filters: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            alignItems: 'center',
        } as IRawStyle,
    });

    return (
        <SetupPageContainer>
            <Text block variant="xLarge">
                Usage Overview
            </Text>

            <div className={classNames.filters}>
                <PeriodDropDown
                    selectedPeriod={selectedPeriod}
                    onPeriodChanged={function (
                        period: PeriodDropDownPeriods,
                        _periodDisplayName: string,
                        startDate: string,
                        endDate: string
                    ): void {
                        setSelectedPeriod(period);
                        setUsagePeriod({
                            start: startDate,
                            end: endDate,
                        });
                    }}
                    allowedPeriods={[
                        'ThisMonth',
                        'LastMonth',
                        'MonthBeforeLast',
                    ]}
                />

                <div>
                    <Dropdown
                        selectedKey={groupBy}
                        options={[
                            {
                                key: 'ByTenant',
                                text: 'By Tenant',
                            },
                            {
                                key: 'ByDivision',
                                text: 'By Division',
                            },
                        ]}
                        onChange={(_ev, option) => {
                            const selected = option?.key
                                ? (option.key as 'ByDivision' | 'ByTenant')
                                : null;

                            if (selected) {
                                setGroupBy(selected);
                            }
                        }}
                    />
                </div>

                <Checkbox
                    checked={includeGps}
                    onChange={(_ev, checked) => setIncludeGps(!!checked)}
                    label="Include GP Tenant & Users"
                />

                <Checkbox
                    checked={includeAnalyticsDisabled}
                    onChange={(_ev, checked) =>
                        setIncludeAnalyticsDisabled(!!checked)
                    }
                    label="Show Hidden"
                />
            </div>

            {!!tenants?.length && (
                <AdvanceCard>
                    <MissionOwnersUsage
                        tenants={tenants}
                        usagePeriodStart={usagePeriod?.start}
                        usagePeriodEnd={usagePeriod?.end}
                        groupBy={groupBy}
                    />
                </AdvanceCard>
            )}

            {!!tenants?.length && (
                <AdvanceCard>
                    <MeasureUsage
                        tenants={tenants}
                        usagePeriodStart={usagePeriod?.start}
                        usagePeriodEnd={usagePeriod?.end}
                        groupBy={groupBy}
                    />
                </AdvanceCard>
            )}
        </SetupPageContainer>
    );
}
