import React, { useCallback } from 'react';
import { useGetRightsCheckLazyQuery } from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { useTranslation } from 'react-i18next';

export function useComments(): {
    renderCommentText: (commentText: string | null) => (string | JSX.Element)[];
    commentWarning: (
        commentText: string | null,
        missionId: string | null
    ) => Promise<string | null>;
} {
    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const { t } = useTranslation();

    const [getUserRightsCheck] = useGetRightsCheckLazyQuery();

    const renderCommentText = useCallback(
        (commentText: string | null): (string | JSX.Element)[] => {
            const nodes = [];
            const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
            let lastIndex = 0;
            let match;

            if (!commentText) {
                return [];
            }

            while ((match = mentionRegex.exec(commentText)) !== null) {
                if (match.index > lastIndex) {
                    nodes.push(commentText.slice(lastIndex, match.index));
                }
                nodes.push(
                    <strong key={match[2]} className="mention">
                        {match[1]}
                    </strong>
                );
                lastIndex = mentionRegex.lastIndex;
            }

            if (lastIndex < commentText.length) {
                nodes.push(commentText.slice(lastIndex));
            }

            return nodes;
        },
        []
    );

    const commentWarning = useCallback(
        async (
            commentText: string | null,
            missionId: string | null
        ): Promise<string | null> => {
            if (!commentText || !currentFinancialYearCode || !missionId) {
                return null;
            }
            const mentions: { id: string; displayName: string }[] = [];

            let match;
            const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
            while ((match = mentionRegex.exec(commentText)) !== null) {
                if (match[2]) {
                    mentions.push({ id: match[2], displayName: match[1] });
                }
            }

            const mentionsWithNoAccess: typeof mentions = [];

            if (mentions.length) {
                for (const mention of mentions) {
                    const rights = await getUserRightsCheck({
                        variables: {
                            userId: mention.id,
                            missionId: missionId,
                            tenantId: currentTenantId || '',
                        },
                    });

                    if (!rights.data?.rightsCheck.read) {
                        mentionsWithNoAccess.push(mention);
                    }
                }
            }

            const listFormatter = new Intl.ListFormat();

            if (mentionsWithNoAccess.length) {
                const userDisplayNameList = listFormatter.format(
                    mentionsWithNoAccess.map((m) => m.displayName)
                );
                return t('comment.mentioned-user-with-no-access', {
                    userDisplayNameList: userDisplayNameList,
                    count: mentionsWithNoAccess.length,
                });
            }

            return null;
        },
        [currentFinancialYearCode, currentTenantId, getUserRightsCheck, t]
    );

    return {
        renderCommentText,
        commentWarning,
    };
}
