import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, SearchBox, Stack } from '@fluentui/react';
import { useThemes } from '../../../hooks/useThemes';
import debounce from 'lodash/debounce';
import { TaskFilterCategorySelector } from './TaskFilterCategorySelector';

export type MissionFilters = {
    keyword: string | null;
    forDateTime: { isOn: boolean; selectedDate: string | null };
    taskCategoryIds: string[];
};

export const DefaultMissionFilters = {
    keyword: null,
    forDateTime: { isOn: false, selectedDate: null },
    taskCategoryIds: [],
};

export function MissionFilterBar(props: {
    filters: MissionFilters;
    onFiltersChanged: (filters: MissionFilters) => void;
    onDismiss?: () => void;
    searchBoxPlaceholder?: string;
    taskCategories?: {
        id: string | null;
        count?: number | null;
        name: string | null;
        colourHex: string | null;
    }[];
}): JSX.Element {
    const { currentTheme } = useThemes();

    const [internalFilters, setInternalFilters] = useState<MissionFilters>(
        props.filters
    );

    const { onFiltersChanged } = props;
    const debouncedChange = useMemo(
        () => debounce((f) => onFiltersChanged(f), 400),
        [onFiltersChanged]
    );

    useEffect(
        () => debouncedChange(internalFilters),
        [internalFilters, debouncedChange]
    );

    const handeKeywordChanged = (keyword: string | null) => {
        setInternalFilters({
            ...internalFilters,
            keyword: keyword,
        });
    };

    const handleCategoryFilterChanged = (categoryIds: string[]) => {
        setInternalFilters({
            ...internalFilters,
            taskCategoryIds: categoryIds,
        });
    };

    const handleClearAndDismissClick = () => {
        props.onFiltersChanged(DefaultMissionFilters);
        if (props.onDismiss) {
            props.onDismiss();
        } else {
            setInternalFilters(DefaultMissionFilters);
        }
    };

    return (
        <Stack
            horizontal
            verticalAlign="center"
            tokens={{
                padding: 4,
                childrenGap: 8,
            }}
            styles={{
                root: {
                    paddingLeft: 8,
                    paddingRight: 8,
                    backgroundColor: currentTheme.semanticColors.infoBackground,
                },
            }}
        >
            <Stack.Item verticalFill align="center" grow>
                <MissionFilterSearchBox
                    keywordValue={internalFilters.keyword}
                    onKeywordChanged={handeKeywordChanged}
                    onSearch={() => debouncedChange.flush()}
                    placeholder={props.searchBoxPlaceholder}
                />
            </Stack.Item>

            {props.taskCategories && (
                <Stack.Item>
                    <TaskFilterCategorySelector
                        taskCategories={props.taskCategories}
                        selectedCategoryIds={internalFilters.taskCategoryIds}
                        onCategoryFilterChanged={handleCategoryFilterChanged}
                    />
                </Stack.Item>
            )}

            <Stack.Item verticalFill align="center">
                <IconButton
                    iconProps={{ iconName: 'Cancel' }}
                    title="Clear and dismiss filters"
                    onClick={handleClearAndDismissClick}
                />
            </Stack.Item>
        </Stack>
    );
}

export function MissionFilterSearchBox(props: {
    keywordValue: string | null;
    onKeywordChanged: (keyword: string | null) => void;
    onSearch: () => void;
    placeholder?: string;
}): JSX.Element {
    const handleKeywordChange = (
        _event?: React.ChangeEvent,
        newValue?: string
    ) => props.onKeywordChanged(newValue || null);

    return (
        <SearchBox
            placeholder={
                props.placeholder !== undefined
                    ? props.placeholder
                    : 'Filter by keyword'
            }
            showIcon
            iconProps={{ iconName: 'Filter' }}
            onChange={handleKeywordChange}
            onSearch={props.onSearch}
            value={props.keywordValue || ''}
            styles={{
                clearButton: {
                    display: 'none',
                },
                root: {
                    backgroundColor: 'transparent',
                    border: 'none',
                    selectors: {
                        ':after': {
                            border: 'none',
                        },
                    },
                },
            }}
        />
    );
}
