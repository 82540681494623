import * as React from 'react';
import PropTypes from 'prop-types';

import { useGetTeamsQuery, TeamQl } from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import {
    ShimmeredDetailsList,
    IconButton,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    Stack,
} from '@fluentui/react';
import { useSorter } from '../../../hooks/useSorter';
import { DivisionPicker } from './DivisionPicker';

type TeamListProps = {
    fyCodeFilter: string | null;
    onItemEdit: (team: TeamQl) => void;
    onAccessEdit: (team: TeamQl) => void;
};

export function TeamList(props: TeamListProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [selectedDivisionId, setSelectedDivisionId] =
        React.useState<string>();

    const { data, loading } = useGetTeamsQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            divisionId: selectedDivisionId || null,
            financialYearCode: props.fyCodeFilter,
        },
    });

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(data?.teams, 'code', false, true);

    const handleSelectedDivisionIdsChange = (
        divisionId: string,
        isSelected: boolean
    ) => {
        if (divisionId && isSelected) {
            setSelectedDivisionId(divisionId);
        }
    };

    const columns: IColumn[] = [
        {
            key: 'financialYear',
            name: 'FY',
            fieldName: 'financialYear.code',
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: function renderFinancialYear(item: TeamQl): JSX.Element {
                return <span>{item?.division?.financialYear?.code}</span>;
            },
            isSorted: sortColumn === 'division.financialYear.code',
            isSortedDescending:
                sortColumn === 'division.financialYear.code' && sortIsDesc,
            onColumnClick: (): void =>
                setSortColumnName('division.financialYear.code', false),
        },
        {
            key: 'division',
            name: 'Division',
            fieldName: 'division.name',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: function renderFinancialYear(item: TeamQl): JSX.Element {
                return <span>{item?.division?.name}</span>;
            },
            isSorted: sortColumn === 'division.name',
            isSortedDescending: sortColumn === 'division.name' && sortIsDesc,
            onColumnClick: (): void =>
                setSortColumnName('division.name', false),
        },
        {
            key: 'code',
            name: 'Code',
            fieldName: 'code',
            isRowHeader: true,
            isResizable: true,
            minWidth: 100,
            maxWidth: 200,
            isSorted: sortColumn === 'code',
            isSortedDescending: sortColumn === 'code' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('code', true),
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            isResizable: true,
            minWidth: 100,
            isSorted: sortColumn === 'name',
            isSortedDescending: sortColumn === 'name' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('name', true),
        },
        {
            key: 'leaderMission',
            name: 'Leader Mission',
            fieldName: 'leaderMission.owner',
            isResizable: true,
            minWidth: 300,
            maxWidth: 500,
            isSorted: sortColumn === 'leaderMission.owner',
            isSortedDescending:
                sortColumn === 'leaderMission.owner' && sortIsDesc,
            onColumnClick: (): void =>
                setSortColumnName('leaderMission.owner', false),
            onRender: function renderLeaderMissionName(
                item: TeamQl
            ): JSX.Element {
                if (item?.leaderMission) {
                    return (
                        <span>
                            {`${item?.leaderMission?.owner} - ${item?.leaderMission?.title}`}
                        </span>
                    );
                } else {
                    return <span />;
                }
            },
        },
        {
            key: 'actions',
            name: '',
            minWidth: 64,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderActions(item: TeamQl): JSX.Element {
                return (
                    <>
                        <IconButton
                            key={`access-${item.id}`}
                            iconProps={{ iconName: 'Lock' }}
                            onClick={(): void => {
                                if (props.onAccessEdit) {
                                    props.onAccessEdit(item);
                                }
                            }}
                        />
                        <IconButton
                            key={`edit-${item.id}`}
                            iconProps={{ iconName: 'Edit' }}
                            onClick={(): void => {
                                props.onItemEdit(item);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <Stack>
            <Stack.Item>
                <div style={{ maxWidth: 300, marginLeft: 8, marginRight: 8 }}>
                    <DivisionPicker
                        placeholder="Filter by division"
                        selectedDivisionId={selectedDivisionId}
                        onChange={handleSelectedDivisionIdsChange}
                        fyCodeFilter={props.fyCodeFilter}
                    />
                </div>
            </Stack.Item>
            <ShimmeredDetailsList
                setKey="items"
                items={sortedItems}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={loading}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details"
                listProps={{
                    renderedWindowsAhead: 0,
                    renderedWindowsBehind: 0,
                }}
                onItemInvoked={(item?: TeamQl): void => {
                    if (item) {
                        props.onItemEdit(item);
                    }
                }}
            />
        </Stack>
    );
}

TeamList.propTypes = {
    onItemEdit: PropTypes.func.isRequired,
};
