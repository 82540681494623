import React from 'react';
import {
    PersonaSize,
    Persona,
    Icon,
    mergeStyleSets,
    TooltipHost,
} from '@fluentui/react';
import { Access } from '../data/extendedTypes';
import { photoService } from '../services/photo.service';
import WarningButton from './WarningButton';
import { WarningTask, useTaskWarning } from '../hooks/useTaskWarning';
import {
    ResourcedFromTooltipContent,
    ResourcedFromTooltipContentTask,
} from './ResourcedFromTooltipContent';
import { useThemes } from '../hooks/useThemes';

export function ResourcedFromCoin(props: {
    task: WarningTask & {
        resourcedFromTask: {
            mission: {
                rights: {
                    read: boolean;
                };
            } | null;
        } | null;
    };
    missionAccess?: Access | null;
    onTaskEditClick?: (() => void) | null;
}): JSX.Element | null {
    const { task } = props;
    const resourcedFromTask = task.resourcedFromTask;

    const taskWarning = useTaskWarning(task, {
        onOpenEditTask: props.onTaskEditClick || undefined,
        isEditing: false,
    });

    const warningButton =
        taskWarning.hasWarning && props.missionAccess?.write ? (
            <WarningButton
                warning={taskWarning}
                showActions={true}
                noOptionsAction={
                    props.onTaskEditClick && props.missionAccess.write
                        ? {
                              actionName: 'Edit Task',
                              executeAction: props.onTaskEditClick,
                          }
                        : undefined
                }
            />
        ) : null;

    if (resourcedFromTask?.mission || task.utcResourceRemoved) {
        return (
            <ResourcedFromCoinContent
                task={task}
                warningButton={warningButton}
            />
        );
    } else {
        return warningButton;
    }
}

export function ContributorResourcedFromCoin(props: {
    task: ResourcedFromTooltipContentTask;
}): JSX.Element | null {
    if (!props.task.resourcedFromTask) {
        return null;
    }

    return <ResourcedFromCoinContent {...props} />;
}

function ResourcedFromCoinContent(props: {
    task: ResourcedFromTooltipContentTask;
    warningButton?: JSX.Element | null | undefined;
}): JSX.Element {
    const { task, warningButton } = props;
    const resourcedFromTask = task.resourcedFromTask;

    const imageUrl = photoService.getImageUrl(
        resourcedFromTask?.mission?.userId
    );

    const { currentTheme } = useThemes();

    const styles = mergeStyleSets({
        container: {
            position: 'relative',
            width: 42,
            height: 24,
        },
        overlapItem: {
            position: 'absolute',
            top: 0,
            left: 0,
        },
        persona: {
            marginLeft: 0,
        },
        arrows: {
            marginTop: 0,
            marginLeft: 24,
        },
        warning: {
            marginTop: 12,
            marginLeft: 12,
        },
    });

    const toolTipContent = <ResourcedFromTooltipContent task={task} />;

    return (
        <TooltipHost content={toolTipContent ?? undefined}>
            <div className={styles.container}>
                <div className={`${styles.overlapItem} ${styles.arrows}`}>
                    <Icon
                        iconName={
                            task.utcResourceRemoved
                                ? 'RemoveLink'
                                : 'SkypeArrow'
                        }
                        styles={{
                            root: {
                                transform: task.utcResourceRemoved
                                    ? undefined
                                    : 'rotateY(180deg)',
                                color: currentTheme.palette.themePrimary,
                            },
                        }}
                    />
                </div>
                <div className={`${styles.overlapItem} ${styles.persona}`}>
                    <Persona
                        showUnknownPersonaCoin={!resourcedFromTask?.mission}
                        imageUrl={imageUrl}
                        text={resourcedFromTask?.mission?.owner || undefined}
                        size={PersonaSize.size24}
                        hidePersonaDetails
                        coinProps={{
                            styles: {
                                coin: {
                                    borderRadius: '50%',
                                    border: `solid 2px ${currentTheme.palette.white}`,
                                },
                            },
                        }}
                    />
                </div>
                <div className={`${styles.overlapItem} ${styles.warning}`}>
                    {warningButton}
                </div>
            </div>
        </TooltipHost>
    );
}
