import React, { useEffect, useState } from 'react';
import {
    mergeStyleSets,
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    ChoiceGroup,
    IChoiceGroupOption,
    FontSizes,
    IChoiceGroupOptionStyles,
} from '@fluentui/react';
import { FrequencyPeriods } from '../../../data/types';

export function MeasureUpgradeDialog(props: {
    measure:
        | {
              id: string | null;
              frequencyPeriod: FrequencyPeriods;
              isCustom: boolean;
          }
        | undefined;
    fyStartDate: string | undefined;
    hideDialog: boolean;
    toggleShowDialog: () => void;
    onNext: (selectedFrequency: FrequencyPeriods) => void;
}) {
    const [selectedFrequency, setSelectedFrequency] =
        useState<FrequencyPeriods>();

    const isMeasureV1 = props.measure?.isCustom;
    const isMeasureV2 = !isMeasureV1;

    const classNames = mergeStyleSets({
        choiceGroup: { marginTop: '12px' },
        titleCase: { textTransform: 'capitalize' },
    });

    const choiceOptionStyle: Partial<IChoiceGroupOptionStyles> = {
        labelWrapper: {
            fontSize: FontSizes.small,
            overflow: 'visible',
        },
    };

    useEffect(() => {
        setSelectedFrequency(undefined);
    }, [props.measure?.id]);

    return (
        <Dialog
            hidden={props.hideDialog}
            onDismiss={props.toggleShowDialog}
            minWidth="50vw"
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: isMeasureV2 ? 'Convert Frequency' : 'Upgrade Measure',
                closeButtonAriaLabel: 'Close',
            }}
            modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: 450 } },
            }}
        >
            {isMeasureV1 && (
                <div>
                    Take advantage of new features such as Monthly/Quarterly
                    Reporting.
                    <br />
                    <br />
                </div>
            )}

            <div>
                Current Frequency:{' '}
                <span className={classNames.titleCase}>
                    {props.measure?.frequencyPeriod.toLowerCase()}
                </span>
            </div>

            <ChoiceGroup
                label="New Time Interval"
                className={classNames.choiceGroup}
                options={[
                    {
                        key: FrequencyPeriods.Month,
                        text: 'Month',

                        iconProps: { iconName: 'Calendar' },
                        styles: choiceOptionStyle,
                        disabled:
                            isMeasureV2 &&
                            props.measure?.frequencyPeriod ===
                                FrequencyPeriods.Month,
                    },
                    {
                        key: FrequencyPeriods.Quarter,
                        text: 'Quarter',
                        iconProps: { iconName: 'CalendarWeek' },
                        styles: choiceOptionStyle,
                        disabled:
                            isMeasureV2 &&
                            props.measure?.frequencyPeriod ===
                                FrequencyPeriods.Quarter,
                    },
                    {
                        key: FrequencyPeriods.Year,
                        text: 'Year',
                        iconProps: { iconName: 'PublicCalendar' },
                        styles: choiceOptionStyle,
                        disabled:
                            isMeasureV2 &&
                            props.measure?.frequencyPeriod ===
                                FrequencyPeriods.Year,
                    },
                    {
                        key: FrequencyPeriods.None,
                        text: 'Intermittent',
                        iconProps: { iconName: 'CustomizeToolbar' },
                        styles: choiceOptionStyle,
                        disabled:
                            isMeasureV2 &&
                            props.measure?.frequencyPeriod ===
                                FrequencyPeriods.None,
                    },
                ]}
                selectedKey={selectedFrequency}
                onChange={(
                    _ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
                    option?: IChoiceGroupOption
                ): void => {
                    setSelectedFrequency(option?.key as FrequencyPeriods);
                }}
            />

            {/* <p>
                We recommend using the standard layout with an inline icon for
                labels longer than a single short word.
            </p> */}

            {/* <p>New Measure Frequency: {selectedFrequency}</p> */}

            <DialogFooter>
                <PrimaryButton
                    onClick={() => {
                        if (!selectedFrequency) return;
                        props.onNext(selectedFrequency);
                    }}
                    text="Next"
                    disabled={!selectedFrequency}
                />
                <DefaultButton onClick={props.toggleShowDialog} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
}
