import { useCallback, useState } from 'react';
import { Guid } from 'guid-typescript';
import { useApolloClient } from '@apollo/client';
import {
    GetTaskDocument,
    GetTaskQuery,
    GetTaskQueryVariables,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { sorters } from '../data/sorters';

export type TaskHierachyTaskType = NonNullable<GetTaskQuery['task']>;

export type TaskHierarchyType = {
    hierarchyId: Guid;
    parentHierarchyId: Guid | null;
    task: TaskHierachyTaskType;
    level: number;
    expanded: boolean;
    hasChildren: boolean;
};

export function useTaskHierachy(): {
    taskHierarchy: TaskHierarchyType[] | null;
    loadHierarchyAsync: (taskId: string) => void;
    expandHierarchyItemAsync: (taskId: Guid) => void;
    collapseHierarchyItemAsync: (taskId: Guid) => void;
    refreshTasksAsync: (taskIds: string[]) => void;
    reset: () => void;
} {
    const client = useApolloClient();
    const { currentTenantId } = useStateContext();

    const [taskHierarchy, setTaskHierarchy] = useState<
        TaskHierarchyType[] | null
    >(null);

    const loadTaskAsync = useCallback(
        async (taskId: string): Promise<TaskHierachyTaskType | null> => {
            const cacheResult = await client.readQuery<
                GetTaskQuery,
                GetTaskQueryVariables
            >({
                query: GetTaskDocument,
                variables: {
                    tenantId: currentTenantId || '',
                    id: taskId,
                },
            });

            if (cacheResult?.task) {
                return cacheResult.task;
            }

            const serverResult = await client.query<
                GetTaskQuery,
                GetTaskQueryVariables
            >({
                query: GetTaskDocument,
                variables: {
                    tenantId: currentTenantId || '',
                    id: taskId,
                },
            });

            return serverResult.data.task || null;
        },
        [client, currentTenantId]
    );

    const loadTasksAsync = useCallback(
        async (taskIds: string[]): Promise<(TaskHierachyTaskType | null)[]> => {
            const functions = taskIds.map(loadTaskAsync);
            const results = await Promise.all(functions);
            return results;
        },
        [loadTaskAsync]
    );

    const getTaskHierarchyAsync = useCallback(
        async (
            task: TaskHierachyTaskType,
            parentHierarchyId: Guid | null,
            level: number,
            expand?: boolean
        ): Promise<TaskHierarchyType[]> => {
            const hierarchyId = Guid.create();

            if (level > 15) {
                console.error('Too much recusion for the task', task);
                return [];
            }

            const hierarchy = [
                {
                    hierarchyId: hierarchyId,
                    parentHierarchyId: parentHierarchyId,
                    task: task,
                    level: level,
                    expanded: !!expand,
                    hasChildren:
                        task.resourcedTasks.length > 0 ||
                        task.subTasks.length > 0,
                },
            ];

            if (!expand) {
                return hierarchy;
            }

            const missionIsActive =
                task.mission != null &&
                !task.mission.utcDeleted &&
                !task.mission.utcInactive;

            const nextLevelTasks = await loadTasksAsync([
                ...task.subTasks.slice().map((st) => st.id || ''),
                ...(missionIsActive ? task.resourcedTasks : [])
                    .slice()
                    .filter((rt) => !rt.utcResourceRemoved)
                    .filter((t) => t.mission === null || t.mission?.rights.read)
                    .map((st) => st.id || ''),
            ]);

            for (const st of task.subTasks
                .slice()
                .sort((r1, r2) =>
                    r1.isDuplicate === r2.isDuplicate
                        ? sorters.sequenceSorter(r1, r2)
                        : r1.isDuplicate
                          ? -1
                          : 1
                )) {
                const subTask = nextLevelTasks.find((f) => f?.id === st.id);

                if (subTask) {
                    const subTaskHierarchy = await getTaskHierarchyAsync(
                        subTask,
                        hierarchyId,
                        level + 1
                    );

                    hierarchy.push(...subTaskHierarchy);
                }
            }

            if (missionIsActive) {
                for (const rt of task.resourcedTasks
                    .slice()
                    .sort((r1, r2) =>
                        r1.resourceIsPrimary === r2.resourceIsPrimary
                            ? 0
                            : r1.resourceIsPrimary
                              ? -1
                              : 1
                    )) {
                    if (rt.mission !== null && !rt.mission?.rights.read) {
                        continue;
                    }
                    let resourcedTask = nextLevelTasks.find(
                        (f) => f?.id === rt.id
                    );

                    // If its a duplicate, load the task its a duplicate of.
                    if (resourcedTask?.isDuplicate && rt.parentTaskId) {
                        resourcedTask = await loadTaskAsync(rt.parentTaskId);
                    }

                    if (resourcedTask) {
                        const resourcedTaskHierarchy =
                            await getTaskHierarchyAsync(
                                resourcedTask,
                                hierarchyId,
                                level + 1
                            );

                        hierarchy.push(...resourcedTaskHierarchy);
                    }
                }
            }

            return hierarchy;
        },
        [loadTaskAsync, loadTasksAsync]
    );

    const loadHierarchyAsync = useCallback(
        async (taskId: string) => {
            const task = await loadTaskAsync(taskId);

            let resourcedFromTask: TaskHierachyTaskType | null = null;

            if (
                task?.resourcedFromTask?.id &&
                task?.resourcedFromTask?.mission?.rights.read &&
                !task?.resourcedFromTask?.utcDeleted
            ) {
                resourcedFromTask = await loadTaskAsync(
                    task?.resourcedFromTask?.id
                );
            }

            const rootTask = resourcedFromTask ?? task;

            if (rootTask) {
                const hierarchy = await getTaskHierarchyAsync(
                    rootTask,
                    null,
                    1,
                    true
                );
                setTaskHierarchy(hierarchy);
            } else {
                setTaskHierarchy([]);
            }
        },
        [getTaskHierarchyAsync, loadTaskAsync]
    );

    const expandHierarchyItemAsync = async (hid: Guid) => {
        if (!taskHierarchy) {
            return;
        }

        const index = taskHierarchy.findIndex((h) => h.hierarchyId === hid);

        if (index > -1) {
            const expandedTask = await getTaskHierarchyAsync(
                taskHierarchy[index as number].task,
                taskHierarchy[index as number].parentHierarchyId,
                taskHierarchy[index as number].level,
                true
            );

            const expandedHierarchy = [
                ...taskHierarchy.slice(0, index),
                ...expandedTask,
                ...taskHierarchy.slice(index + 1),
            ];

            setTaskHierarchy(expandedHierarchy);
        }
    };

    const getHierarchyChildren = (rootHierachyId: Guid): Guid[] => {
        if (!taskHierarchy) {
            return [];
        }
        const children = taskHierarchy
            .filter((t) => t.parentHierarchyId === rootHierachyId)
            .flatMap((ch) => {
                return getHierarchyChildren(ch.hierarchyId);
            });

        return [rootHierachyId, ...children];
    };

    const collapseHierarchyItemAsync = (hid: Guid) => {
        if (!taskHierarchy) {
            return;
        }
        const allChildredToCollapse = getHierarchyChildren(hid);
        const newList: TaskHierarchyType[] = [];
        taskHierarchy.forEach((th) => {
            if (allChildredToCollapse.includes(th.hierarchyId)) {
                newList.push({ ...th, expanded: false });
            } else {
                newList.push(th);
            }
        });
        setTaskHierarchy(newList);
    };

    const refreshTasksAsync = async (taskIds: string[]) => {
        if (taskHierarchy) {
            const updatedHierachy: TaskHierarchyType[] = [];
            const updatedTasks = await loadTasksAsync(taskIds);
            taskHierarchy.forEach((h) => {
                const updatedTask = updatedTasks.find(
                    (t) => t?.id === h.task.id
                );

                if (updatedTask) {
                    updatedHierachy.push({
                        ...h,
                        task: updatedTask,
                    });
                } else {
                    updatedHierachy.push(h);
                }
            });

            setTaskHierarchy(updatedHierachy);
        }
    };

    const reset = useCallback(() => setTaskHierarchy(null), []);

    return {
        taskHierarchy,
        loadHierarchyAsync,
        expandHierarchyItemAsync,
        collapseHierarchyItemAsync,
        refreshTasksAsync,
        reset,
    };
}
