import { DefaultPalette } from '@fluentui/theme';
import dayjs from 'dayjs';
import {
    DeepExtractType,
    DeepExtractTypeSkipArrays,
} from 'ts-deep-extract-types';
import { TaskStatusColours } from '../Colours';

import { GetMeasureValueHistoryQuery } from './types';

export type ExtractQueryArrayType<T, Path extends [...string[]]> = Omit<
    DeepExtractTypeSkipArrays<T, Path>,
    '__typename'
>;

export type ExtractQueryType<T, Path extends [...string[]]> = Omit<
    DeepExtractType<T, Path>,
    '__typename'
>;

export enum MeasureSeriesNames {
    Target = 'Target',
    Actual = 'Actual',
    PhasedTarget = 'PhasedTarget',
    PhasedForecast = 'PhasedForecast',
}

export enum TaskStatusNames {
    NotStarted = 'NotStarted',
    OffTarget = 'OffTarget',
    OnTarget = 'OnTarget',
    CompletedLate = 'CompletedLate',
    CompletedOnTime = 'CompletedOnTime',
    Rejected = 'Rejected',
    Pending = 'Pending',
    Postponed = 'Postponed',
    Cancelled = 'Cancelled',
    AtRisk = 'AtRisk',
}

export type TaskWithStatus = {
    utcAccepted?: string | null;
    utcRejected?: string | null;
    start: string | null;
    due: string | null;
    done: string | null;
    resourcedFromTask: {
        mission: {
            userId: string | null;
        } | null;
    } | null;
    resource?: {
        userId: string | null;
        userMissionFYs: {
            code: string | null;
        }[];
        userContributorFYs: {
            code: string | null;
        }[];
    } | null;
    utcPostponed: string | null;
    utcCancelled: string | null;
    utcAtRisk: string | null;
};

class TaskStatusUtil {
    public GetTaskStatus(
        task: TaskWithStatus | undefined,
        financialYearCode?: string | undefined,
        statusDate?: string | null
    ): TaskStatusNames | null {
        if (!task) {
            return null;
        }

        let statusDay = statusDate ? dayjs(statusDate) : dayjs().utc();

        statusDay = statusDay
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0);

        const start = dayjs.utc(task.start || undefined);
        const due = dayjs.utc(task.due || undefined);
        const done = dayjs.utc(task.done || undefined);

        if (task.resourcedFromTask && task.resource === undefined) {
            throw 'The resource was not included so resource status cannot be found';
        }

        // Is resourced to a user with a mission
        const isResourcedUser =
            task.resource?.userMissionFYs.find(
                (fy) =>
                    fy.code?.toUpperCase() === financialYearCode?.toUpperCase()
            ) &&
            task.resource?.userId &&
            task.resource?.userId !== task.resourcedFromTask?.mission?.userId;

        if (task.resourcedFromTask && task.utcRejected) {
            return TaskStatusNames.Rejected;
        }

        if (task.resourcedFromTask && isResourcedUser && !task.utcAccepted) {
            return TaskStatusNames.Pending;
        } else if (task.utcPostponed) {
            return TaskStatusNames.Postponed;
        } else if (task.utcCancelled) {
            return TaskStatusNames.Cancelled;
        } else if (task.utcAtRisk) {
            return TaskStatusNames.AtRisk;
        } else if (task.done) {
            return done.isAfter(due, 'day')
                ? TaskStatusNames.CompletedLate
                : TaskStatusNames.CompletedOnTime;
        } else if (!task.start && !task.due) {
            return TaskStatusNames.NotStarted;
        } else if (start.isAfter(statusDay, 'day')) {
            return TaskStatusNames.NotStarted;
        } else if (!task.due) {
            return TaskStatusNames.OnTarget;
        } else if (due.isSameOrBefore(statusDay, 'day')) {
            return TaskStatusNames.OffTarget;
        } else if (due.isAfter(statusDay, 'day')) {
            return TaskStatusNames.OnTarget;
        } else {
            return null;
        }
    }

    public GetStatusColour(status: TaskStatusNames | null): string {
        switch (status) {
            case TaskStatusNames.OffTarget: {
                return TaskStatusColours.offTarget;
            }
            case TaskStatusNames.NotStarted: {
                return TaskStatusColours.notStarted;
            }
            case TaskStatusNames.CompletedOnTime: {
                return TaskStatusColours.completedOnTime;
            }
            case TaskStatusNames.CompletedLate: {
                return TaskStatusColours.completedLate;
            }
            case TaskStatusNames.OnTarget: {
                return TaskStatusColours.onTarget;
            }
            case TaskStatusNames.Postponed: {
                return TaskStatusColours.postponed;
            }
            case TaskStatusNames.Cancelled: {
                return TaskStatusColours.cancelled;
            }
            case TaskStatusNames.AtRisk: {
                return TaskStatusColours.atRisk;
            }
            default: {
                return DefaultPalette.neutralPrimary;
            }
        }
    }
}

export const taskStatusUtil = new TaskStatusUtil();

export type MeasureValueHistoryItem = ExtractQueryArrayType<
    GetMeasureValueHistoryQuery,
    ['measure', 'valueHistory']
>;

export type Access = {
    read: boolean;
    write: boolean;
    export: boolean;
    import: boolean;
};
