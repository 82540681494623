import React from 'react';
import {
    IProgressIndicatorStyles,
    mergeStyleSets,
    ProgressIndicator,
    Text,
} from '@fluentui/react';
import { GetTasksForStatusReportQuery } from '../../../data/types';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import { TaskCategoryLabel } from '../../../components/TaskCategoryLabel';
import { useThemes } from '../../../hooks/useThemes';

import dayjs from 'dayjs';

export function StatusReportGridTaskDetail(props: {
    task: ExtractQueryArrayType<GetTasksForStatusReportQuery, ['tasks']> | null;
    allowNavigation: boolean;
    onNavigate: () => void;
}): JSX.Element {
    const { currentTheme } = useThemes();

    const { task } = props;

    const formatter = new Intl.NumberFormat(undefined, {
        style: 'percent',
        maximumFractionDigits: 1,
    });

    const formattedPercentage = formatter.format(task?.percentComplete ?? 0);

    const classNames = mergeStyleSets({
        container: {
            paddingTop: 8,
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
        },
        progress: {
            width: 24,
            padding: 8,
        },
        taskDetails: {
            flexGrow: 1,
            borderWidth: 1,
            borderColor: currentTheme.palette.neutralLight,
            borderStyle: 'solid',
            borderRadius: 4,
            paddingLeft: 8,
            paddingRight: 4,
            paddingTop: 4,
            paddingBottom: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        dates: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
        },
    });

    const progressIndicatorStyles: Partial<IProgressIndicatorStyles> = {
        itemProgress: {
            padding: 0,
        },
        itemDescription: {
            fontSize: 10,
            textAlign: 'center',
            color: currentTheme.palette.neutralPrimary,
        },
    };

    return (
        <div className={classNames.container}>
            <div className={classNames.progress}>
                <ProgressIndicator
                    barHeight={4}
                    description={formattedPercentage}
                    percentComplete={
                        task?.done ? 1 : task?.percentComplete || 0
                    }
                    styles={progressIndicatorStyles}
                />
            </div>

            {task && !task.utcDeleted && (
                <div className={classNames.taskDetails}>
                    <TaskCategoryLabel {...task?.taskCategory} />
                    <div className={classNames.dates}>
                        {!!task?.start && (
                            <Text variant="small">
                                Start: {dayjs(task.start).format('DD MMM YYYY')}
                            </Text>
                        )}
                        {!!task?.due && (
                            <Text variant="small">
                                Due: {dayjs(task.due).format('DD MMM YYYY')}
                            </Text>
                        )}
                        {!!task?.done && (
                            <Text variant="small">
                                Done: {dayjs(task.done).format('DD MMM YYYY')}
                            </Text>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
