import React from 'react';
import {
    ActionButton,
    ContextualMenuItemType,
    IButtonProps,
    IContextualMenuItem,
    Persona,
    PersonaSize,
    Stack,
} from '@fluentui/react';
import orderBy from 'lodash/orderBy';
import { photoService } from '../../../services/photo.service';

export function TaskFilterResourceSelector(props: {
    tasks: {
        resourcedTasks:
            | {
                  id: string | null;
                  resource: {
                      id: string | null;
                      userId: string | null;
                      displayName: string | null;
                  } | null;
              }[]
            | null;
    }[];
    selectedResources: {
        userId: string | null;
        resourceId: string | null;
    }[];
    onResourceFilterChanged: (
        resources: {
            userId: string | null;
            resourceId: string | null;
        }[]
    ) => void;
}): JSX.Element | null {
    const { tasks, selectedResources, onResourceFilterChanged } = props;

    const handleResourceFilterClick = (resource: {
        userId: string | null;
        resourceId: string | null;
    }) => {
        let updated: {
            userId: string | null;
            resourceId: string | null;
        }[];

        const alreadySelectedItem = selectedResources?.find(
            (sr) =>
                sr.userId === resource.userId &&
                sr.resourceId === resource.resourceId
        );

        if (alreadySelectedItem) {
            updated = selectedResources.filter(
                (sr) => sr !== alreadySelectedItem
            );
        } else {
            updated = [...(selectedResources || []), resource];
        }
        onResourceFilterChanged(updated);
    };

    const allResources = tasks
        .filter(
            (t) => t.resourcedTasks?.filter((rt) => !!rt.resource?.id)?.length
        )
        .flatMap((t) => {
            return (t.resourcedTasks || []).map((rt) => rt.resource);
        });

    const uniqueResources = allResources
        .filter(
            (c, i, self) =>
                self.findIndex(
                    (t) =>
                        (t?.userId && t?.userId === c?.userId) ||
                        t?.id === c?.id
                ) === i
        )
        .map((t) => {
            return {
                userId: t?.userId ?? null,
                resourceId: t?.id ?? null,
                displayName: t?.displayName ?? t?.userId ?? '',
                checked:
                    selectedResources?.some(
                        (sr) =>
                            sr.userId === t?.userId && sr.resourceId === t?.id
                    ) || false,
            };
        });

    const renderPersonaIcon = (
        userId: string | null,
        displayName: string
    ): JSX.Element => {
        return (
            <Persona
                size={PersonaSize.size16}
                imageUrl={userId ? photoService.getImageUrl(userId) : undefined}
                text={displayName}
                hidePersonaDetails
            />
        );
    };

    const handeRenderText = (
        props?: IButtonProps,
        defaultRender?: (props?: IButtonProps) => JSX.Element | null
    ): JSX.Element => {
        return (
            <Stack horizontal tokens={{ childrenGap: 2 }}>
                {defaultRender && defaultRender(props)}
                {uniqueResources
                    .filter((ts) =>
                        selectedResources.some(
                            (r) =>
                                r.resourceId === ts.resourceId ||
                                (r.userId && r.userId === ts.userId)
                        )
                    )
                    .map((ts) => renderPersonaIcon(ts.userId, ts.displayName))}
            </Stack>
        );
    };

    const menuItems: IContextualMenuItem[] = orderBy(
        uniqueResources,
        'displayName'
    ).map((r) => {
        return {
            key: r?.resourceId ?? r?.userId ?? '',
            text: r?.displayName,
            canCheck: true,
            checked: r.checked,
            iconProps: {
                iconName: 'User',
            },
            onRenderIcon: () => renderPersonaIcon(r.userId, r.displayName),
            onClick: () => handleResourceFilterClick(r),
        };
    });

    if (selectedResources.length > 0) {
        menuItems.push(
            ...[
                {
                    key: 'clear-divider',
                    itemType: ContextualMenuItemType.Divider,
                },
                {
                    key: 'clear',
                    text: 'Clear',
                    iconProps: {
                        iconName: 'Cancel',
                    },
                    onClick: () => onResourceFilterChanged([]),
                },
            ]
        );
    }

    if (!menuItems.length) {
        return null;
    }

    return (
        <ActionButton
            text="Resource"
            onRenderText={handeRenderText}
            menuProps={{
                items: menuItems,
            }}
        />
    );
}
