import * as React from 'react';
import {
    DefaultPalette,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IRawStyle,
    SelectionMode,
    Shimmer,
    ShimmerElementType,
    ShimmerElementsGroup,
    Text,
    mergeStyleSets,
} from '@fluentui/react';
import {
    GetTeamUsageQuery,
    useGetTeamUsageLazyQuery,
} from '../../../data/types';
import { useCallback, useEffect, useState } from 'react';
import {
    GroupedVerticalBarChart,
    IGroupedVerticalBarChartData,
} from '@fluentui/react-charting';
import dayjs from 'dayjs';
import { ChartTableToggleButton } from './ChartTableToggleButton';
import { ExtractQueryType } from '../../../data/extendedTypes';

type Usage = {
    tenantDescription: string;
    divisionName: string;
    missionCount: number;
    activeMissionUserCount: number;
    averageActiveDays: number;
};

export function MissionOwnersUsage(props: {
    tenants?: {
        id: string | null;
        code: string | null;
        description: string | null;
    }[];
    usagePeriodStart?: string;
    usagePeriodEnd?: string;
    groupBy: 'ByDivision' | 'ByTenant';
}): JSX.Element {
    const [getUsage] = useGetTeamUsageLazyQuery({
        fetchPolicy: 'no-cache', // We don't want the historic results polluting the cache
    });

    const [view, setView] = useState<'Chart' | 'Table'>('Chart');
    const [isLoading, setIsLoading] = useState(true);
    const [usageData, setUsageData] = useState<Usage[]>([]);
    const [counts, setCounts] = useState<{
        missionOwners: number;
        activeMissionOwners: number;
    }>();

    const mapMissionToUsage = (
        missions: ExtractQueryType<GetTeamUsageQuery, ['missions']>,
        tenantName: string | null | undefined,
        divisionName?: string | null | undefined
    ): Usage => {
        const activeDays = missions
            .filter((m) => m.userUsage?.totalDistinctDays)
            ?.map((m) => m.userUsage?.totalDistinctDays || 0);

        return {
            tenantDescription: tenantName || '',
            divisionName: divisionName || '',
            missionCount: missions.length,
            activeMissionUserCount: missions.filter(
                (m) => m.userUsage?.totalUsageInMinutes
            ).length,
            averageActiveDays: activeDays.reduce(
                (acc, v, i, a) => acc + v / a.length,
                0
            ),
        };
    };

    const reloadUsage = useCallback(async () => {
        const newUsageData: Usage[] = [];

        setIsLoading(true);

        if (props.tenants && props.usagePeriodStart && props.usagePeriodEnd) {
            const results = await Promise.all(
                props.tenants?.map(async (t) => {
                    const d = await getUsage({
                        variables: {
                            tenantId: t.id || '',
                            financialYearCode: null,
                            financialYearActiveDate: props.usagePeriodEnd
                                ? dayjs(props.usagePeriodEnd).toISOString()
                                : null,
                            teamId: null,
                            includeDeleted: false,
                            isWritable: false,
                            includeInactive: false,
                            searchText: null,
                            usagePeriodStart: props.usagePeriodStart || '',
                            usagePeriodEnd: props.usagePeriodEnd || '',
                            forDateTime: props.usagePeriodEnd
                                ? dayjs(props.usagePeriodEnd).toISOString()
                                : null,
                        },
                    });

                    // Sort missions by division name
                    const missions = d.data?.missions
                        .slice()
                        .sort((m1, m2) =>
                            (m1.team?.division?.name || '').localeCompare(
                                m2.team?.division?.name || ''
                            )
                        );

                    if (missions?.length) {
                        if (props.groupBy === 'ByDivision') {
                            const divisionIds = [
                                ...new Set(
                                    missions.map((m) => m.team?.division?.id)
                                ),
                            ];

                            return divisionIds
                                .filter((d) => d)
                                .map((did) => {
                                    const divMissions = missions.filter(
                                        (d) => d.team?.division?.id === did
                                    );

                                    return mapMissionToUsage(
                                        divMissions,
                                        t.description || t.code,
                                        divMissions[0].team?.division?.name
                                    );
                                });
                        } else {
                            return mapMissionToUsage(
                                missions,
                                t.description || t.code
                            );
                        }
                    }
                })
            );

            results
                .flatMap((r) => r)
                .forEach((dr) => {
                    if (dr) {
                        newUsageData.push(dr);
                    }
                });
        }

        setUsageData(newUsageData);

        setCounts({
            missionOwners: newUsageData
                .map((u) => u.missionCount)
                .reduce((sum, current) => sum + current, 0),
            activeMissionOwners: newUsageData
                .map((u) => u.activeMissionUserCount)
                .reduce((sum, current) => sum + current, 0),
        });

        setIsLoading(false);
    }, [
        getUsage,
        props.groupBy,
        props.tenants,
        props.usagePeriodEnd,
        props.usagePeriodStart,
    ]);

    useEffect(() => {
        setUsageData([]);
        setCounts(undefined);
        reloadUsage();
    }, [
        props.tenants,
        props.usagePeriodStart,
        props.usagePeriodEnd,
        reloadUsage,
    ]);

    const classNames = mergeStyleSets({
        header: {
            paddingLeft: 8,
            paddingRight: 8,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        } as IRawStyle,
        headerLeft: {
            display: 'flex',
            flexDirection: 'column',
        } as IRawStyle,
        headerRight: {
            display: 'flex',
            flexDirection: 'row',
        } as IRawStyle,
    });

    return (
        <div>
            <div className={classNames.header}>
                <div className={classNames.headerLeft}>
                    <Text block variant="large">
                        Mission Owners
                    </Text>

                    {!isLoading && !!counts && (
                        <Text block variant="small">
                            Total Missions Owners: {counts.missionOwners}
                        </Text>
                    )}

                    {!isLoading && !!counts && (
                        <Text block variant="small">
                            Active Mission Owners: {counts.activeMissionOwners}
                        </Text>
                    )}
                </div>
                <div>
                    <ChartTableToggleButton
                        selectedView={view}
                        onViewChanged={setView}
                    />
                </div>
            </div>

            <Shimmer
                styles={{
                    shimmerWrapper: {
                        marginTop: 8,
                        marginBottom: 8,
                    },
                }}
                isDataLoaded={!!usageData.length && !isLoading}
                customElementsGroup={
                    <div>
                        <ShimmerElementsGroup
                            width="100%"
                            height={380}
                            shimmerElements={[
                                {
                                    type: ShimmerElementType.gap,
                                    width: 16,
                                },
                                {
                                    type: ShimmerElementType.line,
                                    height: 500,
                                    width: '100%',
                                },
                                {
                                    type: ShimmerElementType.gap,
                                    width: 16,
                                },
                            ]}
                        />
                    </div>
                }
            >
                {!!usageData.length && !isLoading && (
                    <>
                        {view === 'Chart' ? (
                            <MissionOwnersUsageChart usageData={usageData} />
                        ) : (
                            <MissionOwnersUsageTable
                                usageData={usageData}
                                showDivision={props.groupBy === 'ByDivision'}
                            />
                        )}
                    </>
                )}
            </Shimmer>
        </div>
    );
}

function MissionOwnersUsageTable(props: {
    usageData: Usage[];
    showDivision: boolean;
}): JSX.Element {
    const { usageData } = props;

    const formatter = new Intl.NumberFormat(undefined, {
        maximumFractionDigits: 1,
    });

    let columns: IColumn[] = [
        {
            key: 'tenantDescription',
            fieldName: 'tenantDescription',
            minWidth: 200,
            name: 'Tenant',
        },
        {
            key: 'divisionName',
            fieldName: 'divisionName',
            minWidth: 180,
            name: 'Division',
        },
        {
            key: 'missionCount',
            fieldName: 'missionCount',
            minWidth: 180,
            name: 'Mission Owners',
        },
        {
            key: 'activeMissionUserCount',
            fieldName: 'activeMissionUserCount',
            minWidth: 180,
            name: 'Active Mission Users',
        },
        {
            key: 'averageActiveDays',
            fieldName: 'averageActiveDays',
            minWidth: 180,
            name: 'Average Active Days',
            onRender: (item: Usage) => {
                return formatter.format(item.averageActiveDays);
            },
        },
    ];

    if (!props.showDivision) {
        columns = columns.filter((c) => c.key !== 'divisionName');
    }

    return (
        <DetailsList
            items={usageData}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            onShouldVirtualize={(): boolean => false}
            compact
        />
    );
}

function MissionOwnersUsageChart(props: { usageData: Usage[] }): JSX.Element {
    const { usageData } = props;

    const chartData: IGroupedVerticalBarChartData[] = usageData.map((item) => ({
        name:
            item.tenantDescription !== item.divisionName && item.divisionName
                ? `${item.tenantDescription} (${item.divisionName})`
                : item.tenantDescription,
        series: [
            {
                key: 'missionCount',
                legend: 'Mission Owners',
                xAxisCalloutData: `Mission Owners`,
                data: item.missionCount,
                color: DefaultPalette.accent,
            },
            {
                key: 'activeCount',
                legend: 'Active Mission Owners',
                xAxisCalloutData: `Active Mission Owners`,
                data: item.activeMissionUserCount,
                color: DefaultPalette.blueMid,
            },
        ],
    }));

    return (
        <GroupedVerticalBarChart
            data={chartData}
            svgProps={{
                width: '100%',
            }}
            height={380}
            barwidth={64}
            wrapXAxisLables
            styles={{
                yAxis: {
                    display: 'none',
                },
            }}
        />
    );
}
