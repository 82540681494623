import React from 'react';
import {
    Rating,
    RatingSize,
    Shimmer,
    Text,
    mergeStyleSets,
} from '@fluentui/react';
import { useThemes } from '../hooks/useThemes';

export const RatingTexts = ['Minimal', 'Low', 'Moderate', 'High', 'Maximum'];

type WeightingRatingProps = {
    isBusy?: boolean;
    readOnly?: boolean;
    value: number;
    onChange?: (newValue: number) => void;
    showRatingText?: boolean;
};

export function EffortWeightingRating(props: WeightingRatingProps) {
    return (
        <WeightingRating
            {...props}
            icon="EffortSolid"
            unselectedIcon="Effort"
            ariaLabel="Effort lightning bolts"
            ariaLabelFormat="{0} of {1} bolts"
        />
    );
}

export function CostWeightingRating(props: WeightingRatingProps) {
    return (
        <WeightingRating
            {...props}
            icon="OfflineStorageSolid"
            unselectedIcon="OfflineStorage"
            ariaLabel="Cost coin stack"
            ariaLabelFormat="{0} of {1} coin stacks"
        />
    );
}

export function WeightingRating(
    props: WeightingRatingProps & {
        icon: string | undefined;
        unselectedIcon: string | undefined;
        ariaLabel: string;
        ariaLabelFormat: string;
    }
): JSX.Element {
    const { currentTheme } = useThemes();

    const ratingText =
        props.value > 0 && props.value <= RatingTexts.length
            ? RatingTexts[props.value - 1]
            : null;

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 8,
        },
    });

    return (
        <Shimmer isDataLoaded={!props.isBusy} width="100%" height={16}>
            <div className={classNames.container}>
                <Rating
                    max={5}
                    size={RatingSize.Small}
                    icon={props.icon}
                    unselectedIcon={props.unselectedIcon}
                    rating={props.value}
                    onChange={(_ev, rating) => {
                        if (props.onChange) {
                            props.onChange(rating || 0);
                        }
                    }}
                    ariaLabel={props.ariaLabel}
                    ariaLabelFormat={props.ariaLabelFormat}
                    readOnly={props.readOnly}
                    styles={{
                        ratingStarFront: {
                            color: currentTheme.palette.themePrimary,
                        },
                    }}
                />
                {props.showRatingText && (
                    <Text variant="smallPlus">{ratingText}</Text>
                )}
            </div>
        </Shimmer>
    );
}
