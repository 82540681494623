import React from 'react';
import { Text, Icon, TooltipHost } from '@fluentui/react';

export function TaskCategoryLabel(props: {
    name?: string | null;
    colourHex?: string | null;
    hideName?: boolean;
}): JSX.Element {
    const iconStyles = {
        color: props.colourHex ? `#${props.colourHex}` : undefined,
        marginRight: 4,
    };

    const icon = (
        <Icon style={iconStyles} iconName="TagSolid" aria-hidden="true" />
    );

    if (props.hideName) {
        return <TooltipHost content={props.name || ''}>{icon}</TooltipHost>;
    } else {
        return (
            <span style={{ display: 'inline-flex' }}>
                {icon}
                {!props.hideName && (
                    <Text variant="small">
                        {props.name ? props.name : 'None'}
                    </Text>
                )}
            </span>
        );
    }
}
