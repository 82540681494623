import * as React from 'react';
import { useFormatters } from '../../../hooks/useFormatters';
import { MeasureChartColours } from '../../../Colours';
import { MeasureTypes, Multipliers, StatusTypes } from '../../../data/types';
import { Text } from '@fluentui/react';
import {
    Bar,
    BarChart,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import {
    ContentType,
    Payload,
} from 'recharts/types/component/DefaultLegendContent';
import { ILegend, Legends } from '@fluentui/react-charting';
import { useThemes } from '../../../hooks/useThemes';
import { MeasureComposedRechartTooltip } from './MeasureComposedRechartTooltip';
import { ChartData } from '../utils/measureUtils';

interface Props {
    width?: number | string;
    height?: number | string;
    hideLegend?: boolean;
    hideAxis?: boolean;
    showForecast: boolean;
    yearChartData: ChartData;
    tooltipTitle?: string | null;
    measureFormat: {
        measureType: MeasureTypes;
        statusType: StatusTypes;
        multiplier: Multipliers | null;
        currencySymbol: string | null;
        decimalPlaces: number;
        isStatusLimited: boolean;
    };
    statusOnly?: boolean;
}

export function YearProgressChart(props: Props): JSX.Element {
    const { formatMeasureValue, formatMeasureStatus } = useFormatters();
    const { currentTheme } = useThemes();

    const target = (props.yearChartData.Target as number) || 0;
    const actual = (props.yearChartData.Actual as number) || 0;
    let targetRemaining = 0;
    let targetOver = 0;

    const targetHex = currentTheme.palette.themeLight;
    const actualHex = currentTheme.palette.themePrimary;

    switch (props.measureFormat.statusType) {
        case StatusTypes.MoreThanTarget:
            targetRemaining = Math.max(0, target - actual);
            break;
        case StatusTypes.LessThanTarget:
            targetRemaining = Math.max(0, target - actual);
            targetOver = Math.max(0, actual - target);
            break;
        case StatusTypes.TargetExact:
            targetRemaining = Math.abs(actual - target);
            break;
    }

    const chartData: (ChartData & {
        ActualAdjusted: number;
        StatusTarget: number;
    })[] = [
        {
            ...props.yearChartData,
            Forecast: props.showForecast ? props.yearChartData.Forecast : null,
            TargetRemaining: targetRemaining,
            TargetOver: targetOver,
            ActualAdjusted: actual - targetOver,
            StatusTarget: 1,
        },
    ];

    const renderLegend: ContentType = (props: { payload?: Payload[] }) => {
        const { payload } = props;

        const legends: ILegend[] = payload
            ? payload.map((p) => ({
                  title: p.value || '',
                  color:
                      p.dataKey === 'TargetOver'
                          ? MeasureChartColours.targetOver
                          : p.color || '',
              }))
            : [];

        return (
            <div style={{ display: 'flex', height: 40, alignItems: 'end' }}>
                <Legends
                    legends={legends}
                    overflowText={'Overflow Items'}
                    allowFocusOnLegends={false}
                    centerLegends
                />
            </div>
        );
    };

    const margin = {
        top: 8,
        right: props.hideAxis ? 8 : 32,
        bottom: 8,
        left: props.hideAxis ? 8 : 32,
    };

    const renderCustomizedLabel = (p: {
        x: number;
        y: number;
        width: number;
        height: number;
        name: string;
    }) => {
        const spacing = 6;

        return (
            <text
                x={margin.left}
                y={p.y + p.height + spacing}
                fontSize={12}
                fill={currentTheme.palette.neutralPrimary}
                textAnchor="start"
                dominantBaseline="hanging"
            >
                {props.tooltipTitle}
            </text>
        );
    };

    return (
        <ResponsiveContainer width={props.width} height={props.height}>
            <BarChart
                layout="vertical"
                barGap={-24}
                data={chartData}
                margin={margin}
            >
                <defs>
                    <pattern
                        id="stripes"
                        patternTransform="rotate(-45)"
                        width="100%"
                        height="10"
                        x="0"
                        y="0"
                        patternUnits="userSpaceOnUse"
                    >
                        <g>
                            <rect
                                x="0"
                                y="0"
                                width="100%"
                                height="5"
                                fill={MeasureChartColours.targetOver}
                            />
                            <rect
                                x="0"
                                y="5"
                                width="100%"
                                height="5"
                                fill={actualHex}
                            />
                        </g>
                    </pattern>
                </defs>

                {!props.statusOnly && (
                    <Bar
                        label={renderCustomizedLabel}
                        dataKey="Target"
                        name="Target"
                        barSize={32}
                        fill={targetHex}
                        radius={[0, 4, 4, 0]}
                    />
                )}

                {!props.statusOnly && (
                    <Bar
                        dataKey="ActualAdjusted"
                        name="Actual"
                        barSize={16}
                        fill={actualHex}
                        stackId="A"
                        radius={[0, 4, 4, 0]}
                    />
                )}

                {props.statusOnly && (
                    <Bar
                        type="monotone"
                        dataKey="StatusTarget"
                        name="Status Target"
                        barSize={32}
                        fill={targetHex}
                        radius={[0, 4, 4, 0]}
                        legendType="none"
                    />
                )}

                {props.statusOnly && (
                    <Bar
                        type="monotone"
                        dataKey="StatusValue"
                        name="Status"
                        barSize={16}
                        fill={actualHex}
                        stackId="A"
                        radius={[0, 4, 4, 0]}
                    />
                )}

                {!!targetOver && (
                    <Bar
                        dataKey="TargetOver"
                        name="Over Target"
                        barSize={16}
                        fill={`url(#stripes)`}
                        stackId="A"
                        radius={[0, 4, 4, 0]}
                    />
                )}
                <XAxis
                    hide={props.hideAxis}
                    type="number"
                    includeHidden
                    orientation="top"
                    tickFormatter={(s): string => {
                        return props.statusOnly
                            ? formatMeasureStatus(
                                  Number(s),
                                  props.measureFormat.isStatusLimited
                              )
                            : target !== 0 && Number(s) !== 0
                              ? formatMeasureStatus(Number(s) / target, false)
                              : '0%';
                    }}
                    domain={([dataMin, dataMax]) => [
                        Math.min(0, dataMin),
                        dataMax,
                    ]}
                />
                <YAxis type="category" dataKey="name" hide />
                <Tooltip
                    cursor={{ fill: 'transparent' }}
                    wrapperStyle={{
                        zIndex: 1000,
                        outline: 'none',
                    }}
                    contentStyle={{
                        backgroundColor: currentTheme.palette.white,
                    }}
                    content={<MeasureComposedRechartTooltip />}
                    formatter={(value, name) => {
                        if (name === 'Status') {
                            return formatMeasureStatus(
                                Number(value) || 0,
                                props.measureFormat.isStatusLimited
                            );
                        }
                        return formatMeasureValue(props.measureFormat, value);
                    }}
                    labelFormatter={(label) => {
                        return (
                            <>
                                <Text block variant="mediumPlus">
                                    {label}
                                </Text>
                                {!!props.tooltipTitle && (
                                    <Text block variant="smallPlus">
                                        {props.tooltipTitle}
                                    </Text>
                                )}
                            </>
                        );
                    }}
                />
                {!props.hideLegend && <Legend content={renderLegend} />}
            </BarChart>
        </ResponsiveContainer>
    );
}
