import React, { CSSProperties, useState } from 'react';

import {
    useUpdateMissionSequenceMutation,
    useUpdateDottedMissionSequenceMutation,
} from '../../../data/types';

import { Text, mergeStyleSets, Stack } from '@fluentui/react';

import TeamMissionCard, { TeamMissionCardProps } from './TeamMissionCard';
import {
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    rectSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { TeamViewModes } from '..';
import Loading from '../../../components/Loading';
import orderBy from 'lodash/orderBy';
import { useSortableGroups } from '../../../hooks/useSortableGroups';
import { GroupContainer } from '../../MissionBuilder/components/GroupContainer';
import { DragHandlerButtonMemo } from '../../../components/DragHandlerButton';
import { Access } from '../../../data/extendedTypes';
import { useThemes } from '../../../hooks/useThemes';

type TeamMissionsPropsMission = {
    id: string | null;
    userId: string | null;
    owner?: string | null;
    title?: string | null;
    missionStatement?: string | null;
    leaderOfTeams?: {
        id: string | null;
        code: string | null;
        name: string | null;
    }[];
    sequence?: number;
    rights: Access;
    missionGroupId?: string | null;
};

export type TeamMissionsProps = {
    tenantId: string;
    tenantCode: string;
    teamId: string | null;
    teamCode: string;
    teamName: string;
    financialYearCode: string;
    fyStartDate: string;
    leaderMission: TeamMissionsPropsMission | null;
    missions: TeamMissionsPropsMission[];
    dottedMissions: (TeamMissionsPropsMission & {
        dottedMissionId: string | null;
    })[];
    teamCardMode: TeamViewModes;
    loading: boolean;
    missionGroups:
        | {
              id: string | null;
              name: string | null;
              sequence: number | null;
          }[]
        | null;
    onMeasureClick: (missionId: string, measureId: string) => void;
    onTaskClick: (missionId: string, taskId: string) => void;
    canSort: boolean;
    compact: boolean;
};

function SortableTeamMissionCard(props: {
    canSort: boolean;
    className: string;
    teamCardProps: TeamMissionCardProps;
}): JSX.Element {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: props.teamCardProps.missionId || '' });

    return (
        <div
            ref={setNodeRef}
            className={props.className}
            style={{
                opacity: isDragging ? 0.4 : 1,
                transform: CSS.Transform.toString(transform),
                transition: transition || undefined,
            }}
        >
            <TeamMissionCard
                {...props.teamCardProps}
                dragHandler={
                    <DragHandlerButtonMemo
                        hidden={!props.canSort}
                        handleListeners={listeners}
                        handleAttributes={attributes}
                    />
                }
            />
        </div>
    );
}

function SortableMissions(
    props: TeamMissionsProps & {
        missionGroups:
            | {
                  id: string | null;
                  name: string | null;
                  sequence: number | null;
              }[]
            | null;
    }
): JSX.Element {
    const { currentTheme } = useThemes();

    const [collapseGroups, setCollapsedGroups] = useState<(string | null)[]>(
        []
    );

    const getTeamCardPropsForId = (
        missionId: string
    ): TeamMissionCardProps | null => {
        const mission =
            props.missions.find((m) => m.id === missionId) ||
            props.dottedMissions.find((m) => m.id === missionId);
        return mission ? getTeamCardProps(mission) : null;
    };

    const getTeamCardProps = (
        mission: TeamMissionsPropsMission
    ): TeamMissionCardProps => {
        const missionTeams = mission.leaderOfTeams?.length
            ? mission.leaderOfTeams
            : [
                  {
                      id: props.teamId,
                      code: props.teamCode,
                      name: props.teamName,
                  },
              ];

        return {
            mode: props.teamCardMode,
            isTeamLeader: false,
            tenantCode: props.tenantCode,
            financialYearCode: props.financialYearCode,
            missionId: mission.id || '',
            missionUserId: mission.userId,
            title: mission.title || '',
            owner: mission.owner || props.teamName || '',
            missionStatement: mission.missionStatement || '',
            missionTeams: missionTeams,
            missionAccess: mission?.rights,
            onMeasureClick: props.onMeasureClick,
            onTaskClick: props.onTaskClick,
            compact: props.compact,
            fyStartDate: props.fyStartDate,
        };
    };
    const classNames = mergeStyleSets({
        missionCardContainer: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gridAutoRows: 'minmax(180px, auto)',
            gridAutoFlow: 'dense',
        },
        missionCard: {
            padding: 8,
            gridColumnEnd: 'span 1',
            gridRowEnd: 'span 1',
        },
    });

    const UNGROUPED_ID = 'UNGROUPED';
    const VOID_ID = 'void';

    type Items = Record<string, string[]>;
    const [items, setItems] = useState<Items>({});
    const [activeId, setActiveId] = useState<string | null>(null);

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: { y: 5, x: 5 },
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    React.useEffect(() => {
        const missions = orderBy(
            props.missions.concat(props.dottedMissions) || [],
            'sequence'
        );

        const groups = [
            {
                id: UNGROUPED_ID,
                name: 'Ungrouped',
                sequence: -1,
            },
            ...orderBy(props.missionGroups || [], 'sequence'),
        ];

        const groupsAndMissions: Items = {};

        groups.forEach((g) => {
            groupsAndMissions[g.id || UNGROUPED_ID] = missions
                .filter((m) => {
                    // Check the group exists first.
                    if (!groups.some((gr) => gr.id === m.missionGroupId)) {
                        // If it doesn't, stick it in ungrouped
                        return g.id === UNGROUPED_ID;
                    }
                    return (m.missionGroupId || UNGROUPED_ID) === g.id;
                })
                .map((m) => m.id || VOID_ID);
        });

        setItems(groupsAndMissions);
    }, [props.missionGroups, props.missions, props.dottedMissions]);

    const [updateMissionSequence] = useUpdateMissionSequenceMutation();

    const [updateDottedMissionSequence] =
        useUpdateDottedMissionSequenceMutation();

    const handleMissionSequenceChanged = async (
        missionId: string,
        missionGroupId: string | null,
        newIndex: number
    ) => {
        const sortedMissions = orderBy(
            props.missions.concat(props.dottedMissions),
            'sequence'
        );

        const movedItem = sortedMissions.find((m) => m.id === missionId);

        if (sortedMissions && movedItem) {
            const remainingItems = sortedMissions.filter(
                (m) => m.id !== missionId && m.missionGroupId === missionGroupId
            );

            const reorderedItems = [
                ...remainingItems.slice(0, newIndex),
                movedItem,
                ...remainingItems.slice(newIndex),
            ];

            setItems((current) => {
                const updated: Items = {};

                Object.keys(current).forEach((currentGroupId) => {
                    const key = currentGroupId || UNGROUPED_ID;

                    updated[key as string] =
                        currentGroupId === (missionGroupId || UNGROUPED_ID)
                            ? reorderedItems.map((r) => r.id || '')
                            : current[currentGroupId as string];
                });

                return updated;
            });

            let index = 0;
            for (const mission of reorderedItems) {
                if (
                    mission?.id &&
                    (mission?.sequence !== index ||
                        (mission?.missionGroupId || null) !==
                            (missionGroupId || null))
                ) {
                    const dottedMission = props.dottedMissions.find(
                        (m) => m.id === mission.id
                    );

                    if (dottedMission) {
                        updateDottedMissionSequence({
                            variables: {
                                tenantId: props.tenantId || '',
                                missionId: mission.id,
                                teamId: props.teamId || '',
                                sequence: index,
                                missionGroupId: missionGroupId,
                            },
                            optimisticResponse: {
                                __typename: 'Mutations',
                                dottedMissionSequenceUpdate: {
                                    id: dottedMission.dottedMissionId,
                                    sequence: index,
                                    missionGroupId: missionGroupId,
                                    __typename: 'DottedTeamMissionQL',
                                },
                            },
                        });
                    } else {
                        updateMissionSequence({
                            variables: {
                                tenantId: props.tenantId || '',
                                missionId: mission.id,
                                sequence: index,
                                missionGroupId: missionGroupId,
                            },
                            optimisticResponse: {
                                __typename: 'Mutations',
                                missionSequenceUpdate: {
                                    id: mission.id,
                                    sequence: index,
                                    missionGroupId: missionGroupId,
                                    version: null,
                                    __typename: 'MissionQL',
                                },
                            },
                        });
                    }
                }
                index++;
            }
        }
    };

    const { handleDragOver, handleDragEnd, handleDragStart, handleDragCancel } =
        useSortableGroups(
            items,
            setItems,
            setActiveId,
            handleMissionSequenceChanged
        );

    const onExpandGroup = (groupId: string | null) => {
        setCollapsedGroups(
            collapseGroups.slice().filter((id) => id !== groupId)
        );
    };

    const onCollapseGroup = (groupId: string | null) => {
        setCollapsedGroups([
            ...collapseGroups.slice().filter((id) => id !== groupId),
            groupId,
        ]);
    };

    const placeholderStyle: CSSProperties = {
        border: `dashed 2px ${currentTheme.palette.neutralLighterAlt}`,
        borderRadius: 8,
        minHeight: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 8,
    };

    const activeTeamCardProps = activeId
        ? getTeamCardPropsForId(activeId)
        : null;

    return (
        <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDragCancel={handleDragCancel}
        >
            {Object.keys(items).map((containerId) => {
                const group = props.missionGroups?.find(
                    (g) => g.id === containerId
                );

                const isExpanded = !collapseGroups.some(
                    (id) => id === containerId
                );

                return (
                    <GroupContainer
                        key={`group_${containerId}`}
                        containerClassName={classNames.missionCardContainer}
                        group={
                            group
                                ? group
                                : {
                                      id: containerId,
                                      name: 'Ungrouped',
                                  }
                        }
                        showGroupName={!!group?.id}
                        isExpanded={isExpanded}
                        onExpandGroup={onExpandGroup}
                        onCollapseGroup={onCollapseGroup}
                    >
                        {!group?.id && props.leaderMission && (
                            <div className={classNames.missionCard}>
                                <TeamMissionCard
                                    {...getTeamCardProps(props.leaderMission)}
                                    isTeamLeader={true}
                                />
                            </div>
                        )}

                        <SortableContext
                            key={containerId}
                            items={items[`${containerId}`] || []}
                            strategy={rectSortingStrategy}
                        >
                            {items[`${containerId}`].map((missionId) => {
                                const cardProps =
                                    getTeamCardPropsForId(missionId);

                                if (cardProps) {
                                    return (
                                        <SortableTeamMissionCard
                                            key={missionId}
                                            teamCardProps={{
                                                ...cardProps,
                                                isTeamLeader: false,
                                            }}
                                            className={classNames.missionCard}
                                            canSort={props.canSort}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            })}

                            {items[`${containerId}`].length === 0 &&
                                (containerId !== UNGROUPED_ID ||
                                    activeId !== null) &&
                                props.canSort && (
                                    <div
                                        className={classNames.missionCard}
                                        style={placeholderStyle}
                                        key={`group_${containerId}_drop`}
                                    >
                                        <Stack
                                            verticalAlign="center"
                                            horizontalAlign="center"
                                            styles={{
                                                root: { height: '100%' },
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontStyle: 'italic',
                                                }}
                                            >
                                                Drop mission here
                                            </Text>
                                        </Stack>
                                    </div>
                                )}
                        </SortableContext>
                    </GroupContainer>
                );
            })}

            <DragOverlay adjustScale={false}>
                {activeTeamCardProps ? (
                    <TeamMissionCard
                        dragHandler={<DragHandlerButtonMemo hidden={false} />}
                        key={`${activeId}_overlay`}
                        {...activeTeamCardProps}
                    />
                ) : null}
            </DragOverlay>
        </DndContext>
    );
}

function TeamMissions(props: TeamMissionsProps): JSX.Element {
    if (props.loading) {
        return <Loading />;
    }

    return (
        <SortableMissions
            {...props}
            missionGroups={props.missionGroups || null}
        />
    );
}

export default React.memo(TeamMissions);
