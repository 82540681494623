import React from 'react';
import {
    Panel,
    PanelType,
    Text,
    Label,
    Stack,
    TextField,
    PrimaryButton,
} from '@fluentui/react';
import { useStateContext } from '../services/contextProvider';
import { useGetTaskQuery, useUpdateResourceTaskMutation } from '../data/types';
import { Access } from '../data/extendedTypes';
import dayjs from 'dayjs';

type RejectedResourcePanelProps = {
    taskId: string | undefined | null;
    showPanel: boolean;
    onCancel: () => void;
    missionAccess: Access;
};

export default function RejectedResourcePanel(
    props: RejectedResourcePanelProps
): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { data, loading } = useGetTaskQuery({
        skip: !currentTenantId || !props.taskId || !props.showPanel,
        variables: {
            tenantId: currentTenantId || '',
            id: props.taskId || '',
        },
    });

    const task = data?.task;

    const [updateResourceTaskMutation, { loading: isRequestingAgain }] =
        useUpdateResourceTaskMutation();

    const getDateForInput = (date: string | null) =>
        date ? dayjs(date).format('YYYY-MM-DD') : null;

    const onRequestAgainButtonClick = async (): Promise<void> => {
        if (task) {
            await updateResourceTaskMutation({
                variables: {
                    tenantId: currentTenantId || '',
                    input: {
                        id: task.id,
                        name: task.name,
                        start: getDateForInput(task.start),
                        due: getDateForInput(task.due),
                        done: getDateForInput(task.done),
                        utcPostponed: task.utcPostponed,
                        utcCancelled: task.utcCancelled,
                        utcAtRisk: task.utcAtRisk,
                        resourceIsPrimary: task.resourceIsPrimary,
                        effortResourceWeight: task.effortResourceWeight,
                        utcResourceRemoved: task.utcResourceRemoved,
                        utcChangesPending: task.utcChangesPending,
                        version: task.version,
                        utcRejected: null,
                        rejectedReason: null,
                    },
                },
            });
            props.onCancel();
        }
    };

    return (
        <Panel
            isOpen={props.showPanel}
            type={PanelType.smallFixedFar}
            headerText="Rejected Resource Request"
            closeButtonAriaLabel="Close"
            onDismiss={props.onCancel}
        >
            {task && !loading && (
                <Stack tokens={{ childrenGap: 8 }}>
                    <Stack.Item>
                        {task.resource?.displayName && (
                            <Text>
                                {task.resource?.displayName} rejected this task.
                            </Text>
                        )}
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Reason</Label>
                        <TextField
                            multiline
                            autoAdjustHeight
                            readOnly
                            value={task?.rejectedReason || ''}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Text block>
                            {task?.utcRejected
                                ? dayjs
                                      .utc(task?.utcRejected)
                                      .tz(dayjs.tz.guess())
                                      .format('DD MMM YYYY - HH:mm (z)')
                                : ''}
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        {props.missionAccess.write && (
                            <PrimaryButton
                                disabled={isRequestingAgain}
                                onClick={onRequestAgainButtonClick}
                            >
                                Request Again
                            </PrimaryButton>
                        )}
                    </Stack.Item>
                </Stack>
            )}
        </Panel>
    );
}
