import React, { useState } from 'react';

import {
    IconButton,
    IButtonStyles,
    ContextualMenuItemType,
    IContextualMenuItem,
} from '@fluentui/react';
import { useGetTasksAndResourcesLazyQuery } from '../data/types';
import { sorters } from '../data/sorters';
import { useStateContext } from '../services/contextProvider';
import { ImpliedTaskType } from './ImpliedTaskList';
import { TaskSummaryItem } from '../scenes/MissionBuilder/components/TaskList';
import MoveToPositionDialog from './shared/MoveToPositionDialog';

export function ImpliedTaskMoreOptionsButton(props: {
    impliedTask: ImpliedTaskType;
    missionUserId: string | null;
    onEditClick: (taskId: string) => void;
    buttonStyles: IButtonStyles;
    onImpliedTaskMove: (
        source: TaskSummaryItem,
        targetSpecifiedId: string,
        targetImpliedId: string | null
    ) => void;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { impliedTask } = props;

    const [getTasks] = useGetTasksAndResourcesLazyQuery({
        variables: {
            tenantId: currentTenantId || '',
            missionId: impliedTask.missionId || '',
        },
    });

    const [specifiedTaskMenuItems, setspecifiedTaskMenuItems] =
        useState<IContextualMenuItem[]>();

    const [moveDialogHidden, setMoveDialogHidden] = useState(true);
    const showMoveDialog = () => setMoveDialogHidden(false);
    const hideMoveDialog = () => setMoveDialogHidden(true);

    const [maxPosition, setMaxPostion] = useState(1);

    const onEditClick = () =>
        impliedTask.id ? props.onEditClick(impliedTask.id) : undefined;

    const handleSwitchSpecifiedTask = async (targetSpecifiedTaskId: string) => {
        const source = impliedTask;
        if (source && source.parentTaskId) {
            props.onImpliedTaskMove(source, targetSpecifiedTaskId, null);
        }
    };

    const handleMoveToPosition = async (newPosition: number) => {
        const tasksResult = await getTasks();

        const source = impliedTask;
        const target = tasksResult.data?.tasks
            .slice()
            .filter((t) => t.parentTaskId === impliedTask.parentTaskId)
            .filter((t) => !t.isDuplicate)
            .sort(sorters.sequenceSorter)
            .at(newPosition - 1);

        if (
            source &&
            target &&
            source.id !== target.id &&
            source.parentTaskId
        ) {
            props.onImpliedTaskMove(source, source.parentTaskId, target.id);
        }
    };

    const handleMoveToTop = async () => {
        handleMoveToPosition(1);
    };

    const loadMenuOptions = async () => {
        const tasksResult = await getTasks();

        const specifiedTasks = tasksResult.data?.tasks
            .filter((t) => t.parentTaskId === null)
            .filter((t) => !t.isDuplicate)
            .filter((t) => t.id !== impliedTask.parentTaskId);

        const impliedTasks = tasksResult.data?.tasks
            .filter((t) => t.parentTaskId === impliedTask.parentTaskId)
            .filter((t) => !t.isDuplicate);

        setMaxPostion(impliedTasks?.length || 1);

        setspecifiedTaskMenuItems(
            specifiedTasks?.sort(sorters.sequenceSorter).map((t) => ({
                key: t.id || '',
                text: t.name || '',
                onClick: () => {
                    if (t.id) {
                        handleSwitchSpecifiedTask(t.id);
                    }
                },
            }))
        );
    };

    const onMenuClick = () => {
        loadMenuOptions();
    };

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'edit',
            text: 'Edit',
            iconProps: { iconName: 'Edit' },
            onClick: onEditClick,
        },
        {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider,
        },
        {
            key: 'movetotop',
            text: 'Move to top',
            iconProps: { iconName: 'Up' },
            disabled: impliedTask.sequence == 0,
            onClick: () => {
                handleMoveToTop();
            },
        },
        {
            key: 'movepostition',
            text: 'Move to position...',
            disabled: maxPosition === 1,
            iconProps: { iconName: 'NumberedListText' },
            onClick: () => showMoveDialog(),
        },
        {
            key: 'movespecifiedtask',
            text: 'Switch specified task',
            iconProps: { iconName: 'Switch' },
            disabled: !specifiedTaskMenuItems?.length,
            subMenuProps: specifiedTaskMenuItems?.length
                ? {
                      items: specifiedTaskMenuItems,
                  }
                : undefined,
        },
    ];

    return (
        <>
            <IconButton
                iconProps={{ iconName: 'MoreVertical' }}
                title="More options"
                ariaLabel="More options"
                styles={props.buttonStyles}
                onRenderMenuIcon={() => {
                    return null;
                }}
                onMenuClick={onMenuClick}
                menuProps={{
                    items: menuItems,
                }}
            />
            <MoveToPositionDialog
                hidden={moveDialogHidden}
                onDismiss={hideMoveDialog}
                maxValue={maxPosition}
                onMoveClicked={(newPosition: number) => {
                    hideMoveDialog();
                    handleMoveToPosition(newPosition);
                }}
            />
        </>
    );
}
