import React, { ReactNode } from 'react';
import { useStateContext } from '../services/contextProvider';
import DeleteModal from './shared/DeleteModal';
import {
    refetchGetMeasureQuery,
    refetchGetMeasureValueHistoryQuery,
    refetchGetMissionMeasuresQuery,
    useDeleteMeasureMutation,
    useGetMeasureQuery,
} from '../data/types';
import { PureQueryOptions } from '@apollo/client';
import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';

export function MeasureDeleteModal(props: {
    measureId: string | null;
    isOpen: boolean;
    onDismiss: () => void;
    onDeleted: () => void;
    onTransferInsteadButtonClick: () => void;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { data } = useGetMeasureQuery({
        skip: !props.measureId || !props.isOpen,
        variables: {
            tenantId: currentTenantId || '',
            id: props.measureId || '',
        },
    });

    const measure = data?.measure;

    const deleteRefetchQueries: PureQueryOptions[] = [
        refetchGetMissionMeasuresQuery({
            tenantId: currentTenantId || '',
            missionId: measure?.missionId || null,
        }),
        refetchGetMeasureValueHistoryQuery({
            tenantId: currentTenantId || '',
            id: props.measureId || '',
            historyHasActual: false,
            historySkip: 0,
            historyTake: 12,
        }),
    ];

    if (measure?.linkedFromMeasureId && measure?.isLinked) {
        deleteRefetchQueries.push(
            refetchGetMeasureQuery({
                tenantId: currentTenantId || '',
                id: measure?.linkedFromMeasureId,
            })
        );
    }

    const [deleteMeasure, { loading: isDeleting, error: deleteError }] =
        useDeleteMeasureMutation({
            variables: {
                tenantId: currentTenantId || '',
                id: props.measureId || '',
                restore: false,
            },
            awaitRefetchQueries: true,
            refetchQueries: deleteRefetchQueries,
            onCompleted: () => {
                props.onDeleted();
            },
        });

    let content: ReactNode | undefined = undefined;

    // Has this measure been linked to (check it isn't a linked one)
    if (measure && !measure?.isLinked && measure?.linkedMeasures.length > 0) {
        content = (
            <MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline={true}
                actions={
                    <div>
                        <MessageBarButton
                            onClick={props.onTransferInsteadButtonClick}
                        >
                            Transfer...
                        </MessageBarButton>
                    </div>
                }
            >
                This measure of success has been linked to. Would you like to
                transfer to another mission instead?
            </MessageBar>
        );
    }

    return (
        <DeleteModal
            activeViewName="MeasureDelete"
            {...props}
            message="Are you sure you want to delete this measure of success from the mission?"
            deleteAction={async (): Promise<void> => {
                await deleteMeasure();
            }}
            isDeleting={isDeleting}
            error={deleteError}
            content={content}
        />
    );
}
