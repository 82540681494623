import React, { useEffect, useState } from 'react';

import {
    PersonaSize,
    PersonaCoin,
    IPersona,
    mergeStyleSets,
    IButtonStyles,
    IconButton,
    IRawStyle,
    IContextualMenuItem,
} from '@fluentui/react';
import { photoService } from '../services/photo.service';
import { useThemes } from '../hooks/useThemes';
import { ResourceTooltip } from './ResourceTooltip';
import {
    ImpliedTaskType,
    useResourceCoinStyles,
    ResourceTaskType,
} from './ImpliedTaskList';
import { useTaskAvailableResources } from '../hooks/useTaskAvailableResources';
import { useResourceUpdater } from '../hooks/useResourceUpdater';
import { useStateContext } from '../services/contextProvider';
import { useViewport } from '../hooks/useViewport';

export function ImpliedTaskListResources(props: {
    impliedTask: ImpliedTaskType;
    missionUserId: string | null;
    isReadOnly: boolean;
    hasChecklist: boolean;
    onTaskEdit: () => void;
}): JSX.Element {
    const { impliedTask, missionUserId, onTaskEdit, isReadOnly, hasChecklist } =
        props;

    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const { isTouchDevice } = useViewport();

    const resourceUpdater = useResourceUpdater(
        currentTenantId || '',
        currentFinancialYearCode || '',
        missionUserId
    );

    const { currentTheme } = useThemes();

    const [isAddIconShown, setIsAddIconShown] = useState(false);

    useEffect(() => {
        // Always show the add icon if there are no resources yet, or its a touchscreen
        if (
            !isReadOnly &&
            !hasChecklist &&
            !impliedTask.isDuplicate &&
            impliedTask.resourcedTasks &&
            (impliedTask.resourcedTasks.length === 0 || isTouchDevice)
        ) {
            setIsAddIconShown(true);
        } else if (hasChecklist) {
            setIsAddIconShown(false);
        }
    }, [
        impliedTask.resourcedTasks,
        impliedTask.isDuplicate,
        isReadOnly,
        hasChecklist,
        isTouchDevice,
    ]);

    const { loadAvailableResources } = useTaskAvailableResources(
        impliedTask,
        missionUserId
    );

    const { getStylesForResourceCoin } = useResourceCoinStyles(missionUserId);

    const handleMouseEnter =
        !isReadOnly &&
        !isTouchDevice &&
        !impliedTask.isDuplicate &&
        impliedTask.resourcedTasks &&
        (impliedTask.resourcedTasks.length || !hasChecklist)
            ? () => setIsAddIconShown(true)
            : undefined;

    const handleMouseLeave =
        !isTouchDevice && impliedTask.resourcedTasks
            ? () => {
                  if (impliedTask.resourcedTasks.length > 0) {
                      setIsAddIconShown(false);
                  }
              }
            : undefined;

    const [resourceMenuItems, setResourceMenuItems] = useState<
        IContextualMenuItem[]
    >([]);

    const personaForResource = (task: ResourceTaskType): IPersona => {
        return {
            text: task.resource?.displayName || '?',
            personaName: task.resource?.displayName || '?',
            imageUrl: task.resource?.userId
                ? photoService.getImageUrl(task.resource?.userId)
                : undefined,
            data: task,
        };
    };

    const primary = (impliedTask.resourcedTasks || []).find(
        (rt) => rt.resourceIsPrimary
    );

    const secondary = (impliedTask.resourcedTasks || []).filter(
        (rt) => !rt.resourceIsPrimary && rt.resource !== null
    );

    const maxSecondaryPersonas = 3;

    const secondaryDisplayed =
        secondary.length > maxSecondaryPersonas
            ? secondary.slice(0, maxSecondaryPersonas - 1)
            : secondary.slice();

    const secondaryOverflow =
        secondary.length > maxSecondaryPersonas
            ? secondary.slice(maxSecondaryPersonas - 1)
            : [];

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%',
        } as IRawStyle,
        secondaryResourcesContainer: {
            display: 'flex',
            alignContent: 'center',
            maxWidth: 40,
            paddingLeft: 4,
            marginRight: 4,
        },
        secondaryResourceWrapper: {
            overflow: 'hidden',
            selectors: {
                '&:last-child': { overflow: 'visible' },
            },
        },
        secondaryResource: {
            width: 12,
            minWidth: 12,
            height: 12,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1px',
            position: 'relative',
        },
    });

    const addButtonStyle: IButtonStyles = {
        root: {
            padding: 0,
            color: currentTheme.palette.themeLighter,
            selectors: {
                '&:hover': {
                    color: currentTheme.palette.themePrimary,
                },
            },
        },
    };

    const addResource = async (resourceItem: {
        resourceId: string | null;
        userId: string | null;
        displayName: string | null;
    }) => {
        const updatedResourcedTasks = [
            ...impliedTask.resourcedTasks.map((rt) => ({
                resourceId: rt.resource?.id || '',
                userId: rt.resource?.userId || '',
                displayName: rt.resource?.displayName || '',
                resourceIsPrimary: rt.resourceIsPrimary,
            })),
            {
                ...resourceItem,
                resourceIsPrimary: false,
            },
        ];

        await resourceUpdater.updateResourcedTasks(
            impliedTask,
            updatedResourcedTasks
        );
    };

    const onMenuClick = async () => {
        setResourceMenuItems([
            {
                key: 'loading',
                text: 'Loading...',
                disabled: true,
            },
        ]);

        const availableResources = await loadAvailableResources();

        const menuItems: IContextualMenuItem[] = availableResources.map(
            (ar) => ({
                key: ar.resourceId || ar.userId || '',
                text: ar.displayName || '',
                iconProps: { iconName: 'User' },
                onClick: () => {
                    addResource(ar);
                },
                onRenderIcon: () => {
                    return (
                        <PersonaCoin
                            size={PersonaSize.size24}
                            imageUrl={
                                ar.userId
                                    ? photoService.getImageUrl(ar.userId)
                                    : undefined
                            }
                            text={ar.displayName || undefined}
                        />
                    );
                },
            })
        );

        menuItems.push({
            key: 'addanother',
            text: 'Add another...',
            onClick: onTaskEdit,
        });

        setResourceMenuItems(menuItems);
    };

    return (
        <div
            className={classNames.container}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {primary !== undefined && (
                <ResourceTooltip
                    resourcedTask={{
                        ...primary,
                        resourcedFromTask: {
                            mission: {
                                userId: props.missionUserId,
                            },
                        },
                    }}
                    showStatus
                >
                    <PersonaCoin
                        {...personaForResource(primary)}
                        size={PersonaSize.size24}
                        hidePersonaDetails
                        styles={getStylesForResourceCoin(primary, true)}
                    />
                </ResourceTooltip>
            )}

            {(!!secondaryDisplayed.length || !!secondaryOverflow.length) && (
                <div className={classNames.secondaryResourcesContainer}>
                    {secondaryDisplayed.map((r) => (
                        <div
                            key={r.id}
                            className={classNames.secondaryResource}
                        >
                            <ResourceTooltip
                                resourcedTask={{
                                    ...r,
                                    resourcedFromTask: {
                                        mission: {
                                            userId: props.missionUserId,
                                        },
                                    },
                                }}
                                showStatus
                            >
                                <PersonaCoin
                                    {...personaForResource(r)}
                                    size={PersonaSize.size16}
                                    hidePersonaDetails
                                    styles={getStylesForResourceCoin(r, true)}
                                />
                            </ResourceTooltip>
                        </div>
                    ))}

                    {secondaryOverflow.length > 0 && (
                        <div className={classNames.secondaryResource}>
                            <ResourceTooltip
                                resourcedTasks={secondaryOverflow.map((t) => {
                                    return {
                                        ...t,
                                        resourcedFromTask: {
                                            mission: {
                                                userId: props.missionUserId,
                                            },
                                        },
                                    };
                                })}
                                showStatus
                            >
                                <PersonaCoin
                                    key="overflowcount"
                                    size={PersonaSize.size16}
                                    hidePersonaDetails
                                    initialsColor={
                                        currentTheme.palette.neutralSecondary
                                    }
                                    initialsTextColor={
                                        currentTheme.palette.neutralLight
                                    }
                                    imageInitials={`+${secondaryOverflow.length}`}
                                    styles={getStylesForResourceCoin(
                                        null,
                                        true
                                    )}
                                />
                            </ResourceTooltip>
                        </div>
                    )}
                </div>
            )}
            {isAddIconShown && (
                <IconButton
                    iconProps={{ iconName: 'AddFriend' }}
                    styles={addButtonStyle}
                    onRenderMenuIcon={() => {
                        return null;
                    }}
                    disabled={resourceUpdater.isBusy}
                    onMenuClick={onMenuClick}
                    menuProps={{
                        items: resourceMenuItems,
                    }}
                />
            )}
        </div>
    );
}
