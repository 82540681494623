import React from 'react';
import { Dropdown, Icon, IDropdownOption } from '@fluentui/react';
import { StatusTypes } from '../../data/types';

export function StatusTypeInput(props: {
    value?: StatusTypes | undefined | null;
    onChange: (newValue: StatusTypes) => void;
    disabled?: boolean;
}): JSX.Element {
    const iconStyles = { marginRight: '8px' };

    const onRenderOption = (option?: IDropdownOption): JSX.Element | null => {
        if (!option) {
            return null;
        }

        return (
            <div>
                {option.data && option.data.icon && (
                    <Icon
                        style={iconStyles}
                        iconName={option.data.icon}
                        aria-hidden="true"
                        title={option.data.icon}
                    />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    const onRenderTitle = (options?: IDropdownOption[]): JSX.Element | null => {
        if (!options) {
            return null;
        }

        const option = options[0];

        return (
            <div style={{ display: 'flex' }}>
                {option.data && option.data.icon && (
                    <Icon
                        style={iconStyles}
                        iconName={option.data.icon}
                        aria-hidden="true"
                        title={option.data.icon}
                    />
                )}
                <span>{option.text}</span>
            </div>
        );
    };

    return (
        <Dropdown
            disabled={props.disabled}
            selectedKey={props.value}
            options={[
                {
                    key: StatusTypes.MoreThanTarget,
                    text: 'Achieve more than target',
                    data: { icon: 'ChevronRight' },
                },
                {
                    key: StatusTypes.LessThanTarget,
                    text: 'Achieve less than target',
                    data: { icon: 'ChevronLeft' },
                },
                {
                    key: StatusTypes.TargetExact,
                    text: 'Achieve target exactly',
                    data: { icon: 'BullseyeTarget' },
                },
            ]}
            label="Status Type"
            onChange={(_ev?: React.FormEvent, option?): void => {
                props.onChange(
                    option
                        ? (option.key as StatusTypes)
                        : StatusTypes.MoreThanTarget
                );
            }}
            onRenderTitle={onRenderTitle}
            onRenderOption={onRenderOption}
        />
    );
}
