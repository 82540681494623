import React from 'react';
import { Link, mergeStyleSets, Text } from '@fluentui/react';

import { Access } from '../../../data/extendedTypes';
import { GetTasksForStatusReportQuery } from '../../../data/types';
import { sorters } from '../../../data/sorters';
import { useFormatters } from '../../../hooks/useFormatters';
import { TaskCategoryLabel } from '../../../components/TaskCategoryLabel';
import { useThemes } from '../../../hooks/useThemes';

import { ImpliedTaskListDateColumn } from '../../../components/ImpliedTaskListDateColumn';
import TaskProgressIndicator from '../../../components/TaskProgressIndicator';
import { useInlineTaskUpdater } from '../../../hooks/useInlineTaskUpdater';

export function StatusReportLinkedTasksExpandablePanel(props: {
    linkedSpecifiedTasksAndImplied: GetTasksForStatusReportQuery['tasks'];
    utcDataDate: string | null;
    utcCompletedDate: string | null;
    onNavigate: (taskId: string) => void;
    missionUserId: string | null;
    missionAccess: Access;
    isReadOnly: boolean;
}): JSX.Element {
    const { currentTheme } = useThemes();
    const { formatTaskPercentage } = useFormatters();

    const { updateTask, busyTaskIds } = useInlineTaskUpdater(
        props.missionUserId
    );

    const statusDate = props.utcDataDate || props.utcCompletedDate || undefined;

    const specifiedTasks = (props.linkedSpecifiedTasksAndImplied || [])
        .filter((t) => !t.parentTaskId)
        .filter((t) => !t.isDuplicate)
        .sort(sorters.sequenceSorter);

    const classNames = mergeStyleSets({
        container: {
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
        task: {
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            padding: 8,
            backgroundColor: currentTheme.palette.neutralLighter,
            borderRadius: 8,
            boxShadow: currentTheme.effects.elevation4,
        },
        stHeader: {
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
            justifyContent: 'space-between',
        },
        categoryAndPercent: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            textAlign: 'right',
        },
        impliedTasks: {
            marginTop: 8,
            backgroundColor: currentTheme.palette.white,
            borderRadius: 8,
            '& td, & th': {
                padding: 4,
            },
            '& tr:not(:last-child) td, & tr th': {
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: currentTheme.palette.neutralLighter,
            },
        },
        columns: {
            whiteSpace: 'nowrap',
            textAlign: 'right',
            minWidth: 80,
        },
    });

    return (
        <div className={classNames.container}>
            {specifiedTasks.map((st) => {
                const impliedTasks = (
                    props.linkedSpecifiedTasksAndImplied || []
                )
                    .filter((t) => t.parentTaskId === st.id)
                    .filter((t) => !t.isDuplicate)
                    .map((t) => {
                        return {
                            ...t,
                            resourcedFromTask: null, // This isn't needed for status reports.
                        };
                    });

                return (
                    <div key={st.id} className={classNames.task}>
                        <div className={classNames.stHeader}>
                            <Link onClick={() => props.onNavigate(st.id || '')}>
                                <Text variant="mediumPlus">{st.name}</Text>
                            </Link>
                            <div className={classNames.categoryAndPercent}>
                                <Text variant="small">
                                    {formatTaskPercentage(st.percentComplete)}
                                </Text>{' '}
                                <TaskCategoryLabel
                                    {...st?.taskCategory}
                                    hideName
                                />
                            </div>
                        </div>

                        <table className={classNames.impliedTasks}>
                            <thead>
                                <tr>
                                    <th>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: 4,
                                            }}
                                        >
                                            Implied Tasks
                                        </div>
                                    </th>
                                    <th
                                        className={classNames.columns}
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        Start
                                    </th>
                                    <th className={classNames.columns}>Due</th>
                                    <th className={classNames.columns}>Done</th>
                                </tr>
                            </thead>
                            <tbody>
                                {impliedTasks.map((it) => {
                                    const isBusy = busyTaskIds.some(
                                        (bid) => bid === it.id
                                    );

                                    const handleNavigate = () =>
                                        props.onNavigate(it.id || '');

                                    return (
                                        <tr key={it.id}>
                                            <td style={{ width: '100%' }}>
                                                <Link onClick={handleNavigate}>
                                                    {it.name}
                                                </Link>
                                            </td>
                                            <td className={classNames.columns}>
                                                <ImpliedTaskListDateColumn
                                                    task={it}
                                                    dateType={'start'}
                                                    missionAccess={
                                                        !props.isReadOnly
                                                            ? props.missionAccess
                                                            : undefined
                                                    }
                                                    allowInlineEdit={
                                                        !props.isReadOnly
                                                    }
                                                    onTaskEditClick={
                                                        handleNavigate
                                                    }
                                                    isBusy={isBusy}
                                                    onDateChanged={(date) =>
                                                        updateTask(it.id, {
                                                            ...it,
                                                            start: date,
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td className={classNames.columns}>
                                                <ImpliedTaskListDateColumn
                                                    task={it}
                                                    dateType={'due'}
                                                    missionAccess={
                                                        !props.isReadOnly
                                                            ? props.missionAccess
                                                            : undefined
                                                    }
                                                    allowInlineEdit={
                                                        !props.isReadOnly
                                                    }
                                                    onTaskEditClick={
                                                        handleNavigate
                                                    }
                                                    isBusy={isBusy}
                                                    onDateChanged={(date) =>
                                                        updateTask(it.id, {
                                                            ...it,
                                                            due: date,
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                <TaskProgressIndicator
                                                    showStatus
                                                    allowDoneDateInlineEdit={
                                                        !props.isReadOnly
                                                    }
                                                    allowPercentageInlineEdit={
                                                        !props.isReadOnly
                                                    }
                                                    allowStatusInlineEdit={
                                                        !props.isReadOnly
                                                    }
                                                    task={it}
                                                    statusDate={statusDate}
                                                    onChange={(taskUpdates) =>
                                                        updateTask(it.id, {
                                                            ...it,
                                                            ...taskUpdates,
                                                        })
                                                    }
                                                    isBusy={isBusy}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                );
            })}
        </div>
    );
}
