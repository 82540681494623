import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { ResultType as MeasureResultType } from '../components/MeasureFlatList';
import { ResultType as AlignmentResultType } from '../components/AlignmentList';
import { ExtractQueryArrayType } from '../data/extendedTypes';
import {
    GetAllTasksQuery,
    GetMissionTasksQuery,
    GetUsageStatsQuery,
} from '../data/types';
import { authService } from './auth.service';
import { IConfiguration } from './configuration.service';

export const missionAnalysisExport = async (
    configuration: IConfiguration,
    missionId: string | undefined,
    tenantId: string | undefined,
    missionOwner: string | null | undefined
): Promise<void> => {
    const host = configuration.exporterHost;
    const endpoint = configuration.importerEndpoint;
    const code = configuration.exporterFnKey;
    const token = await authService.getTokenAsync();

    const url = `${host}/api/missionAnalysisExport?endpoint=${endpoint}&tenantId=${tenantId}&missionId=${missionId}`;
    const response = await fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'x-functions-key': `${code}`,
        },
        method: 'POST',
    });
    if (response.ok) {
        const body = await response.arrayBuffer();
        const dateStr = dayjs().format('YYYY-MM-DD HH-mm-ss');
        fileDownload(
            body,
            `${missionOwner ?? 'MissionAnalysis'} Mission ${dateStr}.pptx`
        );
    } else {
        try {
            const body = await response.json();
            throw new Error(body.message);
        } catch {
            throw new Error(`${response.status}: ${response.statusText}`);
        }
    }
};

export const missionSummaryExport = async (
    configuration: IConfiguration,
    missionId: string | undefined,
    tenantId: string | undefined,
    missionOwner: string | null | undefined
): Promise<void> => {
    const host = configuration.exporterHost;
    const endpoint = configuration.importerEndpoint;
    const code = configuration.exporterFnKey;
    const token = await authService.getTokenAsync();

    const url = `${host}/api/missionSummaryExport?endpoint=${endpoint}&tenantId=${tenantId}&missionId=${missionId}`;
    const response = await fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'x-functions-key': `${code}`,
        },
        method: 'POST',
    });
    if (response.ok) {
        const body = await response.arrayBuffer();
        const dateStr = dayjs().format('YYYY-MM-DD HH-mm-ss');
        fileDownload(
            body,
            `${missionOwner ?? 'MissionAnalysis'} Mission Summary ${dateStr}.pptx`
        );
    } else {
        try {
            const body = await response.json();
            throw new Error(body.message);
        } catch {
            throw new Error(`${response.status}: ${response.statusText}`);
        }
    }
};

export type ImpliedTaskType =
    | ExtractQueryArrayType<GetMissionTasksQuery, ['tasks']>
    | ExtractQueryArrayType<GetAllTasksQuery, ['tasks']>;

export const measureListExport = async (
    configuration: IConfiguration,
    tenantId: string | undefined,
    columns: {
        name: string;
        fieldName?: string;
        minWidth?: number;
        maxWidth?: number;
    }[],
    measures: MeasureResultType[] | null
): Promise<void> => {
    const host = configuration.exporterHost;
    const endpoint = configuration.importerEndpoint;
    const code = configuration.exporterFnKey;
    const token = await authService.getTokenAsync();

    const url = `${host}/api/measureListExport?endpoint=${endpoint}&tenantId=${tenantId}`;

    const response = await fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'x-functions-key': `${code}`,
        },
        method: 'POST',
        body: JSON.stringify({ columns: columns, measures: measures }),
    });
    if (response.ok) {
        const body = await response.arrayBuffer();
        const dateStr = dayjs().format('YYYY-MM-DD HH-mm-ss');
        fileDownload(body, `Measure List ${dateStr}.xlsx`);
    } else {
        try {
            const body = await response.json();
            throw new Error(body.message);
        } catch {
            throw new Error(`${response.status}: ${response.statusText}`);
        }
    }
};

export const taskListExport = async (
    configuration: IConfiguration,
    missionId: string | undefined,
    tenantId: string | undefined,
    missionOwner: string | null | undefined,
    columns: {
        name: string;
        fieldName?: string;
        minWidth?: number;
        maxWidth?: number;
    }[],
    tasks: ImpliedTaskType[] | null
): Promise<void> => {
    const host = configuration.exporterHost;
    const endpoint = configuration.importerEndpoint;
    const code = configuration.exporterFnKey;
    const token = await authService.getTokenAsync();

    const url = `${host}/api/missionTaskListExport?endpoint=${endpoint}&tenantId=${tenantId}`;

    const response = await fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'x-functions-key': `${code}`,
        },
        method: 'POST',
        body: JSON.stringify({ columns: columns, tasks: tasks }),
    });
    if (response.ok) {
        const body = await response.arrayBuffer();
        const dateStr = dayjs().format('YYYY-MM-DD HH-mm-ss');
        fileDownload(
            body,
            `${missionOwner ?? 'MissionAnalysis'} Task List ${dateStr}.xlsx`
        );
    } else {
        try {
            const body = await response.json();
            throw new Error(body.message);
        } catch {
            throw new Error(`${response.status}: ${response.statusText}`);
        }
    }
};

export const alignmentExport = async (
    configuration: IConfiguration,
    tenantId: string | undefined,
    columns: {
        name: string;
        fieldName?: string;
        minWidth?: number;
        maxWidth?: number;
    }[],
    data: AlignmentResultType[] | null
): Promise<void> => {
    const host = configuration.exporterHost;
    const endpoint = configuration.importerEndpoint;
    const code = configuration.exporterFnKey;
    const token = await authService.getTokenAsync();

    const url = `${host}/api/alignmentExport?endpoint=${endpoint}&tenantId=${tenantId}`;

    const response = await fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'x-functions-key': `${code}`,
        },
        method: 'POST',
        body: JSON.stringify({ columns: columns, data: data }),
    });
    if (response.ok) {
        const body = await response.arrayBuffer();
        const dateStr = dayjs().format('YYYY-MM-DD HH-mm-ss');
        fileDownload(body, `Alignment Activity ${dateStr}.xlsx`);
    } else {
        try {
            const body = await response.json();
            throw new Error(body.message);
        } catch {
            throw new Error(`${response.status}: ${response.statusText}`);
        }
    }
};

export const statusReportExport = async (
    configuration: IConfiguration,
    statusReportId: string | undefined,
    tenantId: string | undefined,
    missionOwner: string | null | undefined
): Promise<void> => {
    const host = configuration.exporterHost;
    const endpoint = configuration.importerEndpoint;
    const code = configuration.exporterFnKey;
    const token = await authService.getTokenAsync();

    const url = `${host}/api/missionStatusReport?endpoint=${endpoint}&tenantId=${tenantId}&statusReportId=${statusReportId}`;
    const response = await fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'x-functions-key': `${code}`,
        },
        method: 'POST',
    });
    if (response.ok) {
        const body = await response.arrayBuffer();
        const dateStr = dayjs().format('YYYY-MM-DD HH-mm-ss');
        fileDownload(
            body,
            `${missionOwner ?? ''} Status Report ${dateStr}.pptx`
        );
    } else {
        try {
            const body = await response.json();
            throw new Error(body.message);
        } catch {
            throw new Error(`${response.status}: ${response.statusText}`);
        }
    }
};

export const templateReportExport = async (
    configuration: IConfiguration,
    templateReportId: string | undefined,
    tenantId: string | undefined,
    reportName: string | null | undefined,
    templateReportViewId: string | undefined
): Promise<void> => {
    const host = configuration.exporterHost;
    const endpoint = configuration.importerEndpoint;
    const code = configuration.exporterFnKey;
    const token = await authService.getTokenAsync();

    let url = `${host}/api/templateReport?endpoint=${endpoint}&tenantId=${tenantId}&templateReportId=${templateReportId}`;

    if (templateReportViewId) {
        url += `&templateReportViewId=${templateReportViewId}`;
    }

    const response = await fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'x-functions-key': `${code}`,
        },
        method: 'POST',
    });
    if (response.ok) {
        const body = await response.arrayBuffer();
        const dateStr = dayjs().format('YYYY-MM-DD HH-mm-ss');
        fileDownload(body, `${reportName ?? 'Report'} ${dateStr}.pptx`);
    } else {
        try {
            const body = await response.json();
            throw new Error(body.message);
        } catch {
            throw new Error(`${response.status}: ${response.statusText}`);
        }
    }
};

export const usageStatsExport = async (
    configuration: IConfiguration,
    includeLogins: boolean | undefined = false,
    data: GetUsageStatsQuery | undefined
): Promise<void> => {
    const host = configuration.exporterHost;
    const endpoint = configuration.importerEndpoint;
    const code = configuration.exporterFnKey;
    const token = await authService.getTokenAsync();
    const url = `${host}/api/tenantsWithCountsExport?endpoint=${endpoint}&includeLogins=${includeLogins}`;

    const response = await fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'x-functions-key': `${code}`,
        },
        method: 'POST',
        body: JSON.stringify(data),
    });
    if (response.ok) {
        const body = await response.arrayBuffer();
        const dateStr = dayjs().format('YYYY-MM-DD HH-mm-ss');
        fileDownload(body, `Usage Stats Report ${dateStr}.xlsx`);
    } else {
        try {
            const body = await response.json();
            throw new Error(body.message);
        } catch {
            throw new Error(`${response.status}: ${response.statusText}`);
        }
    }
};

export const multipleExport = async (
    configuration: IConfiguration,
    tenantId: string | undefined,
    exports: {
        exportType: string;
        templateReportId?: string | null;
        templateReportViewId?: string | null;
        statusReportId?: string;
        missionId: string;
    }[]
): Promise<void> => {
    const host = configuration.exporterHost;
    const endpoint = configuration.importerEndpoint;
    const code = configuration.exporterFnKey;
    const token = await authService.getTokenAsync();

    const url = `${host}/api/multipleExport?endpoint=${endpoint}&tenantId=${tenantId}`;

    const response = await fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'x-functions-key': `${code}`,
        },
        method: 'POST',
        body: JSON.stringify({ exports: exports }),
    });
    if (response.ok) {
        const body = await response.arrayBuffer();
        const dateStr = dayjs().format('YYYY-MM-DD HH-mm-ss');
        fileDownload(body, `Multiple Reports ${dateStr}.pptx`);
    } else {
        try {
            const body = await response.json();
            throw new Error(body.message);
        } catch {
            throw new Error(`${response.status}: ${response.statusText}`);
        }
    }
};

export default missionAnalysisExport;
