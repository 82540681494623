import {
    mergeStyleSets,
    Shimmer,
    ShimmerElementType,
    Text,
} from '@fluentui/react';
import { GetMeasurePeriodDataQuery } from '../../data/types';
import { ExtractQueryType } from '../../data/extendedTypes';
import { useThemes } from '../../hooks/useThemes';

export function ReportPeriodDataTargetBoxes(props: {
    periodData:
        | ExtractQueryType<
              GetMeasurePeriodDataQuery,
              ['periodData', 'measurePeriodData']
          >
        | undefined
        | null;
    ytdPeriodData?:
        | ExtractQueryType<
              GetMeasurePeriodDataQuery,
              ['periodData', 'measurePeriodData']
          >
        | undefined
        | null;
    isLoading?: boolean;
}) {
    const { periodData, ytdPeriodData, isLoading } = props;

    const { currentTheme } = useThemes();

    const classNames = mergeStyleSets({
        container: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
        },
        targetBox: {
            borderRadius: 4,
            borderRadiusTopLeft: 4,
            borderRadiusBottomRight: 4,
            paddingTop: 4,
            paddingBottom: 0,
            paddingLeft: 4,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
            borderWidth: 1,
            borderColor: currentTheme.palette.neutralLight,
            borderStyle: 'solid',
        },
        nextTargetBox: {
            backgroundColor: currentTheme.palette.neutralLight,
        },
    });

    return (
        <Shimmer
            isDataLoaded={!isLoading}
            styles={{
                dataWrapper: {
                    height: '100%',
                },
            }}
            shimmerElements={[
                {
                    type: ShimmerElementType.line,
                    height: 52,
                },
            ]}
        >
            <div className={classNames.container}>
                {!!periodData?.targetFormatted && (
                    <div className={`${classNames.targetBox}`}>
                        <Text variant="tiny" block>
                            Target ({periodData?.period})
                        </Text>
                        <Text variant="tiny" block>
                            ·
                        </Text>
                        <Text variant="tiny" block>
                            {periodData?.targetFormatted}
                        </Text>
                    </div>
                )}

                {!!ytdPeriodData?.targetFormatted && (
                    <div className={`${classNames.targetBox}`}>
                        <Text variant="tiny" block>
                            Target (YTD)
                        </Text>
                        <Text variant="tiny" block>
                            ·
                        </Text>
                        <Text variant="tiny" block>
                            {ytdPeriodData?.targetFormatted}
                        </Text>
                    </div>
                )}

                {periodData?.nextTargetFormatted && (
                    <div
                        className={`${classNames.targetBox} ${classNames.nextTargetBox}`}
                    >
                        <Text variant="tiny" block>
                            Next Target ({periodData?.nextPeriod})
                        </Text>
                        <Text variant="tiny" block>
                            ·
                        </Text>
                        <Text variant="tiny" block>
                            {periodData?.nextTargetFormatted}
                        </Text>
                    </div>
                )}
            </div>
        </Shimmer>
    );
}
