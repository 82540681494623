import React, { useEffect, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import {
    Checkbox,
    mergeStyleSets,
    PanelType,
    Separator,
} from '@fluentui/react';
import Loading from '../../../components/Loading';
import { useGetMissionTasksQuery } from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import { EditPanel } from '../../../components/shared/EditPanel';
import {
    SortableTextInputItem,
    SortableTextInputList,
} from '../../../components/SortableTextInputList';
import { Guid } from 'guid-typescript';
import { sorters } from '../../../data/sorters';
import ExpandCollapse from '../../../components/ExpandCollapse';

export function TaskPickerPanel(props: {
    isOpen: boolean;
    onDismiss: () => void;
    missionId: string;
    selectedTaskIds: string[];
    customFacts: string[];
    onUpdateClick?: (
        selectedTaskIds: string[],
        customFacts: string[]
    ) => void | Promise<void>;
}) {
    const { currentTenantId } = useStateContext();

    const { loading, data } = useGetMissionTasksQuery({
        skip: !props.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId,
        },
    });

    const taskData = data?.tasks;

    const specifiedTasks = useMemo(
        () =>
            orderBy(
                taskData?.filter(
                    (t) => t.parentTaskId === null && !t.isDuplicate
                ),
                'sequence'
            ),
        [taskData]
    );

    const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([]);
    const [expandedTaskIds, setExpandedTaskIds] = useState<string[]>([]);

    const [customFacts, setCustomFacts] = useState<SortableTextInputItem[]>([]);

    useEffect(() => {
        if (props.isOpen) {
            setSelectedTaskIds(props.selectedTaskIds);
            setExpandedTaskIds(props.selectedTaskIds);
            setCustomFacts(
                props.customFacts.map((cf, index) => {
                    return {
                        id: Guid.create().toString(),
                        isDeleted: false,
                        isNew: false,
                        sequence: index,
                        name: cf,
                        version: null,
                    };
                })
            );
        }
    }, [props.customFacts, props.selectedTaskIds, props.isOpen]);

    const handleCustomFactsChanged = (updated: SortableTextInputItem[]) => {
        setCustomFacts(updated);
    };

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            marginTop: 16,
        },
        specifiedContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 4,
        },
        impliedContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            marginTop: 4,
            marginBottom: 16,
            paddingLeft: 64,
        },
    });

    const taskChecked = (taskId: string | null, checked?: boolean) => {
        let updated = selectedTaskIds.filter((id) => id !== taskId);
        if (checked && taskId) {
            updated.push(taskId);
        }

        const isSpecified = specifiedTasks.some((st) => st.id === taskId);

        // If they are unchecking a specified task, deselect and implied beneath.
        if (isSpecified && !checked) {
            const impliedIds = taskData
                ?.filter((t) => t.parentTaskId === taskId)
                .map((it) => it.id);
            updated = updated.filter((id) => impliedIds?.indexOf(id) === -1);
        }
        // If they are checking an implied task, select the parent specified task
        else if (!isSpecified && checked) {
            const specifiedTaskId = taskData?.find(
                (it) => it.id === taskId
            )?.parentTaskId;

            if (specifiedTaskId && updated.indexOf(specifiedTaskId) === -1) {
                updated.push(specifiedTaskId);
            }
        }

        setSelectedTaskIds(updated);
    };

    return (
        <EditPanel
            showPanel={props.isOpen}
            panelType={PanelType.medium}
            saveErrorMessage={undefined}
            isSaving={false}
            isValid={true}
            activeViewName={null}
            headerText="Tasks"
            {...props}
            onUpdateClick={() => {
                if (props.onUpdateClick) {
                    props.onUpdateClick(
                        selectedTaskIds,
                        customFacts
                            .sort(sorters.sequenceSorter)
                            .filter((u) => !u.isDeleted)
                            .map((u) => u.name.trim())
                    );
                }
            }}
        >
            {loading && <Loading />}

            <div className={classNames.container}>
                {specifiedTasks?.map((st) => {
                    const impliedTasks = orderBy(
                        taskData?.filter(
                            (t) => t.parentTaskId === st.id && !t.isDuplicate
                        ),
                        'sequence'
                    );

                    const isExpanded = expandedTaskIds.some(
                        (id) => id === st.id
                    );

                    const onExpand = () => {
                        const updated = expandedTaskIds.filter(
                            (expandedId) => expandedId !== st.id
                        );
                        if (st.id) {
                            setExpandedTaskIds([...updated, st.id]);
                        }
                    };

                    const onCollapse = () => {
                        const updated = expandedTaskIds.filter(
                            (expandedId) => expandedId !== st.id
                        );
                        setExpandedTaskIds([...updated]);
                    };

                    return (
                        <div key={st.id}>
                            <div className={classNames.specifiedContainer}>
                                <ExpandCollapse
                                    isExpanded={isExpanded}
                                    onExpand={onExpand}
                                    onCollapse={onCollapse}
                                />
                                <Checkbox
                                    label={st.name || st.id || ''}
                                    checked={selectedTaskIds.some(
                                        (id) => id === st.id
                                    )}
                                    onChange={(_ev, checked) =>
                                        taskChecked(st.id, checked)
                                    }
                                    styles={{
                                        label: {
                                            fontWeight: 'bold',
                                        },
                                    }}
                                />
                            </div>
                            {isExpanded && (
                                <div className={classNames.impliedContainer}>
                                    {impliedTasks.map((it) => {
                                        return (
                                            <Checkbox
                                                key={it.id}
                                                label={it.name || it.id || ''}
                                                checked={selectedTaskIds.some(
                                                    (id) => id === it.id
                                                )}
                                                onChange={(_ev, checked) =>
                                                    taskChecked(it.id, checked)
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            <Separator />

            <h4>Custom Facts</h4>

            <SortableTextInputList
                isLoading={loading}
                isReadOnly={false}
                multiline
                inputItems={customFacts}
                onChange={handleCustomFactsChanged}
                addButtonText="Add Custom Fact"
            />
        </EditPanel>
    );
}
