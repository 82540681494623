import dayjs from 'dayjs';
import { ExtractQueryArrayType } from '../data/extendedTypes';

import {
    useUpdateTaskMutation,
    Task,
    useDeleteTaskMutation,
    GetTaskQuery,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';

export type WarningTask = {
    id: string | null;
    missionId: string | null;
    parentTaskId: string | null;
    name: string | null;
    description: string | null;
    percentComplete: number;
    start: string | null;
    due: string | null;
    done: string | null;
    review: string | null;
    resourcedFromTaskId: string | null;
    resourceId: string | null;
    utcAccepted: string | null;
    utcRejected: string | null;
    rejectedReason: string | null;
    utcResourceRemoved: string | null;
    utcChangesPending: string | null;
    utcCancelled: string | null;
    utcPostponed: string | null;
    utcAtRisk: string | null;
    isPercentageCompleteFromResources: boolean;
    isPercentageCompleteFromSubTasks: boolean;
    sequence: number;
    resourceIsPrimary: boolean;
    effortWeight: number;
    effortResourceWeight: number;
    costWeight: number;
    isDuplicate: boolean;
    taskCategoryId: string | null;
    version: string | null;
    linkedMeasures: Array<{
        id: string | null;
        measureId: string;
        taskId: string;
    }>;
    resourcedTasks: Array<{
        id: string | null;
        utcRejected: string | null;
        rejectedReason: string | null;
        resourceIsPrimary: boolean;
        resource: {
            displayName: string | null;
        } | null;
    }>;
    resourcedFromTask: {
        id: string | null;
        name: string | null;
        start: string | null;
        due: string | null;
        done: string | null;
        utcCancelled: string | null;
        utcPostponed: string | null;
        utcAtRisk: string | null;
        mission: {
            id: string | null;
            userId: string | null;
            title: string | null;
            owner: string | null;
            utcInactive: string | null;
            utcDeleted: string | null;
        } | null;
    } | null;
};

type TaskInput = ExtractQueryArrayType<GetTaskQuery, ['task']>;

export type TaskWarningAction = {
    name: string;
    executeAction: (() => Promise<void>) | null;
    updateInput?: (task: TaskInput) => TaskInput; // Update an input rather than perform the change
    isSelected: boolean;
    isDestructive: boolean; // Will this cause the task to be deleted?
    isRefetchRequired: boolean; // Will this action cause a change to the task data?
    disabled?: boolean;
};

export type TaskWarning = {
    hasWarning: boolean;
    warningMessage: string | null;
    warningDetail?: string | null;
    actions: TaskWarningAction[];
    isBlocking: boolean; // The use will need to address this before progressing.
    date: string | null;
    createNotification?: (taskLink: JSX.Element) => (JSX.Element | string)[];
    enableMerge: boolean;
    mergeFields?: string[];
    dismissMerge?: (fieldName: string) => void;
};

type WarningEditOptions = {
    onOpenEditTask?: () => void;
    isEditing: boolean;
    editingResources?: {
        task:
            | {
                  utcRejected?: string | null;
              }
            | null
            | undefined;
        requestAgain?: boolean;
    }[];
    onRequestAgainClicked?: () => void;
};

function getWarningForTask(
    task: WarningTask | null,
    warningEditOptions: WarningEditOptions,
    onUpdateTask: (taskInput: Task) => Promise<void>,
    onDeleteTask: (taskId: string) => Promise<void>,
    onHandleDeleteResource: (taskId: string) => Promise<void>
): TaskWarning {
    if (!task) {
        return {
            hasWarning: false,
            warningMessage: null,
            actions: [],
            isBlocking: false,
            date: '',
            enableMerge: false,
        };
    }

    const getDateForInput = (date: string | null) =>
        date ? dayjs(date).format('YYYY-MM-DD') : null;

    const getTaskInput = (): Task | null => {
        if (!task) {
            return null;
        }
        return {
            id: task?.id,
            missionId: task?.missionId,
            name: task?.name,
            description: task?.description,
            percentComplete: task?.percentComplete,
            parentTaskId: task?.parentTaskId,
            resourcedFromTaskId: task?.resourcedFromTaskId,
            start: getDateForInput(task?.start),
            due: getDateForInput(task?.due),
            done: getDateForInput(task?.done),
            review: getDateForInput(task?.review),
            utcAccepted: task?.utcAccepted,
            utcRejected: task?.utcRejected,
            rejectedReason: task?.rejectedReason,
            resourceId: task.resourceId,
            utcResourceRemoved: task.utcResourceRemoved,
            utcChangesPending: task.utcChangesPending,
            utcCancelled: task.utcCancelled,
            utcPostponed: task.utcPostponed,
            utcAtRisk: task.utcAtRisk,
            isPercentageCompleteFromResources:
                task?.isPercentageCompleteFromResources,
            isPercentageCompleteFromSubTasks:
                task?.isPercentageCompleteFromSubTasks,
            sequence: task?.sequence,
            resource: null,
            taskCategoryId: task?.taskCategoryId,
            linkedMeasures: task?.linkedMeasures,
            resourceIsPrimary: task?.resourceIsPrimary,
            effortWeight: task?.effortWeight,
            effortResourceWeight: task?.effortResourceWeight,
            costWeight: task?.costWeight,
            isDuplicate: task?.isDuplicate,
            version: task?.version,
        };
    };

    if (task.utcResourceRemoved) {
        const actions: TaskWarningAction[] = [];
        actions.push({
            name: task.isDuplicate ? 'Unmerge' : 'Remove Task',
            isSelected: task.isDuplicate,
            executeAction: () => onDeleteTask(task.id || ''),
            isDestructive: true,
            isRefetchRequired: false,
        });

        if (!task.isDuplicate) {
            actions.push({
                name: 'Keep Task',
                isSelected: true,
                executeAction: async (): Promise<void> => {
                    const inputTask = getTaskInput();
                    if (inputTask) {
                        inputTask.utcResourceRemoved = null;
                        await onUpdateTask(inputTask);
                    }
                },
                isDestructive: false,
                isRefetchRequired: true,
            });
        }

        return {
            hasWarning: true,
            enableMerge: false,
            warningMessage: `You have been removed as a resource or the original task has been deleted.`,
            createNotification: (taskLink: JSX.Element) => {
                return [
                    'The task ',
                    taskLink,
                    ' has been removed as a resource or the original task has been deleted.',
                ];
            },
            date: task.utcResourceRemoved || '',
            actions: actions,
            isBlocking: true,
        };
    }

    if (
        task.resourcedFromTask?.mission?.utcInactive ||
        task.resourcedFromTask?.mission?.utcDeleted
    ) {
        const actions: TaskWarningAction[] = [];
        actions.push({
            name: task.isDuplicate ? 'Unmerge' : 'Remove Task',
            isSelected: task.isDuplicate,
            executeAction: () => onDeleteTask(task.id || ''),
            isDestructive: true,
            isRefetchRequired: false,
        });

        if (!task.isDuplicate) {
            actions.push({
                name: 'Keep Task',
                isSelected: true,
                executeAction: async (): Promise<void> => {
                    const inputTask = getTaskInput();
                    if (inputTask) {
                        inputTask.resourcedFromTaskId = null;
                        await onUpdateTask(inputTask);
                    }
                },
                isDestructive: false,
                isRefetchRequired: true,
            });
        }

        return {
            hasWarning: true,
            enableMerge: false,
            warningMessage: `The mission that requested this task has been removed.`,
            createNotification: (taskLink: JSX.Element) => {
                return [
                    'The mission that requested ',
                    taskLink,
                    ' has been removed.',
                ];
            },
            date:
                task.resourcedFromTask?.mission?.utcInactive ||
                task.resourcedFromTask?.mission?.utcDeleted,
            actions: actions,
            isBlocking: true,
        };
    }

    if (task.utcAccepted && task.resourcedFromTask && task.utcChangesPending) {
        const mergeFields = [];

        if (task.name !== task.resourcedFromTask.name) {
            mergeFields.push('name');
        }

        // Check for dates to merge on all mission tasks, but not duplicates.
        if (task.missionId && !task.isDuplicate) {
            if (task.start !== task.resourcedFromTask.start) {
                mergeFields.push('start');
            }

            if (task.due !== task.resourcedFromTask.due) {
                mergeFields.push('due');
            }

            // Only merge done dates on tasks that haven't been completed.
            if (task.done !== task.resourcedFromTask.done && !task.done) {
                mergeFields.push('done');
            }

            if (
                task.utcCancelled !== task.resourcedFromTask.utcCancelled ||
                task.utcPostponed !== task.resourcedFromTask.utcPostponed ||
                task.utcAtRisk !== task.resourcedFromTask.utcAtRisk
            ) {
                mergeFields.push('status');
            }
        }

        if (mergeFields.length > 0) {
            return {
                hasWarning: true,
                enableMerge: true,
                mergeFields: mergeFields,
                warningMessage: `A task you have accepted has changed. Please review and incorporate the changes if needed.`,
                createNotification: (taskLink: JSX.Element) => {
                    return [
                        'The task ',
                        taskLink,
                        ' has pending changes. Please review and incorporate the changes if needed.',
                    ];
                },
                date: task.utcChangesPending || '',
                actions: [],
                isBlocking: false,
            };
        }
    }

    if (task.utcAccepted && task.resourcedFromTask?.due && task.due) {
        const diffDays = dayjs(task.resourcedFromTask?.due).diff(
            dayjs(task.due),
            'day'
        );

        if (diffDays < 0) {
            const formattedDueDate = dayjs
                .utc(task?.resourcedFromTask?.due)
                .format('Do MMM YYYY');

            const warning: TaskWarning = {
                hasWarning: true,
                enableMerge: false,
                date: '',
                warningMessage: `Due Date Inconsistency – ${task.resourcedFromTask.mission?.owner} set this to be completed by ${formattedDueDate}.`,
                actions: [],
                isBlocking: false,
            };

            if (task?.parentTaskId && !task.isDuplicate) {
                warning.actions.push({
                    name: `Change due date to ${formattedDueDate}`,
                    isSelected: true,
                    executeAction: async (): Promise<void> => {
                        const inputTask = getTaskInput();
                        if (inputTask) {
                            inputTask.due = getDateForInput(
                                task.resourcedFromTask?.due || null
                            );
                            await onUpdateTask(inputTask);
                        }
                    },
                    updateInput: (input: TaskInput): TaskInput => {
                        return {
                            ...input,
                            due: task.resourcedFromTask?.due || null,
                        };
                    },
                    isDestructive: false,
                    isRefetchRequired: true,
                });
            }

            return warning;
        }
    }

    const rejected = task.resourcedTasks.filter((tr) => tr.utcRejected);

    if (rejected.length) {
        const warning: TaskWarning = {
            hasWarning: true,
            enableMerge: false,
            createNotification: (taskLink: JSX.Element) => {
                return rejected.length > 1
                    ? [
                          'The task ',
                          taskLink,
                          ' was rejected by multiple resources.',
                      ]
                    : [
                          'The task ',
                          taskLink,
                          ` was rejected by ${
                              rejected[0].resource?.displayName || 'a resource'
                          }`,
                      ];
            },
            date: rejected[0].utcRejected,
            warningMessage:
                rejected.length > 1
                    ? `This task was rejected by multiple resources.`
                    : `This task was rejected by ${
                          rejected[0].resource?.displayName || 'a resource'
                      }`,
            warningDetail:
                rejected.length > 1
                    ? rejected
                          .map(
                              (r) =>
                                  `${r.resource?.displayName}: "${r.rejectedReason}"`
                          )
                          .join('\n\n')
                    : `Reason: "${rejected[0].rejectedReason}"`,
            actions: [],
            isBlocking: false,
        };

        const removeAction: TaskWarningAction = {
            name: rejected.length > 1 ? 'Remove resources' : 'Remove resource',
            isDestructive: false,
            executeAction: async () => {
                for (const rejectedTask of rejected) {
                    if (rejectedTask.id) {
                        await onHandleDeleteResource(rejectedTask.id);
                    }
                }
            },
            isSelected: false,
            isRefetchRequired: true,
        };

        if (
            !warningEditOptions.isEditing &&
            warningEditOptions.onOpenEditTask
        ) {
            warning.actions.push({
                name: 'Edit task / Request again',
                isDestructive: false,
                executeAction: async () => {
                    if (warningEditOptions.onOpenEditTask) {
                        warningEditOptions.onOpenEditTask();
                    }
                },
                isSelected: true,
                isRefetchRequired: true,
            });

            warning.actions.push(removeAction);
        } else if (
            warningEditOptions.isEditing &&
            warningEditOptions.editingResources?.some((r) => r.requestAgain) &&
            !warningEditOptions.editingResources?.some(
                (r) => r.task?.utcRejected && !r.requestAgain
            )
        ) {
            warning.actions.push({
                name: 'Requests will be sent on save',
                disabled: true,
                isDestructive: false,
                executeAction: null,
                isSelected: false,
                isRefetchRequired: false,
            });
        } else if (
            warningEditOptions.isEditing &&
            warningEditOptions.onRequestAgainClicked
        ) {
            warning.actions.push({
                name: 'Request again',
                isDestructive: false,
                executeAction: async () => {
                    if (warningEditOptions.onRequestAgainClicked) {
                        warningEditOptions.onRequestAgainClicked();
                    }
                },
                isSelected: true,
                isRefetchRequired: false,
            });

            warning.actions.push(removeAction);
        } else {
            warning.actions.push(removeAction);
        }

        return warning;
    }

    return {
        enableMerge: false,
        hasWarning: false,
        warningMessage: null,
        actions: [],
        isBlocking: false,
        date: '',
    };
}

export const useTaskWarnings = (
    tasks: WarningTask[],
    onOpenEditTask?: (taskId: string) => void
): (TaskWarning & { taskId: string; missionId: string | null })[] => {
    const taskWarnings: (TaskWarning & {
        taskId: string;
        missionId: string | null;
    })[] = [];

    const { currentTenantId } = useStateContext();

    const [updateTaskMutation] = useUpdateTaskMutation();

    const [deleteTaskMutation] = useDeleteTaskMutation();

    const handleUpdateTask = async (inputTask: Task) => {
        await updateTaskMutation({
            variables: {
                tenantId: currentTenantId || '',
                input: inputTask,
            },
        });
    };

    const handleDeleteTask = async (taskId: string) => {
        await deleteTaskMutation({
            variables: {
                tenantId: currentTenantId || '',
                id: taskId || '',
                isTaskResource: false,
                restore: false,
            },
            refetchQueries: ['GetMissionTasks'],
        });
    };

    const handleDeleteResource = async (taskId: string) => {
        await deleteTaskMutation({
            variables: {
                tenantId: currentTenantId || '',
                id: taskId || '',
                isTaskResource: true,
                restore: false,
            },
            refetchQueries: ['GetMissionTasks'],
        });
    };

    tasks.forEach((t) => {
        const warning = getWarningForTask(
            t,
            {
                isEditing: false,
                onOpenEditTask: () =>
                    t.id && onOpenEditTask ? onOpenEditTask(t.id) : undefined,
            },
            handleUpdateTask,
            handleDeleteTask,
            handleDeleteResource
        );
        if (t.id && warning.hasWarning) {
            taskWarnings.push({
                taskId: t.id,
                missionId: t.missionId,
                ...warning,
            });
        }
    });
    return taskWarnings;
};

export const useTaskWarning = (
    task: WarningTask | null,
    warningEditOptions: WarningEditOptions
): TaskWarning => {
    const { currentTenantId } = useStateContext();

    const [updateTaskMutation] = useUpdateTaskMutation();

    const [deleteTaskMutation] = useDeleteTaskMutation();

    const handleUpdateTask = async (inputTask: Task) => {
        await updateTaskMutation({
            variables: {
                tenantId: currentTenantId || '',
                input: inputTask,
            },
        });
    };

    const handleDeleteTask = async (taskId: string) => {
        await deleteTaskMutation({
            variables: {
                tenantId: currentTenantId || '',
                id: taskId || '',
                isTaskResource: false,
                restore: false,
            },
            refetchQueries: ['GetMissionTasks'],
        });
    };

    const handleDeleteResource = async (taskId: string) => {
        await deleteTaskMutation({
            variables: {
                tenantId: currentTenantId || '',
                id: taskId || '',
                isTaskResource: true,
                restore: false,
            },
            refetchQueries: ['GetMissionTasks'],
        });
    };

    return getWarningForTask(
        task,
        warningEditOptions,
        handleUpdateTask,
        handleDeleteTask,
        handleDeleteResource
    );
};
