import { PrimaryButton, ThemeProvider } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useThemes } from '../../hooks/useThemes';

export function HelpDownload(): JSX.Element {
    const DefaultSection = '_contents';

    const { currentTheme } = useThemes();

    const [contentsMarkdown, setContentsMarkdown] = useState<string>('');

    const links = useExtractHelpLinks(contentsMarkdown);

    const loadHelpContent = useCallback(async (): Promise<string> => {
        let markdown = '# HELP\n\n';
        for (const l of links) {
            try {
                const { default: linkMarkdown } = await import(
                    `../../help/${l}.md`
                );
                if (linkMarkdown) {
                    markdown += linkMarkdown + '\n\n';
                }
            } catch (error) {
                console.error(`Error loading ${l}.md`, error);
                markdown += `\n\n## Error loading ${l}.md\n\n`;
            }
        }

        markdown = markdown.replace(/:([a-zA-Z0-9]+):/g, () => '');

        return markdown;
    }, [links]);

    const loadContents = useCallback(async () => {
        const { default: markdown } = await import(
            `../../help/${DefaultSection}.md`
        );
        setContentsMarkdown(markdown);
    }, []);

    useEffect(() => {
        loadContents();
    }, [loadContents]);

    const handleDownload = async () => {
        const markdownContent = await loadHelpContent();

        const blob = new Blob([markdownContent], { type: 'text/markdown' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'advance-help.md';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); // Clean up the URL object
    };

    return (
        <ThemeProvider
            theme={currentTheme}
            style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <PrimaryButton
                onClick={handleDownload}
                disabled={!contentsMarkdown}
            >
                Download Markdown
            </PrimaryButton>
        </ThemeProvider>
    );
}

function useExtractHelpLinks(markdown: string | null | undefined): string[] {
    const [linkTexts, setLinkTexts] = useState<string[]>([]);

    useEffect(() => {
        if (!markdown) {
            return;
        }
        const regex = /\[.*?\]\(\/help\/(.+?)\)/g;
        const matches: string[] = [];
        let match: RegExpExecArray | null;
        while ((match = regex.exec(markdown)) !== null) {
            if (match[1]) {
                matches.push(match[1]);
            }
        }
        setLinkTexts(matches);
    }, [markdown]);

    return linkTexts;
}
