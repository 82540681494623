import * as Msal from '@azure/msal-browser';
import { customAuth } from './customAuth';
import { configurationService, IConfiguration } from './configuration.service';
export class User {
    public displayName = '';
    public emailAddress = '';
    public idp: string | null | undefined = undefined;
    public account: Msal.AccountInfo | undefined = undefined;
}
class AuthService {
    authorityBaseUrl =
        'https://advancedashboard.b2clogin.com/advancedashboard.onmicrosoft.com/';

    applicationConfig = {
        clientId: 'b6dff74c-335d-4baf-8ff7-b01713f648ee',
        knownAuthorities: ['advancedashboard.b2clogin.com'],
        b2cScopes: [
            'https://advancedashboard.onmicrosoft.com/api/user_impersonation',
        ],
        cacheLocation: 'sessionStorage',
    };

    clientApplication?: Msal.PublicClientApplication = undefined;

    configuration?: IConfiguration = undefined;

    isIE =
        window.navigator.userAgent.indexOf('MSIE ') > -1 ||
        window.navigator.userAgent.indexOf('Trident/') > -1;

    private async getConfigurationAsync(): Promise<IConfiguration> {
        if (!this.configuration) {
            this.configuration =
                await configurationService.getConfigurationAsync();
        }
        return this.configuration;
    }

    private async getAuthorityAsync(
        authorityType:
            | 'Local'
            | 'External'
            | 'PasswordReset'
            | 'PasswordChange'
            | 'Unknown'
    ): Promise<string> {
        const config = await this.getConfigurationAsync();
        switch (authorityType) {
            case 'Local':
            case 'Unknown':
                return `${this.authorityBaseUrl}${config.localSignupSignInPolicy}`;
            case 'External':
                return `${this.authorityBaseUrl}${config.externalSignupSignInPolicy}`;
            case 'PasswordReset':
                return `${this.authorityBaseUrl}${config.localPasswordResetPolicy}`;
            case 'PasswordChange':
                return `${this.authorityBaseUrl}${config.localPasswordChangePolicy}`;
        }
    }

    private async getClientApplicationAsync(
        authority?: string,
        navigateToLoginRequestUrl?: boolean
    ): Promise<Msal.PublicClientApplication> {
        if (!this.clientApplication) {
            const config: Msal.Configuration = {
                auth: {
                    clientId: this.applicationConfig.clientId,
                    authority:
                        authority || (await this.getAuthorityAsync('Unknown')),
                    knownAuthorities: this.applicationConfig.knownAuthorities,
                    redirectUri: window.location.origin,
                    navigateToLoginRequestUrl: navigateToLoginRequestUrl,
                },
                cache: {
                    cacheLocation: this.applicationConfig.cacheLocation,
                    storeAuthStateInCookie: this.isIE,
                    secureCookies: true,
                    claimsBasedCachingEnabled: true,
                },
                system: {
                    loggerOptions: {
                        //logLevel: Msal.LogLevel.Verbose,
                        //loggerCallback: this.loggerCallback,
                    },
                    loadFrameTimeout: 30,
                },
            };

            // initialize the MSAL JS with a configuration object
            this.clientApplication = new Msal.PublicClientApplication(config);
            await this.clientApplication.initialize();

            await this.clientApplication
                .handleRedirectPromise()
                .catch(async (error) => {
                    // This is a forgotten password request
                    // AADB2C90118: The user has forgotten their password.
                    if (
                        error.errorCode === 'access_denied' &&
                        error.errorMessage.indexOf('AADB2C90118') > -1
                    ) {
                        // Possibly better handled in the template: https://stackoverflow.com/a/58925712
                        const authority =
                            await this.getAuthorityAsync('PasswordReset');
                        if (authority) {
                            await this.redirectToFlowAsync(authority);
                        }
                    } else {
                        console.log(error);
                    }
                });
        }

        return this.clientApplication;
    }

    // a person is logged in if UserAgentApplication has a current user, if there is an id token in the cache, and if the token in the cache is not expired
    public isLoggedInAsync = async (): Promise<boolean> => {
        const clientApp = await this.getClientApplicationAsync();

        if (clientApp != null) {
            const potentialLoggedInUsers = clientApp.getAllAccounts();
            return potentialLoggedInUsers.length > 0;
        }

        return false;
    };

    // If there is a spcific login provider for this tenant code, redirect.
    public redirectToSpecificLoginProviderAsync = async (
        tenantCode: string,
        issuer: string | null
    ): Promise<void> => {
        if (issuer) {
            const authDetails = customAuth.getAuthorityForIssuer(issuer);
            if (authDetails) {
                const authority = await this.getAuthorityAsync(
                    authDetails.authorityType
                );
                await this.redirectToFlowAsync(
                    authority,
                    undefined,
                    authDetails.domainHint
                );
                return;
            }
        }

        const emailAddress = customAuth.getEmailSuffixForTenant(tenantCode);

        if (emailAddress) {
            const authorityType =
                customAuth.getAuthorityTypeForEmail(emailAddress);
            const authority = await this.getAuthorityAsync(authorityType);
            const domainHint = customAuth.getDomainHintForEmail(emailAddress);

            if (authority) {
                await this.redirectToFlowAsync(
                    authority,
                    emailAddress,
                    domainHint
                );
            }
        }
    };

    public redirectToLoginAsync = async (
        emailAddress: string,
        redirectUri?: string
    ): Promise<void> => {
        if (this.clientApplication) {
            this.clientApplication = undefined;
        }

        const authorityType = customAuth.getAuthorityTypeForEmail(emailAddress);
        const authority = await this.getAuthorityAsync(authorityType);
        const domainHint = customAuth.getDomainHintForEmail(emailAddress);

        if (authority) {
            await this.redirectToFlowAsync(
                authority,
                emailAddress,
                domainHint,
                redirectUri
            );
        }
    };

    public redirectToFlowAsync = async (
        authority: string,
        emailAddress?: string,
        domainHint?: string,
        redirectUri?: string
    ): Promise<void> => {
        const loginRequest: Msal.RedirectRequest = {
            scopes: this.applicationConfig.b2cScopes,
            loginHint: emailAddress,
            authority: authority,
            domainHint: domainHint,
            redirectUri: redirectUri,
        };

        const clientApp = redirectUri
            ? await this.getClientApplicationAsync(authority, false)
            : await this.getClientApplicationAsync(authority);

        await clientApp.loginRedirect(loginRequest);
    };

    public logoutAsync = async (): Promise<void> => {
        const clientApp = await this.getClientApplicationAsync();
        await clientApp.logoutRedirect();
    };

    public redirectToChangePasswordAsync = async (): Promise<void> => {
        const authority = await this.getAuthorityAsync('PasswordChange');
        const user = this.getUser();
        await this.redirectToFlowAsync(authority, user?.emailAddress);
    };

    public getUser = (): User | null => {
        const clientUsers = this.clientApplication?.getAllAccounts();

        if (clientUsers?.length) {
            type claims = {
                idp?: string | null | undefined;
                email?: string | null | undefined;
            };

            // After a password change we need to make sure we user the user that has the login details.
            const clientUser: Msal.AccountInfo =
                clientUsers.find(
                    (u) => (u.idTokenClaims as claims)?.email || u.username
                ) || clientUsers[0];

            const tokenClaims = clientUser.idTokenClaims as claims;
            const user = new User();

            user.displayName = clientUser.name || clientUser.username;
            user.emailAddress = tokenClaims?.email || clientUser.username;
            user.idp = tokenClaims?.idp;
            user.account = clientUser;

            return user;
        }

        return null;
    };

    public async getAuthorisationAsync(
        redirectUri?: string
    ): Promise<Msal.AuthenticationResult | null> {
        const clientApp = redirectUri
            ? await this.getClientApplicationAsync(undefined, false)
            : await this.getClientApplicationAsync();

        const user = this.getUser();

        if (!user) {
            throw 'User is null or undefined';
        }

        const authorityType = customAuth.getAuthorityTypeForEmail(
            user?.emailAddress
        );

        const authority = await this.getAuthorityAsync(authorityType);

        let redirectRequired = false;

        const response = await clientApp
            .acquireTokenSilent({
                scopes: this.applicationConfig.b2cScopes,
                authority: authority,
                account: user?.account,
                redirectUri: redirectUri,
            })
            .catch((error) => {
                if (
                    error.errorCode === 'consent_required' ||
                    error.errorCode === 'interaction_required' ||
                    error.errorCode === 'login_required' ||
                    error.errorCode === 'invalid_grant' ||
                    error.errorCode === 'monitor_window_timeout'
                ) {
                    redirectRequired = true;
                }
            });

        if (redirectRequired) {
            const domainHint = customAuth.getDomainHintForEmail(
                user?.emailAddress
            );

            const accessTokenRequest: Msal.RedirectRequest = {
                scopes: this.applicationConfig.b2cScopes,
                authority: authority,
                domainHint: domainHint,
                loginHint: user?.emailAddress,
                redirectUri: redirectUri,
            };

            await clientApp.acquireTokenRedirect(accessTokenRequest);
        } else if (response) {
            return response;
        }

        return null;
    }

    public async getTokenAsync(): Promise<string | null> {
        const auth = await this.getAuthorisationAsync();
        return auth ? auth.accessToken : null;
    }

    protected loggerCallback(logLevel: Msal.LogLevel, message: string): void {
        console.log(message);
    }
}

export const authService = new AuthService();
