import React, { useEffect, useState } from 'react';
import {
    ChoiceGroup,
    FontSizes,
    IChoiceGroupOptionStyles,
    mergeStyleSets,
    Text,
    Toggle,
} from '@fluentui/react';
import {
    FrequencyPeriods,
    GetMeasureQuery,
    MeasureTypes,
    TargetTypes,
    useUpdateMeasureMutation,
    ValueTypes,
} from '../../../data/types';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import { EditPanel } from '../../../components/shared/EditPanel';
import { ValueInput } from '../../../components/inputs';
import dayjs from 'dayjs';
import { useInputMappers } from '../../../hooks/useInputMappers';
import { useStateContext } from '../../../services/contextProvider';

export function MeasureFullYearTargetPanel(props: {
    measure: ExtractQueryArrayType<GetMeasureQuery, ['measure']>;
    showPanel: boolean;
    onDismiss: () => void;
}) {
    const { measure } = props;

    const { getMeasureInput } = useInputMappers();

    const { currentTenantId } = useStateContext();

    const [valueInput, setValueInput] = useState<{
        decimalValue: number | null;
        stringValue: string | null;
        dateValue: string | null;
        isFullYearTarget: boolean;
        isNone: boolean;
        targetType: TargetTypes;
        valueType: ValueTypes;
    }>();

    const [hasSaved, setHasSaved] = React.useState(false);

    const [updateMeasure, { loading: isSaving, error: saveError }] =
        useUpdateMeasureMutation({
            onCompleted: () => {
                setHasSaved(true);
            },
        });

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 8,
            gap: 16,
        },
    });

    useEffect(() => {
        if (props.showPanel) {
            setHasSaved(false);
            setValueInput({
                decimalValue: measure.fullYearTarget,
                dateValue:
                    measure.measureType === MeasureTypes.Date
                        ? dayjs(measure.fullYearString).format('DD MMM YYYY')
                        : null,
                stringValue: measure.fullYearString,
                isFullYearTarget: measure.isFullYearTarget,
                isNone:
                    measure.fullYearString === null &&
                    measure.fullYearTarget === null,
                targetType: measure.targetType,
                valueType: measure.valueType,
            });
        }
    }, [measure, props.showPanel]);

    const handleUpdateClick = async () => {
        if (valueInput) {
            // if ()
            const input = getMeasureInput(measure);
            await updateMeasure({
                variables: {
                    input: {
                        ...input,
                        fullYearTarget: !valueInput?.isNone
                            ? valueInput.decimalValue
                            : null,
                        fullYearString: !valueInput?.isNone
                            ? valueInput.dateValue ||
                              valueInput.stringValue ||
                              null
                            : null,
                        isFullYearTarget: valueInput.isFullYearTarget,
                        targetType: valueInput.targetType,
                        valueType: valueInput.valueType,
                    },
                    tenantId: currentTenantId || '',
                },
            });
        }

        props.onDismiss();
    };

    const isNumberMeasureType =
        measure?.measureType === MeasureTypes.Numeric ||
        measure?.measureType === MeasureTypes.Currency ||
        measure?.measureType === MeasureTypes.Percentage;

    const allowCumulative =
        measure.frequencyPeriod !== FrequencyPeriods.Year &&
        isNumberMeasureType;

    const selectedInterval = valueInput?.isNone
        ? 'None'
        : valueInput?.isFullYearTarget
          ? 'FullYear'
          : 'Reoccurring';

    return (
        <EditPanel
            {...props}
            headerText="Measure of Success Target"
            saveErrorMessage={saveError?.message}
            isSaving={isSaving}
            isValid={true}
            hasSaved={hasSaved}
            onUpdateClick={handleUpdateClick}
            activeViewName={null}
        >
            <div className={classNames.container}>
                <div>
                    <MeasureInvervalChoiceGroup
                        selectedInterval={selectedInterval}
                        onChange={(newValue) => {
                            if (valueInput) {
                                if (newValue === 'None') {
                                    setValueInput({
                                        ...valueInput,
                                        isNone: true,
                                        isFullYearTarget: false,
                                        targetType: TargetTypes.None,
                                        valueType: ValueTypes.Simple,
                                    });
                                } else if (newValue === 'FullYear') {
                                    setValueInput({
                                        ...valueInput,
                                        isNone: false,
                                        isFullYearTarget: true,
                                        targetType: TargetTypes.FullYear,
                                        valueType: allowCumulative
                                            ? ValueTypes.Incremental
                                            : ValueTypes.Simple,
                                    });
                                } else {
                                    setValueInput({
                                        ...valueInput,
                                        isNone: false,
                                        isFullYearTarget: false,
                                        targetType: TargetTypes.Reoccurring,
                                        valueType: allowCumulative
                                            ? ValueTypes.Incremental
                                            : ValueTypes.Simple,
                                    });
                                }
                            }
                        }}
                    />
                </div>
                {!valueInput?.isNone && (
                    <div>
                        <ValueInput
                            label="Target Value"
                            measureType={measure.measureType}
                            multiplier={measure.multiplier}
                            currencySymbol={measure.currency?.symbol}
                            decimalPlaces={measure.decimalPlaces}
                            defaultValue={valueInput}
                            onChange={function (
                                decimalValue: number | null,
                                stringValue: string | null,
                                dateValue: string | null
                            ): void {
                                if (valueInput) {
                                    setValueInput({
                                        ...valueInput,
                                        decimalValue: decimalValue,
                                        stringValue: stringValue,
                                        dateValue: dateValue,
                                    });
                                }
                            }}
                        />
                    </div>
                )}
                {valueInput &&
                    (allowCumulative ||
                        valueInput.valueType === ValueTypes.Incremental) && // show the toggle if its somehow already set.
                    selectedInterval !== 'FullYear' && (
                        <Toggle
                            label="Allow for cumulative value entry"
                            onText="On"
                            offText="Off"
                            checked={
                                valueInput.valueType === ValueTypes.Incremental
                            }
                            onChange={(_ev, checked) => {
                                setValueInput({
                                    ...valueInput,
                                    valueType: checked
                                        ? ValueTypes.Incremental
                                        : ValueTypes.Simple,
                                });
                            }}
                            styles={{ root: { marginTop: '12px' } }}
                        />
                    )}
            </div>
        </EditPanel>
    );
}

type MeasureInvervalChoiceGroupOption = 'FullYear' | 'Reoccurring' | 'None';

export function MeasureInvervalChoiceGroup(props: {
    selectedInterval: MeasureInvervalChoiceGroupOption | null | undefined;
    onChange: (newValue: MeasureInvervalChoiceGroupOption | null) => void;
}) {
    const choiceOptionStyle: Partial<IChoiceGroupOptionStyles> = {
        labelWrapper: {
            fontSize: FontSizes.small,
            overflow: 'visible',
        },
    };

    return (
        <div>
            <ChoiceGroup
                label="Target Type"
                options={[
                    {
                        key: 'FullYear',
                        text: 'Full Year',
                        iconProps: { iconName: 'Calendar' },
                        styles: choiceOptionStyle,
                    },
                    {
                        key: 'Reoccurring',
                        text: 'Reoccurring',
                        iconProps: { iconName: 'SyncOccurence' },
                        styles: choiceOptionStyle,
                    },
                    {
                        key: 'None',
                        text: 'None',
                        iconProps: { iconName: 'Clear' },
                        styles: choiceOptionStyle,
                    },
                ]}
                styles={{
                    flexContainer: {
                        flexFlow: 'row', // don't wrap
                        overflow: 'hidden',
                    },
                }}
                selectedKey={props.selectedInterval ?? 'FullYear'}
                onChange={(_ev?, option?): void => {
                    props.onChange(
                        option?.key
                            ? (option?.key as MeasureInvervalChoiceGroupOption)
                            : null
                    );
                }}
            />
            {props.selectedInterval === 'None' ? (
                <Text block>
                    Target set manually for each value update within the year.
                </Text>
            ) : props.selectedInterval === 'FullYear' ? (
                <Text block>
                    A cumulative target to be achieved by the end of the year.
                </Text>
            ) : (
                <Text block>
                    A reoccurring target to achieve throughout the year.
                </Text>
            )}
        </div>
    );
}
