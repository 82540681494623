import React from 'react';

import {
    Stack,
    TextField,
    Dropdown,
    IDropdownOption,
    Toggle,
    Checkbox,
    Label,
    DefaultButton,
    Persona,
    PersonaSize,
    MessageBar,
} from '@fluentui/react';
import { EditPanel } from '../../../components/shared/EditPanel';
import useForm from '../../../hooks/useForm';
import { useStateContext } from '../../../services/contextProvider';
import {
    UserQl,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useGetTenantsQuery,
    UserTenantQl,
    UserTenant,
    RoleQl,
    useGetRoleTypesQuery,
    UserRole,
    UserRoleQl,
} from '../../../data/types';
import DeleteModal from '../../../components/shared/DeleteModal';
import { ProfilePhotoInput } from '../../../components/inputs/ProfilePhotoInput';
import { photoService } from '../../../services/photo.service';
import { Guid } from 'guid-typescript';
import useHasPhoto from '../../../hooks/useHasPhoto';

export type UserEditPanelProps = {
    showPanel: boolean;
    showGlobalRights: boolean;
    user?: UserQl | null;
    onCancel: () => void;
    onCreate: () => void;
    onUpdate: () => void;
    onDelete: () => void;
    onPhotoChanged: () => void;
};

function CastUserRole(input: UserRoleQl): UserRole {
    return {
        id: input.id,
        userId: input.userId,
        roleId: input.roleId,
        tenantId: input.tenantId,
    };
}

function IsGlobalRole(input: RoleQl): boolean {
    return input.name === 'Developer' || input.name === 'GlobalAdmin';
}

export function UserEditPanel(props: UserEditPanelProps): JSX.Element {
    const { currentTenantId, currentRoles } = useStateContext();

    const [showPanel, setShowPanel] = React.useState(false);
    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin'].includes(r)
    );

    const isGlobalAdmin = currentRoles.some((r) => ['GlobalAdmin'].includes(r));

    // Filter out global and roles for other tenants
    const selected = props.showGlobalRights
        ? (props.user?.userRoles
              .filter((r) => r.tenantId === null)
              .map(CastUserRole) ?? [])
        : (props.user?.userRoles
              .filter((r) => r.tenantId === currentTenantId)
              .map(CastUserRole) ?? []);

    const excludedUserRoles = props.showGlobalRights
        ? (props.user?.userRoles
              .filter((r) => r.tenantId !== null)
              .map(CastUserRole) ?? [])
        : (props.user?.userRoles
              .filter((r) => r.tenantId !== currentTenantId)
              .map(CastUserRole) ?? []);
    const [selectedUserRoles, setSelectedUserRoles] = React.useState(selected);

    const [imageSrc, setImageSrc] = React.useState<string | undefined>();
    const [imageRotation, setImageRotation] = React.useState(0);
    const [imagePixelCrop, setImagePixelCrop] = React.useState<
        { x: number; y: number; width: number; height: number } | undefined
    >();

    const { data: tenantData } = useGetTenantsQuery({
        skip: !props.showPanel,
    });

    const { data: roleTypes_ } = useGetRoleTypesQuery({
        skip: !props.showPanel,
    });
    const roleTypes = roleTypes_?.roleTypes?.filter((r) =>
        props.showGlobalRights ? IsGlobalRole(r) : !IsGlobalRole(r)
    );

    const [updateUser, { loading: isSaving, error: saveError }] =
        useUpdateUserMutation({
            onCompleted: () => {
                if (props.user?.id) {
                    props.onUpdate();
                } else {
                    props.onCreate();
                }
            },
            //refetchQueries: ['GetUsers']
        });

    const [deleteUser, { loading: isDeleting, error: deleteError }] =
        useDeleteUserMutation();

    const tenants = tenantData?.tenants;

    type formValuesType = {
        displayName: string;
        emailAddress: string;
        accessEnabled: boolean;
        selectedTenants: UserTenantQl[];
        utcSelfHelpEnabledUntil: string | null;
    };

    const formDefaultValues: formValuesType = {
        displayName: props.user?.displayName || '',
        emailAddress: props.user?.emailAddress || '',
        selectedTenants: props.user?.userTenants || [],
        accessEnabled: props.user?.accessEnabled || false,
        utcSelfHelpEnabledUntil: props.user?.utcSelfHelpEnabledUntil || null,
    };

    const formValidate = (
        values: formValuesType
    ): {
        [key: string]: string;
    } => {
        const formErrorMessages = {
            displayName: '',
            emailAddress: '',
        };

        if (!values.displayName) {
            formErrorMessages.displayName = 'Enter a display name';
        }

        if (!values.emailAddress) {
            formErrorMessages.emailAddress = 'Enter an email address';
        } else {
            const emailRegex = /\S+@\S+\.\S+/;
            const isValidEmail = emailRegex.test(values.emailAddress || '');
            if (!isValidEmail) {
                formErrorMessages.emailAddress = 'Enter a valid email address';
            }
        }

        return formErrorMessages;
    };

    const formCallback = async (values: formValuesType): Promise<void> => {
        const userTenants = values.selectedTenants.map((t) => {
            return {
                id: t.id,
                isDefault: t.isDefault ?? false,
                tenantId: t.tenantId,
                userId: props.user?.id,
            } as UserTenant;
        });

        const userRoles = [...excludedUserRoles, ...selectedUserRoles];

        await updateUser({
            variables: {
                input: {
                    id: props.user?.id || Guid.createEmpty().toString(),
                    displayName: values.displayName,
                    emailAddress: values.emailAddress,
                    accessEnabled: values.accessEnabled,
                    utcSelfHelpEnabledUntil: values.utcSelfHelpEnabledUntil,
                    userRoles: userRoles,
                    userTenants: userTenants,
                    version: props.user?.version || '',
                },
            },
        });

        if (imageSrc && imagePixelCrop && props.user?.id) {
            const croppedImage = await photoService.getCroppedImgAsync(
                imageSrc,
                'image/jpeg',
                imagePixelCrop,
                imageRotation
            );

            if (croppedImage) {
                await photoService.uploadPhotoAsync(
                    props.user?.id,
                    croppedImage
                );
                setHasPhoto(true);
                setImageSrc('');
                setCurrentUserProfileImageSrc(
                    photoService.getImageUrl(props.user?.id) || ''
                );
                props.onPhotoChanged();
            }
        }
    };

    const { errors, handleChange, handleSubmit, values, updateValue, reset } =
        useForm(formDefaultValues, formCallback, formValidate);

    React.useEffect(() => {
        if (props.showPanel !== showPanel) {
            reset();
            setShowPanel(props.showPanel);
            setIsConfirmingDelete(false);
        }
    }, [props.showPanel, reset, showPanel]);

    const tenantOptions: IDropdownOption[] = (tenants || [])
        .slice()
        .sort((a, b) =>
            (a.description || '').localeCompare(b.description || '')
        )
        .map((t) => {
            return {
                key: t.id || '',
                text: t.description || t.code || t.id || '',
            };
        });

    const confirmDelete = isGlobalAdmin
        ? (): void => {
              setIsConfirmingDelete(true);
          }
        : undefined;

    const deleteAsync = async (): Promise<void> => {
        await deleteUser({
            variables: {
                tenantId: currentTenantId || '',
                id: props.user?.id || '',
                restore: false,
            },
        });

        setIsConfirmingDelete(false);

        if (props.onDelete) {
            props.onDelete();
        }
    };

    const onPhotoChanged = (
        imageSrc: string | undefined,
        pixelCrop: { x: number; y: number; width: number; height: number },
        rotation: number
    ): void => {
        setImageSrc(imageSrc);
        setImagePixelCrop(pixelCrop);
        setImageRotation(rotation);
    };

    function onRoleChanged(role: RoleQl, isChecked: boolean) {
        if (!currentTenantId || !props.user?.id) {
            return;
        }
        const exists = selectedUserRoles.some(
            (selected) => selected.roleId === role.id
        );
        if (isChecked) {
            if (!exists) {
                const userRole: UserRole = {
                    id: null,
                    userId: props.user?.id,
                    roleId: role.id,
                    tenantId: IsGlobalRole(role) ? null : currentTenantId,
                };
                setSelectedUserRoles([...selectedUserRoles, userRole]);
            }
        } else {
            if (exists) {
                const newRoles = selectedUserRoles.filter(
                    (selected) => selected.roleId !== role.id
                );
                setSelectedUserRoles(newRoles);
            }
        }
    }

    const { hasPhoto, setHasPhoto } = useHasPhoto(
        props.user?.id ?? undefined,
        props.showPanel
    );

    const [currentUserProfileImageSrc, setCurrentUserProfileImageSrc] =
        React.useState<string>(photoService.getImageUrl(props.user?.id) || '');

    const removePhotoHandler = async (): Promise<void> => {
        if (props.user?.id) {
            await photoService.removePhotoAsync(props.user?.id);
            setHasPhoto(false);
            setImageSrc('');
            setCurrentUserProfileImageSrc('');
            props.onPhotoChanged();
        }
    };

    return (
        <React.Fragment>
            <DeleteModal
                activeViewName="UserDelete"
                isOpen={isConfirmingDelete}
                onDismiss={(): void => setIsConfirmingDelete(false)}
                message={'Are you sure you want to delete this user?'}
                deleteAction={deleteAsync}
                isDeleting={isDeleting}
                error={deleteError}
            />

            <EditPanel
                activeViewName="UserEdit"
                onDismiss={props.onCancel}
                showPanel={showPanel}
                onDeleteClick={confirmDelete}
                headerText={props.user?.id ? 'Edit User' : 'New User'}
                isSaving={isSaving}
                isValid={true}
                saveErrorMessage={saveError?.message || null}
                onUpdateClick={handleSubmit}
            >
                <Stack tokens={{ childrenGap: 8 }}>
                    <TextField
                        label="Display Name"
                        required
                        name="displayName"
                        autoComplete="off"
                        value={values.displayName || ''}
                        errorMessage={errors.displayName}
                        onChange={handleChange}
                        disabled={!isGlobalAdmin}
                    />

                    <TextField
                        label="Email Address"
                        required
                        name="emailAddress"
                        type="email"
                        autoComplete="off"
                        value={values.emailAddress || ''}
                        errorMessage={errors.emailAddress}
                        onChange={handleChange}
                        disabled={!isGlobalAdmin}
                    />

                    <Toggle
                        label="Account"
                        onText="Access Enabled"
                        offText="Access Disabled"
                        checked={values.accessEnabled}
                        onChange={(
                            ev: React.MouseEvent<HTMLElement>,
                            checked?: boolean
                        ): void => {
                            updateValue('accessEnabled', checked || false);
                        }}
                        disabled={!isGlobalAdmin}
                    />

                    {/* <Toggle
                        label="Self Help"
                        onText="Self Help Enabled"
                        offText="Self Help Disabled"
                        checked={values.utcSelfHelpEnabledUntil !== null}
                        onChange={(
                            ev: React.MouseEvent<HTMLElement>,
                            checked?: boolean
                        ): void => {
                            const date = checked
                                ? dayjs().add(3, 'month').toDate()
                                : null;
                            updateValue('utcSelfHelpEnabledUntil', date);
                        }}
                    /> 

                    {values.utcSelfHelpEnabledUntil !== null && (
                        <DatePicker
                            {...DefaultProps.DatePickerProps}
                            label="Enabled Until"
                            value={
                                values.utcSelfHelpEnabledUntil
                                    ? dayjs(
                                          values.utcSelfHelpEnabledUntil
                                      ).toDate()
                                    : undefined
                            }
                            onSelectDate={(
                                date: Date | null | undefined
                            ): void => {
                                updateValue(
                                    'utcSelfHelpEnabledUntil',
                                    date || null
                                );
                            }}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                        />
                    )}
                        */}

                    {props.user?.accessEnabled && !values.accessEnabled && (
                        <MessageBar>
                            Are you sure you want to deactive this user? They
                            will no longer be able to access the system.
                        </MessageBar>
                    )}

                    {roleTypes && roleTypes.length && (
                        <Stack tokens={{ childrenGap: 8 }}>
                            <Label>User Roles</Label>
                            {roleTypes.map((r) => {
                                return (
                                    <Checkbox
                                        key={r.name}
                                        label={r.name
                                            .replace(/([A-Z])/g, ' $1') // Format PascalCase with spaces
                                            .trim()}
                                        title={r.description}
                                        checked={selectedUserRoles.some(
                                            (selected) =>
                                                selected.roleId === r.id
                                        )}
                                        disabled={!isAdmin}
                                        onChange={(
                                            _ev?: React.FormEvent,
                                            isChecked?: boolean
                                        ) =>
                                            onRoleChanged(r, isChecked || false)
                                        }
                                    />
                                );
                            })}
                        </Stack>
                    )}

                    {props.showGlobalRights && (
                        <Dropdown
                            placeholder="Select tenants"
                            label="Tenants"
                            selectedKeys={values.selectedTenants.map(
                                (t) => t.tenantId || ''
                            )}
                            multiSelect
                            options={tenantOptions}
                            onChange={(
                                _event: React.FormEvent,
                                item?: IDropdownOption
                            ): void => {
                                if (item) {
                                    const tenant = (tenants || [])
                                        .map((t) => {
                                            return {
                                                tenantId: t.id,
                                            };
                                        })
                                        .find(
                                            (t) =>
                                                t.tenantId ===
                                                (item.key as string)
                                        );

                                    updateValue(
                                        'selectedTenants',
                                        item.selected
                                            ? [
                                                  ...values.selectedTenants,
                                                  tenant,
                                              ]
                                            : values.selectedTenants.filter(
                                                  (t) => t.tenantId !== item.key
                                              )
                                    );
                                }
                            }}
                        />
                    )}

                    <ProfilePhotoInput onPhotoChanged={onPhotoChanged} />

                    {!imageSrc && (
                        <Stack.Item
                            style={{
                                marginTop: '24px',
                            }}
                        >
                            <Persona
                                imageUrl={currentUserProfileImageSrc}
                                imageInitials={(values?.displayName || '')
                                    .split(' ')
                                    .map((n) => n[0])
                                    .join('')}
                                size={PersonaSize.size120}
                                hidePersonaDetails={true}
                                imageAlt="Profile Picture"
                            />
                            {hasPhoto && (
                                <DefaultButton
                                    text="Remove Photo"
                                    onClick={removePhotoHandler}
                                    style={{ marginTop: '24px' }}
                                />
                            )}
                        </Stack.Item>
                    )}
                </Stack>
            </EditPanel>
        </React.Fragment>
    );
}
