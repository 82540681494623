import React from 'react';
import { ChoiceGroup } from '@fluentui/react';
import { ExtractQueryType } from '../../../data/extendedTypes';
import {
    ChartDisplay,
    GetMeasureQuery,
    useUpdateMeasureMutation,
} from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import { gql, useApolloClient } from '@apollo/client';
import { useInputMappers } from '../../../hooks/useInputMappers';
import { useAvailableMeasureChartDisplays } from '../hooks/useAvailableMeasureChartDisplays';

export function MeasureVisualisationOptions(props: {
    measure: ExtractQueryType<GetMeasureQuery, ['measure']>;
}): JSX.Element {
    const { measure } = props;

    const { currentTenantId } = useStateContext();
    const client = useApolloClient();
    const { getMeasureInput } = useInputMappers();

    const [updateMeasure, { loading }] = useUpdateMeasureMutation();

    const { chartDisplayOptions } = useAvailableMeasureChartDisplays(measure);

    const selectChartDisplay = async (chartDisplay?: ChartDisplay) => {
        if (!measure || !chartDisplay) return;

        const input = {
            ...getMeasureInput(measure),
            chartDisplay: chartDisplay,
        };

        // Make it nice and snappy
        client.writeFragment({
            id: `MeasureQL:${measure.id}`,
            fragment: gql`
                fragment ChartDisplayUpdate on MeasureQL {
                    chartDisplay
                }
            `,
            data: {
                chartDisplay: input.chartDisplay,
            },
        });

        await updateMeasure({
            variables: {
                input: input,
                tenantId: currentTenantId || '',
            },
        });
    };

    return (
        <div
            style={{
                padding: 8,
            }}
        >
            <ChoiceGroup
                disabled={loading}
                label="Chart Display"
                options={chartDisplayOptions}
                defaultSelectedKey={measure.chartDisplay}
                onChange={(_ev, option) =>
                    selectChartDisplay(option?.key as ChartDisplay | undefined)
                }
            />
        </div>
    );
}
