export function readFile(file: File): Promise<string | null | ArrayBuffer> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
}

export function convertDataUrlToBlob(
    dataUrl: string | ArrayBuffer | null | undefined
): Blob | null {
    if (!dataUrl) return null;
    if (dataUrl instanceof ArrayBuffer) {
        throw new Error('ArrayBuffer is not yet supported');
    }
    const arr = dataUrl.split(',');
    const mimeMatches = arr[0].match(/:(.*?);/);
    const mime = mimeMatches ? mimeMatches[1] : '';
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[Number(n)] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
}
