import React from 'react';
import { mergeStyleSets } from '@fluentui/react';
import {
    GetMeasuresForStatusReportQuery,
    ReportPeriodTypes,
    useGetMeasurePeriodDataQuery,
} from '../../../data/types';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import MeasureArrow from '../../../components/MeasureArrow';
import { useStateContext } from '../../../services/contextProvider';
import { useThemes } from '../../../hooks/useThemes';
import { ReportPeriodDataActualBox } from '../../../components/reports/ReportPeriodDataActualBox';
import { ReportPeriodDataTargetBoxes } from '../../../components/reports/ReportPeriodDataTargetBoxes';

export function StatusReportGridMeasureDetail(props: {
    measure: ExtractQueryArrayType<
        GetMeasuresForStatusReportQuery,
        ['measures']
    > | null;
    fyStartDate: string;
    reportPeriodType: ReportPeriodTypes | null;
    reportPeriod: number | null;
    allowNavigation: boolean;
    onNavigate: () => void;
    hideLabel?: boolean;
    forDateTime: string | null;
}): JSX.Element {
    const { currentTenantId } = useStateContext();
    const { currentTheme } = useThemes();

    const { measure } = props;

    const { data, loading } = useGetMeasurePeriodDataQuery({
        skip: !measure?.id || !currentTenantId,
        variables: {
            forDateTime: props.forDateTime,
            measureId:
                measure?.isLinked && measure.linkedFromMeasureId
                    ? measure.linkedFromMeasureId
                    : measure?.id || '',
            tenantId: currentTenantId || '',
            reportPeriod: props.reportPeriod || null,
            reportPeriodType: props.reportPeriodType || ReportPeriodTypes.None,
        },
    });

    const { data: ytdData, loading: ytdLoading } = useGetMeasurePeriodDataQuery(
        {
            skip: !measure?.id || !currentTenantId,
            variables: {
                forDateTime: props.forDateTime,
                measureId:
                    measure?.isLinked && measure.linkedFromMeasureId
                        ? measure.linkedFromMeasureId
                        : measure?.id || '',
                tenantId: currentTenantId || '',
                reportPeriod: null,
                reportPeriodType: ReportPeriodTypes.Ytd,
            },
        }
    );

    const periodData = data?.periodData?.measurePeriodData;
    const ytdPeriodData = ytdData?.periodData?.measurePeriodData;

    const classNames = mergeStyleSets({
        container: {
            paddingTop: 8,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        arrowContainer: {
            minWidth: 40,
        },
        targetsAndActuals: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            alignItems: 'center',
        },
        actual: {
            alignSelf: 'stretch',
            flexGrow: 1,
            flexBasis: 0,
            paddingTop: 4,
            paddingBottom: 4,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            gap: 0,
            paddingLeft: 8,

            borderWidth: 1,
            borderColor: currentTheme.palette.neutralLight,
            borderStyle: 'solid',
            borderRadius: 4,
        },
        targets: {
            flexGrow: 1,
            flexBasis: 0,
            alignSelf: 'stretch',

            display: 'flex',
            flexDirection: 'row',

            gap: 2,
            '> div': {
                flexGrow: 1,
                flexBasis: 0,
            },
        },
    });

    return (
        <div className={classNames.container}>
            <div className={classNames.targetsAndActuals}>
                <div className={classNames.arrowContainer}>
                    {periodData && (
                        <MeasureArrow
                            {...periodData}
                            isStatusLimited={measure?.isStatusLimited || false}
                            showPercentage={
                                periodData?.actualFormatted !== null ||
                                periodData?.targetFormatted !== null
                            }
                            percentagePosition="Bottom"
                            textFontSize={10}
                            iconFontSize={20}
                            skipValuesCheck
                        />
                    )}
                </div>
                <div className={classNames.actual}>
                    {!!measure && (
                        <ReportPeriodDataActualBox
                            {...props}
                            periodData={periodData}
                            ytdPeriodData={ytdPeriodData}
                            onWarningClick={null}
                            isLoading={loading || ytdLoading}
                        />
                    )}
                </div>
                <div className={classNames.targets}>
                    <ReportPeriodDataTargetBoxes
                        {...props}
                        periodData={periodData}
                        ytdPeriodData={ytdPeriodData}
                        isLoading={loading || ytdLoading}
                    />
                </div>
            </div>
        </div>
    );
}
