import React, { useState } from 'react';
import {
    ChoiceGroup,
    IChoiceGroupOption,
    IChoiceGroupStyles,
    IconButton,
    IRawStyle,
    Link,
    mergeStyleSets,
    Modal,
    PrimaryButton,
    Text,
} from '@fluentui/react';
import { paths } from '../services/navigation';
import {
    CookieTypeKeys,
    CookieTypes,
    useCookieConsent,
} from '../hooks/useCookieConsent';

export function CookieConsentModal(props: {
    isOpen: boolean;
    onDismiss: () => void;
}) {
    const { getConsentState, acceptOnlyTypes } = useCookieConsent();

    // Cookie type and if it's accepted or rejected
    const [preferences, setPreferences] = useState<
        { CookieType: CookieTypeKeys; IsAccepted: boolean }[]
    >([]);

    const choiceGroupStyles: IChoiceGroupStyles = {
        label: {
            display: 'inline',
        },
        flexContainer: {
            columnGap: '1em',
            display: 'inline-flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
    };

    const acceptRejectOptions: IChoiceGroupOption[] = [
        { key: 'Accept', text: 'Accept' },
        { key: 'Reject', text: 'Reject' },
    ];

    const saveChangesButtonClick = () => {
        acceptOnlyTypes(
            preferences.filter((p) => p.IsAccepted).map((p) => p.CookieType)
        );
        props.onDismiss();
    };

    const isSaveDisabled = CookieTypes.some(
        (ct) =>
            !ct.isEssential &&
            !preferences.some((p) => p.CookieType === ct.key) &&
            getConsentState(ct.key) === 'NotSet'
    );

    const classNames = mergeStyleSets({
        header: {
            display: 'flex',
            flexDirection: 'row',
            '& :first-child': {
                flexGrow: 1,
            },
        },
        modalContainer: {
            padding: 32,
        },
        modalContentContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            maxWidth: 480,
        } as IRawStyle,
        toggleSection: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
    });

    return (
        <Modal
            isOpen={props.isOpen}
            containerClassName={classNames.modalContainer}
            onDismiss={props.onDismiss}
            allowTouchBodyScroll
        >
            <div className={classNames.header}>
                <div>
                    <h2>Manage cookie preferences</h2>
                </div>

                <div>
                    <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={props.onDismiss}
                    />
                </div>
            </div>

            <div className={classNames.modalContentContainer}>
                <Text variant="medium" block>
                    We use cookies to collect information allowing for
                    analytics, user experience and session tracking, as well as
                    video use tracking. Users can control the use of cookies at
                    the individual browser level. If you reject cookies, you may
                    still use our site, but your ability to use some features or
                    areas of our site may be limited.
                </Text>

                <Text variant="medium" block>
                    For more information see our{' '}
                    <Link
                        href={paths.privacyPolicy + '#use-of-cookies'}
                        target="_blank"
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        cookie policy
                    </Link>
                    .
                </Text>

                {CookieTypes.map((ct) => {
                    const consentState = getConsentState(ct.key);

                    return (
                        <div className={classNames.toggleSection} key={ct.key}>
                            <Text
                                variant="mediumPlus"
                                style={{ fontWeight: 'bold' }}
                                block
                            >
                                {ct.title}
                            </Text>
                            <Text variant="medium" block>
                                {ct.description}
                            </Text>
                            <ChoiceGroup
                                defaultSelectedKey={
                                    ct.isEssential
                                        ? 'Accept'
                                        : consentState === 'Accepted'
                                        ? 'Accept'
                                        : consentState === 'Rejected'
                                        ? 'Reject'
                                        : undefined
                                }
                                options={acceptRejectOptions}
                                styles={choiceGroupStyles}
                                onChange={(_ev, option) => {
                                    setPreferences((p) => [
                                        ...p.filter(
                                            (pt) => pt.CookieType !== ct.key
                                        ),
                                        {
                                            CookieType: ct.key,
                                            IsAccepted:
                                                option?.key === 'Accept',
                                        },
                                    ]);
                                }}
                                disabled={ct.isEssential}
                                required
                            />
                        </div>
                    );
                })}

                <div>
                    <PrimaryButton
                        text="Save Changes"
                        onClick={saveChangesButtonClick}
                        disabled={isSaveDisabled}
                    />
                </div>
            </div>
        </Modal>
    );
}
