import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';

type ZendeskWindow = typeof window & {
    zESettings: {
        webWidget: {
            launcher: {
                label: Record<string, string>;
            };
            color: {
                theme: string;
                launcherText: string;
            };
            position: {
                horizontal: string;
                vertical: string;
            };
        };
    };
    zE: (zendeskObject: string, action: string, data: unknown) => void;
};

type SupportWidgetProps = {
    userEmailAddress?: string;
    userDisplayName?: string;
    companyName?: string;
    themePrimaryColourHex?: string | null;
};

const ZendeskDefaultThemeColour = '#363636'; // GP Navy

export function useSupportWidget(): {
    setThemeColour: (hexColour: string | null) => void;
} {
    const setThemeColour = useCallback((hexColour: string | null) => {
        const zendeskWindow = window as ZendeskWindow;

        if (zendeskWindow.zE) {
            const updatedSettings = (zendeskWindow.zESettings = {
                ...zendeskWindow.zESettings,
                webWidget: {
                    ...zendeskWindow.zESettings.webWidget,
                    color: {
                        theme: hexColour
                            ? `#${hexColour}`
                            : ZendeskDefaultThemeColour,
                        launcherText: '#fff',
                    },
                },
            });

            zendeskWindow.zE('webWidget', 'updateSettings', updatedSettings);
        }
    }, []);

    return {
        setThemeColour,
    };
}

export default class SupportWidget extends React.Component<SupportWidgetProps> {
    scriptElement: HTMLScriptElement;

    constructor(props: SupportWidgetProps) {
        super(props);

        this.scriptElement = document.createElement('script');
        this.scriptElement.id = 'ze-snippet';
        this.scriptElement.src =
            'https://static.zdassets.com/ekr/snippet.js?key=a7b92d96-c438-46b1-9aa0-760042b797bb';

        const zendeskWindow = window as ZendeskWindow;

        zendeskWindow.zESettings = {
            webWidget: {
                launcher: {
                    label: {
                        '*': 'Support',
                    },
                },
                color: {
                    theme: props.themePrimaryColourHex
                        ? `#${props.themePrimaryColourHex}`
                        : ZendeskDefaultThemeColour,
                    launcherText: '#fff',
                },
                position: { horizontal: 'right', vertical: 'bottom' },
            },
        };

        this.scriptElement.onload = (): void => {
            const zendeskWindow = window as ZendeskWindow;

            if (zendeskWindow.zE) {
                zendeskWindow.zE('webWidget', 'identify', {
                    name: props.userDisplayName || '',
                    email: props.userEmailAddress || '',
                    organization: props.companyName || '',
                });

                zendeskWindow.zE('webWidget', 'prefill', {
                    name: {
                        value: props.userDisplayName || '',
                    },
                    email: {
                        value: props.userEmailAddress || '',
                    },
                });
            }
        };
    }

    componentDidMount(): void {
        document.body.appendChild(this.scriptElement);
    }

    componentWillUnmount(): void {
        document.body.removeChild(this.scriptElement);
    }

    render(): JSX.Element {
        return ReactDOM.createPortal([], this.scriptElement);
    }
}
