import React, { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';

import {
    Stack,
    Text,
    mergeStyleSets,
    IconButton,
    Icon,
    Link,
    ThemeProvider,
} from '@fluentui/react';
import {
    useStateContext,
    useDispatchContext,
} from '../../services/contextProvider';

import { useNavigate } from 'react-router';
import { TopNav } from '../../components/navigation';
import {
    UpdateUserTenantDefaultMutation,
    UserTenantQl,
    useGetDefaultMissionQuery,
    useUpdateUserTenantDefaultMutation,
} from '../../data/types';
import { ExtractQueryArrayType } from '../../data/extendedTypes';
import { AdvanceCard } from '../../components/AdvanceCard';
import { useThemes } from '../../hooks/useThemes';
import { useActiveView } from '../../hooks/useActiveView';
import { EngagementProvider } from '../../components/engagement/EngagementContext';
import { PathParams, navigation } from '../../services/navigation';

function TenantCard(props: {
    isDefault: boolean;
    tenant: {
        id: string | null;
        code: string | null;
        description: string | null;
        isEnabled: boolean;
    };
    makeDefault: () => void;
}): JSX.Element {
    const { currentRoles, currentUserId } = useStateContext();

    const { currentTheme } = useThemes();

    const navigate = useNavigate();
    const dispatch = useDispatchContext();

    const { data } = useGetDefaultMissionQuery({
        skip: !currentUserId || !props.tenant?.id || !props.tenant.isEnabled,
        variables: {
            tenantId: props.tenant?.id || '',
            financialYearCode: null,
        },
    });

    const [themeColour, setThemeColour] = useState('363636');

    useEffect(() => {
        const primaryColourHex =
            data?.default_mission?.team?.division?.primaryColourHex || '363636';
        setThemeColour(primaryColourHex);
    }, [data]);

    const onClick = async (): Promise<void> => {
        const mission = data?.default_mission;

        const params: PathParams = {
            financialYearCode:
                mission?.team?.division?.financialYear?.code || undefined,
            teamCode: mission?.team?.code || undefined,
            missionId: mission?.id || undefined,
            tenantCode: props.tenant.code?.toLocaleLowerCase(),
        };

        const path = navigation.getPathForParams(params);

        dispatch({
            type: 'SetupContext',
            payload: {
                currentTenantCode: params.tenantCode || '',
                currentContributorId: null,
                currentFinancialYearCode: params.financialYearCode,
                currentTeamCode: params.teamCode,
                currentMissionId: params.missionId,
                themePrimaryColourHex: themeColour,
            },
        });

        navigate(path);
    };

    const classNames = mergeStyleSets({
        topButtons: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
        },
        permissionButtonContainer: {
            height: 32,
            width: 32,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
    });

    return (
        <AdvanceCard
            childrenGap={4}
            style={{
                borderLeftWidth: 4,
                borderLeftColor: `#${themeColour}`,
                borderLeftStyle: 'solid',
            }}
        >
            <AdvanceCard.Item>
                <div className={classNames.topButtons}>
                    <div className={classNames.permissionButtonContainer}>
                        {currentRoles.length > 0 && (
                            <Icon
                                iconName="Crown"
                                title={currentRoles.join(', ')}
                            />
                        )}
                    </div>
                    <div>
                        <IconButton
                            iconProps={{
                                iconName: props.isDefault
                                    ? 'FavoriteStarFill'
                                    : 'FavoriteStar',
                            }}
                            onClick={props.makeDefault}
                            title="Default"
                        />
                    </div>
                </div>
            </AdvanceCard.Item>
            <AdvanceCard.Item>
                <Link onClick={onClick}>
                    <Text
                        block
                        variant={'xLargePlus'}
                        style={{
                            color: props.tenant.isEnabled
                                ? undefined
                                : currentTheme.palette.neutralTertiary,
                        }}
                    >
                        {props.tenant.description}
                    </Text>
                </Link>
            </AdvanceCard.Item>
            <AdvanceCard.Item>
                <Link onClick={onClick}>
                    <Text
                        block
                        variant="mediumPlus"
                        onClick={onClick}
                        style={{
                            color: props.tenant.isEnabled
                                ? undefined
                                : currentTheme.palette.neutralTertiary,
                        }}
                    >
                        {props.tenant.code?.toLocaleUpperCase()}
                    </Text>
                </Link>
            </AdvanceCard.Item>
        </AdvanceCard>
    );
}

type UserTenant = ExtractQueryArrayType<
    UpdateUserTenantDefaultMutation,
    ['userUpdateTenantDefault']
>;

function CastUserTenantQl(input: UserTenant): UserTenantQl {
    return {
        ...input,
        __typename: 'UserTenantQL',
        tenant: {
            ...input.tenant,
            __typename: 'TenantQL',
            users: [],
        },
    };
}

export function SwitchDashboard(): JSX.Element {
    const dispatch = useDispatchContext();
    const {
        currentUserId,
        userTenants: currentUserTenants,
        currentRoles,
    } = useStateContext();

    const [userTenants, setUserTenants] = React.useState(currentUserTenants);

    const { currentTheme } = useThemes();

    useActiveView('SwitchTenant', 'Switch Tenant');

    const [updateUserTenantDefault] = useUpdateUserTenantDefaultMutation({
        onCompleted: (data) => {
            const newUserTenants = data.userUpdateTenantDefault.map((ut) =>
                CastUserTenantQl(ut)
            );
            if (newUserTenants) {
                setUserTenants(newUserTenants);
            }

            dispatch({
                type: 'SetUserTenants',
                payload: newUserTenants,
            });
        },
        refetchQueries: ['GetCurrentUserQuery'],
    });

    const makeDefault = async (userTenant: UserTenantQl): Promise<void> => {
        if (currentUserId) {
            await updateUserTenantDefault({
                variables: {
                    tenantId: userTenant.tenantId,
                    userId: currentUserId,
                },
            });
        }
    };

    const classNames = mergeStyleSets({
        container: {
            backgroundColor: currentTheme.palette.neutralLighter,
            height: '100%',
        },
        tenantCards: {
            padding: 16,
            display: 'grid',
            gridGap: 16,
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gridAutoRows: 'minmax(auto, auto)',
            gridAutoFlow: 'dense',
        },
        tenantCard: {
            gridColumnEnd: 'span 1',
            gridRowEnd: 'span 1',
        },
    });

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'Developer'].includes(r)
    );

    const tenantsToShow = orderBy(
        isAdmin ? userTenants : userTenants.filter((ut) => ut.tenant.isEnabled),
        ['tenant.isEnabled', 'tenant.description'],
        ['desc', 'asc']
    );

    return (
        <ThemeProvider theme={currentTheme} style={{ height: '100%' }}>
            <EngagementProvider>
                <Stack className={classNames.container}>
                    <TopNav showSearch={false} showBreadcrumbs={false} />

                    <div className={classNames.tenantCards}>
                        {tenantsToShow.map((t) => {
                            return (
                                <div
                                    key={`tenantCard_${t.tenant.id}`}
                                    className={classNames.tenantCard}
                                >
                                    <TenantCard
                                        key={`tenantCard_${t.tenant.id}`}
                                        makeDefault={() => makeDefault(t)}
                                        {...t}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </Stack>
            </EngagementProvider>
        </ThemeProvider>
    );
}
