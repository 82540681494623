import React, { useCallback, useMemo } from 'react';
import { Text, Link } from '@fluentui/react';
import Fuse from 'fuse.js';

export function useSearchMarkdownLinks(markdownContent: string): {
    search: (query: string) => JSX.Element[];
} {
    // Parse markdown content and create a searchable list of links
    const links = useMemo(() => {
        const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
        let match;
        const parsedLinks = [];

        while ((match = linkRegex.exec(markdownContent)) !== null) {
            parsedLinks.push({ title: match[1], url: match[2] });
        }

        return parsedLinks;
    }, [markdownContent]);

    // Initialize fuse with the links and options
    const fuse = useMemo(
        () =>
            new Fuse(links, {
                keys: ['title'],
                includeScore: true,
                threshold: 0.3, // Adjust threshold to control fuzziness
            }),
        [links]
    );

    // Function to perform the search
    const search = useCallback(
        (query: string): JSX.Element[] => {
            if (!query.trim() || !markdownContent) {
                return [];
            }

            const results = fuse.search(query);

            if (!results.length) {
                return [<Text key="no-results">No results found.</Text>];
            }

            return results.map((result) => (
                <Link key={result.item.url} href={result.item.url}>
                    {result.item.title}
                </Link>
            ));
        },
        [fuse, markdownContent]
    );

    return { search };
}
