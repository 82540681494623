import { Access } from '../data/extendedTypes';
import { useStateContext } from '../services/contextProvider';

export function useTaskEditRules(
    task: {
        isDuplicate: boolean;
        missionId: string | null;
        parentTaskId: string | null;
        resourcedFromTaskId: string | null;
        utcAccepted: string | null;
        utcPostponed: string | null;
        utcCancelled: string | null;
        utcAtRisk: string | null;
        done: string | null;
        isPercentageCompleteFromResources: boolean;
        isPercentageCompleteFromSubTasks: boolean;
        resource: {
            userId: string | null;
            userContributorFYs: { code: string | null }[];
        } | null;
        resourcedFromTask: {
            mission: {
                userId: string | null;
                rights: {
                    write: boolean;
                };
            } | null;
        } | null;
    } | null,
    missionAccess: Access | undefined
): {
    isReadOnly: boolean;
    isNameReadOnly: boolean;
    isStartDateReadOnly: boolean;
    isDueDateReadOnly: boolean;
    isDoneDateReadOnly: boolean;
    isPercentageReadOnly: boolean;
    isStatusReadOnly: boolean;
    canDragToMission: boolean;
} {
    const { currentUserId, currentFinancialYearCode } = useStateContext();

    const hasRights =
        missionAccess?.write ||
        (currentUserId === task?.resource?.userId &&
            task?.resource?.userContributorFYs.some(
                (fy) =>
                    fy.code?.toUpperCase() ===
                    currentFinancialYearCode?.toUpperCase()
            ));

    const taskIsReadOnly = !task || !hasRights;

    const isNonAcceptedResourcedTask =
        !!task?.resourcedFromTaskId &&
        task?.resource?.userId !== task?.resourcedFromTask?.mission?.userId &&
        (!task?.utcAccepted || task.missionId === null);

    const hasRightsToResourcingTask =
        !!task?.resourcedFromTask?.mission?.rights.write;

    // const isAutomated =
    //     task?.tags.some((t) =>
    //         t.name?.toLocaleUpperCase().startsWith('JIRA:')
    //     ) || false;

    const isSpecifiedTask =
        !task?.parentTaskId && !!task?.missionId && !task.isDuplicate;

    return {
        isReadOnly: taskIsReadOnly,
        isNameReadOnly:
            taskIsReadOnly ||
            (isNonAcceptedResourcedTask && !hasRightsToResourcingTask),
        isStartDateReadOnly:
            taskIsReadOnly ||
            (isNonAcceptedResourcedTask && !hasRightsToResourcingTask) ||
            isSpecifiedTask ||
            task.isDuplicate,
        isDueDateReadOnly:
            taskIsReadOnly ||
            (isNonAcceptedResourcedTask && !hasRightsToResourcingTask) ||
            isSpecifiedTask ||
            task.isDuplicate,
        isDoneDateReadOnly:
            taskIsReadOnly || isSpecifiedTask || task.isDuplicate,
        isPercentageReadOnly:
            taskIsReadOnly ||
            isSpecifiedTask ||
            task?.isPercentageCompleteFromResources ||
            task?.isPercentageCompleteFromSubTasks ||
            task.isDuplicate,
        isStatusReadOnly:
            taskIsReadOnly ||
            isSpecifiedTask ||
            task.isDuplicate ||
            (isNonAcceptedResourcedTask && !hasRightsToResourcingTask) ||
            (!!task.done &&
                !task.utcCancelled &&
                !task.utcPostponed &&
                !task.utcAtRisk),
        canDragToMission: !taskIsReadOnly,
    };
}
