import React from 'react';
import { Stack, Icon, Text, TooltipHost } from '@fluentui/react';
import { useViewport } from '../../../hooks/useViewport';

function HeaderSummaryBar(props: {
    items: {
        text: string;
        count: number;
        iconName: string;
        iconColour: string;
        hoverText?: string;
    }[];
}): JSX.Element {
    const { width } = useViewport();
    const breakpoint = 760;

    return (
        <Stack horizontal tokens={{ childrenGap: width > breakpoint ? 16 : 8 }}>
            {props.items
                .filter((item) => item.count)
                .map((item, index) => {
                    const displayText =
                        width > breakpoint
                            ? `${item.count} ${item.text}`
                            : `${item.count}`;

                    return (
                        <Stack.Item key={index}>
                            <Stack horizontal>
                                <Stack.Item align="center">
                                    <TooltipHost content={item.text}>
                                        <Icon
                                            iconName={item.iconName}
                                            title={item.text}
                                            styles={{
                                                root: {
                                                    color: item.iconColour,
                                                    marginRight:
                                                        width > breakpoint
                                                            ? 4
                                                            : 2,
                                                    verticalAlign: 'middle',
                                                },
                                            }}
                                        />
                                    </TooltipHost>
                                </Stack.Item>
                                <Stack.Item align="center">
                                    <Text
                                        variant="xSmall"
                                        title={item.hoverText}
                                    >
                                        {displayText}
                                    </Text>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    );
                })}
        </Stack>
    );
}

export default React.memo(HeaderSummaryBar);
