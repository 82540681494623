import * as React from 'react';
import '../../../components/icons/RegisterIcons';
import {
    TagQl,
    TagTypes,
    useGetTagsQuery,
    useUpdateTagMutation,
} from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import {
    ShimmeredDetailsList,
    IconButton,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    Stack,
    Icon,
    Toggle,
    Shimmer,
} from '@fluentui/react';
import { useSorter } from '../../../hooks/useSorter';
import { DetailsListCellItemContainer } from '../../../components/shared/DetailsListCellItemContainer';

type TagListProps = {
    onItemEdit: (team: TagQl) => void;
    fyCodeFilter?: string | null;
};

export function TagList(props: TagListProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { data, loading } = useGetTagsQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
        },
    });

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(data?.tags, 'name', false, true);

    const onEditIconClick = (item: TagQl): void => {
        props.onItemEdit(item);
    };

    const [updateTag, { loading: isSaving }] = useUpdateTagMutation({
        refetchQueries: ['GetTags'],
    });

    const changeTagCategory = (
        tagId: string,
        property: string,
        value: string | boolean
    ): void => {
        const tag = data?.tags?.find((t) => t.id === tagId);

        if (tag) {
            const input = {
                ...tag,
                [property]: value,
            };

            updateTag({
                variables: {
                    tenantId: currentTenantId || '',
                    input: input,
                },
                optimisticResponse: {
                    __typename: 'Mutations',
                    tagUpdate: {
                        __typename: 'TagQL',
                        id: tag.id,
                        name: tag.name,
                        colourHex: tag.colourHex,
                        icon: tag.icon,
                        tagType: tag.tagType,
                        isMeasureTag: tag.isMeasureTag,
                        isTaskTag: tag.isTaskTag,
                    },
                },
            });
        }
    };

    const columns: IColumn[] = [
        {
            key: 'colour',
            name: '',
            fieldName: 'colourHex',
            isIconOnly: true,
            isResizable: false,
            minWidth: 24,
            maxWidth: 24,

            onRender: function renderIcon(tag: TagQl): JSX.Element {
                return (
                    <Icon
                        style={{
                            color: tag.colourHex
                                ? `#${tag.colourHex}`
                                : undefined,
                            fontSize: 18,
                            width: '20px',
                            height: '20px',
                        }}
                        iconName={
                            tag.icon
                                ? tag.icon
                                : tag.tagType === TagTypes.AdminTag
                                ? 'TagAdmin'
                                : 'TagSolid'
                        }
                        aria-label="Tag"
                    />
                );
            },
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            isRowHeader: true,
            isResizable: true,
            minWidth: 100,
            isSorted: sortColumn === 'name',
            isSortedDescending: sortColumn === 'name' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('name', true),
        },
        {
            key: 'tagType',
            name: 'Type',
            fieldName: 'tagType',
            isRowHeader: true,
            isResizable: true,
            minWidth: 100,
            isSorted: sortColumn === 'tagType',
            isSortedDescending: sortColumn === 'tagType' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('tagType', true),
            onRender: (tag) => {
                return tag?.tagType === TagTypes.AdminTag ? 'Admin' : 'User';
            },
        },
        {
            key: 'isMeasureTag',
            name: 'On Measures',
            fieldName: 'isMeasureTag',
            isRowHeader: true,
            isResizable: true,
            minWidth: 100,
            isSorted: sortColumn === 'isMeasureTag',
            isSortedDescending: sortColumn === 'isMeasureTag' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('isMeasureTag', true),
            onRender: function renderEnabled(item: {
                id: string;
                isMeasureTag: boolean;
            }): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        <Shimmer isDataLoaded={!isSaving}>
                            <Toggle
                                styles={{
                                    root: {
                                        marginBottom: 0,
                                    },
                                }}
                                onText="Yes"
                                offText="No"
                                checked={item.isMeasureTag}
                                onChange={(
                                    ev: React.MouseEvent<HTMLElement>,
                                    checked?: boolean
                                ): void => {
                                    changeTagCategory(
                                        item.id,
                                        'isMeasureTag',
                                        checked || false
                                    );
                                }}
                            />
                        </Shimmer>
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'isTaskTag',
            name: 'On Tasks',
            fieldName: 'isTaskTag',
            isRowHeader: true,
            isResizable: true,
            minWidth: 100,
            isSorted: sortColumn === 'isTaskTag',
            isSortedDescending: sortColumn === 'isTaskTag' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('isTaskTag', true),
            onRender: function renderEnabled(item: {
                id: string;
                isTaskTag: boolean;
            }): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        <Shimmer isDataLoaded={!isSaving}>
                            <Toggle
                                styles={{
                                    root: {
                                        marginBottom: 0,
                                    },
                                }}
                                onText="Yes"
                                offText="No"
                                checked={item.isTaskTag}
                                onChange={(
                                    ev: React.MouseEvent<HTMLElement>,
                                    checked?: boolean
                                ): void => {
                                    changeTagCategory(
                                        item.id,
                                        'isTaskTag',
                                        checked || false
                                    );
                                }}
                            />
                        </Shimmer>
                    </DetailsListCellItemContainer>
                );
            },
        },
        // {
        //     key: 'divisions',
        //     name: 'Divisions',
        //     fieldName: 'divisions',
        //     minWidth: 100,
        //     isResizable: true,
        //     onRender: function renderFinancialYear(item: TagQl): JSX.Element {
        //         const divisions = item.divisions || [];
        //         return <span>{divisions.map((d) => d.name).join(', ')}</span>;
        //     },
        // },
        {
            key: 'actions',
            name: '',
            minWidth: 32,
            isIconOnly: true,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderActions(item: TagQl): JSX.Element {
                return (
                    <IconButton
                        key={item.id}
                        iconProps={{ iconName: 'Edit' }}
                        onClick={() => onEditIconClick(item)}
                    />
                );
            },
        },
    ];

    return (
        <Stack>
            <ShimmeredDetailsList
                setKey="items"
                items={sortedItems}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={loading}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details"
                listProps={{
                    renderedWindowsAhead: 0,
                    renderedWindowsBehind: 0,
                }}
                onItemInvoked={(item?: TagQl): void => {
                    if (item) {
                        props.onItemEdit(item);
                    }
                }}
            />
        </Stack>
    );
}
