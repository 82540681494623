import React from 'react';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { Checkbox, Link, mergeStyleSets } from '@fluentui/react';
import Loading from '../Loading';
import { sorters } from '../../data/sorters';

export function MeasureCheckBoxList(props: {
    measures: {
        id: string | null;
        name: string | null;
        sequence: number;
        group: {
            id: string | null;
            name: string | null;
            sequence: number;
        } | null;
    }[];
    isLoading: boolean;
    selectedMeasures: { id: string; missionSequence?: number }[];
    onSelectedMeasuresChanged: (
        selected: { id: string; missionSequence?: number }[]
    ) => void;
}) {
    const { measures, selectedMeasures, onSelectedMeasuresChanged, isLoading } =
        props;

    const measureGroups = orderBy(
        uniqBy(measures?.flatMap((m) => m.group), (g) => g?.id),
        'sequence'
    );

    const getMeasureMissionSequence = (measure: {
        name: string | null;
        sequence: number;
        group: { sequence: number } | null;
    }) => {
        const groupMultiplier = measure.group
            ? measure.group?.sequence * 100
            : 10000;

        return groupMultiplier + measure.sequence;
    };

    const selectAllMeasures = () => {
        const selected =
            measures?.map((m) => ({
                id: m.id || '',
                missionSequence: getMeasureMissionSequence(m),
            })) || [];

        onSelectedMeasuresChanged(selected);
    };

    const unselectAllMeasures = () => {
        onSelectedMeasuresChanged([]);
    };

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
        selectUnselect: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
        },
    });

    return (
        <div className={classNames.container}>
            <div className={classNames.selectUnselect}>
                {measures.length > 0 && (
                    <Link
                        onClick={selectAllMeasures}
                        disabled={selectedMeasures.length === measures?.length}
                    >
                        Select All
                    </Link>
                )}

                {measures.length > 0 && (
                    <Link
                        onClick={unselectAllMeasures}
                        disabled={!selectedMeasures.length}
                    >
                        Unselect All
                    </Link>
                )}
            </div>

            {measureGroups.map((mg) => {
                return (
                    <div key={mg?.id || 'ungrouped'}>
                        {measureGroups.length > 1 && (
                            <strong>{mg?.name ?? 'Ungrouped'}</strong>
                        )}

                        {measures
                            .filter((m) => m.group?.id === mg?.id)
                            .sort(sorters.sequenceSorter)
                            ?.map((m) => {
                                return (
                                    <Checkbox
                                        key={m.id}
                                        label={m.name || m.id || ''}
                                        checked={selectedMeasures.some(
                                            (sm) => sm.id === m.id
                                        )}
                                        styles={{
                                            root: { marginTop: 4 },
                                        }}
                                        onChange={(_ev, checked) => {
                                            const updated =
                                                selectedMeasures.filter(
                                                    (sm) => sm.id !== m.id
                                                );
                                            if (checked && m.id) {
                                                updated.push({
                                                    id: m.id || '',
                                                    missionSequence:
                                                        getMeasureMissionSequence(
                                                            m
                                                        ),
                                                });
                                            }
                                            onSelectedMeasuresChanged(updated);
                                        }}
                                    />
                                );
                            })}
                    </div>
                );
            })}

            {isLoading && <Loading />}
        </div>
    );
}
