import React, { useState } from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import { Access } from '../data/extendedTypes';
import { useStateContext } from '../services/contextProvider';
import { PanelCommandBarButton } from './PanelCommandBarButton';
import { MeasureTransferDialog } from './MeasureTransferDialog';
import { useThemes } from '../hooks/useThemes';
import { MeasureDeleteModal } from './MeasureDeleteModal';

type MeasureCommandBarProps = {
    measureId: string | null | undefined;
    missionAccess: Access | undefined;
    activeButton: 'Overview' | 'Edit' | 'TargetsAndActuals';
    isLinked: boolean;
    isCustom: boolean;
    onOverviewButtonClick?: () => void | undefined;
    onEditMeasureButtonClick?: () => void | undefined;
    onAddValuesButtonClick?: () => void | undefined;
    onSendLinkedButtonClick?: () => void;
    onLinkFromMeasureClick?: () => void;
    onCopyMeasureIdButtonClick: () => void;
    onMeasureLockToggleClick?: () => void;
    onCloseButtonClick?: () => void;
    onDeleted?: () => void;
    isLocked: boolean;
    hideDelete?: boolean;
};

export function MeasureCommandBar(props: MeasureCommandBarProps): JSX.Element {
    const { currentRoles } = useStateContext();
    const { currentTheme } = useThemes();

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin'].includes(r)
    );

    const [isTransferDialogHidden, setIsTransferDialogHidden] = useState(true);
    const hideTransferDialog = () => setIsTransferDialogHidden(true);
    const showTransferDialog = () => setIsTransferDialogHidden(false);

    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);

    const items: ICommandBarItemProps[] = props.isCustom
        ? [
              {
                  key: 'overview',
                  text: 'Overview',
                  onClick: props.onOverviewButtonClick,
                  iconProps: { iconName: 'AnalyticsView' },
                  checked: props.activeButton === 'Overview',
              },
              {
                  key: 'edit',
                  text: 'Edit',
                  disabled:
                      !isAdmin &&
                      (props.isLocked || !props.missionAccess?.write),
                  onClick: props.onEditMeasureButtonClick,
                  iconProps: { iconName: 'Edit' },
                  checked: props.activeButton === 'Edit',
              },
              {
                  key: 'targetsActuals',
                  text: 'Targets & Actuals',
                  onClick: props.onAddValuesButtonClick,
                  iconProps: { iconName: 'LineChart' },
                  checked: props.activeButton === 'TargetsAndActuals',
              },
          ]
        : [];

    const showTransfer = !props.isLinked && props.missionAccess?.write;

    if (
        props.isCustom &&
        (props.onSendLinkedButtonClick ||
            props.onLinkFromMeasureClick ||
            showTransfer)
    ) {
        const linkButton: ICommandBarItemProps = {
            key: 'links',
            text: 'Links',
            iconProps: { iconName: 'Link12' },
            subMenuProps: {
                items: [
                    {
                        key: 'link',
                        text: 'Send to...',
                        disabled: !props.onSendLinkedButtonClick,
                        onClick: props.onSendLinkedButtonClick,
                        iconProps: { iconName: 'Send' },
                    },
                ],
            },
        };

        if (!props.isLinked && props.onLinkFromMeasureClick) {
            linkButton.subMenuProps?.items.push({
                key: 'linkFrom',
                text: 'Link from...',
                onClick: props.onLinkFromMeasureClick,
                iconProps: { iconName: 'AddLink' },
            });
        }

        if (props.measureId && showTransfer) {
            linkButton.subMenuProps?.items.push({
                key: 'transfer',
                text: 'Transfer ownership...',
                onClick: showTransferDialog,
                iconProps: { iconName: 'Remote' },
            });
        }

        items.push(linkButton);
    }

    const overflowItems: ICommandBarItemProps[] = [];

    const farItems: ICommandBarItemProps[] = props.onCloseButtonClick
        ? [
              {
                  key: 'close',
                  text: 'Close',
                  onClick: props.onCloseButtonClick,
                  iconOnly: true,
                  iconProps: {
                      iconName: 'Cancel',
                  },
              },
          ]
        : [];

    const handleDeleteClick = () => setIsConfirmingDelete(true);

    if (isAdmin && props.missionAccess?.import) {
        farItems.unshift({
            key: 'copyId',
            text: 'Copy ID to clipboard',
            onClick: props.onCopyMeasureIdButtonClick,
            iconProps: { iconName: 'ClipboardSolid' },
            iconOnly: true,
        });
    }

    if (props.missionAccess?.write && props.measureId && !props.hideDelete) {
        farItems.unshift({
            key: 'delete',
            onClick: handleDeleteClick,
            iconOnly: true,
            text: 'Delete',
            iconProps: {
                styles: {
                    root: {
                        color: currentTheme.semanticColors.errorIcon,
                    },
                },
                iconName: 'Delete',
            },
        });
    }

    if (props.measureId) {
        farItems.unshift({
            key: 'lockTag',
            iconOnly: true,
            onClick:
                isAdmin || props.missionAccess?.import
                    ? props.onMeasureLockToggleClick
                    : undefined,
            iconProps: { iconName: props.isLocked ? 'LockSolid' : 'Unlock' },
        });
    }

    return (
        <div style={{ width: '100%' }}>
            <MeasureDeleteModal
                measureId={props.measureId || null}
                isOpen={isConfirmingDelete}
                onDismiss={(): void => setIsConfirmingDelete(false)}
                onDeleted={(): void => {
                    setIsConfirmingDelete(false);
                    if (props.onDeleted) {
                        props.onDeleted();
                    }
                    if (props.onCloseButtonClick) {
                        props.onCloseButtonClick();
                    }
                }}
                onTransferInsteadButtonClick={() => {
                    setIsConfirmingDelete(false);
                    showTransferDialog();
                }}
            />
            <MeasureTransferDialog
                measureId={props.measureId}
                hidden={isTransferDialogHidden}
                onDismiss={hideTransferDialog}
                onMeasureTransferred={() => {
                    hideTransferDialog();
                    if (props.onCloseButtonClick) {
                        props.onCloseButtonClick();
                    }
                }}
            />
            <CommandBar
                styles={{
                    root: {
                        height: 32,
                        paddingRight: 8, // Gap to save / close icons on right
                    },
                }}
                items={items}
                overflowItems={overflowItems}
                farItems={farItems}
                buttonAs={PanelCommandBarButton}
                overflowButtonAs={PanelCommandBarButton}
            />
        </div>
    );
}
