import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { useGetFinancialYearsQuery } from '../../data/types';
import { useStateContext } from '../../services/contextProvider';
import { InputShimmer } from '.';
import orderBy from 'lodash/orderBy';

export function FinancialYearPicker(props: {
    selectedFinancialYearId?: string | null;
    onChange: (
        financialYear: { id: string | null; code: string | null } | undefined
    ) => void;
    required?: boolean;
    label?: string;
    placeholder?: string;
    errorMessage?: string;
    disabled?: boolean;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { data, loading } = useGetFinancialYearsQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
        },
    });

    const financialYears = orderBy(
        data?.financialYears,
        ['startDate', 'code'],
        ['desc', 'asc']
    );

    const financialYearOptions: IDropdownOption[] | undefined = orderBy(
        financialYears?.map((fy) => {
            return {
                key: fy.id || '',
                text: fy.code || fy.name || fy.id || '',
            };
        })
    );

    return (
        <InputShimmer isDataLoaded={!loading}>
            <Dropdown
                label={props.label}
                placeholder={props.placeholder}
                required={props.required}
                disabled={props.disabled}
                options={financialYearOptions || []}
                defaultSelectedKey={props.selectedFinancialYearId}
                errorMessage={props.errorMessage}
                onChange={(
                    _event: React.FormEvent,
                    option?: IDropdownOption | undefined
                ): void => {
                    const selectedFy = option
                        ? financialYears.find((fy) => fy.id === option.key)
                        : undefined;
                    if (props.onChange) {
                        props.onChange(selectedFy);
                    }
                }}
            />
        </InputShimmer>
    );
}
