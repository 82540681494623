import React from 'react';
import dayjs from 'dayjs';
import {
    IChartDataPoint,
    IChartProps,
    StackedBarChart,
} from '@fluentui/react-charting';
import { useTaskStatus } from '../../../hooks/useTaskStatus';
import { DefaultPalette } from '@fluentui/theme';
import { TaskWithStatus } from '../../../data/extendedTypes';

type TaskType = TaskWithStatus & {
    name: string | null;
    percentComplete: number | null;
};

const getDataPointsForTask = (
    fyStartDate: string,
    task: TaskType,
    status?: {
        text: string;
        colour: string | undefined;
    } | null
): IChartDataPoint[] => {
    const djFyStart = dayjs(fyStartDate);
    const djStart = dayjs(task.start || undefined);
    const djEnd = dayjs(task.done || task.due || task.start || undefined);

    const durationInDays = djStart.isAfter(djFyStart)
        ? Math.max(Math.min(djEnd.diff(djStart, 'day'), 365), 1)
        : Math.max(Math.min(djEnd.diff(djFyStart, 'day'), 365), 1);

    const dateFormat = 'DD MMM YYYY';

    // The start day ofsett of the coloured bar.
    let startDay = djStart.diff(djFyStart, 'day');

    // start day must not be 0 when it starts before this year.
    startDay = startDay > 0 ? startDay : 0;

    // The length of the coloured bar.
    const remainingDays = 365 - durationInDays - startDay;

    const daysComplete = durationInDays * (task.percentComplete || 0);
    const daysNotComplete = durationInDays - daysComplete;

    const xAxisCalloutData1 = task.start
        ? 'Start: ' + dayjs(task.start).format(dateFormat)
        : null;

    const xAxisCalloutData2 = task.due
        ? 'Due: ' + dayjs(task.due).format(dateFormat)
        : null;

    const xAxisCalloutData3 = task.done
        ? 'Done: ' + dayjs(task.done).format(dateFormat)
        : null;

    const xAxisCalloutData = [
        xAxisCalloutData1,
        xAxisCalloutData2,
        xAxisCalloutData3,
    ].join(' \n');

    const dataPoints: IChartDataPoint[] = [
        {
            data: startDay,
            placeHolder: true,
            color: DefaultPalette.neutralTertiaryAlt,
            xAxisCalloutData: xAxisCalloutData,
            yAxisCalloutData: status?.text,
        },
        {
            data: daysComplete,
            color: status?.colour,
            xAxisCalloutData: xAxisCalloutData,
            yAxisCalloutData: status?.text,
        },
        {
            data: daysNotComplete,
            color: `${status?.colour}80`,
            xAxisCalloutData: xAxisCalloutData,
            yAxisCalloutData: status?.text,
        },
        {
            data: remainingDays,
            placeHolder: true,
            color: DefaultPalette.neutralTertiaryAlt,
            xAxisCalloutData: xAxisCalloutData,
            yAxisCalloutData: status?.text,
        },
    ];

    return dataPoints;
};

export function TaskGanttBar(props: {
    task: TaskType;
    fyStartDate: string;
    statusDate?: string;
}): JSX.Element {
    const status = useTaskStatus(props.task, props.statusDate);

    const data: IChartProps = {
        chartData: getDataPointsForTask(props.fyStartDate, props.task, status),
    };

    const jsFyStart = dayjs(props.fyStartDate);

    const benchmarkDate = props.statusDate
        ? dayjs.utc(props.statusDate)
        : dayjs().utc();

    const benchmarkDataPoint = benchmarkDate.isAfter(jsFyStart)
        ? Math.min(benchmarkDate.diff(jsFyStart, 'day'), 365)
        : 0;

    const benchmarkData: IChartDataPoint = {
        data: benchmarkDataPoint,
    };

    return (
        <StackedBarChart
            data={data}
            benchmarkData={benchmarkData}
            width={600}
            hideLegend={true}
            styles={{
                chart: {
                    marginBottom: 8,
                },
            }}
            calloutProps={{
                styles: {
                    calloutMain: {
                        whiteSpace: 'pre-wrap',
                    },
                },
            }}
        />
    );
}
