import React, { useState } from 'react';
import { mergeStyleSets } from '@fluentui/react';
import { AdvanceCard } from '../../../components/AdvanceCard';
import MarkdownEditor from '../../../components/inputs/MarkdownEditor';
import { TemplateReportElementType } from '../TemplateReport';
import { EditibleText } from '../../../components/EditibleText';

export function TemplateReportRichTextElement(props: {
    element: TemplateReportElementType;
    sourceElement: TemplateReportElementType | null;
    onElementChanged: (
        updated: TemplateReportElementType,
        forceImmediateSave: boolean
    ) => void;
    isReadOnly: boolean;
}): JSX.Element {
    const classNames = mergeStyleSets({
        title: {
            margin: 0,
        },
        container: {
            height: '100%',
            paddingLeft: 8,
            paddingRight: 8,
            display: 'flex',
            flexDirection: 'column',
            gap: 0,
        },
        editorContainer: {
            flex: 1,
        },
    });

    const textContent = props.sourceElement
        ? props.sourceElement.textContent
        : props.element.textContent;

    const [title, setTitle] = useState<string | null>(props.element.title);
    const [markdown, setMarkdown] = useState<string | null>(textContent);

    const handleMarkdownChanged = (markdown: string) => {
        setMarkdown(markdown);
        props.onElementChanged(
            {
                ...props.element,
                title: title,
                textContent: markdown,
            },
            false
        );
    };

    const handleTitleUpdateClick = async (newTitle: string) => {
        setTitle(newTitle);
        props.onElementChanged(
            {
                ...props.element,
                title: newTitle,
                textContent: markdown,
            },
            true
        );
    };

    return (
        <AdvanceCard style={{ height: '100%' }} childrenGap={8}>
            <div className={classNames.container}>
                {!!props.element.title && (
                    <div>
                        <EditibleText
                            isRequired
                            isReadOnly={props.isReadOnly}
                            dialogTitle={'Edit Title'}
                            text={title || ''}
                            onUpdateClick={handleTitleUpdateClick}
                        >
                            <h4 className={classNames.title}>{title}</h4>
                        </EditibleText>
                    </div>
                )}
                <div className={classNames.editorContainer}>
                    <MarkdownEditor
                        onChange={handleMarkdownChanged}
                        defaultValue={textContent}
                        readOnly={props.isReadOnly}
                    />
                </div>
            </div>
        </AdvanceCard>
    );
}
