// Import markdown-it and Token classes
import React from 'react';
import MarkdownIt from 'markdown-it';
import ReactDOMServer from 'react-dom/server';
import { Icon, IIconStyles, ITheme } from '@fluentui/react';

export const MarkdownItFluentUIIcon = (
    md: MarkdownIt,
    opts: { theme: ITheme }
): void => {
    const iconStyles: IIconStyles = {
        root: {
            color: opts.theme.palette.themePrimary,
            verticalAlign: 'bottom',
            fontSize: 16,
        },
    };

    const replaceIcon = (
        tokens: { content: string }[],
        idx: number
    ): string => {
        const tokenContent = tokens[idx as number].content;

        return tokenContent.replace(/:([a-zA-Z0-9]+):/g, (_match, iconName) =>
            ReactDOMServer.renderToString(
                <Icon iconName={iconName} styles={iconStyles} />
            )
        );
    };

    md.core.ruler.push('fluent_ui_icon', (state) => {
        state.tokens.forEach((blockToken) => {
            if (blockToken.type === 'inline') {
                blockToken.children?.forEach((token, idx) => {
                    if (token.type === 'text' && token.content.includes(':')) {
                        token.type = 'html_inline';
                        token.content = replaceIcon(
                            blockToken.children || [],
                            idx
                        );
                    }
                });
            }
        });
    });
};
