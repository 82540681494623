import React from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import { useStateContext } from '../services/contextProvider';
import { ExtractQueryArrayType, TaskStatusNames } from '../data/extendedTypes';
import { TaskPanelSections } from './TaskPanel';
import { GetTaskQuery } from '../data/types';
import { useThemes } from '../hooks/useThemes';
import { PanelCommandBarButton } from './PanelCommandBarButton';
import { useTaskDeleter } from '../hooks/useTaskDeleter';
import { useTaskEditRules } from '../hooks/useTaskEditRules';
import { TaskDeleteModal } from './TaskDeleteModal';

export function TaskCommandBar(props: {
    task: ExtractQueryArrayType<GetTaskQuery, ['task']>;
    taskStatus: { name: TaskStatusNames };
    section: TaskPanelSections;
    onSectionChange: (section: TaskPanelSections) => void;
    onCopyTaskIdButtonClick: () => void;
    onCloseButtonClick: () => void;
    onCommentsButtonClick?: () => void;
    onAttachmentsButtonClick: () => void;
    hideDelete: boolean;
}): JSX.Element {
    const { currentRoles, currentTenantId, currentFinancialYearCode } =
        useStateContext();

    const { currentTheme } = useThemes();

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin'].includes(r)
    );

    const missionAccess = props.task?.mission
        ? props.task?.mission?.rights
        : props.task?.resourcedFromTask?.mission?.rights;

    const { isReadOnly } = useTaskEditRules(props.task, missionAccess);

    const { canDelete, deleteWillReturnToDependencies } = useTaskDeleter(
        currentTenantId,
        currentFinancialYearCode,
        props.task
    );

    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);

    const items: ICommandBarItemProps[] = [];

    if (
        props.taskStatus.name !== TaskStatusNames.Pending &&
        props.taskStatus.name !== TaskStatusNames.Rejected
    ) {
        items.push({
            key: 'edit',
            text: 'Edit',
            disabled: isReadOnly,
            onClick: () => props.onSectionChange(TaskPanelSections.Edit),
            iconProps: { iconName: 'Edit' },
            checked: props.section === TaskPanelSections.Edit,
        });
    }

    items.push({
        key: 'alignment',
        text: 'Alignment',
        onClick: () => props.onSectionChange(TaskPanelSections.Alignment),
        iconProps: { iconName: 'Relationship' },
        checked: props.section === TaskPanelSections.Alignment,
    });

    if (
        props.taskStatus.name !== TaskStatusNames.Pending &&
        props.taskStatus.name !== TaskStatusNames.Rejected &&
        props.task?.missionId
    ) {
        items.push({
            key: 'map',
            text: 'Map',
            disabled: isReadOnly,
            onClick: () => props.onSectionChange(TaskPanelSections.Map),
            iconProps: { iconName: 'BulletedTreeList' },
            checked: props.section === TaskPanelSections.Map,
        });
    }

    items.push({
        key: 'history',
        text: 'History',
        onClick: () => props.onSectionChange(TaskPanelSections.History),
        iconProps: { iconName: 'FullHistory' },
        checked: props.section === TaskPanelSections.History,
    });

    const overflowItems: ICommandBarItemProps[] = [];

    if (isAdmin && missionAccess?.import) {
        overflowItems.push({
            key: 'copyId',
            text: 'Copy task ID to clipboard',
            onClick: props.onCopyTaskIdButtonClick,
            iconProps: { iconName: 'ClipboardSolid' },
        });
    }

    const handleDeleteButtonClick = canDelete
        ? (): void => {
              setIsConfirmingDelete(true);
          }
        : undefined;

    const farItems: ICommandBarItemProps[] = [
        {
            key: 'comments',
            iconOnly: true,
            onClick: props.onCommentsButtonClick,
            iconProps: { iconName: 'Message' },
        },
        {
            key: 'attachments',
            iconOnly: true,
            onClick: props.onAttachmentsButtonClick,
            iconProps: { iconName: 'Attach' },
        },
    ];

    if (!props.hideDelete) {
        farItems.push({
            key: 'delete',
            onClick: handleDeleteButtonClick,
            iconOnly: true,
            disabled: !canDelete,
            text: deleteWillReturnToDependencies ? 'Unaccept task' : 'Delete',
            iconProps: {
                styles: {
                    root: {
                        color: currentTheme.semanticColors.errorIcon,
                    },
                },
                iconName: deleteWillReturnToDependencies
                    ? 'DependencyRemove'
                    : 'Delete',
            },
        });
    }

    return (
        <div style={{ width: '100%' }}>
            <TaskDeleteModal
                task={props.task}
                onCancelClick={() => setIsConfirmingDelete(false)}
                onDeleted={props.onCloseButtonClick}
                isOpen={isConfirmingDelete}
            />

            <CommandBar
                styles={{
                    root: {
                        height: 32,
                        paddingRight: 8, // Gap to save / close icons on right
                    },
                }}
                items={items}
                overflowItems={overflowItems}
                farItems={farItems}
                buttonAs={PanelCommandBarButton}
                overflowButtonAs={PanelCommandBarButton}
            />
        </div>
    );
}
