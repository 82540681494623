import React from 'react';
import orderBy from 'lodash/orderBy';
import { Stack, Text } from '@fluentui/react';
import dayjs from 'dayjs';
import {
    Arrows,
    ArrowDirection,
    GetMissionMeasuresQuery,
    GetMeasureGroupsQuery,
} from '../../../data/types';
import { MeasureTile, MeasureTileProps } from './MeasureTile';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import { getLabelForDate } from '../../Measure/utils/measureUtils';

export type MissionMeasure = ExtractQueryArrayType<
    GetMissionMeasuresQuery,
    ['measures']
>;

export function MeasureTiles(props: {
    measureData: GetMissionMeasuresQuery;
    measuresGroupsData: GetMeasureGroupsQuery;
    numberOfMeasuresDisplayed: number;
    onMeasureClick: (measureId: string) => void;
    fyStartDate: string;
}): JSX.Element | null {
    if (!props.measureData?.measures) {
        return null;
    }

    const groupsAndMeasures: {
        groupId: string | null;
        groupName: string | null;
        measureId: string | null;
    }[] = [];

    const groups = orderBy(
        [
            ...(props.measuresGroupsData?.measureGroups || []).slice(),
            {
                id: null,
                name: 'Ungrouped',
                sequence: 99999,
            },
        ],
        'sequence'
    );

    const measures = orderBy(
        (props.measureData?.measures || []).slice(),
        'sequence'
    );

    groups.forEach((g) => {
        measures
            .filter((m) => m.measureGroupId === g.id)
            .forEach((m) => {
                groupsAndMeasures.push({
                    groupId: g.id,
                    groupName: g.name,
                    measureId: m.id,
                });
            });
    });

    const itemsToDisplay = groupsAndMeasures.slice(
        0,
        Math.min(
            (props.measureData?.measures || []).length,
            props.numberOfMeasuresDisplayed
        )
    );

    let currentGroupId: string | null = null;

    const tiles = itemsToDisplay.map((mg) => {
        const measure = measures.find((m) => m.id === mg.measureId);

        const latestComment = measure?.lastComment
            ? {
                  timeDisplay: dayjs
                      .utc(measure?.lastComment.utcCreated)
                      .fromNow(),
                  content: measure?.lastComment.text || '',
                  authorName: measure?.lastComment.username || 'Unknown',
              }
            : null;

        const label = measure?.lastAsOf?.asOfDate
            ? getLabelForDate(
                  props.fyStartDate,
                  measure.frequencyPeriod,
                  measure?.lastAsOf?.asOfDate,
                  false
              )
            : null;

        const measureTileProps: MeasureTileProps = {
            id: measure?.id || '',
            name: measure?.name || '',
            values: measure?.lastAsOf?.values,
            asOfLabel: label,
            arrowColour: measure?.lastAsOf?.arrowColour || Arrows.Yellow,
            arrowDirection:
                measure?.lastAsOf?.arrowDirection || ArrowDirection.Same,
            status: measure?.lastAsOf?.statusValue || 0,
            isStatusLimited: measure?.isStatusLimited || false,
            latestComment: latestComment,
            onClick: (): void => props.onMeasureClick(measure?.id || ''),
        };

        const groupText = currentGroupId !== mg.groupId ? mg.groupName : null;
        currentGroupId = mg.groupId;

        return (
            <React.Fragment key={`measuretile_${measure?.id}`}>
                {groupText && (
                    <Text variant="smallPlus" block style={{ padding: 4 }}>
                        {groupText}
                    </Text>
                )}
                <MeasureTile {...measureTileProps} />
            </React.Fragment>
        );
    });

    return <Stack tokens={{ childrenGap: 4 }}>{tiles}</Stack>;
}
