import React, { useCallback, useState } from 'react';
import compact from 'lodash/compact';
import {
    ICommandBarItemProps,
    CommandBar,
    mergeStyleSets,
    IButtonStyles,
    ICommandBarData,
    CommandBarButton,
} from '@fluentui/react';
import { MeasureCopyDialog } from '../../../components/MeasureCopyDialog';
import { useStateContext } from '../../../services/contextProvider';
import { MeasureTransferDialog } from '../../../components/MeasureTransferDialog';
import { MeasureLinkFromDialog } from '../../../components/MeasureLinkFromDialog';
import MeasureHistoryPanel from './MeasureHistoryPanel';
import { ExtractQueryType } from '../../../data/extendedTypes';
import { GetMeasureQuery } from '../../../data/types';
import { navigation } from '../../../services/navigation';
import { useNavigate, useParams } from 'react-router';
import MeasureCalcTooltip from '../../MeasureFormulaEditor/components/MeasureCalcTooltip';
import { useLanguage } from '../../../services/i18n';

export function MeasureEditPageCommandBar(props: {
    measure: ExtractQueryType<GetMeasureQuery, ['measure']>;
    isReadOnly: boolean;
    enableEditButton: boolean;
    showTotals: boolean;
    toggleShowTotals: () => void;
    onEditMeasureClick: (measureId: string) => void;
    onAttachmentsClick: (measureId: string) => void;
    onCommentsClick: (measureId: string) => void;
    onUpgradeClick: () => void;
}) {
    const {
        currentTenantId,
        currentTenantCode,
        currentFinancialYearCode,
        currentRoles,
    } = useStateContext();
    const navigate = useNavigate();
    const { teamCode, missionId } = useParams();

    const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);
    const [isLinkFromDialogOpen, setIsLinkFromDialogOpen] = useState(false);
    const [isTransferDialogOpen, setIsTransferDialogOpen] = useState(false);
    const [isHistoryPanelOpen, setIsHistoryPanelOpen] = useState(false);

    const { t } = useLanguage();

    const { measure, isReadOnly } = props;

    const linkedToMissions = compact(
        measure?.linkedMeasures?.filter((m) => m.isLinked).map((m) => m.mission)
    ).filter(
        (value, index, self) =>
            self.findIndex((s) => value.id === s.id) === index
    );

    const allowTransferOwnership = !measure.isLinked && !isReadOnly;

    const allowSendTo = !measure.isLinked;

    const allowLinkFrom =
        !measure.isLinked && measure.linkedMeasures.length === 0 && !isReadOnly;

    const allowEditValuesFormula = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin'].includes(r)
    );

    const handleSendToButtonClick = () => setIsCopyDialogOpen(true);
    const handleLinkFromMeasureClick = () => setIsLinkFromDialogOpen(true);
    const handleTransferOwnershipClick = () => setIsTransferDialogOpen(true);

    const handleValuesFormulaNavigate = useCallback(() => {
        const path = navigation.getPathForParams({
            tenantCode: currentTenantCode || '',
            financialYearCode: currentFinancialYearCode || '',
            teamCode: teamCode,
            missionId: missionId,
            measureId: measure.id || undefined,
            formulaEdit: true,
        });
        navigate(path, { replace: true });
    }, [
        currentTenantCode,
        currentFinancialYearCode,
        teamCode,
        missionId,
        measure.id,
        navigate,
    ]);

    const classNames = mergeStyleSets({
        commandBar: {
            primarySet: {
                alignItems: 'flex-end',
            },
            secondarySet: {
                marginTop: 8,
            },
        },
    });

    const buttonStyles: IButtonStyles = {
        root: {
            backgroundColor: 'transparent',
        },
        rootDisabled: {
            backgroundColor: 'transparent',
        },
    };

    const commandBarStyles: IButtonStyles = {
        ...buttonStyles,
        root: {
            backgroundColor: 'transparent',
            height: '100%',
        },
    };

    const commentCount =
        (measure?.isLinked
            ? measure?.linkedFromMeasure?.commentCount || 0
            : 0) + measure.commentCount;

    const items: ICommandBarItemProps[] = [
        {
            key: 'comments',
            text: commentCount
                ? `${commentCount} ${t('general.comment', { count: commentCount })}`
                : t('general.add-comment'),
            onClick: () => props.onCommentsClick(props.measure.id || ''),
            iconProps: {
                iconName: 'Message',
            },
            buttonStyles: buttonStyles,
        },
        {
            key: 'attachments',
            text: measure.attachmentCount
                ? `${measure.attachmentCount} ${t('general.attachment', { count: measure.attachmentCount })}`
                : t('general.add-attachment'),
            onClick: () => props.onAttachmentsClick(props.measure.id || ''),
            iconProps: { iconName: 'Attach' },
            buttonStyles: buttonStyles,
        },
        {
            key: 'history',
            text: 'History',
            onClick: () => setIsHistoryPanelOpen(true),
            iconProps: { iconName: 'FullHistory' },
            buttonStyles: buttonStyles,
        },
    ];

    if (allowSendTo || allowLinkFrom || allowTransferOwnership) {
        items.push({
            key: 'links',
            text: 'Links',
            iconProps: { iconName: 'Link12' },
            subMenuProps: {
                items: [
                    {
                        key: 'link',
                        text: 'Send to...',
                        onClick: handleSendToButtonClick,
                        disabled: !allowSendTo,
                        iconProps: { iconName: 'Send' },
                    },
                    {
                        key: 'linkFrom',
                        text: 'Link from...',
                        onClick: handleLinkFromMeasureClick,
                        disabled: !allowLinkFrom,
                        iconProps: { iconName: 'AddLink' },
                    },
                    {
                        key: 'transfer',
                        text: 'Transfer ownership...',
                        onClick: handleTransferOwnershipClick,
                        disabled: !allowTransferOwnership,
                        iconProps: { iconName: 'Remote' },
                    },
                ],
            },
            buttonStyles: buttonStyles,
        });
    }

    if (allowEditValuesFormula) {
        const links = items?.filter((i) => i.key === 'links')[0];
        links?.subMenuProps?.items.push({
            key: 'calculatedMeasure',
            text: props.measure.valueFormula
                ? 'Edit Calculated Measure...'
                : 'Setup Calculated Measure...',
            onClick: handleValuesFormulaNavigate,
            disabled: !allowTransferOwnership,
            iconProps: { iconName: 'Calculator' },
        });
    }

    if (!isReadOnly && measure.isCustom) {
        items.push({
            key: 'upgrade',
            text: 'Upgrade',
            onClick: () => props.onUpgradeClick(),
            iconProps: { iconName: 'FavoriteStar' },
            buttonStyles: buttonStyles,
        });
    }

    const farItems: ICommandBarItemProps[] = [
        {
            key: `EditButton${props.enableEditButton ? '' : '_DISABLED'}`,
            text: 'Edit',
            iconProps: {
                iconName: 'Edit',
            },
            onClick: () => props.onEditMeasureClick(measure?.id || ''),
            disabled: !props.enableEditButton,
            buttonStyles: buttonStyles,
        },
    ];

    if (measure?.valueFormula) {
        farItems.unshift({
            key: `FormulaButton${props.enableEditButton ? '' : '_DISABLED'}`,
            onRender: () => {
                return (
                    <MeasureCalcTooltip
                        measureId={measure.id}
                        valueFormula={measure.valueFormula}
                    >
                        <CommandBarButton
                            styles={commandBarStyles}
                            iconProps={{
                                iconName: 'Calculator',
                            }}
                            onClick={handleValuesFormulaNavigate}
                            disabled={
                                props.isReadOnly || !props.enableEditButton
                            }
                        >
                            Calculated
                        </CommandBarButton>
                    </MeasureCalcTooltip>
                );
            },
            buttonStyles: buttonStyles,
        });
    }

    const onReduceData = (
        data: ICommandBarData
    ): ICommandBarData | undefined => {
        if (data.primaryItems?.some((fi) => !fi.iconOnly)) {
            return {
                ...data,
                primaryItems: data.primaryItems.map((fi) => {
                    return {
                        ...fi,
                        iconOnly: true,
                    };
                }),
            };
        }

        // Return this when when can't reduce anymore.
        return undefined;
    };

    const onGrowData = (data: ICommandBarData): ICommandBarData | undefined => {
        if (data.primaryItems?.some((fi) => fi.iconOnly)) {
            return {
                ...data,
                primaryItems: data.primaryItems.map((fi) => {
                    return {
                        ...fi,
                        iconOnly: false,
                    };
                }),
            };
        }

        // Return this when when can't grow anymore.
        return undefined;
    };

    return (
        <>
            <CommandBar
                className={classNames.commandBar}
                styles={{
                    root: {
                        paddingLeft: 16,
                        backgroundColor: 'transparent',
                    },
                }}
                items={items}
                farItems={farItems}
                onReduceData={onReduceData}
                onGrowData={onGrowData}
            />
            <MeasureCopyDialog
                tenantId={currentTenantId || ''}
                measureId={measure.id}
                hidden={!isCopyDialogOpen}
                onDismiss={() => setIsCopyDialogOpen(false)}
                onMeasuresCreated={() => {
                    setIsCopyDialogOpen(false);
                }}
                financialYearCode={currentFinancialYearCode || null}
                alreadyLinkedToMissions={linkedToMissions}
            />
            <MeasureTransferDialog
                measureId={measure.id}
                hidden={!isTransferDialogOpen}
                onDismiss={() => setIsTransferDialogOpen(false)}
                onMeasureTransferred={() => {
                    setIsTransferDialogOpen(false);
                }}
            />
            <MeasureLinkFromDialog
                tenantId={currentTenantId || ''}
                measureId={measure.id}
                hidden={!isLinkFromDialogOpen}
                onDismiss={() => setIsLinkFromDialogOpen(false)}
                onLinked={() => {
                    setIsLinkFromDialogOpen(false);
                }}
                financialYearCode={currentFinancialYearCode || null}
            />
            <MeasureHistoryPanel
                measureId={measure.id}
                showPanel={isHistoryPanelOpen}
                onDismiss={() => setIsHistoryPanelOpen(false)}
            />
        </>
    );
}
