import React, {
    ChangeEvent,
    PropsWithChildren,
    useEffect,
    useMemo,
    useState,
} from 'react';
import debounce from 'lodash/debounce';

import { IconButton, SearchBox, mergeStyleSets } from '@fluentui/react';
import { useThemes } from '../hooks/useThemes';

export function FilterBar(
    props: PropsWithChildren<{
        searchPlaceholder?: string;
        onSearchTextChanged: (keyword: string) => void;
        onClearAndDismissClick: () => void;
    }>
): JSX.Element {
    const [searchText, setSearchText] = useState('');

    const { onSearchTextChanged } = props;

    const debouncedChange = useMemo(
        () => debounce((f) => onSearchTextChanged(f), 400),
        [onSearchTextChanged]
    );

    useEffect(() => debouncedChange(searchText), [searchText, debouncedChange]);

    const handeKeywordChanged = (
        _ev?: ChangeEvent<HTMLInputElement>,
        keyword?: string | null
    ) => {
        setSearchText(keyword || '');
    };

    const handleClearAndDismiss = () => {
        setSearchText('');
        debouncedChange.flush();
        props.onClearAndDismissClick();
    };

    const { currentTheme } = useThemes();

    const classNames = mergeStyleSets({
        bar: {
            margin: 8,
            padding: 8,
            backgroundColor: currentTheme.semanticColors.infoBackground,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
    });

    const searchBoxStyles = {
        clearButton: {
            display: 'none',
        },
        root: {
            backgroundColor: 'transparent',
            minWidth: 200,
            maxWidth: 300,

            border: 'none',
            selectors: {
                ':after': {
                    border: 'none',
                },
            },
        },
    };

    return (
        <div className={classNames.bar}>
            <div
                style={{
                    flexGrow: 1,
                }}
            >
                <SearchBox
                    styles={searchBoxStyles}
                    value={searchText}
                    onChange={handeKeywordChanged}
                    onSearch={() => debouncedChange.flush()}
                    iconProps={{ iconName: 'Filter' }}
                    placeholder={props.searchPlaceholder}
                    showIcon
                />
            </div>

            {props.children}

            <div>
                <IconButton
                    iconProps={{ iconName: 'Cancel' }}
                    title="Clear and dismiss filters"
                    onClick={handleClearAndDismiss}
                />
            </div>
        </div>
    );
}
