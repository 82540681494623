import dayjs from 'dayjs';
import sum from 'lodash/sum';
import { MeasureSeriesNames } from '../../../data/extendedTypes';
import {
    ArrowDirection,
    Arrows,
    FrequencyPeriods,
    MeasureAsOf,
    SeriesValue,
} from '../../../data/types';
import { DatePickerStrings } from '../../../services/i18n';

export enum FrequencyTypes {
    Yearly = 'YEARLY',
    Quarterly = 'QUARTERLY',
    Monthly = 'MONTHLY',
    Custom = 'CUSTOM',
}

export type CombineMethod = 'add' | 'latest';

export type MonthRow = {
    index: number;
    dt: string;
    label: string;

    interval: SeriesValue;
    ytd: number | null;

    actual: SeriesValue;
    actualYtd: number | null;

    forecast: number | null;
    forecastYtd: number | null;

    status: {
        statusValue: number;
        arrowColour: Arrows;
        arrowDirection: ArrowDirection;
    };

    statusYtd?: {
        statusValue: number | null;
        arrowColour: Arrows;
        arrowDirection: ArrowDirection;
    };

    hasChanges: boolean;
    asOf?: MeasureAsOf;
    isNew?: boolean;
    isDeleted?: boolean;
};

const formatter = new Intl.NumberFormat(undefined, {
    style: 'percent',
    maximumFractionDigits: 1,
});

export function formatPercentage(value: number) {
    return formatter.format(value ?? 0);
}

export function getRandomIntInclusive(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getExpectedTableSize = (
    frequency: FrequencyPeriods,
    measure?: { isCustom: boolean; valueHistory: { id: string }[] | null }
) => {
    if (measure?.isCustom) return measure.valueHistory?.length ?? 0;

    return frequency === FrequencyPeriods.Year
        ? 1
        : frequency === FrequencyPeriods.Quarter
          ? 4
          : 12;
};

export const getLabelByType = (
    fyStartDate: string,
    measureFrequency: FrequencyTypes,
    asOfDate: string | null | undefined,
    useShortForm = true
) => {
    if (measureFrequency === FrequencyTypes.Yearly) return 'Year';
    else if (measureFrequency === FrequencyTypes.Quarterly && asOfDate)
        return `Quarter ${getQuarter(fyStartDate, asOfDate)}`;
    else if (measureFrequency === FrequencyTypes.Monthly)
        return useShortForm
            ? DatePickerStrings.shortMonths[dayjs(asOfDate).month()]
            : DatePickerStrings.months[dayjs(asOfDate).month()];
    else if (asOfDate) {
        const formattedAsOfDate = dayjs(asOfDate).format('DD MMM YYYY');
        return formattedAsOfDate;
    } else {
        return 'Unknown';
    }
};

export const getQuarter = (
    fyStart: string | dayjs.Dayjs,
    current: string | dayjs.Dayjs
): number => {
    const startYear = dayjs(fyStart).year();
    const currentYear = dayjs(current).year();
    const startMonth = dayjs(fyStart).month();
    const currentMonth = dayjs(current).month();

    let quarter: number;

    if (currentYear > startYear) {
        quarter = (currentMonth + 12 - startMonth) / 3 + 1;
    } else {
        quarter = (currentMonth - startMonth) / 3 + 1;
    }

    return Math.floor(quarter);
};

export const getLabelForDate = (
    fyStartDate: string,
    measureFrequency: FrequencyPeriods,
    asOfDate: string,
    useShortForm = true
): string => {
    if (measureFrequency === FrequencyPeriods.Year) return 'Year';
    else if (measureFrequency === FrequencyPeriods.Quarter) {
        return `Quarter ${getQuarter(fyStartDate, asOfDate)}`;
    } else if (measureFrequency === FrequencyPeriods.Month) {
        return useShortForm
            ? DatePickerStrings.shortMonths[dayjs(asOfDate).month()]
            : DatePickerStrings.months[dayjs(asOfDate).month()];
    } else {
        const formattedAsOfDate = dayjs(asOfDate).format('DD MMM YYYY');
        return formattedAsOfDate;
    }
};

export const getLabelFreq = (
    i: number,
    measureFrequency: FrequencyPeriods,
    asOfDate: string | null | undefined,
    useShortForm = true
) => {
    if (measureFrequency === FrequencyPeriods.Year) return 'Year';
    else if (measureFrequency === FrequencyPeriods.Quarter)
        return `Quarter ${i + 1}`;
    else if (measureFrequency === FrequencyPeriods.Month)
        return useShortForm
            ? DatePickerStrings.shortMonths[dayjs(asOfDate).month()]
            : DatePickerStrings.months[dayjs(asOfDate).month()];
    else if (asOfDate) {
        const formattedAsOfDate = dayjs(asOfDate).format('DD MMM YYYY');
        return formattedAsOfDate;
    } else {
        return 'Custom';
    }
};

export const createEmptyRow = (
    index: number,
    formattedAsOfDate: string,
    measureFrequency: FrequencyTypes,
    fyStartDate: string
): MonthRow => {
    return {
        index: index,
        label: getLabelByType(fyStartDate, measureFrequency, formattedAsOfDate),
        dt: formattedAsOfDate,

        interval: createSeriesValue(MeasureSeriesNames.Target),
        ytd: 0,

        forecast: null,
        forecastYtd: null,

        actual: createSeriesValue(MeasureSeriesNames.Actual),
        actualYtd: 0,

        status: {
            statusValue: 0,
            arrowColour: Arrows.None,
            arrowDirection: ArrowDirection.None,
        },
        hasChanges: false,
        asOf: undefined,
    };
};

export const createSeriesValue = (
    seriesType: MeasureSeriesNames,
    decimalValue: number | null = null,
    stringValue: string | null = null,
    dateValue: string | null = null
): SeriesValue => {
    return {
        id: null,
        decimalValue: decimalValue,
        stringValue: stringValue,
        dateValue: dateValue,
        calcId: null,
        seriesType: {
            id: null,
            name: seriesType,
            calcSymbol: null,
            defaultFormat: '',
            sequence: null,
        },
        version: '',
    };
};

export const isSeriesValueEmpty = (
    seriesValue: SeriesValue | null | undefined
): boolean => {
    if (seriesValue === null || seriesValue === undefined) {
        return true;
    }
    return (
        seriesValue.dateValue === null &&
        seriesValue.decimalValue === null &&
        seriesValue.decimalValue === null
    );
};

export const getSeriesValue = (
    seriesType: MeasureSeriesNames,
    asOf?: MeasureAsOf
): SeriesValue | undefined => {
    const value = (asOf?.values || []).find(
        (v) => v.seriesType?.name === seriesType
    );

    return value ? { ...value } : undefined;
};

export const combineData = (
    data: MonthRow[],
    lastPhasedTarget: MonthRow | null,
    combineMethod: CombineMethod,
    index: number,
    formattedAsOfDate: string,
    measureFrequency: FrequencyTypes,
    measureId: string | null,
    fyStartDate: string
) => {
    const row: MonthRow = createEmptyRow(
        index,
        formattedAsOfDate,
        measureFrequency,
        fyStartDate
    );

    const unused: MonthRow[] = [];

    if (combineMethod === 'add') {
        for (const item of data) {
            if (
                row.interval.decimalValue !== null ||
                item.interval.decimalValue !== null
            ) {
                if (!row.interval.decimalValue) {
                    row.interval.decimalValue = 0;
                }

                row.interval.decimalValue += item.interval.decimalValue ?? 0;
            }

            if (row.forecast !== null || item.forecast !== null) {
                row.forecast = (row.forecast ?? 0) + (item.forecast ?? 0);
            }

            if (
                row.actual.decimalValue !== null ||
                item.actual.decimalValue !== null
            ) {
                if (!row.actual.decimalValue) {
                    row.actual.decimalValue = 0;
                }

                row.actual.decimalValue += item.actual.decimalValue ?? 0;
            }

            row.status = item.status;
            unused.push(item);
        }

        const target = createSeriesValue(
            MeasureSeriesNames.Target,
            row.interval.decimalValue
        );
        const actual = createSeriesValue(
            MeasureSeriesNames.Actual,
            row.actual.decimalValue
        );

        row.asOf = {
            id: null,
            asOfDate: formattedAsOfDate,
            measureId: measureId,
            values: [target, actual],
            version: null,
        };

        if (row.forecast !== null) {
            row.asOf.values?.push(
                createSeriesValue(
                    MeasureSeriesNames.PhasedForecast,
                    row.forecast
                )
            );
        }

        // If there is no target for this row
        if (isSeriesValueEmpty(row.interval)) {
            // Have a look in the phasing for this period.
            const phasingForPeriod = data
                .flatMap((d) => d.asOf?.values)
                ?.filter(
                    (v) =>
                        v?.seriesType?.name === MeasureSeriesNames.PhasedTarget
                )
                .map((v) => v?.decimalValue);

            if (phasingForPeriod.length) {
                row.interval.decimalValue = sum(phasingForPeriod);
                target.decimalValue = sum(phasingForPeriod);
            } else if (lastPhasedTarget) {
                // Use the latest phasing specified.
                const phasingTarget = getSeriesValue(
                    MeasureSeriesNames.PhasedTarget,
                    lastPhasedTarget.asOf
                );

                if (phasingTarget) {
                    row.interval.decimalValue = phasingTarget.decimalValue;
                    target.decimalValue = phasingTarget.decimalValue;
                }
            }
        }
    } else {
        // Use latest
        const dataOrderedDesc = data
            .slice()
            .sort((a, b) => dayjs(b.dt).valueOf() - dayjs(a.dt).valueOf());

        row.asOf = {
            id: null,
            asOfDate: formattedAsOfDate,
            measureId: measureId,
            values: [],
            version: null,
        };

        const actualRow = findFirstFirstRowWithSeries(
            dataOrderedDesc,
            MeasureSeriesNames.Actual
        );

        if (actualRow) {
            row.actual = actualRow.actual;
            if (row.asOf && !row.asOf.id) {
                row.asOf.id = actualRow.asOf?.id || null;
                row.asOf.version = actualRow.asOf?.version || null;
            }
            const seriesValue = getSeriesValue(
                MeasureSeriesNames.Actual,
                actualRow.asOf
            );
            if (seriesValue) {
                row.asOf.values?.push(seriesValue);
            }
        }

        const targetRow = findFirstFirstRowWithSeries(
            dataOrderedDesc,
            MeasureSeriesNames.Target
        );

        // If there's a trarget row, and its more recent than the latest phase.
        if (
            targetRow &&
            (!lastPhasedTarget ||
                dayjs(targetRow?.dt).isAfter(lastPhasedTarget?.dt))
        ) {
            const seriesValue = getSeriesValue(
                MeasureSeriesNames.Target,
                targetRow.asOf
            );

            row.interval =
                seriesValue || createSeriesValue(MeasureSeriesNames.Target);

            if (row.asOf && !row.asOf.id) {
                row.asOf.id = targetRow.asOf?.id || null;
                row.asOf.version = targetRow.asOf?.version || null;
            }

            if (seriesValue) {
                row.asOf.values?.push({
                    ...seriesValue,
                    seriesType: {
                        id: null,
                        name: MeasureSeriesNames.Target,
                        calcSymbol: null,
                        defaultFormat: '',
                        sequence: null,
                    },
                });
            }
        } else if (lastPhasedTarget) {
            const phasingTarget = getSeriesValue(
                MeasureSeriesNames.PhasedTarget,
                lastPhasedTarget.asOf
            );

            if (phasingTarget) {
                row.interval = phasingTarget;

                row.asOf.values?.push({
                    ...phasingTarget,
                    seriesType: {
                        id: null,
                        name: MeasureSeriesNames.Target,
                        calcSymbol: null,
                        defaultFormat: '',
                        sequence: null,
                    },
                });
            }
        }

        const phasedForecastRow = findFirstFirstRowWithSeries(
            dataOrderedDesc,
            MeasureSeriesNames.PhasedForecast
        );

        if (phasedForecastRow) {
            row.forecast = phasedForecastRow.forecast;
            row.asOf.values?.push(
                createSeriesValue(
                    MeasureSeriesNames.PhasedForecast,
                    getSeriesValue(
                        MeasureSeriesNames.PhasedForecast,
                        phasedForecastRow.asOf
                    )?.decimalValue
                )
            );
        }

        // Set any that don't match the assigned asof id to unused.
        dataOrderedDesc.forEach((d) => {
            if (d.asOf && d.asOf.id !== row.asOf?.id) {
                unused.push(d);
            }
        });
    }

    // Clear any phased targets so they don't appear in any future conversions.
    const phasedTargetRow = getSeriesValue(MeasureSeriesNames.PhasedTarget);
    if (phasedTargetRow) {
        phasedTargetRow.decimalValue = null;
        phasedTargetRow.stringValue = null;
        phasedTargetRow.dateValue = null;
    }

    return { row: row, unused: unused };
};

const findFirstFirstRowWithSeries = (
    data: MonthRow[],
    seriesName: MeasureSeriesNames
): MonthRow | null => {
    return (
        data?.find((r) =>
            r.asOf?.values?.some(
                (v) =>
                    v.seriesType?.name === seriesName &&
                    (v.decimalValue !== null ||
                        v.dateValue !== null ||
                        v.stringValue !== null)
            )
        ) || null
    );
};

export const convertIntermittentData = (data: MonthRow[]) => {
    data.forEach((d) => {
        const phasedTarget = getSeriesValue(
            MeasureSeriesNames.PhasedTarget,
            d.asOf
        );
        if (isSeriesValueEmpty(d.interval) && phasedTarget) {
            d.interval.decimalValue = phasedTarget.decimalValue;
            phasedTarget.decimalValue = null;
        }
    });

    return { rows: data, unused: [] };
};

export const convertMonthData = (
    data: MonthRow[],
    fyStartDate: string,
    combineMethod: CombineMethod,
    measureId: string | null
) => {
    let currentMonth = dayjs(fyStartDate);
    let nextMonth = currentMonth.add(1, 'month');
    let formattedAsOfDate = currentMonth.format('YYYY-MM-DD');

    const array: MonthRow[] = [];
    let unused: MonthRow[] = [];

    for (let mIndex = 0; mIndex < 12; mIndex++) {
        if (mIndex > 0) {
            currentMonth = nextMonth;
            nextMonth = nextMonth.add(1, 'month');
            formattedAsOfDate = currentMonth.format('YYYY-MM-DD');
        }

        const qData = data.filter((d) => {
            const asOfDate = dayjs(d.dt);
            return asOfDate >= currentMonth && asOfDate < nextMonth;
        });

        const phasingData =
            data
                .slice()
                .filter((d) => dayjs(d.dt).isBefore(nextMonth))
                .sort((a, b) => dayjs(b.dt).valueOf() - dayjs(a.dt).valueOf())
                .find((d) =>
                    d.asOf?.values?.some(
                        (v) =>
                            v.seriesType?.name ===
                            MeasureSeriesNames.PhasedTarget
                    )
                ) || null;

        const combo = combineData(
            qData,
            phasingData,
            combineMethod,
            mIndex,
            formattedAsOfDate,
            FrequencyTypes.Monthly,
            measureId,
            fyStartDate
        );

        array.push(combo.row);
        unused = unused.concat(combo.unused);
    }

    return { rows: array, unused: unused };
};

/*
export const getQuarterData = (data: MonthRow[], fyStartDate: string) => {
    // if (fyStartDate) {
    //     data = filterFiscalYearOnly(data, fyStartDate);
    // }

    let currentMonth = dayjs(fyStartDate);
    let formattedAsOfDate = currentMonth.format('YYYY-MM-DD');

    let row: MonthRow | undefined = undefined;
    const array: MonthRow[] = [];

    if (!data || data.length === 0) {
        return [
            createEmptyRow(
                0,
                formattedAsOfDate,
                FrequencyTypes.Quarterly,
                fyStartDate
            ),
        ];
    }

    let lastQIndex = -1;
    for (let index = 0; index < data.length; index++) {
        const qindex = Math.floor(index / 3);

        if (index > 0) {
            currentMonth = currentMonth.add(3, 'month');
        }
        formattedAsOfDate = currentMonth.format('YYYY-MM-DD');

        if (!row || lastQIndex !== qindex) {
            lastQIndex = qindex;
            row = createEmptyRow(
                qindex,
                formattedAsOfDate,
                FrequencyTypes.Quarterly,
                fyStartDate
            );
            array.push(row);
        }

        if (!row.interval.decimalValue) row.interval.decimalValue = 0;
        if (!row.actual.decimalValue) row.actual.decimalValue = 0;

        row.interval.decimalValue +=
            data[index as number].interval.decimalValue ?? 0;
        row.forecast =
            row.forecast ?? 0 + (data[index as number].forecast ?? 0);
        row.actual.decimalValue +=
            data[index as number].actual.decimalValue ?? 0;
        row.status = data[index as number].status; //row.actual.decimalValue / row.interval.decimalValue;
    }
    return array;
};
*/

export const convertQuarterData = (
    data: MonthRow[],
    fyStartDate: string,
    combineMethod: CombineMethod,
    measureId: string | null
) => {
    let currentMonth = dayjs(fyStartDate);
    let nextMonth = currentMonth.add(3, 'month');
    let formattedAsOfDate = currentMonth.format('YYYY-MM-DD');

    // if (!data || data.length === 0) {
    //     return {
    //         rows: [
    //             createEmptyRow(0, formattedAsOfDate, FrequencyTypes.Quarterly),
    //             createEmptyRow(1, formattedAsOfDate, FrequencyTypes.Quarterly),
    //             createEmptyRow(2, formattedAsOfDate, FrequencyTypes.Quarterly),
    //             createEmptyRow(3, formattedAsOfDate, FrequencyTypes.Quarterly),
    //         ],
    //         unused: [],
    //     };
    // }

    const array: MonthRow[] = [];
    let unused: MonthRow[] = [];
    for (let qIndex = 0; qIndex < 4; qIndex++) {
        if (qIndex > 0) {
            currentMonth = nextMonth;
            nextMonth = nextMonth.add(3, 'month');
            formattedAsOfDate = currentMonth.format('YYYY-MM-DD');
        }

        const qData = data.filter((d) => {
            const asOfDate = dayjs(d.dt);
            return asOfDate >= currentMonth && asOfDate < nextMonth;
        });

        const lastPhasedTarget =
            data
                .slice()
                .filter((d) => dayjs(d.dt).isBefore(nextMonth))
                .sort((a, b) => dayjs(b.dt).valueOf() - dayjs(a.dt).valueOf())
                .find((d) =>
                    d.asOf?.values?.some(
                        (v) =>
                            v.seriesType?.name ===
                            MeasureSeriesNames.PhasedTarget
                    )
                ) || null;

        const combo = combineData(
            qData,
            lastPhasedTarget,
            combineMethod,
            qIndex,
            formattedAsOfDate,
            FrequencyTypes.Quarterly,
            measureId,
            fyStartDate
        );

        array.push(combo.row);
        unused = unused.concat(combo.unused);
    }

    return { rows: array, unused: unused };
};

export const convertYearData = (
    data: MonthRow[],
    fyStartDate: string,
    combineMethod: CombineMethod,
    measureId: string | null
) => {
    if (fyStartDate) {
        data = filterFiscalYearOnly(data, fyStartDate);
    }

    const formattedAsOfDate = dayjs(fyStartDate).format('YYYY-MM-DD');

    const lastPhasedTarget =
        data
            .slice()
            .sort((a, b) => dayjs(b.dt).valueOf() - dayjs(a.dt).valueOf())
            .find((d) =>
                d.asOf?.values?.some(
                    (v) =>
                        v.seriesType?.name === MeasureSeriesNames.PhasedTarget
                )
            ) || null;

    const combo = combineData(
        data,
        lastPhasedTarget,
        combineMethod,
        0,
        formattedAsOfDate,
        FrequencyTypes.Yearly,
        measureId,
        fyStartDate
    );

    return { rows: [combo.row], unused: combo.unused };
};

export const filterFiscalYearOnly = (
    data: MonthRow[],
    fyStartDate: string | undefined
) => {
    const fyStart = dayjs(fyStartDate);
    const fyEnd = dayjs(fyStartDate).add(1, 'year');

    return data
        .filter((d) => !d.isDeleted)
        .filter((d) => {
            const asOfDate = dayjs(d.dt);
            return asOfDate >= fyStart && asOfDate < fyEnd;
        });
};

export type ChartData = {
    Label: string;
    Date: string | null;
    Target: number | string | null;
    TargetRemaining?: number | null | undefined;
    TargetOver?: number | null | undefined;
    Actual: number | string | null;
    Forecast: number | null;
    StatusValue?: number | null;
    ArrowDirection?: ArrowDirection;
    ArrowColour?: Arrows;
    isDeleted?: boolean;
    isCurrentPeriod?: boolean;
};

export type ChartDataRaw = {
    // Needed because isDeleted casuses a problem
    Label: string;
    Date: string | null;
    Target: number | string | null;
    Actual: number | string | null;
    Forecast: number | null;
    StatusValue?: number | string | null;
    [key: string]: number | string | null | undefined;
};

export const convertToChartData = (data: MonthRow[]): ChartData[] => {
    return data.map((d) => {
        return {
            Label: d.label,
            Date: d.dt || d.asOf?.asOfDate || null,
            Target: d.interval.decimalValue,
            Actual: d.actual.decimalValue,
            Forecast: d.forecast,
            StatusValue: d.status?.statusValue,
            ArrowDirection: d.status?.arrowDirection,
            ArrowColour: d.status?.arrowColour,
        };
    });
};

export const convertToYTDChartData = (data: MonthRow[]): ChartData[] => {
    return data.map((d) => {
        return {
            Label: d.label,
            Date: d.dt || d.asOf?.asOfDate || null,
            Target: d.ytd,
            Actual: d.actualYtd,
            Forecast: d.forecastYtd,
        };
    });
};

export const convertStatusToChartData = (data: MonthRow[]): ChartData[] => {
    return data.map((d) => {
        return {
            Label: d.label,
            Date: d.dt || d.asOf?.asOfDate || null,
            Target: d.interval.stringValue,
            Actual: d.actual.stringValue,
            Forecast: d.forecast,
            StatusValue: d.actual.stringValue ? d.status?.statusValue : null,
            ArrowDirection: d.status?.arrowDirection,
            ArrowColour: d.status?.arrowColour,
        };
    });
};

export const recalcYtd = (data: MonthRow[]) => {
    let intervalYtd = 0;
    let actualYtd = 0;
    let forecastYtd = 0;

    for (let i = 0; i < data.length; i++) {
        const d = data[i as number];

        if (!d.isDeleted) {
            actualYtd += d.actual.decimalValue ?? 0;
            intervalYtd += d.interval.decimalValue ?? 0;
            forecastYtd += d.forecast ?? 0;
        }

        d.ytd = intervalYtd;
        d.actualYtd = actualYtd;
        d.forecastYtd = forecastYtd;
    }
};
