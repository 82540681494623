import React from 'react';
import { Shimmer, ShimmerElementType } from '@fluentui/react';
import MeasureComposedRechart from './MeasureComposedRechart';
import { MonthRow } from '../utils/measureUtils';
import { YearProgressChart } from './MeasureYearProgressChart';
import {
    ChartDisplay,
    GetMeasureQuery,
    useGetMeasureValueFormulaQuery,
} from '../../../data/types';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import {
    AdvanceCard,
    AdvanceCardActionButton,
} from '../../../components/AdvanceCard';
import { MeasureVisualisationOptions } from './MeasureVisualisationOptions';
import { useChartData } from '../hooks/useChartData';
import { useStateContext } from '../../../services/contextProvider';

export function MeasureValuesChartCard(props: {
    fyStartDate: string | undefined;
    measure: ExtractQueryArrayType<GetMeasureQuery, ['measure']> | null;
    data: MonthRow[];
    isSavingValues: boolean;
    isLoading: boolean;
    isReadOnly: boolean;
}): JSX.Element {
    const {
        measure,
        fyStartDate,
        data,
        isSavingValues,
        isLoading,
        isReadOnly,
    } = props;

    const {
        chartDisplay,
        chartData,
        chartActualMode,
        isStatusOnly,
        chartTooltipTitle,
    } = useChartData(data, measure, fyStartDate);

    const measureFormat = measure
        ? {
              currencySymbol: measure.currency?.symbol || null,
              decimalPlaces: measure.decimalPlaces,
              measureType: measure.measureType,
              statusType: measure.statusType,
              multiplier: measure.multiplier,
              isStatusLimited: measure.isStatusLimited,
          }
        : null;

    const { currentTenantId } = useStateContext();

    const { data: calculatedData } = useGetMeasureValueFormulaQuery({
        skip:
            !currentTenantId ||
            !measure?.id ||
            chartDisplay.key != ChartDisplay.Calculated,
        variables: {
            tenantId: currentTenantId || '',
            measureId: measure?.id || '',
        },
    });

    const buttons: AdvanceCardActionButton[] = [];

    if (!isReadOnly && measure) {
        buttons.push({
            key: 'displayOptions',
            text: 'Options',
            iconProps: { iconName: 'BIDashboard' },
            menuProps: {
                items: [
                    {
                        key: 'VisualisationOptionsContainer',
                        onRender: () => {
                            return (
                                <MeasureVisualisationOptions
                                    measure={measure}
                                />
                            );
                        },
                    },
                ],
            },
        });
    }

    return (
        <AdvanceCard
            title={measure ? chartDisplay.text : ' '}
            buttons={buttons}
        >
            <div style={{ height: 240 }}>
                <Shimmer
                    isDataLoaded={!isLoading && !!measure && !isSavingValues}
                    shimmerElements={[
                        {
                            type: ShimmerElementType.gap,
                            width: 16,
                            height: 232,
                        },
                        {
                            type: ShimmerElementType.line,
                            height: 232,
                        },
                        {
                            type: ShimmerElementType.gap,
                            width: 16,
                            height: 232,
                        },
                    ]}
                >
                    {(chartDisplay.key === ChartDisplay.ByFrequency ||
                        chartDisplay.key === ChartDisplay.Ftd) &&
                        !!chartData &&
                        !!measure &&
                        !!measureFormat && (
                            <MeasureComposedRechart
                                data={chartData}
                                showForecast={measure.showForecast}
                                measureFormat={measureFormat}
                                actualMode={chartActualMode}
                                width="100%"
                                height={240}
                                statusOnly={isStatusOnly}
                                tooltipTitle={chartTooltipTitle}
                            />
                        )}
                    {chartDisplay.key === ChartDisplay.Qtd &&
                        chartData &&
                        measure &&
                        measureFormat && (
                            <MeasureComposedRechart
                                data={chartData}
                                showForecast={measure.showForecast}
                                measureFormat={measureFormat}
                                actualMode={chartActualMode}
                                width="100%"
                                height={240}
                                tooltipTitle={chartTooltipTitle}
                            />
                        )}
                    {(chartDisplay.key === ChartDisplay.Ytd ||
                        chartDisplay.key === ChartDisplay.YtdVsFullYear) &&
                        chartData?.length &&
                        measureFormat && (
                            <YearProgressChart
                                yearChartData={chartData[0]}
                                tooltipTitle={chartTooltipTitle}
                                measureFormat={measureFormat}
                                showForecast={measure?.showForecast || false}
                                width="100%"
                                height={240}
                                statusOnly={isStatusOnly}
                            />
                        )}
                    {chartDisplay.key === ChartDisplay.Calculated &&
                        !!chartData &&
                        !!measure &&
                        !!measureFormat && (
                            <MeasureComposedRechart
                                data={chartData}
                                showForecast={measure.showForecast}
                                measureFormat={measureFormat}
                                actualMode={chartActualMode}
                                width="100%"
                                height={240}
                                statusOnly={isStatusOnly}
                                tooltipTitle={chartTooltipTitle}
                                isStacked={true}
                                formulaData={
                                    calculatedData?.measureValueFormula
                                }
                            />
                        )}
                </Shimmer>
            </div>
        </AdvanceCard>
    );
}
