import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import {
    IPersonaProps,
    NormalPeoplePicker,
    IBasePickerSuggestionsProps,
    BasePicker,
    IPeoplePickerProps,
} from '@fluentui/react';

import {
    GetContributorsSearchDocument,
    GetContributorsSearchQuery,
    GetContributorsSearchQueryVariables,
    GetMissionSearchDocument,
    GetMissionSearchQuery,
    GetMissionSearchQueryVariables,
} from '../../data/types';

import { useStateContext } from '../../services/contextProvider';
import { navigation } from '../../services/navigation';
import { photoService } from '../../services/photo.service';
import { useViewport } from '../../hooks/useViewport';

export function SearchPicker(props: {
    onSearchNavigated: () => void;
}): JSX.Element {
    const navigate = useNavigate();
    const client = useApolloClient();

    interface SearchResult extends IPersonaProps {
        teamCode: string;
        missionId?: string;
        contributorId?: string;
    }

    const { width } = useViewport();
    const mobileBreakpoint = 560;

    const { currentTenantId, currentTenantCode, currentFinancialYearCode } =
        useStateContext();

    const ref = useRef<BasePicker<IPersonaProps, IPeoplePickerProps>>(null);

    useEffect(() => {
        ref.current?.focus();
    }, []);

    const handleResolveSuggestions = async (
        filterText: string
    ): Promise<IPersonaProps[]> => {
        if (!filterText?.trim()) {
            return [];
        }

        const missionQuery = client.query<
            GetMissionSearchQuery,
            GetMissionSearchQueryVariables
        >({
            query: GetMissionSearchDocument,
            variables: {
                tenantId: currentTenantId || '',
                searchText: filterText,
                isWritable: false,
                isImportable: false,
                includeInactive: false,
                includeDeleted: false,
                financialYearCode: currentFinancialYearCode || null,
                divisionId: null,
            },
        });

        const contributorQuery = client.query<
            GetContributorsSearchQuery,
            GetContributorsSearchQueryVariables
        >({
            query: GetContributorsSearchDocument,
            variables: {
                tenantId: currentTenantId || '',
                searchText: filterText,
                financialYearCode: currentFinancialYearCode || null,
            },
        });

        const results = await Promise.all([missionQuery, contributorQuery]);
        const missionResults = results[0].data.missions || [];
        const contributorResults = results[1].data.contributors || [];

        const searchResults: SearchResult[] = [
            ...missionResults.map((mission) => {
                const teamCode =
                    mission.leaderOfTeams.length > 0
                        ? mission.leaderOfTeams[0].code
                        : mission.team?.code || '';
                return {
                    text: mission?.owner || mission?.username,
                    secondaryText: mission?.title,
                    teamCode: teamCode,
                    missionId: mission?.id,
                    imageUrl: photoService.getImageUrl(mission.userId),
                    resultType: 'mission',
                } as SearchResult;
            }),
            ...contributorResults.map((c) => {
                return {
                    text: c?.userDisplayName,
                    secondaryText: 'Contributor',
                    teamCode: c.team?.code,
                    contributorId: c?.id,
                    imageUrl: photoService.getImageUrl(c.userId),
                } as SearchResult;
            }),
        ];

        return searchResults;
    };

    const onItemSelected = (selectedItem: IPersonaProps | undefined): null => {
        const result = selectedItem as SearchResult;

        const path = navigation.getPathForParams({
            tenantCode: currentTenantCode || '',
            financialYearCode: currentFinancialYearCode || '',
            teamCode: result.teamCode,
            missionId: result.missionId,
            contributorId: result.contributorId,
        });

        navigate(path);
        props.onSearchNavigated();
        return null;
    };

    const getTextFromItem = (persona: IPersonaProps): string => {
        return persona.text as string;
    };

    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Results',
        mostRecentlyUsedHeaderText: 'Results',
        noResultsFoundText: 'No results found',
        loadingText: 'Loading',
        showRemoveButtons: false,
        suggestionsAvailableAlertText: 'Results available',
        suggestionsContainerAriaLabel: 'Results',
    };

    return (
        <NormalPeoplePicker
            componentRef={ref}
            inputProps={{
                placeholder: 'Search',
            }}
            onResolveSuggestions={handleResolveSuggestions}
            onDismiss={(ev) => {
                ev?.preventDefault();
                return false;
            }}
            onItemSelected={onItemSelected}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={suggestionProps}
            styles={{
                root: { width: width > mobileBreakpoint ? 250 : '100%' },
            }}
            resolveDelay={300}
        />
    );
}
