import React from 'react';

import { EditPanel } from './shared/EditPanel';
import {
    Stack,
    Text,
    TextField,
    Dropdown,
    IDropdownOption,
} from '@fluentui/react';
import useForm from '../hooks/useForm';
import {
    useGetDivisionsQuery,
    useUpdateTeamMutation,
    useDeleteTeamMutation,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { InputShimmer } from './inputs';
import { MissionPicker } from './MissionPicker';
import DeleteModal from './shared/DeleteModal';
import orderBy from 'lodash/orderBy';

type EditTeamPanelProps = {
    team: {
        id: string | null;
        code: string | null;
        name: string | null;
        division: {
            id: string | null;
            financialYear: {
                code: string | null;
            } | null;
        } | null;
        leaderMission: {
            id: string | null;
            title: string | null;
            owner: string | null;
            userId: string | null;
        } | null;
        version: string | null;
    };
    showPanel: boolean;
    onCancel: () => void;
    onSave: () => void;
    fyCodeFilter: string | null;
};

export default function EditTeamPanel(props: EditTeamPanelProps): JSX.Element {
    const { currentTenantId, currentRoles } = useStateContext();

    const [updateTeam, { loading: isSaving, error: saveError }] =
        useUpdateTeamMutation({
            onCompleted: props.onSave,
            refetchQueries: ['GetTeams'],
        });

    const { data: divisionData, loading: divisionsLoading } =
        useGetDivisionsQuery({
            skip: !currentTenantId || !props.showPanel,
            variables: {
                tenantId: currentTenantId || '',
                financialYearCode: props.fyCodeFilter,
            },
        });

    const [deleteTeamMutation, { loading: isDeleting, error: deleteError }] =
        useDeleteTeamMutation();

    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);

    type formValuesType = {
        code: string;
        name: string;
        divisionId: string | null;
        leaderMissionId: string | null;
    };

    const formDefaultValues: formValuesType = {
        code: props.team?.code || '',
        name: props.team?.name || '',
        divisionId: props.team?.division?.id || null,
        leaderMissionId: props.team?.leaderMission?.id || null,
    };

    const formCallback = (values: formValuesType): void => {
        updateTeam({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    id: props.team.id,
                    code: values.code,
                    name: values.name,
                    divisionId: values.divisionId,
                    leaderMissionId: values.leaderMissionId,
                    version: props.team.version || null,
                },
            },
        });
    };

    const formValidate = (
        values: formValuesType
    ): {
        [key: string]: string;
    } => {
        const formErrorMessages: { [key: string]: string } = {};

        if (!values.code) {
            formErrorMessages.code = 'Enter a code';
        }

        if (!values.name) {
            formErrorMessages.name = 'Enter a name';
        }

        return formErrorMessages;
    };

    const { errors, handleChange, handleSubmit, updateValue, values } = useForm(
        formDefaultValues,
        formCallback,
        formValidate
    );

    const divisionOptions: IDropdownOption[] | undefined = orderBy(
        divisionData?.divisions || [],
        ['financialYear.code', 'code'],
        ['desc', 'asc']
    ).map((d) => {
        return {
            key: d.id || '',
            text: d.name || d.id || '',
            data: {
                fyCode: d.financialYear?.code,
                code: d.code,
            },
        };
    });

    const onMissionPickerChange = (
        missions: {
            id: string;
        }[]
    ): void => {
        let selectedMissionId: string | null = null;

        if (missions?.length > 0) {
            selectedMissionId = missions[0].id;
        }

        updateValue('leaderMissionId', selectedMissionId || undefined);
    };

    const onRenderDivisionOption = (
        option?: IDropdownOption
    ): JSX.Element | null => {
        if (!option) {
            return null;
        }

        return (
            <Stack>
                <Text>{option.text}</Text>
                {option.data && option.data.code && (
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                        <Text variant="tiny">{option.data.fyCode}</Text>
                        <Text variant="tiny">Code: {option.data.code}</Text>
                    </Stack>
                )}
            </Stack>
        );
    };

    const confirmDelete = (): void => {
        setIsConfirmingDelete(true);
    };

    const deleteTeamAsync = async (): Promise<void> => {
        await deleteTeamMutation({
            variables: {
                tenantId: currentTenantId || '',
                id: props.team.id || '',
                restore: false,
            },
            refetchQueries: ['GetTeams'],
        });

        setIsConfirmingDelete(false);

        if (props.onCancel) {
            props.onCancel();
        }
    };

    const onDeleteClick =
        currentRoles?.some((r) => r === 'GlobalAdmin') && props.team?.id
            ? confirmDelete
            : undefined;

    return (
        <React.Fragment>
            <EditPanel
                activeViewName="TeamEdit"
                onDismiss={props.onCancel}
                showPanel={props.showPanel}
                headerText="Team"
                isSaving={isSaving}
                isValid={true}
                saveErrorMessage={saveError?.message}
                onUpdateClick={handleSubmit}
                onDeleteClick={onDeleteClick}
            >
                <Stack tokens={{ childrenGap: 8 }}>
                    <TextField
                        label="Code"
                        required
                        name="code"
                        autoComplete="off"
                        defaultValue={formDefaultValues.code}
                        errorMessage={errors.code}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Name"
                        required
                        name="name"
                        autoComplete="off"
                        defaultValue={formDefaultValues.name}
                        errorMessage={errors.name}
                        onChange={handleChange}
                    />

                    <InputShimmer isDataLoaded={!divisionsLoading}>
                        <Dropdown
                            required
                            options={divisionOptions || []}
                            defaultSelectedKey={values.divisionId}
                            placeholder="Select a division"
                            styles={{
                                dropdownItem: {
                                    paddingTop: '22px',
                                    paddingBottom: '22px',
                                    lineHeight: '', // usually 20px which doesn't work with the extra line.
                                },
                                dropdownItemSelected: {
                                    paddingTop: '22px',
                                    paddingBottom: '22px',
                                },
                            }}
                            errorMessage={errors.divisionId}
                            onChange={(
                                _event: React.FormEvent,
                                option?: IDropdownOption | undefined
                            ): void => {
                                updateValue(
                                    'divisionId',
                                    option ? (option.key as string) : undefined
                                );
                            }}
                            onRenderOption={onRenderDivisionOption}
                            label="Division"
                        />
                    </InputShimmer>

                    <MissionPicker
                        label="Leader Mission"
                        selectedMissions={
                            props.team?.leaderMission
                                ? [
                                      {
                                          ...props.team.leaderMission,
                                          id: props.team.leaderMission.id || '',
                                      },
                                  ]
                                : []
                        }
                        disabled={!values.divisionId}
                        onChange={onMissionPickerChange}
                        itemLimit={1}
                        financialYearCode={
                            divisionData?.divisions?.find(
                                (d) => d.id === values.divisionId
                            )?.financialYear?.code ||
                            props.team.division?.financialYear?.code
                        }
                    />
                </Stack>
            </EditPanel>
            <DeleteModal
                activeViewName="TeamDelete"
                isOpen={isConfirmingDelete}
                onDismiss={(): void => setIsConfirmingDelete(false)}
                message="Are you sure you want to delete this team?"
                deleteAction={deleteTeamAsync}
                isDeleting={isDeleting}
                error={deleteError}
            />
        </React.Fragment>
    );
}
