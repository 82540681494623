import React, { useState } from 'react';
import { Stack, Dropdown, IDropdownOption } from '@fluentui/react';

import { FrequencyPeriods } from '../../data/types';

export function FrequencyInput(props: {
    disabled?: boolean;
    defaultFrequencyPeriod: FrequencyPeriods | undefined | null;
    onChange: (newFrequencyPeriod: FrequencyPeriods) => void;
}): JSX.Element {
    const frequencyPeriods: IDropdownOption[] = [
        // { key: FrequencyPeriods.Day, text: 'Daily' },
        // { key: FrequencyPeriods.Week, text: 'Weekly' },
        { key: FrequencyPeriods.Month, text: 'Monthly' },
        { key: FrequencyPeriods.Quarter, text: 'Quarterly' },
        { key: FrequencyPeriods.Year, text: 'Yearly' },
        { key: FrequencyPeriods.None, text: 'Intermittent' },
    ];

    const [frequencyPeriod, setFrequencyPeriod] = useState<FrequencyPeriods>(
        props.defaultFrequencyPeriod || FrequencyPeriods.Month
    );

    return (
        <Stack
            tokens={{ childrenGap: 8 }}
            horizontal
            verticalAlign={'end'}
            horizontalAlign="stretch"
        >
            <Stack.Item grow>
                <Dropdown
                    label="Update Frequency"
                    disabled={props.disabled}
                    defaultSelectedKey={frequencyPeriod}
                    options={frequencyPeriods}
                    onChange={(ev: React.FormEvent, option?): void => {
                        const newValue = option
                            ? (option.key as FrequencyPeriods)
                            : FrequencyPeriods.Month;
                        setFrequencyPeriod(newValue);
                        props.onChange(newValue);
                    }}
                />
            </Stack.Item>
        </Stack>
    );
}
