import {
    ApolloCache,
    ApolloError,
    DefaultContext,
    MutationUpdaterFunction,
} from '@apollo/client';
import dayjs from 'dayjs';
import {
    refetchGetDependenciesQuery,
    useUpdateTaskMutation,
    ResourceTask as ResourceTaskInput,
    Task,
    useUpdateResourceTaskMutation,
    DeleteTaskMutation,
    useDeleteTaskMutation,
    DeleteTaskMutationVariables,
} from '../data/types';
import { useCallback } from 'react';

type TaskType = {
    id: string | null;
    name: string | null;
    start: string | null;
    due: string | null;
    done: string | null;
    description: string | null;
    percentComplete: number | null;
    utcPostponed: string | null;
    utcCancelled: string | null;
    utcAtRisk: string | null;
    resourcedTasks: {
        id: string | null;
        missionId: string | null;
        name: string | null;
        start: string | null;
        due: string | null;
        done: string | null;
        utcAccepted: string | null;
        utcRejected: string | null;
        utcPostponed: string | null;
        utcCancelled: string | null;
        utcAtRisk: string | null;
        rejectedReason: string | null;
        utcResourceRemoved: string | null;
        resourceIsPrimary: boolean;
        effortResourceWeight: number;
        version: string | null;
        resource: {
            id: string | null;
            userId: string | null;
            displayName: string | null;
        } | null;
    }[];
};

export type UpdatedResourceType = {
    userId: string | null;
    resourceId: string | null;
    displayName: string | null;
    resourceIsPrimary: boolean;
    requestAgain?: boolean;
};

export const useResourceUpdater = (
    tenantId: string | null,
    financialYearCode: string | null,
    missionUserId: string | null
): {
    updateResourcedTasks: (
        task: TaskType,
        updatedResourcedTasks: UpdatedResourceType[]
    ) => Promise<void>;
    syncResources: (task: TaskType) => Promise<void>;
    isBusy: boolean;
    saveError: ApolloError | undefined;
    reset: () => void;
} => {
    const [
        updateTaskMutation,
        {
            loading: isUpdatingTask,
            error: saveTaskError,
            reset: resetTaskMutation,
        },
    ] = useUpdateTaskMutation();

    const [
        updateResourceTaskMutation,
        {
            loading: isUpdatingResourceTask,
            error: saveResourceTaskError,
            reset: resetResourceTaskMutation,
        },
    ] = useUpdateResourceTaskMutation();

    const cacheUpdater: MutationUpdaterFunction<
        DeleteTaskMutation,
        DeleteTaskMutationVariables,
        DefaultContext,
        ApolloCache<unknown>
    > = (cache, mutationResult) => {
        const deletedObject = mutationResult.data?.taskDelete?.deletedObject;
        if (deletedObject?.utcDeleted) {
            cache.evict({ id: cache.identify(deletedObject) });
            deletedObject.subTasks
                ?.filter((st) => st.utcDeleted)
                .forEach((st) => {
                    cache.evict({ id: cache.identify(st) });
                });
            cache.gc();
        }
    };

    const [deleteTaskMutation, { loading: isDeleting }] = useDeleteTaskMutation(
        {
            update: cacheUpdater,
        }
    );

    const getDateForInput = (date: string | null) =>
        date ? dayjs(date).format('YYYY-MM-DD') : null;

    const createNewTaskForResource = (
        task: {
            id: string | null;
            name: string | null;
            start: string | null;
            due: string | null;
            done: string | null;
            description: string | null;
            percentComplete: number | null;
        },
        resourceId: string | null,
        userId: string | null,
        name: string | null,
        resourceIsPrimary: boolean
    ): Task => {
        return task
            ? {
                  id: null,
                  missionId: null,
                  name: task?.name,
                  description: task?.description,
                  percentComplete: task?.percentComplete || 0,
                  parentTaskId: null,
                  resourcedFromTaskId: task?.id,
                  start: getDateForInput(task?.start),
                  due: getDateForInput(task?.due),
                  done: getDateForInput(task?.done),
                  review: null,
                  utcAccepted: null,
                  utcRejected: null,
                  rejectedReason: null,
                  resourceId: null,
                  taskCategoryId: null,
                  utcResourceRemoved: null,
                  utcChangesPending: null,
                  utcCancelled: null,
                  utcPostponed: null,
                  utcAtRisk: null,
                  isPercentageCompleteFromResources: false,
                  isPercentageCompleteFromSubTasks: false,
                  linkedMeasures: [],
                  sequence: 0,
                  resource: {
                      id: resourceId,
                      name: name,
                      userId: userId,
                      version: '',
                  },
                  isDuplicate: false,
                  resourceIsPrimary: resourceIsPrimary,
                  effortResourceWeight: 1,
                  effortWeight: 1,
                  costWeight: 1,
                  version: '',
              }
            : ({} as Task);
    };

    const updateResourcedTasks = async (
        task: TaskType,
        updatedResourcedTasks: UpdatedResourceType[]
    ): Promise<void> => {
        await Promise.all(
            updatedResourcedTasks.map(async (selectedResource) => {
                const existing =
                    (selectedResource.userId &&
                        task.resourcedTasks.find(
                            (cr) =>
                                selectedResource.userId === cr?.resource?.userId
                        )) ||
                    (selectedResource.resourceId &&
                        task.resourcedTasks.find(
                            (cr) =>
                                selectedResource.resourceId === cr?.resource?.id
                        )) ||
                    (!selectedResource.resourceId &&
                        task.resourcedTasks.find(
                            (cr) =>
                                selectedResource.displayName ===
                                cr?.resource?.displayName
                        ));

                if (!existing) {
                    const newTask = createNewTaskForResource(
                        task,
                        selectedResource.resourceId || null,
                        selectedResource.userId || null,
                        selectedResource.displayName || null,
                        selectedResource.resourceIsPrimary
                    );

                    await updateTaskMutation({
                        variables: {
                            tenantId: tenantId || '',
                            input: newTask,
                        },
                        refetchQueries:
                            newTask.resource?.userId &&
                            tenantId &&
                            financialYearCode
                                ? [
                                      refetchGetDependenciesQuery({
                                          tenantId: tenantId,
                                          userId: newTask.resource.userId,
                                          financialYearCode: financialYearCode,
                                      }),
                                  ]
                                : [],
                        awaitRefetchQueries: true,
                    });
                } else if (existing.resource?.id) {
                    const taskToUpdate = task.resourcedTasks?.find(
                        (rt) => rt?.resource?.id === existing.resource?.id
                    );
                    if (taskToUpdate?.id) {
                        let updateResourceTask = false;
                        const input: ResourceTaskInput = {
                            id: taskToUpdate.id,
                            name: taskToUpdate.name,
                            start: getDateForInput(taskToUpdate.start),
                            due: getDateForInput(taskToUpdate.due),
                            done: getDateForInput(taskToUpdate.done),
                            utcPostponed: taskToUpdate.utcPostponed,
                            utcCancelled: taskToUpdate.utcCancelled,
                            utcAtRisk: taskToUpdate.utcAtRisk,
                            utcRejected: taskToUpdate.utcRejected,
                            rejectedReason: taskToUpdate.rejectedReason,
                            utcResourceRemoved: taskToUpdate.utcResourceRemoved,
                            utcChangesPending: null, // Set below
                            resourceIsPrimary: taskToUpdate.resourceIsPrimary,
                            effortResourceWeight:
                                taskToUpdate.effortResourceWeight,
                            version: taskToUpdate.version,
                        };

                        if (selectedResource.requestAgain) {
                            input.utcRejected = null;
                            input.rejectedReason = null;
                            updateResourceTask = true;
                        }

                        // Update resource for existing
                        if (task) {
                            if (taskToUpdate.name !== task.name) {
                                if (
                                    taskToUpdate?.utcAccepted &&
                                    taskToUpdate.missionId
                                ) {
                                    input.utcChangesPending = dayjs
                                        .utc()
                                        .format();
                                    updateResourceTask = true;
                                } else if (
                                    taskToUpdate.resource?.userId &&
                                    taskToUpdate.resource?.userId !==
                                        missionUserId
                                ) {
                                    // Only cascade the name if this is somebody else's unaccepted task.
                                    input.name = task.name;
                                    updateResourceTask = true;
                                }
                            }
                            if (taskToUpdate.start !== task.start) {
                                if (
                                    taskToUpdate?.utcAccepted &&
                                    taskToUpdate.missionId
                                ) {
                                    input.utcChangesPending = dayjs
                                        .utc()
                                        .format();
                                } else {
                                    input.start = getDateForInput(task.start);
                                }
                                updateResourceTask = true;
                            }
                            if (taskToUpdate.due !== task.due) {
                                if (
                                    taskToUpdate?.utcAccepted &&
                                    taskToUpdate.missionId
                                ) {
                                    input.utcChangesPending = dayjs
                                        .utc()
                                        .format();
                                } else {
                                    input.due = getDateForInput(task.due);
                                }
                                updateResourceTask = true;
                            }
                            if (taskToUpdate.done !== task.done) {
                                if (
                                    !taskToUpdate?.utcAccepted &&
                                    taskToUpdate.missionId
                                ) {
                                    input.done = getDateForInput(task.done);
                                    updateResourceTask = true;
                                } else if (!taskToUpdate.done) {
                                    // Only flag changes when the done date is not set.
                                    input.utcChangesPending = dayjs
                                        .utc()
                                        .format();
                                    updateResourceTask = true;
                                }
                            }
                            if (
                                taskToUpdate.utcPostponed !== task.utcPostponed
                            ) {
                                if (
                                    taskToUpdate?.utcAccepted &&
                                    taskToUpdate.missionId
                                ) {
                                    input.utcChangesPending = dayjs
                                        .utc()
                                        .format();
                                } else {
                                    input.utcPostponed = task.utcPostponed;
                                }
                                updateResourceTask = true;
                            }
                            if (
                                taskToUpdate.utcCancelled !== task.utcCancelled
                            ) {
                                if (
                                    taskToUpdate?.utcAccepted &&
                                    taskToUpdate.missionId
                                ) {
                                    input.utcChangesPending = dayjs
                                        .utc()
                                        .format();
                                } else {
                                    input.utcCancelled = task.utcCancelled;
                                }
                                updateResourceTask = true;
                            }
                            if (taskToUpdate.utcAtRisk !== task.utcAtRisk) {
                                if (
                                    taskToUpdate?.utcAccepted &&
                                    taskToUpdate.missionId
                                ) {
                                    input.utcChangesPending = dayjs
                                        .utc()
                                        .format();
                                } else {
                                    input.utcAtRisk = task.utcAtRisk;
                                }
                                updateResourceTask = true;
                            }
                        }

                        if (
                            taskToUpdate.resourceIsPrimary !==
                            selectedResource.resourceIsPrimary
                        ) {
                            input.resourceIsPrimary =
                                selectedResource.resourceIsPrimary;
                            updateResourceTask = true;
                        }

                        if (updateResourceTask) {
                            await updateResourceTaskMutation({
                                variables: {
                                    tenantId: tenantId || '',
                                    input: input,
                                },
                                refetchQueries:
                                    taskToUpdate.resource?.userId &&
                                    tenantId &&
                                    financialYearCode
                                        ? [
                                              refetchGetDependenciesQuery({
                                                  tenantId: tenantId,
                                                  userId: taskToUpdate.resource
                                                      .userId,
                                                  financialYearCode:
                                                      financialYearCode,
                                              }),
                                          ]
                                        : [],
                                awaitRefetchQueries: true,
                            });
                        }
                    }
                }
            })
        );

        // Check the resources that have been removed.
        await Promise.all(
            task.resourcedTasks?.map(async (rt) => {
                const removed =
                    (rt?.resource?.userId &&
                        !updatedResourcedTasks.find(
                            (sr) => sr.userId === rt?.resource?.userId
                        )) ||
                    (rt?.resource?.id &&
                        !updatedResourcedTasks.find(
                            (sr) => sr.resourceId === rt?.resource?.id
                        ));

                if (removed) {
                    await deleteTaskMutation({
                        variables: {
                            tenantId: tenantId || '',
                            id: rt.id || '',
                            isTaskResource: true,
                            restore: false,
                        },
                        refetchQueries:
                            rt.resource?.userId && tenantId && financialYearCode
                                ? [
                                      refetchGetDependenciesQuery({
                                          tenantId: tenantId,
                                          userId: rt.resource.userId,
                                          financialYearCode: financialYearCode,
                                      }),
                                  ]
                                : [],
                        awaitRefetchQueries: true,
                    });
                }
            })
        );
    };

    /// Updates the resources based on the start / due / done of the base task.
    const syncResources = async (task: TaskType): Promise<void> => {
        await updateResourcedTasks(
            task,
            task.resourcedTasks.map((r) => {
                return {
                    userId: r.resource?.userId || null,
                    resourceId: r?.resource?.id || null,
                    resourceIsPrimary: r?.resourceIsPrimary,
                    displayName: r?.resource?.displayName || null,
                };
            })
        );
    };

    const reset = useCallback(() => {
        resetTaskMutation();
        resetResourceTaskMutation();
    }, [resetTaskMutation, resetResourceTaskMutation]);

    return {
        updateResourcedTasks: updateResourcedTasks,
        syncResources: syncResources,
        isBusy: isUpdatingTask || isUpdatingResourceTask || isDeleting,
        saveError: saveTaskError || saveResourceTaskError,
        reset,
    };
};
