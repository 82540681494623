import { Label } from '@fluentui/react';
import styles from './ApiHelp.module.css';

export function ApiHelpMissions(): JSX.Element {
    return (
        <div>
            <h1>Missions</h1>
            A company may have one or more Missions.
            <br />
            <br />
            <Label>OData Query Parameters</Label>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td>
                            <code className={styles.method}>GET</code>
                            <code className={styles.code}>/Mission</code>
                        </td>
                        <td>
                            Get a List of Missions
                            <br />
                            <br />
                            Expandable properties: <br />
                            <code className={styles.code}>?$expand=Team</code>
                            <br />
                            <code className={styles.code}>
                                ?$expand=Freedoms
                            </code>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <code className={styles.method}>GET</code>
                            <code className={styles.code}>
                                /Mission(&#123;id&#125;)
                            </code>
                        </td>
                        <td>
                            Get single Mission
                            <p>
                                <i>
                                    (Replace &#123;id&#125; with the guid
                                    identifying the individual record).
                                </i>
                            </p>
                            <p>
                                This can also be done with a filter query:
                                <br />
                                <i>
                                    <code className={styles.code}>
                                        $filter=Id eq &#123;id&#125;
                                    </code>
                                </i>
                            </p>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <code className={styles.method}>PATCH</code>
                            <code className={styles.code}>/Mission</code>
                        </td>
                        <td>
                            Update Mission.
                            <br />
                            <br />
                            Updatable properties: <br />
                            <code className={styles.code}>Title</code>
                            <br />
                            <code className={styles.code}>Owner</code>
                            <br />
                            <code className={styles.code}>
                                MissionStatement
                            </code>
                            <br />
                            <code className={styles.code}>MissionDate</code>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
