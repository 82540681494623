import * as React from 'react';
import PropTypes from 'prop-types';

import {
    ShimmeredDetailsList,
    SelectionMode,
    IColumn,
    DetailsListLayoutMode,
    IconButton,
    Toggle,
} from '@fluentui/react';

import { useSorter } from '../../../hooks/useSorter';
import { useUpdateTenantMutation } from '../../../data/types';

export type TenantDetailListProps = {
    tenants: {
        id: string | null;
        code: string | null;
        description: string | null;
        isEnabled: boolean;
        isAnalyticsEnabled: boolean;
        isMaiEnabled: boolean;
    }[];
    isDataLoaded: boolean;
    onItemEdit: (selectedId: string) => void;
};

export function TenantDetailList(props: TenantDetailListProps): JSX.Element {
    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(props.tenants, 'description', false, true);

    const [updateTenant] = useUpdateTenantMutation();

    const changeTenantEnabled = (
        tenantId: string,
        isEnabled: boolean
    ): void => {
        const tenant = props.tenants.find((t) => t.id === tenantId);

        if (tenant) {
            updateTenant({
                variables: {
                    input: {
                        id: tenantId,
                        code: tenant.code,
                        description: tenant.description,
                        isEnabled: isEnabled,
                        isAnalyticsEnabled: tenant.isAnalyticsEnabled,
                        isMaiEnabled: tenant.isMaiEnabled,
                    },
                },
                optimisticResponse: {
                    __typename: 'Mutations',
                    tenantUpdate: {
                        id: tenantId,
                        code: tenant.code,
                        description: tenant.description,
                        isEnabled: isEnabled,
                        isAnalyticsEnabled: tenant.isAnalyticsEnabled,
                        isMaiEnabled: tenant.isMaiEnabled,
                        __typename: 'TenantQL',
                    },
                },
            });
        }
    };

    const changeTenantAnalyticsEnabled = (
        tenantId: string,
        analyticsEnabled: boolean
    ): void => {
        const tenant = props.tenants.find((t) => t.id === tenantId);

        if (tenant) {
            updateTenant({
                variables: {
                    input: {
                        id: tenantId,
                        code: tenant.code,
                        description: tenant.description,
                        isEnabled: tenant.isEnabled,
                        isAnalyticsEnabled: analyticsEnabled,
                        isMaiEnabled: tenant.isMaiEnabled,
                    },
                },
                optimisticResponse: {
                    __typename: 'Mutations',
                    tenantUpdate: {
                        id: tenantId,
                        code: tenant.code,
                        description: tenant.description,
                        isEnabled: tenant.isEnabled,
                        isAnalyticsEnabled: analyticsEnabled,
                        isMaiEnabled: tenant.isMaiEnabled,
                        __typename: 'TenantQL',
                    },
                },
            });
        }
    };

    const changeTenantMaiEnabled = (
        tenantId: string,
        isMaiEnabled: boolean
    ): void => {
        const tenant = props.tenants.find((t) => t.id === tenantId);

        if (tenant) {
            updateTenant({
                variables: {
                    input: {
                        id: tenantId,
                        code: tenant.code,
                        description: tenant.description,
                        isEnabled: tenant.isEnabled,
                        isAnalyticsEnabled: tenant.isAnalyticsEnabled,
                        isMaiEnabled: isMaiEnabled,
                    },
                },
                optimisticResponse: {
                    __typename: 'Mutations',
                    tenantUpdate: {
                        id: tenantId,
                        code: tenant.code,
                        description: tenant.description,
                        isEnabled: tenant.isEnabled,
                        isAnalyticsEnabled: tenant.isAnalyticsEnabled,
                        isMaiEnabled: isMaiEnabled,
                        __typename: 'TenantQL',
                    },
                },
            });
        }
    };

    const columns: IColumn[] = [
        {
            key: 'description',
            name: 'Description',
            fieldName: 'description',
            isRowHeader: true,
            isMultiline: true,
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            isSorted: sortColumn === 'description',
            isSortedDescending: sortColumn === 'description' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('description', true),
        },
        {
            key: 'code',
            name: 'Code',
            fieldName: 'code',
            minWidth: 200,
            isResizable: true,
            isSorted: sortColumn === 'code',
            isSortedDescending: sortColumn === 'code' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('code', true),
        },
        {
            key: 'isEnabled',
            name: 'Enabled?',
            fieldName: 'isEnabled',
            minWidth: 100,
            isResizable: true,
            isSorted: sortColumn === 'isEnabled',
            isSortedDescending: sortColumn === 'isEnabled' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('isEnabled'),
            onRender: function renderEnabled(item: {
                id: string;
                isEnabled: boolean;
            }): JSX.Element {
                return (
                    <Toggle
                        onText="Yes"
                        offText="No"
                        checked={item.isEnabled}
                        onChange={(
                            ev: React.MouseEvent<HTMLElement>,
                            checked?: boolean
                        ): void => {
                            changeTenantEnabled(item.id, checked || false);
                        }}
                    />
                );
            },
        },
        {
            key: 'isAnalyticsEnabled',
            name: 'Analytics?',
            fieldName: 'isAnalyticsEnabled',
            minWidth: 100,
            isResizable: true,
            isSorted: sortColumn === 'isAnalyticsEnabled',
            isSortedDescending:
                sortColumn === 'isAnalyticsEnabled' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('isAnalyticsEnabled'),
            onRender: function renderEnabled(item: {
                id: string;
                isAnalyticsEnabled: boolean;
            }): JSX.Element {
                return (
                    <Toggle
                        onText="Yes"
                        offText="No"
                        checked={item.isAnalyticsEnabled}
                        onChange={(
                            ev: React.MouseEvent<HTMLElement>,
                            checked?: boolean
                        ): void => {
                            changeTenantAnalyticsEnabled(
                                item.id,
                                checked || false
                            );
                        }}
                    />
                );
            },
        },
        {
            key: 'isMaiEnabled',
            name: 'MAI Enabled?',
            fieldName: 'isMaiEnabled',
            minWidth: 100,
            isResizable: true,
            isSorted: sortColumn === 'isMaiEnabled',
            isSortedDescending: sortColumn === 'isMaiEnabled' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('isMaiEnabled'),
            onRender: function renderEnabled(item: {
                id: string;
                isMaiEnabled: boolean;
            }): JSX.Element {
                return (
                    <Toggle
                        onText="Yes"
                        offText="No"
                        checked={item.isMaiEnabled}
                        onChange={(
                            ev: React.MouseEvent<HTMLElement>,
                            checked?: boolean
                        ): void => {
                            changeTenantMaiEnabled(item.id, checked || false);
                        }}
                    />
                );
            },
        },
        {
            key: 'edit',
            name: 'Edit',
            isIconOnly: true,
            minWidth: 16,
            maxWidth: 16,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderActions(item: {
                id: string;
            }): JSX.Element {
                return (
                    <IconButton
                        key={item.id}
                        iconProps={{ iconName: 'Edit' }}
                        onClick={(): void => props.onItemEdit(item.id)}
                    />
                );
            },
        },
    ];

    return (
        <ShimmeredDetailsList
            setKey="items"
            items={sortedItems}
            compact={false}
            columns={columns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            enableShimmer={!props.isDataLoaded}
            ariaLabelForShimmer="Content is being fetched"
            ariaLabelForGrid="Item details"
            listProps={{
                renderedWindowsAhead: 0,
                renderedWindowsBehind: 0,
            }}
        />
    );
}

TenantDetailList.propTypes = {
    onItemEdit: PropTypes.func.isRequired,
};
