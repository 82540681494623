import { MeasureTypes, Multipliers } from '../data/types';

export const useMeasureValueFormatter = (
    measure:
        | {
              measureType: MeasureTypes;
              multiplier: Multipliers;
              currency: { symbol: string | null | undefined } | null;
              decimalPlaces: number;
          }
        | undefined
        | null
): {
    formatDecimalValue: (decimalValue: number | null) => string | null;
    isNumeric: boolean;
} => {
    return {
        isNumeric:
            measure?.measureType === MeasureTypes.Currency ||
            measure?.measureType === MeasureTypes.Percentage ||
            measure?.measureType === MeasureTypes.Numeric,
        formatDecimalValue: (decimalValue: number | null) => {
            if (!measure || decimalValue === null) {
                return null;
            }

            let adjustedNumber = decimalValue;

            if (measure.measureType === MeasureTypes.Percentage) {
                adjustedNumber = decimalValue * 100;
            } else if (measure.multiplier) {
                switch (measure.multiplier) {
                    case Multipliers.K:
                        adjustedNumber = adjustedNumber / 1000;
                        break;

                    case Multipliers.M:
                        adjustedNumber = adjustedNumber / 1000000;
                        break;

                    case Multipliers.B:
                        adjustedNumber = adjustedNumber / 1000000000;
                        break;
                }
            }

            let prefix: string | null = null;
            let suffix: string | null = null;

            if (measure.measureType === MeasureTypes.Percentage) {
                suffix = '%';
            } else {
                prefix = measure.currency?.symbol || null;
                suffix =
                    measure.multiplier &&
                    measure.multiplier !== Multipliers.None
                        ? measure.multiplier?.toString()
                        : null;
            }

            const decimalPlaces = Math.max(
                Math.min(measure.decimalPlaces, 4),
                0
            );

            // Use British English for consistency for now.
            const locale = 'en-GB';

            return `${prefix || ''} ${adjustedNumber.toLocaleString(locale, {
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces,
            })}${suffix || ''}`.trim();
        },
    };
};
