import React from 'react';
import { MutationUpdaterFn } from '@apollo/client';
import { DeleteCommentMutation, useDeleteCommentMutation } from '../data/types';
import DeleteModal from './shared/DeleteModal';

export function CommentDeleteModal(props: {
    tenantId: string;
    measureId?: string | null;
    taskId?: string | null;
    confirmDeleteCommentId: string | null | undefined;
    onDeleteComplete: () => void;
    onDismiss: () => void;
}): JSX.Element {
    const cacheUpdater: MutationUpdaterFn<DeleteCommentMutation> = (
        cache,
        mutationResult
    ) => {
        const deletedObject = mutationResult.data?.commentDelete?.deletedObject;
        if (deletedObject?.utcDeleted) {
            cache.evict({ id: cache.identify(deletedObject) });
            cache.gc();
        }
    };

    const [deleteComment, { loading: isDeleting, error: deleteError }] =
        useDeleteCommentMutation({
            update: cacheUpdater,
        });

    const deleteAsync = async () => {
        if (props.confirmDeleteCommentId) {
            await deleteComment({
                variables: {
                    id: props.confirmDeleteCommentId,
                    tenantId: props.tenantId,
                    restore: false,
                },
            });
        }
        props.onDeleteComplete();
    };

    return (
        <DeleteModal
            activeViewName={
                props.measureId
                    ? 'MeasureCommentDelete'
                    : props.taskId
                    ? 'TaskCommentDelete'
                    : null
            }
            isOpen={props.confirmDeleteCommentId !== null}
            onDismiss={props.onDismiss}
            message="Are you sure you want to delete this comment?"
            deleteAction={deleteAsync}
            isDeleting={isDeleting}
            error={deleteError}
        />
    );
}
