import React from 'react';
import { EditPanel } from './shared/EditPanel';
import {
    DefaultButton,
    Persona,
    PersonaSize,
    Stack,
    StackItem,
    TextField,
} from '@fluentui/react';
import useForm from '../hooks/useForm';
import { useGetCurrentUserQuery } from '../data/types';
import { ProfilePhotoInput } from './inputs/ProfilePhotoInput';
import { photoService } from '../services/photo.service';
import { InputShimmer } from './inputs';
import useHasPhoto from '../hooks/useHasPhoto';

type ProfilePanelProps = {
    showPanel: boolean;
    userId: string | undefined;
    onCancel: () => void;
    onSave: () => void;
    onPhotoChanged: () => void;
};

export default function ProfilePanel(props: ProfilePanelProps): JSX.Element {
    const [isSaving, setIsSaving] = React.useState(false);

    const [imageSrc, setImageSrc] = React.useState<string | undefined>();
    const [imageRotation, setImageRotation] = React.useState(0);
    const [imagePixelCrop, setImagePixelCrop] = React.useState<
        { x: number; y: number; width: number; height: number } | undefined
    >();

    type formValuesType = {
        displayName: string | null;
    };

    const formDefaultValues: formValuesType = {
        displayName: '',
    };

    const formValidate = (
        values: formValuesType
    ): {
        [key: string]: string;
    } => {
        return {
            displayName: !values.displayName ? 'Enter a value' : '',
        };
    };

    const formCallback = async (): Promise<void> => {
        setIsSaving(true);

        if (imageSrc && imagePixelCrop && props.userId) {
            const croppedImage = await photoService.getCroppedImgAsync(
                imageSrc,
                'image/jpeg',
                imagePixelCrop,
                imageRotation
            );

            if (croppedImage) {
                await photoService.uploadPhotoAsync(props.userId, croppedImage);
                setHasPhoto(true);
                setImageSrc('');
                setCurrentUserProfileImageSrc(
                    photoService.getImageUrl(props.userId) || ''
                );
                props.onPhotoChanged();
            }
        }

        setIsSaving(false);

        props.onSave();
    };

    const { errors, handleChange, handleSubmit, values } = useForm(
        formDefaultValues,
        formCallback,
        formValidate
    );

    const { loading, data } = useGetCurrentUserQuery({
        skip: !props.showPanel,
        fetchPolicy: 'network-only',
    });

    const onPhotoChanged = (
        imageSrc: string | undefined,
        pixelCrop: { x: number; y: number; width: number; height: number },
        rotation: number
    ): void => {
        setImageSrc(imageSrc);
        setImagePixelCrop(pixelCrop);
        setImageRotation(rotation);
    };

    if (!loading && props.showPanel) {
        values.displayName = data?.current_user?.displayName || '';
    }

    const { hasPhoto, setHasPhoto } = useHasPhoto(
        props.userId,
        props.showPanel
    );

    const [currentUserProfileImageSrc, setCurrentUserProfileImageSrc] =
        React.useState<string>(photoService.getImageUrl(props.userId) || '');

    const removePhotoHandler = async (): Promise<void> => {
        setIsSaving(true);

        if (props.userId) {
            await photoService.removePhotoAsync(props.userId);
            setHasPhoto(false);
            setImageSrc('');
            setCurrentUserProfileImageSrc('');
            props.onPhotoChanged();
        }

        setIsSaving(false);

        props.onSave();
    };

    return (
        <EditPanel
            activeViewName="UserProfile"
            onDismiss={props.onCancel}
            showPanel={props.showPanel}
            headerText="Profile"
            isSaving={isSaving}
            isValid={true}
            saveErrorMessage={undefined}
            onUpdateClick={(): void => {
                handleSubmit();
            }}
            onDeleteClick={undefined}
        >
            <Stack tokens={{ childrenGap: 8 }}>
                <InputShimmer isDataLoaded={!loading}>
                    <TextField
                        label="Display Name"
                        disabled
                        required
                        name="displayName"
                        value={values?.displayName || ''}
                        errorMessage={errors.displayName}
                        onChange={handleChange}
                    />
                </InputShimmer>

                <InputShimmer isDataLoaded={!loading}>
                    <ProfilePhotoInput onPhotoChanged={onPhotoChanged} />
                </InputShimmer>

                {!imageSrc && (
                    <StackItem
                        style={{
                            margin: '24px auto 0 auto',
                        }}
                    >
                        <Persona
                            imageUrl={currentUserProfileImageSrc}
                            imageInitials={(values?.displayName || '')
                                .split(' ')
                                .map((n) => n[0])
                                .join('')}
                            size={PersonaSize.size120}
                            hidePersonaDetails={true}
                            imageAlt="Profile Picture"
                        />
                        {hasPhoto && (
                            <DefaultButton
                                text="Remove Photo"
                                onClick={removePhotoHandler}
                                style={{ marginTop: '24px' }}
                            />
                        )}
                    </StackItem>
                )}
            </Stack>
        </EditPanel>
    );
}
