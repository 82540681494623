import React, { PropsWithChildren } from 'react';

import {
    Shimmer,
    ShimmerElementType,
    ShimmerElementsGroup,
} from '@fluentui/react';

type InputShimmerProps = {
    isDataLoaded: boolean;
    inputHeight?: number;
};

export function InputShimmer(
    props: PropsWithChildren<InputShimmerProps>
): JSX.Element {
    return (
        <Shimmer
            isDataLoaded={props.isDataLoaded}
            customElementsGroup={
                <div>
                    <ShimmerElementsGroup
                        width="100%"
                        shimmerElements={[
                            {
                                type: ShimmerElementType.line,
                                height: 24,
                                width: '100%',
                            },
                        ]}
                    />
                    <ShimmerElementsGroup
                        width="100%"
                        shimmerElements={[
                            {
                                type: ShimmerElementType.gap,
                                height: 5,
                                width: '100%',
                            },
                        ]}
                    />
                    <ShimmerElementsGroup
                        width="100%"
                        shimmerElements={[
                            {
                                type: ShimmerElementType.line,
                                height: props.inputHeight || 32,
                                width: '100%',
                            },
                        ]}
                    />
                </div>
            }
        >
            {props.children}
        </Shimmer>
    );
}
