import React from 'react';
import { Spinner, Stack, mergeStyleSets } from '@fluentui/react';
import { useThemes } from '../../hooks/useThemes';

export const ChatBubble = ({
    content: text,
    tailPosition,
    persona,
    onClick,
    isGenerating = false,
}: {
    content: string | JSX.Element | React.ReactNode;
    tailPosition: 'bottomRight' | 'topLeft' | 'topRight';
    persona?: JSX.Element;
    onClick?: () => void;
    isGenerating?: boolean;
}) => {
    const { currentTheme } = useThemes();

    const getStyles = (tailPos: 'bottomRight' | 'topLeft' | 'topRight') => {
        const backgroundColor =
            tailPos === 'bottomRight' || tailPos === 'topRight'
                ? currentTheme.palette.themePrimary
                : currentTheme.palette.neutralLighter;

        const textColor =
            tailPos === 'bottomRight' || tailPos === 'topRight'
                ? currentTheme.palette.white
                : undefined;

        const baseStyles = {
            paddingLeft: 10,
            paddingRight: 10,
            marginLeft: tailPos === 'topLeft' ? 10 : 0,
            marginRight:
                tailPos === 'bottomRight' || tailPos === 'topRight' ? 10 : 0,
            backgroundColor: backgroundColor,
            borderRadius:
                tailPos === 'topLeft'
                    ? '0 10px 10px 10px'
                    : tailPos === 'bottomRight'
                      ? '10px 10px 0 10px'
                      : '10px 0 10px 10px',
            position: 'relative',
            cursor: onClick ? 'pointer' : undefined,
            color: textColor,
        };

        const tailBaseStyles = {
            content: '""',
            position: 'absolute',
            width: '0',
            height: '0',
            border: '10px solid transparent',
        };

        const tailPositions = {
            topLeft: {
                ...tailBaseStyles,
                top: 0,
                left: -10,
                borderTopColor: backgroundColor,
                borderLeftColor: 'transparent',
                borderBottomColor: 'transparent',
                borderRight: '0',
            },
            topRight: {
                ...tailBaseStyles,
                top: 0,
                right: -10,
                borderTopColor: backgroundColor,
                borderRightColor: 'transparent',
                borderBottomColor: 'transparent',
                borderLeft: '0',
            },
            bottomRight: {
                ...tailBaseStyles,
                bottom: 0,
                right: -10,
                borderTopColor: 'transparent',
                borderBottomColor: backgroundColor,
                borderLeft: '0',
                borderRightColor: 'transparent',
            },
        };

        const tailStyle =
            tailPositions[tailPos as 'bottomRight' | 'topLeft' | 'topRight'] ||
            tailPositions.bottomRight;

        return mergeStyleSets({
            bubble: {
                ...baseStyles,
                ':after': tailStyle,
            },
        });
    };

    const classNames = getStyles(tailPosition);

    return (
        <Stack horizontal onClick={onClick}>
            {tailPosition === 'topLeft' && persona}

            <Stack horizontalAlign="start" className={classNames.bubble}>
                {isGenerating && (
                    <div style={{ padding: 10 }}>
                        <Spinner label="Generating..." labelPosition="right" />
                    </div>
                )}
                {!isGenerating && <div>{text}</div>}
            </Stack>

            {(tailPosition === 'bottomRight' || tailPosition === 'topRight') &&
                persona}
        </Stack>
    );
};
