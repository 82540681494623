import React, { FormEvent } from 'react';
import { Dropdown, IDropdownOption, Label } from '@fluentui/react';
import dayjs from 'dayjs';

export type PeriodDropDownPeriods =
    | 'Last90'
    | 'ThisMonth'
    | 'LastMonth'
    | 'MonthBeforeLast';

export function PeriodDropDown(props: {
    selectedPeriod: PeriodDropDownPeriods;
    onPeriodChanged: (
        period: PeriodDropDownPeriods,
        periodDisplayName: string,
        startDate: string,
        endDate: string
    ) => void;
    allowedPeriods?: PeriodDropDownPeriods[];
}) {
    let options: { key: PeriodDropDownPeriods; text: string }[] = [
        {
            key: 'Last90',
            text: 'Last 90 days',
        },
        {
            key: 'ThisMonth',
            text: dayjs().format('MMMM'),
        },
        {
            key: 'LastMonth',
            text: dayjs().subtract(1, 'month').format('MMMM'),
        },
        {
            key: 'MonthBeforeLast',
            text: dayjs().subtract(2, 'months').format('MMMM'),
        },
    ];

    if (props.allowedPeriods) {
        options = options.filter(
            (o) => props.allowedPeriods?.some((ap) => ap == o.key)
        );
    }

    const handleChange = (_ev: FormEvent, option?: IDropdownOption) => {
        const selectedPeriod = option?.key
            ? (option.key as PeriodDropDownPeriods)
            : null;

        let dayjsStart: dayjs.Dayjs | undefined = undefined;
        let dayjsEnd: dayjs.Dayjs | undefined = undefined;
        let periodDisplayName: string | undefined = undefined;

        switch (selectedPeriod) {
            case 'Last90':
                dayjsStart = dayjs.utc().subtract(90, 'day');
                dayjsEnd = dayjs.utc();
                periodDisplayName = 'Last 90 days';
                break;
            case 'ThisMonth':
                dayjsStart = dayjs.utc().startOf('month');
                dayjsEnd = dayjs.utc().endOf('month');
                periodDisplayName = dayjsStart.format('MMMM');
                break;
            case 'LastMonth':
                dayjsStart = dayjs.utc().subtract(1, 'month').startOf('month');
                dayjsEnd = dayjs.utc().subtract(1, 'month').endOf('month');
                periodDisplayName = dayjsStart.format('MMMM');
                break;
            case 'MonthBeforeLast':
                dayjsStart = dayjs.utc().subtract(2, 'months').startOf('month');
                dayjsEnd = dayjs.utc().subtract(2, 'months').endOf('month');
                periodDisplayName = dayjsStart.format('MMMM');
                break;
        }

        if (selectedPeriod && periodDisplayName && dayjsStart && dayjsEnd) {
            props.onPeriodChanged(
                selectedPeriod,
                periodDisplayName,
                dayjsStart.startOf('day').format('YYYY-MM-DD'),
                dayjsEnd.endOf('day').format('YYYY-MM-DD')
            );
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 8,
            }}
        >
            <Label>Usage Period:</Label>
            <Dropdown
                options={options}
                selectedKey={props.selectedPeriod}
                style={{ minWidth: 200 }}
                onChange={handleChange}
            />
        </div>
    );
}
