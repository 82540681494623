import React from 'react';
import DeleteModal from '../../../components/shared/DeleteModal';
import { useStateContext } from '../../../services/contextProvider';
import {
    DeleteTemplateReportElementMutation,
    useDeleteTemplateReportElementMutation,
    useGetTemplateReportSectionQuery,
    useUpdateTemplateReportSectionMutation,
} from '../../../data/types';
import { MutationUpdaterFn } from '@apollo/client';
import Loading from '../../../components/Loading';
import { MessageBar, MessageBarType } from '@fluentui/react';

export function TemplateReportElementDeleteModal(props: {
    sectionId: string;
    elementId: string;
    isOpen: boolean;
    onDeleted: () => void;
    onDismiss: () => void;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { data, loading } = useGetTemplateReportSectionQuery({
        skip: !props.sectionId || !props.isOpen,
        variables: {
            id: props.sectionId,
            tenantId: currentTenantId || '',
        },
    });

    const [deleteElementMutation, { error, loading: isDeleting }] =
        useDeleteTemplateReportElementMutation();

    const [
        updateTemplateReportSection,
        { loading: isUpdatingSection, error: updateSectionError },
    ] = useUpdateTemplateReportSectionMutation();

    const section = data?.reports?.templateReportSection;
    const element = section?.elements.find((e) => e.id === props.elementId);
    const group = element?.groupId
        ? section?.groups.find((g) => g.id === element.groupId)
        : null;
    const otherElements =
        group && section
            ? section.elements
                  .filter((e) => e.id !== props.elementId)
                  .filter((e) => e.groupId === group?.id)
            : [];

    const cacheUpdater: MutationUpdaterFn<
        DeleteTemplateReportElementMutation
    > = (cache, mutationResult) => {
        const deletedObject =
            mutationResult.data?.templateReportElementDelete?.deletedObject;
        if (deletedObject?.utcDeleted) {
            cache.evict({ id: cache.identify(deletedObject) });
            cache.gc();
        }
    };

    const deleteAction = async () => {
        if (props.elementId && currentTenantId) {
            await deleteElementMutation({
                variables: {
                    id: props.elementId,
                    tenantId: currentTenantId || '',
                    restore: false,
                },
                update: cacheUpdater,
            });
        }

        for (const otherElement of otherElements) {
            await deleteElementMutation({
                variables: {
                    id: otherElement.id || '',
                    tenantId: currentTenantId || '',
                    restore: false,
                },
                update: cacheUpdater,
            });
        }

        if (section && group && currentTenantId) {
            // Delete the group
            await updateTemplateReportSection({
                variables: {
                    tenantId: currentTenantId,
                    input: {
                        id: section.id,
                        layoutDirection: section.layoutDirection,
                        availableElementTypes: section.availableElementTypes,
                        title: section.title,
                        sequence: section.sequence,
                        groups: section.groups
                            .filter((g) => g.id !== group.id)
                            .map((g) => ({
                                id: g.id,
                                sequence: g.sequence,
                                layoutGrow: g.layoutGrow,
                                layoutDirection: g.layoutDirection,
                                version: g.version,
                            })),
                        version: section.version,
                    },
                },
            });
        }

        props.onDeleted();
    };

    const content = (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    {otherElements.length > 0 && (
                        <MessageBar messageBarType={MessageBarType.warning}>
                            <span>
                                The following elements will also be deleted:
                            </span>
                            <ul>
                                {otherElements.map((e) => (
                                    <li key={e.id}>{e.title || 'Untitled'}</li>
                                ))}
                            </ul>
                        </MessageBar>
                    )}
                </div>
            )}
        </div>
    );

    return (
        <DeleteModal
            {...props}
            isDeleting={isDeleting || isUpdatingSection}
            message={
                'Are you sure you want to remove this element from the report?'
            }
            error={error || updateSectionError}
            content={content}
            deleteAction={deleteAction}
            activeViewName={null}
        />
    );
}
