import union from 'lodash/union';
import React, {
    ComponentType,
    createContext,
    PropsWithChildren,
    useContext,
    useState,
} from 'react';

type TaskExpandContextType = string[];

type TaskExpandActionsContextType = {
    expandTask: (id: string) => void;
    collapseTask: (id: string) => void;
    expandTasks: (ids: string[]) => void;
    collapseTasks: (ids: string[]) => void;
    getExpandedTaskIds: () => string[];
};

const TaskExpandContext = createContext<TaskExpandContextType>([]);

const TaskExpandActionsContext = createContext({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    expandTask: (id: string) => {
        // Defined below
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    collapseTask: (id: string) => {
        // Defined below
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    expandTasks: (ids: string[]) => {
        // Defined below
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    collapseTasks: (ids: string[]) => {
        // Defined below
    },
    getExpandedTaskIds: (): string[] => {
        // Defined below
        return [];
    },
});

export function useTaskExpandContext(): TaskExpandContextType {
    const context = useContext(TaskExpandContext);
    if (context === undefined) {
        throw new Error(
            'useStateContext must be used within a ContextProvider'
        );
    }
    return context;
}

export function useTaskExpandActionsContext(): TaskExpandActionsContextType {
    const context = useContext(TaskExpandActionsContext);
    if (context === undefined) {
        throw new Error(
            'useStateContext must be used within a ContextProvider'
        );
    }
    return context;
}

const TaskExpandContextProvider = (props: {
    children?: React.ReactNode;
}): JSX.Element => {
    const [expandedTaskIds, setExpandedTaskIds] = useState<string[]>([]);

    const actionState = {
        expandTask: (id: string) => {
            if (!expandedTaskIds.some((expandedId) => expandedId === id)) {
                setExpandedTaskIds([...expandedTaskIds, id]);
            }
        },
        collapseTask: (id: string) => {
            const updated = expandedTaskIds.filter(
                (expandedId) => expandedId !== id
            );
            setExpandedTaskIds(updated);
        },
        expandTasks: (ids: string[]) => {
            const updated = union(expandedTaskIds, ids);
            setExpandedTaskIds(updated);
        },
        collapseTasks: (ids: string[]) => {
            const updated = expandedTaskIds.filter(
                (expandedId) => !ids.includes(expandedId)
            );
            setExpandedTaskIds(updated);
        },
        getExpandedTaskIds: (): string[] => {
            return expandedTaskIds;
        },
    };

    return (
        <TaskExpandContext.Provider value={expandedTaskIds}>
            <TaskExpandActionsContext.Provider value={actionState}>
                {props.children}
            </TaskExpandActionsContext.Provider>
        </TaskExpandContext.Provider>
    );
};

export function withTaskExpand<T extends PropsWithChildren<P>, P>(
    Component: ComponentType<T>
) {
    return function componentWithTaskExpand(props: T): JSX.Element {
        return (
            <TaskExpandContextProvider>
                <Component {...props} />
            </TaskExpandContextProvider>
        );
    };
}

export default TaskExpandContextProvider;
