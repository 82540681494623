import React, { useState } from 'react';
import {
    MessageBarButton,
    MessageBar,
    MessageBarType,
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
} from '@fluentui/react';
import dayjs from 'dayjs';
import { useGetStatusReportsDataLastUpdatedQuery } from '../../../data/types';

export function StatusReportDataChangedIndicator(props: {
    missionId: string;
    utcDataDate: string;
    tenantId: string;
    onRefreshDataButtonClick: () => void;
}): JSX.Element | null {
    const { data } = useGetStatusReportsDataLastUpdatedQuery({
        variables: {
            tenantId: props.tenantId,
            missionId: props.missionId,
        },
    });

    const [hideConfirmDialog, setHideConfirmDialog] = useState(true);

    const allDates = [];

    if (data?.measures) {
        allDates.push(
            ...data.measures.map((m) =>
                dayjs.utc(m.utcDeleted || m.utcUpdated || m.utcCreated)
            )
        );

        allDates.push(
            ...data.measures
                .flatMap((m) => m.valueHistory || [])
                .map((ao) =>
                    dayjs.utc(ao.utcDeleted || ao.utcUpdated || ao.utcCreated)
                )
        );
    }

    if (data?.tasks) {
        allDates.push(
            ...data.tasks.map((t) =>
                dayjs.utc(t.utcDeleted || t.utcUpdated || t.utcCreated)
            )
        );
    }

    let hasChanges = false;

    if (allDates.length) {
        const latestUpdateDate = dayjs.max(allDates);

        hasChanges = dayjs.utc(props.utcDataDate).isBefore(latestUpdateDate);
    }

    if (!hasChanges) {
        return null;
    }

    return (
        <React.Fragment>
            <MessageBar
                messageBarType={MessageBarType.info}
                messageBarIconProps={{
                    iconName: '',
                }}
                isMultiline={false}
                actions={
                    <div>
                        <MessageBarButton
                            onClick={() => setHideConfirmDialog(false)}
                        >
                            Get Latest Updates
                        </MessageBarButton>
                    </div>
                }
            >
                The fact values have changed since this report was last updated.
            </MessageBar>

            <StatusReportGetUpdatesConfirmDialog
                hidden={hideConfirmDialog}
                onDismiss={() => setHideConfirmDialog(true)}
                onConfirm={props.onRefreshDataButtonClick}
            />
        </React.Fragment>
    );
}

function StatusReportGetUpdatesConfirmDialog(props: {
    hidden: boolean;
    onDismiss: () => void;
    onConfirm: () => void;
}): JSX.Element {
    return (
        <Dialog
            hidden={props.hidden}
            onDismiss={props.onDismiss}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Get latest updates',
                closeButtonAriaLabel: 'Close',
                subText:
                    'Do you want update all measures of success and task facts with the latest values?',
            }}
        >
            <DialogFooter>
                <PrimaryButton onClick={props.onConfirm} text="Get Updates" />
                <DefaultButton onClick={props.onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
}
