import { useCallback, useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import { useStateContext } from '../services/contextProvider';
import {
    useGetFinancialYearsLazyQuery,
    useGetUserMissionSearchLazyQuery,
} from '../data/types';
import dayjs from 'dayjs';

export function useLastYear(missionUserId: string | null | undefined): {
    financialYearCode?: string | null;
    missionId?: string | null;
} {
    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const [getFinancialYears] = useGetFinancialYearsLazyQuery();
    const [getUserMissionSearch] = useGetUserMissionSearchLazyQuery();

    const getLastYearsFinancialYearCodeAsync = useCallback(async (): Promise<
        string | null
    > => {
        if (!currentTenantId || !currentFinancialYearCode) {
            return null;
        }

        const fys = await getFinancialYears({
            variables: {
                tenantId: currentTenantId,
            },
        });

        const thisYear = fys.data?.financialYears?.find(
            (fy) =>
                fy.code?.toUpperCase() ===
                currentFinancialYearCode.toUpperCase()
        );

        if (thisYear?.code) {
            const lastYear = orderBy(
                filter(fys.data?.financialYears, (fy) => !fy.utcInactive),
                ['startDate'],
                ['desc']
            ).find((fy) => dayjs(fy.startDate).isBefore(thisYear?.startDate));

            return lastYear?.code || null;
        }

        return null;
    }, [currentTenantId, currentFinancialYearCode, getFinancialYears]);

    const [financialYearCode, setFinancialYearCode] = useState<string | null>();
    const [missionId, setMissionId] = useState<string | null>();

    useEffect(() => {
        async function loadState() {
            const fyCode = await getLastYearsFinancialYearCodeAsync();

            if (currentTenantId && fyCode && missionUserId) {
                const lastYearsMissionSearch = await getUserMissionSearch({
                    variables: {
                        tenantId: currentTenantId,
                        financialYearCode: fyCode,
                        userId: missionUserId,
                    },
                });

                const lastYearsMission =
                    lastYearsMissionSearch.data?.missions.find((m) => m.id);

                setFinancialYearCode(fyCode);
                setMissionId(lastYearsMission?.id || null);
            }
        }

        setFinancialYearCode(null);
        setMissionId(null);

        if (missionUserId && currentTenantId) {
            loadState();
        }
    }, [
        missionUserId,
        currentTenantId,
        getLastYearsFinancialYearCodeAsync,
        getUserMissionSearch,
    ]);

    return {
        financialYearCode,
        missionId,
    };
}
