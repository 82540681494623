import React, { PropsWithChildren } from 'react';

import { Image, mergeStyleSets, ScrollablePane } from '@fluentui/react';

import logo from '../../../images/advance-large-light.png';

import Footer from '../../../components/shared/Footer';

export function LegalContainer(props: PropsWithChildren): JSX.Element {
    const styles = mergeStyleSets({
        container: {
            position: 'relative',

            height: '100vh',
        },
        sticky: {
            backgroundColor: '#fff',
        },
    });

    return (
        <div className={styles.container}>
            <ScrollablePane
                styles={{
                    contentContainer: {
                        padding: 64,
                    },
                }}
            >
                <div style={{ marginBottom: 32 }}>
                    <Image src={logo} alt="Advance" height={60} />
                </div>

                {props.children}

                <Footer />
            </ScrollablePane>
        </div>
    );
}
