import React, { useCallback, useEffect, useState } from 'react';
import {
    DatePicker,
    TextField,
    Dropdown,
    IDropdownOption,
} from '@fluentui/react';
import { MeasureTypes, Multipliers } from '../../data/types';
import dayjs from 'dayjs';

type ValueInputProps = {
    id?: string | undefined;
    label?: string | undefined;
    measureType: MeasureTypes;
    multiplier: Multipliers;
    currencySymbol: string | null | undefined;
    decimalPlaces: number | undefined;
    minValue?: number | null | undefined;
    disabled?: boolean;
    value?: {
        decimalValue: number | null;
        stringValue?: string | null;
        dateValue?: string | null;
    };
    defaultValue?: {
        decimalValue: number | null;
        stringValue?: string | null;
        dateValue?: string | null;
    };
    onChange: (
        decimalValue: number | null,
        stringValue: string | null,
        dateValue: string | null
    ) => void;
};

export function ValueInput(props: ValueInputProps): JSX.Element {
    switch (props.measureType) {
        case MeasureTypes.Numeric:
        case MeasureTypes.Currency:
        case MeasureTypes.Percentage:
            return <NumberValueInput {...props} />;

        case MeasureTypes.YesNo:
            return (
                <DropDownValueInput
                    {...props}
                    options={[
                        { key: 'Yes', text: 'Yes' },
                        { key: 'No', text: 'No' },
                    ]}
                />
            );

        case MeasureTypes.OnOff:
            return (
                <DropDownValueInput
                    {...props}
                    options={[
                        { key: 'On', text: 'On' },
                        { key: 'Off', text: 'Off' },
                    ]}
                />
            );

        case MeasureTypes.Gyr:
            return (
                <DropDownValueInput
                    {...props}
                    options={[
                        { key: 'Green', text: 'Green' },
                        { key: 'Yellow', text: 'Yellow' },
                        { key: 'Red', text: 'Red' },
                    ]}
                />
            );

        case MeasureTypes.Date:
            return (
                <DatePicker
                    label={props.label}
                    onSelectDate={(date: Date | undefined | null): void => {
                        props.onChange(
                            null,
                            null,
                            date ? dayjs(date).format('YYYY-MM-DD') : null
                        );
                    }}
                />
            );

        default:
            return <div>No input found</div>;
    }
}

function DropDownValueInput(
    props: { options: IDropdownOption[] } & ValueInputProps
): JSX.Element {
    const [defaultSelectedKey, setDefaultSelectedKey] = useState<
        string | undefined
    >(props.defaultValue?.stringValue || undefined);

    const [selectedKey, setSelectedKey] = useState<string>();

    // Only update the default value if its changed
    useEffect(() => {
        if (
            props.defaultValue?.stringValue !== null &&
            props.defaultValue?.stringValue !== undefined
        ) {
            setDefaultSelectedKey(props.defaultValue.stringValue);
        }
    }, [props.defaultValue, props.measureType]);

    return (
        <Dropdown
            label={props.label}
            options={props.options}
            disabled={props.disabled}
            defaultSelectedKey={defaultSelectedKey}
            selectedKey={selectedKey}
            onChange={(_ev: React.FormEvent, option?): void => {
                const stringValue = option ? (option.key as string) : null;
                setSelectedKey(stringValue || undefined);
                props.onChange(null, stringValue, null);
            }}
        />
    );
}

function NumberValueInput(props: ValueInputProps): JSX.Element {
    const getAdjustedNumber = useCallback(
        (decimalValue: number): string => {
            let adjustedNumber = decimalValue;

            const decimalPlaces = Math.max(
                Math.min(props.decimalPlaces || 0, 4),
                0
            );

            if (props.measureType === MeasureTypes.Percentage) {
                adjustedNumber = decimalValue * 100;
            } else if (props.multiplier) {
                switch (props.multiplier) {
                    case Multipliers.K:
                        adjustedNumber = adjustedNumber / 1000;
                        break;

                    case Multipliers.M:
                        adjustedNumber = adjustedNumber / 1000000;
                        break;

                    case Multipliers.B:
                        adjustedNumber = adjustedNumber / 1000000000;
                        break;
                }
            }

            return adjustedNumber.toFixed(decimalPlaces);
        },
        [props.measureType, props.decimalPlaces, props.multiplier]
    );

    const [displayValue, setDisplayValue] = useState<string>(
        props.defaultValue?.decimalValue !== null &&
            props.defaultValue?.decimalValue !== undefined
            ? getAdjustedNumber(props.defaultValue.decimalValue)
            : ''
    );

    const [errorMessage, setErrorMessage] = useState<string>('');

    let targetPrefix: string | undefined = undefined;
    let targetSuffix: string | undefined = undefined;

    useEffect(() => {
        if (
            props.value?.decimalValue !== null &&
            props.value?.decimalValue !== undefined
        ) {
            const adjustedNumber = getAdjustedNumber(props.value.decimalValue);
            setDisplayValue(adjustedNumber);
        } else if (props.value) {
            setDisplayValue('');
        }
    }, [
        props.value,
        props.measureType,
        props.multiplier,
        props.decimalPlaces,
        getAdjustedNumber,
    ]);

    const parseNumberInput = useCallback(
        (numberInput: string | null): number => {
            const number =
                numberInput !== null && numberInput !== '-'
                    ? Number.parseFloat(numberInput.replace(/,/g, ''))
                    : 0;
            return isNaN(number) ? 0 : number;
        },
        []
    );

    const getDecimalValueFromString = useCallback(
        (stringValue: string): number | undefined => {
            if (props.measureType === MeasureTypes.Percentage) {
                return parseNumberInput(stringValue) / 100;
            } else if (
                props.measureType === MeasureTypes.Numeric ||
                props.measureType === MeasureTypes.Currency
            ) {
                switch (props.multiplier) {
                    case Multipliers.K:
                        return parseNumberInput(stringValue) * 1000;
                    case Multipliers.M:
                        return parseNumberInput(stringValue) * 1000000;
                    case Multipliers.B:
                        return parseNumberInput(stringValue) * 1000000000;
                    default:
                        return parseNumberInput(stringValue);
                }
            }
            return undefined;
        },
        [props.measureType, props.multiplier, parseNumberInput]
    );

    useEffect(() => {
        // Only update the default value if its changed
        if (
            props.defaultValue?.decimalValue !== null &&
            props.defaultValue?.decimalValue !== undefined
        ) {
            setDisplayValue((curr) => {
                const currentValue = getDecimalValueFromString(curr);
                if (currentValue !== props.defaultValue?.decimalValue) {
                    return getAdjustedNumber(
                        props.defaultValue?.decimalValue || 0
                    );
                }
                return curr;
            });
        }
    }, [
        props.defaultValue,
        props.measureType,
        props.multiplier,
        props.decimalPlaces,
        getDecimalValueFromString,
        getAdjustedNumber,
    ]);

    if (props.measureType === MeasureTypes.Percentage) {
        targetSuffix = '%';
    } else {
        targetPrefix = props.currencySymbol || undefined;
        targetSuffix =
            props.multiplier && props.multiplier !== Multipliers.None
                ? props.multiplier?.toString()
                : undefined;
    }

    return (
        <TextField
            id={props.id}
            label={props.label}
            prefix={targetPrefix}
            suffix={targetSuffix}
            disabled={props.disabled}
            errorMessage={errorMessage}
            value={displayValue}
            autoComplete="off"
            pattern="^-?(\d*\.?\d*|\d{1,3}(,\d{3})*(\.\d+)?)$"
            onChange={(
                ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string | undefined
            ): void => {
                const target = ev.target as HTMLInputElement;

                setDisplayValue(newValue || '');
                setErrorMessage(
                    target?.validity.valid ? '' : 'Please enter a valid number.'
                );

                if (!newValue) {
                    props.onChange(null, null, null);
                } else if (
                    props.measureType === MeasureTypes.Percentage ||
                    props.measureType === MeasureTypes.Numeric ||
                    props.measureType === MeasureTypes.Currency
                ) {
                    const decimalValue =
                        getDecimalValueFromString(newValue) ?? null;

                    if (
                        props.minValue !== undefined &&
                        props.minValue !== null &&
                        decimalValue !== null &&
                        decimalValue < props.minValue
                    ) {
                        setErrorMessage(
                            `This number needs to be at least ${props.minValue}`
                        );
                    }

                    return props.onChange(decimalValue, null, null);
                } else {
                    props.onChange(null, newValue || null, null);
                }
            }}
        />
    );
}
