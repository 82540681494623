import React from 'react';
import { Link, Stack, Text } from '@fluentui/react';
import { useGetCurrentUserLastLoginQuery } from '../../data/types';
import { useUtcDates } from '../../hooks/useUtcDates';

export function LastLoginDetails(props: {
    onDetailsClick: () => void;
}): JSX.Element {
    const { fromNow } = useUtcDates();

    const { data } = useGetCurrentUserLastLoginQuery({
        pollInterval: 300000, // Every five minutes
    });

    if (!data?.current_user?.lastLogin) {
        return <div />;
    }

    return (
        <Stack tokens={{ childrenGap: 4 }} horizontal className="no-print">
            <Text>
                Last login: {fromNow(data?.current_user?.lastLogin.dateTime)}
            </Text>
            <Stack.Item styles={{ root: { whiteSpace: 'nowrap' } }}>
                (<Link onClick={props.onDetailsClick}>Details</Link>)
            </Stack.Item>
        </Stack>
    );
}
