import React, { useCallback, useEffect, useState } from 'react';

import {
    Stack,
    TextField,
    Text,
    PanelType,
    ActionButton,
    PrimaryButton,
} from '@fluentui/react';

import { EditPanel } from './shared/EditPanel';
import {
    useTaskAcceptMutation,
    GetTaskQuery,
    refetchGetDependenciesQuery,
    refetchGetMissionTasksQuery,
    useGetTaskQuery,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import {
    DependencyAcceptForm,
    DependencyAcceptModes,
} from './DependencyAcceptForm';
import { Access, ExtractQueryArrayType } from '../data/extendedTypes';
import { useThemes } from '../hooks/useThemes';
import { ResourcedFromDetailBox } from './ResourcedFromDetailBox';

type TaskType = ExtractQueryArrayType<GetTaskQuery, ['task']>;

type DependencyActionPanelProps = {
    missionId: string;
    missionUserId: string;
    missionAccess: Access;
    taskId: string | null | undefined;
    showPanel: boolean;
    action: 'accept' | 'reject';
    onChangeAction: (action: 'accept' | 'reject') => void;
    onCancel: () => void;
    onSave: () => void;
};

export default function DependencyActionPanel(
    props: DependencyActionPanelProps
): JSX.Element {
    const { taskId, missionId, onSave } = props;
    const { currentTenantId, currentFinancialYearCode } = useStateContext();
    const { currentTheme } = useThemes();

    const { data, loading: isLoading } = useGetTaskQuery({
        fetchPolicy: 'cache-and-network',
        skip: !props.showPanel || !props.taskId,
        variables: {
            tenantId: currentTenantId || '',
            id: props.taskId || '',
        },
    });

    const resourcedTask: TaskType | undefined = data?.task || undefined;

    const resourcedFromTask = resourcedTask?.resourcedFromTask;

    const [acceptTask, { loading: isSaving, error: saveError }] =
        useTaskAcceptMutation({
            awaitRefetchQueries: true,
            refetchQueries: [
                refetchGetDependenciesQuery({
                    tenantId: currentTenantId || '',
                    userId: props.missionUserId,
                    financialYearCode: currentFinancialYearCode || '',
                }),
                refetchGetMissionTasksQuery({
                    tenantId: currentTenantId || '',
                    missionId: props.missionId,
                }),
            ],
        });

    type formValuesType = {
        rejectedReason: string | null;
    };

    const [formInput, setFormInput] = useState<formValuesType>({
        rejectedReason: null,
    });

    const [errors, setErrors] = useState({
        rejectedReason: '',
    });

    useEffect(() => {
        if (props.showPanel) {
            setFormInput({
                rejectedReason: '',
            });
        }
    }, [props.showPanel]);

    const validateReject = (): boolean => {
        const formErrorMessages = {
            rejectedReason:
                props.action === 'reject' && !formInput.rejectedReason
                    ? 'Rejection reason required'
                    : '',
        };

        setErrors(formErrorMessages);

        return !formErrorMessages.rejectedReason;
    };

    const handleRejectClicked = async () => {
        if (!validateReject()) {
            return;
        }

        if (props.taskId && currentTenantId) {
            await acceptTask({
                variables: {
                    tenantId: currentTenantId,
                    taskId: props.taskId,
                    rejectReason: formInput.rejectedReason,
                    isAccepted: false,
                    isRejected: true,
                    parentTaskId: null,
                    assignToTaskId: null,
                    missionId: null,
                    taskName: null,
                    isDuplicate: false,
                },
            });
        }

        props.onSave();
    };

    const onDependencyAcceptClicked = useCallback(
        async (
            acceptMode: DependencyAcceptModes,
            selectedTaskId: string | null,
            taskName: string | null
        ) => {
            if (taskId && currentTenantId) {
                await acceptTask({
                    variables: {
                        tenantId: currentTenantId,
                        taskId: taskId,
                        rejectReason: null,
                        isAccepted: true,
                        isRejected: false,
                        parentTaskId:
                            acceptMode !== 'assign-to' ? selectedTaskId : null,
                        assignToTaskId:
                            acceptMode === 'assign-to' ? selectedTaskId : null,
                        missionId: missionId,
                        taskName: taskName,
                        isDuplicate: acceptMode === 'duplicate-task',
                    },
                });
            }
            onSave();
        },
        [acceptTask, currentTenantId, missionId, taskId, onSave]
    );

    const onSwapToReject = () => {
        props.onChangeAction('reject');
    };

    const headerText =
        props.action === 'accept' ? 'Accept Task' : 'Reject Task';

    return (
        <React.Fragment>
            <EditPanel
                activeViewName="DependencyActions"
                onDismiss={props.onCancel}
                showPanel={props.showPanel}
                headerText={headerText}
                isSaving={isSaving}
                isValid={true}
                saveErrorMessage={saveError?.message || null}
                panelType={
                    props.action === 'accept'
                        ? PanelType.medium
                        : PanelType.smallFixedFar
                }
                additionalButtons={
                    props.action === 'accept'
                        ? [
                              <ActionButton
                                  key="RejectButton"
                                  text="Reject"
                                  iconProps={{ iconName: 'Reply' }}
                                  onClick={onSwapToReject}
                                  disabled={
                                      !props.missionAccess.write || isSaving
                                  }
                                  styles={{
                                      icon: {
                                          color: currentTheme.semanticColors
                                              .errorIcon,
                                      },
                                      root: {
                                          height: 32,
                                          backgroundColor:
                                              currentTheme.semanticColors
                                                  .errorBackground,
                                          color: currentTheme.semanticColors
                                              .errorIcon,
                                      },
                                  }}
                              />,
                          ]
                        : []
                }
            >
                <Stack tokens={{ childrenGap: 8 }}>
                    {!!resourcedFromTask?.mission?.owner &&
                        !!resourcedFromTask?.name && (
                            <Text variant="medium" block>
                                {`${resourcedFromTask?.mission?.owner} has requested you as a resource on the task `}
                                <strong>{resourcedFromTask?.name}</strong>
                            </Text>
                        )}
                    {resourcedTask?.resourcedFromTask && (
                        <ResourcedFromDetailBox task={resourcedTask} />
                    )}

                    {props.action === 'accept' && !isLoading && (
                        <DependencyAcceptForm
                            tenantId={currentTenantId}
                            missionId={props.missionId}
                            missionAccess={props.missionAccess}
                            saveError={saveError?.message}
                            isSaving={isSaving}
                            dependencyTaskName={
                                resourcedTask &&
                                resourcedTask.id === props.taskId
                                    ? resourcedTask.resourcedFromTask?.name ||
                                      ''
                                    : null
                            }
                            onAcceptClicked={onDependencyAcceptClicked}
                        />
                    )}
                    {props.action === 'reject' && !isLoading && (
                        <TextField
                            label="Why are you rejecting this task?"
                            disabled={isSaving}
                            multiline
                            autoAdjustHeight
                            required
                            name="rejectedReason"
                            defaultValue={formInput.rejectedReason || ''}
                            errorMessage={errors.rejectedReason}
                            onChange={(_ev, newValue) => {
                                setFormInput({
                                    ...formInput,
                                    rejectedReason: newValue || null,
                                });
                            }}
                        />
                    )}
                    {props.action === 'reject' && !isLoading && (
                        <Stack.Item>
                            <PrimaryButton
                                onClick={() => handleRejectClicked()}
                                disabled={
                                    isSaving || !props.missionAccess.write
                                }
                            >
                                Reject
                            </PrimaryButton>
                        </Stack.Item>
                    )}
                </Stack>
            </EditPanel>
        </React.Fragment>
    );
}
