import React, { PropsWithChildren } from 'react';
import { useEngagement } from './EngagementContext';
import {
    ActionButton,
    DefaultButton,
    Dialog,
    DialogType,
    IButtonProps,
    TeachingBubble,
} from '@fluentui/react';
import BeaconEngagement from './BeaconEngagement';
import { createPortal } from 'react-dom';
import { useThemes } from '../../hooks/useThemes';
import { useId } from '@fluentui/react-hooks';
import { EngagementTargetKeys } from './Engagements';

export interface IEngagementTargetProps {
    engagementTargetKey?: EngagementTargetKeys;
    targetId?: string;
}

export function EngagementTarget(
    props: PropsWithChildren<IEngagementTargetProps>
): JSX.Element {
    const { activeEngagements, dismissEngagement, dismissEngagementForTarget } =
        useEngagement();

    const { currentTheme } = useThemes();

    const engagement = props.engagementTargetKey
        ? activeEngagements.find(
              (f) => f.targetKey === props.engagementTargetKey
          )
        : activeEngagements.find((f) => !f.targetKey);

    const portal = document.getElementById('engagement-portal');

    if (engagement?.type === 'Announcement' && portal) {
        return (
            <>
                {props.children}
                {createPortal(
                    <TeachingBubble
                        theme={currentTheme}
                        key={engagement.key}
                        target={`#${props.targetId}`}
                        headline={engagement.headline}
                        hasCloseButton
                        closeButtonAriaLabel="Close"
                        onDismiss={() => {
                            dismissEngagementForTarget(engagement.targetKey);
                        }}
                    >
                        {engagement?.content}
                    </TeachingBubble>,
                    portal
                )}
            </>
        );
    } else if (engagement?.type === 'Beacon') {
        return (
            <BeaconEngagement
                showBeacon
                headline={engagement.headline}
                content={engagement.content as string}
                onDimiss={() => {
                    dismissEngagementForTarget(engagement.targetKey);
                }}
            >
                {props.children}
            </BeaconEngagement>
        );
    } else if (engagement?.type === 'Popup' && portal) {
        return (
            <>
                {props.children}
                {createPortal(
                    <Dialog
                        hidden={false}
                        onDismiss={() => {
                            dismissEngagement(engagement.key);
                        }}
                        dialogContentProps={{
                            type: DialogType.largeHeader,
                            showCloseButton: true,
                            styles: {
                                title: {
                                    paddingRight: '24px !important',
                                    backgroundColor:
                                        engagement.backgroundColour,
                                    color: engagement.headlineColour,
                                },
                                content: {
                                    borderColor: engagement.headlineColour,
                                    whiteSpace: 'pre-wrap',
                                    backgroundColor:
                                        engagement.backgroundColour,
                                },
                            },
                            title: (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            letterSpacing: '.2rem',
                                            width: '100%',
                                        }}
                                    >
                                        {engagement.headline}
                                    </div>
                                    {/* <IconButton
                                        onClick={() => {
                                            dismissEngagement(engagement.key);
                                        }}
                                        iconProps={{
                                            iconName: 'Cancel',
                                        }}
                                    /> */}
                                </div>
                            ),
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: {
                                main: {
                                    minWidth: 'min(50vw, 400px) !important',
                                },
                            },
                        }}
                    >
                        <div
                            onClick={() => {
                                dismissEngagement(engagement.key);
                            }}
                        >
                            {engagement.content}
                        </div>
                    </Dialog>,
                    portal
                )}
            </>
        );
    }

    return <>{props.children}</>;
}

export const EngagementButtonWrapper: React.FunctionComponent<IButtonProps> = (
    props
) => {
    const { defaultRender, ...buttonProps } = props;

    const engagementTargetKey = (buttonProps as IEngagementTargetProps)
        ?.engagementTargetKey;

    const ButtonComponent = defaultRender || DefaultButton;

    const buttonId = useId('engagementButton');

    return engagementTargetKey ? (
        <EngagementTarget
            targetId={buttonId}
            engagementTargetKey={engagementTargetKey}
        >
            <ButtonComponent {...buttonProps} id={buttonId} />
        </EngagementTarget>
    ) : (
        <ButtonComponent {...buttonProps} />
    );
};

export const EngagementActionButton = (
    props: IButtonProps & { engagementTargetKey: EngagementTargetKeys }
) => {
    const { dismissEngagementForTarget } = useEngagement();

    const buttonId = useId('engagementButton');

    return (
        <EngagementTarget
            targetId={buttonId}
            engagementTargetKey={props.engagementTargetKey}
        >
            <ActionButton
                {...props}
                id={buttonId}
                onClick={(ev) => {
                    dismissEngagementForTarget(props.engagementTargetKey);
                    if (props.onClick) {
                        props.onClick(ev);
                    }
                }}
            />
        </EngagementTarget>
    );
};
