import React, { useEffect, useState } from 'react';
import {
    Icon,
    Link,
    mergeStyleSets,
    Persona,
    PersonaSize,
    Stack,
    Text,
    TooltipHost,
} from '@fluentui/react';
import { useGetTaskLazyQuery, useUpdateTaskMutation } from '../data/types';
import { useStateContext } from '../services/contextProvider';
import ExpandCollapse from './ExpandCollapse';
import TaskProgressIndicator from './TaskProgressIndicator';
import { TaskStatusNames } from '../data/extendedTypes';
import { photoService } from '../services/photo.service';
import { useIsTaskImplied } from '../hooks/useIsTaskImplied';
import { useTaskStatus } from '../hooks/useTaskStatus';
import { useThemes } from '../hooks/useThemes';
import Loading from './Loading';
import { useTaskEditRules } from '../hooks/useTaskEditRules';
import { EditibleText } from './EditibleText';
import { useInputMappers } from '../hooks/useInputMappers';
import { useResourceUpdater } from '../hooks/useResourceUpdater';
import { navigation } from '../services/navigation';
import {
    TaskHierachyTaskType,
    useTaskHierachy,
} from '../hooks/useTaskHierachy';

type TaskMapProps = {
    taskId: string | null;
};

export function TaskMap(props: TaskMapProps): JSX.Element {
    const {
        taskHierarchy,
        loadHierarchyAsync,
        expandHierarchyItemAsync,
        collapseHierarchyItemAsync,
        refreshTasksAsync,
        reset,
    } = useTaskHierachy();

    useEffect(() => {
        reset();
        (async function load() {
            if (props.taskId) {
                await loadHierarchyAsync(props.taskId);
            }
        })();
    }, [props.taskId, loadHierarchyAsync, reset]);

    const handleTasksChanged = async (taskIds: string[]) =>
        refreshTasksAsync(taskIds);

    return (
        <div>
            {taskHierarchy === null && <Loading />}

            {taskHierarchy?.map((h) => {
                if (!h.task) {
                    return null;
                }

                if (
                    h.parentHierarchyId &&
                    !taskHierarchy.find(
                        (ph) => ph.hierarchyId === h.parentHierarchyId
                    )?.expanded
                ) {
                    return null;
                }

                const parentTask =
                    taskHierarchy.find(
                        (ph) => ph.hierarchyId === h.parentHierarchyId
                    )?.task || null;

                return (
                    <TaskMapLine
                        key={h.hierarchyId.toString()}
                        task={h.task}
                        parentTask={parentTask}
                        isExpanded={h.expanded}
                        onExpand={() => expandHierarchyItemAsync(h.hierarchyId)}
                        onCollapse={() =>
                            collapseHierarchyItemAsync(h.hierarchyId)
                        }
                        level={h.level}
                        showExpandCollapse={h.hasChildren}
                        highlight={h.task.id === props.taskId}
                        onTasksChanged={handleTasksChanged}
                    />
                );
            })}
        </div>
    );
}

export function TaskMapLine(props: {
    task: TaskHierachyTaskType;
    parentTask: TaskHierachyTaskType | null;
    level: number;
    isExpanded: boolean;
    showExpandCollapse: boolean;
    onExpand: () => void;
    onCollapse: () => void;
    highlight?: boolean;
    onTasksChanged: (taskIds: string[]) => void;
}): JSX.Element {
    const { task, parentTask } = props;

    const { currentTheme } = useThemes();

    const { currentTenantId, currentTenantCode, currentFinancialYearCode } =
        useStateContext();

    const isImpliedTask = useIsTaskImplied(task);

    const { isNameReadOnly } = useTaskEditRules(task, task.mission?.rights);

    const isParentImpliedTask = useIsTaskImplied(parentTask);

    const [isExpanding, setIsExpanding] = useState(false);

    const { getTaskInput } = useInputMappers();

    const [getTaskLazy] = useGetTaskLazyQuery({
        fetchPolicy: 'network-only',
    });

    const [updateTaskMutation] = useUpdateTaskMutation();

    // If this is under a task that is labeled as implied
    const isChecklistTask =
        parentTask?.id === task.parentTaskId &&
        isParentImpliedTask &&
        !task.isDuplicate;

    const mission =
        isChecklistTask && parentTask ? parentTask?.mission : task.mission;

    const resource =
        isChecklistTask && parentTask ? parentTask?.resource : task.resource;

    const isDuplicateOfParent =
        task.isDuplicate && task.parentTaskId == parentTask?.id;

    const personaImageUrl = photoService.getImageUrl(
        resource?.userId || mission?.userId
    );

    const personaText = mission
        ? `${mission.owner} - ${mission.title}`
        : resource?.displayName;

    const resourceUpdater = useResourceUpdater(
        currentTenantId || null,
        currentFinancialYearCode || null,
        mission?.userId || null
    );

    const updateTask = async (name: string) => {
        if (!task.id || !currentTenantId) {
            return;
        }

        const getResult = await getTaskLazy({
            variables: {
                tenantId: currentTenantId,
                id: task.id,
            },
        });

        if (getResult.data?.task) {
            const taskForInput = {
                ...getResult.data?.task,
                name: name,
            };

            const input = getTaskInput(taskForInput);

            await updateTaskMutation({
                variables: {
                    tenantId: currentTenantId,
                    input: input,
                },
            });

            const resourcedTasks = getResult.data.task.resourcedTasks;

            await resourceUpdater.updateResourcedTasks(
                taskForInput,
                resourcedTasks.map((r) => {
                    return {
                        userId: r.resource?.userId || null,
                        resourceId: r.resource?.id || null,
                        displayName: r.resource?.displayName || null,
                        resourceIsPrimary: r.resourceIsPrimary,
                        requestAgain: false,
                    };
                })
            );

            props.onTasksChanged([
                taskForInput.id || '',
                ...resourcedTasks.map((rt) => rt.id || ''),
            ]);
        }
    };

    const handleNameChange = async (newName: string) => {
        await updateTask(newName);
    };

    const taskStatus = useTaskStatus(task);

    let taskType: string;

    if (
        taskStatus?.name === TaskStatusNames.Pending ||
        taskStatus?.name === TaskStatusNames.Rejected
    ) {
        taskType = taskStatus.name;
    } else if (isChecklistTask) {
        taskType = 'Checklist Task';
    } else {
        taskType = isImpliedTask ? 'Implied Task' : 'Specified Task';
    }

    let taskPath = null;
    let missionPath = null;

    if (
        mission?.id &&
        mission.rights.read &&
        !mission.utcDeleted &&
        !mission.utcInactive &&
        task.mission?.team?.code &&
        task.mission?.team?.division?.financialYear?.code
    ) {
        missionPath = navigation.getPathForParams({
            tenantCode: currentTenantCode,
            missionId: mission.id,
            teamCode: task.mission?.team?.code,
            financialYearCode:
                task.mission?.team?.division?.financialYear?.code,
        });

        if (taskType === 'Specified Task' && task.id && !task.isDuplicate) {
            taskPath = navigation.getPathForParams({
                tenantCode: currentTenantCode,
                missionId: mission.id,
                teamCode: task.mission?.team?.code,
                financialYearCode:
                    task.mission?.team?.division?.financialYear?.code,
                specifiedTaskId: task.id,
            });
        } else if (
            taskType === 'Implied Task' &&
            !isChecklistTask &&
            !task.isDuplicate &&
            task.parentTask?.id &&
            task.id
        ) {
            taskPath = navigation.getPathForParams({
                tenantCode: currentTenantCode,
                missionId: mission.id,
                teamCode: task.mission?.team?.code,
                financialYearCode:
                    task.mission?.team?.division?.financialYear?.code,
                specifiedTaskId: task.parentTask.id,
                impliedTaskId: task.id,
            });
        }
    }

    const classNames = mergeStyleSets({
        tooltipContent: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        tooltipContentButtons: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
        },
    });

    const tooltipContent: JSX.Element = (
        <div className={classNames.tooltipContent}>
            {missionPath ? (
                <Link href={missionPath}>{personaText}</Link>
            ) : (
                <Text variant="small">{personaText}</Text>
            )}
        </div>
    );

    const handleExpand = () => {
        setIsExpanding(true);
        props.onExpand();
    };

    useEffect(() => {
        if (props.isExpanded) {
            setIsExpanding(false);
        }
    }, [props.isExpanded]);

    return (
        <Stack
            horizontal
            horizontalAlign="center"
            styles={{
                root: {
                    borderTop: `solid 1px ${currentTheme.palette.neutralLight}`,
                    minHeight: 32,
                    padding: 4,
                },
            }}
        >
            <Stack.Item align="center" styles={{ root: { minWidth: 32 } }}>
                {!isDuplicateOfParent && (
                    <TooltipHost content={tooltipContent || ''}>
                        <Persona
                            size={PersonaSize.size24}
                            imageUrl={personaImageUrl}
                            text={personaText || undefined}
                            hidePersonaDetails
                        />
                    </TooltipHost>
                )}
            </Stack.Item>

            <Stack.Item align="center" styles={{ root: { minWidth: 72 } }}>
                {!isDuplicateOfParent && <Text variant="tiny">{taskType}</Text>}
            </Stack.Item>

            <Stack.Item
                align="center"
                styles={{
                    root: {
                        paddingLeft: (props.level - 1) * 24,
                        textAlign: 'right',
                    },
                }}
            >
                {props.showExpandCollapse && (
                    <ExpandCollapse
                        isExpanded={props.isExpanded}
                        isBusy={isExpanding}
                        onExpand={handleExpand}
                        onCollapse={props.onCollapse}
                    />
                )}
                {!props.showExpandCollapse && (
                    <Icon
                        iconName={
                            isDuplicateOfParent
                                ? 'Combine'
                                : 'StatusCircleInner'
                        }
                        title={
                            isDuplicateOfParent ? 'Merged' : taskStatus?.text
                        }
                        styles={{
                            root: {
                                padding: 8,
                                color:
                                    taskStatus?.colour ||
                                    currentTheme.palette.neutralLight,
                            },
                        }}
                    />
                )}
            </Stack.Item>
            <Stack.Item align="center" grow>
                <EditibleText
                    isReadOnly={isNameReadOnly}
                    text={task.name?.trim() || ''}
                    dialogTitle="Edit Task Name"
                    onUpdateClick={handleNameChange}
                >
                    <Text
                        variant="small"
                        styles={{
                            root: {
                                fontWeight: props.highlight
                                    ? 'bold'
                                    : undefined,
                            },
                        }}
                    >
                        {taskPath ? (
                            <Link href={taskPath}>{task.name}</Link>
                        ) : (
                            <Text variant="small">{task.name}</Text>
                        )}
                    </Text>
                </EditibleText>
            </Stack.Item>

            <Stack.Item align="center" styles={{ root: { minWidth: 128 } }}>
                <TaskProgressIndicator showStatus task={task} />
            </Stack.Item>
        </Stack>
    );
}
