import * as React from 'react';

import { useGetMissionSearchQuery, MissionQl } from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import {
    ShimmeredDetailsList,
    IconButton,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    SearchBox,
    Stack,
    Persona,
    PersonaSize,
    Selection,
} from '@fluentui/react';
import { useSorter } from '../../../hooks/useSorter';
import { photoService } from '../../../services/photo.service';
import { navigation } from '../../../services/navigation';
import { useNavigate } from 'react-router-dom';
import { DetailsListCellItemContainer } from '../../../components/shared/DetailsListCellItemContainer';

type MissionListProps = {
    onMissionIdsSelected?: (missionIds: string[]) => void;
    onMissionsSelected?: (missions: MissionQl[]) => void;
};

export function MissionList(props: MissionListProps): JSX.Element {
    const { currentTenantId, currentTenantCode, currentFinancialYearCode } =
        useStateContext();

    const [textFilter, setTextFilter] = React.useState<string | null>();

    const navigate = useNavigate();

    const { data, loading } = useGetMissionSearchQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            searchText: textFilter || '',
            isWritable: false,
            isImportable: true,
            includeInactive: false,
            includeDeleted: false,
            financialYearCode: currentFinancialYearCode || null,
            divisionId: null,
        },
    });

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(data?.missions, 'owner', false, true);

    function notNull<T>(val: T | null): val is T {
        return val !== null;
    }

    const _selection = new Selection({
        onSelectionChanged: () => {
            if (props.onMissionIdsSelected) {
                const missionIds = _selection
                    .getSelection()
                    .map((s) => {
                        const m = s as MissionQl;
                        return m.id;
                    })
                    .filter(notNull);
                props.onMissionIdsSelected(missionIds);
            }

            if (props.onMissionsSelected) {
                const missions = _selection
                    .getSelection()
                    .map((s) => s as MissionQl);
                props.onMissionsSelected(missions);
            }
        },
        // getKey: (item: MissionQl) => item.id || 0,
    });

    const columns: IColumn[] = [
        {
            key: 'financialYear',
            name: 'FY',
            fieldName: 'financialYear.code',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            onRender: function renderFinancialYear(
                item: MissionQl
            ): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        {item?.team?.division?.financialYear?.code}
                    </DetailsListCellItemContainer>
                );
            },
            isSorted: sortColumn === 'team.division.financialYear.code',
            isSortedDescending:
                sortColumn === 'team.division.financialYear.code' && sortIsDesc,
            onColumnClick: (): void =>
                setSortColumnName('team.division.financialYear.code', false),
        },
        {
            key: 'owner',
            name: 'Owner',
            fieldName: 'owner',
            minWidth: 100,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: sortColumn === 'owner',
            isSortedDescending: sortColumn === 'owner' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('owner', true),
        },
        {
            key: 'title',
            name: 'Title',
            fieldName: 'title',
            minWidth: 50,
            isResizable: true,
            isSorted: sortColumn === 'title',
            isSortedDescending: sortColumn === 'title' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('title', true),
        },
        {
            key: 'username',
            name: 'User',
            fieldName: 'username',
            minWidth: 200,
            isResizable: true,
            isSorted: sortColumn === 'username',
            isSortedDescending: sortColumn === 'username' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('username', true),
            onRender: function renderDisplayName(item: {
                userId: string | null;
                username: string | null;
            }): JSX.Element {
                if (item.userId) {
                    return (
                        <Persona
                            text={item.username || item.userId}
                            imageUrl={photoService.getImageUrl(item.userId)}
                            size={PersonaSize.size24}
                        />
                    );
                } else {
                    return <span />;
                }
            },
        },
        {
            key: 'teamCode',
            name: 'Team Code',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            isSorted: sortColumn === 'team.code',
            isSortedDescending: sortColumn === 'team.code' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('team.code', false),
            onRender: function renderDisplayName(item: {
                team: {
                    code: string | null;
                } | null;
            }): JSX.Element {
                return <span>{item.team?.code}</span>;
            },
        },
        {
            key: 'team',
            name: 'Team',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            isSorted: sortColumn === 'team.name',
            isSortedDescending: sortColumn === 'team.name' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('team.name', false),
            onRender: function renderDisplayName(item: {
                team: {
                    name: string | null;
                } | null;
            }): JSX.Element {
                return <span>{item.team?.name}</span>;
            },
        },
        {
            key: 'actions',
            name: '',
            minWidth: 64,
            isIconOnly: true,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderActions(item: MissionQl): JSX.Element {
                return (
                    <Stack horizontal>
                        <IconButton
                            iconProps={{ iconName: 'NavigateForward' }}
                            onClick={(): void => {
                                const path = navigation.getPathForParams({
                                    tenantCode: currentTenantCode || '',
                                    financialYearCode:
                                        currentFinancialYearCode || '',
                                    teamCode: item.team?.code || undefined,
                                    missionId: item.id || undefined,
                                });
                                navigate(path);
                            }}
                        />
                    </Stack>
                );
            },
        },
    ];

    return (
        <Stack>
            <Stack.Item>
                <SearchBox
                    styles={{
                        root: { minWidth: 300, maxWidth: 300 },
                    }}
                    // onChange={(_, newValue?: string) => {
                    //     setTextFilter(newValue);
                    // }}
                    onSearch={(newValue?: string) => {
                        if (textFilter !== newValue) {
                            setTextFilter(newValue);
                        }
                    }}
                    onClear={(): void => {
                        setTextFilter(null);
                    }}
                    iconProps={{ iconName: 'Filter' }}
                    placeholder="Filter by owner or title"
                />
            </Stack.Item>

            <ShimmeredDetailsList
                setKey="items"
                items={sortedItems || []}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                selection={_selection}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={loading}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details"
                listProps={{
                    renderedWindowsAhead: 0,
                    renderedWindowsBehind: 0,
                }}
            />
        </Stack>
    );
}
