import { generatePath } from 'react-router-dom';

const tenantCode = ':tenantCode';
const financialYearCode = ':financialYearCode';

export const paths = {
    setupTenants: `/setup/tenants`,
    setupUsers: `/setup/users`,
    usageStats: `/setup/usage`,
    usageOverview: `/setup/usage-overview`,
    termsOfUse: `/legal/terms-of-use`,
    privacyPolicy: `/legal/privacy-policy`,
    help: `/help`,
    helpDownload: `/help-download`,
    helpSection: `/help/:section`,
    switchTenant: `/switch-tenant`,
    importer: `/${tenantCode}/${financialYearCode}/imports`,
    reports: `/${tenantCode}/${financialYearCode}/reports`,
    report: `/${tenantCode}/${financialYearCode}/reports/:reportName`,
    importerSection: `/${tenantCode}/${financialYearCode}/imports/:sectionKey`,
    setupHome: `/${tenantCode}/setup`,
    setupSection: `/${tenantCode}/setup/:sectionKey`,
    missionBuilder: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId`,
    missionBuilderMeasure: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/measures-of-success/:measureId`,
    missionBuilderMeasureFormulaEditor: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/measures-of-success/:measureId/formula-editor`,
    missionBuilderSpecifiedTask: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/specified-tasks/:specifiedTaskId`,
    missionBuilderImpliedTask: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/specified-tasks/:specifiedTaskId/implied-tasks/:impliedTaskId`,
    statusReport: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/status-reports/:statusReportId`,
    missionAnalysisPresentation: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/presentations/mission-analysis`,
    missionSummaryPresentation: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/presentations/mission-summary`,
    statusReportPresentation: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/status-reports/:statusReportId/presentation`,
    present: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/present/:presentation`,
    presentStatusReport: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/status-reports/:statusReportId/present/:presentation`,
    presentTemplateReport: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/template-reports/:templateReportId/present/:presentation`,
    presentTemplateReportView: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/template-reports/:templateReportId/present/:presentation/views/:templateReportViewId`,
    statusReports: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/status-reports`,
    team: `/${tenantCode}/${financialYearCode}/teams/:teamCode`,
    templateReport: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/status-reports/template-reports/:templateReportId`,
    templateReportPresentation: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/status-reports/template-reports/:templateReportId/presentation`,
    templateReportPresentationView: `/${tenantCode}/${financialYearCode}/teams/:teamCode/missions/:missionId/status-reports/template-reports/:templateReportId/presentation/views/:templateReportViewId`,
    contributor: `/${tenantCode}/${financialYearCode}/contributors/:contributorId/tasks`,
    contributorTask: `/${tenantCode}/${financialYearCode}/contributors/:contributorId/tasks/:taskId`,
    contributorWithTeam: `/${tenantCode}/${financialYearCode}/:teamCode/contributors/:contributorId/tasks`,
    contributorWithTeamTask: `/${tenantCode}/${financialYearCode}/:teamCode/contributors/:contributorId/tasks/:taskId`,
    fyRoot: `/${tenantCode}/${financialYearCode}`,
    tenantRoot: `/${tenantCode}`,
    root: `/`,
};

export type PathParams = {
    tenantCode?: string | undefined;
    financialYearCode?: string | undefined;
    teamCode?: string | undefined;
    missionId?: string | undefined;
    statusReportId?: string | undefined;
    templateReportId?: string | undefined;
    templateReportViewId?: string | undefined;
    measureId?: string | undefined;
    specifiedTaskId?: string | undefined;
    impliedTaskId?: string | undefined;
    sectionKey?: string | undefined;
    contributorId?: string | undefined;
    taskId?: string | undefined;
    presentation?: string | undefined;
};

class Navigation {
    getPathForParams = (
        params: PathParams & { formulaEdit?: boolean | undefined }
    ): string => {
        let pathPattern: string;

        if (params.statusReportId) {
            pathPattern = paths.statusReport;
        } else if (params.templateReportId) {
            pathPattern = paths.templateReport;
        } else if (params.measureId && params.formulaEdit) {
            pathPattern = paths.missionBuilderMeasureFormulaEditor;
        } else if (params.measureId) {
            pathPattern = paths.missionBuilderMeasure;
        } else if (params.specifiedTaskId && params.impliedTaskId) {
            pathPattern = paths.missionBuilderImpliedTask;
        } else if (params.specifiedTaskId) {
            pathPattern = paths.missionBuilderSpecifiedTask;
        } else if (params.missionId) {
            pathPattern = paths.missionBuilder;
        } else if (
            params.contributorId &&
            params.teamCode &&
            params.financialYearCode
        ) {
            if (params.taskId) {
                pathPattern = paths.contributorWithTeamTask;
            } else {
                pathPattern = paths.contributorWithTeam;
            }
        } else if (params.contributorId && params.financialYearCode) {
            if (params.taskId) {
                pathPattern = paths.contributorTask;
            } else {
                pathPattern = paths.contributor;
            }
        } else if (params.teamCode) {
            pathPattern = paths.team;
        } else if (params.financialYearCode) {
            pathPattern = paths.fyRoot;
        } else {
            pathPattern = paths.tenantRoot;
        }

        return generatePath(pathPattern, {
            ...params,
            tenantCode: params.tenantCode?.toLowerCase(),
            financialYearCode: params.financialYearCode?.toLowerCase(),
            teamCode: params.teamCode?.toLowerCase() || undefined,
        });
    };
}

export const navigation = new Navigation();
