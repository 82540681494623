import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    FontSizes,
    IconButton,
    ScrollablePane,
    mergeStyleSets,
    Sticky,
    StickyPositionType,
    ThemeProvider,
    SearchBox,
    Text,
    IRawStyle,
} from '@fluentui/react';
import MarkdownIt from 'markdown-it';
import { useParams, useSearchParams } from 'react-router-dom';
import { AdvanceCard } from '../AdvanceCard';
import { useThemes } from '../../hooks/useThemes';
import _content from '../../help/_contents.md';
import Loading from '../Loading';
import { useSearchMarkdownLinks } from '../../hooks/useSearchMarkdownLinks';
import { useActiveView } from '../../hooks/useActiveView';
import { MarkdownItFluentUIIcon } from './MarkdownItIconPlugin';
import { useDispatchContext } from '../../services/contextProvider';

// Keeps track of the current help page
function useHelpHistory(
    defaultSection: string
): [
    helpHistory: string[],
    setHelpHistory: (
        value: string[] | ((prevState: string[]) => string[])
    ) => void,
] {
    const helpHistoryStorageKey = 'ADVANCE_HELP_HISTORY';
    const [helpHistory, setHelpHistory] = useState(
        window.sessionStorage.getItem(helpHistoryStorageKey)?.split('|') || [
            defaultSection,
        ]
    );

    useEffect(() => {
        window.sessionStorage.setItem(
            helpHistoryStorageKey,
            helpHistory.join('|')
        );
    }, [helpHistory]);

    return [helpHistory, setHelpHistory];
}

export function HelpContent(): JSX.Element {
    const DefaultSection = '_contents';
    const SearchSection = 'SEARCH_QUERY=';

    const { section } = useParams<{ section?: string }>();

    const dispatch = useDispatchContext();
    const { currentTheme } = useThemes();

    const [helpHistory, setHelpHistory] = useHelpHistory(DefaultSection);

    const [showSearch, setShowSearch] = useState(false);

    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const [searchResults, setSearchResults] = useState<JSX.Element[]>();

    const { search } = useSearchMarkdownLinks(_content);

    const [searchParams] = useSearchParams();

    const themeStorageKey = 'ADVANCE_HELP_THEME';

    useEffect(() => {
        let themePrimaryColourHex: string | null = null;

        if (searchParams.has('themePrimaryColourHex')) {
            themePrimaryColourHex = searchParams.get('themePrimaryColourHex');
            window.sessionStorage.setItem(
                themeStorageKey,
                themePrimaryColourHex || ''
            );
        } else {
            themePrimaryColourHex =
                window.sessionStorage.getItem(themeStorageKey);
        }

        if (themePrimaryColourHex) {
            dispatch({
                type: 'SetThemePrimaryColour',
                payload: {
                    themePrimaryColourHex: themePrimaryColourHex,
                },
            });
        }
    }, [searchParams, dispatch]);

    const md = useMemo(
        () =>
            new MarkdownIt().use(MarkdownItFluentUIIcon, {
                theme: currentTheme,
            }),
        [currentTheme]
    );

    const [docTitle, setDocTitle] = useState<string | undefined>();
    const [contentHtml, setContentHtml] = useState<string>();

    useActiveView('HelpContent', docTitle);

    const extractTitleFromMarkdown = useCallback(
        (markdown: string): string | null => {
            const tokens = md.parse(markdown, {});
            for (let i = 0; i < tokens.length; i++) {
                const token = tokens[i as number];
                if (token.type === 'heading_open' && token.tag === 'h1') {
                    const titleToken = tokens[i + 1];
                    if (titleToken && titleToken.type === 'inline') {
                        return titleToken.content;
                    }
                }
            }
            return null;
        },
        [md]
    );

    const loadContent = useCallback(
        async (fileName: string) => {
            try {
                setContentHtml(undefined);

                const { default: markdown } = await import(
                    `../../help/${fileName}.md`
                );

                const title = extractTitleFromMarkdown(markdown);
                if (title) {
                    setDocTitle(`Help: ${title}`);
                } else {
                    setDocTitle(`Help`);
                }

                // Remove the test links from the contents
                const filteredMarkdown =
                    fileName === DefaultSection
                        ? markdown.replace(
                              /\[([^\]]+)\]\(([^)]+)\)/g,
                              (match: string, text: string, url: string) => {
                                  if (url.toLowerCase().includes('test')) {
                                      return ''; // Remove the link if 'test' is found in the URL
                                  }
                                  return match; // Keep the original link otherwise
                              }
                          )
                        : markdown;

                const parsed = md.render(filteredMarkdown);
                setContentHtml(parsed);
            } catch (error) {
                console.error('Error loading content:', error);
            }
        },
        [extractTitleFromMarkdown, md]
    );

    useEffect(() => {
        if (section) {
            setHelpHistory((h) => [...h, section ?? DefaultSection]);
        }
    }, [section, setHelpHistory]);

    useEffect(() => {
        if (helpHistory.length) {
            const newHelpHistory = [...helpHistory];
            const fileName = newHelpHistory.pop() || DefaultSection;

            if (fileName.startsWith(SearchSection)) {
                const query = fileName.replace(SearchSection, '');
                setSearchQuery(query);
            } else {
                setSearchQuery(null);
                loadContent(fileName);
                setShowSearch(fileName === DefaultSection);
            }
        }
    }, [helpHistory, loadContent]);

    useEffect(() => {
        const results = search(searchQuery || '');
        setSearchResults(results);
    }, [searchQuery, search]);

    const goHome = () => setHelpHistory([DefaultSection]);
    const goBack = () => setHelpHistory((h) => h.slice(0, -1));

    const handleQueryChanged = (query: string | null) => {
        if (query) {
            const newHelpHistory = [
                ...helpHistory,
                SearchSection + query.replace('|', ''),
            ];
            setHelpHistory(newHelpHistory);
        } else {
            setHelpHistory([...helpHistory, DefaultSection]);
        }
    };

    const classNames = mergeStyleSets({
        themeProvider: {
            height: '100%',
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: 'transparent',
        },
        scrollablePane: {
            backgroundColor: currentTheme.palette.neutralLighter,
        },
        container: {
            //backgroundColor: currentTheme.palette.neutralLighter,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 16,
        },
        navBar: {
            padding: 16,
            paddingBottom: 8,
            //backgroundColor: currentTheme.palette.neutralLighter,
            display: 'flex',
            gap: 8,
            flexDirection: 'row',
            alignItems: 'center',
        },
        navButtons: {
            display: 'flex',
            gap: 0,
            flexDirection: 'row',
        },
        navSearch: {
            flexGrow: 1,
        },
        searchResults: {
            marginTop: 8,
            marginLeft: 16,
            marginRight: 16,
            marginBottom: 8,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
        contentContainer: {
            marginTop: 8,
            marginLeft: 16,
            marginRight: 16,
            '& H1': {
                fontSize: FontSizes.mediumPlus,
                margin: 0,
                marginBottom: 8,
            },
            '& H2': {
                fontSize: FontSizes.medium,
                margin: 0,
                marginBottom: 8,
            },
            '& H3': {
                fontSize: FontSizes.medium,
                margin: 0,
                marginBottom: 8,
            },
            '& IMG': {
                maxWidth: '100%',
                height: 'auto',
                border: `solid 1px ${currentTheme.palette.neutralLight}`,
                boxShadow: currentTheme.effects.elevation4,
                '@media screen and (-webkit-device-pixel-ratio: 1.5)': {
                    maxWidth: 170,
                },
                '@media screen and (-webkit-device-pixel-ratio: 2)': {
                    maxWidth: 170,
                },
            } as IRawStyle,

            '& BLOCKQUOTE': {
                marginLeft: 16,
                marginRight: 8,
                paddingLeft: 12,
                paddingRight: 16,
                paddingTop: 1,
                paddingBottom: 1,
                borderLeft: `solid 4px ${currentTheme.palette.themeLight}`,
                backgroundColor: currentTheme.palette.themeLighterAlt,
                fontStyle: 'italic',
            },
            '& BLOCKQUOTE P ': {
                marginTop: 8,
                marginBottom: 8,
            },
            '& OL, UL': {
                paddingLeft: 16,
            },
            '& UL LI': {
                marginBottom: 4,
            },
            '& P': {
                marginTop: 14,
                marginBottom: 14,
            },
            '& LI P': {
                marginBottom: 8,
            },
            '& A': {
                textDecoration: 'none',
                color: currentTheme.semanticColors.link,
                '& :visited': {
                    color: currentTheme.semanticColors.link,
                },
                '& :hover': {
                    color: currentTheme.semanticColors.linkHovered,
                },
            },
        },
    });

    return (
        <ThemeProvider
            theme={currentTheme}
            className={classNames.themeProvider}
        >
            <ScrollablePane className={classNames.scrollablePane}>
                <Sticky stickyPosition={StickyPositionType.Both}>
                    <div className={classNames.navBar}>
                        <div className={classNames.navButtons}>
                            <IconButton
                                iconProps={{ iconName: 'Back' }}
                                onClick={goBack}
                                disabled={helpHistory.length <= 1}
                            />
                            <IconButton
                                iconProps={{ iconName: 'Home' }}
                                onClick={goHome}
                            />
                        </div>
                        {showSearch && (
                            <div className={classNames.navSearch}>
                                <HelpSearchBox
                                    value={searchQuery}
                                    onQueryChanged={handleQueryChanged}
                                />
                            </div>
                        )}
                        {!showSearch && !!searchQuery && (
                            <Text variant="mediumPlus">
                                Results for &quot;{searchQuery}&quot;
                            </Text>
                        )}
                    </div>
                </Sticky>

                {searchResults?.length ? (
                    <div className={classNames.container}>
                        <AdvanceCard>
                            <div className={classNames.searchResults}>
                                {searchResults}
                            </div>
                        </AdvanceCard>
                    </div>
                ) : contentHtml ? (
                    <div className={classNames.container}>
                        <AdvanceCard>
                            <div
                                className={classNames.contentContainer}
                                dangerouslySetInnerHTML={{
                                    __html: contentHtml,
                                }}
                            />
                        </AdvanceCard>
                    </div>
                ) : (
                    <Loading />
                )}
            </ScrollablePane>
        </ThemeProvider>
    );
}

function HelpSearchBox(props: {
    value: string | null;
    onQueryChanged: (query: string | null) => void;
}): JSX.Element {
    const handleSearch = (query: string) => {
        props.onQueryChanged(query);
    };

    const handleClear = () => {
        props.onQueryChanged(null);
    };

    return <SearchBox onSearch={handleSearch} onClear={handleClear} />;
}
