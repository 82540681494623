export const useAttachmentIcons = (): {
    getIconName: (attachment: {
        href: string | null;
        isFile: boolean;
        name: string | null;
    }) => string | undefined;
} => {
    return {
        getIconName: (attachment: {
            href: string | null;
            isFile: boolean;
            name: string | null;
        }): string | undefined => {
            if (attachment.isFile) {
                const fileExt = attachment.name?.split('.').pop();
                switch (fileExt) {
                    case 'txt':
                        return 'TextDocument';
                    case 'xml':
                        return 'FileHTML';
                    case 'pdf':
                        return 'PDF';
                    case 'xls':
                    case 'xlsx':
                    case 'xlsm':
                        return 'ExcelDocument';
                    case 'pps':
                    case 'ppt':
                    case 'pptx':
                    case 'pptm':
                        return 'PowerPointDocument';
                    case 'doc':
                    case 'docx':
                        return 'WordDocument';
                    case 'jpeg':
                    case 'jpg':
                    case 'png':
                    case 'gif':
                        return 'FileImage';
                    default:
                        return 'Document';
                }
            } else {
                return 'PageLink';
            }
        },
    };
};
