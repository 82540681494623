import React, { useEffect, useState } from 'react';

import {
    useUpdateMeasureGroupMutation,
    useDeleteMeasureGroupMutation,
    useGetMeasureGroupsQuery,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { sorters } from '../data/sorters';
import { Access } from '../data/extendedTypes';
import EditGroupingPanel, { GroupingPanelGroup } from './EditGroupingPanel';

type EditMeasureGroupsPanelProps = {
    missionId: string | null | undefined;
    missionAccess: Access;
    showPanel: boolean;
    onCancel: () => void;
    onSave: () => void;
};

export default function EditMeasureGroupsPanel(
    props: EditMeasureGroupsPanelProps
): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [updateMeasureGroup, { error: saveError }] =
        useUpdateMeasureGroupMutation();

    const [deleteMeasureGroup, { error: deleteError }] =
        useDeleteMeasureGroupMutation();

    const [groups, setGroups] = useState<GroupingPanelGroup[]>();

    const { data, loading } = useGetMeasureGroupsQuery({
        fetchPolicy: 'network-only',
        skip: !currentTenantId || !props.missionId || !props.showPanel,
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId || '',
        },
    });

    useEffect(() => {
        if (data?.measureGroups && props.showPanel && props.missionId) {
            setGroups(
                data?.measureGroups
                    ?.slice()
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    ?.sort(sorters.sequenceSorter)
                    .map((item) => {
                        return {
                            ...item,
                            isDeleted: false,
                            isNew: false,
                        };
                    }) || []
            );
        } else {
            setGroups(undefined);
        }
    }, [props.showPanel, props.missionId, data]);

    const updateGroup = async (item: GroupingPanelGroup) => {
        await updateMeasureGroup({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    id: item.id,
                    name: item.name.trim(),
                    missionId: props.missionId || '',
                    sequence: item.sequence,
                    version: item.version || '',
                },
            },
        });
    };

    const deleteGroup = async (item: GroupingPanelGroup) => {
        await deleteMeasureGroup({
            update: (cache, mutationResult) => {
                const deletedObject =
                    mutationResult.data?.measureGroupDelete?.deletedObject;
                if (deletedObject?.utcDeleted) {
                    cache.evict({ id: cache.identify(deletedObject) });
                    cache.gc();
                }
            },
            variables: {
                tenantId: currentTenantId || '',
                id: item.id,
                restore: false,
            },
        });
    };

    return (
        <EditGroupingPanel
            showPanel={props.showPanel}
            defaultGroups={groups}
            isLoading={loading}
            updateGroup={updateGroup}
            deleteGroup={deleteGroup}
            missionAccess={props.missionAccess}
            errorMessage={saveError?.message || deleteError?.message}
            onSave={props.onSave}
            onCancel={props.onCancel}
            activeViewName="MeasureGrouping"
            addButtonText="Add Group"
        />
    );
}
