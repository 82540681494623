import React from 'react';

import { PanelType } from '@fluentui/react';
import { EditPanel } from './shared/EditPanel';
import { sorters } from '../data/sorters';
import { Access } from '../data/extendedTypes';
import { ActiveViews } from '../hooks/useActiveView';
import {
    SortableTextInputItem,
    SortableTextInputList,
} from './SortableTextInputList';

export type GroupingPanelGroup = SortableTextInputItem;

type EditGroupingPanelProps = {
    defaultGroups: GroupingPanelGroup[] | undefined;
    onCancel: () => void;
    onSave: () => void;
    updateGroup: (group: GroupingPanelGroup) => Promise<void>;
    deleteGroup: (group: GroupingPanelGroup) => Promise<void>;
    errorMessage: string | null | undefined;
    missionAccess: Access;
    showPanel: boolean;
    activeViewName: keyof typeof ActiveViews;
    headerText?: string;
    addButtonText?: string;
    isLoading: boolean;
};

export default function EditGroupingPanel(
    props: EditGroupingPanelProps
): JSX.Element {
    const [isSaving, setIsSaving] = React.useState(false);
    const [hasSaved, setHasSaved] = React.useState(false);
    const [groups, setGroups] = React.useState<GroupingPanelGroup[]>();

    const isGroupDirty = (group: GroupingPanelGroup) => {
        const original = props.defaultGroups?.find(
            (og: GroupingPanelGroup) => og.id === group.id
        );
        return (
            original &&
            (original.name !== group.name ||
                original.sequence !== group.sequence)
        );
    };

    React.useEffect(() => {
        setIsSaving(false);
        setHasSaved(false);
        if (props.showPanel && !props.isLoading) {
            let sequence = 0;
            setGroups(
                props.defaultGroups?.map((g: SortableTextInputItem) => {
                    return {
                        ...g,
                        sequence: sequence++,
                    };
                })
            );
        } else {
            setGroups(undefined);
        }
    }, [props.showPanel, props.defaultGroups, props.isLoading]);

    const handleSave = async () => {
        setIsSaving(true);
        setHasSaved(false);

        if (!groups) {
            return;
        }

        // Resequence again to skip the deleted.
        let sequence = 0;
        await Promise.all(
            groups
                .map((g) => {
                    return {
                        ...g,
                        sequence: g.isDeleted ? g.sequence : sequence++,
                    };
                })
                .sort(sorters.sequenceSorter)
                .map(async (g) => {
                    if (g.isDeleted && !g.isNew) {
                        await props.deleteGroup(g);
                    } else if (g.isNew && g.name && !g.isDeleted) {
                        await props.updateGroup(g);
                    } else if (!g.isNew && isGroupDirty(g)) {
                        await props.updateGroup(g);
                    }
                })
        );

        setIsSaving(false);
        setHasSaved(true);

        props.onSave();
    };

    return (
        <EditPanel
            activeViewName={props.activeViewName}
            onDismiss={props.onCancel}
            showPanel={props.showPanel}
            panelType={PanelType.smallFixedFar}
            headerText={props.headerText || 'Grouping'}
            isSaving={isSaving}
            isValid={props.missionAccess.write}
            hasSaved={hasSaved}
            saveErrorMessage={props.errorMessage}
            onUpdateClick={handleSave}
        >
            <SortableTextInputList
                {...props}
                inputItems={groups || []}
                onChange={(updatedItems) => setGroups(updatedItems)}
                isReadOnly={!props.missionAccess.write}
                addButtonText={props.addButtonText}
            />
        </EditPanel>
    );
}
