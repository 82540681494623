import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';

export type CookieTypeKeys =
    | 'ESSENTIAL'
    | 'PERFORMANCE_AND_ANALYTICS'
    | 'DUMMY';

export const CookieTypes: {
    key: CookieTypeKeys;
    title: string;
    description: string;
    isEssential: boolean;
}[] = [
    {
        key: 'ESSENTIAL',
        title: '1. Essential cookies',
        description:
            'These cookies are necessary for the website to function and cannot be switched off in our systems.',
        isEssential: true,
    },
    {
        key: 'PERFORMANCE_AND_ANALYTICS',
        title: '2. Performance & analytics',
        description:
            'These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site.',
        isEssential: false,
    },
];

export function useCookieConsent(): {
    acceptAll: () => void;
    rejectAll: () => void;
    acceptOnlyTypes: (types: CookieTypeKeys[]) => void;
    isConsentRequired: boolean;
    hasAccepted: (type: CookieTypeKeys) => boolean;
    getConsentState: (
        type: CookieTypeKeys
    ) => 'NotSet' | 'Accepted' | 'Rejected';
} {
    const ConsentCookieName = 'cookie-consent';

    type ConsentCookieValueType = {
        cookieType: CookieTypeKeys;
        isAccepted: boolean;
        lastUpdatedDate: string;
    }[];

    const [cookies, setCookie] = useCookies([ConsentCookieName]);

    const cookieSetOptions: CookieSetOptions = {
        secure: true,
        httpOnly: false,
        sameSite: 'strict',
        maxAge: 365 * 24 * 60 * 60, // 1 Year in seconds
        path: '/',
    };

    const consentCookie = cookies['cookie-consent'];

    const isConsentRequired = !consentCookie;

    const updateConsentCookie = (cookieValue: ConsentCookieValueType) => {
        setCookie(ConsentCookieName, cookieValue, cookieSetOptions);
    };

    return {
        acceptAll: () => {
            updateConsentCookie(
                CookieTypes.filter((ct) => !ct.isEssential).map((ct) => ({
                    cookieType: ct.key,
                    isAccepted: true,
                    lastUpdatedDate: new Date().toISOString(),
                }))
            );
        },
        rejectAll: () => {
            updateConsentCookie(
                CookieTypes.filter((ct) => !ct.isEssential).map((ct) => ({
                    cookieType: ct.key,
                    isAccepted: false,
                    lastUpdatedDate: new Date().toISOString(),
                }))
            );
        },
        acceptOnlyTypes: (acceptedTypes: CookieTypeKeys[]) => {
            updateConsentCookie(
                CookieTypes.filter((ct) => !ct.isEssential).map((ct) => ({
                    cookieType: ct.key,
                    isAccepted: acceptedTypes.some((at) => at === ct.key),
                    lastUpdatedDate: new Date().toISOString(),
                }))
            );
        },
        hasAccepted: (type: CookieTypeKeys) =>
            (consentCookie as ConsentCookieValueType)?.some(
                (ct) => ct.cookieType === type && ct.isAccepted
            ) || false,
        getConsentState: (
            type: CookieTypeKeys
        ): 'NotSet' | 'Accepted' | 'Rejected' => {
            const consentForCookie = (
                consentCookie as ConsentCookieValueType
            )?.find((ct) => ct.cookieType === type);
            if (consentForCookie) {
                return consentForCookie.isAccepted ? 'Accepted' : 'Rejected';
            }
            return 'NotSet';
        },
        isConsentRequired,
    };
}
