import React, { useState } from 'react';
import {
    IconButton,
    IButtonStyles,
    ContextualMenuItemType,
    IContextualMenuItem,
} from '@fluentui/react';
import { useGetTasksAndResourcesLazyQuery } from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { TaskSummaryItem } from '../scenes/MissionBuilder/components/TaskList';
import MoveToPositionDialog from './shared/MoveToPositionDialog';

export function SpecifiedTaskMoreOptionsButton(props: {
    missionId: string;
    specifiedTask: TaskSummaryItem;
    onEditClick: (taskId: string) => void;
    buttonStyles: IButtonStyles;
    onMove: (newIndex: number) => void;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { specifiedTask, missionId } = props;

    const [getTasks] = useGetTasksAndResourcesLazyQuery({
        variables: {
            tenantId: currentTenantId || '',
            missionId: missionId || '',
        },
    });

    const [moveDialogHidden, setMoveDialogHidden] = useState(true);
    const showMoveDialog = () => setMoveDialogHidden(false);
    const hideMoveDialog = () => setMoveDialogHidden(true);

    const [maxPosition, setMaxPostion] = useState(1);

    const onEditClick = () =>
        specifiedTask.id ? props.onEditClick(specifiedTask.id) : undefined;

    const handleMoveToPosition = async (newPosition: number) => {
        props.onMove(newPosition - 1);
    };

    const handleMoveToTop = async () => {
        handleMoveToPosition(1);
    };

    const loadMenuOptions = async () => {
        const tasksResult = await getTasks();

        const specifiedTasks = tasksResult.data?.tasks
            .filter((t) => t.parentTaskId === null)
            .filter((t) => !t.isDuplicate);

        setMaxPostion(specifiedTasks?.length || 1);
    };

    const onMenuClick = () => {
        loadMenuOptions();
    };

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'edit',
            text: 'Edit',
            iconProps: { iconName: 'Edit' },
            onClick: onEditClick,
        },
        {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider,
        },
        {
            key: 'movetotop',
            text: 'Move to top',
            iconProps: { iconName: 'Up' },
            disabled: specifiedTask.sequence == 0,
            onClick: () => {
                handleMoveToTop();
            },
        },
        {
            key: 'movepostition',
            text: 'Move to position...',
            disabled: maxPosition === 1,
            iconProps: { iconName: 'NumberedListText' },
            onClick: () => showMoveDialog(),
        },
    ];

    return (
        <>
            <IconButton
                iconProps={{ iconName: 'MoreVertical' }}
                title="More options"
                ariaLabel="More options"
                styles={props.buttonStyles}
                onRenderMenuIcon={() => {
                    return null;
                }}
                onMenuClick={onMenuClick}
                menuProps={{
                    items: menuItems,
                }}
            />
            <MoveToPositionDialog
                hidden={moveDialogHidden}
                onDismiss={hideMoveDialog}
                maxValue={maxPosition}
                onMoveClicked={(newPosition: number) => {
                    hideMoveDialog();
                    handleMoveToPosition(newPosition);
                }}
            />
        </>
    );
}
