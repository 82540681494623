import React from 'react';
import {
    ActionButton,
    ContextualMenuItemType,
    IButtonProps,
    IContextualMenuItem,
    Icon,
    Stack,
} from '@fluentui/react';

export function TaskFilterCategorySelector(props: {
    taskCategories: {
        id: string | null;
        count?: number | null;
        name: string | null;
        colourHex: string | null;
    }[];
    selectedCategoryIds: string[];
    onCategoryFilterChanged: (selectedCategoryIds: string[]) => void;
}): JSX.Element {
    const { taskCategories, selectedCategoryIds, onCategoryFilterChanged } =
        props;

    const handleCategoryFilterClick = (categoryId: string) => {
        let updated: string[];
        if (selectedCategoryIds?.includes(categoryId)) {
            updated = selectedCategoryIds.filter((c) => c !== categoryId);
        } else {
            updated = [...(selectedCategoryIds || []), categoryId];
        }
        onCategoryFilterChanged(updated);
    };

    const handeRenderText = (
        props?: IButtonProps,
        defaultRender?: (props?: IButtonProps) => JSX.Element | null
    ): JSX.Element => {
        return (
            <Stack horizontal tokens={{ childrenGap: 2 }}>
                {defaultRender && defaultRender(props)}

                {taskCategories
                    .filter((tc) => selectedCategoryIds.includes(tc.id || ''))
                    .map((tc) => (
                        <Icon
                            key={tc.name}
                            iconName={tc.id ? 'TagSolid' : 'Untag'}
                            styles={
                                tc.colourHex
                                    ? {
                                          root: {
                                              color: `#${tc.colourHex}`,
                                          },
                                      }
                                    : undefined
                            }
                        />
                    ))}
            </Stack>
        );
    };

    const menuItems: IContextualMenuItem[] = taskCategories.map((tc) => {
        return {
            key: tc.id || '',
            text: tc.count ? `${tc.name} (${tc.count})` : tc.name || '',
            canCheck: true,
            checked: selectedCategoryIds?.includes(tc.id || ''),
            onClick: () => handleCategoryFilterClick(tc.id || ''),
            iconProps: {
                iconName: tc.id ? 'TagSolid' : 'Untag',
                styles: tc.colourHex
                    ? {
                          root: {
                              color: `#${tc.colourHex}`,
                          },
                      }
                    : undefined,
            },
        };
    });

    if (selectedCategoryIds.length > 0) {
        menuItems.push(
            ...[
                {
                    key: 'clear-divider',
                    itemType: ContextualMenuItemType.Divider,
                },
                {
                    key: 'clear',
                    text: 'Clear',
                    iconProps: {
                        iconName: 'Cancel',
                    },
                    onClick: () => onCategoryFilterChanged([]),
                },
            ]
        );
    }

    return (
        <ActionButton
            text="Category"
            onRenderText={handeRenderText}
            menuProps={{
                items: menuItems,
            }}
        />
    );
}
