import dayjs from 'dayjs';
import { ReportPeriodTypes } from '../data/types';
import { getQuarter } from '../scenes/Measure/utils/measureUtils';
import { DatePickerStrings } from '../services/i18n';

export function useReportPeriods(
    fyStartDate: string | null | undefined,
    fyEndDate: string | null | undefined
): {
    getPeriods(
        reportPeriodType: ReportPeriodTypes | null | undefined
    ): { text: string; period: number }[];
    formatPeriod(
        reportPeriodType: ReportPeriodTypes | null | undefined,
        reportPeriod: number | null | undefined
    ): string;
} {
    // The month number in the current fy
    // For example: If the FY starts 1 Apr, May would be 2.
    const getMonthNumber = (
        fyStart: string | dayjs.Dayjs,
        current: string | dayjs.Dayjs
    ): number => {
        const startYear = dayjs(fyStart).year();
        const currentYear = dayjs(current).year();
        const startMonth = dayjs(fyStart).month();
        const currentMonth = dayjs(current).month();

        let monthNumber: number;

        if (currentYear > startYear) {
            monthNumber = currentMonth + 12 - startMonth + 1;
        } else {
            monthNumber = currentMonth - startMonth + 1;
        }

        return Math.floor(monthNumber);
    };

    const getMonthNumberName = (
        fyStart: string | dayjs.Dayjs,
        monthNumber: number
    ): string => {
        const periodDate = dayjs(fyStart).add(monthNumber - 1, 'M');

        return `${DatePickerStrings.months[periodDate.month()]} ${periodDate.year()}`;
    };

    return {
        formatPeriod: (
            reportPeriodType: ReportPeriodTypes | null | undefined,
            reportPeriod: number | null | undefined
        ) => {
            const prefix =
                reportPeriodType === ReportPeriodTypes.Quarter
                    ? 'Q'
                    : reportPeriodType === ReportPeriodTypes.Half
                      ? 'H'
                      : '';
            return reportPeriodType === ReportPeriodTypes.Month &&
                fyStartDate &&
                reportPeriod
                ? getMonthNumberName(fyStartDate, reportPeriod)
                : `${prefix}${reportPeriod}`;
        },
        getPeriods: (reportPeriodType: ReportPeriodTypes | null) => {
            if (!fyStartDate || !reportPeriodType) {
                return [];
            }

            const periods: { text: string; period: number }[] = [];

            let currentDate = dayjs().isAfter(fyEndDate)
                ? dayjs(fyEndDate)
                : dayjs();

            if (dayjs(currentDate).isBefore(fyStartDate)) {
                currentDate = dayjs(fyStartDate);
            }

            switch (reportPeriodType) {
                case ReportPeriodTypes.Month: {
                    const monthNumber = getMonthNumber(
                        fyStartDate,
                        currentDate
                    );

                    if (monthNumber) {
                        const monthNumberName = getMonthNumberName(
                            fyStartDate,
                            monthNumber
                        );
                        periods.push({
                            period: monthNumber,
                            text: `This month - ${monthNumberName}`,
                        });
                    }
                    if (monthNumber > 1) {
                        const lastMonthNumberName = getMonthNumberName(
                            fyStartDate,
                            monthNumber - 1
                        );
                        periods.push({
                            period: monthNumber - 1,
                            text: `Last month - ${lastMonthNumberName}`,
                        });
                    }
                    break;
                }
                case ReportPeriodTypes.Quarter: {
                    const quarter = getQuarter(fyStartDate, currentDate);
                    if (quarter) {
                        periods.push({
                            period: quarter,
                            text: `This quarter - Q${quarter}`,
                        });
                    }
                    if (quarter > 1) {
                        periods.push({
                            period: quarter - 1,
                            text: `Last quarter - Q${quarter - 1}`,
                        });
                    }
                    break;
                }
                case ReportPeriodTypes.Half:
                    // TODO
                    break;
                default:
                    break;
            }

            return periods;
        },
    };
}
