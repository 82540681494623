import React from 'react';
import { SearchBox } from '@fluentui/react';

export function TaskFilterSearchBox(props: {
    keywordValue: string | null;
    onKeywordChanged: (keyword: string | null) => void;
    onSearch: () => void;
    placeholder?: string;
}): JSX.Element {
    const handleKeywordChange = (
        _event?: React.ChangeEvent,
        newValue?: string
    ) => props.onKeywordChanged(newValue || null);

    return (
        <SearchBox
            placeholder={
                props.placeholder !== undefined
                    ? props.placeholder
                    : 'Filter by keyword'
            }
            showIcon
            iconProps={{ iconName: 'Filter' }}
            onChange={handleKeywordChange}
            onSearch={props.onSearch}
            value={props.keywordValue || ''}
            styles={{
                clearButton: {
                    display: 'none',
                },
                root: {
                    backgroundColor: 'transparent',
                    border: 'none',
                    selectors: {
                        ':after': {
                            border: 'none',
                        },
                    },
                },
            }}
        />
    );
}
