import React, { useEffect, useState } from 'react';
import {
    Stack,
    PrimaryButton,
    ChoiceGroup,
    IChoiceGroupOption,
    TextField,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import {
    useAttachmentDeleteMutation,
    useAttachmentUpdateMutation,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';

type NewAttachmentProps = {
    measureId?: string | null | undefined;
    taskId?: string | null | undefined;
    missionId?: string | null | undefined;
    templateReportId?: string | null | undefined;
    onNewAttachment?: () => void;
    restrictUpload?: 'Image' | null | undefined;
};

export function NewAttachment(props: NewAttachmentProps): JSX.Element {
    const { currentTenantId, configuration } = useStateContext();
    const [dataUrl, setDataUrl] = useState<string>();
    const [selectedKey, setSelectedKey] = useState<string>('');
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [attachment, setAttachment] = useState({
        id: null,
        measureId: props.measureId || null,
        taskId: props.taskId || null,
        missionId: props.missionId || null,
        templateReportId: props.templateReportId || null,
        name: '',
        href: '',
        isFile: false,
        isUrl: false,
    });
    const [formErrorMessages] = useState({
        name: '',
        href: '',
    });
    const [uploadError, setUploadError] = useState('');

    const [
        attachmentUpdateMutation,
        { loading: isSaving, error: updateError },
    ] = useAttachmentUpdateMutation({
        variables: {
            tenantId: currentTenantId || '',
            input: attachment,
        },
    });

    useEffect(() => {
        if (props.restrictUpload === 'Image') {
            setSelectedKey('file');
            setAttachment((a) => ({
                ...a,
                isFile: true,
                isUrl: false,
            }));
        }
    }, [props.restrictUpload]);

    const [attachmentDeleteMutation] = useAttachmentDeleteMutation();

    const handleUserInput = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void => {
        const element = ev.target as HTMLInputElement;
        const name = element.name;
        if (attachment) {
            setAttachment({ ...attachment, [name]: newValue });
        }
    };

    function readFile(file: File): Promise<string | null | ArrayBuffer> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => resolve(reader.result),
                false
            );
            reader.readAsDataURL(file);
        });
    }

    const onFileChange = async (
        e: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const dataUrl = await readFile(file);

            if (dataUrl) {
                setAttachment({ ...attachment, name: file.name });
                setDataUrl(dataUrl as string);
            }

            if (uploadError) {
                setUploadError('');
            }
        }
    };

    const convertDataUrlToBlob = (dataUrl: string | undefined): Blob | null => {
        if (!dataUrl) return null;
        const arr = dataUrl.split(',');
        const mimeMatches = arr[0].match(/:(.*?);/);
        const mime = mimeMatches ? mimeMatches[1] : '';
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[Number(n)] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
    };

    interface UploadResult {
        success: string;
        message: string;
        attachment: {
            Id: string;
        };
    }

    const uploadAttachment = async (): Promise<void> => {
        setIsUploading(true);
        await attachmentUpdateMutation().then(
            (createAttachmentResult) => {
                const blob = convertDataUrlToBlob(dataUrl);

                const safeFilename = attachment.name
                    .replaceAll('‘', "'") // left quotation mark U+2019
                    .replaceAll('’', "'"); // right quotation mark U+2018

                fetch(`${configuration.storageFnUrl}/api/UploadAttachment`, {
                    method: 'POST',
                    headers: {
                        filename: safeFilename,
                        tenantId: currentTenantId || '',
                        attachmentId:
                            createAttachmentResult.data?.attachmentUpdate?.id ||
                            '',
                    },
                    body: blob,
                })
                    .then((response) => response.json())
                    .then(async (res: UploadResult) => {
                        if (res.success) {
                            setIsUploading(false);
                            setDataUrl(undefined);
                            reset();
                            if (props.onNewAttachment) props.onNewAttachment();
                        } else {
                            setUploadError(res.message);
                            setIsUploading(false);

                            if (
                                createAttachmentResult.data?.attachmentUpdate
                                    ?.id
                            ) {
                                await attachmentDeleteMutation({
                                    variables: {
                                        tenantId: currentTenantId || '',
                                        id: createAttachmentResult.data
                                            ?.attachmentUpdate?.id,
                                        restore: false,
                                    },
                                });
                            }
                        }
                    });
            },
            (error) => {
                setUploadError(error);
                setIsUploading(false);
            }
        );
    };

    const saveHref = async (): Promise<void> => {
        await attachmentUpdateMutation().then(
            () => {
                reset();
                if (props.onNewAttachment) props.onNewAttachment();
            },
            (error) => {
                setUploadError(error);
            }
        );
    };

    const reset = () => {
        if (props.restrictUpload === 'Image') {
            setSelectedKey('file');
            setAttachment((a) => ({
                ...a,
                name: '',
                href: '',
                isFile: true,
                isUrl: false,
            }));
        } else {
            setSelectedKey('');
            setAttachment((a) => ({
                ...a,
                name: '',
                href: '',
                isFile: false,
                isUrl: false,
            }));
        }
    };

    return (
        <Stack tokens={{ childrenGap: 8 }}>
            {!props.restrictUpload && (
                <ChoiceGroup
                    selectedKey={selectedKey}
                    options={[
                        {
                            key: 'file',
                            text: 'Attach a file',
                            iconProps: {
                                iconName: 'Attach',
                            },
                        },
                        {
                            key: 'href',
                            text: 'Add a link (URL)',
                            iconProps: {
                                iconName: 'AddOnlineMeeting',
                            },
                        },
                    ]}
                    onChange={(
                        _ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
                        option?: IChoiceGroupOption
                    ): void => {
                        if (option && option.key) {
                            setSelectedKey(option.key);
                            setAttachment({
                                ...attachment,
                                isFile: option.key === 'file',
                                isUrl: option.key === 'href',
                            });
                        }
                    }}
                    required
                />
            )}
            {updateError && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                >
                    <span>{updateError.message}</span>
                </MessageBar>
            )}

            {attachment && attachment.isFile && (
                <React.Fragment>
                    <input
                        type="file"
                        onChange={onFileChange}
                        accept={
                            props.restrictUpload === 'Image'
                                ? 'image/*'
                                : undefined
                        }
                    />
                    {dataUrl && (
                        <TextField
                            label="File Name"
                            required
                            multiline
                            autoAdjustHeight
                            name="name"
                            value={attachment.name || ''}
                            errorMessage={formErrorMessages.name}
                            onChange={handleUserInput}
                            readOnly
                        />
                    )}
                    <PrimaryButton
                        onClick={uploadAttachment}
                        disabled={isSaving || isUploading || !dataUrl}
                    >
                        Upload
                    </PrimaryButton>

                    {uploadError && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={true}
                        >
                            <span>{uploadError}</span>
                        </MessageBar>
                    )}
                </React.Fragment>
            )}
            {attachment && attachment.isUrl && (
                <React.Fragment>
                    <TextField
                        label="Title"
                        required
                        multiline
                        autoAdjustHeight
                        name="name"
                        defaultValue={attachment.name || ''}
                        errorMessage={formErrorMessages.name}
                        onChange={handleUserInput}
                    ></TextField>
                    <TextField
                        label="https://..."
                        required
                        multiline
                        autoAdjustHeight
                        name="href"
                        defaultValue={attachment.href || ''}
                        errorMessage={formErrorMessages.href}
                        onChange={handleUserInput}
                    ></TextField>
                    <PrimaryButton onClick={saveHref} disabled={isSaving}>
                        Save
                    </PrimaryButton>
                </React.Fragment>
            )}
        </Stack>
    );
}
