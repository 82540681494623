import { useStateContext } from '../services/contextProvider';

export function useCurrentTenant(): {
    id: string | null;
    code: string | null;
    description: string | null;
} | null {
    const { currentTenantId, userTenants } = useStateContext();

    return (
        (userTenants || []).find((ut) => ut?.tenant.id === currentTenantId)
            ?.tenant || null
    );
}
