import React from 'react';
import { useClipboard } from 'use-clipboard-copy';
import { ActionButton, Label, Stack, mergeStyleSets } from '@fluentui/react';

type Props = {
    selectedFunction: string | undefined;
};

const classNames = mergeStyleSets({
    helpText: {
        lineHeight: '22px',
    },
    copyExample: {
        display: 'block',
        height: '25px',
    },
});

function FunctionHelpText(props: Props): JSX.Element {
    const { selectedFunction } = props;

    const clipboard = useClipboard({
        copiedTimeout: 3000, // show copy message in milliseconds
    });

    return selectedFunction == 'general' ? (
        <Stack>
            <Label>Description:</Label>
            Formulas are a way to update measure values from values in other
            measures.
            <br />
            Some basic mathematical calculations may be performed at the same
            time.
            <br />
            Use the function help to see which functions are supported.
            <Label>Basic Examples: </Label>
            <ActionButton
                className={classNames.copyExample}
                text="Target: add('0000-0000-0000-0000', '0000-0000-0000-0000')"
                onClick={() =>
                    clipboard.copy(
                        "Target: add('0000-0000-0000-0000', '0000-0000-0000-0000')"
                    )
                }
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="Target: add(10, 10)"
                onClick={() => clipboard.copy('Target: add(10, 10)')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="Target: add(@M1, @M2); @M1='0000-0000-0000-0001'; @M2='0000-0000-0000-0002'"
                onClick={() =>
                    clipboard.copy(
                        "Target: add(@M1, @M2)\n@M1='0000-0000-0000-0001'\n@M2='0000-0000-0000-0002'"
                    )
                }
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
        </Stack>
    ) : selectedFunction == 'series' ? (
        <Stack>
            <Label>Description:</Label>
            Specify which series to update by looking at the following examples.
            <br />
            <b>Important:</b> Place each statement on a new line (or seperated
            by a semicolon)
            <Label>Basic Examples: </Label>
            <ActionButton
                className={classNames.copyExample}
                text="Target: ID | variable | number | function  - Updates Target"
                onClick={() => clipboard.copy('target: 0')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="Actual: ID | variable | number | function  - Updates Actual"
                onClick={() => clipboard.copy('actual: 0')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="Forecast: ID | variable | number | function  - Updates Forecast"
                onClick={() => clipboard.copy('forecast: 0')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            {/* <ActionButton
                className={classNames.copyExample}
                text="TargetYtd: ID | variable | number | function  - Updates TargetYtd"
                onClick={() => clipboard.copy('targetYtd: 0')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="ActualYtd: ID | variable | number | function  - Updates ActualYtd"
                onClick={() => clipboard.copy('actualYtd: 0')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="ForecastYtd: ID | variable | number | function  - Updates ForecastYtd"
                onClick={() => clipboard.copy('forecastYtd: 0')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            /> */}
        </Stack>
    ) : selectedFunction == 'add' ? (
        <div className={classNames.helpText}>
            <Label>Description:</Label>
            Add up all the arguments.
            <Label>Arguments:</Label>0 to many
            <Label>Examples: </Label>
            <ActionButton
                className={classNames.copyExample}
                text="Target: add(10,10)"
                onClick={() => clipboard.copy('Target: add(10,10)')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="Target: add('0000-0000-0000-0000', '0000-0000-0000-0000')"
                onClick={() =>
                    clipboard.copy(
                        "Target: add('0000-0000-0000-0000', '0000-0000-0000-0000')"
                    )
                }
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
        </div>
    ) : selectedFunction == 'subtract' ? (
        <div className={classNames.helpText}>
            <Label>Description:</Label> Take the first argument, then subtract
            each subsequent argument.
            <Label>Arguments:</Label> 0 to many
            <Label>Examples: </Label>
            <ActionButton
                className={classNames.copyExample}
                text="Target: subtract(10,10)"
                onClick={() => clipboard.copy('Target: subtract(10,10)')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="Target: subtract('0000-0000-0000-0000', '0000-0000-0000-0000')"
                onClick={() =>
                    clipboard.copy(
                        "Target: subtract('0000-0000-0000-0000', '0000-0000-0000-0000')"
                    )
                }
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
        </div>
    ) : selectedFunction == 'multiply' ? (
        <div className={classNames.helpText}>
            <Label>Description:</Label> Multiply all the arguments together.
            <Label>Arguments:</Label> 0 to many
            <Label>Examples: </Label>
            <ActionButton
                className={classNames.copyExample}
                text="Target: multiply(10,10)"
                onClick={() => clipboard.copy('Target: multiply(10,10)')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="Target: multiply('0000-0000-0000-0000', '0000-0000-0000-0000')"
                onClick={() =>
                    clipboard.copy(
                        "Target: multiply('0000-0000-0000-0000', '0000-0000-0000-0000')"
                    )
                }
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
        </div>
    ) : selectedFunction == 'divide' ? (
        <div className={classNames.helpText}>
            <Label>Description:</Label> Take the first argument, then divide by
            each subsequent argument.
            <Label>Arguments:</Label> 0 to many
            <Label>Examples: </Label>
            <ActionButton
                className={classNames.copyExample}
                text="Target: divide(10,10)"
                onClick={() => clipboard.copy('Target: divide(10,10)')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="Target: divide('0000-0000-0000-0000', '0000-0000-0000-0000')"
                onClick={() =>
                    clipboard.copy(
                        "Target: divide('0000-0000-0000-0000', '0000-0000-0000-0000')"
                    )
                }
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
        </div>
    ) : selectedFunction == 'mean' ||
      selectedFunction == 'average' ||
      selectedFunction == 'avg' ? (
        <div className={classNames.helpText}>
            <Label>Description:</Label> Take an average of all the arguments
            <Label>Arguments:</Label> 0 to many
            <ActionButton
                className={classNames.copyExample}
                text="Target: avg(10,10)"
                onClick={() => clipboard.copy('Target: avg(10,10)')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="Target: avg('0000-0000-0000-0000', '0000-0000-0000-0000')"
                onClick={() =>
                    clipboard.copy(
                        "Target: avg('0000-0000-0000-0000', '0000-0000-0000-0000')"
                    )
                }
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
        </div>
    ) : selectedFunction == 'median' ? (
        <div className={classNames.helpText}>
            <Label>Description:</Label> When given an array, returns the value
            in the middle
            <Label>Arguments:</Label> 0 to many
        </div>
    ) : selectedFunction == 'min' ? (
        <div className={classNames.helpText}>
            <Label>Description:</Label> Find the argument with the lowest value
            <Label>Arguments:</Label> 0 to many
            <ActionButton
                className={classNames.copyExample}
                text="Target: min(1, 5, 10)"
                onClick={() => clipboard.copy('Target: min(1, 5, 10)')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="Target: min('0000-0000-0000-0000', '0000-0000-0000-0000')"
                onClick={() =>
                    clipboard.copy(
                        "Target: min('0000-0000-0000-0000', '0000-0000-0000-0000')"
                    )
                }
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
        </div>
    ) : selectedFunction == 'max' ? (
        <div className={classNames.helpText}>
            <Label>Description:</Label> Find the argument with the highest value
            <Label>Arguments:</Label> 0 to many
            <ActionButton
                className={classNames.copyExample}
                text="Target: max(1, 5, 10)"
                onClick={() => clipboard.copy('Target: max(1, 5, 10)')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="Target: max('0000-0000-0000-0000', '0000-0000-0000-0000')"
                onClick={() =>
                    clipboard.copy(
                        "Target: max('0000-0000-0000-0000', '0000-0000-0000-0000')"
                    )
                }
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
        </div>
    ) : selectedFunction == 'random' ? (
        <div className={classNames.helpText}>
            <Label>Description:</Label> Generate a random value
            <Label>Arguments:</Label> 2 optional arguments. Defaults are 100 max
            and 0 min.
            <Label>Examples: </Label>
            <ActionButton
                className={classNames.copyExample}
                text="Target: random() - Generate a random number between 0 and 100"
                onClick={() => clipboard.copy('Target: random()')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="Target: random(1000) - Generate a random number between 0 and 1000"
                onClick={() => clipboard.copy('Target: random(1000)')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text="Target: random(1000, 10) - Generate a random number between 10 and 1000"
                onClick={() => clipboard.copy('Target: random(1000, 10)')}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
        </div>
    ) : selectedFunction == 'lookup' ? (
        <div className={classNames.helpText}>
            <Label>Description:</Label> Lookup the value from the argument
            <Label>Arguments:</Label> 1 required.
            {/* 2nd optional argument
            specifies what value should be read. Valid options are
            &apos;Target&apos;, &apos;Actual&apos;, &apos;Status&apos; */}
            <Label>Examples: </Label>
            {/*<ActionButton
                className={classNames.copyExample}
                text='Target: lookup("0000-0000-0000-0000")'
                onClick={() => {
                    const value = 'Target: lookup("0000-0000-0000-0000")';
                    clipboard.copy(value);
                }}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />*/}
            <ActionButton
                className={classNames.copyExample}
                text='Target: target("0000-0000-0000-0000")'
                onClick={() => {
                    const value = 'Target: target("0000-0000-0000-0000")';
                    clipboard.copy(value);
                }}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text='Actual: actual("0000-0000-0000-0000")'
                onClick={() => {
                    const value = 'Actual: actual("0000-0000-0000-0000")';
                    clipboard.copy(value);
                }}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text='Forecast: forecast("0000-0000-0000-0000")'
                onClick={() => {
                    const value = 'Forecast: forecast("0000-0000-0000-0000")';
                    clipboard.copy(value);
                }}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text='Target: targetYtd("0000-0000-0000-0000")'
                onClick={() => {
                    const value = 'Target: targetYtd("0000-0000-0000-0000")';
                    clipboard.copy(value);
                }}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text='Actual: actualYtd("0000-0000-0000-0000")'
                onClick={() => {
                    const value = 'Actual: Ytd("0000-0000-0000-0000")';
                    clipboard.copy(value);
                }}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text='Forecast: forecastYtd("0000-0000-0000-0000")'
                onClick={() => {
                    const value = 'Forecast: forecastYtd("0000-0000-0000-0000")';
                    clipboard.copy(value);
                }}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            {/* <ActionButton
                className={classNames.copyExample}
                text='lookup("0000-0000-0000-0000", "target")'
                onClick={() => {
                    const value = 'lookup("0000-0000-0000-0000", "target")';
                    clipboard.copy(value);
                }}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            />
            <ActionButton
                className={classNames.copyExample}
                text='lookup("0000-0000-0000-0000", "actual")'
                onClick={() => {
                    const value = 'lookup("0000-0000-0000-0000", "actual")';
                    clipboard.copy(value);
                }}
                iconProps={{
                    iconName: 'ClipboardSolid',
                }}
            /> */}
        </div>
    ) : (
        <></>
    );
}

export default FunctionHelpText;
