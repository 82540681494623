import React, { useState } from 'react';
import {
    Dialog,
    DialogType,
    DialogFooter,
    Text,
    PrimaryButton,
    DefaultButton,
    MessageBar,
    MessageBarType,
    MessageBarButton,
    Toggle,
} from '@fluentui/react';
import { useLanguage } from '../../../services/i18n';
import { useStateContext } from '../../../services/contextProvider';
import { MonthRow } from '../utils/measureUtils';
import {
    GetMeasureQuery,
    MeasureTypes,
    SeriesValue,
    refetchGetMissionMeasuresQuery,
    useUpdateMeasureMutation,
} from '../../../data/types';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import { useInputMappers } from '../../../hooks/useInputMappers';
import { useThemes } from '../../../hooks/useThemes';
import {
    AdvanceCard,
    AdvanceCardActionButton,
} from '../../../components/AdvanceCard';
import { MeasureValuesGrid } from './MeasureValuesGrid';
import { useViewport } from '../../../hooks/useViewport';
import { gql, useApolloClient } from '@apollo/client';
import { MeasureValuesClearDialog } from './MeasureValuesClearDialog';
import { useDistributeTarget } from '../../../hooks/useDistributeTarget';

export function MeasureValuesGridCard(props: {
    isSavingValues: boolean;
    isEditingValues: boolean;
    isLoading: boolean;
    measure: ExtractQueryArrayType<GetMeasureQuery, ['measure']> | null;
    data: MonthRow[];
    showTotals: boolean;
    toggleShowTotals: () => void;
    showCumulativeColumn: boolean;
    onDataChanged: (data: MonthRow[]) => void;
    onDistributeTargetClicked: () => void;
    onDiscardChangesClicked: () => void;
    onSaveChangesClicked: () => void;
    onAddRowClicked: () => void;
    onUpdateValuesClicked: () => void;
    isReadOnly: boolean;
    saveErrorMessage?: string | null;
    fyStartDate: string | undefined;
    fyEndDate: string | undefined;
}): JSX.Element {
    const { isLoading, isSavingValues, measure, data, isReadOnly } = props;

    const { currentTenantId, currentRoles } = useStateContext();
    const client = useApolloClient();

    const { width } = useViewport();

    const isMobile = width < 620;

    const { t } = useLanguage();
    const { currentTheme } = useThemes();

    const [hideDistDialog, setHideDistDialog] = useState(true);
    const [hideClearDialog, setHideClearDialog] = useState(true);

    // Bit of a hack to remount the grid after values have been cleared.
    const [resetKey, setResetKey] = useState(0);

    const [updateMeasure] = useUpdateMeasureMutation();

    const { isTargetDistributed } = useDistributeTarget(
        measure,
        props.fyStartDate
    );

    const isNumberMeasureType =
        measure?.measureType === MeasureTypes.Numeric ||
        measure?.measureType === MeasureTypes.Currency ||
        measure?.measureType === MeasureTypes.Percentage;

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin', 'Developer'].includes(r)
    );

    const { getMeasureInput } = useInputMappers();

    const toggleForecastDisplay = async () => {
        if (!measure) return;

        const input = {
            ...getMeasureInput(measure),
            showForecast: !measure.showForecast,
        };

        // Make it nice and snappy
        client.writeFragment({
            id: `MeasureQL:${measure.id}`,
            fragment: gql`
                fragment ShowForecastUpdate on MeasureQL {
                    showForecast
                }
            `,
            data: {
                showForecast: input.showForecast,
            },
        });

        await updateMeasure({
            variables: {
                input: input,
                tenantId: currentTenantId || '',
            },
            refetchQueries: [
                refetchGetMissionMeasuresQuery({
                    tenantId: currentTenantId || '',
                    missionId: input.missionId || '',
                }),
            ],
        });
    };

    let showTargetWarning = false;

    if (
        !isReadOnly &&
        !isLoading &&
        !isSavingValues &&
        isNumberMeasureType &&
        measure &&
        measure?.fullYearTarget !== null &&
        measure?.fullYearTarget !== undefined
    ) {
        showTargetWarning = !isTargetDistributed(data);
    }

    const handleSaveChangesClick = () => props.onSaveChangesClicked();
    const handleDiscardChangesClick = () => props.onDiscardChangesClicked();

    const handleEditYTDValuesClick = () => {
        props.toggleShowTotals();
    };

    const handleShowClearValuesClick = () => {
        setHideClearDialog(false);
    };

    const clearValue = (seriesValue: SeriesValue) => {
        return {
            ...seriesValue,
            stringValue: null,
            decimalValue: null,
            dateValue: null,
        };
    };

    const handleClearValuesClick = (valuesToClear: {
        target: boolean;
        actual: boolean;
        forecast: boolean;
    }) => {
        const updatedData: MonthRow[] = props.data.map((mr) => {
            return {
                ...mr,
                interval: valuesToClear.target
                    ? clearValue(mr.interval)
                    : mr.interval,
                ytd: valuesToClear.target ? null : mr.ytd,

                actual: valuesToClear.actual
                    ? clearValue(mr.actual)
                    : mr.actual,
                actualYtd: valuesToClear.actual ? null : mr.actualYtd,

                forecast: valuesToClear.forecast ? null : mr.forecast,
                forecastYtd: valuesToClear.forecast ? null : mr.forecastYtd,

                hasChanges: true,
            };
        });

        props.onDataChanged(updatedData);

        setResetKey((prevKey) => prevKey + 1);

        setHideClearDialog(true);
    };

    let actionButtons: AdvanceCardActionButton[] = [];

    if (isReadOnly) {
        actionButtons = [];
    } else if (!props.isEditingValues && !isSavingValues) {
        actionButtons = [
            {
                key: 'showForecast',
                iconProps: {
                    iconName: 'Timeline',
                },
                text: !measure?.showForecast
                    ? t('measures2.show-forecast')
                    : t('measures2.hide-forecast'),
                onClick: () => {
                    toggleForecastDisplay();
                },
                disabled: isLoading,
            },
        ];

        if (!isMobile) {
            actionButtons.push({
                key: 'edit',
                text: 'Update Values...',
                iconProps: { iconName: 'Edit' },
                onClick: () => props.onUpdateValuesClicked(),
                disabled: isLoading,
            });
        }
    } else {
        actionButtons = [];

        if (props.showCumulativeColumn) {
            actionButtons.push({
                key: 'showTotalsWhileEditing',
                onClick: handleEditYTDValuesClick,
                disabled: isLoading || isSavingValues,
                onRender: () => {
                    return (
                        <Toggle
                            key="EditYTDToggle"
                            onText="Edit YTD Values"
                            offText="Edit YTD Values"
                            defaultChecked={props.showTotals}
                            onChange={handleEditYTDValuesClick}
                            styles={{
                                root: {
                                    paddingTop: '6px',
                                    paddingRight: '6px',
                                },
                            }}
                        />
                    );
                },
            });
        }

        if (isAdmin) {
            actionButtons.push({
                key: 'clear',
                text: 'Clear Values...',
                iconProps: { iconName: 'DeleteColumns' },
                disabled: isLoading || isSavingValues,
                onClick: handleShowClearValuesClick,
            });
        }

        actionButtons.push(
            ...[
                {
                    key: 'save',
                    text: 'Save Changes',
                    iconProps: { iconName: 'Save' },
                    disabled: isLoading || isSavingValues,
                    onClick: handleSaveChangesClick,
                    styles: {
                        root: {
                            backgroundColor:
                                currentTheme.semanticColors.successBackground,
                            color: currentTheme.semanticColors.successIcon,
                        },
                        icon: {
                            color: currentTheme.semanticColors.successIcon,
                        },
                    },
                },
                {
                    key: 'discard',
                    text: 'Discard Updates',
                    iconProps: { iconName: 'Cancel' },
                    disabled: isLoading || isSavingValues,
                    onClick: handleDiscardChangesClick,
                    styles: {
                        root: {
                            backgroundColor:
                                currentTheme.semanticColors.errorBackground,
                            color: currentTheme.semanticColors.errorIcon,
                        },
                        icon: {
                            color: currentTheme.semanticColors.errorIcon,
                        },
                    },
                },
            ]
        );
    }

    return (
        <>
            <AdvanceCard title="Targets & Actuals" buttons={actionButtons}>
                {showTargetWarning && (
                    <AdvanceCard.Item>
                        <MessageBar
                            messageBarType={MessageBarType.warning}
                            isMultiline={false}
                            styles={{
                                root: {
                                    margin: 0,
                                },
                            }}
                            actions={
                                <div>
                                    {isNumberMeasureType && !isReadOnly && (
                                        <MessageBarButton
                                            onClick={() =>
                                                setHideDistDialog(false)
                                            }
                                            iconProps={{
                                                iconName: 'Timeline',
                                            }}
                                        >
                                            {t('measures2.distribute-target')}
                                        </MessageBarButton>
                                    )}
                                </div>
                            }
                        >
                            <Text>
                                The measure target does not match the values in
                                the table below.
                            </Text>
                        </MessageBar>
                    </AdvanceCard.Item>
                )}

                <AdvanceCard.Item fill={isMobile}>
                    <MeasureValuesGrid
                        key={resetKey}
                        measure={measure}
                        data={data}
                        isLoading={isLoading}
                        isEditing={props.isEditingValues}
                        isSaving={isSavingValues}
                        isReadOnly={isReadOnly}
                        onDataChanged={props.onDataChanged}
                        onAddRowClicked={props.onAddRowClicked}
                        showEditButton={isMobile}
                        showTotals={props.showTotals}
                        showCumulativeColumn={props.showCumulativeColumn}
                        fyStartDate={props.fyStartDate}
                        fyEndDate={props.fyEndDate}
                    />
                </AdvanceCard.Item>
            </AdvanceCard>
            <Dialog
                hidden={hideDistDialog}
                onDismiss={() => setHideDistDialog(true)}
                dialogContentProps={{
                    type: DialogType.close,
                    title: 'Distribute Content',
                    closeButtonAriaLabel: 'Close',
                    subText:
                        'Distribute Content? This will overwrite existing Targets.',
                }}
                modalProps={{
                    isBlocking: false,
                    styles: { main: { maxWidth: 450 } },
                }}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            props.onDistributeTargetClicked();
                            setHideDistDialog(true);
                        }}
                        text="Distribute"
                    />
                    <DefaultButton
                        onClick={() => setHideDistDialog(true)}
                        text="Cancel"
                    />
                </DialogFooter>
            </Dialog>
            <MeasureValuesClearDialog
                hidden={hideClearDialog}
                onDismiss={() => {
                    setHideClearDialog(true);
                }}
                onClearClick={handleClearValuesClick}
                allowClear={{
                    target: true,
                    actual: true,
                    forecast: measure?.showForecast || false,
                }}
            />
        </>
    );
}
