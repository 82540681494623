import isEqual from 'lodash/isEqual';
import { useState, useEffect, useCallback } from 'react';

const useForm = <ValuesType extends Record<string, unknown>>(
    defaultValues: ValuesType,
    callback: (values: ValuesType) => void | Promise<void>,
    validate: (values: ValuesType) => { [key: string]: string },
    resetAfterSubmit?: boolean | undefined
): {
    handleChange: (event: React.FormEvent | undefined) => void;
    updateValue: (
        name: keyof ValuesType,
        value:
            | string
            | number
            | string[]
            | Date
            | Record<string, unknown>
            | Record<string, unknown>[]
            | boolean
            | unknown[]
            | undefined
            | null
    ) => void;
    handleSubmit: (event?: React.FormEvent) => void;
    reset: () => void;
    values: ValuesType;
    errors: { [key: string]: string };
} => {
    const [values, setValues] = useState<ValuesType>(defaultValues);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (isSubmitting) {
            let hasErrors = false;
            Object.keys(errors).forEach((key: string) => {
                if (errors[`${key}`] && errors[`${key}`] !== '') {
                    hasErrors = true;
                }
            });

            if (!hasErrors) {
                callback(values);

                if (resetAfterSubmit) {
                    setValues(defaultValues);
                }
            }

            setIsSubmitting(false);
        }
    }, [
        errors,
        isSubmitting,
        callback,
        values,
        resetAfterSubmit,
        defaultValues,
    ]);

    const handleSubmit = (event?: React.FormEvent): void => {
        if (event) event.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    };

    const handleChange = (event: React.FormEvent | undefined): void => {
        event?.persist();

        let name: string;
        let value: string;

        if (event?.target as HTMLInputElement) {
            const element = event?.target as HTMLInputElement;
            name = element.name;
            value = element.value;
        } else if (event?.target as HTMLTextAreaElement) {
            const element = event?.target as HTMLTextAreaElement;
            name = element.name;
            value = element.value;
        }

        setValues((values) => ({
            ...values,
            [name]: value,
        }));
    };

    const updateValue = useCallback(
        (
            name: keyof ValuesType,
            value:
                | string
                | number
                | string[]
                | Date
                | Record<string, unknown>
                | Record<string, unknown>[]
                | boolean
                | unknown[]
                | undefined
                | null
        ): void => {
            setValues((values) => ({
                ...values,
                [name]: value,
            }));
        },
        []
    );

    const reset = (): void => {
        if (isSubmitting) {
            setIsSubmitting(false);
        }
        if (!isEqual(defaultValues, values)) {
            setValues(defaultValues);
        }
    };

    return {
        handleChange,
        updateValue,
        handleSubmit,
        reset,
        values,
        errors,
    };
};

export default useForm;
