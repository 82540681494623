import { useCallback } from 'react';
import { useStateContext } from '../../../services/contextProvider';
import {
    MissionFilterType,
    ContributorFilterType,
    RightsFilterType,
} from '../components/UserFilterBar';

type UserType = {
    id: string | null;
    userRoles: {
        name: string;
        tenantId: string | null;
    }[];
};

export function useGetUserTypes(
    missions: MissionFilterType[] | null = [],
    contributors: ContributorFilterType[] | null = [],
    rights: RightsFilterType[] | null = []
): {
    isUserContributor: (user: UserType) => boolean;
    isUserMissionOwner: (user: UserType) => boolean;
    isClientAdmin: (user: UserType) => boolean;
    isGlobalAdmin: (user: UserType) => boolean;
    hasAssignedRights: (user: UserType) => {
        read: boolean;
        write: boolean;
    };
} {
    const { currentTenantId } = useStateContext();

    const isUserContributor = useCallback(
        (user: UserType) => !!contributors?.some((c) => c.userId === user.id),
        [contributors]
    );

    const isUserMissionOwner = useCallback(
        (user: UserType) => !!missions?.some((c) => c.userId === user.id),
        [missions]
    );

    const isClientAdmin = useCallback(
        (user: UserType) =>
            user.userRoles.some(
                (r) =>
                    r.name === 'ClientAdmin' && r.tenantId === currentTenantId
            ),
        [currentTenantId]
    );

    const isGlobalAdmin = useCallback(
        (user: UserType) =>
            user.userRoles.some((r) => r.name === 'GlobalAdmin'),
        []
    );

    const hasAssignedRights = useCallback(
        (user: UserType) => ({
            read: !!rights?.some((r) => r.read && r.userId === user.id),
            write: !!rights?.some(
                (r) => (r.write || r.import) && r.userId === user.id
            ),
        }),
        [rights]
    );

    return {
        isUserContributor,
        isUserMissionOwner,
        isClientAdmin,
        isGlobalAdmin,
        hasAssignedRights,
    };
}
