import { Guid } from 'guid-typescript';
import { configurationService } from './configuration.service';

interface PhotoUploadResult {
    Success: boolean;
    Message: string;
    photo: {
        id: Guid;
        containerPath: string;
        filename: string;
    };
}

class PhotoService {
    private baseUrl: string | undefined;

    private cacheBusters: { [index: string]: string } = {};

    public async init() {
        const configuration =
            await configurationService.getConfigurationAsync();
        this.baseUrl = configuration.storageFnUrl;
    }

    public refreshImageUrl(userId: string | undefined | null): void {
        if (userId) {
            this.cacheBusters[`${userId}`] = Date.now().toString();
        }
    }

    public getImageUrl(userId: string | undefined | null): string | undefined {
        if (userId && this.baseUrl) {
            return `${this.baseUrl}/api/userphoto?userId=${userId}${
                this.cacheBusters[`${userId}`]
                    ? `&c=${this.cacheBusters[`${userId}`]}`
                    : ''
            }`;
        }
        return undefined;
    }

    public async uploadPhotoAsync(
        userId: string,
        blob: Blob
    ): Promise<PhotoUploadResult> {
        const uri = `${this.baseUrl}/api/UploadUserPhoto`;
        const fileName = `${userId}.jpg`;

        const buffer = await blob.arrayBuffer();

        const options: RequestInit = {
            method: 'POST',
            headers: {
                userId: userId,
                filename: fileName,
            },
            body: buffer,
        };

        const result = await fetch(uri, options);
        return result.json();
    }

    public async hasImageAsync(userId: string): Promise<boolean> {
        const uri = this.getImageUrl(userId);
        if (!uri) {
            return false;
        }
        try {
            const result = await fetch(uri);
            return result.status === 200;
        } catch {
            return false;
        }
    }

    public async hasPhotoAsync(userId: string): Promise<boolean> {
        try {
            const uri = `${this.baseUrl}/api/UserHasPhoto?userId=${userId}`;
            const result = await fetch(uri);
            return result.status == 200;
        } catch {
            return false;
        }
    }

    public async removePhotoAsync(userId: string): Promise<PhotoUploadResult> {
        const uri = `${this.baseUrl}/api/RemoveUserPhoto`;
        const fileName = `${userId}.jpg`;
        const options: RequestInit = {
            method: 'POST',
            headers: {
                userId: userId,
                filename: fileName,
            },
        };

        const result = await fetch(uri, options);
        return result.json();
    }

    private createImage = (url: string): Promise<HTMLImageElement> =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', () => resolve(image));
            image.addEventListener('error', (error) => reject(error));
            image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
            image.src = url;
        });

    private getRadianAngle(degreeValue: number): number {
        return (degreeValue * Math.PI) / 180;
    }

    /**
     * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
     * @param {File} image - Image File url
     * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
     * @param {number} rotation - optional rotation parameter
     */
    public async getCroppedImgAsync(
        imageSrc: string,
        imageType = 'image/jpeg',
        croppedAreaPixels: {
            x: number;
            y: number;
            width: number;
            height: number;
        },
        rotation = 0
    ): Promise<Blob | null> {
        const image = await this.createImage(imageSrc);

        const rotRad = this.getRadianAngle(rotation);
        const bBoxWidth =
            Math.abs(Math.cos(rotRad) * image.width) +
            Math.abs(Math.sin(rotRad) * image.height);
        const bBoxHeight =
            Math.abs(Math.sin(rotRad) * image.width) +
            Math.abs(Math.cos(rotRad) * image.height);

        const canvas = document.createElement('canvas');
        canvas.width = bBoxWidth;
        canvas.height = bBoxHeight;

        const ctx = canvas.getContext('2d');

        if (ctx) {
            ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
            ctx.rotate(rotRad);
            ctx.translate(-image.width / 2, -image.height / 2);
            ctx.drawImage(image, 0, 0);
        }

        const data = ctx?.getImageData(
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height
        );

        // set the canvas size to the final image size - this will clear existing context
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        if (data) {
            // paste the extracted image at the top left corner
            ctx?.putImageData(data, 0, 0);
        }

        // As a blob
        return new Promise((resolve) => {
            canvas.toBlob((file) => {
                resolve(file);
            }, imageType);
        });
    }
}

export const photoService = new PhotoService();
photoService.init();
