import React, { PropsWithChildren } from 'react';
import { Stack } from '@fluentui/react';
import ExpandCollapse from '../../../components/ExpandCollapse';
import { useDroppable } from '@dnd-kit/core';

export function GroupContainer(
    props: PropsWithChildren<{
        group: {
            id: string | null;
            name: string | null;
        };
        showGroupName: boolean;
        isExpanded: boolean;
        onExpandGroup: (groupId: string | null) => void;
        onCollapseGroup: (groupId: string | null) => void;
        containerClassName: string;
    }>
): JSX.Element {
    const { setNodeRef } = useDroppable({
        id: props.group.id || '',
    });

    const { group, isExpanded, onExpandGroup, onCollapseGroup, showGroupName } =
        props;

    const containerStyles: React.CSSProperties =
        isExpanded || !showGroupName ? {} : { display: 'none' };

    return (
        <div>
            {showGroupName && (
                <Stack horizontal tokens={{ padding: 8 }}>
                    <Stack.Item>
                        <ExpandCollapse
                            isExpanded={isExpanded}
                            onExpand={() => onExpandGroup(group.id)}
                            onCollapse={() => onCollapseGroup(group.id)}
                        />
                    </Stack.Item>
                    <Stack.Item grow align="center">
                        {group.name}
                    </Stack.Item>
                </Stack>
            )}
            <div style={containerStyles}>
                <div ref={setNodeRef} className={props.containerClassName}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}
