import { IButtonProps } from '@fluentui/react';

import { useThemes } from './useThemes';

export const useActionButtons = (params: {
    onSaveButtonClick?: () => void | Promise<void>;
    onDiscardButtonClick?: () => void | Promise<void>;
    onDeleteButtonClick?: () => void | Promise<void> | null | undefined;
    disabled?: boolean;
    isSaving?: boolean;
    isSaved?: boolean;
    isValid?: boolean;
    deleteIconName?: string | null;
    deleteText?: string | null;
}): IButtonProps[] => {
    const buttons: IButtonProps[] = [];

    const { currentTheme } = useThemes();

    if (params.onDeleteButtonClick) {
        buttons.push({
            title: params.deleteText || 'Delete',
            disabled: params.isSaving || params.disabled,
            onClick: params.onDeleteButtonClick,
            style: {
                marginRight: 8,
                color: currentTheme.semanticColors.errorIcon,
            },
            iconProps: {
                iconName: params.deleteIconName || 'Delete',
            },
        });
    }

    if (params.onSaveButtonClick) {
        buttons.push({
            title: 'Save',
            disabled:
                params.isSaving ||
                !params.isValid ||
                params.disabled ||
                params.isSaved,
            primary: true,
            onClick: params.onSaveButtonClick,
            style: {
                marginRight: '8px',
                backgroundColor: currentTheme.semanticColors.successBackground,
                color: currentTheme.semanticColors.successIcon,
            },
            iconProps: {
                iconName: 'CheckMark',
            },
        });

        if (params.onDiscardButtonClick) {
            buttons.push({
                title: 'Discard Changes',
                disabled: params.isSaving || params.disabled,
                onClick: params.onDiscardButtonClick,
                style: {
                    marginRight: '8px',
                    backgroundColor:
                        currentTheme.semanticColors.errorBackground,
                    color: currentTheme.semanticColors.errorIcon,
                },
                iconProps: {
                    iconName: 'Cancel',
                },
            });
        }
    } else if (params.onDiscardButtonClick) {
        buttons.push({
            title: 'Close',
            disabled: params.isSaving || params.disabled,
            onClick: params.onDiscardButtonClick,
            style: {
                marginRight: '8px',
            },
            iconProps: {
                iconName: 'Cancel',
            },
        });
    }

    return buttons;
};
