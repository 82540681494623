import React from 'react';
import {
    DetailsListLayoutMode,
    IconButton,
    mergeStyleSets,
    Modal,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    Text,
} from '@fluentui/react';
import { useGetCurrentUserLoginHistoryQuery } from '../../data/types';
import dayjs from 'dayjs';
import { UAParser } from 'ua-parser-js';
import useGeoLocation from '../../hooks/useGeoLocation';

export function LoginHistoryModal(props: {
    isOpen: boolean;
    onDismiss: () => void;
}): JSX.Element {
    const { data, loading } = useGetCurrentUserLoginHistoryQuery({
        skip: !props.isOpen,
        fetchPolicy: 'no-cache',
    });

    const renderAccessType = (item: { userAgent: string }) => {
        const { browser, os, device } = UAParser(item.userAgent);

        let accessType = browser?.name || '';

        if (os) {
            accessType = `${accessType} on ${os.name} ${os.version}`.trim();
        }

        if (device?.type) {
            accessType = `${accessType} (${device?.type})`;
        }

        return accessType;
    };

    const MemoLocation = React.memo(IpAddressLocation);

    const renderLocation = (item: { ipAddress: string }) => {
        return item.ipAddress ? (
            <MemoLocation ipAddress={item.ipAddress} />
        ) : null;
    };

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            {
                flex: '1 1 auto',
                display: 'flex',
                alignItems: 'center',
            },
        ],
        body: {
            flex: '4 4 auto',
            maxHeight: 450,
            minWidth: 680,
        },
    });

    return (
        <Modal
            isOpen={props.isOpen}
            containerClassName={contentStyles.container}
            onDismiss={props.onDismiss}
        >
            <Stack tokens={{ padding: 32, childrenGap: 16 }}>
                <Stack horizontal>
                    <Stack.Item grow>
                        <h2>Recent Activity</h2>
                    </Stack.Item>
                    <Stack.Item align="start">
                        <IconButton
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close popup modal"
                            onClick={props.onDismiss}
                        />
                    </Stack.Item>
                </Stack>
                {props.isOpen && (
                    <Text>
                        <CurrentIpAddress />
                    </Text>
                )}
                <div className={contentStyles.body}>
                    <ShimmeredDetailsList
                        items={data?.current_user?.loginHistory || []}
                        enableShimmer={loading}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={(): boolean => false}
                        columns={[
                            {
                                key: 'userAgent',
                                name: 'Access Type',
                                fieldName: 'userAgent',
                                minWidth: 180,
                                onRender: renderAccessType,
                            },
                            {
                                key: 'location',
                                name: 'Country',
                                minWidth: 80,
                                onRender: renderLocation,
                            },
                            {
                                key: 'ipAddress',
                                name: 'IP Address',
                                fieldName: 'ipAddress',
                                minWidth: 120,
                            },

                            {
                                key: 'dateTime',
                                name: 'Date/Time',
                                fieldName: 'name',
                                minWidth: 200,
                                onRender: function renderTime(item: {
                                    dateTime: string | null;
                                }) {
                                    return item.dateTime
                                        ? dayjs
                                              .utc(item.dateTime)
                                              .tz(dayjs.tz.guess())
                                              .format('DD MMM YYYY - HH:mm (z)')
                                        : '';
                                },
                            },
                        ]}
                    />
                </div>
            </Stack>
        </Modal>
    );
}

function CurrentIpAddress(): JSX.Element | null {
    const currentIpDetails = useGeoLocation();
    return currentIpDetails.ipAddress ? (
        <span>
            This device is using IP address {currentIpDetails.ipAddress}.
        </span>
    ) : null;
}

function IpAddressLocation(props: { ipAddress: string }): JSX.Element {
    const geoLocation = useGeoLocation({
        ipAddress: props.ipAddress,
    });
    return <span>{geoLocation.country}</span>;
}
