import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Guid } from 'guid-typescript';
import max from 'lodash/max';
import orderBy from 'lodash/orderBy';
import {
    Text,
    TextField,
    DatePicker,
    Checkbox,
    IconButton,
    TooltipHost,
    Dialog,
    DialogType,
    DefaultButton,
    PrimaryButton,
    ActionButton,
    DialogFooter,
    mergeStyleSets,
    Link,
    Icon,
} from '@fluentui/react';
import {
    useGetTaskLazyQuery,
    useUpdateTaskSequenceMutation,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { useThemes } from '../hooks/useThemes';
import { TaskStatusColours } from '../Colours';

export type ChecklistTask = {
    id: string | null;
    name: string | null;
    due: string | null;
    done: string | null;
    sequence: number;
    isNew?: boolean;
    isDeleted?: boolean;
};

type TaskChecklistProps = {
    impliedTask: {
        parentTaskId: string | null;
    };
    items: ChecklistTask[];
    readonly: boolean;
    onChange: (checklistTasks: ChecklistTask[]) => void;
};

type TaskChecklistItemProps = {
    item: ChecklistTask;
    readonly: boolean;
    onChange: (item: ChecklistTask) => void;
    onDeleteClick: (taskId: string) => void;
    onPromoteClick: (taskId: string) => void;
    canPromote: boolean;
};

export function TaskChecklist(props: TaskChecklistProps): JSX.Element {
    const [promoteTaskId, setPromoteTaskId] = useState<string | null>();

    const [sorting, setSorting] = useState<{
        field: string;
        direction: 'asc' | 'desc';
    }>({
        field: 'sequence',
        direction: 'asc',
    });

    const handleItemChanged = (item: ChecklistTask) => {
        const others = props.items.filter((ct) => ct.id !== item.id);
        props.onChange([...others, item]);
    };

    const handleAddClicked = () => {
        const lastSequence = max(props.items.map((ct) => ct.sequence));
        const newSequence = lastSequence !== undefined ? lastSequence + 1 : 0;
        props.onChange([
            ...props.items,
            {
                id: Guid.create().toString(),
                name: null,
                due: null,
                done: null,
                sequence: newSequence,
                isNew: true,
            },
        ]);
    };

    const handleDeleteClick = (deletedTaskId: string) => {
        const item = props.items.find((ct) => ct.id === deletedTaskId);
        const others = props.items.filter((ct) => ct.id !== deletedTaskId);

        if (item) {
            props.onChange([...others, { ...item, isDeleted: true }]);
        }
    };

    const handlePromoteClick = (taskId: string) => {
        setPromoteTaskId(taskId);
    };

    const handleItemPromoted = () => {
        if (promoteTaskId) {
            props.onChange(props.items.filter((ct) => ct.id !== promoteTaskId));
            setPromoteTaskId(null);
        }
    };

    const canPromote = !!props.impliedTask.parentTaskId;

    const classNames = mergeStyleSets({
        checklistFlexTable: {
            display: 'grid',
            gap: 8,
            gridTemplateColumns: 'repeat(3, 1fr)',
            alignItems: 'center',
        },
        checklistHeader: {
            gridColumnStart: 1,
            gridColumnEnd: 4,
            display: 'contents',
        },
        sortTitle: {
            display: 'flex',
            gap: 2,
            textDecoration: 'none !important',
        },
    });

    const sortedItems = orderBy(
        props.items.slice().filter((t) => !t.isDeleted),
        ['isNew', sorting.field, 'sequence'],
        ['desc', sorting.direction, 'asc']
    );

    const toggleSorting = (field: string) => {
        if (field === sorting.field) {
            setSorting({
                field: field,
                direction: sorting.direction === 'asc' ? 'desc' : 'asc',
            });
        } else {
            setSorting({
                field: field,
                direction: 'asc',
            });
        }
    };

    return (
        <React.Fragment>
            <TaskChecklistPromoteDialog
                taskId={promoteTaskId}
                targetSpecifiedTaskId={props.impliedTask.parentTaskId}
                hidden={!promoteTaskId}
                onDismiss={() => setPromoteTaskId(null)}
                onTaskPromoted={handleItemPromoted}
            />

            <div className={classNames.checklistFlexTable}>
                <div className={classNames.checklistHeader}>
                    <div>
                        <Link
                            onClick={() => toggleSorting('name')}
                            className={classNames.sortTitle}
                        >
                            <Text style={{ fontWeight: 600 }}>
                                Checklist Item
                            </Text>
                            {sorting.field === 'name' && (
                                <Icon
                                    iconName={
                                        sorting.direction === 'asc'
                                            ? 'SortUp'
                                            : 'SortDown'
                                    }
                                />
                            )}
                        </Link>
                    </div>
                    <div>
                        <Link
                            onClick={() => toggleSorting('due')}
                            className={classNames.sortTitle}
                        >
                            <Text style={{ fontWeight: 600 }}>Due</Text>
                            {sorting.field === 'due' && (
                                <Icon
                                    iconName={
                                        sorting.direction === 'asc'
                                            ? 'SortUp'
                                            : 'SortDown'
                                    }
                                />
                            )}
                        </Link>
                    </div>
                    <div></div>
                </div>
                {sortedItems.map((item) => {
                    return (
                        <TaskChecklistItem
                            readonly={props.readonly}
                            key={item.id}
                            item={item}
                            onChange={handleItemChanged}
                            onDeleteClick={handleDeleteClick}
                            onPromoteClick={handlePromoteClick}
                            canPromote={canPromote}
                        />
                    );
                })}

                {!props.readonly && (
                    <ActionButton
                        onClick={handleAddClicked}
                        text="New Item"
                        iconProps={{ iconName: 'Add' }}
                    />
                )}
            </div>
        </React.Fragment>
    );
}

function TaskChecklistItem(props: TaskChecklistItemProps): JSX.Element {
    const { currentTheme } = useThemes();

    const handleDeleteClick = () =>
        props.item.id ? props.onDeleteClick(props.item.id) : undefined;

    const handlePromoteClick = () =>
        props.item.id ? props.onPromoteClick(props.item.id) : undefined;

    const handleNameChange = (_ev: React.FormEvent, newValue?: string) => {
        props.onChange({
            ...props.item,
            name: newValue || null,
        });
    };

    const handleDoneCheck = (_ev?: React.FormEvent, checked?: boolean) => {
        props.onChange({
            ...props.item,
            done: checked ? dayjs.utc().format('YYYY-MM-DD') : null,
        });
    };

    const dueDateValue = props.item?.due
        ? dayjs(props.item?.due).toDate()
        : undefined;

    const handleDateSelect = (date: Date | null | undefined) => {
        props.onChange({
            ...props.item,
            due: date ? dayjs(date).format('YYYY-MM-DD') : null,
        });
    };

    const classNames = mergeStyleSets({
        actionsColumn: {
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
    });

    let statusDay = dayjs().utc();
    statusDay = statusDay
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0);

    const due = dayjs.utc(props.item.due || undefined);

    let statusColour = currentTheme.palette.whiteTranslucent40;

    if (props.item.isNew) {
        statusColour = currentTheme.palette.yellow;
    } else if (props.item.done) {
        statusColour = TaskStatusColours.completedOnTime;
    } else if (!props.item.due) {
        statusColour = TaskStatusColours.onTarget;
    } else if (due.isSameOrBefore(statusDay, 'day')) {
        statusColour = TaskStatusColours.offTarget;
    } else if (due.isAfter(statusDay, 'day')) {
        statusColour = TaskStatusColours.onTarget;
    }

    return (
        <React.Fragment>
            <div
                style={{
                    borderLeftStyle: 'solid',
                    borderLeftWidth: 4,
                    paddingLeft: 4,
                    borderLeftColor: statusColour,
                }}
            >
                <TextField
                    readOnly={props.readonly}
                    disabled={props.readonly}
                    placeholder="Enter Checklist Item"
                    value={props.item?.name || ''}
                    onChange={handleNameChange}
                />
            </div>
            <div>
                <DatePicker
                    disabled={props.readonly}
                    allowTextInput
                    placeholder="Due"
                    value={dueDateValue}
                    onSelectDate={handleDateSelect}
                    styles={{
                        statusMessage: {
                            display: 'none',
                        },
                    }}
                />
            </div>
            <div className={classNames.actionsColumn}>
                <div>
                    <Checkbox
                        disabled={props.readonly}
                        label="Done?"
                        boxSide="end"
                        checked={!!props.item?.done}
                        onChange={handleDoneCheck}
                    />
                </div>

                {!props.readonly && props.canPromote && (
                    <div>
                        <TooltipHost content="Promote to implied task">
                            <IconButton
                                iconProps={{ iconName: 'Movers' }}
                                onClick={handlePromoteClick}
                                disabled={props.item.isNew}
                            />
                        </TooltipHost>
                    </div>
                )}
                {!props.readonly && props.item.id && (
                    <div>
                        <TooltipHost content="Delete">
                            <IconButton
                                iconProps={{ iconName: 'Delete' }}
                                onClick={handleDeleteClick}
                            />
                        </TooltipHost>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export type TaskChecklistPromoteDialogProps = {
    taskId: string | null | undefined;
    targetSpecifiedTaskId: string | null | undefined;
    hidden: boolean;
    onDismiss: () => void;
    onTaskPromoted: () => void;
};

export function TaskChecklistPromoteDialog(
    props: TaskChecklistPromoteDialogProps
): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [getTask, { loading: isLoadingTarget }] = useGetTaskLazyQuery();

    const [updateTaskSequence, { loading: isSaving }] =
        useUpdateTaskSequenceMutation();

    const isPromoting = isLoadingTarget || isSaving;

    const onConfirmPromoteClick = async () => {
        if (!props.taskId || !props.targetSpecifiedTaskId || !currentTenantId) {
            throw 'Target specified task not set.';
        }

        const targetTaskResult = await getTask({
            variables: {
                tenantId: currentTenantId,
                id: props.targetSpecifiedTaskId,
            },
        });

        const lastSequence = max(
            targetTaskResult.data?.task?.subTasks
                .filter((t) => !t.isDuplicate)
                .map((t) => t.sequence)
        );

        const newSequence = lastSequence !== undefined ? lastSequence + 1 : 0;

        await updateTaskSequence({
            variables: {
                taskId: props.taskId,
                targetParentTaskId: props.targetSpecifiedTaskId,
                tenantId: currentTenantId,
                sequence: newSequence,
            },
            optimisticResponse: {
                __typename: 'Mutations',
                taskSequenceUpdate: {
                    id: props.taskId,
                    missionId: targetTaskResult.data?.task?.missionId || null,
                    sequence: newSequence,
                    version: null,
                    utcUpdated: null,
                    parentTaskId: props.targetSpecifiedTaskId,
                    parentTask: null,
                    __typename: 'TaskQL',
                },
            },
        });

        props.onTaskPromoted();
    };

    return (
        <Dialog
            hidden={props.hidden}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Promote',
                showCloseButton: true,
                subText:
                    'This will promote this checklist item to an implied task inside the specified task.',
            }}
            modalProps={{
                isBlocking: true,
            }}
            onDismiss={props.onDismiss}
        >
            <DialogFooter>
                <PrimaryButton
                    text="Promote"
                    onClick={onConfirmPromoteClick}
                    disabled={isPromoting}
                />
                <DefaultButton text="Cancel" onClick={props.onDismiss} />
            </DialogFooter>
        </Dialog>
    );
}
