import React from 'react';
import {
    Text,
    Stack,
    Persona,
    PersonaSize,
    Shimmer,
    ShimmerElementType,
    ActivityItem,
    TooltipHost,
    DirectionalHint,
    IPersonaSharedProps,
} from '@fluentui/react';
import { GetMeasureQuery } from '../data/types';
import { photoService } from '../services/photo.service';
import compact from 'lodash/compact';
import { useThemes } from '../hooks/useThemes';
import { ExtractQueryType } from '../data/extendedTypes';

export function MeasureLinkInformation(props: {
    isLoading: boolean;
    measure: ExtractQueryType<GetMeasureQuery, ['measure']> | undefined | null;
    showOwner?: boolean;
}): JSX.Element {
    const { measure } = props;
    const { currentTheme } = useThemes();

    const linkedFromMeasure = measure?.linkedFromMeasure;
    const mission = measure?.mission;
    const linkedFromMission = linkedFromMeasure?.mission;

    const uniqueMissionLinks = compact(
        measure?.linkedMeasures?.filter((m) => m.isLinked).map((m) => m.mission)
    ).filter(
        (value, index, self) =>
            self.findIndex((s) => value.id === s.id) === index
    );

    const getInitials = (displayName: string) => {
        const match = displayName?.match(/\b\w/g) || [];
        const initials = (
            (match.shift() || '') + (match.pop() || '')
        ).toUpperCase();
        return initials;
    };

    const linkedMeasurePersonas: IPersonaSharedProps[] =
        uniqueMissionLinks?.map((m) => {
            const displayName = m.owner || m.title || '';
            return {
                imageUrl: photoService.getImageUrl(m.userId),
                text: displayName,
                imageInitials: getInitials(displayName),
            };
        }) || [];

    const linkedMeasureDescription = (
        <div>
            <Text variant="xSmall" style={{ fontWeight: 600 }} block>
                Linked to:
            </Text>
            {linkedMeasurePersonas.length > 0
                ? [
                      <span key="primary">
                          {linkedMeasurePersonas[0].text}
                      </span>,
                      linkedMeasurePersonas.length > 1 ? (
                          <span key="other">
                              {` and ${linkedMeasurePersonas.length - 1} other${
                                  linkedMeasurePersonas.length > 2 ? 's' : ''
                              }`}
                          </span>
                      ) : null,
                  ]
                : undefined}
        </div>
    );

    const linkedToTooltipContent = (
        <div>
            {uniqueMissionLinks?.map((m) => (
                <Text variant="small" key={m.id} block>
                    {m.owner} - {m.title}
                </Text>
            ))}
        </div>
    );

    return (
        <Stack horizontal>
            {props.showOwner && (
                <Stack.Item grow>
                    <Shimmer
                        isDataLoaded={!!mission && !props.isLoading}
                        width={200}
                        shimmerElements={[
                            {
                                type: ShimmerElementType.circle,
                                height: 34,
                            },
                            { type: ShimmerElementType.gap, width: 4 },
                            {
                                type: ShimmerElementType.line,
                                height: 34,
                            },
                        ]}
                    >
                        <Persona
                            size={PersonaSize.size32}
                            imageUrl={photoService.getImageUrl(mission?.userId)}
                            text={mission?.owner || ''}
                            secondaryText={mission?.title || ''}
                            showSecondaryText
                        />
                    </Shimmer>
                </Stack.Item>
            )}
            {linkedMeasurePersonas.length > 0 && !measure?.isLinked && (
                <Stack.Item>
                    <TooltipHost
                        content={linkedToTooltipContent}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                    >
                        <ActivityItem
                            styles={{
                                // workaround for ActivityItem not using the theme
                                root: {
                                    color: currentTheme.palette
                                        .neutralSecondary,
                                },
                                commentText: {
                                    color: currentTheme.palette.neutralPrimary,
                                },
                                timeStamp: {
                                    color: currentTheme.palette
                                        .neutralSecondary,
                                },
                            }}
                            activityDescription={linkedMeasureDescription}
                            activityPersonas={linkedMeasurePersonas}
                        />
                    </TooltipHost>
                </Stack.Item>
            )}

            {measure?.isLinked && (
                <Stack.Item>
                    <ActivityItem
                        styles={{
                            // workaround for ActivityItem not using the theme
                            root: {
                                color: currentTheme.palette.neutralSecondary,
                            },
                            commentText: {
                                color: currentTheme.palette.neutralPrimary,
                            },
                            timeStamp: {
                                color: currentTheme.palette.neutralSecondary,
                            },
                        }}
                        activityDescription={
                            <div>
                                <Text
                                    variant="xSmall"
                                    style={{ fontWeight: 600 }}
                                    block
                                >
                                    Linked from:
                                </Text>
                                {linkedFromMission?.owner ||
                                    linkedFromMission?.title}
                            </div>
                        }
                        activityPersonas={[
                            {
                                imageUrl: photoService.getImageUrl(
                                    linkedFromMission?.userId
                                ),
                                imageInitials: getInitials(
                                    linkedFromMission?.owner ||
                                        linkedFromMission?.title ||
                                        ''
                                ),
                                text:
                                    linkedFromMission?.owner ||
                                    linkedFromMission?.title ||
                                    '',
                            },
                        ]}
                    />
                </Stack.Item>
            )}
        </Stack>
    );
}
