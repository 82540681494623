import React, { useEffect } from 'react';
import {
    ComboBox,
    ConstrainMode,
    DetailsListLayoutMode,
    IColumn,
    IComboBox,
    IComboBoxOption,
    Selection,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    Label,
    Spinner,
    SpinnerSize,
    CommandButton,
    DatePicker,
    IDetailsColumnFieldProps,
    mergeStyleSets,
    IRenderFunction,
    IconButton,
    IDetailsRowProps,
} from '@fluentui/react';
import { useSorter } from '../hooks/useSorter';

import dayjs from 'dayjs';
import { DefaultProps } from '../DefaultProps';
import { useColumnPreferences } from '../hooks/useColumnPreferences';

export type FlatListFilterType = {
    forDateTime: { isOn: boolean; selectedDate: string | null };
};

export function FlatList<
    FlatListDataType extends { id: string | null; displaySequence: number },
>(props: {
    listTypeName: string;
    items: FlatListDataType[];
    loading?: boolean;
    availableColumns: ({
        sortFieldName?: keyof FlatListDataType;
        align?: 'left' | 'right' | 'center';
        fieldName: keyof FlatListDataType;
        name: string;
        isString: boolean;
    } & Partial<IColumn>)[];
    defaultColumns?: (keyof FlatListDataType)[];
    filters?: FlatListFilterType;
    allowExport?: boolean;
    onExportClick?: (
        columns: IColumn[],
        sortedItems: FlatListDataType[]
    ) => void | Promise<void>;
    isExporting?: boolean;
    setForDateTime?: (forDateTime: string | null) => void;
    shimmerLines?: number | undefined;
    onRenderItemColumn?: (
        item?: FlatListDataType,
        index?: number,
        column?: IColumn
    ) => React.ReactNode;
    onRenderRow?: IRenderFunction<IDetailsRowProps>;
    onRefreshClick?: () => void | Promise<void>;
    isRefreshing?: boolean;
    onColumnsChanged?: (columns: (keyof FlatListDataType)[]) => void;
    onRenderAdditionalFilters?: () => JSX.Element | JSX.Element[];
}): JSX.Element {
    const { onColumnsChanged } = props;

    const selection = new Selection();
    selection.setItems([]);

    const listColumnsStorageKey = `ADVANCE_LISTCOLUMNS_${props.listTypeName.toUpperCase()}`;

    const { selectedColumnNames, setSelectedColumnNames } =
        useColumnPreferences<FlatListDataType>(
            listColumnsStorageKey,
            props.defaultColumns
        );

    const columnOptions: IComboBoxOption[] = props.availableColumns.map((c) => {
        return {
            key: c.fieldName,
            text: c.name,
        };
    });

    useEffect(() => {
        if (onColumnsChanged) {
            onColumnsChanged(selectedColumnNames);
        }
    }, [selectedColumnNames, onColumnsChanged]);

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(props.items, 'displaySequence', false, false);

    const handleColumnChange = (
        _event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption
    ) => {
        const selected = option?.selected;

        setSelectedColumnNames((prev) =>
            selected
                ? [...prev, option.key as keyof FlatListDataType]
                : prev.filter((k) => k !== option?.key)
        );
    };

    const handleSelectDate = (date: Date | null | undefined): void => {
        if (props.setForDateTime) {
            props.setForDateTime(dayjs(date).toISOString());
        }
    };

    const onColumnClick = (_ev: React.MouseEvent, column: IColumn) => {
        const c = props.availableColumns.find(
            (c) => c.fieldName === column.fieldName
        );
        setSortColumnName(
            (c?.sortFieldName as string) || c?.fieldName || '',
            c?.isString || false
        );
    };

    const columns: IColumn[] = props.availableColumns
        .filter((c) => selectedColumnNames.includes(c.fieldName))
        .map((c) => {
            const sortField = c.sortFieldName || c.fieldName;
            return {
                key: c.fieldName,
                isMultiline: c.isString,
                minWidth: 120,
                isResizable: true,
                isSorted: sortColumn === sortField,
                isSortedDescending: sortColumn === sortField && sortIsDesc,
                onColumnClick: onColumnClick,
                styles:
                    c.align === 'center'
                        ? {
                              cellTitle: { justifyContent: 'center' },
                          }
                        : c.align === 'right'
                          ? {
                                cellTitle: { justifyContent: 'flex-end' },
                            }
                          : undefined,
                ...c,
            };
        });

    const classNames = mergeStyleSets({
        alignRight: {
            textAlign: 'right',
        },
        alignCenter: {
            textAlign: 'center',
        },
    });

    const handleRenderField = (
        p?: IDetailsColumnFieldProps,
        defaultRender?: IRenderFunction<IDetailsColumnFieldProps>
    ) => {
        const newProps = Object.assign({}, p);

        const column = p?.column as { align?: 'left' | 'right' | 'center' };

        if (column.align === 'right') {
            newProps.className = classNames.alignRight;
        } else if (column.align === 'center') {
            newProps.className = classNames.alignCenter;
        }

        return defaultRender ? defaultRender(newProps) : null;
    };

    return (
        <Stack>
            <Stack.Item align="end">
                <Stack
                    horizontal
                    horizontalAlign="end"
                    verticalAlign="center"
                    verticalFill
                    tokens={{ childrenGap: 8, padding: 8 }}
                >
                    {props.onRenderAdditionalFilters
                        ? props.onRenderAdditionalFilters()
                        : null}

                    <Stack.Item align="center" grow>
                        <Label>Columns:</Label>
                    </Stack.Item>
                    <ComboBox
                        multiSelect
                        options={columnOptions}
                        selectedKey={selectedColumnNames as string[]}
                        placeholder="Choose columns..."
                        onChange={handleColumnChange}
                    />
                    {props.filters?.forDateTime.isOn && (
                        <>
                            <Stack.Item align="center" grow>
                                <Label>Time:</Label>
                            </Stack.Item>

                            <DatePicker
                                {...DefaultProps.DatePickerProps}
                                value={
                                    props.filters?.forDateTime.selectedDate
                                        ? dayjs(
                                              props.filters.forDateTime
                                                  .selectedDate
                                          ).toDate()
                                        : undefined
                                }
                                onSelectDate={handleSelectDate}
                                disabled={props.isExporting}
                                placeholder="Select a date..."
                                ariaLabel="Select a date"
                            />
                        </>
                    )}
                    {props.allowExport && (
                        <CommandButton
                            key={'Export'}
                            text={props.isExporting ? 'Exporting' : 'Export'}
                            iconProps={{ iconName: 'ExcelDocument' }}
                            disabled={props.isExporting}
                            onRenderIcon={(_props, defaultRenderer) => {
                                if (props.isExporting) {
                                    return <Spinner size={SpinnerSize.small} />;
                                }

                                return defaultRenderer
                                    ? defaultRenderer()
                                    : null;
                            }}
                            onClick={() => {
                                if (props.onExportClick) {
                                    props.onExportClick(columns, sortedItems);
                                }
                            }}
                        />
                    )}

                    {!!props.onRefreshClick && (
                        <IconButton
                            iconProps={{ iconName: 'Refresh' }}
                            disabled={props.loading || props.isRefreshing}
                            onClick={props.onRefreshClick}
                        />
                    )}
                </Stack>
            </Stack.Item>
            <Stack.Item
                styles={{
                    root: {
                        overflowX: 'auto',
                    },
                }}
            >
                <ShimmeredDetailsList
                    onRenderItemColumn={props.onRenderItemColumn}
                    onRenderField={handleRenderField}
                    onRenderRow={props.onRenderRow}
                    columns={columns}
                    shimmerLines={props.shimmerLines}
                    enableShimmer={props.loading}
                    items={sortedItems}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    constrainMode={ConstrainMode.unconstrained}
                    selection={selection}
                    compact={true}
                />
            </Stack.Item>
        </Stack>
    );
}
