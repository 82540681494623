import dayjs from 'dayjs';
import { ExtractQueryArrayType, taskStatusUtil } from '../data/extendedTypes';
import { GetAllTasksQuery, GetMissionTasksQuery } from '../data/types';
import { TaskFilters } from '../scenes/MissionBuilder/components/TaskFilterBar';
import { useStateContext } from '../services/contextProvider';
import { ContributorTask } from '../scenes/Contributor/hooks/useContributorTasks';

export type ImpliedTaskType =
    | ExtractQueryArrayType<GetMissionTasksQuery, ['tasks']>
    | ExtractQueryArrayType<GetAllTasksQuery, ['tasks']>;

type TaskFilterItem = (ImpliedTaskType | ContributorTask) & {
    mission?: {
        title: string | null;
        owner: string | null;
        team: { code: string | null; name: string | null } | null;
    } | null;
    categoryId?: string | null;
};

export function useTaskFilters<T extends TaskFilterItem>(
    filters: TaskFilters | null | undefined,
    tasks: T[] | null,
    filterResourcedTasks?: boolean // Include resourced tasks in filter - such as those on the contributor section
): {
    hasFilters: boolean;
    filteredImpliedTasks: T[] | null;
} {
    const { currentFinancialYearCode } = useStateContext();

    const shouldFilterTask = (t: T): boolean => {
        if (filterResourcedTasks && t.resourcedFromTaskId) {
            return true;
        } else if (!!t.parentTaskId && !t.isDuplicate) {
            return true;
        }
        return false;
    };

    const hasFilters =
        !!filters &&
        (!!filters.keyword ||
            filters.taskStatusNames.length > 0 ||
            filters.dueDateRanges.length > 0 ||
            filters?.resources.length > 0 ||
            filters?.forDateTime.isOn);

    let filteredTasks = tasks || [];

    if (filters?.keyword) {
        let regex: RegExp | null = null;
        try {
            // eslint-disable-next-line security/detect-non-literal-regexp
            regex = new RegExp(filters.keyword, 'gi');
        } catch {
            regex = null;
        }

        filteredTasks = filteredTasks.filter((it) => {
            if (shouldFilterTask(it)) {
                if (regex) {
                    if (it.name && it.name.match(regex)?.length) {
                        return true;
                    }
                    if (it.mission) {
                        return (
                            it.mission.team?.code?.match(regex)?.length ||
                            it.mission.team?.name?.match(regex)?.length ||
                            it.mission.owner?.match(regex)?.length ||
                            it.mission.title?.match(regex)?.length
                        );
                    }
                } else if (filters?.keyword) {
                    if (
                        it.name &&
                        it.name.toLowerCase().includes(filters?.keyword)
                    ) {
                        return true;
                    }
                    if (it.mission) {
                        return (
                            it.mission.team?.code
                                ?.toLowerCase()
                                .includes(filters?.keyword) ||
                            it.mission.team?.name
                                ?.toLowerCase()
                                .includes(filters?.keyword) ||
                            it.mission.owner
                                ?.toLowerCase()
                                .includes(filters?.keyword) ||
                            it.mission.title
                                ?.toLowerCase()
                                .includes(filters?.keyword)
                        );
                    }
                }
            }
            return false;
        });
    }

    if (filters?.taskStatusNames.length) {
        filteredTasks = filteredTasks.filter((it) => {
            if (shouldFilterTask(it)) {
                const taskStatus = taskStatusUtil.GetTaskStatus(
                    it,
                    currentFinancialYearCode
                );
                return (
                    taskStatus && filters?.taskStatusNames.includes(taskStatus)
                );
            }
            return true;
        });
    }

    if (filters?.dueDateRanges.length) {
        filteredTasks = filteredTasks.filter((it) => {
            if (shouldFilterTask(it)) {
                return (
                    !!it.due &&
                    filters?.dueDateRanges.some((r) =>
                        dayjs(it.due).isBetween(
                            r.startDate,
                            r.endDate,
                            null,
                            '[]' // inclusive
                        )
                    )
                );
            }
            return true;
        });
    }

    if (filters?.resources.length) {
        filteredTasks = filteredTasks.filter((it) => {
            if (shouldFilterTask(it)) {
                return filters?.resources.some((r) =>
                    it.resourcedTasks?.some(
                        (rt) =>
                            (rt.resource?.id &&
                                rt.resource?.id === r.resourceId) ||
                            (rt.resource?.userId &&
                                rt.resource?.userId === r.userId)
                    )
                );
            }
            return true;
        });
    }

    if (filters?.resourcedFromMissionIds.length) {
        filteredTasks = filteredTasks.filter((it) => {
            if (shouldFilterTask(it)) {
                return filters?.resourcedFromMissionIds.some(
                    (mid) => it.resourcedFromTask?.mission?.id === mid
                );
            }
            return true;
        });
    }

    if (filters?.categoryIds.length) {
        filteredTasks = filteredTasks.filter((it) => {
            if (shouldFilterTask(it)) {
                return filters.categoryIds.some(
                    (cid) => cid === (it.categoryId || '')
                );
            }
            return true;
        });
    }

    return {
        hasFilters,
        filteredImpliedTasks: filteredTasks,
    };
}
