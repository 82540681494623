import * as React from 'react';

import {
    useGetDivisionsQuery,
    DivisionQl,
    useUpdateDivisionMutation,
    Division,
} from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import {
    ShimmeredDetailsList,
    IconButton,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    Toggle,
    Icon,
} from '@fluentui/react';
import { useSorter } from '../../../hooks/useSorter';
import { DetailsListCellItemContainer } from '../../../components/shared/DetailsListCellItemContainer';
import { defaultTheme } from '../../../hooks/useThemes';

type DivisionListProps = {
    fyCodeFilter: string | null;
    onItemEdit: (division: DivisionQl) => void;
    onJsonUpload: (division: DivisionQl) => void;
};

export function DivisionList(props: DivisionListProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { data, loading } = useGetDivisionsQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: props.fyCodeFilter,
        },
    });

    const [updateDivision] = useUpdateDivisionMutation();

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(data?.divisions, 'code', false, true);

    const changeActive = (divisionId: string, isChecked: boolean): void => {
        const division = data?.divisions?.find((f) => f.id === divisionId);

        if (division?.id) {
            updateDivision({
                variables: {
                    tenantId: currentTenantId || '',
                    input: {
                        id: division.id,
                        code: division.code,
                        name: division.name,
                        purpose: division.purpose,
                        vision: division.vision,
                        values: division.values,
                        financialYearId: division.financialYear?.id ?? '',
                        utcInactive: isChecked ? null : new Date(),
                        version: division.version,
                    } as Division,
                },
                optimisticResponse: {
                    __typename: 'Mutations',
                    divisionUpdate: {
                        ...division,
                        financialYearId: division.financialYear?.id ?? '',
                        utcInactive: isChecked
                            ? null
                            : new Date().toISOString(),
                    },
                },
            });
        }
    };

    const columns: IColumn[] = [
        {
            key: 'colour',
            name: '',
            fieldName: 'colourHex',
            isIconOnly: true,
            isResizable: false,
            minWidth: 24,
            maxWidth: 24,

            onRender: function renderIcon(item: DivisionQl): JSX.Element {
                const iconStyles = {
                    color: item.primaryColourHex
                        ? `#${item.primaryColourHex}`
                        : defaultTheme.palette.themePrimary,
                    fontSize: 18,
                };
                return (
                    <Icon
                        style={iconStyles}
                        iconName="SquareShapeSolid"
                        aria-hidden="true"
                    />
                );
            },
        },
        {
            key: 'financialYear',
            name: 'FY',
            fieldName: 'financialYear.code',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            onRender: function renderFinancialYear(
                item: DivisionQl
            ): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        {item?.financialYear?.code}
                    </DetailsListCellItemContainer>
                );
            },
            isSorted: sortColumn === 'financialYear.code',
            isSortedDescending:
                sortColumn === 'financialYear.code' && sortIsDesc,
            onColumnClick: (): void =>
                setSortColumnName('financialYear.code', false),
        },
        {
            key: 'code',
            name: 'Code',
            fieldName: 'code',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isSorted: sortColumn === 'code',
            isSortedDescending: sortColumn === 'code' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('code', true),
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 200,
            isResizable: true,
            isSorted: sortColumn === 'name',
            isSortedDescending: sortColumn === 'name' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('name', true),
        },
        {
            key: 'active',
            name: 'Active?',
            fieldName: 'utcInactive',
            minWidth: 100,
            isResizable: true,
            isSorted: sortColumn === 'utcInactive',
            isSortedDescending: sortColumn === 'utcInactive' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('utcInactive'),
            onRender: function renderEnabled(item: {
                id: string;
                utcInactive: boolean;
            }): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        <Toggle
                            styles={{
                                root: {
                                    marginBottom: 0,
                                },
                            }}
                            onText="Yes"
                            offText="No"
                            checked={!item.utcInactive}
                            onChange={(
                                ev: React.MouseEvent<HTMLElement>,
                                checked?: boolean
                            ): void => {
                                changeActive(item.id, checked || false);
                            }}
                        />
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'actions',
            name: '',
            minWidth: 64,
            maxWidth: 64,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderActions(item: DivisionQl): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        <IconButton
                            iconProps={{ iconName: 'Edit' }}
                            onClick={(): void => {
                                props.onItemEdit(item);
                            }}
                        />
                        <IconButton
                            iconProps={{ iconName: 'BulkUpload' }}
                            title={'JSON Upload'}
                            onClick={(): void => {
                                props.onJsonUpload(item);
                            }}
                        />
                    </DetailsListCellItemContainer>
                );
            },
        },
    ];

    function renderItemColumn(
        item?: DivisionQl,
        _index?: number,
        column?: IColumn
    ): JSX.Element {
        if (!column || !item) {
            return <span />;
        }
        return (
            <DetailsListCellItemContainer>
                {
                    item[column.fieldName as keyof DivisionQl] as
                        | React.ReactNode
                        | string
                }
            </DetailsListCellItemContainer>
        );
    }

    return (
        <ShimmeredDetailsList
            setKey="items"
            items={sortedItems}
            columns={columns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            enableShimmer={loading}
            ariaLabelForShimmer="Content is being fetched"
            ariaLabelForGrid="Item details"
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            onItemInvoked={(item?: DivisionQl): void => {
                if (item) {
                    props.onItemEdit(item);
                }
            }}
            onShouldVirtualize={(): boolean => false}
            onRenderItemColumn={renderItemColumn}
        />
    );
}
