import React from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';

import {
    Link,
    Text,
    Stack,
    Icon,
    PersonaCoin,
    ActionButton,
    ShimmeredDetailsList,
    SelectionMode,
    ConstrainMode,
    MessageBar,
    MessageBarType,
    PersonaSize,
    Separator,
} from '@fluentui/react';

import { useStateContext } from '../../../services/contextProvider';
import {
    useGetMissionMeasuresQuery,
    useGetMissionTasksQuery,
    TaskQl,
    useGetMeasureGroupsQuery,
} from '../../../data/types';

import Loading from '../../../components/Loading';
import {
    InternalLink,
    InternalLinkProps,
} from '../../../components/navigation';

import { photoService } from '../../../services/photo.service';
import { useThemes } from '../../../hooks/useThemes';
import { MeasureTiles } from './MeasureTiles';
import MeasureTable from './MeasureTable';
import { TaskSummaryBar } from './TaskSummaryBar';
import { AdvanceCard } from '../../../components/AdvanceCard';
import { Access } from '../../../data/extendedTypes';

export type TeamMissionCardProps = {
    mode?: 'measures' | 'tasks' | 'mission';
    isTeamLeader: boolean;
    tenantCode: string;
    financialYearCode: string;
    missionId: string;
    missionUserId: string | null;
    missionAccess: Access;
    owner: string;
    title: string;
    missionStatement: string;
    missionTeams: {
        id: string | null;
        code: string | null;
        name: string | null;
    }[];
    fyStartDate: string;
    onMeasureClick: (missionId: string, measureId: string) => void;
    onTaskClick: (missionId: string, taskId: string) => void;
    dragHandler?: JSX.Element | null;
    compact: boolean;
};
function TeamMissionCard(props: TeamMissionCardProps): JSX.Element {
    const defaultNumberOfMeasuresDisplayed = 3;

    const { currentTenantId } = useStateContext();

    const [numberOfMeasuresDisplayed, setNumberOfMeasuresDisplayed] =
        React.useState(defaultNumberOfMeasuresDisplayed);

    const { loading: measureGroupsLoading, data: measuresGroupsData } =
        useGetMeasureGroupsQuery({
            skip: props.mode !== 'measures' || !props.missionAccess.read,
            variables: {
                tenantId: currentTenantId || '',
                missionId: props.missionId,
            },
        });

    const { loading: measuresLoading, data: measuresData } =
        useGetMissionMeasuresQuery({
            skip: props.mode !== 'measures' || !props.missionAccess.read,
            variables: {
                tenantId: currentTenantId || '',
                missionId: props.missionId,
            },
        });

    const { loading: tasksLoading, data: tasksData } = useGetMissionTasksQuery({
        skip: props.mode !== 'tasks' || !props.missionAccess.read,
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId,
        },
    });

    const { currentTheme } = useThemes();

    const measureCount = measuresData?.measures?.length || 0;

    const canShowMoreCount: number = measureCount - numberOfMeasuresDisplayed;

    const canShowMore: boolean = canShowMoreCount > 0;

    const canShowLess: boolean =
        numberOfMeasuresDisplayed > defaultNumberOfMeasuresDisplayed;

    const showMore = (): void => {
        setNumberOfMeasuresDisplayed(measureCount);
    };

    const showLess = (): void => {
        setNumberOfMeasuresDisplayed(defaultNumberOfMeasuresDisplayed);
    };

    const buttons: {
        key: string;
        name: string;
        icon: JSX.Element;
        internalLinkProps: InternalLinkProps;
    }[] = [];

    props.missionTeams?.forEach((t) => {
        buttons.push({
            key: `team${t.id}`,
            name: t.name || '',
            icon: (
                <Icon
                    iconName="Group"
                    style={{ fontSize: props.compact ? '1.4em' : '2em' }}
                />
            ),
            internalLinkProps: {
                scene: 'Team',
                tenantCode: props.tenantCode,
                financialYearCode: props.financialYearCode,
                teamCode: t.code,
            },
        });
    });

    buttons.push({
        key: `mission${props.missionId}`,
        name: 'Mission',
        icon: (
            <Icon
                iconName="Trending12"
                style={{ fontSize: props.compact ? '1.4em' : '2em' }}
            />
        ),
        internalLinkProps: {
            scene: 'Mission',
            tenantCode: props.tenantCode,
            financialYearCode: props.financialYearCode,
            teamCode: props.missionTeams[0].code,
            missionId: props.missionId,
            disabled: !props.missionAccess.read,
        },
    });

    buttons.push({
        key: `presenation${props.missionId}`,
        name: 'Present',
        icon: (
            <Icon
                iconName="Presentation"
                style={{ fontSize: props.compact ? '1.4em' : '2em' }}
            />
        ),
        internalLinkProps: {
            scene: 'Present',
            tenantCode: props.tenantCode,
            financialYearCode: props.financialYearCode,
            teamCode: props.missionTeams[0].code,
            missionId: props.missionId,
            presentation: 'mission-analysis',
            disabled: !props.missionAccess.read,
        },
    });

    const specifiedTasks = orderBy(
        tasksData?.tasks?.filter((task) => !task.parentTaskId) || [],
        'sequence'
    );

    const handleMeasureClick = (measureId: string) => {
        props.onMeasureClick(props.missionId, measureId);
    };

    const renderTask = (task: TaskQl): JSX.Element => {
        const formatter = new Intl.NumberFormat(undefined, {
            style: 'percent',
            maximumFractionDigits: 1,
        });

        const impliedTasks = tasksData?.tasks?.filter(
            (t) => t.parentTaskId === task.id
        );

        const formattedPercentage = formatter.format(task.percentComplete ?? 0);

        const handleTaskClick = () => {
            props.onTaskClick(props.missionId, task.id || '');
        };

        return (
            <Stack tokens={{ padding: 0, childrenGap: props.compact ? 0 : 4 }}>
                <Text variant="small" block>
                    <Link onClick={handleTaskClick}>{task.name}</Link>
                </Text>
                <TaskSummaryBar
                    impliedTasks={impliedTasks || []}
                    percentageComplete={task.percentComplete || 0}
                    compact={props.compact}
                />
                <Text variant="tiny" block>
                    Completed: {formattedPercentage}
                </Text>
            </Stack>
        );
    };

    return (
        <AdvanceCard
            style={{ paddingBottom: 0, paddingTop: 8 }}
            childrenGap={props.compact ? 0 : 4}
        >
            <AdvanceCard.Item>
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <Stack.Item>
                        <PersonaCoin
                            text={props.owner}
                            size={
                                props.compact
                                    ? PersonaSize.size40
                                    : PersonaSize.size48
                            }
                            imageUrl={photoService.getImageUrl(
                                props.missionUserId
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item grow align="center">
                        <Text
                            variant={props.compact ? 'medium' : 'mediumPlus'}
                            block
                            title={props.owner}
                            style={{
                                fontWeight: props.isTeamLeader
                                    ? 'bold'
                                    : undefined,
                            }}
                        >
                            <div>{props.owner}</div>
                        </Text>
                        <Text
                            variant={props.compact ? 'small' : 'medium'}
                            block
                            title={props.title}
                        >
                            <div className="line-clamp1">{props.title}</div>
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        {!props.isTeamLeader && props.dragHandler}
                    </Stack.Item>
                </Stack>
            </AdvanceCard.Item>
            <AdvanceCard.Item fill>
                <Separator styles={{ root: { padding: 0 } }} />
            </AdvanceCard.Item>
            <AdvanceCard.Item
                style={{
                    minHeight: props.compact ? 30 : 45,
                    paddingTop: 0,
                    paddingBottom: 8,
                }}
            >
                {props.missionAccess.read && (
                    <Text title={props.missionStatement} variant="small" block>
                        <div
                            className={
                                props.compact ? 'line-clamp2' : 'line-clamp3'
                            }
                        >
                            {props.missionStatement?.trim()
                                ? props.missionStatement.trim()
                                : 'This mission does not have a mission statement.'}
                        </div>
                    </Text>
                )}
                {!props.missionAccess.read && (
                    <MessageBar
                        messageBarType={MessageBarType.warning}
                        messageBarIconProps={{
                            iconName: 'AlertSolid',
                        }}
                        isMultiline={true}
                    >
                        <span>You do not have access to view this mission</span>
                    </MessageBar>
                )}
            </AdvanceCard.Item>
            <AdvanceCard.Item
                fill
                style={{
                    backgroundColor: currentTheme.palette.neutralLight,
                    padding: props.compact ? '8px 0' : '16px 0',
                }}
            >
                <Stack horizontal horizontalAlign="space-around">
                    {buttons.map((b) => (
                        <Stack.Item
                            grow={1}
                            align="center"
                            key={b.key}
                            styles={{
                                root: {
                                    padding: '0 8px',
                                    textAlign: 'center',
                                    width: `${Math.round(
                                        100 / buttons.length
                                    )}%`,
                                },
                            }}
                        >
                            <InternalLink {...b.internalLinkProps}>
                                {b.icon}
                                <Text
                                    title={b.name}
                                    variant="small"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        display: 'block',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {b.name}
                                </Text>
                            </InternalLink>
                        </Stack.Item>
                    ))}
                </Stack>
            </AdvanceCard.Item>

            {props.missionAccess.read && props.mode === 'tasks' && (
                <AdvanceCard.Item fill>
                    <ShimmeredDetailsList
                        enableShimmer={tasksLoading}
                        shimmerLines={5}
                        compact={props.compact}
                        isHeaderVisible={false}
                        selectionMode={SelectionMode.none}
                        constrainMode={ConstrainMode.unconstrained}
                        items={specifiedTasks}
                        onShouldVirtualize={(): boolean => false}
                        columns={[
                            {
                                key: 'name',
                                name: 'Specified Tasks',
                                fieldName: 'name',
                                minWidth: 50,
                                isRowHeader: true,
                                isMultiline: true,
                                onRender: renderTask,
                            },
                        ]}
                    />
                </AdvanceCard.Item>
            )}

            {props.missionAccess.read &&
                props.mode === 'tasks' &&
                !tasksLoading &&
                specifiedTasks &&
                specifiedTasks.length === 0 && (
                    <AdvanceCard.Item
                        style={{
                            paddingBottom: 16,
                            textAlign: 'center',
                        }}
                    >
                        <Text variant="smallPlus">
                            This mission does not have any tasks.
                        </Text>
                    </AdvanceCard.Item>
                )}

            {props.missionAccess.read &&
                props.mode === 'measures' &&
                measuresLoading && (
                    <AdvanceCard.Item>
                        <Loading />
                    </AdvanceCard.Item>
                )}

            {props.missionAccess.read &&
                props.mode === 'measures' &&
                !measuresLoading &&
                measuresData &&
                measuresGroupsData && (
                    <AdvanceCard.Item fill>
                        {!props.compact && (
                            <MeasureTiles
                                measureData={measuresData}
                                measuresGroupsData={measuresGroupsData}
                                numberOfMeasuresDisplayed={
                                    numberOfMeasuresDisplayed
                                }
                                onMeasureClick={handleMeasureClick}
                                fyStartDate={props.fyStartDate}
                            />
                        )}
                        {props.compact && (
                            <MeasureTable
                                measureData={measuresData}
                                measuresGroupsData={measuresGroupsData}
                                numberOfMeasuresDisplayed={
                                    numberOfMeasuresDisplayed
                                }
                                onMeasureClick={handleMeasureClick}
                            />
                        )}
                    </AdvanceCard.Item>
                )}

            {props.missionAccess.read &&
                !props.compact &&
                props.mode === 'measures' &&
                canShowMore && (
                    <AdvanceCard.Item align="center">
                        <ActionButton onClick={showMore}>
                            + {canShowMoreCount} More
                        </ActionButton>
                    </AdvanceCard.Item>
                )}

            {props.missionAccess.read &&
                !props.compact &&
                props.mode === 'measures' &&
                canShowLess && (
                    <AdvanceCard.Item align="center">
                        <ActionButton onClick={showLess}>
                            Show less
                        </ActionButton>
                    </AdvanceCard.Item>
                )}

            {props.missionAccess.read &&
                props.mode === 'measures' &&
                !measureGroupsLoading &&
                !measuresLoading &&
                measuresData?.measures?.length === 0 && (
                    <AdvanceCard.Item
                        style={{
                            paddingBottom: 16,
                            textAlign: 'center',
                        }}
                    >
                        <Text variant="smallPlus">
                            This mission does not have any measures of success.
                        </Text>
                    </AdvanceCard.Item>
                )}
        </AdvanceCard>
    );
}

TeamMissionCard.propTypes = {
    missionTeams: PropTypes.array.isRequired,
};

export default React.memo(TeamMissionCard);
