import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import {
    GetTasksForStatusReportQuery,
    GetTemplateReportQuery,
    TemplateReportElementTypes,
    useGetTasksForStatusReportQuery,
    useGetTemplateReportQuery,
} from '../../data/types';
import { useStateContext } from '../../services/contextProvider';
import { StepContainer } from './components/StepContainer';
import { sorters } from '../../data/sorters';
import { Markdown } from './Markdown';
import { ExtractQueryArrayType } from '../../data/extendedTypes';
import { useFormatters } from '../../hooks/useFormatters';
import { PresentationTitleSlide } from './components/PresentationTitleSlide';
import { PresentationSlide } from './components/PresentationSlide';
import { useReportingMeasures } from '../../hooks/useReportingMeasures';
import { PresentationMeasureBox } from './components/PresentationMeasureBox';

export function TemplateReportPresentation(): JSX.Element {
    const handleMessage = () => {
        parent.document.dispatchEvent(new Event('presentation:action'));
    };

    useEffect(() => {
        window.addEventListener('keydown', handleMessage, false);
        window.addEventListener('mousedown', handleMessage, false);
        window.addEventListener('touchstart', handleMessage, false);
    }, []);

    const [revealApi, setRevealApi] = useState<Reveal.Api>();
    const handleInitialized = setRevealApi;
    const handleSectionChanged = () => {
        if (revealApi?.sync) {
            revealApi.sync();
        }
    };

    const { currentTenantId, configuration } = useStateContext();

    const { storageFnUrl } = configuration;

    const { missionId, templateReportId, templateReportViewId } = useParams();
    const formatters = useFormatters();

    const { data } = useGetTemplateReportQuery({
        skip: !templateReportId || !currentTenantId,

        variables: {
            tenantId: currentTenantId || '',
            id: templateReportId || '',
        },
        pollInterval: 10000,
    });

    const forDateTime =
        data?.reports?.templateReport?.utcDataDate ||
        data?.reports?.templateReport?.utcCompletedDate ||
        null;

    const { data: taskData } = useGetTasksForStatusReportQuery({
        skip: !data?.reports?.templateReport,
        variables: {
            tenantId: currentTenantId || '',
            missionId: missionId || '',
            forDateTime: forDateTime,
            includeDeleted: true,
        },
        fetchPolicy: 'no-cache', // We don't want the historic results polluting the cache
    });

    const templateReport = data?.reports?.templateReport;
    const tasks = taskData?.tasks;
    const mission = templateReport?.mission;

    const { measures, setSelectedMeasureIds, periodData } =
        useReportingMeasures({
            missionId: missionId || null,
            reportPeriod: templateReport?.reportPeriod || null,
            reportPeriodType: templateReport?.reportPeriodType || null,
            utcCompletedDate: templateReport?.utcCompletedDate || null,
            utcDataDate: templateReport?.utcDataDate || null,
        });

    useEffect(() => {
        setSelectedMeasureIds(
            templateReport?.sections.flatMap((s) =>
                s.elements.flatMap((e) =>
                    e.measureLinks.map((ml) => ml.measureId)
                )
            ) || []
        );
    }, [templateReport, setSelectedMeasureIds]);

    const isReady = !!mission && !!templateReport && !!measures && !!tasks;
    const steps: JSX.Element[] = [];

    function addStep(key: string, content: JSX.Element) {
        steps.push(
            <PresentationSlide
                key={key}
                sectionKey="risks-opportunities-support"
                leftSize="none"
            >
                <PresentationSlide.ContentContainer>
                    {content}
                </PresentationSlide.ContentContainer>
            </PresentationSlide>
        );
    }

    const formattedReportDate = templateReport?.reportDate
        ? dayjs.utc(templateReport?.reportDate).format('DD MMM YYYY')
        : null;

    if (!templateReportViewId) {
        steps.push(
            <PresentationTitleSlide
                key="title"
                title={`${templateReport?.title || 'Status Report'} ${formattedReportDate ? `(${formattedReportDate})` : ''}`}
                mission={mission}
                onSectionChanged={handleSectionChanged}
            />
        );
    }

    /*
    steps.push(
        <MissionPresentationSlide
            key="mission"
            mission={mission}
            measures={measures}
        />
    );
    */

    const findSourceElement = (element: {
        sourceTemplateReportSectionElementId: string | null;
    }): ExtractQueryArrayType<
        GetTemplateReportQuery,
        ['reports', 'templateReport', 'sections', 'elements']
    > | null => {
        return element.sourceTemplateReportSectionElementId
            ? templateReport?.sections
                  .flatMap((s) => s.elements)
                  ?.find(
                      (e) =>
                          e.id === element.sourceTemplateReportSectionElementId
                  ) || null
            : null;
    };

    const renderCustomTable = (
        element: ExtractQueryArrayType<
            GetTemplateReportQuery,
            ['reports', 'templateReport', 'sections', 'elements']
        >
    ): JSX.Element => {
        const lastRowIndex = Math.max(
            ...(element.columns
                .flatMap((t) => t.cells)
                .map((c) => c.rowIndex) || [-1])
        );

        const rows: { [key: string]: string }[] = [];

        for (let i = 0; i <= lastRowIndex; i++) {
            const newRow: { [key: string]: string } = {};

            element.columns.forEach((c) => {
                const cell = c.cells.find((cell) => cell.rowIndex === i);
                newRow[c.id || ''] = cell?.content?.trim() || '';
            });

            rows.push(newRow);
        }

        return (
            <div className="mintListBody" style={{ padding: 0 }}>
                <table style={{ minWidth: '100%' }}>
                    <thead>
                        <tr>
                            {element.columns
                                .slice()
                                .sort(sorters.sequenceSorter)
                                .map((c) => {
                                    return <th key={c.id}>{c.columnName}</th>;
                                })}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    {element.columns
                                        .slice()
                                        .sort(sorters.sequenceSorter)
                                        .map((c) => {
                                            return (
                                                <td key={c.id}>
                                                    {row[c.id || '']}
                                                </td>
                                            );
                                        })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderTaskBulletList = (
        element: ExtractQueryArrayType<
            GetTemplateReportQuery,
            ['reports', 'templateReport', 'sections', 'elements']
        >,
        sourceElement: ExtractQueryArrayType<
            GetTemplateReportQuery,
            ['reports', 'templateReport', 'sections', 'elements']
        > | null
    ): JSX.Element => {
        const selectedTasks: GetTasksForStatusReportQuery['tasks'] = [];

        (sourceElement ? sourceElement.taskLinks : element.taskLinks)
            .slice()
            .sort(sorters.sequenceSorter)
            .forEach((tl) => {
                const task = taskData?.tasks.find((t) => t.id === tl.taskId);
                if (task) {
                    selectedTasks.push(task);
                }
            });

        const specifiedTasks = selectedTasks.filter(
            (t) => t && t?.parentTaskId === null && !t.isDuplicate
        );

        const textContent = sourceElement
            ? sourceElement.textContent
            : element.textContent;

        const customFacts = textContent?.trim() ? textContent.split('|') : [];

        let markdown: string = '';

        specifiedTasks.forEach((st) => {
            const impliedTasks =
                selectedTasks.filter(
                    (it) => it?.parentTaskId === st.id && !it.isDuplicate
                ) || [];

            markdown += `${st.name}\n`;

            impliedTasks.forEach((it) => {
                markdown += `  - ${it.name}\n`;
            });
        });

        customFacts.forEach((cf) => {
            markdown += `${cf}\n`;
        });

        return (
            <Markdown source={markdown} className="mintMarkdownBoxNoScroll" />
        );
    };

    const renderTaskTable = (
        element: ExtractQueryArrayType<
            GetTemplateReportQuery,
            ['reports', 'templateReport', 'sections', 'elements']
        >
    ): JSX.Element => {
        const selectedTasks: GetTasksForStatusReportQuery['tasks'] = [];

        element.taskLinks
            .slice()
            .sort(sorters.sequenceSorter)
            .forEach((tl) => {
                const task = taskData?.tasks.find((t) => t.id === tl.taskId);
                if (task) {
                    selectedTasks.push(task);
                }
            });

        const specifiedTasks = selectedTasks.filter(
            (t) => t && t?.parentTaskId === null && !t.isDuplicate
        );

        const customFacts = element.textContent?.trim()
            ? element.textContent.split('|')
            : [];

        return (
            <div
                className="mintListBody"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '3vh',
                    padding: 0,
                }}
            >
                {specifiedTasks?.map((st) => {
                    const impliedTasks =
                        selectedTasks.filter(
                            (it) =>
                                it?.parentTaskId === st.id && !it.isDuplicate
                        ) || [];

                    // const linkedMeasures =
                    //     measureData?.measures
                    //         ?.slice()
                    //         .sort(sorters.sequenceSorter)
                    //         .filter(
                    //             (m) =>
                    //                 st.linkedMeasures
                    //                     .map((lm) => lm.measureId)
                    //                     .indexOf(m.id || '') > -1
                    //         ) || [];

                    return (
                        <div
                            key={st.id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1vh',
                            }}
                        >
                            <h6
                                style={{
                                    textAlign: 'left',
                                    margin: 0,
                                    fontSize: '1em',
                                }}
                            >
                                {st.name}
                            </h6>

                            {!!impliedTasks.length && (
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '100%' }}>
                                                Implied Tasks
                                            </th>
                                            <th
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    textAlign: 'center',
                                                    width: 'auto',
                                                }}
                                            >
                                                Due Date
                                            </th>
                                            <th
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    textAlign: 'center',
                                                    width: 'auto',
                                                }}
                                            >
                                                % Complete
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {impliedTasks.map((it) => (
                                            <tr key={it.id}>
                                                <td style={{ width: '100%' }}>
                                                    {it.name}
                                                </td>
                                                <td
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        textAlign: 'center',
                                                        width: 'auto',
                                                    }}
                                                >
                                                    {it.due
                                                        ? dayjs
                                                              .utc(it.due)
                                                              .format(
                                                                  'DD MMM YYYY'
                                                              )
                                                        : '-'}
                                                </td>
                                                <td
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        textAlign: 'center',
                                                        width: 'auto',
                                                    }}
                                                >
                                                    {formatters.formatTaskPercentage(
                                                        it.percentComplete
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}

                            {/* {!!linkedMeasures.length && (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Linked Measures of Success</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {linkedMeasures.map((m) => {
                                            const lastAsOf = isHistoric
                                                ? m?.asOfForDate
                                                : m?.lastAsOf;

                                            return (
                                                <tr key={m.id}>
                                                    <td>{m.name}</td>
                                                    <td>
                                                        {formatters.formatMeasureStatus(
                                                            lastAsOf?.statusValue,
                                                            m.isStatusLimited
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )} */}
                        </div>
                    );
                })}
                {customFacts?.map((cf, index) => {
                    return (
                        <div key={index}>
                            <h6
                                style={{
                                    textAlign: 'left',
                                    marginTop: '2vh',
                                    fontSize: '1em',
                                }}
                            >
                                {cf}
                            </h6>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderImage = (
        element: ExtractQueryArrayType<
            GetTemplateReportQuery,
            ['reports', 'templateReport', 'sections', 'elements']
        >
    ): JSX.Element => {
        const source = findSourceElement(element) || element;

        const imageUrl = source?.attachmentId
            ? `${storageFnUrl}/api/Attachment?tenantId=${currentTenantId}&attachmentId=${source.attachmentId}`
            : null;

        return imageUrl ? (
            <img
                src={imageUrl}
                style={{
                    width: '100%',
                    maxWidth: '100%',
                    height: 'auto',
                    maxHeight: 'none',
                    margin: 0,
                }}
            />
        ) : (
            <div />
        );
    };

    const renderElement = (
        element: ExtractQueryArrayType<
            GetTemplateReportQuery,
            ['reports', 'templateReport', 'sections', 'elements']
        >
    ): JSX.Element => {
        const sourceElement = element.sourceTemplateReportSectionElementId
            ? findSourceElement(element)
            : element;

        return (
            <div
                key={element.id}
                style={{
                    flexGrow: element.layoutGrow || undefined,
                    flexBasis: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    minWidth: 0,
                    minHeight: 'auto',
                    gap: 'min(0.8vw, 16px)',
                }}
            >
                {!!element.title && element.title !== 'Untitled' ? (
                    <h6 style={{ marginBottom: 0 }}>{element.title}</h6>
                ) : (
                    <h6 style={{ marginBottom: 0 }}>&nbsp;</h6>
                )}

                {element.type === TemplateReportElementTypes.Markdown &&
                    sourceElement?.textContent?.trim() && (
                        <div className="mintMarkdownFrame">
                            <Markdown
                                source={sourceElement?.textContent?.trim()}
                                className="mintMarkdownBoxNoScroll"
                            />
                        </div>
                    )}

                {element.type === TemplateReportElementTypes.Label && (
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Markdown
                            source={element.textContent?.trim()}
                            className="markdownLabel"
                        />
                    </div>
                )}

                {(element.type === TemplateReportElementTypes.MeasureList ||
                    element.type ===
                        TemplateReportElementTypes.MeasureTable) && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 'min(0.8vw, 16px)',
                            minHeight: 'auto',
                        }}
                    >
                        {sourceElement?.measureLinks
                            .slice()
                            .sort(sorters.sequenceSorter)
                            .map((ml) => {
                                const measure = measures?.find(
                                    (m) => m.id === ml.measureId
                                );

                                if (!measure) {
                                    return null;
                                }

                                const measurePeriodData = periodData.find(
                                    (pd) =>
                                        pd.measureId === measure?.id ||
                                        (measure?.isLinked &&
                                            measure.linkedFromMeasureId ===
                                                pd.measureId)
                                );

                                if (!measurePeriodData) {
                                    return null;
                                }

                                return (
                                    <div key={ml.id}>
                                        <PresentationMeasureBox
                                            measure={measure}
                                            periodData={measurePeriodData}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                )}

                {element.type === TemplateReportElementTypes.TaskTable && (
                    <div className="contentAreaRaised">
                        <div>{renderTaskTable(element)}</div>
                    </div>
                )}

                {element.type === TemplateReportElementTypes.TaskBulletList && (
                    <div className="mintMarkdownFrame">
                        <div>
                            {renderTaskBulletList(element, sourceElement)}
                        </div>
                    </div>
                )}

                {element.type === TemplateReportElementTypes.CustomTable && (
                    <div
                        className="contentAreaRaised"
                        style={
                            {
                                // position: 'relative',
                                //height: '100%',
                            }
                        }
                    >
                        <div
                            style={
                                {
                                    // overflowY: 'auto',
                                    // position: 'absolute',
                                    // left: 0,
                                    // top: 0,
                                    // right: 0,
                                    // bottom: 0,
                                    // padding: 16,
                                }
                            }
                        >
                            {renderCustomTable(element)}
                        </div>
                    </div>
                )}

                {element.type === TemplateReportElementTypes.Image && (
                    <div className="contentAreaRaised">
                        {renderImage(element)}
                    </div>
                )}
            </div>
        );
    };

    const addSectionStep = (
        key: string,
        layoutDirection: string,
        groups: ExtractQueryArrayType<
            GetTemplateReportQuery,
            ['reports', 'templateReport', 'sections', 'groups']
        >[],
        elements: ExtractQueryArrayType<
            GetTemplateReportQuery,
            ['reports', 'templateReport', 'sections', 'elements']
        >[],
        title: string
    ) => {
        const filteredElements = elements.filter((e) => {
            const source = e.sourceTemplateReportSectionElementId
                ? findSourceElement(e)
                : e;

            return (
                e.type === TemplateReportElementTypes.Label ||
                e.type === TemplateReportElementTypes.CustomTable ||
                source?.attachmentId ||
                source?.textContent ||
                source?.taskLinks.length ||
                source?.measureLinks.length
            );
        });
        const filteredGroups = groups.filter((g) =>
            filteredElements.some((e) => e.groupId === g.id)
        );

        const maxSequence =
            Math.max(
                0,
                ...filteredElements.map((e) => e.sequence),
                ...(filteredGroups?.map((g) => g.sequence) || [])
            ) + 1;

        addStep(
            key,
            <div
                style={{
                    height: 'calc(100vh - 64px)', // 100% - the bottom paging section
                    display: 'flex',
                    alignItems: 'stretch',
                    flexDirection: 'column',
                    gap: 'min(0.4vw, 16px)',
                }}
            >
                {title ? (
                    <div className="mintListHeader">
                        <h6>{title}</h6>
                    </div>
                ) : (
                    <div className="mintListHeader">
                        <h6>&nbsp;</h6>
                    </div>
                )}
                <div
                    style={{
                        //flexGrow: 1,
                        display: 'flex',
                        overflowY: 'auto',
                        flexDirection: layoutDirection as
                            | 'column'
                            | 'column-reverse'
                            | 'row'
                            | 'row-reverse',
                        alignItems: 'stretch',
                        gap: 'min(0.8vw, 16px)',
                        paddingLeft: 12,
                        paddingRight: 12,
                    }}
                >
                    {[...Array(maxSequence)].map((_obj, sequence) => {
                        return (
                            <React.Fragment key={sequence}>
                                {filteredElements
                                    .slice()
                                    .sort(sorters.sequenceSorter)
                                    .filter(
                                        (e) =>
                                            e.sequence == sequence && !e.groupId
                                    )
                                    .map((element) => renderElement(element))}

                                {!!filteredGroups &&
                                    filteredGroups
                                        .slice()
                                        .sort(sorters.sequenceSorter)
                                        .filter((e) => e.sequence == sequence)
                                        .map((group) => (
                                            <div
                                                style={{
                                                    display: 'flex',

                                                    flexBasis: 0,
                                                    gap: 'min(0.8vw, 16px)',
                                                    flexGrow:
                                                        group.layoutGrow ||
                                                        undefined,
                                                    flexDirection:
                                                        group.layoutDirection as
                                                            | 'column'
                                                            | 'column-reverse'
                                                            | 'row'
                                                            | 'row-reverse',
                                                    alignItems: 'stretch',

                                                    minWidth: 0,
                                                }}
                                                key={group.sequence}
                                            >
                                                {filteredElements
                                                    .slice()
                                                    .sort(
                                                        sorters.sequenceSorter
                                                    )
                                                    .filter(
                                                        (e) =>
                                                            e.groupId ==
                                                            group.id
                                                    )
                                                    .map((element) =>
                                                        renderElement(element)
                                                    )}
                                            </div>
                                        ))}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        );
    };

    const sections =
        templateReport?.sections
            .slice()
            .filter(
                (s) => s.templateReportViewId === (templateReportViewId || null)
            )
            .sort(sorters.sequenceSorter) || [];

    for (const section of sections) {
        let splitGroups = false;
        let splitElements = false;

        const title =
            section.title ||
            (sections.indexOf(section) === 0 && templateReport?.title
                ? templateReport?.title
                : '');

        // If all the elements are empty
        if (
            section.elements.every((element) => {
                const source = findSourceElement(element) ?? element;

                return (
                    !source?.textContent &&
                    !source?.attachmentId &&
                    !source?.measureLinks.length &&
                    !source?.taskLinks.length &&
                    element.type !== TemplateReportElementTypes.CustomTable
                );
            })
        ) {
            // Skip
            continue;
        }

        if (section.layoutDirection === 'column') {
            for (const group of section.groups) {
                for (const element of section.elements) {
                    if (
                        element.groupId === group.id &&
                        element.type === TemplateReportElementTypes.CustomTable
                    ) {
                        splitGroups = true;
                        break;
                    }
                }
            }
            for (const element of section.elements) {
                if (element.type === TemplateReportElementTypes.CustomTable) {
                    splitElements = true;
                    break;
                }
            }
        }

        if (splitGroups) {
            const maxSequence = Math.max(
                ...[
                    ...section.groups.map((g) => g.sequence),
                    ...section.elements.map((e) => e.sequence),
                ]
            );

            for (let sequence = 0; sequence <= maxSequence; sequence++) {
                for (const group of section.groups.filter(
                    (g) => g.sequence === sequence
                )) {
                    // get all the elements for this group
                    const groupElements =
                        section.elements.filter(
                            (e) => e.groupId === group.id
                        ) || [];

                    addSectionStep(
                        `${section.id}_${group.id}`,
                        group.layoutDirection || '',
                        [group],
                        groupElements,
                        title
                    );
                }

                for (const element of section.elements.filter(
                    (e) => e.sequence === sequence && !e.groupId
                )) {
                    addSectionStep(
                        `${section.id}_${element.id}`,
                        section.layoutDirection || '',
                        [],
                        [element],
                        title
                    );
                }
            }
        } else if (splitElements) {
            for (const element of section.elements) {
                addSectionStep(
                    `${section.id}_${element.id}`,
                    section.layoutDirection || '',
                    section.groups,
                    [element],
                    title
                );
            }
        } else {
            addSectionStep(
                section.id || '',
                section.layoutDirection || '',
                section.groups,
                section.elements,
                title
            );
        }
    }

    return (
        <StepContainer
            isReady={isReady}
            steps={steps}
            onInitialized={handleInitialized}
        />
    );
}
