import React, { PropsWithChildren } from 'react';
import {
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    MeasuringStrategy,
    MouseSensor,
    TouchSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

export function SortableContainer<TUniqueIdentifier extends UniqueIdentifier>(
    props: PropsWithChildren<{
        ids: TUniqueIdentifier[] | null;
        onDragging: (id: TUniqueIdentifier | null) => void;
        onDropped: (id: TUniqueIdentifier | null, index: number) => void;
        dragOverlay?: JSX.Element | null;
    }>
): JSX.Element {
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: { y: 5, x: 5 },
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const modifiers = [restrictToVerticalAxis];

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (props.ids && over?.id) {
            props.onDropped(
                active.id as TUniqueIdentifier,
                props.ids.indexOf(over.id as TUniqueIdentifier)
            );
        }

        props.onDragging(null);
    }

    return (
        <DndContext
            sensors={sensors}
            modifiers={modifiers}
            onDragStart={({ active }) => {
                props.onDragging(active.id as TUniqueIdentifier);
            }}
            onDragEnd={handleDragEnd}
            onDragCancel={() => {
                props.onDragging(null);
            }}
            measuring={{
                droppable: {
                    strategy: MeasuringStrategy.Always,
                },
            }}
        >
            <SortableContext items={props.ids || []}>
                {props.children}
            </SortableContext>

            {props.dragOverlay}
        </DndContext>
    );
}
