import React from 'react';
import { useActiveView } from '../../hooks/useActiveView';

import { LegalContainer } from './components/LegalContainer';

export function PrivacyPolicy(): JSX.Element {
    useActiveView('PrivacyPolicy', 'Privacy Policy');

    return (
        <LegalContainer>
            <h1>Privacy Policy Information</h1>
            <p style={{ textAlign: 'right' }}>
                <strong>
                    <em>Updated 27 January 2020</em>
                </strong>
            </p>
            <h3>
                <strong>Personal Privacy Data Handling Information</strong>
            </h3>
            <p>
                At GP Strategies our Data Privacy and Records Management Policy
                is a commitment to protecting the privacy data of employees,
                clients, business partners and web and social media site
                visitors. The Policy guides our privacy data handling practices
                as described here in this Frequently Asked Questions (FAQ)
                format. GP Strategies (GP) makes every reasonable effort to
                protect the privacy of data collected when individuals visit our
                sites.
            </p>
            <p>
                GP Strategies policy and practice is also guided by the General
                Data Protection Regulation (GDPR) of the European Union (EU),
                other country or U.S. state laws like the California Consumer
                Privacy Act (CCPA), the U.S. Securities Exchange Commission
                regulation and guidance and the EU-U.S. and Swiss-U.S. Privacy
                Shield protocols.
            </p>
            <p>
                For example, the GDPR and Privacy Shield are our worldwide
                administrative thresholds but we do adhere to more strict laws
                when applicable.
                <strong>
                    <u>
                        GP Strategies does not buy or sell consumer privacy data
                    </u>
                    as defined in the CCPA and therefore while abiding by the
                    law, we are not subject to all provisions of the CCPA.
                </strong>
                For example, for CCPA compliance case we are not required to
                have a separate landing page describing how GP buys and sells
                consumer privacy data. Therefore, there are limited needs for
                opt-out, opt-in and other CCPA specific disclosures.
            </p>
            <h3>
                <strong>Children’s Privacy</strong>
            </h3>
            <p>
                If you are under 18 years of age we request you obtain parental
                consent before posting a comment on our blogs and sites. Our
                websites do not publish content for or collect data that is
                directed at children.
            </p>
            <h3>
                <strong>
                    Contacting GP Strategies and more Information about Data
                    Privacy Practices
                </strong>
            </h3>
            <p>
                Contact information is provided at the end of this page. Please
                also review our FAQs, Fact Sheets and Privacy Policy linked
                below for more information about GP privacy practices.
            </p>
            <h3>
                <strong>
                    As a GP Strategies web site visitor, what information does
                    GP collect?
                </strong>
            </h3>
            <p>
                When you visit the GP Strategies web site, we collect and store
                information about your visit. We may also engage other companies
                to do this on our behalf. Examples of the kind of information
                we, or third parties we engage, track include which pages you
                view on our site, the site you visited just before coming to
                ours, keywords you used in your search, your company name and
                the name of your Internet Service Provider. We use this
                information to develop ideas for improving our site, our
                products and services and for improving marketing of our
                products and services. We do not make any attempts to link this
                information with you as an individual.
            </p>
            <p>
                There are also various forms on our web site that ask for
                personal information about you, such as your name, phone number,
                and e-mail address. If you tell us any personal information, we
                use it only to contact you in response to your request or to
                provide you with information that we think you may be interested
                in receiving. If you inform us that you do not want to receive
                any information that you have not specifically requested, we
                will honor your request. We will not sell or otherwise disclose
                your information to any other company.
            </p>
            <p>
                GP Strategies employs professional security personnel and takes
                technical and organizational measures designed to prevent
                unauthorized access, use, alteration, or disclosure of privacy
                data collected via GP sites. We try to be both selective and
                proactive in checking the security background for certain
                external social media sites and other sites that we come in
                contact with but do not control. GP has been in business for
                over 50 years and has more than 30 years of experience in
                operating highly secured data repositories with security
                controls that are continuously updated to meet industry
                standards and address protective measures for emerging threats.
                Security practices are described in detail in our internal
                information technology (IT) policies and procedures. Also see
                our information security practices IT Fact Sheet below for more
                information.
            </p>
            <h3>
                <strong>
                    As a GP Strategies site visitor does the “GDPR”, “CCPA” ,
                    Privacy Shield and other similar data privacy and records
                    management requirements apply to my data?
                </strong>
            </h3>
            <p>
                Companies within the EU and California, or who are externally
                located controllers and processors of the personal privacy data
                of EU and California residents in the context of collecting
                privacy data while soliciting and providing goods or services,
                must comply with the GDPR and CCPA. As a GP site visitor we do
                collect your business contact information. However, we may also
                collect or process privacy data for the purpose of providing
                additional services. We are very aware that combining multiple
                privacy data elements, even if not considered personal data when
                taken alone, may result in the sum of the elements being
                considered personal privacy data when combined into a listing,
                and we protect it as required.
            </p>
            <p>
                GP may move your data within or to locations outside of the
                European Economic Area (EAA) or California. These data transfers
                are legal under the GDPR, CCPA and Privacy Shield as long as we
                adhere to the requirements for legal processing. We encrypt all
                data in transit and in storage.
            </p>
            <p>
                GP has evaluated our obligations under the GDPR, Privacy Shield
                and other laws like the CCPA, in part, based on: (1) the type of
                visitor data that we collect via our sites, and (2) the legal
                basis on which you rely for the protection of your data. We will
                exercise data privacy stewardship on all of our sites.
            </p>
            <h3>
                <strong>
                    How will my personal information be used and shared by GP
                    Strategies for internal management of the sites?
                </strong>
            </h3>
            <p>
                The personal information we collect in our various sites, if
                needed, allows us to:
            </p>
            <ul>
                <li>Respond to your inquiries;</li>
                <li>
                    Provide the information, products and services you have
                    ordered;
                </li>
                <li>
                    Verify your identity and details of your payment method or
                    credit card amount;
                </li>
                <li>Administer our sites and provide user services;</li>
                <li>Meet legal, regulatory and compliance requirements;</li>
                <li>
                    Monitor and analyze the use of any account to prevent,
                    investigate and/or report fraud, terrorism,
                    misrepresentation, security incidents, public safety threats
                    to you or crime;
                </li>
                <li>
                    Gather management information to form statistical and trend
                    analysis;
                </li>
                <li>Communicate with you;</li>
                <li>Investigate any complaints about our sites;</li>
                <li>Personalize your experience of the sites;</li>
                <li>
                    Contact you about our products and services which we think
                    might be of interest to you (where we have the appropriate
                    permissions to do so);
                </li>
                <li>
                    When warranted, we share your personal information with our
                    GP affiliate companies and brands for the above purposes,
                    they adhere uniformly to these data handling practices;
                </li>
                <li>
                    Employ the services of third party service providers to help
                    us in certain areas, such as site hosting, maintenance and
                    call center operation. In some cases the third party may
                    receive your information. However, at all times we use third
                    parties, we will contractually control and be responsible
                    for the use of your information and place contractual
                    requirements on privacy data sent to our sub-processors.
                </li>
                <li>
                    If you provide a credit or debit card, we may also use third
                    parties to check the validity of the sort code, account
                    number and card number you submit in order to prevent fraud
                    as well as to process any transaction you attempt via the
                    website.
                </li>
                <li>
                    If false or inaccurate information is provided and fraud is
                    identified, we will follow legal processes if details will
                    be passed to fraud prevention agencies. Law enforcement
                    agencies may access and use this information. We and other
                    organizations may also access and use this information to
                    prevent fraud and money laundering, for example when:
                    <ul>
                        <li>
                            Managing credit and credit related accounts or
                            facilities
                        </li>
                        <li>Recovering debt</li>
                        <li>
                            Checking details on proposals and claims for all
                            types of insurance
                        </li>
                        <li>
                            Checking details of job applicants and employees
                            when required.
                        </li>
                    </ul>
                </li>
            </ul>
            <h3>
                <strong>
                    Where does GP Strategies store my privacy information?
                </strong>
            </h3>
            <p>
                GP Strategies stores privacy data in data centers in the United
                Kingdom and the United States. These are certified centers which
                have one or more of the following: SOC 1 Type 2, SOC 2 Type 2,
                Lloyd’s Register (LRQA) and ISO (International Standards
                Organization) 27001. (SOC = Service Organization Controls
                reports (1-3) of the AICPA (American Institute of Certified
                Public Accountants). ISO 27001 is one of the most recognized
                worldwide information technology security standards. SSAE 16 and
                ISAE 3402 – 22451 and PCI – Data 2334 Security Standard (SSAE =
                Statement on Standards for Attestation Engagements (#16 &amp;
                18), PCI = Payment Card Industry-Data Security Standard
                ((PCI-DSS)).
            </p>
            <h3>
                <strong>What constitutes personal privacy data?</strong>
            </h3>
            <p>
                Personal privacy data is information related to a natural person
                (called a ‘data subject’ by the GDPR, a “consumer” in the CCPA
                and as otherwise differentiated by other laws) that can be used
                to directly or indirectly identify the person when not encrypted
                and used individually or in combinations to create a profile.
            </p>
            <p>
                Personal privacy data is a very broad range of personal
                information and can be any information item that might be used
                to create a profile, to include basic business contact
                information of name, business address, and business phone and
                business title or business job. Further personal privacy
                information would also be: an identifiable photo; identifiable
                voice recordings; fingerprints; biometric data; psychological
                profile, a personal email address, home phone number, home
                address; numbered identifiers – bank account, credit information
                and credit card, passport, country identification, driver’s
                license, pension and social security numbers; family member
                information; medical information; political opinions; sex,
                sexual preferences; computer IP address; data on children;
                travel profiles; trade union membership; criminal records. Some
                countries differentiate some of these listed items as Sensitive
                Personal Identification Information (SPII).
            </p>
            <h3>
                <strong>
                    What is the difference between a data processor and a data
                    controller?
                </strong>
            </h3>
            <ul>
                <li>
                    A controller is the entity that determines the purposes,
                    conditions and means of the processing of personal data. A
                    controller can be a processor. A web site owner is a
                    controller.
                </li>
                <li>
                    A data processor is an entity which processes personal data,
                    with instructions and IT security framework, on behalf of
                    the controller.
                </li>
                <li>
                    GP Strategies is a controller and/ or a processor at varying
                    times in our conduct of business.
                </li>
            </ul>
            <h3>
                <strong>
                    Is GP Strategies a data processor or data controller in
                    regard to my personal data?
                </strong>
            </h3>
            <p>
                GP Strategies acts as a data controller and in some cases is
                also a processor (or sub-processor) for personal data provided
                to GP through our customers, by individuals and other third
                parties such as business partners.
            </p>
            <p>
                If a data subject (consumer) provides their personal data
                directly to GP Strategies (such as a site visitor, a forum or
                conference attendee, a site browser, etc.) GP acts as the data
                controller for that personal data. Note, if GP also processes
                that personal data in some fashion, GP also qualifies as a data
                processor in regard to that personal data.
            </p>
            <h3>
                <strong>
                    Is GP Strategies organized to manage the data processor
                    obligations imposed by the GDPR, Privacy Shield and other
                    similar laws and regulations?
                </strong>
            </h3>
            <p>
                GP Strategies established a Data Protection and Records
                Management (DPRM) Committee and appointed Data Protection
                Officers (DPOs) to manage the DPRM Program and comply with the
                GDPR, Privacy Shield and other laws. The Committee is tasked
                with instituting, conducting and monitoring GP’s internal data
                privacy compliance initiatives.
            </p>
            <p>
                For GP Strategies, keeping site visitor and all data secure is a
                high priority. Along with ensuring data security, it is
                important that a site visitor’s confidence is always maintained
                and a high level of security around processes and protection is
                strongly administered.
            </p>
            <p>
                At GP Strategies, we strongly value and base our business on the
                trust that our site visitors, employees and customers have
                placed upon us. We will continue to earn and reinforce that
                trusted relationship by cooperating with requests related to our
                GDPR, CCPA, Privacy Shield and other country data privacy
                obligations.
            </p>
            <p>
                GP Strategies is committed to taking advanced measures to
                support and continuously enhance the security of our systems, to
                ensure that we collect and process personal data in a manner
                compliant with GDPR, Privacy Shield or any similar legislation.
            </p>
            <p>
                GP Strategies management strongly believes that information
                technology security/ compliance is a key business service.
                Information security objectives and strategy must be continually
                aligned with GP’s business strategy and objectives.
            </p>
            <h3>
                <strong>When does GP Strategies delete client data?</strong>
            </h3>
            <p>
                GP Strategies deletes client data, including backups based on
                our records management schedule. In some cases that can be
                shortly after you leave one of our sites. Web and social media
                privacy information is deleted after you finish browsing or have
                opted out of receiving our communications or you have been
                unresponsive to our inquiry messages for a period of time. If
                you acknowledge our site use rules to continue browsing or you
                agree when specifically requested to opt-in your information is
                transferred to our secure customer relations management data
                base.
            </p>
            <p>&nbsp;</p>
            <p>
                To meet business operations, contractual and statutory
                requirements we are legally and /or contractually required to
                keep some data for more extended periods of time consistent with
                the lawful processing provisions of the GDPR. Data is held in
                various categories in our records management deletion schedules.
                These data retention categories range from nearly immediate up
                to seven (7) years unless there is a longer legal requirement.
                For more information about data retention times please{' '}
                <a href="https://www.gpstrategies.com/contact-us/?src=privacypolicy">
                    contact us
                </a>
                .
            </p>
            <h3>
                <strong>
                    Does GP Strategies provide clients with the option to delete
                    client data?
                </strong>
            </h3>
            <p>
                GP Strategies supports the deletion of client data. However, we
                prefer such requests to be in writing and made in cooperation
                with the GP Data Protection and Records Management Committee
                staff. This will ensure clarity of the request in the event it
                is not a temporary storage item like non-persistent web and
                social media cookies.
            </p>
            <h3 id="use-of-cookies">
                <strong>What are GP Strategies site cookie practices?</strong>
            </h3>
            <p>
                We use cookies to collect information allowing for analytics,
                user experience and session tracking, as well as video use
                tracking. Users can control the use of cookies at the individual
                browser level. If you reject cookies, you may still use our
                site, but your ability to use some features or areas of our site
                may be limited.
            </p>
            <p>
                <strong></strong>
            </p>
            <h4>
                <strong>Use of Cookies – Practices and Procedures</strong>
            </h4>
            <p>
                GP Strategies uses cookies for a variety of purposes such as
                remembering your preferences, measuring your activity on GP
                Strategies’ websites, mobile sites and mobile applications
                (“sites”) or optimizing your user experience. Disabling cookies
                on your internet browser will stop tracking of any part of your
                visit to pages within the site. All traffic (transferal of
                files) between the site and your browser is encrypted and
                delivered over the HTTPS security protocol.
            </p>
            <h4>
                <strong>Cookie Settings</strong>
            </h4>
            <p>
                The types of cookies GP Strategies and others may place on your
                device are described below.
            </p>
            <ul>
                <li>
                    GP will read or set only the types of cookies that are
                    strictly necessary for quality browsing or specifically
                    allowed by your browser preference settings.
                </li>
                <li>
                    GP only places cookies that set your preferred language,
                    deliver specific content based on visit history, and give
                    access to various sections of the sites.
                </li>
                <li>
                    Cookies set by our sites will remain on your device but GP
                    will not access or use those non-strictly-necessary cookies.
                    You may remove them using functionality provided by your
                    browser. Please note that cookies are specific to the
                    browser or device you use, as well as to the domain, and you
                    will therefore have to configure your preferences again if
                    you change your browser or device, or visit a different
                    domain.
                </li>
                <li>
                    Any specific questions regarding these cookie settings may
                    be sent to{' '}
                    <a href="mailto:gpwebmaster@gpstrategies.com">
                        gpwebmaster@gpstrategies.com
                    </a>
                </li>
            </ul>
            <h3>
                <strong>How do you use Web Beacons?</strong>
            </h3>
            <p>
                Some of our web pages may contain electronic images known as web
                beacons (sometimes known as clear gifs) that allow us to count
                users who have visited these pages. Web beacons collect only
                limited information which includes a cookie number, time and
                date of a page view, and a description of the page on which the
                web beacon resides. We may also carry web beacons placed by
                third party advertisers. These beacons do not carry any
                personally identifiable information and are only used to track
                the effectiveness of a particular campaign.
            </p>
            <h3>
                <strong>How do you use Social Media Widgets?</strong>
            </h3>
            <p>
                GP Strategies web sites can include certain social media
                features, such as the Facebook button. These features may
                collect your IP address, may collect which page you are visiting
                on our sites, and may set a cookie to enable such feature to
                function properly. Social media features are either hosted by a
                third party or hosted directly on our sites. Your interactions
                with these features are governed by the privacy policy of the
                company providing it.
            </p>
            <h3>
                <strong>How do you use Blog Information?</strong>
            </h3>
            <p>
                Should you choose to add a comment to any posts that we have
                published on our sites for example in a blog, the name and email
                address you enter with your comment will be saved to the site’s
                database, along with your computer’s IP address and the time and
                date that you submitted the comment. This information is only
                used to identify you as a contributor to the comment section of
                the respective blog post and is not passed on to any of the
                third party data processors. Only your name will be shown on a
                site that is public-facing. Your posted comment(s) and its
                associated personal data will remain on this site until we see
                fit to either 1) remove the comment, or 2) remove the blog post.
                Should you wish to have the comment and its associated personal
                data deleted, please email us listed with your contact
                information using the email address that you commented with.
            </p>
            <p>&nbsp;</p>
            <p>
                NOTE: You should avoid entering personally identifiable
                information to the actual comment field of any blog post
                comments that you submit on this site.
            </p>
            <h3>
                <strong>How do you use Contact Forms and Email Links?</strong>
            </h3>
            <p>
                Should you choose to contact us using a contact form on our
                sites or an email link, the data you supply will be stored in
                our customer relationship management database or may be passed
                on to be processed by a third party data processor(s) or may be
                sent an internal affiliated subsidiary/ organization for
                response. We do retain information from data requests in our
                customer relationship management system. Collated and
                transferred data is encrypted before being sent across the
                internet. We do not sell personal information.
            </p>
            <h3>
                <strong>How do you use information we provide to you?</strong>
            </h3>
            <p>
                As a compliance philosophy GP Strategies makes every effort to
                apply a data minimization approach in our privacy data gathering
                and storage activities. We do collect and hold some personal
                information you give us. These are the general types of
                information we may collect from you:
            </p>
            <h4>
                <strong>Registration and Profile Information</strong>
            </h4>
            <p>
                We try to gather only the most necessary information for the
                involved inquiry or purpose. When you enter or register to use
                our site, our services, to receive information, to participate
                in our events, and create or update your forum profiles, we may
                collect various kinds of information about you. For example, we
                may collect: your name; postal address; phone number; fax
                numbers and email address; your log-in ID and password; your
                title; company; and other event specific profile information you
                provide; demographic information; and information linked with
                your profile such as comments you may post. We need this
                information to be able to respond to you, secure the site and
                provide services as applicable.
            </p>
            <h4>
                <strong>Information We Automatically Collect</strong>
            </h4>
            <ul>
                <li>
                    When you visit our site or use GP Strategies site services,
                    as do many companies, some information is collected and
                    recorded automatically such as your computer’s operating
                    system, Internet Protocol (IP) address, access times,
                    browser type and language, and the website you visited
                    before our corporate sites, so we are aware of transfers and
                    linking for security due diligence (blocking and enhancing)
                    of our site and to protect you the user. This is logged
                    automatically and stored in log files. We also collect
                    information about your usage and activity on our corporate
                    sites. We may tie your IP address to information we
                    automatically collect on our corporate sites. We may also
                    tie information we automatically collect with personal
                    information, such as your login ID and information you give
                    us for a registration. We use our own products, and products
                    of third parties acting on our behalf, to analyze, optimize,
                    securely protect and improve our site.
                </li>
            </ul>
            <p>&nbsp;</p>
            <ul>
                <li>
                    GP will also collect information on your usage to ensure the
                    security of the data we collect on behalf of our clients.
                    You cannot opt-out of this collection and processing as it
                    is necessary to ensure the security of the service we
                    provide for our sites and for our clients.
                </li>
            </ul>
            <p>&nbsp;</p>
            <ul>
                <li>
                    GP Strategies may also use device-recognition technologies
                    combined with other identifiers to create cross-browsers and
                    cross-devices identities to provide you with better services
                    and security.
                </li>
            </ul>
            <h3>
                <strong>
                    In GP Strategies’ Commitment to data protection what steps
                    have been taken to protect my data?
                </strong>
            </h3>
            <p>
                GP Strategies initiated a data privacy data management
                compliance review and update effort beginning in October 2017 to
                ensure alignment with the May 2018 requirements of the European
                Union (EU) General Data Protection Regulation (GDPR) and other
                similar privacy data regulatory obligations coming into force
                around the world like Privacy Shield and the California Consumer
                Privacy Act (CCPA). GP was certified into the Privacy Shield
                regime by the U.S. Department of Commerce in 2017. These efforts
                are Companywide, ongoing and driven by a chartered Committee
                sponsored by senior corporate leaders. We meet regulatory
                requirements, to include these examples:
            </p>
            <ul>
                <li>
                    We have not identified any information technology shortfalls
                    in data privacy transmission and storage encryption.
                </li>
                <li>
                    The standing Data Privacy and Records Management Committee
                    meets regularly and has web based information sites
                    available to employees and for Program administration where
                    it keeps its meeting records and products.
                </li>
                <li>
                    Privacy data owners’ storage information submissions are
                    being continuously catalogued and updated.
                </li>
                <li>
                    Standard contract language amendments for
                    subcontractors/processors are in use.
                </li>
                <li>
                    Frequently asked data privacy questions (FAQ) documents to
                    enhance transparency and privacy data handling awareness are
                    available to employees on our internal portal.
                </li>
                <li>
                    We periodically evaluate our privacy policy information
                    statement status and take enhancement actions as required
                    for all of our worldwide web and social media sites.
                </li>
                <li>
                    In December 2017 GP obtained EU-U.S. Privacy Shield
                    certification for the international transfer of EU privacy
                    data. To learn more about the Privacy Shield Framework, and
                    to view our certification, visit the U.S. Department of
                    Commerce Privacy Shield web site.
                </li>
                <li>
                    In December 2017 GP attained UK Government Cyber Essentials
                    certification.
                </li>
                <li>
                    We conduct employee data privacy awareness training
                    annually.
                </li>
                <li>
                    If you need additional elaboration on Committee actions
                    please{' '}
                    <a href="https://www.gpstrategies.com/contact-us/?src=privacypolicy">
                        contact us
                    </a>
                    .
                </li>
            </ul>
            <p>
                In addition to efforts by the Committee, GP Strategies employs
                security professionals and takes technical and organizational
                measures designed to prevent unauthorized access, use,
                alteration, or disclosure of privacy data collected via GP
                sites. Further, GP has more than 30 years of experience in
                operating highly-secured solutions with security controls that
                are continuously updated to meet industry standards and address
                emerging threats. This is described in detail in our information
                technology (IT) policies and procedures. Also see our IT fact
                sheet on information security practices.
            </p>
            <h3>
                <strong>EU-U.S. and Swiss-U.S. Privacy Shield Framework</strong>
            </h3>
            <p>
                GP Strategies complies with the EU-U.S. Privacy Shield Framework
                and the Swiss-U.S. Privacy Shield Framework(s) (Privacy Shield)
                as set forth by the U.S. Department of Commerce regarding the
                collection, use, and retention of personal information
                transferred from the European Union, the United Kingdom and
                Switzerland to the United States in reliance on Privacy Shield.
                GP Strategies has certified to the Department of Commerce that
                it adheres to the Privacy Shield Principles with respect to such
                information. If there is any conflict between the terms in this
                privacy policy and the Privacy Shield Principles, the Privacy
                Shield Principles shall govern.
            </p>
            <p>
                GP Strategies is committed to cooperate with EU data protection
                authorities (DPAs) and the Swiss Federal Data Protection and
                Information Commissioner (FDPIC) and comply with the advice
                given by such authorities with regard to human resources data
                transferred from the EU and Switzerland in the context of
                employment relationship and/or a business services relationship.
                To learn more about the Privacy Shield program, and to view our
                certification, please visit{' '}
                <a href="https://www.privacyshield.gov/">
                    https://www.privacyshield.gov/
                </a>
                .
            </p>
            <p>
                GP Strategies has further committed to cooperate with the panel
                established by the EU DPAs and the FDPIC with regard to
                unresolved Privacy Shield complaints. Such complaints involving
                GP human resources, web and social media and client related
                privacy data transferred to the U.S. will be reviewed in the
                context of the employment relationship and/or a business
                services relationship.
            </p>
            <p>
                In the conduct of our business to meet the international Privacy
                Shield requirements:
            </p>
            <ul>
                <li>We encrypt our data in transit and in storage.</li>
                <li>
                    In our delivery of training activities and consulting
                    services we are sometimes contractually required to conduct
                    business operations processing wherein we collect client
                    employee, vendor and client customer privacy data on behalf
                    of our clients and for our internal services processes. We
                    also may collect limited categories of privacy data from
                    individuals that visit our web and social media sites. This
                    information is stored in secure sites.
                </li>
                <li>
                    We use or intermittently use contracted assistance to
                    process GP payroll, human resources and related employee
                    services and in doing so we necessarily disclose personal
                    information to these third parties to accomplish these
                    processing operations.
                </li>
                <li>
                    We acknowledge that individuals have the right to inquire
                    about and access their data.
                </li>
                <li>
                    GP Strategies is required to disclose personal information
                    in response to lawful requests by public authorities,
                    including to meet national security or law enforcement
                    requirements.
                </li>
                <li>
                    We make efforts to limit the collection, use and disclosure
                    of personal data to help protect personal privacy.
                </li>
                <li>
                    We will discuss with you your specific request to limit use
                    of your data if you choose to restrict the use of privacy
                    data. We most likely cannot make exceptions for required
                    legal, regulatory, contractual and personal public safety
                    contact information erasure requests. Personal contact
                    information may be used to contact you or family members in
                    the event of an emergency to warn you and others of a
                    dangerous safety situation. Such discussions will include
                    the alternate processes and consequences of the alternative
                    processing methods available to accomplish the same
                    necessary services affected by your request, up to and
                    including deleting data if that is an option. Your services
                    may be slowed by your requested processing restrictions.
                </li>
                <li>
                    GP Strategies is subject to the investigatory and
                    enforcement powers of the Federal Trade Commission (FTC).
                </li>
                <li>
                    An individual has the possibility, under certain conditions,
                    for the individual to invoke binding arbitration when
                    seeking more restrictive data privacy remedies from GP
                    Strategies.
                </li>
                <li>
                    GP Strategies understands that it may not be absolved of
                    liability in cases of onward transfers of privacy data to
                    third parties.
                </li>
            </ul>
            <h3>
                <strong>California Consumer Privacy Act (CCPA)</strong>
            </h3>
            <h4>
                <strong>
                    What is considered “personal information” under the CCPA?
                </strong>
            </h4>
            <p>
                “Personal information” is defined in the California law. CCPA
                categories are expanded beyond those in the EU General Data
                Protection Regulation (GDPR). In the law, personal information
                is any “information that identifies, relates to, describes, is
                capable of being associated with, or could reasonably be linked,
                directly or indirectly, with any particular consumer.”
                “Consumer” is a defined term in the CCPA similar to “data
                subject” as used in the GDPR, however it is not an exact
                matching definition.
            </p>
            <h4>
                <strong>
                    As a California citizen does GP Strategies sell my data?
                </strong>
            </h4>
            <p>
                By Company Policy GP-C-18, GP Strategies does Not Sell or Buy
                Personal Information and has no intent to start. This “sale of
                consumer privacy data” element of the CCPA (and its regulations)
                is a major section that has limited impact on GP Strategies due
                to our Policy position. Therefore, for example, GP strategies is
                not required to have a separate landing page for California
                consumers that addresses the sales of their personal data.
            </p>
            <h4>
                <strong>
                    Does GP Strategies honor the “rights” of consumers in the
                    CCPA?
                </strong>
            </h4>
            <p>
                By our Privacy Policy, GP Strategies does honor the rights of
                California consumers as specified in the CCPA and the rights of
                citizens of other U.S. states where they may be more stringent
                or different than the European Union General Data Protection
                Regulation (GDPR).
            </p>
            <h3>
                <strong>Additional Information</strong>
            </h3>
            <p>
                In compliance with the Privacy Shield Principles, the CCPA among
                other laws and the GDPR, GP Strategies commits to respond to
                inquiries about our collection or use of your personal
                information. EU, UK, Switzerland, U.S. and individuals worldwide
                with inquiries regarding our privacy policy should contact us
                at:
            </p>
            <h3></h3>
            <p>
                <strong>
                    GP Strategies Global Privacy Office, GP Strategies
                    Corporation
                </strong>
            </p>
            <p>
                <strong>
                    70 Corporate Center, Suite 200, 11000 Broken Land Pkwy,{' '}
                </strong>
            </p>
            <p>
                <strong>Colombia, MD 21044 USA</strong>
            </p>
            <p>
                <strong>OR: </strong>
                <a href="mailto:dataprivacy@gpstrategies.com">
                    dataprivacy@gpstrategies.com
                </a>
            </p>
            <p>&nbsp;</p>
            <p>
                <a
                    href="http://www.gpstrategies.com/wp-content/uploads/2020/01/GP-Strategies-Personal-Data-Privacy-Policy-2020.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    GP Strategies Personal Data Privacy and Records Management
                    Policy
                </a>
            </p>
            <p>
                <a
                    href="http://www.gpstrategies.com/wp-content/uploads/2018/04/Web-Social-Sites-Data-Privacy-FAQ-March-2018-v2.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    Web and Social Media Data Privacy and Records Management
                    Information Frequently Asked Questions
                </a>
            </p>
            <p>
                <a
                    href="http://www.gpstrategies.com/wp-content/uploads/2018/04/Fact-Sheet-Social-Media-Sites-04.16.18.pdf"
                    target="_blank"
                    rel="noreferrer"
                >
                    GP Strategies Information Technology Security Practices
                    &amp; Data Privacy
                </a>
            </p>
        </LegalContainer>
    );
}
