import React, { useMemo } from 'react';
import {
    TextField,
    Label,
    Text,
    ITextFieldProps,
    Icon,
    mergeStyleSets,
    useTheme,
} from '@fluentui/react';

import debounce from 'lodash/debounce';
import { StatusReportGridPreviousReportButton } from './StatusReportGridPreviousReportButton';

export function StatusReportGridTextBox(props: {
    label?: string;
    placeholder?: string;
    defaultValue?: string;
    onChange: (newValue: string) => void;
    isReadOnly: boolean;
    previousText?: string | null;
    previousStatusReport?: {
        id: string | null;
        reportDate: string | null;
        title: string | null;
    } | null;
}): JSX.Element {
    const { onChange } = props;

    const currentTheme = useTheme();

    const debouncedChange = useMemo(
        () =>
            debounce(
                (_ev: React.FormEvent, newValue?: string | undefined) =>
                    onChange(newValue || ''),
                1000
            ),
        [onChange]
    );

    const classNames = mergeStyleSets({
        boxHeader: {
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
            alignItems: 'center',
        },
        boxHeaderLabel: {
            flexGrow: 1,
        },
        readOnlyContainer: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        readOnlyContent: {
            flexGrow: 1,
            backgroundColor: currentTheme.palette.white,
            borderRadius: 4,
            padding: 4,
        },
    });

    const handleRenderLabel = (
        textFieldProps?: ITextFieldProps,
        defaultRender?: (props?: ITextFieldProps) => JSX.Element | null
    ) => {
        return (
            <div className={classNames.boxHeader}>
                {!!props.label && <Icon iconName="DoubleChevronRight8" />}
                <div className={classNames.boxHeaderLabel}>
                    {defaultRender && defaultRender(textFieldProps)}
                </div>
                <StatusReportGridPreviousReportButton {...props} />
            </div>
        );
    };

    if (props.isReadOnly) {
        return (
            <div className={classNames.readOnlyContainer}>
                <div className={classNames.boxHeader}>
                    {!!props.label && <Icon iconName="DoubleChevronRight8" />}
                    <div className={classNames.boxHeaderLabel}>
                        <Label>{props.label}</Label>
                    </div>
                </div>
                <div className={classNames.readOnlyContent}>
                    <Text variant="medium" style={{ whiteSpace: 'pre-wrap' }}>
                        {props.defaultValue}
                    </Text>
                </div>
            </div>
        );
    } else {
        return (
            <TextField
                styles={{
                    root: {
                        height: '100%',
                    },
                    field: {
                        height: '100%',
                        padding: props.isReadOnly ? 0 : undefined,
                        border: 'none',
                    },
                    fieldGroup: {
                        minHeight: 80,
                        border: 'none',
                        flexGrow: 1,
                    },
                    wrapper: {
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    },
                }}
                multiline
                autoAdjustHeight
                rows={2}
                onRenderLabel={handleRenderLabel}
                label={props.label}
                placeholder={props.isReadOnly ? undefined : props.placeholder}
                defaultValue={props.defaultValue}
                borderless={props.isReadOnly}
                readOnly={props.isReadOnly}
                onChange={debouncedChange}
            />
        );
    }
}
