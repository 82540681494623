import { useEffect, useState } from 'react';
import { photoService } from '../services/photo.service';

interface HasPhotoResult {
    hasPhoto: boolean;
    setHasPhoto: (value: boolean) => void;
}

export const useHasPhoto = (
    userId: string | undefined,
    enabled: boolean
): HasPhotoResult => {
    const [hasPhoto, setHasPhoto] = useState(false);

    useEffect(() => {
        if (enabled && userId) {
            photoService.hasPhotoAsync(userId).then((result) => {
                setHasPhoto(result);
            });
        } else {
            setHasPhoto(false);
        }
    }, [userId, enabled]);

    return { hasPhoto, setHasPhoto };
};

export default useHasPhoto;
