import React from 'react';
import { NewComment } from './NewComment';
import {
    Panel,
    PanelType,
    Text,
    Stack,
    Shimmer,
    ShimmerElementType,
} from '@fluentui/react';
import { CommentsList } from './CommentsList';
import { useStateContext } from '../services/contextProvider';
import { useGetMeasureQuery, useGetTaskQuery } from '../data/types';
import { useActiveView } from '../hooks/useActiveView';

type CommentsPanelProps = {
    showPanel: boolean;
    measureId?: string | null | undefined;
    taskId?: string | null | undefined;
    onDismiss: () => void;
};

export default function CommentsPanel(props: CommentsPanelProps): JSX.Element {
    const { currentTenantId, currentUserId } = useStateContext();

    const { data: measureData, loading: measureLoading } = useGetMeasureQuery({
        skip: !props.measureId || !props.showPanel,
        variables: {
            tenantId: currentTenantId || '',
            id: props.measureId || '',
        },
    });

    const { data: taskData, loading: taskLoading } = useGetTaskQuery({
        skip: !props.taskId || !props.showPanel,
        variables: {
            tenantId: currentTenantId || '',
            id: props.taskId || '',
        },
    });

    const mission =
        (measureData?.measure?.isLinked
            ? measureData?.measure?.linkedFromMeasure?.mission
            : measureData?.measure?.mission) || taskData?.task?.mission;

    const activeView =
        props.showPanel && props.measureId
            ? 'MeasureComments'
            : props.showPanel && props.taskId
              ? 'TaskComments'
              : null;

    useActiveView(activeView);

    const isLoading = measureLoading || taskLoading;

    return (
        <Panel
            isOpen={props.showPanel}
            type={PanelType.smallFixedFar}
            onDismiss={props.onDismiss}
            headerText="Comments"
            closeButtonAriaLabel="Close"
        >
            <Stack tokens={{ childrenGap: 8 }}>
                <Stack.Item>
                    <Shimmer
                        isDataLoaded={!isLoading}
                        shimmerElements={[
                            {
                                type: ShimmerElementType.line,
                                height: 36,
                            },
                        ]}
                    >
                        <Text style={{ whiteSpace: 'pre-line' }}>
                            {!!props.measureId && measureData?.measure?.name}
                            {!!props.taskId && taskData?.task?.name}
                        </Text>
                    </Shimmer>
                </Stack.Item>
                <Stack.Item>
                    {!isLoading && !!props.measureId && (
                        <NewComment
                            measureId={
                                measureData?.measure?.isLinked &&
                                measureData.measure.linkedFromMeasureId
                                    ? measureData.measure.linkedFromMeasureId
                                    : props.measureId
                            }
                            missionId={measureData?.measure?.missionId}
                            refetchMeasureId={props.measureId}
                        />
                    )}
                    {!isLoading && !!props.taskId && (
                        <NewComment
                            taskId={props.taskId}
                            missionId={taskData?.task?.missionId}
                        />
                    )}
                </Stack.Item>
                <Stack.Item grow>
                    {!isLoading && (
                        <CommentsList
                            {...props}
                            isOwnerOfCommentItem={
                                mission?.userId === currentUserId
                            }
                        />
                    )}
                </Stack.Item>
            </Stack>
        </Panel>
    );
}
