import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@fluentui/react';
import { AdvanceCard } from '../../components/AdvanceCard';
import { useActiveView } from '../../hooks/useActiveView';
import { useStateContext } from '../../services/contextProvider';
import { AreaContainer } from '../MissionBuilder/components/AreaContainer';
import { useCurrentTenant } from '../../hooks/useCurrentTenant';
import { EditPanels, PanelTypes } from '../../components/EditPanels';
import { useState } from 'react';
import { ReportsPivot } from './Components/ReportsPivot';
import { ReportNames } from './ReportNames';

export default function Reports(): JSX.Element {
    const { reportName } = useParams();
    const { currentFinancialYearCode } = useStateContext();
    const currentTenant = useCurrentTenant();

    const [activePanel, setActivePanel] = useState<PanelTypes>(null);
    const [activeMissionId, setActiveMissionId] = useState<string | null>();
    const [activeTaskId, setActiveTaskId] = useState<string | null>();
    const [activeMeasureId, setActiveMeasureId] = useState<string | null>();

    useActiveView('Reports', 'Reports');

    const handleTaskClick = (missionId: string, taskId: string): void => {
        setActiveMissionId(missionId);
        setActiveMeasureId(null);
        setActiveTaskId(taskId);
        setActivePanel('edit-task');
    };

    const handleMeasureClick = (missionId: string, measureId: string): void => {
        setActiveMissionId(missionId);
        setActiveMeasureId(measureId);
        setActiveTaskId(null);
        setActivePanel('measure-overview');
    };

    const onActivePanelChanged = (panelType: PanelTypes): void => {
        setActivePanel(panelType);
    };

    const onActiveTaskChanged = (taskId: string | null) => {
        setActiveTaskId(taskId);
    };

    const selectedReport = (reportName as ReportNames) || 'tasks';

    const subTitle = `${
        currentTenant?.description
    } - ${currentFinancialYearCode?.toLocaleUpperCase()}`;

    return (
        <React.Fragment>
            <EditPanels
                activePanel={activePanel}
                missionId={activeMissionId}
                measureId={activeMeasureId}
                taskId={activeTaskId}
                teamId={null}
                onActivePanelChanged={onActivePanelChanged}
                onActiveTaskChanged={onActiveTaskChanged}
            />
            <Stack
                tokens={{
                    padding: 0,
                    childrenGap: 8,
                }}
            >
                <AreaContainer
                    title="Reports"
                    isCollapsable={false}
                    subTitle={subTitle}
                >
                    <Stack tokens={{ padding: 8 }}>
                        <AdvanceCard>
                            <AdvanceCard.Item>
                                <ReportsPivot
                                    reportName={selectedReport}
                                    onTaskClick={handleTaskClick}
                                    onMeasureClick={handleMeasureClick}
                                />
                            </AdvanceCard.Item>
                        </AdvanceCard>
                    </Stack>
                </AreaContainer>
            </Stack>
        </React.Fragment>
    );
}
