import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Slider, Stack, Label, IconButton } from '@fluentui/react';
import { Area } from 'react-easy-crop';

function readFile(file: File): Promise<string | null | ArrayBuffer> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
}

type ProfilePhotoInputProps = {
    onPhotoChanged: (
        imageSrc: string | undefined,
        pixelCrop: Area,
        rotation: number
    ) => void;
};

export function ProfilePhotoInput(props: ProfilePhotoInputProps): JSX.Element {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [imageSrc, setImageSrc] = useState<string | undefined>();

    const onCropComplete = (
        _croppedArea: Area,
        croppedAreaPixels: Area
    ): void => {
        props.onPhotoChanged(imageSrc, croppedAreaPixels, rotation);
    };

    const onFileChange = async (
        e: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const imageDataUrl = await readFile(file);

            if (imageDataUrl) {
                setImageSrc(imageDataUrl as string);
                setCrop({ x: 0, y: 0 });
                setZoom(1);
            }
        }
    };

    const rotateClockwise = () => {
        setRotation(rotation + 90);
    };

    const rotateAntiClockwise = () => {
        setRotation(rotation - 90);
    };

    return (
        <Stack tokens={{ childrenGap: 8 }}>
            <Label>Profile Photo</Label>

            <input type="file" onChange={onFileChange} />

            {imageSrc && (
                <Stack.Item>
                    <div style={{ position: 'relative', height: 300 }}>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            rotation={rotation}
                            aspect={1}
                            cropShape="round"
                            showGrid={false}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            onRotationChange={setRotation}
                        />
                    </div>
                    <Stack horizontal>
                        <Stack.Item grow>
                            <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={setZoom}
                            />
                        </Stack.Item>

                        <IconButton
                            iconProps={{
                                iconName: 'Rotate90CounterClockwise',
                            }}
                            onClick={rotateAntiClockwise}
                        />
                        <IconButton
                            iconProps={{
                                iconName: 'Rotate90Clockwise',
                            }}
                            onClick={rotateClockwise}
                        />
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
}
