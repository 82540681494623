import * as React from 'react';
import { Stack } from '@fluentui/react';
import { useStateContext } from '../../../services/contextProvider';
import {
    DefaultMissionFilters,
    MissionFilterBar,
    MissionFilters,
} from '../../MissionBuilder/components/MissionFilterBar';
import { AlignmentList } from '../../../components/AlignmentList';

export function ReportAlignment(): JSX.Element {
    const { currentTenantId, currentTenantCode, currentFinancialYearCode } =
        useStateContext();

    const [filters, setFilters] = React.useState<MissionFilters>(
        DefaultMissionFilters
    );

    const handleFiltersChanged = (f: MissionFilters) => setFilters(f);

    return (
        <Stack tokens={{ childrenGap: 4 }} styles={{ root: { marginTop: 8 } }}>
            <Stack.Item>
                <MissionFilterBar
                    filters={filters}
                    onFiltersChanged={handleFiltersChanged}
                    searchBoxPlaceholder={'Filter by mission or keyword'}
                />
            </Stack.Item>
            {!!currentTenantId &&
                !!currentTenantCode &&
                !!currentFinancialYearCode && (
                    <AlignmentList
                        filters={filters}
                        tenantId={currentTenantId}
                        tenantCode={currentTenantCode}
                        financialYearCode={currentFinancialYearCode}
                        initialSort={['owner']}
                        defaultColumns={[
                            'displaySequence',
                            'missionName',
                            'measureCount',
                            'specifiedTaskCount',
                            'impliedTaskCount',
                            'outstandingDependencyCount',
                            'latestStatusReport',
                        ]}
                    />
                )}
        </Stack>
    );
}
