import * as React from 'react';

import {
    ShimmeredDetailsList,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    IconButton,
    Toggle,
} from '@fluentui/react';

import dayjs from 'dayjs';

import {
    useGetFinancialYearsQuery,
    FinancialYearQl,
    useUpdateFinancialYearMutation,
    FinancialYear,
} from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import { useSorter } from '../../../hooks/useSorter';
import { DetailsListCellItemContainer } from '../../../components/shared/DetailsListCellItemContainer';

type FinancialYearListProps = {
    onItemEdit: (financialYear: FinancialYearQl) => void;
    onCopyStructure: (financialYear: FinancialYearQl) => void;
};

export function FinancialYearList(props: FinancialYearListProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { data, loading } = useGetFinancialYearsQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
        },
    });

    const [updateFinancialYear] = useUpdateFinancialYearMutation({
        refetchQueries: ['GetFinancialYears'],
    });

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(data?.financialYears, 'code', false, true);

    const renderDateColumn = (
        date: string | Date | undefined | null
    ): JSX.Element => (
        <span>{date ? dayjs.utc(date).format('DD MMM YYYY') : ' - '}</span>
    );

    const changeActive = (fyId: string, isChecked: boolean): void => {
        const fy = data?.financialYears?.find((f) => f.id === fyId);

        if (fy?.id) {
            updateFinancialYear({
                variables: {
                    tenantId: currentTenantId || '',
                    input: {
                        ...fy,
                        utcInactive: isChecked ? null : new Date(),
                    } as FinancialYear,
                },
                optimisticResponse: {
                    __typename: 'Mutations',
                    financialYearUpdate: {
                        ...fy,
                        utcInactive: isChecked
                            ? null
                            : new Date().toISOString(),
                    },
                },
            });
        }
    };

    const columns: IColumn[] = [
        {
            key: 'code',
            name: 'FY',
            fieldName: 'code',
            isRowHeader: true,
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            isSorted: sortColumn === 'code',
            isSortedDescending: sortColumn === 'code' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('code', true),
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 200,
            isResizable: true,
            isSorted: sortColumn === 'name',
            isSortedDescending: sortColumn === 'name' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('name', true),
        },
        {
            key: 'startDate',
            name: 'Start',
            fieldName: 'startDate',
            minWidth: 80,
            isResizable: true,
            onRender: (item: FinancialYearQl): JSX.Element =>
                renderDateColumn(item?.startDate),
            isSorted: sortColumn === 'startDate',
            isSortedDescending: sortColumn === 'startDate' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('startDate', true),
        },
        {
            key: 'endDate',
            name: 'End',
            fieldName: 'endDate',
            minWidth: 80,
            isResizable: true,
            onRender: (item: FinancialYearQl): JSX.Element =>
                renderDateColumn(item?.endDate),
            isSorted: sortColumn === 'endDate',
            isSortedDescending: sortColumn === 'endDate' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('endDate', true),
        },
        {
            key: 'active',
            name: 'Active?',
            fieldName: 'utcInactive',
            minWidth: 100,
            isResizable: true,
            isSorted: sortColumn === 'utcInactive',
            isSortedDescending: sortColumn === 'utcInactive' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('utcInactive'),
            onRender: function renderEnabled(item: {
                id: string;
                utcInactive: boolean;
            }): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        <Toggle
                            onText="Yes"
                            offText="No"
                            checked={!item.utcInactive}
                            onChange={(
                                _ev: React.MouseEvent<HTMLElement>,
                                checked?: boolean
                            ): void => {
                                changeActive(item.id, checked || false);
                            }}
                            styles={{
                                root: {
                                    marginBottom: 0,
                                },
                            }}
                        />
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'actions',
            name: '',
            minWidth: 32,
            maxWidth: 32,
            isIconOnly: true,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderActions(
                item: FinancialYearQl
            ): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        <IconButton
                            key={item.id}
                            iconProps={{ iconName: 'Edit' }}
                            onClick={(): void => {
                                props.onItemEdit(item);
                            }}
                        />
                        {/* <IconButton
                            iconProps={{ iconName: 'UnstackSelected' }}
                            onClick={(): void => {
                                props.onCopyStructure(item);
                            }}
                        /> */}
                    </DetailsListCellItemContainer>
                );
            },
        },
    ];

    function renderItemColumn(
        item?: FinancialYearQl,
        _index?: number,
        column?: IColumn
    ): JSX.Element {
        if (!column || !item) {
            return <span />;
        }
        return (
            <DetailsListCellItemContainer>
                {
                    item[column.fieldName as keyof FinancialYearQl] as
                        | React.ReactNode
                        | string
                }
            </DetailsListCellItemContainer>
        );
    }

    return (
        <ShimmeredDetailsList
            setKey="items"
            items={sortedItems}
            columns={columns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            enableShimmer={loading}
            ariaLabelForShimmer="Content is being fetched"
            ariaLabelForGrid="Item details"
            shimmerLines={1}
            listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
            onItemInvoked={(item?: FinancialYearQl): void => {
                if (item) {
                    props.onItemEdit(item);
                }
            }}
            onShouldVirtualize={(): boolean => false}
            onRenderItemColumn={renderItemColumn}
        />
    );
}
