import React, { PropsWithChildren } from 'react';
import { Link, Persona, PersonaSize, mergeStyleSets } from '@fluentui/react';
import { photoService } from '../services/photo.service';

export function MergeControlContainer(
    props: PropsWithChildren<{
        fieldName: string;
        allowAccept: boolean;
        isDismissed: boolean;
        onAccept: (fieldName: string) => void;
        onDismiss: (fieldName: string) => void;
        resourcedFromTask:
            | {
                  mission: {
                      owner: string | null;
                      title: string | null;
                      userId: string | null;
                  } | null;
              }
            | null
            | undefined;
    }>
): JSX.Element {
    const {
        children,
        fieldName,
        resourcedFromTask,
        allowAccept,
        isDismissed,
        onAccept,
        onDismiss,
    } = props;

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        mergeButtons: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    });

    return (
        <div className={classNames.container}>
            <Persona
                styles={{ root: { marginBottom: 4 } }}
                hidePersonaDetails
                size={PersonaSize.size24}
                text={`${resourcedFromTask?.mission?.owner} - ${resourcedFromTask?.mission?.title}`}
                imageUrl={photoService.getImageUrl(
                    resourcedFromTask?.mission?.userId
                )}
            />
            {children}
            {!isDismissed && (
                <div className={classNames.mergeButtons}>
                    <div>
                        {allowAccept && (
                            <Link onClick={() => onAccept(fieldName)}>
                                Allow Changes
                            </Link>
                        )}
                    </div>
                    <div>
                        <Link onClick={() => onDismiss(fieldName)}>
                            {allowAccept ? 'Dismiss' : 'Acknowledge'}
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
}
