import React, { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { Checkbox, mergeStyleSets } from '@fluentui/react';
import { useStateContext } from '../services/contextProvider';
import {
    useGetMeasureGroupsQuery,
    useGetMissionMeasuresQuery,
} from '../data/types';
import Loading from './Loading';

type LinkedMeasuresSelectorProps = {
    missionId: string | null | undefined;
    linkedMeasureIds: string[];
    onSelectionChanged: (selectedMeasureIds: string[]) => void;
    disabled?: boolean;
};

export function LinkedMeasuresSelector(
    props: LinkedMeasuresSelectorProps
): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [selectedMeasureIds, setSelectedMeasureIds] = useState<string[]>([]);

    useEffect(() => {
        setSelectedMeasureIds(props.linkedMeasureIds);
    }, [props.linkedMeasureIds]);

    const { loading, data } = useGetMissionMeasuresQuery({
        skip: !props.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId || '',
        },
    });

    const { loading: groupsLoading, data: groupData } =
        useGetMeasureGroupsQuery({
            skip: !props.missionId || !currentTenantId,
            variables: {
                tenantId: currentTenantId || '',
                missionId: props.missionId || '',
            },
        });

    if (!data?.measures || loading || groupsLoading) {
        return <Loading />;
    }

    if (data?.measures && !loading && data?.measures.length === 0) {
        return <span>There are no measures of success.</span>;
    }

    const measures = orderBy(data.measures, 'sequence');
    const measureGroups = orderBy(groupData?.measureGroups, 'sequence');

    const onCheckChange = (measureId: string, isChecked: boolean) => {
        const updatedSelection = selectedMeasureIds
            .slice()
            .filter((id) => id !== measureId);

        if (isChecked) {
            updatedSelection.push(measureId);
        }

        setSelectedMeasureIds(updatedSelection);

        props.onSelectionChanged(updatedSelection);
    };

    const measureGroupDisplayList: {
        id: string | null;
        name: string | null;
    }[] = (measureGroups || [])
        .filter((mg) => measures?.some((m) => m.measureGroupId === mg.id))
        .map((mg) => ({
            id: mg.id,
            name: mg.name,
        }));

    if (measures?.some((m) => !m.measureGroupId)) {
        measureGroupDisplayList?.push({
            id: null,
            name: measureGroupDisplayList.length ? 'Ungrouped' : null,
        });
    }

    const classNames = mergeStyleSets({
        container: {
            paddingTop: 8,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        measureCheckboxes: {
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
        },
    });

    return (
        <div className={classNames.container}>
            {measureGroupDisplayList.map((mg) => {
                return (
                    <div
                        key={mg.id || 'nogroup'}
                        className={classNames.measureCheckboxes}
                    >
                        {!!mg.name && <strong>{mg.name}</strong>}
                        {measures
                            .filter((m) => m.measureGroupId === mg.id)
                            .map((m) => {
                                const checked = !!selectedMeasureIds.find(
                                    (id) => id === m.id
                                );
                                const onChange = (
                                    _ev?: React.FormEvent,
                                    isChecked?: boolean
                                ) =>
                                    onCheckChange(
                                        m.id || '',
                                        isChecked || false
                                    );
                                return (
                                    <Checkbox
                                        key={m.id}
                                        label={m.name || 'Unnamed'}
                                        checked={checked}
                                        disabled={props.disabled}
                                        onChange={onChange}
                                    />
                                );
                            })}
                    </div>
                );
            })}
        </div>
    );
}
