import React, { CSSProperties } from 'react';
import { ActionButton } from '@fluentui/react';
import { useThemes } from '../hooks/useThemes';

type NewTaskCardProps = {
    onAddButtonClick: () => void;
};

export default function NewTaskCard(props: NewTaskCardProps): JSX.Element {
    const { currentTheme } = useThemes();

    const placeholerStyle: CSSProperties = {
        border: `dashed 2px ${currentTheme.palette.neutralLighterAlt}`,
        borderRadius: 8,
        minHeight: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <div style={placeholerStyle}>
            <ActionButton
                iconProps={{ iconName: 'Add' }}
                text="Specified Task"
                onClick={props.onAddButtonClick}
            />
        </div>
    );
}
