import React from 'react';

import {
    mergeStyleSets,
    Shimmer,
    ShimmerElementType,
    Text,
} from '@fluentui/react';
import {
    GetMeasurePeriodDataQuery,
    GetMeasuresForStatusReportQuery,
} from '../../data/types';
import {
    ExtractQueryArrayType,
    ExtractQueryType,
} from '../../data/extendedTypes';
import { ReportPeriodDataActualWarning } from './ReportPeriodDataActualWarning';

export function ReportPeriodDataActualBox(props: {
    periodData:
        | ExtractQueryType<
              GetMeasurePeriodDataQuery,
              ['periodData', 'measurePeriodData']
          >
        | undefined
        | null;
    measure: ExtractQueryArrayType<
        GetMeasuresForStatusReportQuery,
        ['measures']
    > | null;
    ytdPeriodData?:
        | ExtractQueryType<
              GetMeasurePeriodDataQuery,
              ['periodData', 'measurePeriodData']
          >
        | undefined
        | null;
    fyStartDate: string | null | undefined;
    isLoading?: boolean;
    onWarningClick: (() => void) | null;
}) {
    const { periodData, ytdPeriodData, isLoading } = props;

    const classNames = mergeStyleSets({
        container: {
            flexGrow: 1,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
        },
        actual: {
            display: 'flex',
            gap: 8,
            alignItems: 'center',
        },
        warning: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
        },
    });

    return (
        <div className={classNames.container}>
            <Shimmer
                isDataLoaded={!isLoading}
                shimmerElements={[
                    {
                        type: ShimmerElementType.line,
                        height: 42,
                    },
                ]}
            >
                <div className={classNames.content}>
                    <Text variant="tiny" block>
                        Actual{' '}
                        {periodData?.period ? `(${periodData?.period})` : null}
                    </Text>
                    <div className={classNames.actual}>
                        <Text variant="large">
                            {periodData?.actualFormatted !== null
                                ? periodData?.actualFormatted
                                : 'None'}
                        </Text>

                        <div className={classNames.warning}>
                            <ReportPeriodDataActualWarning
                                {...props}
                                onWarningClick={props.onWarningClick}
                            />
                        </div>
                    </div>
                    {ytdPeriodData?.actualFormatted && (
                        <Text variant="small" block>
                            {ytdPeriodData?.actualFormatted} (YTD)
                        </Text>
                    )}
                </div>
            </Shimmer>
        </div>
    );
}
