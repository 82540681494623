import React from 'react';
import orderBy from 'lodash/orderBy';

import {
    ActionButton,
    ContextualMenuItemType,
    IButtonProps,
    IContextualMenuItem,
    Persona,
    PersonaSize,
    Stack,
} from '@fluentui/react';
import { photoService } from '../../../services/photo.service';

export function TaskFilterResourcedFromSelector(props: {
    tasks: {
        resourcedFromTask: {
            mission: {
                id: string | null;
                userId: string | null;
                owner: string | null;
            } | null;
        } | null;
    }[];
    selectedMissionIds: string[];
    onResourcedFromFilterChanged: (missionIds: string[]) => void;
}): JSX.Element | null {
    const { tasks, selectedMissionIds, onResourcedFromFilterChanged } = props;

    const handleFilterClick = (missionId: string | null | undefined) => {
        if (!missionId) {
            return;
        }

        const alreadySelectedItem = selectedMissionIds?.some(
            (mid) => mid === missionId
        );

        let updated: string[] = [];
        if (alreadySelectedItem) {
            updated = selectedMissionIds.filter((sr) => sr !== missionId);
        } else {
            updated = [...(selectedMissionIds || []), missionId];
        }
        onResourcedFromFilterChanged(updated);
    };

    const resourcedFromMissions = tasks
        .filter((t) => t.resourcedFromTask?.mission)
        .map((t) => t.resourcedFromTask?.mission);

    const uniqueMissions = resourcedFromMissions
        .filter((c, i, self) => self.findIndex((m) => m?.id === c?.id) === i)
        .map((m) => {
            return {
                ...m,
                checked:
                    selectedMissionIds?.some((mid) => mid === m?.id) || false,
            };
        });

    const menuItems: IContextualMenuItem[] = orderBy(
        uniqueMissions,
        'owner'
    ).map((m) => {
        return {
            key: m?.id ?? '',
            text: m?.owner || '',
            canCheck: true,
            checked: m.checked,
            iconProps: {
                iconName: 'User',
            },
            onRenderIcon: () =>
                renderPersonaIcon(m?.userId || null, m.owner || ''),
            onClick: () => handleFilterClick(m.id),
        };
    });

    if (selectedMissionIds.length > 0) {
        menuItems.push(
            ...[
                {
                    key: 'clear-divider',
                    itemType: ContextualMenuItemType.Divider,
                },
                {
                    key: 'clear',
                    text: 'Clear',
                    iconProps: {
                        iconName: 'Cancel',
                    },
                    onClick: () => onResourcedFromFilterChanged([]),
                },
            ]
        );
    }

    const renderPersonaIcon = (
        userId: string | null,
        displayName: string
    ): JSX.Element => {
        return (
            <Persona
                key={userId}
                size={PersonaSize.size16}
                imageUrl={userId ? photoService.getImageUrl(userId) : undefined}
                text={displayName}
                hidePersonaDetails
            />
        );
    };

    const handeRenderText = (
        props?: IButtonProps,
        defaultRender?: (props?: IButtonProps) => JSX.Element | null
    ): JSX.Element => {
        return (
            <Stack horizontal tokens={{ childrenGap: 2 }}>
                {defaultRender && defaultRender(props)}
                {uniqueMissions
                    .filter((mission) =>
                        selectedMissionIds.some((mid) => mid === mission?.id)
                    )
                    .map((mission) =>
                        renderPersonaIcon(
                            mission?.userId ?? null,
                            mission?.owner || ''
                        )
                    )}
            </Stack>
        );
    };

    if (!menuItems.length) {
        return null;
    }

    return (
        <ActionButton
            text="Resourced From"
            onRenderText={handeRenderText}
            menuProps={{
                items: menuItems,
            }}
        />
    );
}
