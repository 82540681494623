import React from 'react';
import { useTaskSummary } from '../../../hooks/useTaskSummary';
import {
    IChartDataPoint,
    IChartProps,
    MultiStackedBarChart,
} from '@fluentui/react-charting';
import { TaskWithStatus } from '../../../data/extendedTypes';

export function TaskSummaryBar(props: {
    percentageComplete: number;
    impliedTasks: TaskWithStatus[];
    compact?: boolean;
    statusDate?: string | null;
}): JSX.Element {
    const taskSummary = useTaskSummary(props.impliedTasks, props.statusDate);

    const chartPoints: IChartDataPoint[] = [];

    taskSummary.forEach((ts) => {
        if (ts.count > 0) {
            chartPoints.push({
                legend: ts.name,
                data:
                    (ts.count / props.impliedTasks.length) *
                    100 *
                    props.percentageComplete,
                color: ts.colour,
                yAxisCalloutData: ts.count.toString() || '0',
            });
        }
    });

    if (chartPoints.length === 0 || props.percentageComplete < 100) {
        chartPoints.push({
            placeHolder: true,
            data: 100 - props.percentageComplete * 100,
        });
    }

    const data: IChartProps[] = [
        {
            chartData: chartPoints,
        },
    ];

    const barHeight = props.compact ? 10 : 20;

    return (
        <MultiStackedBarChart
            data={data}
            barHeight={barHeight}
            hideRatio={[true]}
            hideLegend={true}
            width={600}
            styles={{
                singleChartRoot: {
                    marginBottom: 0,
                    height: barHeight,
                },
            }}
        />
    );
}
