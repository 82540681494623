import React, { CSSProperties } from 'react';

import { useThemes } from '../../hooks/useThemes';

import logoDark from '../../images/advance-logo-colour-dark.svg';
import logoWhite from '../../images/advance-logo-white.svg';
import logoCharcoal from '../../images/advance-logo-charcoal.svg';

import iconDark from '../../images/advance-icon-colour-dark.svg';
import iconWhite from '../../images/advance-icon-white.svg';
import iconCharcoal from '../../images/advance-icon-charcoal.svg';

export function LogoImage(props: { compact: boolean; style?: CSSProperties }) {
    const { compact, style } = props;

    const { logoTheme } = useThemes();

    let imageSrc: string | null = null;

    switch (logoTheme) {
        case 'Charcoal':
            imageSrc = compact ? iconCharcoal : logoCharcoal;
            break;
        case 'White':
            imageSrc = compact ? iconWhite : logoWhite;
            break;
        case 'Dark':
            imageSrc = compact ? iconDark : logoDark;
            break;
    }

    return <img src={imageSrc} alt="Advance" style={style} />;
}
