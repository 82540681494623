import React, { useState } from 'react';
import {
    DefaultButton,
    Dialog,
    DialogType,
    FocusZone,
    Label,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Text,
} from '@fluentui/react';
import {
    Measure,
    refetchGetMeasureValueHistoryQuery,
    refetchGetMissionMeasuresQuery,
    useGetMeasureLazyQuery,
    useGetMeasureQuery,
    useUpdateMeasureMutation,
} from '../data/types';
import { MeasurePicker } from './MeasurePicker';
import { InputShimmer } from './inputs';
export type MeasureLinkFromDialogProps = {
    tenantId: string;
    financialYearCode: string | null;
    measureId: string | null | undefined;
    hidden: boolean;
    onDismiss: () => void;
    onLinked: () => void;
};

export function MeasureLinkFromDialog(
    props: MeasureLinkFromDialogProps
): JSX.Element {
    const [selectedMeasureId, setSelectedMeasureId] = useState<string | null>();

    const { data, loading, error } = useGetMeasureQuery({
        skip: props.hidden || !props.measureId,
        variables: {
            tenantId: props.tenantId,
            id: props.measureId || '',
        },
    });

    const [loadMeasure] = useGetMeasureLazyQuery();

    const measure = data?.measure;

    const [updateMeasure, { loading: isSaving, error: saveError }] =
        useUpdateMeasureMutation();

    const handleLinkClick = async () => {
        if (selectedMeasureId && measure) {
            const selectedMeasureQuery = await loadMeasure({
                variables: {
                    id: selectedMeasureId,
                    tenantId: props.tenantId,
                },
            });

            const selectedMeasure = selectedMeasureQuery.data?.measure;

            if (!selectedMeasure) {
                return;
            }

            const inputMeasure: Measure = {
                id: measure.id,
                missionId: measure?.missionId,
                measureGroupId: measure.measureGroupId,
                version: measure.version,
                tags: measure.tags,
                sequence: measure.sequence,

                name: selectedMeasure.name,
                shortName: selectedMeasure.shortName,
                description: selectedMeasure.description,
                measureType: selectedMeasure.measureType,
                phaseType: selectedMeasure.phaseType,
                currency: selectedMeasure.currency,
                multiplier: selectedMeasure.multiplier,
                decimalPlaces: selectedMeasure.decimalPlaces,
                statusType: selectedMeasure.statusType,
                yellowStart: selectedMeasure.yellowStart,
                greenRange: selectedMeasure.greenRange,
                yellowRange: selectedMeasure.yellowRange,
                isStatusLimited: selectedMeasure.isStatusLimited,
                frequencyNumber: selectedMeasure.frequencyNumber,
                frequencyPeriod: selectedMeasure.frequencyPeriod,
                isLinked: true,
                linkedFromMeasureId:
                    selectedMeasure.isLinked &&
                    selectedMeasure.linkedFromMeasureId
                        ? selectedMeasure.linkedFromMeasureId
                        : selectedMeasureId,

                previousFYMeasureId: selectedMeasure.previousFYMeasureId,
                fullYearTarget: selectedMeasure.fullYearTarget,
                fullYearString: selectedMeasure.fullYearString,
                isFullYearTarget: selectedMeasure.isFullYearTarget,
                chartDisplay: selectedMeasure.chartDisplay,
                chartType: selectedMeasure.chartType,
                showForecast: selectedMeasure.showForecast,
                showFutureLook: selectedMeasure.showFutureLook,
                isCustom: selectedMeasure.isCustom,
                targetType: selectedMeasure.targetType,
                valueType: selectedMeasure.valueType,
                valueFormula: null, // Do not copy a formula when linking
            };

            await updateMeasure({
                variables: {
                    tenantId: props.tenantId,
                    input: inputMeasure,
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                    refetchGetMissionMeasuresQuery({
                        tenantId: props.tenantId,
                        missionId: measure.missionId,
                    }),
                    refetchGetMeasureValueHistoryQuery({
                        tenantId: props.tenantId,
                        id: measure.id || '',
                        historyHasActual: false,
                        historySkip: 0,
                        historyTake: 12,
                    }),
                ],
            });
            props.onLinked();
        }
    };

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        buttonContainer: {
            alignSelf: 'flex-end',
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
        },
    });

    const excludeMeasureIds = data?.measure?.linkedMeasures
        ? [
              props.measureId || '',
              ...data.measure.linkedMeasures.map((m) => m.id || ''),
          ]
        : [props.measureId || ''];

    return (
        <Dialog
            hidden={props.hidden}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Link from...',
                showCloseButton: true,
                subText:
                    'Link this measure of success from a measure of success on another mission..',
            }}
            modalProps={{
                isBlocking: true,
            }}
            onDismiss={props.onDismiss}
        >
            <FocusZone>
                <div className={classNames.container}>
                    {(error || saveError) && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={true}
                        >
                            <span>{(error || saveError)?.message}</span>
                        </MessageBar>
                    )}

                    {measure?.lastAsOf !== null && (
                        <MessageBar
                            messageBarType={MessageBarType.warning}
                            isMultiline={true}
                        >
                            <span>
                                The values on this measure of success will be
                                removed and replaced with those on the selected
                                measure of success below.
                            </span>
                        </MessageBar>
                    )}

                    {isSaving && (
                        <Spinner
                            size={SpinnerSize.small}
                            label="Saving..."
                            labelPosition="right"
                        />
                    )}
                    <InputShimmer isDataLoaded={!loading}>
                        <Label>Find a measure of success to link from</Label>
                        <MeasurePicker
                            excludeMeasureIds={excludeMeasureIds}
                            onMeasureSelected={(measureId) => {
                                setSelectedMeasureId(measureId || null);
                            }}
                        />
                    </InputShimmer>

                    <Text block variant="small">
                        Once linked, all edits and value updates from the
                        selected measure of success will cascade automatically.
                    </Text>

                    <div className={classNames.buttonContainer}>
                        <PrimaryButton
                            text="Link"
                            onClick={handleLinkClick}
                            disabled={loading || isSaving || !selectedMeasureId}
                        />
                        <DefaultButton
                            onClick={props.onDismiss}
                            text="Cancel"
                        />
                    </div>
                </div>
            </FocusZone>
        </Dialog>
    );
}
