import { TaskStatusColours } from '../Colours';
import { TaskStatusIcons } from '../Icons';
import {
    TaskStatusNames,
    taskStatusUtil,
    TaskWithStatus,
} from '../data/extendedTypes';
import { useStateContext } from '../services/contextProvider';

export const useTaskStatus = (
    task: TaskWithStatus | undefined,
    statusDate?: string
): {
    name: TaskStatusNames;
    text:
        | 'Not Started'
        | 'Off Target'
        | 'On Target'
        | 'Completed Late'
        | 'Completed On Time'
        | 'Postponed'
        | 'Cancelled'
        | 'At Risk'
        | 'Rejected'
        | 'Requested';
    colour: string | undefined;
    iconName: string;
} | null => {
    const { currentFinancialYearCode } = useStateContext();
    const status = taskStatusUtil.GetTaskStatus(
        task,
        currentFinancialYearCode,
        statusDate
    );

    switch (status) {
        case TaskStatusNames.NotStarted:
            return {
                name: status,
                text: 'Not Started',
                colour: TaskStatusColours.notStarted,
                iconName: TaskStatusIcons.notStarted,
            };
        case TaskStatusNames.OnTarget:
            return {
                name: status,
                text: 'On Target',
                colour: TaskStatusColours.onTarget,
                iconName: TaskStatusIcons.onTarget,
            };
        case TaskStatusNames.OffTarget:
            return {
                name: status,
                text: 'Off Target',
                colour: TaskStatusColours.offTarget,
                iconName: TaskStatusIcons.offTarget,
            };
        case TaskStatusNames.CompletedOnTime:
            return {
                name: status,
                text: 'Completed On Time',
                colour: TaskStatusColours.completedOnTime,
                iconName: TaskStatusIcons.completedOnTime,
            };
        case TaskStatusNames.CompletedLate:
            return {
                name: status,
                text: 'Completed Late',
                colour: TaskStatusColours.completedLate,
                iconName: TaskStatusIcons.completedLate,
            };
        case TaskStatusNames.Postponed:
            return {
                name: status,
                text: 'Postponed',
                colour: TaskStatusColours.postponed,
                iconName: TaskStatusIcons.postponed,
            };
        case TaskStatusNames.Cancelled:
            return {
                name: status,
                text: 'Cancelled',
                colour: TaskStatusColours.cancelled,
                iconName: TaskStatusIcons.cancelled,
            };
        case TaskStatusNames.AtRisk:
            return {
                name: status,
                text: 'At Risk',
                colour: TaskStatusColours.atRisk,
                iconName: TaskStatusIcons.atRisk,
            };
        case TaskStatusNames.Pending:
            return {
                name: status,
                text: 'Requested',
                colour: undefined,
                iconName: TaskStatusIcons.pending,
            };
        case TaskStatusNames.Rejected:
            return {
                name: status,
                text: 'Rejected',
                colour: TaskStatusColours.rejected,
                iconName: TaskStatusIcons.rejected,
            };
        default:
            return null;
    }
};
