import { ActionButton, FontSizes } from '@fluentui/react';
import React from 'react';
import { useThemes } from '../hooks/useThemes';

export default function CommentButton(props: {
    commentCount: number;
    onClick: () => void;
}): JSX.Element {
    const { currentTheme } = useThemes();
    return (
        <ActionButton
            iconProps={{ iconName: 'Message' }}
            text={props.commentCount.toFixed(0)}
            ariaLabel="Comments"
            onClick={props.onClick}
            styles={{
                label: {
                    whiteSpace: 'nowrap',
                    fontSize: FontSizes.xSmall,
                    color: props.commentCount
                        ? undefined
                        : currentTheme.palette.themeLighter,
                },
                root: {
                    height: 32, // Same as IconButton
                },
                icon: {
                    margin: 0,
                    color: props.commentCount
                        ? undefined
                        : currentTheme.palette.themeLighter,
                },
            }}
        />
    );
}
