import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { MeasureSeriesNames } from '../data/extendedTypes';
import { useThemes } from '../hooks/useThemes';
import { MeasureStatusColours } from '../Colours';
import {
    IChartProps,
    ILineChartDataPoint,
    ILineChartPoints,
    Sparkline,
} from '@fluentui/react-charting';
import debounce from 'lodash/debounce';
import { useViewport } from '../hooks/useViewport';
import { useGetMeasureValueHistoryQuery } from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { Shimmer } from '@fluentui/react';

export function MeasureHistorySparkline(props: {
    height?: number;
    measureId?: string;
    arrowColour: 'RED' | 'GREEN' | 'YELLOW' | 'NONE';
}) {
    const { currentTenantId } = useStateContext();
    const { width: viewportWidth } = useViewport();
    const [containerWidth, setContainerWidth] = useState<number>(100);
    const { currentTheme, currentThemeName } = useThemes();

    const backgroundContainer = useMemo(
        () =>
            debounce((node) => {
                if (node !== null && viewportWidth) {
                    setContainerWidth(
                        Math.round(node.getBoundingClientRect().width)
                    );
                }
            }, 100),
        [viewportWidth]
    );

    const { data, loading } = useGetMeasureValueHistoryQuery({
        skip: !props.measureId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            id: props.measureId || '',
            historyHasActual: false,
            historySkip: 0,
            historyTake: 12,
        },
    });

    const chart1Points: ILineChartDataPoint[] =
        data?.measure?.valueHistory
            ?.filter((h) =>
                h.values?.some(
                    (v) => v.seriesType?.name === MeasureSeriesNames.Actual
                )
            )
            .map((h) => {
                const actual = h.values?.find(
                    (v) => v.seriesType?.name === MeasureSeriesNames.Actual
                );

                return {
                    x: dayjs(h.asOfDate).toDate(),
                    y: actual?.decimalValue || h.statusValue || 0,
                };
            }) || [];

    let statusColour: string;

    if (props.arrowColour === 'GREEN') {
        statusColour =
            currentThemeName === 'Dark'
                ? MeasureStatusColours.onTarget
                : `${MeasureStatusColours.onTarget}40`;
    } else if (props.arrowColour === 'RED') {
        statusColour =
            currentThemeName === 'Dark'
                ? MeasureStatusColours.offTarget
                : `${MeasureStatusColours.offTarget}40`;
    } else if (props.arrowColour === 'YELLOW') {
        statusColour =
            currentThemeName === 'Dark'
                ? MeasureStatusColours.nearTarget
                : `${MeasureStatusColours.nearTarget}40`;
    } else {
        statusColour = currentTheme.palette.neutralLighter;
    }

    const chartPoints: ILineChartPoints[] = [
        {
            legend: 'legend1',
            data: chart1Points,
            color: statusColour,
        },
    ];

    const chartData: IChartProps = {
        chartTitle: 'Measure History',
        lineChartData: chartPoints,
    };

    return (
        <div ref={backgroundContainer}>
            <Shimmer
                isDataLoaded={!loading}
                height={props.height}
                width={containerWidth}
            >
                {chart1Points?.length > 1 && (
                    <Sparkline
                        data={chartData}
                        width={containerWidth}
                        {...props}
                        key={`chart_${containerWidth}`}
                    />
                )}
            </Shimmer>
        </div>
    );
}
