import React, { useState } from 'react';
import { MessageBarType, MessageBar, MessageBarButton } from '@fluentui/react';
import { FrequencyPeriods, GetMeasureQuery } from '../data/types';
import { ExtractQueryType } from '../data/extendedTypes';
import { MeasureUpgradeDialog } from '../scenes/Measure/components/MeasureUpgradeDialog';
import { MeasureUpgradePreviewDialog } from '../scenes/Measure/components/MeasureUpgradePreviewDialog';

export function LegacyMeasureBar(props: {
    measure: ExtractQueryType<GetMeasureQuery, ['measure']>;
    onUpgradeCompleted: () => void;
}): JSX.Element {
    const [hideUpgradeDialog, setHideUpgradeDialog] = useState(true);
    const [hidePreviewDialog, setHidePreviewDialog] = useState(true);
    const [upgradeFrequency, setUpgradeFrequency] = useState<FrequencyPeriods>(
        FrequencyPeriods.None
    );

    return (
        <>
            <MessageBar
                messageBarType={MessageBarType.info}
                isMultiline={false}
                actions={
                    <div>
                        <MessageBarButton
                            onClick={() => setHideUpgradeDialog(false)}
                        >
                            Upgrade
                        </MessageBarButton>
                    </div>
                }
            >
                This is a legacy measure. Click to upgrade to the latest
                version.
            </MessageBar>
            <MeasureUpgradeDialog
                measure={props.measure}
                fyStartDate={undefined}
                hideDialog={hideUpgradeDialog}
                toggleShowDialog={() => setHideUpgradeDialog(true)}
                onNext={(selectedFrequency) => {
                    setUpgradeFrequency(selectedFrequency);
                    setHideUpgradeDialog(true);
                    setHidePreviewDialog(false);
                }}
            />
            <MeasureUpgradePreviewDialog
                measure={props.measure}
                selectedFrequency={upgradeFrequency}
                hideDialog={hidePreviewDialog}
                toggleShowDialog={() => setHidePreviewDialog(true)}
                onBack={() => {
                    setHideUpgradeDialog(false);
                    setHidePreviewDialog(true);
                }}
                onUpgradeCompleted={props.onUpgradeCompleted}
            />
        </>
    );
}
