import React from 'react';
import { Text, Alignment, TooltipHost, mergeStyleSets } from '@fluentui/react';
import { memoizeFunction } from '@fluentui/utilities';
import { MeasureArrowIcon } from './MeasureArrowIcon';
import { useFormatters } from '../hooks/useFormatters';

export type MeasureArrowProps = {
    arrowDirection: 'UP' | 'DOWN' | 'SAME' | 'NONE' | null | undefined;
    arrowColour: 'GREEN' | 'YELLOW' | 'RED' | 'NONE' | null | undefined;
    statusValue?: number | null | undefined;
    previousPeriod?: string | null;
    values?:
        | {
              formatStr?: string | null;
              decimalValue?: number | null;
              stringValue?: string | null;
              dateValue?: string | null;
              seriesType: {
                  name: string | null;
              } | null;
          }[]
        | null
        | undefined;
    showPercentage?: boolean;
    percentagePosition?: 'Left' | 'Right' | 'Bottom' | 'Top';
    iconFontSize?: string | number;
    textFontSize?: string | number;
    textFontWeight?: string | number;
    isStatusLimited: boolean;
    horizontalAlign?: Alignment;
    skipValuesCheck?: boolean; // Otherwise values will need to be included in the props and validated.
};

function MeasureArrow({
    arrowDirection,
    arrowColour,
    statusValue,
    previousPeriod,
    values,
    showPercentage = false,
    iconFontSize = 18,
    textFontSize = 18,
    textFontWeight = undefined,
    percentagePosition = 'Right',
    isStatusLimited,
    horizontalAlign,
    skipValuesCheck,
}: MeasureArrowProps): JSX.Element {
    const formatters = useFormatters();

    const isHorizontal =
        percentagePosition === 'Left' || percentagePosition === 'Right';

    const hasTarget =
        skipValuesCheck ||
        values?.some(
            (v) =>
                v.seriesType?.name === 'Target' &&
                (v.formatStr ||
                    v.decimalValue !== null ||
                    v.decimalValue !== undefined ||
                    v.stringValue ||
                    v.dateValue)
        );

    const hasActual =
        skipValuesCheck ||
        values?.some(
            (v) =>
                v?.seriesType?.name === 'Actual' &&
                (v.formatStr ||
                    v.decimalValue !== null ||
                    v.decimalValue !== undefined ||
                    v.stringValue ||
                    v.dateValue)
        );

    const formattedStatus =
        hasTarget && hasActual
            ? formatters.formatMeasureStatus(statusValue, isStatusLimited)
            : null;

    const getClassNames = memoizeFunction(
        (isHorizontal: boolean, horizontalAlign: string | undefined) => {
            return mergeStyleSets({
                container: [
                    {
                        display: 'flex',
                        flexWrap: 'none',
                        gap: isHorizontal ? 2 : 0,
                        verticalAlign: 'center',
                        flexDirection: isHorizontal ? 'row' : 'column',
                        alignItems: 'center',
                    },
                    horizontalAlign && {
                        justifyContent: 'end',
                    },
                ],
                iconContainer: {
                    alignSelf: isHorizontal ? 'auto' : 'center',
                    display: 'flex',
                },
                percentageContainer: {
                    alignSelf: 'center',
                    display: 'flex',
                },
                percentageText: {
                    textAlign: 'center',
                    fontSize: textFontSize,
                    fontWeight: textFontWeight,
                },
            });
        }
    );

    const classNames = getClassNames(isHorizontal, horizontalAlign);

    return (
        <div className={classNames.container}>
            {showPercentage &&
                (percentagePosition === 'Top' ||
                    percentagePosition === 'Left') && (
                    <div className={classNames.percentageContainer}>
                        <Text block className={classNames.percentageText}>
                            {formattedStatus}
                        </Text>
                    </div>
                )}

            <div className={classNames.iconContainer}>
                {hasTarget && hasActual ? (
                    <MeasureArrowIcon
                        previousPeriod={previousPeriod}
                        arrowColour={arrowColour}
                        arrowDirection={arrowDirection}
                        iconFontSize={iconFontSize}
                    />
                ) : (
                    <TooltipHost
                        content={
                            !hasTarget
                                ? 'Status cannot be calculated without a target value.'
                                : 'Status cannot be calculated without a actual value.'
                        }
                    >
                        <MeasureArrowIcon
                            arrowColour="NONE"
                            arrowDirection="NONE"
                            iconFontSize={iconFontSize}
                        />
                    </TooltipHost>
                )}
            </div>

            {showPercentage &&
                (percentagePosition === 'Bottom' ||
                    percentagePosition === 'Right') && (
                    <div className={classNames.percentageContainer}>
                        <Text block className={classNames.percentageText}>
                            {formattedStatus}
                        </Text>
                    </div>
                )}
        </div>
    );
}

export default MeasureArrow;
