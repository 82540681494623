import React, { useEffect, useState } from 'react';
import { Text, mergeStyleSets } from '@fluentui/react';
import { EditPanel } from '../../../components/shared/EditPanel';
import {
    ChartType,
    PhaseType,
    TargetTypes,
    useGetMeasureGroupsLazyQuery,
    useGetMeasureGroupsQuery,
    useGetMissionLazyQuery,
    useGetMissionMeasuresQuery,
    useGetMissionQuery,
    useUpdateMeasureGroupMutation,
    useUpdateMeasureMutation,
} from '../../../data/types';
import { useLastYear } from '../../../hooks/useLastYear';
import { useStateContext } from '../../../services/contextProvider';
import { MeasureCheckBoxList } from '../../../components/inputs/MeasureCheckBoxList';
import { Guid } from 'guid-typescript';

export default function MeasureAddLinkedPanel(props: {
    missionId: string;
    showPanel: boolean;
    onCancel: () => void;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [selectedMeasures, setSelectedMeasures] = useState<
        { id: string; missionSequence?: number }[]
    >([]);

    const [isValid, setIsValid] = useState(false);

    useEffect(() => setIsValid(!!selectedMeasures.length), [selectedMeasures]);

    // This years mission
    const { data: thisYearsMissionData } = useGetMissionQuery({
        skip: !props.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId,
        },
    });
    const thisYearsMission = thisYearsMissionData?.mission;
    const thisYearsGroups = thisYearsMissionData?.measureGroups;

    // Last years mission
    const {
        missionId: lastYearsMissionId,
        financialYearCode: lastYearsFyCode,
    } = useLastYear(thisYearsMission?.userId);

    const { data: lastYearsGroupsData, loading: groupsLoading } =
        useGetMeasureGroupsQuery({
            skip: !currentTenantId || !lastYearsMissionId,
            variables: {
                tenantId: currentTenantId || '',
                missionId: lastYearsMissionId || ' ',
            },
        });

    const { data: lastYearsMeasureData, loading } = useGetMissionMeasuresQuery({
        skip: !currentTenantId || !lastYearsMissionId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: lastYearsMissionId || '',
        },
    });

    const lastYearsGroups = lastYearsGroupsData?.measureGroups;
    const lastYearsMeasures = lastYearsMeasureData?.measures;

    const [updateMeasure, { loading: isSaving, error: saveError }] =
        useUpdateMeasureMutation();

    const [
        updateMeasureGroup,
        { loading: isSavingGroup, error: saveGroupError },
    ] = useUpdateMeasureGroupMutation();

    const [getMission] = useGetMissionLazyQuery();
    const [getMeasureGroups] = useGetMeasureGroupsLazyQuery();

    async function handleSave(): Promise<void> {
        if (!currentTenantId || !lastYearsMissionId) {
            return;
        }

        // Create any missing groups
        const selectedMeasuresData =
            lastYearsMeasures?.filter((m) =>
                selectedMeasures.some((sm) => sm.id === m.id)
            ) || [];

        const groupsNeeded =
            lastYearsGroups?.filter((g) =>
                selectedMeasuresData?.some((m) => m.measureGroupId === g.id)
            ) || [];

        let groupSequence = thisYearsGroups?.length
            ? Math.max(...thisYearsGroups.map((g) => g.sequence)) + 1
            : 0;

        for (const g of groupsNeeded) {
            if (!thisYearsGroups?.find((eg) => eg.name === g.name)) {
                await updateMeasureGroup({
                    variables: {
                        input: {
                            id: Guid.create().toString(),
                            missionId: props.missionId,
                            name: g.name,
                            sequence: groupSequence++,
                            version: '',
                        },
                        tenantId: currentTenantId,
                    },
                });
            }
        }

        const finshedGroups = await getMeasureGroups({
            fetchPolicy: 'network-only',
            variables: {
                tenantId: currentTenantId,
                missionId: props.missionId,
            },
        });

        await Promise.all(
            selectedMeasuresData.map((m) => {
                let measureGroupId: string | null = null;

                if (m.measureGroupId) {
                    const lastYearsGroup = lastYearsGroups?.find(
                        (g) => g.id === m.measureGroupId
                    );

                    if (lastYearsGroup?.name) {
                        measureGroupId =
                            finshedGroups.data?.measureGroups?.find(
                                (g) => g.name === lastYearsGroup.name
                            )?.id || null;
                    }
                }

                return updateMeasure({
                    variables: {
                        tenantId: currentTenantId,
                        input: {
                            id: null,
                            missionId: props.missionId,
                            previousFYMeasureId: m.id,
                            measureGroupId: measureGroupId,
                            name: m.name,
                            shortName: m.shortName,
                            description: m.description,
                            measureType: m.measureType,
                            phaseType: PhaseType.Cumulative,
                            currency: m.currency
                                ? {
                                      code: m.currency?.code,
                                      descr: m.currency?.descr,
                                      symbol: m.currency?.symbol,
                                  }
                                : null,
                            multiplier: m.multiplier,
                            decimalPlaces: m.decimalPlaces,
                            statusType: m.statusType,
                            yellowStart: m.yellowStart,
                            greenRange: m.greenRange,
                            yellowRange: m.yellowRange,
                            isStatusLimited: m.isStatusLimited,
                            frequencyNumber: 1,
                            frequencyPeriod: m.frequencyPeriod,
                            isLinked: false,
                            linkedFromMeasureId: null,
                            tags: [],
                            sequence: m.sequence,
                            fullYearTarget: null,
                            fullYearString: null,
                            isFullYearTarget: m.isFullYearTarget,
                            chartDisplay: m.chartDisplay,
                            chartType: ChartType.Default,
                            showForecast: m.showForecast,
                            showFutureLook: false,
                            isCustom: false,
                            targetType: TargetTypes.None,
                            valueType: m.valueType,
                            valueFormula: m.valueFormula,
                            version: '',
                        },
                    },
                });
            })
        );

        // reload the measures for this year
        await getMission({
            fetchPolicy: 'network-only',
            variables: {
                tenantId: currentTenantId,
                missionId: props.missionId,
            },
        });

        setSelectedMeasures([]);
        props.onCancel();
    }

    const measures =
        lastYearsMeasures?.map((m) => {
            return {
                ...m,
                group: m.measureGroupId
                    ? lastYearsGroups?.find((g) => g.id === m.measureGroupId) ||
                      null
                    : null,
            };
        }) || [];

    const classNames = mergeStyleSets({
        container: {
            marginTop: 16,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
    });

    return (
        <EditPanel
            showPanel={props.showPanel}
            headerText={`Copy from ${lastYearsFyCode}`}
            onDismiss={props.onCancel}
            saveErrorMessage={saveError?.message || saveGroupError?.message}
            isSaving={isSaving || isSavingGroup}
            isValid={isValid}
            activeViewName={null}
            onUpdateClick={handleSave}
        >
            <div className={classNames.container}>
                <Text>
                    Select the measure of success definitions to copy from last
                    year into this year.
                </Text>
                <MeasureCheckBoxList
                    measures={measures}
                    isLoading={loading || groupsLoading}
                    selectedMeasures={selectedMeasures}
                    onSelectedMeasuresChanged={setSelectedMeasures}
                />
            </div>
        </EditPanel>
    );
}
