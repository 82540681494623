import { useCallback } from 'react';
import orderBy from 'lodash/orderBy';
import {
    MonthRow,
    FrequencyTypes,
    getLabelByType,
    createSeriesValue,
    getSeriesValue,
} from '../utils/measureUtils';
import {
    FrequencyPeriods,
    SeriesValue,
    MeasureAsOf,
    ArrowDirection,
    Arrows,
    StatusTypes,
    MeasureTypes,
    ValueTypes,
} from '../../../data/types';
import {
    MeasureSeriesNames,
    MeasureValueHistoryItem,
} from '../../../data/extendedTypes';
import { useInputMappers } from '../../../hooks/useInputMappers';
import dayjs from 'dayjs';
import { useMeasureStatusCalc } from '../../../hooks/useMeasureStatusCalc';

export type DataMeasureType = {
    id: string | null;
    frequencyPeriod: FrequencyPeriods;
    isCustom: boolean;
    valueHistory: MeasureValueHistoryItem[] | null;
    valueType: ValueTypes;
    measureType: MeasureTypes;
    statusType: StatusTypes;
    greenRange: number;
    yellowRange: number;
    yellowStart: number;
    isStatusLimited: boolean;
};

export function useMeasureData(): {
    buildTargetTable: (
        measure: DataMeasureType | null,
        fyStartDate: string | undefined
    ) => MonthRow[];
} {
    const { getMeasureAsOfInput } = useInputMappers();

    const { calcStatus } = useMeasureStatusCalc();

    const getFrequencyType = useCallback(
        (
            measure: {
                frequencyPeriod: FrequencyPeriods;
                isCustom: boolean;
            } | null
        ) => {
            if (measure?.isCustom) {
                return FrequencyTypes.Custom;
            } else if (measure?.frequencyPeriod === FrequencyPeriods.Month) {
                return FrequencyTypes.Monthly;
            } else if (measure?.frequencyPeriod === FrequencyPeriods.Quarter) {
                return FrequencyTypes.Quarterly;
            } else if (measure?.frequencyPeriod === FrequencyPeriods.Year) {
                return FrequencyTypes.Yearly;
            } else {
                // Day or Week or None:
                return FrequencyTypes.Custom;
            }
        },
        []
    );

    const getTableSize = useCallback(
        (measure: DataMeasureType | null) => {
            const frequencyType = getFrequencyType(measure);

            if (measure?.isCustom || frequencyType === FrequencyTypes.Custom)
                return measure?.valueHistory?.length ?? 0;

            // TODO: 18 Month FYs?
            return frequencyType === FrequencyTypes.Yearly
                ? 1
                : frequencyType === FrequencyTypes.Quarterly
                  ? 4
                  : 12;
        },
        [getFrequencyType]
    );

    const buildTargetTable = useCallback(
        (
            measure: DataMeasureType | null,
            fyStartDate: string | undefined
        ): MonthRow[] => {
            if (!measure || !fyStartDate) {
                return [];
            }

            const valueHistory =
                orderBy(measure?.valueHistory, ['index', 'asOfDate']) || [];

            const rowCount = getTableSize(measure);
            const asOfInputs: MeasureAsOf[] = [];
            const dataIn: MonthRow[] = [];
            const statuses: {
                statusValue: number;
                arrowColour: Arrows;
                arrowDirection: ArrowDirection;
            }[] = [];
            let ytd = 0;
            let actualYtd = 0;
            let forecastYtd = 0;
            let previousYtdStatus: number | null | undefined = undefined;

            const frequencyType = getFrequencyType(measure);
            const isCustom =
                measure.isCustom || frequencyType === FrequencyTypes.Custom;

            if (isCustom) {
                for (let i = 0; i < rowCount; i++) {
                    const asOf = valueHistory[i as number];
                    const asOfInput = getMeasureAsOfInput(measure.id, asOf);
                    asOfInputs.push(asOfInput);
                    statuses.push(asOf);
                }
            } else {
                let currentMonth = dayjs(fyStartDate);
                for (let i = 0; i < rowCount; i++) {
                    if (i > 0) {
                        const months =
                            frequencyType === FrequencyTypes.Quarterly ? 3 : 1;
                        currentMonth = currentMonth.add(months, 'month');
                    }

                    const formattedAsOfDate = currentMonth.format('YYYY-MM-DD');

                    const asOf = (valueHistory || []).find(
                        (v) =>
                            formattedAsOfDate ===
                            dayjs(v.asOfDate).format('YYYY-MM-DD')
                    );

                    let asOfInput: MeasureAsOf | undefined;
                    if (asOf) {
                        asOfInput = getMeasureAsOfInput(measure.id, asOf);
                    } else {
                        asOfInput = {
                            id: null,
                            measureId: measure.id ?? '',
                            asOfDate: formattedAsOfDate,
                            values: [],
                            version: null,
                        };
                    }

                    statuses.push(
                        asOf
                            ? asOf
                            : {
                                  statusValue: 0,
                                  arrowColour: Arrows.None,
                                  arrowDirection: ArrowDirection.None,
                              }
                    );
                    asOfInputs.push(asOfInput);
                }
            }

            for (let i = 0; i < asOfInputs.length; i++) {
                const asOfInput = asOfInputs[i as number];
                const status = statuses[i as number];
                let target: SeriesValue | undefined;
                let actual: SeriesValue | undefined;
                let forecast: SeriesValue | undefined;

                target = getSeriesValue(MeasureSeriesNames.Target, asOfInput);
                if (!target) {
                    target = createSeriesValue(MeasureSeriesNames.Target, null);
                    asOfInput.values?.push(target);
                }

                actual = getSeriesValue(MeasureSeriesNames.Actual, asOfInput);
                if (!actual) {
                    actual = createSeriesValue(MeasureSeriesNames.Actual, null);
                    asOfInput.values?.push(actual);
                }

                forecast = getSeriesValue(
                    MeasureSeriesNames.PhasedForecast,
                    asOfInput
                );
                if (!forecast) {
                    forecast = createSeriesValue(
                        MeasureSeriesNames.PhasedForecast,
                        null
                    );
                    asOfInput.values?.push(forecast);
                }

                ytd += target.decimalValue ?? 0;
                actualYtd += actual.decimalValue ?? 0;
                forecastYtd += forecast.decimalValue ?? 0;

                const calcYtdStatus =
                    (measure.measureType === MeasureTypes.Currency ||
                        measure.measureType === MeasureTypes.Percentage ||
                        measure.measureType === MeasureTypes.Numeric) &&
                    measure.valueType === ValueTypes.Incremental;

                const statusYtd:
                    | {
                          statusValue: number | null;
                          arrowColour: Arrows;
                          arrowDirection: ArrowDirection;
                      }
                    | undefined = calcYtdStatus
                    ? calcStatus(ytd, actualYtd, measure, previousYtdStatus)
                    : undefined;

                previousYtdStatus = statusYtd?.statusValue;

                const dateFormatted = dayjs(asOfInput.asOfDate).format(
                    'YYYY-MM-DD'
                );

                dataIn.push({
                    index: i,
                    label: getLabelByType(
                        fyStartDate,
                        frequencyType,
                        asOfInput?.asOfDate || dateFormatted
                    ),
                    dt: dateFormatted,

                    interval: target,
                    ytd: ytd,

                    forecast:
                        forecast.decimalValue !== null
                            ? forecast.decimalValue
                            : null,
                    forecastYtd: forecastYtd,

                    actual: actual,
                    actualYtd: actualYtd,

                    status: {
                        arrowColour: status.arrowColour,
                        arrowDirection: status.arrowDirection,
                        statusValue: status.statusValue,
                    },

                    statusYtd: statusYtd,

                    hasChanges: !asOfInput.id,
                    asOf: asOfInput,
                });
            }

            return dataIn;
        },
        [getTableSize, calcStatus, getFrequencyType, getMeasureAsOfInput]
    );

    return {
        buildTargetTable,
    };
}
