import React from 'react';
import { CommandBarButton, IButtonProps } from '@fluentui/react';

export function PanelCommandBarButton(props: IButtonProps): JSX.Element {
    return (
        <CommandBarButton
            {...props}
            styles={{
                label: {
                    whiteSpace: 'nowrap',
                },
                root: {
                    height: 32, // Same as IconButton
                },
            }}
        />
    );
}
