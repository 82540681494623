import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    ActionButton,
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    mergeStyleSets,
    SelectionMode,
    TextField,
    Text,
    IconButton,
    IDragDropEvents,
    mergeStyles,
} from '@fluentui/react';
import { Guid } from 'guid-typescript';
import { AdvanceCard } from '../../../components/AdvanceCard';
import { TemplateReportElementType } from '../TemplateReport';
import { DetailsListCellItemContainer } from '../../../components/shared/DetailsListCellItemContainer';
import { EditibleText } from '../../../components/EditibleText';
import { TemplateReportCustomTablePanel } from './TemplateReportCustomTablePanel';
import { sorters } from '../../../data/sorters';
import { useThemes } from '../../../hooks/useThemes';
import orderBy from 'lodash/orderBy';
import DeleteModal from '../../../components/shared/DeleteModal';

export function TemplateReportCustomTableElement(props: {
    missionId: string;
    utcDataDate: string | null;
    utcCompletedDate: string | null;
    element: TemplateReportElementType;
    onElementChanged: (
        updated: TemplateReportElementType,
        forceImmediateSave: boolean
    ) => void;
    isReadOnly: boolean;
}): JSX.Element {
    type CustomTableRow = {
        [key: string]: { cellId: string; content: string };
    };

    const { currentTheme } = useThemes();

    const [isEditOpen, setIsEditOpen] = useState(false);

    const [updatedElement, setUpdatedElement] =
        useState<TemplateReportElementType>(props.element);

    const [isDeleteRowConfirming, setIsDeleteRowConfirming] =
        useState<CustomTableRow>();

    const [isDeletingRow, setIsDeletingRow] = useState(false);

    const [isEditingRow, setIsEditingRow] = useState<number | null>(null);

    const [listItems, setListItems] = useState<CustomTableRow[]>([]);

    const [columnWidths, setColumnWidths] = useState<{
        [columnId: string]: number;
    }>({});

    const renderDeleteButton = (item?: CustomTableRow): JSX.Element => {
        return (
            <IconButton
                iconProps={{
                    styles: {
                        root: {
                            color: currentTheme.semanticColors.errorIcon,
                        },
                    },
                    iconName: 'Delete',
                }}
                onClick={(): void => {
                    if (item) {
                        setIsDeleteRowConfirming(item);
                    }
                }}
            />
        );
    };

    const renderMoveButton = () => {
        return (
            <IconButton
                iconProps={{
                    iconName: 'GripperDotsVertical',
                }}
                style={{ padding: 8, cursor: 'grab' }}
                styles={{
                    root: {
                        color: currentTheme.palette.themeLighter,
                        selectors: {
                            '&:hover': {
                                color: currentTheme.palette.themePrimary,
                            },
                        },
                        touchAction: 'manipulation',
                    },
                }}
            />
        );
    };

    const columns: IColumn[] = updatedElement.columns
        .slice()
        .sort(sorters.sequenceSorter)
        .map((c) => ({
            key: c.id || 'NOKEY',
            name: c.columnName || '',
            fieldName: c.id || 'NOKEY',
            minWidth: 60,
            isResizable: true,
            isMultiline: true,
            isPadded: false,
            onColumnResize: (width?: number) => {
                setColumnWidths((cw) => {
                    cw[c.id || ''] = width || 0;
                    return cw;
                });
            },
        }));

    if (!props.isReadOnly) {
        columns.unshift({
            key: 'move',
            name: '',
            minWidth: 16,
            maxWidth: 16,
            isIconOnly: true,
            isPadded: false,
            onRender: renderMoveButton,
        });

        columns.push({
            key: 'delete',
            name: '',
            minWidth: 20,
            maxWidth: 20,
            isIconOnly: true,
            isPadded: false,
            className: 'iconCell',
            onRender: renderDeleteButton,
        });
    }

    const deleteRowAction = () => {
        if (isDeleteRowConfirming) {
            setIsDeletingRow(true);
            deleteRow(isDeleteRowConfirming);
            setIsDeletingRow(false);
            setIsDeleteRowConfirming(undefined);
        }
    };

    const deleteRow = (item: CustomTableRow) => {
        const modified: TemplateReportElementType = {
            ...updatedElement,
            columns: updatedElement.columns.map((c) => {
                let rowIndex = 0;
                const cells = orderBy(
                    c.cells.filter(
                        (cell) => cell.id !== item[c.id || '']?.cellId
                    ),
                    'rowIndex'
                );
                return {
                    ...c,
                    cells: cells.map((cell) => ({
                        ...cell,
                        rowIndex: rowIndex++,
                    })),
                };
            }),
        };
        setUpdatedElement(modified);
        props.onElementChanged(modified, true);
    };

    const updateCell = (
        columnId: string,
        cell: {
            id: string;
            rowIndex: number;
            content: string;
        }
    ) => {
        const elementColumn = updatedElement.columns.find(
            (col) => col.id === columnId
        );

        if (elementColumn) {
            const modified: TemplateReportElementType = {
                ...updatedElement,
                columns: [
                    ...updatedElement.columns.filter(
                        (col) => elementColumn.id !== col?.id
                    ),
                    {
                        ...elementColumn,
                        cells: [
                            ...elementColumn.cells.filter(
                                (c) => c.id !== cell.id
                            ),
                            cell,
                        ],
                    },
                ],
            };

            setUpdatedElement(modified);
            props.onElementChanged(modified, false);
        }
    };

    const moveRow = (
        draggedRow: CustomTableRow,
        droppedOnRow: CustomTableRow
    ) => {
        const modified: TemplateReportElementType = {
            ...updatedElement,
            columns: [
                ...updatedElement.columns.map((column) => {
                    const cells = orderBy(column.cells, 'rowIndex');

                    const draggedCell = column.id
                        ? draggedRow[column.id]
                        : null;
                    const droppedOnCell = column.id
                        ? droppedOnRow[column.id]
                        : null;

                    if (!draggedCell || !droppedOnCell) {
                        return column;
                    }

                    const draggedToIndex = cells.findIndex(
                        (cell) => cell.id === droppedOnCell?.cellId
                    );

                    const reorderedCells = cells.filter(
                        (cell) => cell.id !== draggedCell?.cellId
                    );

                    reorderedCells.splice(draggedToIndex, 0, {
                        id: draggedCell?.cellId || '',
                        content: draggedCell?.content || '',
                        rowIndex: -1, // updated later
                    });

                    let rowIndex = 0;

                    return {
                        ...column,
                        cells: reorderedCells.map((cell) => {
                            return {
                                ...cell,
                                rowIndex: rowIndex++,
                            };
                        }),
                    };
                }),
            ],
        };

        setUpdatedElement(modified);
        props.onElementChanged(modified, true);
    };

    const handleRenderItemColumn = (
        item?: CustomTableRow,
        index?: number,
        column?: IColumn
    ): JSX.Element => {
        if (!column || !item || index === undefined) {
            return <span />;
        }

        const cellItem = item[column.key] || {
            cellId: Guid.create().toString(),
            content: '',
        };

        const onChange = (
            _event: React.FormEvent,
            newValue?: string | undefined
        ) =>
            updateCell(column.key, {
                id: cellItem.cellId,
                rowIndex: index || 0,
                content: newValue || '',
            });

        const onFocus = () => setIsEditingRow(index);
        const onBlur = () => setIsEditingRow(null);

        return props.isReadOnly ? (
            <DetailsListCellItemContainer key={`${cellItem.cellId}_text`}>
                <Text variant="small">{cellItem.content}</Text>
            </DetailsListCellItemContainer>
        ) : (
            // Hack: Use the column width in the key to make the textbox autosize when a column is resized.
            <TextField
                key={`${cellItem.cellId}_textfield_${columnWidths[
                    column.key
                ]?.toString()}`}
                defaultValue={cellItem.content}
                multiline
                autoAdjustHeight
                styles={{
                    fieldGroup: {
                        minHeight: 30,
                    },
                }}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                rows={1}
            />
        );
    };

    const lastRowIndex = useMemo(
        () =>
            Math.max(
                ...(updatedElement.columns
                    .flatMap((t) => t.cells)
                    .map((c) => c.rowIndex) || [-1]),
                -1
            ),
        [updatedElement.columns]
    );

    useEffect(() => {
        const rowIndexes =
            lastRowIndex > -1 ? [...Array(lastRowIndex + 1).keys()] : [];

        const rows: CustomTableRow[] = rowIndexes.map((rowIndex) => {
            const rowItem: CustomTableRow = {};
            updatedElement.columns.forEach((column) => {
                if (column.id) {
                    const cell = column.cells.find(
                        (c) => c.rowIndex === rowIndex
                    );
                    rowItem[column.id] = {
                        cellId: cell?.id || Guid.create().toString(),
                        content: cell?.content || '',
                    };
                }
            });
            return rowItem;
        });
        setListItems([...rows]);
    }, [updatedElement, lastRowIndex]);

    const handleAddRowButtonClick = () => {
        const modified: TemplateReportElementType = {
            ...updatedElement,
            columns: updatedElement.columns.map((col) => {
                return {
                    ...col,
                    cells: [
                        ...col.cells,
                        {
                            id: Guid.create().toString(),
                            rowIndex: lastRowIndex + 1,
                            content: '',
                        },
                    ],
                };
            }),
        };

        setUpdatedElement(modified);
        props.onElementChanged(modified, false);
    };

    const handleTitleUpdateClick = async (newTitle: string) => {
        const modified = {
            ...updatedElement,
            title: newTitle,
        };
        setUpdatedElement(modified);
        props.onElementChanged(modified, true);
    };

    const handleEditIconClick = () => {
        setIsEditOpen(true);
    };

    const handleTableChanged = (updatedElement: TemplateReportElementType) => {
        setUpdatedElement(updatedElement);
        props.onElementChanged(updatedElement, true);
    };

    const classNames = mergeStyleSets({
        title: {
            margin: 0,
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    });

    const dragEnterClass = mergeStyles({
        backgroundColor: currentTheme.palette.neutralLight,
    });

    const draggedItem = useRef<CustomTableRow | undefined>(undefined);

    const getDragDropEvents = (): IDragDropEvents => {
        return {
            onDrop: (droppedOnItem?: CustomTableRow): void => {
                if (draggedItem.current && droppedOnItem) {
                    moveRow(draggedItem.current, droppedOnItem);
                }
            },
            canDrop: () => {
                return true;
            },
            canDrag: () => !props.isReadOnly && isEditingRow === null,
            onDragEnter: (): string => {
                return dragEnterClass;
            },
            onDragLeave: (): void => {
                return;
            },
            onDragEnd: (): void => {
                draggedItem.current = undefined;
            },
            onDragStart: (item?: CustomTableRow): void => {
                if (item) {
                    draggedItem.current = item;
                }
            },
        };
    };

    return (
        <React.Fragment>
            <AdvanceCard>
                <AdvanceCard.Item>
                    <div className={classNames.header}>
                        <div>
                            <EditibleText
                                isReadOnly={props.isReadOnly}
                                isRequired
                                dialogTitle={
                                    updatedElement.title
                                        ? 'Edit Title'
                                        : 'Add Title'
                                }
                                text={updatedElement.title || ''}
                                onUpdateClick={handleTitleUpdateClick}
                            >
                                {!!updatedElement.title && (
                                    <h4 className={classNames.title}>
                                        {updatedElement.title}
                                    </h4>
                                )}
                            </EditibleText>
                        </div>
                        {!props.isReadOnly && (
                            <div>
                                <IconButton
                                    iconProps={{ iconName: 'Edit' }}
                                    onClick={handleEditIconClick}
                                />
                            </div>
                        )}
                    </div>
                </AdvanceCard.Item>
                <AdvanceCard.Item fill>
                    <DetailsList
                        dragDropEvents={getDragDropEvents()}
                        columns={columns}
                        items={listItems || []}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={(): boolean => false}
                        onRenderItemColumn={handleRenderItemColumn}
                        compact
                    />
                </AdvanceCard.Item>
                {!props.isReadOnly && (
                    <AdvanceCard.Item align="end">
                        <ActionButton
                            iconProps={{ iconName: 'Add' }}
                            text="Add Row"
                            onClick={handleAddRowButtonClick}
                        />
                    </AdvanceCard.Item>
                )}
            </AdvanceCard>
            <TemplateReportCustomTablePanel
                isOpen={isEditOpen}
                onDismiss={() => setIsEditOpen(false)}
                element={updatedElement}
                isReadOnly={props.isReadOnly}
                onElementChanged={handleTableChanged}
            />
            <DeleteModal
                isOpen={!!isDeleteRowConfirming}
                isDeleting={isDeletingRow}
                message="Are you sure you want to delete this row?"
                deleteAction={deleteRowAction}
                onDismiss={() => setIsDeleteRowConfirming(undefined)}
                error={null}
                activeViewName={'TemplateReportCustomTableRowDelete'}
            />
        </React.Fragment>
    );
}
