import React, { useEffect, useState } from 'react';
import groupBy from 'lodash/groupBy';
import {
    mergeStyleSets,
    Text,
    Label,
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    Checkbox,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import {
    GetResourceQuery,
    useGetResourceLazyQuery,
    useUpdateResourceTaskUserMutation,
} from '../../../data/types';
import { UserPicker } from '../../../components/UserPicker';
import { ExtractQueryType } from '../../../data/extendedTypes';
import { useStateContext } from '../../../services/contextProvider';

function useResourceTransfer(): {
    transferResourceAsync: (
        resourceId: string,
        targetUserId: string,
        fyCodes: string[]
    ) => Promise<{
        success: boolean;
        errorMessage?: string;
    }>;
} {
    const { currentTenantId } = useStateContext();

    const [getResourceAsync, { refetch }] = useGetResourceLazyQuery({
        fetchPolicy: 'network-only',
    });

    const [updateResourceTaskUser] = useUpdateResourceTaskUserMutation();

    const transferResourceAsync = async (
        resourceId: string,
        targetUserId: string,
        fyCodes: string[]
    ): Promise<{
        success: boolean;
        errorMessage?: string;
    }> => {
        try {
            const resourceQuery = await getResourceAsync({
                variables: {
                    id: resourceId,
                    tenantId: currentTenantId || '',
                },
            });

            const resource = resourceQuery.data?.resource;

            if (!resource) {
                throw new Error('Resource not found');
            }

            if (resource.userId === targetUserId) {
                throw new Error(
                    'The target user is the same as the selected resource'
                );
            }

            const tasksToTransfer = resource.tasks.filter((t) => {
                const owningMission = t.mission ?? t.resourcedFromTask?.mission;
                return fyCodes.some(
                    (fy) =>
                        owningMission?.team?.division?.financialYear?.code ===
                        fy
                );
            });

            if (tasksToTransfer.length === 0) {
                throw new Error('No dependencies found to transfer');
            } else {
                // Change the user on the selected tasks
                const updateResult = await updateResourceTaskUser({
                    variables: {
                        tenantId: currentTenantId || '',
                        taskIds: tasksToTransfer.map((t) => t.id || ''),
                        targetUserId: targetUserId,
                    },
                    refetchQueries: ['GetResources'],
                });
                if (updateResult.errors?.length) {
                    return {
                        success: false,
                        errorMessage: updateResult.errors[0].message,
                    };
                }
            }
        } catch (e) {
            if (typeof e === 'string') {
                return {
                    success: false,
                    errorMessage: e,
                };
            } else if (e instanceof Error) {
                return {
                    success: false,
                    errorMessage: e.message,
                };
            }
            throw e;
        }

        // reload the original resource
        await refetch();

        return {
            success: true,
        };
    };

    return {
        transferResourceAsync,
    };
}

export function ResourceTransferDialog(props: {
    resource?: ExtractQueryType<GetResourceQuery, ['resource']> | null;
    isOpen: boolean;
    onDismiss: () => void;
}): JSX.Element {
    const { transferResourceAsync } = useResourceTransfer();

    const [isTransferring, setIsTransferring] = useState(false);
    const [isTransferred, setIsTransferred] = useState(false);

    const [selectedUser, setSelectedUser] = useState<{
        userId: string;
        name: string;
    } | null>();

    const [selectedFyCodes, setSelectedFyCodes] = useState<string[]>([]);

    const [errorMessage, setErrorMessage] = useState<
        string | null | undefined
    >();

    useEffect(() => {
        if (props.isOpen) {
            setIsTransferring(false);
            setSelectedFyCodes([]);
            setSelectedUser(null);
            setIsTransferred(false);
            setErrorMessage(null);
        }
    }, [props.isOpen]);

    const handleTransferButtonClick = async () => {
        if (
            props.resource?.id &&
            selectedUser?.userId &&
            !isTransferring &&
            selectedFyCodes.length
        ) {
            setIsTransferring(true);
            setErrorMessage(null);

            const result = await transferResourceAsync(
                props.resource?.id,
                selectedUser?.userId,
                selectedFyCodes
            );

            if (result.success) {
                setErrorMessage(null);
                setIsTransferred(true);

                // Give them time to see the success message before dismiss
                await new Promise((res) => setTimeout(res, 1200));

                props.onDismiss();
            } else {
                setErrorMessage(result.errorMessage);
                setIsTransferred(false);
            }
        }
        setIsTransferring(false);
    };

    const handleUserChange = (
        users: {
            userId: string;
            name: string;
        }[]
    ): void => {
        const targetUser = users?.length > 0 ? users[0] : null;

        setSelectedUser(targetUser);
    };

    const handleFyChange = (
        fyCode: string | null | undefined,
        checked: boolean | undefined
    ) => {
        setSelectedFyCodes((prevState) => {
            if (checked && fyCode) {
                return [...prevState.filter((t) => t !== fyCode), fyCode];
            } else {
                return [...prevState.filter((t) => t !== fyCode)];
            }
        });
    };

    const selectedUsers = selectedUser
        ? [
              {
                  userId: selectedUser.userId,
                  displayName: selectedUser.name,
              },
          ]
        : [];

    const classNames = mergeStyleSets({
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
    });

    const canTransfer =
        props.resource?.id &&
        selectedUser?.userId &&
        !isTransferring &&
        selectedFyCodes.length &&
        !isTransferred;

    return (
        <Dialog
            hidden={!props.isOpen}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Transfer Dependencies',
                closeButtonAriaLabel: 'Close',
                subText:
                    'Transfer the dependencies for this resource to another user',
            }}
            onDismiss={props.onDismiss}
        >
            <div className={classNames.formContainer}>
                {isTransferred && (
                    <MessageBar messageBarType={MessageBarType.success}>
                        Resource Transferred
                    </MessageBar>
                )}

                {!isTransferred && errorMessage && (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline
                    >
                        {errorMessage}
                    </MessageBar>
                )}

                <UserPicker
                    onChange={handleUserChange}
                    itemLimit={1}
                    selectedUsers={selectedUsers}
                    disabled={isTransferring}
                />

                <div>
                    <Label>Select FYs</Label>
                    <ResourceTransferFinancialYearPicker
                        selectedFyCodes={selectedFyCodes}
                        onChange={handleFyChange}
                        disabled={isTransferring}
                        resource={props.resource}
                    />
                </div>
            </div>

            <DialogFooter>
                <PrimaryButton
                    text="Transfer"
                    disabled={isTransferring || !canTransfer}
                    onClick={handleTransferButtonClick}
                />
                <DefaultButton
                    text="Cancel"
                    disabled={isTransferring}
                    onClick={(): void => {
                        props.onDismiss();
                    }}
                />
            </DialogFooter>
        </Dialog>
    );
}

function ResourceTransferFinancialYearPicker(props: {
    disabled?: boolean;
    selectedFyCodes: string[];
    onChange: (
        fyCode: string | null | undefined,
        checked: boolean | undefined
    ) => void;
    resource?: ExtractQueryType<GetResourceQuery, ['resource']> | null;
}): JSX.Element {
    const classNames = mergeStyleSets({
        checkboxContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
        missionBreakdown: {
            paddingLeft: 28,
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
        },
    });

    const tasks = props.resource?.tasks;

    const fys = [
        ...new Set(
            tasks?.map((t) => {
                const owningMission = t.mission ?? t.resourcedFromTask?.mission;
                return owningMission?.team?.division?.financialYear?.code;
            })
        ),
    ];

    const fyBreakdown = fys.map((fy) => {
        const fyTasks = tasks?.filter((t) => {
            const owningMission = t.mission ?? t.resourcedFromTask?.mission;
            return owningMission?.team?.division?.financialYear?.code === fy;
        });

        const acceptedIntoMissions = groupBy(fyTasks, (t) =>
            t.mission ? [t.mission?.owner, t.mission?.title].join(' - ') : ''
        );

        const missionBreakdown: {
            missionName: string | null;
            count: number;
        }[] = [];

        for (const mission in acceptedIntoMissions) {
            const tasks = acceptedIntoMissions[mission as string];
            const missionName: string | null = mission;
            missionBreakdown.push({
                missionName: missionName || null,
                count: tasks.length,
            });
        }

        return {
            code: fy,
            missions: missionBreakdown,
        };
    });

    return (
        <div className={classNames.checkboxContainer}>
            {fyBreakdown.map((fy) => {
                return (
                    <div key={fy.code || 'None'}>
                        <Checkbox
                            label={fy.code || 'No Code'}
                            disabled={props.disabled}
                            checked={props.selectedFyCodes.some(
                                (c) => c === fy.code
                            )}
                            onChange={(_ev, checked) =>
                                props.onChange(fy.code, checked)
                            }
                        />
                        <div className={classNames.missionBreakdown}>
                            {fy.missions.map((m) => {
                                return (
                                    <Text
                                        variant="tiny"
                                        block
                                        key={m.missionName || 'Unaccepted'}
                                    >
                                        {m.missionName || 'Unaccepted'}:{' '}
                                        {m.count}
                                    </Text>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
