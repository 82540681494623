import { useLayoutEffect, useState } from 'react';

export const useViewport = (): { width: number; isTouchDevice: boolean } => {
    const [width, setWidth] = useState(window.innerWidth);

    const isTouchDevice =
        'ontouchstart' in window || navigator.maxTouchPoints > 0;

    useLayoutEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return { width, isTouchDevice };
};
