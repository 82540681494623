import React from 'react';
import { IDropdownOption, Dropdown } from '@fluentui/react';
import { useGetMeasureGroupsQuery } from '../data/types';
import { InputShimmer } from './inputs';

export default function MeasureGroupDropdown(props: {
    tenantId: string;
    missionId: string;
    disabled?: boolean;
    defaultSelectedKey?: string | null | undefined;
    label: string;
    onChange: (measureGroupId: string | null) => void;
}): JSX.Element | null {
    const { data: measureGroupsData, loading: measureGroupsLoading } =
        useGetMeasureGroupsQuery({
            skip: !props.tenantId || !props.missionId,
            variables: {
                tenantId: props.tenantId || '',
                missionId: props.missionId || '',
            },
        });

    const measureGroupOptions: IDropdownOption[] | undefined =
        measureGroupsData?.measureGroups?.map((d) => {
            return {
                key: d.id || '',
                text: d.name || d.id || '',
            };
        });

    if (!measureGroupsLoading && !measureGroupOptions?.length) {
        return null;
    }

    return (
        <InputShimmer isDataLoaded={!measureGroupsLoading}>
            {measureGroupOptions && (
                <Dropdown
                    options={[
                        {
                            key: '',
                            text: 'Ungrouped',
                        },
                        ...measureGroupOptions,
                    ]}
                    defaultSelectedKey={props.defaultSelectedKey}
                    placeholder="Ungrouped"
                    disabled={props.disabled}
                    onChange={(
                        _event: React.FormEvent,
                        option?: IDropdownOption | undefined
                    ): void => {
                        props.onChange(
                            option?.key ? (option.key as string) : null
                        );
                    }}
                    label={props.label}
                />
            )}
        </InputShimmer>
    );
}
