import React from 'react';
import {
    Dropdown,
    DropdownMenuItemType,
    IDropdownOption,
    Label,
    Stack,
} from '@fluentui/react';
import FunctionHelpText from './FunctionHelpText';

type Props = {
    selectedFunction: string | undefined;
    setSelectedFunction: (value: string | undefined) => void;
};

function FunctionHelp(props: Props): JSX.Element {
    const { selectedFunction, setSelectedFunction } = props;

    return (
        <Stack
            horizontal
            tokens={{
                childrenGap: 36,
            }}
        >
            <Stack>
                <Label>Function Help</Label>
                <Dropdown
                    placeholder="Select an option"
                    // label="Function"
                    options={[
                        {
                            key: 'generalHeader',
                            text: 'General',
                            itemType: DropdownMenuItemType.Header,
                        },
                        {
                            key: 'general',
                            text: 'Examples',
                        },
                        {
                            key: 'series',
                            text: 'Series',
                        },
                        {
                            key: 's0',
                            text: '-',
                            itemType: DropdownMenuItemType.Divider,
                        },
                        {
                            key: 'functionsHeader',
                            text: 'Functions:',
                            itemType: DropdownMenuItemType.Header,
                        },
                        { key: 'add', text: 'add' },
                        { key: 'subtract', text: 'subtract' },
                        { key: 'multiply', text: 'multiply' },
                        { key: 'divide', text: 'divide' },
                        {
                            key: 's1',
                            text: '-',
                            itemType: DropdownMenuItemType.Divider,
                        },
                        { key: 'mean', text: 'average | avg | mean' },
                        { key: 'median', text: 'median' },
                        {
                            key: 's2',
                            text: '-',
                            itemType: DropdownMenuItemType.Divider,
                        },
                        { key: 'min', text: 'min' },
                        { key: 'max', text: 'max' },
                        { key: 'random', text: 'random' },
                        {
                            key: 's3',
                            text: '-',
                            itemType: DropdownMenuItemType.Divider,
                        },
                        { key: 'lookup', text: 'lookup' },
                    ]}
                    styles={{
                        dropdown: { width: 300 },
                    }}
                    selectedKey={selectedFunction}
                    onChange={(
                        _event: React.FormEvent<HTMLDivElement>,
                        item: IDropdownOption | undefined
                    ): void => {
                        setSelectedFunction(item?.key as string);
                    }}
                />
            </Stack>
            <FunctionHelpText selectedFunction={selectedFunction} />
        </Stack>
    );
}

export default FunctionHelp;
