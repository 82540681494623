import { mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { Markdown } from '../../Presentations/Markdown';
import { TemplateReportElementType } from '../TemplateReport';

export function TemplateReportLabelElement(props: {
    element: TemplateReportElementType;
}) {
    const { element } = props;

    const classNames = mergeStyleSets({
        markdown: {
            p: {
                marginBottom: 8,
            },
        },
    });

    return (
        <Markdown
            className={classNames.markdown}
            source={element.textContent?.trim()}
        />
    );
}
