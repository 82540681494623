import * as React from 'react';
import PropTypes from 'prop-types';

import {
    ShimmeredDetailsList,
    SelectionMode,
    IColumn,
    DetailsListLayoutMode,
    IconButton,
    Icon,
    Stack,
    TooltipHost,
    Persona,
    PersonaSize,
    Toggle,
    Shimmer,
    Text,
    Link,
    IButtonStyles,
} from '@fluentui/react';

import { useSorter } from '../../../hooks/useSorter';
import { photoService } from '../../../services/photo.service';
import {
    GetUsersQuery,
    useGetTenantsQuery,
    UserRoleQl,
    UserTenantQl,
    useUpdateUserMutation,
} from '../../../data/types';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import DeleteModal from '../../../components/shared/DeleteModal';
import { DetailsListCellItemContainer } from '../../../components/shared/DetailsListCellItemContainer';
import dayjs from 'dayjs';
import { useStateContext } from '../../../services/contextProvider';
import { useThemes } from '../../../hooks/useThemes';
import { useEffect, useState } from 'react';
import HeaderSummaryBar from '../../MissionBuilder/components/HeaderSummaryBar';

type UserQL = ExtractQueryArrayType<GetUsersQuery, ['userSearch']>;

export type UserDetailListProps = {
    users: UserQL[];
    missions?:
        | {
              id: string | null;
              userId: string | null;
              owner: string | null;
              title: string | null;
              utcInactive: string | null;
          }[]
        | null;
    contributors?:
        | {
              id: string | null;
              userId: string | null;
              team: {
                  name: string | null;
              } | null;
          }[]
        | null;
    rights?:
        | {
              userId: string | null;
              write: boolean;
              read: boolean;
              import: boolean;
          }[]
        | null;
    isDataLoaded: boolean;
    hideDashboards?: boolean | undefined;
    onItemEdit: (selectedId: string) => void;
    onAccessEdit: ((selectedId: string) => void) | null;
    onSetupMissionClick?: (userId: string, missionId: string | null) => void;
    onSetupContributorClick?: (
        userId: string,
        contributorId: string | null
    ) => void;
    fyCodeFilter?: string | null;
};

export function UserDetailList(props: UserDetailListProps): JSX.Element {
    const { currentTenantId, currentTenantCode } = useStateContext();

    const { currentTheme } = useThemes();

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(props.users, 'displayName', false, true);

    const [updateUser, error] = useUpdateUserMutation();

    const { data: tenantData } = useGetTenantsQuery();

    function getTenantDescription(tenantId: string) {
        // Optimisation to avoid returning tenant description for each user in userSearch
        return (
            tenantData?.tenants?.find((t) => t.id === tenantId)?.description ??
            ''
        );
    }

    const [userBreakdown, setUserBreakdown] = useState<{
        totalUsers: number;
        clientAdmin: number;
        missionOwners: number;
        activeMissionOwners: number;
        contributors: number;
        editors: number;
        viewers: number;
    }>();

    useEffect(() => {
        if (
            props.fyCodeFilter &&
            props.missions &&
            props.contributors &&
            props.rights
        ) {
            const breakDown = {
                totalUsers: 0,
                clientAdmin: 0,
                missionOwners: 0,
                activeMissionOwners: 0,
                contributors: 0,
                editors: 0,
                viewers: 0,
            };

            if (!currentTenantCode) {
                throw 'currentTenantCode is null';
            }

            props.users
                .filter(
                    (u) =>
                        currentTenantCode === 'gps' ||
                        currentTenantCode === 'gfs' ||
                        currentTenantCode.indexOf('demo') > -1 ||
                        currentTenantCode.indexOf('example') > -1 ||
                        (!u.emailAddress?.endsWith('gpstrategies.com') &&
                            !u.emailAddress?.endsWith('mckinneyrogers.com') &&
                            !u.emailAddress?.endsWith('xlineconsulting.com') &&
                            !u.emailAddress?.endsWith('advancedashboard.com') &&
                            !u.userRoles.some((r) => r.name === 'GlobalAdmin'))
                )
                .forEach((u) => {
                    breakDown.totalUsers++;

                    const userMissions = props.missions?.filter(
                        (m) => m.userId === u.id
                    );

                    if (userMissions?.length) {
                        breakDown.missionOwners++;
                        if (userMissions.some((m) => !m.utcInactive)) {
                            breakDown.activeMissionOwners++;
                        }
                    } else if (
                        props.contributors?.some((c) => c.userId === u.id)
                    ) {
                        breakDown.contributors++;
                    } else if (
                        u.userRoles.some(
                            (r) =>
                                r.name === 'ClientAdmin' &&
                                r.tenantId === currentTenantId
                        )
                    ) {
                        breakDown.clientAdmin++;
                    } else if (
                        props.rights?.some(
                            (r) => r.userId === u.id && (r.write || r.import)
                        )
                    ) {
                        breakDown.editors++;
                    } else if (
                        props.rights?.some((r) => r.userId === u.id && r.read)
                    ) {
                        breakDown.viewers++;
                    }
                });

            setUserBreakdown(breakDown);
        } else {
            setUserBreakdown(undefined);
        }
    }, [
        props.users,
        props.fyCodeFilter,
        props.missions,
        props.contributors,
        props.rights,
        currentTenantCode,
        currentTenantId,
    ]);

    const changeAccessEnabled = (userId: string, isEnabled: boolean): void => {
        const user = props.users.find((u) => u.id === userId);

        if (user?.id) {
            updateUser({
                variables: {
                    input: {
                        id: user.id,
                        displayName: user.displayName,
                        emailAddress: user.emailAddress,
                        accessEnabled: isEnabled,
                        utcSelfHelpEnabledUntil: user.utcSelfHelpEnabledUntil,
                        userRoles: user.userRoles.map((ur) => {
                            return {
                                id: ur.id,
                                tenantId: ur.tenantId,
                                roleId: ur.roleId,
                                userId: ur.userId,
                            };
                        }),
                        userTenants: user.userTenants,
                        version: user?.version,
                    },
                },
                optimisticResponse: {
                    __typename: 'Mutations',
                    userUpdate: {
                        __typename: 'UserQL',
                        id: user.id,
                        displayName: user.displayName,
                        emailAddress: user.emailAddress,
                        accessEnabled: isEnabled,
                        utcSelfHelpEnabledUntil: user.utcSelfHelpEnabledUntil,
                        userRoles: user.userRoles,
                        userTenants: user.userTenants,
                        utcUpdated: user.utcUpdated,
                        version: user?.version,
                    },
                },
            });
        }
    };

    const changeSelfHelpEnabledUntil = (
        userId: string,
        utcSelfHelpEnabledUntil: string | null
    ): void => {
        const user = props.users.find((u) => u.id === userId);

        if (user?.id) {
            updateUser({
                variables: {
                    input: {
                        id: user.id,
                        displayName: user.displayName,
                        emailAddress: user.emailAddress,
                        accessEnabled: user.accessEnabled,
                        utcSelfHelpEnabledUntil: utcSelfHelpEnabledUntil,
                        userRoles: user.userRoles.map((ur) => {
                            return {
                                id: ur.id,
                                tenantId: ur.tenantId,
                                roleId: ur.roleId,
                                userId: ur.userId,
                            };
                        }),
                        userTenants: user.userTenants,
                        version: user?.version,
                    },
                },
                optimisticResponse: {
                    __typename: 'Mutations',
                    userUpdate: {
                        __typename: 'UserQL',
                        id: user.id,
                        displayName: user.displayName,
                        emailAddress: user.emailAddress,
                        accessEnabled: user.accessEnabled,
                        utcSelfHelpEnabledUntil: utcSelfHelpEnabledUntil,
                        userRoles: user.userRoles,
                        userTenants: user.userTenants,
                        utcUpdated: user.utcUpdated,
                        version: user?.version,
                    },
                },
            });
        }
    };

    const onEditButtonClick = (userId: string) => {
        props.onItemEdit(userId);
    };

    const onAccessButtonClick = (userId: string) => {
        if (props.onAccessEdit) {
            props.onAccessEdit(userId);
        }
    };

    const buttonStyles: IButtonStyles = {
        root: {
            color: currentTheme.palette.themeLighter,
            selectors: {
                '&:hover': {
                    color: currentTheme.palette.themePrimary,
                },
            },
        },
        icon: {
            margin: 0,
        },
    };

    let columns: IColumn[] = [
        {
            key: 'photo',
            name: '',
            fieldName: 'displayName',
            isIconOnly: true,
            isResizable: false,
            isPadded: false,
            minWidth: 24,
            maxWidth: 24,
            onRender: function renderPhoto(item: {
                id: string;
                displayName: string | null;
                emailAddress: string | null;
            }): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        <Persona
                            text={
                                item.displayName || item.emailAddress || item.id
                            }
                            hidePersonaDetails
                            imageUrl={photoService.getImageUrl(item.id)}
                            size={PersonaSize.size24}
                        />
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'displayName',
            name: 'Name',
            fieldName: 'displayName',
            isRowHeader: true,
            isMultiline: true,
            isResizable: true,
            minWidth: 200,
            maxWidth: 300,
            isPadded: true,
            isSorted: sortColumn === 'displayName',
            isSortedDescending: sortColumn === 'displayName' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('displayName', true),
            onRender: function renderDisplayName(item: {
                displayName: string;
                userRoles: UserRoleQl[];
            }): JSX.Element {
                const adminRoles = item.userRoles
                    .filter(
                        (r) =>
                            r.name === 'GlobalAdmin' ||
                            r.name === 'Developer' ||
                            (r.name === 'ClientAdmin' &&
                                r.tenantId === currentTenantId)
                    )
                    .map((r) => ({
                        key: r.id,
                        name: r.name,
                        displayName:
                            r.name === 'ClientAdmin'
                                ? 'Client Admin'
                                : r.name === 'GlobalAdmin'
                                  ? 'Global Admin'
                                  : r.name,
                    }));
                return (
                    <DetailsListCellItemContainer>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            <Stack.Item>{item.displayName}</Stack.Item>
                            {adminRoles.length > 0 && (
                                <TooltipHost
                                    content={adminRoles
                                        .map((ar) => ar.displayName)
                                        .join(', ')}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 2,
                                        }}
                                    >
                                        {adminRoles.map((ar) => {
                                            switch (ar.name) {
                                                case 'GlobalAdmin':
                                                    return (
                                                        <Icon
                                                            key={ar.key}
                                                            iconName="Admin"
                                                        />
                                                    );
                                                case 'Developer':
                                                    return (
                                                        <Icon
                                                            key={ar.key}
                                                            iconName="DeveloperTools"
                                                        />
                                                    );
                                                case 'ClientAdmin':
                                                    return (
                                                        <Icon
                                                            key={ar.key}
                                                            iconName="Crown"
                                                        />
                                                    );
                                            }

                                            return <Icon key={ar.key} />;
                                        })}
                                    </div>
                                </TooltipHost>
                            )}
                        </Stack>
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'emailAddress',
            name: 'Email Address',
            fieldName: 'emailAddress',
            minWidth: 200,
            isMultiline: false,
            isResizable: true,
            isPadded: true,
            isSorted: sortColumn === 'emailAddress',
            isSortedDescending: sortColumn === 'emailAddress' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('emailAddress', true),
        },
        {
            key: 'mission',
            name: 'Mission',
            fieldName: 'id',
            minWidth: 300,
            isMultiline: false,
            isResizable: true,
            isPadded: true,
            onRender: function renderMission(item: {
                id: string;
            }): JSX.Element {
                const mission = props.missions?.find(
                    (m) => m.userId === item.id
                );

                const contributor = props.contributors?.find(
                    (m) => m.userId === item.id
                );

                return (
                    <DetailsListCellItemContainer>
                        {!props.missions || !props.contributors ? (
                            <Shimmer width={200} />
                        ) : !!mission && props.fyCodeFilter ? (
                            <>
                                {!!mission.utcInactive && (
                                    <span>
                                        <Icon
                                            iconName="Blocked12"
                                            title="Inactive"
                                        />
                                        &nbsp;
                                    </span>
                                )}
                                <Link
                                    onClick={() =>
                                        props.onSetupMissionClick
                                            ? props.onSetupMissionClick(
                                                  item.id,
                                                  mission.id
                                              )
                                            : {}
                                    }
                                >
                                    {mission?.owner} - {mission?.title}
                                </Link>
                            </>
                        ) : contributor ? (
                            <Text variant="small">
                                <Link
                                    onClick={() =>
                                        props.onSetupContributorClick
                                            ? props.onSetupContributorClick(
                                                  item.id,
                                                  contributor.id
                                              )
                                            : {}
                                    }
                                >
                                    Contributor
                                </Link>
                                {contributor.team
                                    ? ` (${contributor.team?.name})`
                                    : null}
                            </Text>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 8,
                                }}
                            >
                                <Link
                                    style={{
                                        color: currentTheme.palette
                                            .neutralTertiaryAlt,
                                    }}
                                    onClick={() =>
                                        props.onSetupMissionClick
                                            ? props.onSetupMissionClick(
                                                  item.id,
                                                  null
                                              )
                                            : {}
                                    }
                                >
                                    Setup Mission
                                </Link>
                                <span>·</span>
                                <Link
                                    style={{
                                        color: currentTheme.palette
                                            .neutralTertiaryAlt,
                                    }}
                                    onClick={() =>
                                        props.onSetupContributorClick
                                            ? props.onSetupContributorClick(
                                                  item.id,
                                                  null
                                              )
                                            : {}
                                    }
                                >
                                    Setup Contributor
                                </Link>
                            </div>
                        )}
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'accessEnabled',
            name: 'Active?',
            fieldName: 'accessEnabled',
            minWidth: 100,
            isResizable: true,
            isSorted: sortColumn === 'accessEnabled',
            isSortedDescending: sortColumn === 'accessEnabled' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('accessEnabled'),
            onRender: function renderEnabled(item: {
                id: string;
                accessEnabled: boolean;
            }): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        <Toggle
                            styles={{
                                root: {
                                    marginBottom: 0,
                                },
                            }}
                            onText="Yes"
                            offText="No"
                            checked={item.accessEnabled}
                            onChange={(
                                _ev: React.MouseEvent<HTMLElement>,
                                checked?: boolean
                            ): void => {
                                if (checked) {
                                    changeAccessEnabled(
                                        item.id,
                                        checked || false
                                    );
                                } else {
                                    setAccessChange({
                                        id: item.id,
                                        checked: checked || false,
                                    });
                                    setIsConfirmingActive(true);
                                }
                            }}
                        />
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'selfHelp',
            name: 'Self Help?',
            fieldName: 'utcSelfHelpEnabledUntil',
            minWidth: 100,
            isResizable: true,
            isSorted: sortColumn === 'utcSelfHelpEnabledUntil',
            isSortedDescending:
                sortColumn === 'utcSelfHelpEnabledUntil' && sortIsDesc,
            onColumnClick: (): void =>
                setSortColumnName('utcSelfHelpEnabledUntil'),
            onRender: function renderEnabled(item: {
                id: string;
                utcSelfHelpEnabledUntil: string | null;
            }): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        <Toggle
                            styles={{
                                root: {
                                    marginBottom: 0,
                                },
                            }}
                            onText="Yes"
                            offText="No"
                            checked={
                                item.utcSelfHelpEnabledUntil !== null &&
                                dayjs(item.utcSelfHelpEnabledUntil).isAfter(
                                    dayjs.utc()
                                )
                            }
                            onChange={(
                                _ev: React.MouseEvent<HTMLElement>,
                                checked?: boolean
                            ): void => {
                                const date = checked
                                    ? dayjs().add(3, 'month').toISOString()
                                    : null;
                                changeSelfHelpEnabledUntil(item.id, date);
                            }}
                        />
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'tenants',
            name: 'Tenants',
            minWidth: 400,
            isMultiline: true,
            isResizable: true,
            onRender: function renderTenants(item: {
                userTenants: UserTenantQl[];
            }): JSX.Element {
                return (
                    <span>
                        {item.userTenants.length
                            ? item.userTenants
                                  .map((ut) =>
                                      getTenantDescription(ut.tenantId)
                                  )
                                  .join(', ')
                            : ''}
                    </span>
                );
            },
        },
        {
            key: 'actions',
            name: '',
            minWidth: props.onAccessEdit ? 64 : 32,
            maxWidth: props.onAccessEdit ? 64 : 32,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderActions(item: {
                id: string;
            }): JSX.Element {
                return (
                    <>
                        {props.onAccessEdit && (
                            <IconButton
                                key={`access-${item.id}`}
                                iconProps={{
                                    iconName: props.rights?.find(
                                        (r) => r.userId === item.id
                                    )
                                        ? 'LockSolid'
                                        : 'Lock',
                                }}
                                onClick={(): void => {
                                    onAccessButtonClick(item.id);
                                }}
                                styles={buttonStyles}
                            />
                        )}
                        <IconButton
                            key={`edit-${item.id}`}
                            iconProps={{ iconName: 'Edit' }}
                            onClick={(): void => {
                                onEditButtonClick(item.id);
                            }}
                            styles={buttonStyles}
                        />
                    </>
                );
            },
        },
    ];

    if (props.hideDashboards) {
        columns = columns.filter((c) => c.key !== 'tenants');
    }

    if (!currentTenantId || !props.fyCodeFilter) {
        columns = columns.filter(
            (c) => c.key !== 'mission' && c.key !== 'contributor'
        );
    }

    const [isConfirmingActive, setIsConfirmingActive] = React.useState(false);
    const [isChangingActive, setIsChangingActive] = React.useState(false);
    const [accessChange, setAccessChange] = React.useState<{
        id: string;
        checked: boolean;
    }>();

    const activeChangeAction = async (): Promise<void> => {
        if (accessChange) {
            setIsChangingActive(true);
            changeAccessEnabled(accessChange.id, accessChange.checked || false);
        }
        setIsConfirmingActive(false);
        setIsChangingActive(false);
    };

    function renderItemColumn(
        item?: UserQL,
        _index?: number,
        column?: IColumn
    ): JSX.Element {
        if (!column || !item) {
            return <span />;
        }
        return (
            <DetailsListCellItemContainer>
                {
                    item[column.fieldName as keyof UserQL] as
                        | React.ReactNode
                        | string
                }
            </DetailsListCellItemContainer>
        );
    }

    return (
        <>
            {!!userBreakdown && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'end',
                        paddingRight: 16,
                    }}
                >
                    <HeaderSummaryBar
                        items={[
                            {
                                text: 'Mission Owners',
                                count: userBreakdown?.missionOwners,
                                iconColour: currentTheme.palette.neutralPrimary,
                                iconName: 'Trending12',
                                hoverText: `${userBreakdown?.activeMissionOwners} users with active missions`,
                            },
                            {
                                text: 'Contributors',
                                count: userBreakdown?.contributors,
                                iconColour: currentTheme.palette.neutralPrimary,
                                iconName: 'CustomList',
                            },
                            {
                                text: 'Client Admins',
                                count: userBreakdown?.clientAdmin,
                                iconColour: currentTheme.palette.neutralPrimary,
                                iconName: 'Crown',
                            },
                            {
                                text: 'Importers/Editors',
                                count: userBreakdown?.editors,
                                iconColour: currentTheme.palette.neutralPrimary,
                                iconName: 'PenWorkspace',
                            },
                            {
                                text: 'Viewers',
                                count: userBreakdown?.viewers,
                                iconColour: currentTheme.palette.neutralPrimary,
                                iconName: 'SeeDo',
                            },
                        ]}
                    />
                </div>
            )}

            <ShimmeredDetailsList
                compact={false}
                items={sortedItems}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={!props.isDataLoaded}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details"
                onRenderItemColumn={renderItemColumn}
                onItemInvoked={(item: { id: string }): void => {
                    props.onItemEdit(item.id);
                }}
            />

            <DeleteModal
                activeViewName="UserDelete"
                isOpen={isConfirmingActive}
                primaryButtonText="Confirm"
                onDismiss={(): void => setIsConfirmingActive(false)}
                message={
                    'Are you sure you want to deactive this user? They will no longer be able to access the system.'
                }
                deleteAction={activeChangeAction}
                isDeleting={isChangingActive}
                error={error.error}
            />
        </>
    );
}

UserDetailList.propTypes = {
    onItemEdit: PropTypes.func.isRequired,
};
