import React from 'react';
import {
    DetailsRow,
    IColumn,
    IDetailsRowProps,
    IDetailsRowStyles,
    Link,
    mergeStyleSets,
    Text,
} from '@fluentui/react';
import {
    Access,
    ExtractQueryArrayType,
    MeasureSeriesNames,
} from '../data/extendedTypes';
import { GetAllMeasuresQuery, GetMissionMeasuresQuery } from '../data/types';
import orderBy from 'lodash/orderBy';
import { FlatList } from './FlatList';
import MeasureArrow from './MeasureArrow';
import dayjs from 'dayjs';
import { useFormatters } from '../hooks/useFormatters';
import { measureListExport } from '../services/exporter';
import { useStateContext } from '../services/contextProvider';
import { MissionFilters } from '../scenes/MissionBuilder/components/MissionFilterBar';
import { useMeasureWarning } from '../hooks/useMeasureWarning';
import WarningButton from './WarningButton';
import { useThemes } from '../hooks/useThemes';
import { MeasureStatusColours } from '../Colours';
import { MeasureHistorySparkline } from './MeasureHistorySparkline';
import { DetailsListCellItemContainer } from './shared/DetailsListCellItemContainer';
import { useComments } from '../hooks/useComments';

type MeasureType =
    | ExtractQueryArrayType<GetAllMeasuresQuery, ['measures']>
    | ExtractQueryArrayType<GetMissionMeasuresQuery, ['measures']>;

export type MeasureFlatListItem = MeasureType & {
    mission?: {
        id: string | null;
        title: string | null;
        owner: string | null;
        team: {
            id: string | null;
            code: string | null;
            name: string | null;
        } | null;
        leaderOfTeams: {
            id: string | null;
            code: string | null;
            name: string | null;
        }[];
        rights: Access;
    } | null;
    group: {
        name: string | null;
        sequence: number;
    } | null;
};

export type ResultType = MeasureFlatListItem & {
    displaySequence: number;
    measureId: string | null;
    warning: string | null;
    missionName: string | null;
    team?: string | null;
    teamCode?: string | null;
    teamName?: string | null;
    target: string | null;
    actual: string | null;
    targetValue: string | number | null;
    actualValue: string | number | null;
    status: string | null;
    statusValueRestricted: number | null;
    isLeader: boolean;
    asOfDate: string | null;
    sparkline: string | null;
    groupName: string | null;
    lastCommentText: string | null;
    linkedFromUserId: string | null;
    linkedFrom: string | null;
};

export type MeasureFlatListProps = {
    teamId?: string | undefined;
    missionId?: string | undefined;
    missionAccess?: Access; // if mission was specified
    measures: MeasureFlatListItem[] | null | undefined;
    measuresLoading: boolean;
    onMeasureClick: (missionId: string, measureId: string) => void;
    defaultColumns?: (keyof ResultType)[];
    initialSort?: string[];
    initialSortOrders?: (boolean | 'asc' | 'desc')[];
    filters?: MissionFilters;
    onRefreshClick?: () => void | Promise<void>;
    isRefreshing?: boolean;
};

export function MeasureFlatList(props: MeasureFlatListProps): JSX.Element {
    const { currentTenantId, configuration } = useStateContext();
    const formatters = useFormatters();
    const { currentTheme } = useThemes();
    const { renderCommentText } = useComments();

    type MeasureColumn = {
        sortFieldName?: keyof ResultType;
        fieldName: keyof ResultType;
        name: string;
        isString: boolean;
        align?: 'left' | 'right';
        exportHidden?: boolean;
    } & Partial<IColumn>;

    const availableColumns: MeasureColumn[] = [
        {
            fieldName: 'displaySequence',
            name: '#',
            isString: false,
            minWidth: 48,
            maxWidth: 48,
        },
        {
            fieldName: 'measureId',
            name: 'ID',
            isString: false,
            minWidth: 220,
            maxWidth: 220,
        },
        {
            fieldName: 'groupName',
            name: 'Group',
            isString: true,
            minWidth: 150,
            maxWidth: 300,
        },
        {
            fieldName: 'name',
            name: 'Name',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
            isRowHeader: true,
        },
        {
            fieldName: 'target',
            sortFieldName: 'targetValue',
            name: 'Target',
            isString: false,
            minWidth: 100,
            isRowHeader: true,
            align: 'right',
        },
        {
            fieldName: 'actual',
            sortFieldName: 'actualValue',
            name: 'Actual',
            isString: false,
            minWidth: 100,
            align: 'right',
        },
        {
            fieldName: 'status',
            sortFieldName: 'statusValueRestricted',
            name: 'Status',
            isString: false,
            minWidth: 80,
            maxWidth: 80,
            align: 'right',
        },
        {
            fieldName: 'sparkline',
            name: 'Sparkline',
            sortFieldName: 'statusValueRestricted',
            isString: false,
            minWidth: 100,
            maxWidth: 100,
            align: 'right',
            isPadded: false,
            exportHidden: true,
        },
        {
            fieldName: 'asOfDate',
            name: 'As Of',
            isString: false,
            minWidth: 80,
            maxWidth: 80,
            align: 'right',
        },
        {
            fieldName: 'linkedFrom',
            name: 'Linked From',
            isString: true,
            minWidth: 200,
            align: 'right',
        },
        {
            fieldName: 'lastCommentText',
            name: 'Latest Comment',
            isString: true,
        },
    ];

    if (!props.teamId && !props.missionId) {
        availableColumns.splice(2, 0, {
            fieldName: 'teamName',
            sortFieldName: 'teamName',
            name: 'Team Name',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        });

        availableColumns.splice(2, 0, {
            fieldName: 'teamCode',
            sortFieldName: 'teamCode',
            name: 'Team Code',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        });

        availableColumns.splice(2, 0, {
            fieldName: 'team',
            sortFieldName: 'teamCode',
            name: 'Team',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        });
    }

    if (!props.missionId) {
        availableColumns.splice(2, 0, {
            fieldName: 'missionName',
            name: 'Mission',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        });
    }

    const defaultOrder = [
        ...(props.initialSort ?? []),
        'missionName',
        'sortSequence',
    ];

    const measures: ResultType[] =
        props.measures?.slice().map((m) => {
            const targetValue = m?.lastAsOf?.values?.find(
                (v) => v?.seriesType?.name === MeasureSeriesNames.Target
            );

            const actualValue = m?.lastAsOf?.values?.find(
                (v) => v?.seriesType?.name === MeasureSeriesNames.Actual
            );

            let statusValueRestricted = m.lastAsOf?.statusValue || 0;
            if (m.isStatusLimited) {
                statusValueRestricted = Math.min(statusValueRestricted, 1);
                statusValueRestricted = Math.max(statusValueRestricted, 0);
            }

            const formattedStatus = formatters.formatMeasureStatus(
                m.lastAsOf?.statusValue,
                m.isStatusLimited
            );

            const linkedFromMission = m.isLinked
                ? m.linkedFromMeasure?.mission
                : null;

            return {
                ...m,
                displaySequence: 0,
                measureId: m.id,
                sortSequence: (m.group?.sequence ?? 100) * 1000 + m.sequence,
                warning: '',
                team: `${m.mission?.team?.code} (${m.mission?.team?.name})`,
                teamName: m.mission?.team?.name,
                teamCode: m.mission?.team?.code,
                missionName: m.mission
                    ? [m.mission.owner, m.mission.title].join(' - ')
                    : null,
                groupName: m.group?.name || null,
                target: targetValue?.formatStr || null,
                actual: actualValue?.formatStr || null,
                targetValue:
                    targetValue?.decimalValue ||
                    targetValue?.dateValue ||
                    targetValue?.stringValue ||
                    null,
                actualValue:
                    actualValue?.decimalValue ||
                    actualValue?.dateValue ||
                    actualValue?.stringValue ||
                    null,
                statusValueRestricted: statusValueRestricted,
                status: formattedStatus,
                asOfDate: m.lastAsOf?.asOfDate
                    ? dayjs(m.lastAsOf?.asOfDate).format('DD MMM YYYY')
                    : '',
                sparkline: null,
                isLeader:
                    m.mission?.leaderOfTeams?.some(
                        (t) => t.id === props.teamId
                    ) || false,
                lastCommentText: m.lastComment?.text || null,
                linkedFromUserId: linkedFromMission?.userId || null,
                linkedFrom: linkedFromMission
                    ? [linkedFromMission.owner, linkedFromMission.title].join(
                          ' - '
                      )
                    : null,
            };
        }) || [];

    orderBy(measures || [], defaultOrder, props.initialSortOrders).forEach(
        (t, i) => {
            t.displaySequence = i + 1;
        }
    );

    const { filteredMeasures } = useMeasureFilters(props.filters, measures);

    const renderItemColumn = (
        measure?: ResultType,
        _index?: number,
        column?: IColumn
    ) => {
        if (!measure) {
            return null;
        }

        let fieldContent: JSX.Element;

        switch (column?.fieldName) {
            case 'displaySequence':
                fieldContent = (
                    <DetailsListCellItemContainer>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Text variant="small">
                                {measure.displaySequence}
                            </Text>
                            <MeasureListWarning
                                missionId={
                                    measure.mission?.id || props.missionId || ''
                                }
                                missionAccess={
                                    measure.mission?.rights ||
                                    props.missionAccess
                                }
                                measure={measure}
                            />
                        </div>
                    </DetailsListCellItemContainer>
                );
                break;
            case 'groupName':
                fieldContent = (
                    <DetailsListCellItemContainer>
                        <Text
                            variant="small"
                            styles={{
                                root: {
                                    fontStyle: !measure.group
                                        ? 'italic'
                                        : undefined,
                                },
                            }}
                            block
                        >
                            {measure.group ? measure.groupName : 'Ungrouped'}
                        </Text>
                    </DetailsListCellItemContainer>
                );
                break;
            case 'name':
                fieldContent = (
                    <DetailsListCellItemContainer>
                        <MeasureNameBlock
                            missionId={measure.mission?.id || props.missionId}
                            measure={measure}
                            onClick={props.onMeasureClick}
                        />
                    </DetailsListCellItemContainer>
                );
                break;
            case 'status':
                fieldContent = (
                    <DetailsListCellItemContainer>
                        <span
                            style={{
                                display: 'block',
                                textAlign: 'right',
                            }}
                        >
                            <MeasureArrow
                                arrowDirection={
                                    measure.lastAsOf?.arrowDirection
                                }
                                arrowColour={measure.lastAsOf?.arrowColour}
                                statusValue={measure.lastAsOf?.statusValue}
                                values={measure.lastAsOf?.values}
                                isStatusLimited={measure.isStatusLimited}
                                showPercentage={true}
                                percentagePosition="Left"
                                iconFontSize={12}
                                textFontSize={12}
                                horizontalAlign="end"
                            />
                        </span>
                    </DetailsListCellItemContainer>
                );
                break;
            case 'sparkline':
                fieldContent =
                    measure.lastAsOf && measure.id ? (
                        <DetailsListCellItemContainer>
                            <MeasureHistorySparkline
                                arrowColour={measure.lastAsOf?.arrowColour}
                                measureId={measure.id}
                                height={28}
                            />
                        </DetailsListCellItemContainer>
                    ) : (
                        <span />
                    );

                break;
            case 'lastCommentText':
                fieldContent = measure.lastComment ? (
                    <DetailsListCellItemContainer>
                        {renderCommentText(measure.lastComment.text)}
                    </DetailsListCellItemContainer>
                ) : (
                    <span />
                );
                break;

            default: {
                const text = measure[
                    column?.fieldName as keyof ResultType
                ] as string;
                fieldContent = (
                    <DetailsListCellItemContainer>
                        <Text variant="small">{text}</Text>
                    </DetailsListCellItemContainer>
                );
            }
        }

        return fieldContent;
    };

    const renderRow = (
        detailsRowProps?: IDetailsRowProps
    ): JSX.Element | null => {
        const measure = detailsRowProps?.item as ResultType;
        let statusColourBg: string;

        const hasTarget = measure.lastAsOf?.values?.some(
            (v) => v?.seriesType?.name === 'Target' && v?.formatStr
        );

        const hasActual = measure.lastAsOf?.values?.some(
            (v) => v?.seriesType?.name === 'Actual' && v?.formatStr
        );

        switch (measure.lastAsOf?.arrowColour) {
            case 'GREEN':
                statusColourBg = `${MeasureStatusColours.onTarget}20`;
                break;
            case 'RED':
                statusColourBg = `${MeasureStatusColours.offTarget}20`;
                break;
            default:
                statusColourBg = `${MeasureStatusColours.nearTarget}20`;
                break;
        }

        if (!hasTarget || !hasActual) {
            statusColourBg = currentTheme.palette.neutralLighter;
        }

        const customStyles: Partial<IDetailsRowStyles> = {};
        if (detailsRowProps) {
            customStyles.root = {
                backgroundColor: !currentTheme.isInverted
                    ? statusColourBg
                    : currentTheme.palette.neutralLighter,
                marginTop: 2,
                selectors: {
                    '&:hover': {
                        backgroundColor: !currentTheme.isInverted
                            ? statusColourBg
                            : currentTheme.palette.neutralLighter,
                    },
                },
            };

            return <DetailsRow {...detailsRowProps} styles={customStyles} />;
        }
        return null;
    };

    const [isExporting, setIsExporting] = React.useState(false);

    const handleExportClick = async (
        columns: IColumn[],
        sortedItems: ResultType[]
    ) => {
        try {
            setIsExporting(true);
            const exportableFieldNames = availableColumns
                .filter((ac) => !ac.exportHidden)
                .map((ac) => ac.fieldName);
            await measureListExport(
                configuration,
                currentTenantId,
                columns.filter((c) =>
                    exportableFieldNames.some((f) => f === c.fieldName)
                ),
                sortedItems
            );
        } catch (e) {
            console.log(e);
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <FlatList<ResultType>
            {...props}
            listTypeName={props.missionId ? 'MissionMeasures' : 'Measures'}
            items={filteredMeasures || []}
            onRenderItemColumn={renderItemColumn}
            onRenderRow={renderRow}
            allowExport={true}
            isExporting={isExporting}
            onExportClick={handleExportClick}
            loading={props.measuresLoading}
            availableColumns={availableColumns}
        />
    );
}

function MeasureListWarning(props: {
    missionId: string;
    missionAccess?: Access;
    measure: MeasureType;
}) {
    const warning = useMeasureWarning(props.missionId, props.measure);

    return warning.hasWarning ? (
        <WarningButton
            warning={warning}
            showActions={!!props.missionAccess?.write}
        />
    ) : null;
}

function MeasureNameBlock(props: {
    measure: MeasureFlatListItem;
    missionId: string | null | undefined;
    onClick: (missionId: string, measureId: string) => void;
}) {
    const { measure } = props;

    const classNames = mergeStyleSets({
        text: {
            whiteSpace: 'pre-line',
            minHeight: '1em',
            verticalAlign: 'middle',
        },
    });

    const onLinkClick =
        props.missionId && measure.id
            ? () => props.onClick(props.missionId || '', measure.id || '')
            : undefined;

    const displayName = measure.name?.trim() || '';

    return (
        <Link onClick={onLinkClick}>
            {!!displayName && (
                <Text
                    variant="smallPlus"
                    className={classNames.text}
                    title={displayName}
                    block
                >
                    {displayName}
                </Text>
            )}
            {!displayName && (
                <Text
                    variant="small"
                    styles={{ root: { fontStyle: 'italic' } }}
                    block
                >
                    Enter a measure name
                </Text>
            )}
        </Link>
    );
}

function useMeasureFilters<T extends MeasureFlatListItem>(
    filters: MissionFilters | undefined,
    measures: T[]
): { filteredMeasures: T[] } {
    let filteredMeasures = measures || [];

    if (filters?.keyword) {
        let regex: RegExp | null = null;
        try {
            // eslint-disable-next-line security/detect-non-literal-regexp
            regex = new RegExp(filters.keyword, 'gi');
        } catch {
            regex = null;
        }

        filteredMeasures = filteredMeasures.filter((m) => {
            if (regex) {
                if (m.name && m.name.match(regex)?.length) {
                    return true;
                }
                if (m.mission) {
                    return (
                        m.mission.team?.code?.match(regex)?.length ||
                        m.mission.team?.name?.match(regex)?.length ||
                        m.mission.owner?.match(regex)?.length ||
                        m.mission.title?.match(regex)?.length
                    );
                }
            } else if (filters.keyword) {
                const keyword = filters.keyword.toLowerCase();
                if (m.name && m.name.toLowerCase().includes(keyword)) {
                    return true;
                }
                if (m.mission) {
                    return (
                        m.mission.team?.code?.toLowerCase().includes(keyword) ||
                        m.mission.team?.name?.toLowerCase().includes(keyword) ||
                        m.mission.owner?.toLowerCase().includes(keyword) ||
                        m.mission.title?.toLowerCase().includes(keyword)
                    );
                }
            }
            return false;
        });
    }

    return { filteredMeasures: filteredMeasures };
}
