import React from 'react';

import { Stack, Image, ImageFit, mergeStyleSets } from '@fluentui/react';

import logo from '../../../images/advance-large-light.png';
import { defaultTheme } from '../../../hooks/useThemes';
import SupportWidget from '../../../components/SupportWidget';

export function ErrorContainer(
    props: React.PropsWithChildren<{
        emailAddress?: string | null;
    }>
): JSX.Element {
    const styles = mergeStyleSets({
        container: {
            backgroundColor: '#d6dde5',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        errorMessageArea: {
            boxShadow: defaultTheme.effects.elevation8,
            backgroundColor: '#fff',
            maxWidth: 480,
        },
    });

    return (
        <div className={styles.container}>
            <Stack tokens={{ padding: 8, childrenGap: 4 }}>
                <Stack.Item>
                    <div className={styles.errorMessageArea}>
                        <Stack tokens={{ padding: 32, childrenGap: 16 }}>
                            <Image
                                src={logo}
                                alt="Advance"
                                height={120}
                                imageFit={ImageFit.contain}
                                shouldFadeIn
                            />
                            {props.children}
                        </Stack>
                    </div>
                </Stack.Item>
            </Stack>

            <SupportWidget userEmailAddress={props.emailAddress || undefined} />
        </div>
    );
}
