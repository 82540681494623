import React from 'react';
import { IButtonProps, IconButton, IContextualMenuItem } from '@fluentui/react';
import { AdvanceBreadcrumb } from './Breadcrumbs';

// This is used when the breadcrumbs is restricted and goes into overflow
export function BreadcrumbOverflowButton(
    props: IButtonProps & { allItems: AdvanceBreadcrumb[] }
): JSX.Element {
    {
        const menuItems: IContextualMenuItem[] = [];

        props.menuProps?.items.forEach((menuItem) => {
            const item = props.allItems.find(
                (item) => item.key === menuItem.key
            );

            const subMenuProps = item?.menuProps?.items?.length
                ? {
                      items: item.menuProps
                          ? item.menuProps.items
                          : menuItem.subMenuProps?.items || [],
                  }
                : undefined;

            menuItems.push({
                ...menuItem,
                disabled: !subMenuProps,
                itemProps: !subMenuProps ? menuItem.itemProps : undefined,
                subMenuProps: subMenuProps,
            });
        });

        const newProps = {
            ...props,
            menuProps: {
                ...props.menuProps,
                items: menuItems,
            },
        };

        return <IconButton {...newProps} />;
    }
}
