import orderBy from 'lodash/orderBy';
import {
    useGetTaskLazyQuery,
    useGetTasksAndResourcesLazyQuery,
    useGetTeamUsersLazyQuery,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';

export type ImpliedTaskType = {
    id: string | null;
    missionId: string | null;
    resourcedTasks: {
        resource: {
            id: string | null;
            userId: string | null;
        } | null;
    }[];
};

export type AvaliableResourceItem = {
    resourceId: string | null;
    userId: string | null;
    displayName: string | null;
};

export function useTaskAvailableResources(
    impliedTask: ImpliedTaskType,
    missionUserId: string | null
) {
    const { currentTenantId, currentFinancialYearCode, currentTeamCode } =
        useStateContext();

    const [getTasks] = useGetTasksAndResourcesLazyQuery({
        variables: {
            tenantId: currentTenantId || '',
            missionId: impliedTask.missionId || '',
        },
    });

    const [getTask] = useGetTaskLazyQuery({
        variables: {
            tenantId: currentTenantId || '',
            id: impliedTask.id || '',
        },
    });

    const [getTeam] = useGetTeamUsersLazyQuery({
        variables: {
            tenantId: currentTenantId || '',
            teamCode: currentTeamCode || '',
            financialYearCode: currentFinancialYearCode || '',
        },
    });

    const loadAvailableResources = async (): Promise<
        AvaliableResourceItem[]
    > => {
        const results = await Promise.all([getTasks(), getTeam(), getTask()]);

        const tasksResult = results[0];
        const teamResult = results[1];
        const taskResult = results[2];

        const impliedTasks = tasksResult.data?.tasks
            .filter((t) => t.parentTaskId !== null)
            .filter((t) => !t.isDuplicate);

        const resources = impliedTasks
            ?.flatMap((it) => it.resourcedTasks)
            ?.filter((rt) => !!rt.resource)
            ?.filter((rt) => !rt.resource?.utcDeleted)
            .map((rt) => ({
                resourceId: rt.resource?.id || null,
                displayName: rt.resource?.displayName || null,
                userId: rt.resource?.userId || null,
            }));

        let availableResources: AvaliableResourceItem[] = [
            ...(teamResult.data?.teams
                ?.filter((t) => t.leaderMission?.userId)
                .map((t) => ({
                    resourceId: null,
                    userId: t.leaderMission?.userId || null,
                    displayName: t.leaderMission?.username || null,
                })) || []),
            ...(teamResult.data?.teams
                ?.flatMap((t) =>
                    t.missions.filter(
                        (m) => m.userId !== t.leaderMission?.userId
                    )
                )
                .filter((tm) => tm.userId)
                .map((tm) => ({
                    resourceId: null,
                    userId: tm.userId,
                    displayName: tm.username,
                })) || []),
        ];

        // Move the self resource to the top
        const selfResourceIndex = missionUserId
            ? availableResources.findIndex((ar) => ar.userId === missionUserId)
            : -1;
        if (selfResourceIndex !== -1) {
            const selfResource = availableResources.splice(
                selfResourceIndex,
                1
            );
            availableResources.unshift(...selfResource);
        }

        const usedResources: AvaliableResourceItem[] = orderBy(
            resources?.reduce(
                (
                    acc: Record<
                        string,
                        AvaliableResourceItem & {
                            count: number;
                        }
                    >,
                    resource
                ) => {
                    const key = resource.resourceId || '';
                    if (acc[key as string]) {
                        acc[key as string].count += 1;
                    } else {
                        acc[key as string] = { ...resource, count: 1 };
                    }
                    return acc;
                },
                {}
            ),
            'count',
            'desc'
        );

        usedResources.forEach((ur) => {
            if (
                !availableResources.some(
                    (ar) =>
                        ar.userId === ur.userId ||
                        ar.resourceId === ur.resourceId
                )
            ) {
                availableResources.push(ur);
            }
        });

        const alreadyResourcedToThisTask = impliedTask.resourcedTasks
            .filter((r) => r.resource)
            .map((r) => ({
                resourceId: r.resource?.id,
                userId: r.resource?.userId,
            }));

        const alreadyResourcedToParent =
            taskResult.data?.task?.resourcedFromTask?.resourcedTasks
                .flatMap((r) => r.resource)
                .filter((r) => r)
                .map((r) => ({
                    resourceId: r?.id,
                    userId: r?.userId,
                })) || [];

        // Filter out already resourced
        availableResources = availableResources.filter(
            (r) =>
                !alreadyResourcedToThisTask.some(
                    (ar) =>
                        (r.resourceId &&
                            ar.resourceId &&
                            ar.resourceId === r.resourceId) ||
                        (r.userId && ar.userId && ar.userId === r.userId)
                )
        );

        // Filter out already resourced to the resourcing task if there is one (self resourcing is fine)
        availableResources = availableResources.filter(
            (r) =>
                (missionUserId && r.userId === missionUserId) ||
                !alreadyResourcedToParent.some(
                    (ar) =>
                        (r.resourceId &&
                            ar.resourceId &&
                            ar.resourceId === r.resourceId) ||
                        (r.userId && ar.userId && ar.userId === r.userId)
                )
        );

        // Filter out the person that resourced this task too
        const resourcedFromUserId =
            taskResult.data?.task?.resourcedFromTask?.mission?.userId;

        if (resourcedFromUserId) {
            availableResources = availableResources.filter(
                (t) => t.userId !== resourcedFromUserId
            );
        }

        return availableResources;
    };

    return { loadAvailableResources };
}
