import { useNavigate } from 'react-router-dom';
import { useGetMissionNavLazyQuery } from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { navigation } from '../services/navigation';

export const useMissionNavigation = (): {
    navigateToMission: (missionId: string) => Promise<void>;
} => {
    const { currentTenantId, currentTenantCode, currentFinancialYearCode } =
        useStateContext();

    const [getMissionNav] = useGetMissionNavLazyQuery();

    const navigate = useNavigate();

    const navigateToMission = async (missionId: string) => {
        if (currentTenantId && missionId) {
            const missionNav = await getMissionNav({
                variables: {
                    tenantId: currentTenantId,
                    missionId: missionId,
                },
            });

            const mission = missionNav.data?.mission;

            const navTeam =
                mission?.team && mission?.team?.leaderMission?.rights.read
                    ? mission?.team
                    : mission?.leaderOfTeams != null
                    ? mission?.leaderOfTeams[0]
                    : mission?.team;

            if (navTeam?.code) {
                const missionHref = navigation.getPathForParams({
                    tenantCode: currentTenantCode,
                    financialYearCode: currentFinancialYearCode,
                    missionId: missionId,
                    teamCode: navTeam?.code,
                });

                if (missionHref) {
                    navigate(missionHref);
                }
            }
        }
    };

    return {
        navigateToMission,
    };
};
