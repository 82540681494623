import { TeachingBubble, keyframes, mergeStyleSets } from '@fluentui/react';
import React, { PropsWithChildren, useState } from 'react';
import { useThemes } from '../../hooks/useThemes';
import { useId } from '@fluentui/react-hooks';

const BeaconEngagement = (
    props: PropsWithChildren<{
        showBeacon: boolean;
        headline?: string;
        content?: string;
        onDimiss?: () => void;
        beaconOffset?: {
            top?: number;
            left?: number;
            right?: number;
            bottom?: number;
        };
    }>
) => {
    const { currentTheme } = useThemes();

    const pulse = keyframes({
        '0%': {
            transform: 'scale(0.95)',
            boxShadow: `0 0 0 0 ${currentTheme.palette.themeLight}b3`,
        },
        '70%': {
            transform: 'scale(1)',
            boxShadow: `0 0 0 10px ${currentTheme.palette.themeLight}00`,
        },
        '100%': {
            transform: 'scale(0.95)',
            boxShadow: `0 0 0 0 ${currentTheme.palette.themeLight}00`,
        },
    });

    const classNames = mergeStyleSets({
        beacon: {
            position: 'absolute',
            top:
                props.beaconOffset?.top !== undefined
                    ? props.beaconOffset?.top
                    : 0,
            right:
                props.beaconOffset?.right !== undefined
                    ? props.beaconOffset?.right
                    : 0,
            bottom:
                props.beaconOffset?.right !== undefined
                    ? props.beaconOffset?.right
                    : undefined,
            left:
                props.beaconOffset?.right !== undefined
                    ? props.beaconOffset?.right
                    : undefined,
            width: 10,
            height: 10,
            backgroundColor: currentTheme.palette.themeLight,
            borderRadius: '50%',
            animation: `${pulse} 2s infinite`,
        },
        beaconContainer: {
            position: 'relative',
            display: 'inline-block',
        },
    });

    const beaconId = useId('engagementBeacon');

    const [showTeachingBubble, setShowTeachingBubble] = useState(false);

    return (
        <div
            className={classNames.beaconContainer}
            onMouseEnter={() => setShowTeachingBubble(true)}
            onMouseLeave={() => setShowTeachingBubble(false)}
        >
            {showTeachingBubble && (
                <TeachingBubble
                    target={`#${beaconId}`}
                    headline={props.headline}
                    hasCloseButton
                    closeButtonAriaLabel="Close"
                    onDismiss={props.onDimiss}
                >
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                        {props.content}
                    </div>
                </TeachingBubble>
            )}

            {props.showBeacon && (
                <div id={beaconId} className={classNames.beacon} />
            )}
            {props.children}
        </div>
    );
};

export default BeaconEngagement;
