import { MessageBar, MessageBarType } from '@fluentui/react';
import styles from './ApiHelp.module.css';

export function Authentication(): JSX.Element {
    return (
        <div>
            <h1>Authentication</h1>
            <div>
                Advance uses API keys to allow access to the API. You must be an
                existing Advance Dashboard client with Import privileges in
                order to use the API. Create your API key in the "API Keys" tab.
            </div>
            <h3>Safety Best Practices</h3>
            <ol>
                <li>
                    Make a careful note of your key as once generated and
                    displayed, you will not be able to view it again.
                </li>
                <li>
                    If you lose your key, then make sure to delete the key in
                    the "API Keys" and create a new one.
                </li>
                <li>It is a good idea to rotate your apikey regularly.</li>
            </ol>

            <h3>Send Token Along With Requests</h3>
            <div>
                Advance expects for the API Key to be included in all API
                requests to the server in a header that looks like the
                following:
            </div>

            <p>
                <code className={styles.code}>ApiKey: your-api-key</code>
            </p>

            <MessageBar messageBarType={MessageBarType.warning}>
                You must replace your-api-key with your own personal API key.
            </MessageBar>
        </div>
    );
}
