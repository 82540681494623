import React, {
    forwardRef,
    memo,
    Ref,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    sinkListItem,
    liftListItem,
    splitListItem,
    wrapInList,
} from 'prosemirror-schema-list';
import {
    schema as markdownSchema,
    defaultMarkdownParser,
    defaultMarkdownSerializer,
} from 'prosemirror-markdown';

import { keymap } from 'prosemirror-keymap';
import { baseKeymap, chainCommands, toggleMark } from 'prosemirror-commands';
import { MarkType, NodeType } from 'prosemirror-model';
import { history, redo, undo } from 'prosemirror-history';
import { useProseMirror, ProseMirror } from 'use-prosemirror';
import { EditorState, Transaction } from 'prosemirror-state';
import { DirectEditorProps, EditorView } from 'prosemirror-view';

import 'prosemirror-view/style/prosemirror.css';
import './MarkdownEditor.css';

import { CommandBar, ICommandBarItemProps, IconButton } from '@fluentui/react';
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';

const schema = markdownSchema;

const toggleBold = toggleMarkCommand(schema.marks.strong);
const toggleItalic = toggleMarkCommand(schema.marks.em);
const toggleBulletList = wrapList(schema.nodes.bullet_list);
const toggleNumberedList = wrapList(schema.nodes.ordered_list);

type MarkdownEditorProps = {
    defaultValue?: string | null;
    onChange?: (value: string) => void;
    readOnly?: boolean;
};

const opts: Parameters<typeof useProseMirror>[0] = {
    schema,
    plugins: [
        history(),
        keymap({
            ...baseKeymap,
            'Mod-z': undo,
            'Mod-y': redo,
            'Mod-Shift-z': redo,
            'Mod-b': toggleBold,
            'Mod-i': toggleItalic,
            Enter: chainCommands(
                splitListItem(schema.nodes.list_item),
                baseKeymap['Enter']
            ),
            Tab: sinkListItem(schema.nodes.list_item),
            'Shift-Tab': liftListItem(schema.nodes.list_item),
        }),
    ],
};

export type MarkdownEditorRef = {
    insertList: (textToInsert: string[]) => void;
};

function MarkdownEditor(
    props: MarkdownEditorProps,
    ref: Ref<MarkdownEditorRef>
): JSX.Element {
    const { onChange, readOnly } = props;

    const parsed = defaultMarkdownParser.parse(props.defaultValue || '');

    const config = {
        ...opts,
        doc: parsed || undefined,
    };

    const [state, setState] = useState<EditorState>(EditorState.create(config));

    const editorViewFactory = (
        el: HTMLDivElement,
        editorProps: DirectEditorProps
    ): EditorView => {
        return new EditorView(el, editorProps);
    };

    const insertList = (textToInsert: string[]) => {
        const list = schema.nodes.bullet_list.create(
            { tight: true },
            compact(
                textToInsert
                    .filter((t) => !!t)
                    .map((t) =>
                        schema.nodes.list_item.create(
                            {},
                            schema.nodes.paragraph.create({}, schema.text(t))
                        )
                    )
            )
        );

        if (list) {
            const tr = state.tr
                .insert(state.selection.$from.pos, list)
                .scrollIntoView();

            handleChange(state.apply(tr));
        }
    };

    useImperativeHandle(ref, () => ({
        insertList,
    }));

    const persisted = useRef(state.doc);
    const isPersisting = useRef(false);
    const stateRef = useRef(state);

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const debouncedChange = useMemo(
        () =>
            debounce(() => {
                if (stateRef.current.doc.eq(persisted.current)) {
                    return;
                }

                if (!readOnly && onChange) {
                    if (isPersisting.current) {
                        debouncedChange();
                        return;
                    }

                    const markdown = defaultMarkdownSerializer.serialize(
                        stateRef.current.doc
                    );

                    try {
                        isPersisting.current = true;
                        onChange(markdown);
                    } finally {
                        persisted.current = stateRef.current.doc;
                        isPersisting.current = false;
                    }
                }
            }, 1000),
        [onChange, readOnly]
    );

    const handleChange = useCallback(
        (newState: EditorState) => {
            if (!readOnly) {
                setState(newState);
                debouncedChange();
            }
        },
        [readOnly, debouncedChange]
    );

    useEffect(() => {
        debouncedChange();
        return () => debouncedChange.flush();
    }, [debouncedChange]);

    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: 'undo',
            commandBarButtonAs: function italicButton() {
                return (
                    <Button
                        isActive={false}
                        disabled={!canUndo(state)}
                        text="Undo"
                        iconName="Undo"
                        onClick={() =>
                            undo(state, (tr: Transaction) =>
                                setState(state.apply(tr))
                            )
                        }
                    />
                );
            },
        },
        {
            key: 'redo',
            commandBarButtonAs: function italicButton() {
                return (
                    <Button
                        isActive={false}
                        disabled={!canRedo(state)}
                        text="Redo"
                        iconName="Redo"
                        onClick={() =>
                            redo(state, (tr) => setState(state.apply(tr)))
                        }
                    />
                );
            },
        },
        {
            key: 'bold',
            commandBarButtonAs: function boldButton() {
                return (
                    <Button
                        isActive={isBold(state)}
                        text="Bold"
                        iconName="Bold"
                        onClick={() =>
                            toggleBold(state, (tr: Transaction) =>
                                setState(state.apply(tr))
                            )
                        }
                    />
                );
            },
        },
        {
            key: 'italic',
            commandBarButtonAs: function italicButton() {
                return (
                    <Button
                        isActive={isItalic(state)}
                        text="Italic"
                        iconName="Italic"
                        onClick={() =>
                            toggleItalic(state, (tr: Transaction) =>
                                setState(state.apply(tr))
                            )
                        }
                    />
                );
            },
        },
        {
            key: 'bullet',
            commandBarButtonAs: function italicButton() {
                return (
                    <Button
                        isActive={false}
                        text="Bulleted List"
                        iconName="BulletedList"
                        onClick={() => {
                            toggleBulletList(state, (tr: Transaction) =>
                                setState(state.apply(tr))
                            );
                        }}
                    />
                );
            },
        },
        {
            key: 'numbered',
            commandBarButtonAs: function italicButton() {
                return (
                    <Button
                        isActive={false}
                        text="Numbered List"
                        iconName="NumberedList"
                        onClick={() => {
                            toggleNumberedList(state, (tr: Transaction) =>
                                setState(state.apply(tr))
                            );
                        }}
                    />
                );
            },
        },
    ];

    if (canLiftItem(state)) {
        commandBarItems.push({
            key: 'lift',
            commandBarButtonAs: function italicButton() {
                return (
                    <Button
                        isActive={false}
                        text="Decrease Indent"
                        iconName="DecreaseIndentLegacy"
                        onClick={() =>
                            liftListItem(schema.nodes.list_item)(
                                state,
                                (tr: Transaction) => setState(state.apply(tr))
                            )
                        }
                    />
                );
            },
        });
    }

    return (
        <div className="MarkdownEditorContainer">
            {!props.readOnly && (
                <CommandBar
                    className="no-print"
                    styles={{ root: { padding: 0, height: '' } }}
                    items={commandBarItems}
                />
            )}

            <div className="ProseMirrorContainer">
                <ProseMirror
                    editable={() => !props.readOnly}
                    className="ProseMirror"
                    state={state}
                    onChange={handleChange}
                    editorViewFactory={editorViewFactory}
                />
            </div>
        </div>
    );
}

export default memo(forwardRef(MarkdownEditor));

function toggleMarkCommand(mark: MarkType) {
    return (
        state: EditorState,
        dispatch: ((tr: Transaction) => void) | undefined
    ) => toggleMark(mark)(state, dispatch);
}

function wrapList(listType: NodeType) {
    return (
        state: EditorState,
        dispatch: ((tr: Transaction) => void) | undefined
    ) => {
        wrapInList(listType)(state, dispatch);
    };
}

function canLiftItem(state: EditorState): boolean {
    return liftListItem(schema.nodes.list_item)(state);
}

function canUndo(state: EditorState): boolean {
    return undo(state);
}

function canRedo(state: EditorState): boolean {
    return redo(state);
}

function isBold(state: EditorState): boolean {
    return isMarkActive(state, schema.marks.strong);
}

function isItalic(state: EditorState): boolean {
    return isMarkActive(state, schema.marks.em);
}

// https://github.com/ProseMirror/prosemirror-example-setup/blob/afbc42a68803a57af3f29dd93c3c522c30ea3ed6/src/menu.js#L57-L61
function isMarkActive(state: EditorState, mark: MarkType): boolean {
    const { from, $from, to, empty } = state.selection;
    return empty
        ? !!mark.isInSet(state.storedMarks || $from.marks())
        : state.doc.rangeHasMark(from, to, mark);
}

function Button(props: {
    disabled?: boolean;
    isActive: boolean;
    iconName: string;
    text: string;
    onClick: () => void;
}) {
    return (
        <IconButton
            toggle
            checked={props.isActive}
            text={props.text}
            title={props.text}
            iconProps={{ iconName: props.iconName }}
            onClick={(event) => {
                event.preventDefault();
                props.onClick();
            }}
            onMouseDown={(event) => {
                event.preventDefault();
            }}
            disabled={props.disabled}
        />
    );
}
