import React from 'react';
import {
    Icon,
    mergeStyleSets,
    Persona,
    PersonaSize,
    Shimmer,
    Stack,
    Text,
} from '@fluentui/react';
import { GetTaskQuery, useGetMissionTasksQuery } from '../data/types';
import { ExtractQueryArrayType } from '../data/extendedTypes';
import { useIsTaskImplied } from '../hooks/useIsTaskImplied';
import { useThemes } from '../hooks/useThemes';
import TaskProgressIndicator from './TaskProgressIndicator';
import { photoService } from '../services/photo.service';
import dayjs from 'dayjs';
import { useStateContext } from '../services/contextProvider';
import { sorters } from '../data/sorters';

export function TaskOverviewMap(props: {
    task: ExtractQueryArrayType<GetTaskQuery, ['task']> | null;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { task } = props;

    const { currentTheme } = useThemes();
    const isTaskImplied = useIsTaskImplied(task);

    const { data: taskData } = useGetMissionTasksQuery({
        skip: !task?.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: task?.missionId || '',
        },
    });

    const allTasks = taskData?.tasks;

    const classNames = mergeStyleSets({
        container: {
            padding: 4,
            margin: 0,
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        subTask: {
            width: 168,
            boxShadow: currentTheme.effects.elevation4,
            padding: 4,
            margin: 8,
            backgroundColor: currentTheme.palette.neutralLighterAlt,
            borderRadius: 4,
        },
        task: {
            width: 280,
            boxShadow: currentTheme.effects.elevation8,
            backgroundColor: currentTheme.palette.neutralLight,
            padding: 8,
            margin: 8,
            borderRadius: 4,
        },
        resourcedFromTask: {
            width: 240,
            boxShadow: currentTheme.effects.elevation4,
            padding: 4,
            margin: 8,
            backgroundColor: currentTheme.palette.neutralLighter,
            borderRadius: 4,
        },
        separator: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '4px 0',
        },
        separatorLine: {
            flex: 1,
            height: 2,
            backgroundColor: currentTheme.palette.neutralLight,
        },
        separatorMiddle: {
            textAlign: 'center',
            padding: '0 8px',
        },
    });

    if (!task) {
        return (
            <Shimmer
                height="200"
                width="100%"
                styles={{
                    root: {
                        padding: 4,
                    },
                }}
            />
        );
    }

    const resourcedTasks = isTaskImplied
        ? task.resourcedTasks
              .slice()
              .sort((r1, r2) =>
                  r1?.resourceIsPrimary === r2?.resourceIsPrimary
                      ? 0
                      : r1?.resourceIsPrimary
                        ? -1
                        : 1
              )
        : [];

    const subTasks =
        !isTaskImplied && !!allTasks
            ? allTasks
                  .filter((it) => it.parentTaskId === task.id)
                  .filter((it) => !it.isDuplicate)
                  .sort(sorters.sequenceSorter)
            : [];

    const mergedTasks = allTasks
        ? allTasks
              .filter((it) => it.parentTaskId === task.id)
              .filter((it) => it.isDuplicate)
              .sort(sorters.sequenceSorter)
        : [];

    const resourcedFromMergedTasks = mergedTasks
        .filter((mt) => !mt.utcResourceRemoved)
        .flatMap((mt) =>
            mt.resourcedFromTask
                ? [{ ...mt.resourcedFromTask, isMerged: true }]
                : []
        );

    const resourcedFromTasks = task.resourcedFromTask
        ? [
              { ...task.resourcedFromTask, isMerged: false },
              ...resourcedFromMergedTasks,
          ]
        : resourcedFromMergedTasks;

    return (
        <div className={classNames.container}>
            {resourcedFromTasks.map((resourcedFromTask) => {
                return (
                    <div
                        className={classNames.resourcedFromTask}
                        key={resourcedFromTask.id}
                    >
                        <Stack tokens={{ childrenGap: 4 }}>
                            <Persona
                                size={PersonaSize.size24}
                                imageUrl={photoService.getImageUrl(
                                    resourcedFromTask?.mission?.userId
                                )}
                                text={`${resourcedFromTask?.mission?.owner}${resourcedFromTask.isMerged ? ' (Merged)' : ''}`}
                                secondaryText={
                                    resourcedFromTask?.mission?.title || ''
                                }
                                showSecondaryText
                            />
                            <Text variant="tiny">
                                {resourcedFromTask?.name}
                            </Text>
                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                {task.start && (
                                    <Text variant="xSmall">
                                        Start:{' '}
                                        {dayjs(resourcedFromTask.start).format(
                                            'DD MMM YYYY'
                                        )}
                                    </Text>
                                )}
                                {task.due && (
                                    <Text variant="xSmall">
                                        Due:{' '}
                                        {dayjs(resourcedFromTask.due).format(
                                            'DD MMM YYYY'
                                        )}
                                    </Text>
                                )}
                            </Stack>
                            <TaskProgressIndicator
                                showStatus
                                task={{
                                    ...resourcedFromTask,
                                    resourcedFromTask: null, // We don't need resource status on the resourced from task
                                }}
                            />
                        </Stack>
                    </div>
                );
            })}

            {!!resourcedFromTasks.length && (
                <div className={classNames.separator}>
                    <div className={classNames.separatorLine}></div>
                    <div className={classNames.separatorMiddle}>
                        <Icon iconName="DoubleChevronUp12" />
                        <Text variant="tiny" block>
                            Resourced from
                        </Text>
                    </div>
                    <div className={classNames.separatorLine}></div>
                </div>
            )}

            <div className={classNames.task}>
                <Stack tokens={{ childrenGap: 8 }}>
                    <Persona
                        size={PersonaSize.size24}
                        imageUrl={
                            task.resource?.userId
                                ? photoService.getImageUrl(
                                      task.resource?.userId
                                  )
                                : photoService.getImageUrl(task.mission?.userId)
                        }
                        text={
                            task.mission?.owner ||
                            task.resource?.displayName ||
                            ''
                        }
                        secondaryText={task.mission?.title || ''}
                        showSecondaryText={!!task.missionId}
                    />
                    <Text
                        variant="smallPlus"
                        styles={{ root: { fontWeight: 'bold' } }}
                    >
                        {task?.name}
                    </Text>
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                        {task.start && (
                            <Text variant="xSmall">
                                Start: {dayjs(task.start).format('DD MMM YYYY')}
                            </Text>
                        )}
                        {task.due && (
                            <Text variant="xSmall">
                                Due: {dayjs(task.due).format('DD MMM YYYY')}
                            </Text>
                        )}
                    </Stack>
                    <TaskProgressIndicator showStatus task={task} />
                </Stack>
            </div>

            {(!!resourcedTasks.length || !!subTasks.length) && (
                <div className={classNames.separator}>
                    <div className={classNames.separatorLine}></div>

                    <div className={classNames.separatorMiddle}>
                        {(!isTaskImplied ||
                            task.isPercentageCompleteFromResources) && (
                            <Icon iconName="DoubleChevronUp12" />
                        )}
                        <Text variant="tiny" block>
                            {isTaskImplied &&
                                (task.isPercentageCompleteFromResources
                                    ? 'Completion'
                                    : 'Resources')}
                            {!isTaskImplied && 'Implied Tasks'}
                        </Text>
                    </div>

                    <div className={classNames.separatorLine}></div>
                </div>
            )}

            {resourcedTasks.map((rt) => {
                return (
                    <div className={classNames.subTask} key={rt.id}>
                        <Stack tokens={{ childrenGap: 4 }}>
                            <Persona
                                size={PersonaSize.size24}
                                imageUrl={photoService.getImageUrl(
                                    rt.resource?.userId
                                )}
                                text={rt.resource?.displayName || ''}
                            />
                            <Text
                                variant="tiny"
                                className="line-clamp3"
                                title={rt?.name || ''}
                            >
                                {rt?.name}
                            </Text>
                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                {task.start && (
                                    <Text variant="xSmall">
                                        Start:{' '}
                                        {dayjs(rt.start).format('DD MMM YYYY')}
                                    </Text>
                                )}
                                {task.due && (
                                    <Text variant="xSmall">
                                        Due:{' '}
                                        {dayjs(rt.due).format('DD MMM YYYY')}
                                    </Text>
                                )}
                            </Stack>
                            <TaskProgressIndicator
                                showStatus
                                task={{ ...rt, resourcedFromTask: task }}
                            />
                        </Stack>
                    </div>
                );
            })}

            {subTasks.map((it) => {
                return (
                    <div className={classNames.subTask} key={it.id}>
                        <Stack tokens={{ childrenGap: 4 }}>
                            <Text
                                variant="tiny"
                                className="line-clamp3"
                                title={it?.name || ''}
                            >
                                {it?.name}
                            </Text>
                            <Stack horizontal tokens={{ childrenGap: 8 }}>
                                {task.start && (
                                    <Text variant="xSmall">
                                        Start:{' '}
                                        {dayjs(it.start).format('DD MMM YYYY')}
                                    </Text>
                                )}
                                {task.due && (
                                    <Text variant="xSmall">
                                        Due:{' '}
                                        {dayjs(it.due).format('DD MMM YYYY')}
                                    </Text>
                                )}
                            </Stack>
                            <TaskProgressIndicator
                                showStatus
                                task={{
                                    ...it,
                                    resourcedFromTask: null, // We don't need resource status on subtasks for the task map
                                    resource: null,
                                }}
                            />
                        </Stack>
                    </div>
                );
            })}
        </div>
    );
}
