import React, { CSSProperties } from 'react';

import {
    Stack,
    Text,
    ActionButton,
    Link,
    FontSizes,
    Shimmer,
    ShimmerElementType,
} from '@fluentui/react';

import { GetMissionTasksQuery, useUpdateTaskMutation } from '../data/types';

import ImpliedTaskList from './ImpliedTaskList';
import ExpandCollapse from './ExpandCollapse';
import { useThemes } from '../hooks/useThemes';
import { Access, ExtractQueryArrayType } from '../data/extendedTypes';
import {
    useTaskExpandActionsContext,
    useTaskExpandContext,
} from '../TaskExpandContext';
import { AdvanceCard } from './AdvanceCard';
import TaskProgressIndicator from './TaskProgressIndicator';
import { ResourcedFromCoin } from './ResourcedFromCoin';
import { TaskFilters } from '../scenes/MissionBuilder/components/TaskFilterBar';
import { ImpliedTaskGantt } from './ImpliedTaskGantt';
import { EditibleText } from './EditibleText';
import { useStateContext } from '../services/contextProvider';
import { useInputMappers } from '../hooks/useInputMappers';
import { SpecifiedTaskCardChipPicker } from './SpecifiedTaskCardChipPicker';
import { useViewport } from '../hooks/useViewport';
import CommentButton from './CommentButton';
import { TaskSummaryItem } from '../scenes/MissionBuilder/components/TaskList';
import { SpecifiedTaskMoreOptionsButton } from './SpecifiedTaskMoreOptionsButton';
import { TaskTileColumn } from '../hooks/useColumnPreferences';
import { CommentTooltipHost } from './CommentTooltipHost';

export type TaskCardProps = {
    isLoading: boolean;
    divisionId: string | null;
    missionId: string;
    missionUserId: string | null;
    specifiedTask: ExtractQueryArrayType<GetMissionTasksQuery, ['tasks']>;
    specifiedTaskDuplicates: GetMissionTasksQuery['tasks'] | null;
    shimmerLines: number;
    impliedTasks: GetMissionTasksQuery['tasks'] | null;
    impliedSubTasks: GetMissionTasksQuery['tasks'] | null;
    onTaskClick: (taskId: string) => void;
    onTaskEditClick: (taskId: string) => void;
    onRejectedTaskClick: (rejectedTaskId: string) => void;
    onCommentsClick: (taskId: string) => void;
    onAttachmentsClick: (taskId: string) => void;
    onImpliedTaskDrag: (dragged: TaskSummaryItem) => void;
    onImpliedTaskDrop: (
        targetSpecifiedId: string,
        targetImpliedId: string | null
    ) => void;
    onImpliedTaskMove: (
        source: TaskSummaryItem,
        targetSpecifiedId: string,
        targetImpliedId: string | null
    ) => void;
    onAddImpliedButtonClick: (specifiedTaskId: string) => void;
    missionAccess: Access;
    dragHandler?: JSX.Element | null;
    filters?: TaskFilters;
    ganttStartDate: string | null;
    ganttEndDate: string | null;
    viewMode: 'Tile' | 'Gantt' | 'List';
    onSendToTop: (taskId: string) => void;
    onSpecifiedTaskMove: (
        sourceSpecifiedTaskId: string,
        newIndex: number
    ) => void;
    highlightImpliedTaskId?: string | null | undefined;
    tileColumnNames: TaskTileColumn[];
};

function TaskCard(props: TaskCardProps): JSX.Element {
    const expandedTaskIds = useTaskExpandContext();
    const { expandTask, collapseTask } = useTaskExpandActionsContext();

    const { width } = useViewport();
    const mobileBreakpoint = 560;

    const { currentTheme } = useThemes();

    const isExpanded = props.specifiedTask.id
        ? expandedTaskIds.some((id) => id === props.specifiedTask.id)
        : false;

    const onExpand = (): void => expandTask(props.specifiedTask.id || '');

    const onCollapse = (): void => collapseTask(props.specifiedTask.id || '');

    const cardStyle: CSSProperties = {
        backgroundColor: currentTheme.palette.white,
        borderLeftStyle: 'solid',
        borderLeftWidth: 4,
        borderLeftColor: props.specifiedTask?.taskCategory
            ? `#${props.specifiedTask?.taskCategory.colourHex}`
            : currentTheme.palette.neutralLighter,
    };

    const onAttachmentsIconClick = () => {
        props.onAttachmentsClick(props.specifiedTask?.id || '');
    };

    const onCommentsIconClick = () => {
        props.onCommentsClick(props.specifiedTask?.id || '');
    };

    return (
        <React.Fragment>
            <AdvanceCard
                key={`taskCard_${props.specifiedTask?.id}`}
                style={cardStyle}
            >
                <AdvanceCard.Item>
                    <Stack
                        horizontal
                        tokens={{ childrenGap: 4 }}
                        verticalAlign="start"
                    >
                        <Stack.Item grow align="start">
                            <SpecifiedTaskCardChipPicker
                                divisionId={props.divisionId}
                                specifiedTask={props.specifiedTask}
                                isReadOnly={!props.missionAccess.write}
                                isLoading={props.isLoading}
                                onSendToTop={() =>
                                    props.onSendToTop(
                                        props.specifiedTask.id || ''
                                    )
                                }
                            />
                        </Stack.Item>

                        {props.specifiedTask && (
                            <Stack.Item align="center">
                                <ResourcedFromCoin
                                    task={props.specifiedTask}
                                    missionAccess={props.missionAccess}
                                    onTaskEditClick={
                                        props.missionAccess.write
                                            ? () =>
                                                  props.onTaskEditClick(
                                                      props.specifiedTask.id ||
                                                          ''
                                                  )
                                            : null
                                    }
                                />
                            </Stack.Item>
                        )}

                        {width > mobileBreakpoint &&
                            props.specifiedTaskDuplicates?.map((dupe) => {
                                return (
                                    <Stack.Item align="center" key={dupe.id}>
                                        <ResourcedFromCoin
                                            task={dupe}
                                            missionAccess={props.missionAccess}
                                            onTaskEditClick={
                                                props.missionAccess.write
                                                    ? () =>
                                                          props.onTaskEditClick(
                                                              dupe.id || ''
                                                          )
                                                    : null
                                            }
                                        />
                                    </Stack.Item>
                                );
                            })}

                        <Stack.Item align="start" verticalFill>
                            <Shimmer
                                isDataLoaded={!props.isLoading}
                                width={width > mobileBreakpoint ? 32 : 24}
                                shimmerElements={[
                                    {
                                        type: ShimmerElementType.line,
                                        height: 32,
                                    },
                                ]}
                            >
                                <div
                                    style={{
                                        minWidth:
                                            width > mobileBreakpoint ? 32 : 24,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                    }}
                                >
                                    <TaskProgressIndicator
                                        task={props.specifiedTask}
                                        showStatus={false}
                                        hidePercentage={false}
                                    />
                                </div>
                            </Shimmer>
                        </Stack.Item>

                        {props.missionAccess.write && (
                            <Stack.Item align="start" style={{ width: 40 }}>
                                <Shimmer
                                    isDataLoaded={!props.isLoading}
                                    width={32}
                                    shimmerElements={[
                                        {
                                            type: ShimmerElementType.line,
                                            height: 32,
                                        },
                                    ]}
                                >
                                    {props.dragHandler}
                                </Shimmer>
                            </Stack.Item>
                        )}

                        {width > mobileBreakpoint && (
                            <Stack.Item
                                align="start"
                                verticalFill
                                style={{ width: 40 }}
                            >
                                <Shimmer
                                    isDataLoaded={!props.isLoading}
                                    width={38}
                                    shimmerElements={[
                                        {
                                            type: ShimmerElementType.line,
                                            height: 32,
                                        },
                                    ]}
                                >
                                    {!!props.specifiedTask && (
                                        <ActionButton
                                            iconProps={{ iconName: 'Attach' }}
                                            text={props.specifiedTask.attachmentCount.toFixed(
                                                0
                                            )}
                                            title="Attachments"
                                            ariaLabel="Attachments"
                                            styles={{
                                                label: {
                                                    whiteSpace: 'nowrap',
                                                    fontSize: FontSizes.xSmall,
                                                    color: props.specifiedTask
                                                        .attachmentCount
                                                        ? undefined
                                                        : currentTheme.palette
                                                              .themeLighter,
                                                },
                                                root: {
                                                    height: 32, // Same as IconButton
                                                },
                                                icon: {
                                                    margin: 0,
                                                    color: props.specifiedTask
                                                        .attachmentCount
                                                        ? undefined
                                                        : currentTheme.palette
                                                              .themeLighter,
                                                },
                                            }}
                                            onClick={onAttachmentsIconClick}
                                        />
                                    )}
                                </Shimmer>
                            </Stack.Item>
                        )}

                        {width > mobileBreakpoint && (
                            <Stack.Item align="start" style={{ width: 40 }}>
                                <Shimmer
                                    isDataLoaded={!props.isLoading}
                                    width={38}
                                    shimmerElements={[
                                        {
                                            type: ShimmerElementType.line,
                                            height: 32,
                                        },
                                    ]}
                                >
                                    {!!props.specifiedTask && (
                                        <CommentTooltipHost
                                            content={
                                                props.specifiedTask.lastComment
                                                    ?.text
                                            }
                                            authorName={
                                                props.specifiedTask.lastComment
                                                    ?.username
                                            }
                                            dateTime={
                                                props.specifiedTask.lastComment
                                                    ?.utcCreated
                                            }
                                        >
                                            <CommentButton
                                                commentCount={
                                                    props.specifiedTask
                                                        .commentCount
                                                }
                                                onClick={onCommentsIconClick}
                                            />
                                        </CommentTooltipHost>
                                    )}
                                </Shimmer>
                            </Stack.Item>
                        )}

                        {props.missionAccess.write && (
                            <Stack.Item align="start">
                                <Shimmer
                                    isDataLoaded={!props.isLoading}
                                    width={32}
                                    shimmerElements={[
                                        {
                                            type: ShimmerElementType.line,
                                            height: 32,
                                        },
                                    ]}
                                >
                                    {!!props.specifiedTask?.id && (
                                        <SpecifiedTaskMoreOptionsButton
                                            missionId={props.missionId}
                                            specifiedTask={props.specifiedTask}
                                            onEditClick={props.onTaskEditClick}
                                            buttonStyles={{
                                                root: {
                                                    height: 32, // Same as IconButton
                                                    color: currentTheme.palette
                                                        .themeLighter,
                                                    selectors: {
                                                        '&:hover': {
                                                            color: currentTheme
                                                                .palette
                                                                .themePrimary,
                                                        },
                                                    },
                                                },
                                                icon: {
                                                    margin: 0,
                                                },
                                            }}
                                            onMove={(newIndex) =>
                                                props.onSpecifiedTaskMove(
                                                    props.specifiedTask.id ||
                                                        '',
                                                    newIndex
                                                )
                                            }
                                        />
                                    )}
                                </Shimmer>
                            </Stack.Item>
                        )}
                    </Stack>
                </AdvanceCard.Item>

                <AdvanceCard.Item>
                    <Stack
                        horizontal
                        verticalAlign="start"
                        tokens={{ childrenGap: 8 }}
                    >
                        <Stack.Item align="start">
                            <ExpandCollapse
                                isExpanded={isExpanded}
                                onExpand={onExpand}
                                onCollapse={onCollapse}
                            />
                        </Stack.Item>
                        <Stack.Item grow align="center" verticalFill>
                            <TaskCardName
                                onClick={() =>
                                    props.onTaskClick(
                                        props.specifiedTask.id || ''
                                    )
                                }
                                task={props.specifiedTask}
                                isReadOnly={!props.missionAccess.write}
                            />
                        </Stack.Item>
                    </Stack>
                </AdvanceCard.Item>

                <AdvanceCard.Item fill>
                    {isExpanded && props.viewMode === 'Tile' && (
                        <ImpliedTaskList {...props} />
                    )}
                    {isExpanded && props.viewMode === 'Gantt' && (
                        <ImpliedTaskGantt {...props} />
                    )}
                </AdvanceCard.Item>

                {isExpanded &&
                    props.missionAccess.write &&
                    props.specifiedTask.id && (
                        <AdvanceCard.Item align="start">
                            <div
                                id={`newImpliedTaskButtonContainer${props.specifiedTask?.id}`}
                                style={{
                                    marginLeft: 32,
                                    marginTop: 8,
                                }}
                            >
                                <ActionButton
                                    iconProps={{ iconName: 'Add' }}
                                    styles={{
                                        label: {
                                            whiteSpace: 'nowrap',
                                        },
                                    }}
                                    text="Implied Task"
                                    onClick={() =>
                                        props.onAddImpliedButtonClick(
                                            props.specifiedTask.id || ''
                                        )
                                    }
                                />
                            </div>
                        </AdvanceCard.Item>
                    )}
            </AdvanceCard>
        </React.Fragment>
    );
}

function TaskCardName(props: {
    task: ExtractQueryArrayType<GetMissionTasksQuery, ['tasks']>;
    onClick: () => void;
    isReadOnly: boolean;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [updateTaskMutation] = useUpdateTaskMutation();

    const { getTaskInput } = useInputMappers();

    const updateSpecifiedTaskName = async (id: string | null, name: string) => {
        if (!id || !currentTenantId) {
            return;
        }

        const taskForInput = {
            ...props.task,
            name: name,
        };

        const input = getTaskInput(taskForInput);

        await updateTaskMutation({
            variables: {
                tenantId: currentTenantId,
                input: input,
            },
        });
    };

    const handleTextUpdateClick = async (newText: string) => {
        await updateSpecifiedTaskName(props.task.id, newText);
    };

    return (
        <EditibleText
            isReadOnly={props.isReadOnly}
            text={props.task.name || ''}
            dialogTitle="Edit Task Name"
            onUpdateClick={handleTextUpdateClick}
            disableOnMobile
        >
            <Link onClick={props.onClick}>
                <Text
                    variant="mediumPlus"
                    style={{
                        whiteSpace: 'pre-line',
                        fontStyle: !props.task.name?.trim()
                            ? 'italic'
                            : 'normal',
                    }}
                >
                    {props.task.name?.trim() ? props.task.name : 'Untitled'}
                </Text>
            </Link>
        </EditibleText>
    );
}

export default React.memo(TaskCard);
