import dayjs from 'dayjs';
import {
    ExtractQueryType,
    MeasureSeriesNames,
} from '../../../data/extendedTypes';
import { FrequencyPeriods, GetMeasureQuery } from '../../../data/types';
import { useInputMappers } from '../../../hooks/useInputMappers';
import {
    CombineMethod,
    convertIntermittentData,
    convertMonthData,
    convertQuarterData,
    convertYearData,
    createSeriesValue,
    getLabelFreq,
    getSeriesValue,
    MonthRow,
} from '../utils/measureUtils';

export function useMeasureUpgradeData(
    measure: ExtractQueryType<GetMeasureQuery, ['measure']> | null,
    fyStartDate: string | undefined,
    selectedFrequency: FrequencyPeriods,
    combineMethod: CombineMethod,
    isSourceYtd?: boolean
): {
    rows: MonthRow[];
    unused: MonthRow[];
} | null {
    const { getMeasureAsOfInput } = useInputMappers();

    if (measure && fyStartDate) {
        const data: MonthRow[] = [];

        const valueHistory = (measure.valueHistory || [])
            .slice()
            .sort(
                (a, b) =>
                    dayjs(a.asOfDate).valueOf() - dayjs(b.asOfDate).valueOf()
            );

        for (let i = 0; i < valueHistory.length; i++) {
            const asOfInput = getMeasureAsOfInput(
                measure.id,
                valueHistory[i as number]
            );

            const target = getSeriesValue(MeasureSeriesNames.Target, asOfInput);
            const actual = getSeriesValue(MeasureSeriesNames.Actual, asOfInput);
            const phasedForecast = getSeriesValue(
                MeasureSeriesNames.PhasedForecast,
                asOfInput
            );

            data.push({
                index: i,
                label: getLabelFreq(
                    i,
                    measure.frequencyPeriod,
                    asOfInput?.asOfDate
                ),
                dt: dayjs(asOfInput.asOfDate).format('YYYY-MM-DD'),

                interval:
                    target ||
                    createSeriesValue(MeasureSeriesNames.Target, null),
                ytd: null,

                forecast:
                    phasedForecast?.decimalValue !== undefined &&
                    phasedForecast?.decimalValue !== null
                        ? phasedForecast?.decimalValue
                        : null,
                forecastYtd: null,

                actual:
                    actual ||
                    createSeriesValue(MeasureSeriesNames.Actual, null),
                actualYtd: null,

                status: {
                    arrowColour: valueHistory[i as number].arrowColour,
                    arrowDirection: valueHistory[i as number].arrowDirection,
                    statusValue: valueHistory[i as number].statusValue,
                },

                hasChanges: true,
                asOf: asOfInput,
            });
        }

        const combinedData =
            selectedFrequency === FrequencyPeriods.Year
                ? convertYearData(data, fyStartDate, combineMethod, measure?.id)
                : selectedFrequency === FrequencyPeriods.Quarter
                ? convertQuarterData(
                      data,
                      fyStartDate,
                      combineMethod,
                      measure?.id
                  )
                : selectedFrequency === FrequencyPeriods.Month
                ? convertMonthData(
                      data,
                      fyStartDate,
                      combineMethod,
                      measure?.id
                  )
                : convertIntermittentData(data);

        if (isSourceYtd) {
            let targetYtd = 0;
            let actualYtd = 0;
            let forecastYtd = 0;

            // Move year to date values into the as ofs.
            for (const d of combinedData.rows) {
                if (d.interval.decimalValue !== null) {
                    const intervalTarget = d.interval.decimalValue - targetYtd;
                    targetYtd = d.interval.decimalValue;

                    d.interval.decimalValue = intervalTarget;
                    d.ytd = targetYtd;

                    const seriesValue = (d.asOf?.values || []).find(
                        (v) => v.seriesType?.name === MeasureSeriesNames.Target
                    );

                    if (seriesValue) {
                        seriesValue.decimalValue = intervalTarget;
                    }
                }

                if (d.actual.decimalValue !== null) {
                    const intervalActual = d.actual.decimalValue - actualYtd;
                    actualYtd = d.actual.decimalValue;

                    d.actual.decimalValue = intervalActual;
                    d.actualYtd = actualYtd;

                    const seriesValue = (d.asOf?.values || []).find(
                        (v) => v.seriesType?.name === MeasureSeriesNames.Actual
                    );

                    if (seriesValue) {
                        seriesValue.decimalValue = intervalActual;
                    }
                }

                if (d.forecast !== null) {
                    const intervalForecast = d.forecast - forecastYtd;
                    forecastYtd = d.forecast;

                    d.forecast = intervalForecast;
                    d.forecastYtd = forecastYtd;

                    const seriesValue = (d.asOf?.values || []).find(
                        (v) =>
                            v.seriesType?.name ===
                            MeasureSeriesNames.PhasedForecast
                    );

                    if (seriesValue) {
                        seriesValue.decimalValue = intervalForecast;
                    }
                }
            }
        }

        return combinedData;
    }

    return null;
}
