import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { Task } from '../data/types';

export function useColumnPreferences<ListDataType>(
    listColumnsStorageKey: string,
    defaultColumns?: (keyof ListDataType)[]
): {
    selectedColumnNames: (keyof ListDataType)[];
    setSelectedColumnNames: Dispatch<SetStateAction<(keyof ListDataType)[]>>;
    toggleColumn: (columnName: keyof ListDataType) => void;
} {
    const getStoredColumns = (): (keyof ListDataType)[] => {
        const stored = localStorage.getItem(listColumnsStorageKey);

        let columns: (keyof ListDataType)[] = [];

        if (stored) {
            stored.split('|').forEach((columnName) => {
                if (columnName as keyof ListDataType) {
                    columns.push(columnName as keyof ListDataType);
                }
            });
        }

        if (!stored || columns.length === 0) {
            columns = defaultColumns || [];
        }

        return columns;
    };

    const [selectedColumnNames, setSelectedColumnNames] =
        useState<(keyof ListDataType)[]>(getStoredColumns());

    const storeColumns = useCallback(
        (columns: (keyof ListDataType)[]): void => {
            localStorage.setItem(listColumnsStorageKey, columns.join('|'));
        },
        [listColumnsStorageKey]
    );

    const toggleColumn = (columnName: keyof ListDataType) => {
        setSelectedColumnNames((prev) =>
            prev.some((c) => c === columnName)
                ? prev.filter((k) => k !== columnName)
                : [...prev, columnName]
        );
    };

    useEffect(() => {
        storeColumns(selectedColumnNames);
    }, [selectedColumnNames, storeColumns]);

    return {
        selectedColumnNames,
        setSelectedColumnNames,
        toggleColumn,
    };
}

export type TaskTileColumnType = Task & {
    resourcedFrom: [];
    resources: [];
    comments: [];
};

export type TaskTileColumn = keyof TaskTileColumnType;

export function useTaskTileColumnPreferences(): {
    selectedColumnNames: TaskTileColumn[];
    setSelectedColumnNames: Dispatch<SetStateAction<TaskTileColumn[]>>;
    toggleColumn: (columnName: TaskTileColumn) => void;
} {
    const listColumnsStorageKey = `ADVANCE_TILECOLUMNS_TASK`;

    return useColumnPreferences<TaskTileColumnType>(listColumnsStorageKey, [
        'sequence',
        'name',
        'resources',
        // 'effortWeight', -- default off
        'start',
        'due',
        'done',
        'resourcedFrom',
        'comments',
    ]);
}
