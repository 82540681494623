import React, { useCallback, useEffect, useState } from 'react';
import {
    Checkbox,
    ChoiceGroup,
    Dropdown,
    IDropdownOption,
    PanelType,
    Pivot,
    PivotItem,
    TextField,
} from '@fluentui/react';
import { Guid } from 'guid-typescript';
import { EditPanel } from '../../../components/shared/EditPanel';
import {
    SortableTextInputItem,
    SortableTextInputList,
} from '../../../components/SortableTextInputList';
import {
    TemplateReportElementType,
    TemplateReportGroupType,
} from '../TemplateReport';
import { sorters } from '../../../data/sorters';
import { TemplateReportPicker } from './TemplateReportPicker';
import { useStateContext } from '../../../services/contextProvider';
import {
    TemplateReportElementTypes,
    useGetTemplateReportLazyQuery,
    useGetTemplateReportSectionLazyQuery,
} from '../../../data/types';
import { InputShimmer } from '../../../components/inputs';
import { TemplateReportElementDeleteModal } from './TemplateReportElementDeleteModal';

export function TemplateReportCustomTablePanel(props: {
    isOpen: boolean;
    onDismiss: () => void;
    element: TemplateReportElementType;
    onElementChanged?: (element: TemplateReportElementType) => void;
    onElementsAdded?: (
        elements: TemplateReportElementType[],
        group?: TemplateReportGroupType | null
    ) => void;
    isReadOnly: boolean;
    missionId?: string | null | undefined;
}) {
    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const [title, setTitle] = useState<string | null>();
    const [columns, setColumns] = useState<SortableTextInputItem[]>([]);
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
    const [selectedPivotKey, setSelectedPivotKey] = useState<
        'COLUMNS' | 'IMPORT'
    >('COLUMNS');

    const [selectedImportType, setSelectedImportType] = useState<
        'COLUMNS_ONLY' | 'COLUMNS_AND_DATA'
    >('COLUMNS_ONLY');

    const [loadTemplateReport, { loading: templateReportLoading }] =
        useGetTemplateReportLazyQuery();

    const [loadTemplateReportSection] = useGetTemplateReportSectionLazyQuery();

    const [importFromTables, setImportFromTables] = useState<
        { elementId: string; tableTitle: string; templateReportId: string }[]
    >([]);

    const [isIncludeCommentsChecked, setIsIncludeCommentsChecked] =
        useState(false);

    const [selectedInputFromTable, setSelectedInputFromTable] = useState<{
        elementId: string;
        tableTitle: string;
        templateReportId: string;
    } | null>();

    useEffect(() => {
        if (props.isOpen) {
            if (props.element) {
                setTitle(props.element.title);
                setSelectedImportType('COLUMNS_ONLY');
                setSelectedPivotKey('COLUMNS');
                setIsConfirmingDelete(false);
                setSelectedInputFromTable(null);
                setIsIncludeCommentsChecked(false);

                setColumns(
                    props.element.columns
                        .slice()
                        .sort(sorters.sequenceSorter)
                        .map((c) => {
                            return {
                                id: c.id,
                                name: c.columnName,
                                sequence: c.sequence,
                                isNew: false,
                                isDeleted: false,
                                version: null,
                            } as SortableTextInputItem;
                        })
                );
            } else {
                setColumns([]);
            }
        }
    }, [props.isOpen, props.element]);

    const handleImportFromReportChange = useCallback(
        async (
            report: {
                templateReportId: string;
            } | null
        ) => {
            if (currentTenantId && report?.templateReportId) {
                const result = await loadTemplateReport({
                    variables: {
                        tenantId: currentTenantId,
                        id: report.templateReportId,
                    },
                });

                const importFromElements =
                    result?.data?.reports?.templateReport?.sections
                        .flatMap((s) => s.elements)
                        .filter(
                            (e) =>
                                e.type ===
                                TemplateReportElementTypes.CustomTable
                        );

                setImportFromTables(
                    importFromElements?.map((e) => ({
                        templateReportId: report.templateReportId,
                        elementId: e.id || '',
                        tableTitle: e.title ?? 'Untitled Table',
                    })) || []
                );
            } else {
                setImportFromTables([]);
            }

            setSelectedInputFromTable(null);
        },
        [currentTenantId, loadTemplateReport]
    );

    type ColumnType = {
        id: string | null;
        columnName: string | null;
        sequence: number;
        cells: {
            id: string | null;
            rowIndex: number;
            content: string | null;
        }[];
        version: string | null;
    };

    const createOrUpdateColumns = (): ColumnType[] => {
        const updatedColumns: ColumnType[] = [];

        columns.sort(sorters.sequenceSorter).forEach((c) => {
            if (c.isNew) {
                updatedColumns.push({
                    id: c.id || Guid.create().toString(),
                    columnName: c.name,
                    sequence: c.sequence,
                    cells: [],
                    version: '',
                });
            } else if (!c.isDeleted) {
                const updatedColumn = props.element.columns.find(
                    (oc) => c.id === oc.id
                );
                if (updatedColumn) {
                    updatedColumns.push({
                        ...updatedColumn,
                        columnName: c.name,
                        sequence: c.sequence,
                    });
                }
            }
        });

        return updatedColumns;
    };

    const importFromOtherReport = async (): Promise<ColumnType[]> => {
        let importedColumns: ColumnType[] = [];

        if (selectedInputFromTable?.templateReportId && currentTenantId) {
            const result = await loadTemplateReport({
                variables: {
                    tenantId: currentTenantId,
                    id: selectedInputFromTable.templateReportId,
                },
            });

            const sourceTemplateReport = result.data?.reports?.templateReport;

            const sourceElement = sourceTemplateReport?.sections
                .flatMap((s) => s.elements)
                .find((e) => e.id === selectedInputFromTable.elementId);

            importedColumns =
                sourceElement?.columns.map((c) => ({
                    id: Guid.create().toString(),
                    columnName: c.columnName,
                    sequence: c.sequence,
                    version: '',
                    cells:
                        selectedImportType === 'COLUMNS_ONLY'
                            ? []
                            : c.cells.map((cell) => ({
                                  id: Guid.create().toString(),
                                  rowIndex: cell.rowIndex,
                                  content: cell.content,
                              })),
                })) || [];
        }

        return importedColumns;
    };

    const handleUpdateClick = async () => {
        const updatedColumns: ColumnType[] =
            selectedPivotKey === 'IMPORT'
                ? await importFromOtherReport()
                : createOrUpdateColumns();

        const section =
            props.element.sectionId && currentTenantId
                ? (
                      await loadTemplateReportSection({
                          variables: {
                              id: props.element.sectionId,
                              tenantId: currentTenantId,
                          },
                      })
                  )?.data?.reports?.templateReportSection
                : null;

        if (props.element.id) {
            if (props.onElementChanged) {
                props.onElementChanged({
                    ...props.element,
                    title: title || null,
                    columns: updatedColumns,
                });
            }
        } else if (props.onElementsAdded) {
            const maxSequence = Math.max(
                ...[
                    -1,
                    ...(section?.elements?.map((e) => e.sequence) || []),
                    ...(section?.groups?.map((e) => e.sequence) || []),
                ]
            );

            if (isIncludeCommentsChecked) {
                props.onElementsAdded(
                    [
                        {
                            ...props.element,
                            title: title || null,
                            columns: updatedColumns,
                            sequence: 0,
                            layoutGrow: '2',
                        },
                        {
                            id: null,
                            groupId: null,
                            attachmentId: null,
                            title: 'Comments',
                            type: TemplateReportElementTypes.Markdown,
                            sourceTemplateReportSectionElementId: null,
                            sequence: 1,
                            layoutGrow: '1',
                            textContent: '',
                            columns: [],
                            measureLinks: [],
                            taskLinks: [],
                            sectionId: props.element.sectionId,
                            utcUpdated: null,
                            version: '',
                        },
                    ],
                    {
                        id: null,
                        layoutDirection: 'row',
                        layoutGrow: '1',
                        sequence: maxSequence + 1,
                        sectionId: props.element.sectionId,
                    }
                );
            } else {
                props.onElementsAdded([
                    {
                        ...props.element,
                        title: title || null,
                        columns: updatedColumns,
                        sequence: maxSequence + 1,
                    },
                ]);
            }
        }

        props.onDismiss();
    };

    const onIncludeCommentsCheckedChange = (
        _ev?: React.FormEvent,
        checked?: boolean
    ) => {
        setIsIncludeCommentsChecked(!!checked);
    };

    const canDelete = !props.isReadOnly;

    const importFromTableOptions: IDropdownOption[] =
        importFromTables.map((t) => ({
            key: t.elementId,
            text: t.tableTitle,
            data: t,
        })) || [];

    if (importFromTableOptions.length) {
        importFromTableOptions.unshift({
            key: '',
            text: 'Select Table',
        });
    } else {
        importFromTableOptions.unshift({
            key: '',
            text: 'No tables found',
        });
    }

    const isValid =
        !props.isReadOnly &&
        !!title &&
        (selectedPivotKey === 'COLUMNS' || !!selectedInputFromTable);

    return (
        <React.Fragment>
            <EditPanel
                activeViewName="TemplateReport"
                onDismiss={props.onDismiss}
                showPanel={props.isOpen}
                panelType={PanelType.smallFixedFar}
                onDeleteClick={
                    canDelete ? () => setIsConfirmingDelete(true) : undefined
                }
                headerText="Custom Table"
                isSaving={false}
                isValid={isValid}
                hasSaved={false}
                saveErrorMessage={undefined}
                onUpdateClick={handleUpdateClick}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                        paddingTop: 8,
                    }}
                >
                    <TextField
                        label="Title"
                        value={title || ''}
                        onChange={(_ev, newValue) => {
                            setTitle(newValue);
                        }}
                    />

                    {!props.element.id && (
                        <Checkbox
                            label="Include Comment Box"
                            checked={isIncludeCommentsChecked}
                            onChange={onIncludeCommentsCheckedChange}
                        />
                    )}

                    <Pivot
                        selectedKey={selectedPivotKey}
                        onLinkClick={(item?: PivotItem) => {
                            setSelectedPivotKey(
                                (item?.props.itemKey || 'COLUMNS') as
                                    | 'COLUMNS'
                                    | 'IMPORT'
                            );
                        }}
                    >
                        <PivotItem headerText="Columns" itemKey="COLUMNS">
                            <SortableTextInputList
                                {...props}
                                isLoading={false}
                                inputItems={columns || []}
                                onChange={(updatedItems) =>
                                    setColumns(updatedItems)
                                }
                                addButtonText="Add Column"
                            />
                        </PivotItem>
                        {!!currentTenantId &&
                            !!currentFinancialYearCode &&
                            !!props.missionId && (
                                <PivotItem
                                    headerText="Import..."
                                    itemKey="IMPORT"
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 8,
                                        }}
                                    >
                                        <TemplateReportPicker
                                            tenantId={currentTenantId}
                                            financialYearCode={
                                                currentFinancialYearCode
                                            }
                                            missionId={props.missionId}
                                            onReportChange={
                                                handleImportFromReportChange
                                            }
                                        />

                                        <InputShimmer
                                            isDataLoaded={
                                                !templateReportLoading
                                            }
                                        >
                                            <Dropdown
                                                disabled={
                                                    importFromTables.length ===
                                                    0
                                                }
                                                label="Import From Table"
                                                options={importFromTableOptions}
                                                placeholder="Select Table"
                                                selectedKey={
                                                    selectedInputFromTable?.elementId
                                                }
                                                onChange={(_ev, option) => {
                                                    setSelectedInputFromTable(
                                                        option?.data || null
                                                    );
                                                }}
                                            />
                                        </InputShimmer>

                                        <ChoiceGroup
                                            selectedKey={selectedImportType}
                                            options={[
                                                {
                                                    key: 'COLUMNS_ONLY',
                                                    text: 'Columns Only',
                                                },
                                                {
                                                    key: 'COLUMNS_AND_DATA',
                                                    text: 'Columns and data',
                                                },
                                            ]}
                                            onChange={(_ev, option) => {
                                                setSelectedImportType(
                                                    option?.key ===
                                                        'COLUMNS_AND_DATA'
                                                        ? 'COLUMNS_AND_DATA'
                                                        : 'COLUMNS_ONLY'
                                                );
                                            }}
                                        />
                                    </div>
                                </PivotItem>
                            )}
                    </Pivot>
                </div>
            </EditPanel>
            {canDelete && !!props.element.sectionId && !!props.element.id && (
                <TemplateReportElementDeleteModal
                    sectionId={props.element.sectionId}
                    elementId={props.element.id}
                    isOpen={isConfirmingDelete}
                    onDeleted={() => {
                        setIsConfirmingDelete(false);
                        props.onDismiss();
                    }}
                    onDismiss={() => setIsConfirmingDelete(false)}
                />
            )}
        </React.Fragment>
    );
}
