import * as React from 'react';
import {
    Text,
    PersonaSize,
    PersonaPresence,
    Shimmer,
    ShimmerElementsGroup,
    ShimmerElementType,
    PersonaCoin,
    Stack,
} from '@fluentui/react';

import { photoService } from '../../services/photo.service';

function MenuPersona(props: {
    isDataLoaded: boolean;
    missionOwner: string | null | undefined;
    missionTitle: string | null | undefined;
    missionUserId: string;
    hidePersonaDetails: boolean;
}): JSX.Element {
    const truncate = (input: string, length: number): string => {
        if (input.length > length)
            return input.substring(0, length - 3) + '...';
        else return input;
    };

    return (
        <Shimmer
            isDataLoaded={props.isDataLoaded}
            customElementsGroup={
                <div>
                    {props.hidePersonaDetails && (
                        <ShimmerElementsGroup
                            shimmerElements={[
                                {
                                    type: ShimmerElementType.circle,
                                    height: 40,
                                },
                            ]}
                        />
                    )}
                    {!props.hidePersonaDetails && (
                        <ShimmerElementsGroup
                            shimmerElements={[
                                {
                                    type: ShimmerElementType.gap,
                                    width: 28,
                                    height: 40,
                                },
                                {
                                    type: ShimmerElementType.circle,
                                    height: 40,
                                },
                                {
                                    type: ShimmerElementType.gap,
                                    width: 28,
                                    height: 40,
                                },
                            ]}
                        />
                    )}
                    {!props.hidePersonaDetails && (
                        <ShimmerElementsGroup
                            styles={{
                                root: {
                                    marginTop: 4,
                                },
                            }}
                            shimmerElements={[
                                {
                                    type: ShimmerElementType.line,
                                    height: 30,
                                },
                            ]}
                        />
                    )}
                </div>
            }
        >
            {(props.missionTitle || props.missionOwner) && (
                <Stack horizontalAlign="center" tokens={{ childrenGap: 4 }}>
                    <PersonaCoin
                        text={props.missionOwner || ''}
                        secondaryText={truncate(props.missionTitle || '', 24)}
                        showSecondaryText={true}
                        imageUrl={photoService.getImageUrl(props.missionUserId)}
                        size={PersonaSize.size40}
                        hidePersonaDetails
                        presence={PersonaPresence.none}
                    />

                    {!props.hidePersonaDetails && (
                        <Stack.Item>
                            <Text
                                variant="smallPlus"
                                block
                                style={{
                                    textAlign: 'center',
                                    lineHeight: '1.2em',
                                }}
                            >
                                {props.missionOwner}
                            </Text>
                            <Text
                                variant="tiny"
                                block
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                {props.missionTitle}
                            </Text>
                        </Stack.Item>
                    )}
                </Stack>
            )}
        </Shimmer>
    );
}

export default React.memo(MenuPersona);
