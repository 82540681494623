import React from 'react';
import { Text, mergeStyleSets } from '@fluentui/react';
import {
    MeasureAsOf,
    MeasureTypes,
    Multipliers,
    ValueFormulaStepLogQl,
    useGetMeasureValueFormulaEvaluationQuery,
} from '../../../data/types';
import { useMeasureValueFormatter } from '../../../hooks/useMeasureValueFormatter';
import { useThemes } from '../../../hooks/useThemes';
import { useStateContext } from '../../../services/contextProvider';
import { useLanguage } from '../../../services/i18n';

type Props = {
    isShown?: boolean;
    measureId: string | null | undefined;
    showValues?: boolean;
    asOf?: MeasureAsOf;
    seriesTypeName?: string;
    formattedTotal?: string | null;
    total?: number;
    formatOptions?:
        | {
              measureType: MeasureTypes;
              multiplier: Multipliers;
              currency: { symbol: string | null | undefined } | null;
              decimalPlaces: number;
          }
        | undefined
        | null;
};

function ValueCalcToolTipContent(props: Props): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { currentTheme } = useThemes();

    const { t } = useLanguage();

    const measureFormatter = useMeasureValueFormatter(props.formatOptions);

    const { data: data2 } = useGetMeasureValueFormulaEvaluationQuery({
        skip:
            !currentTenantId ||
            !props.measureId ||
            !props.seriesTypeName ||
            !props.isShown,
        variables: {
            tenantId: currentTenantId || '',
            measureId: props.measureId || '',
        },
    });

    const findLog = (): ValueFormulaStepLogQl | undefined => {
        const stepLog = (
            data2?.measureValueFormulaEvaluation?.stepLogs || []
        ).find(
            (step) =>
                step?.seriesName == props.seriesTypeName &&
                step?.asOfDate == props.asOf?.asOfDate
        );

        return stepLog;
    };

    const stepLog: ValueFormulaStepLogQl | undefined = findLog();

    // const formattedTotal =
    //     props.showValues && props.total
    //         ? measureFormatter.formatDecimalValue(props.total)
    //         : null;

    const classNames = mergeStyleSets({
        container: {
            padding: 8,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        title: {
            paddingBottom: 8,
            borderBottom: `solid 1px ${currentTheme.palette.neutralLight}`,
        },
        underline: {
            paddingBottom: 8,
            borderBottom: `solid 1px ${currentTheme.palette.neutralLight}`,
        },
        overline: {
            paddingTop: 8,
            borderTop: `solid 1px ${currentTheme.palette.neutralLight}`,
        },
        measureContainer: {
            display: 'flex',
            gap: 8,
            flexDirection: 'row',
            paddingBottom: 8,
            borderBottom: `solid 1px ${currentTheme.palette.neutralLight}`,
        },
        sourceText: {
            width: 250,
        },
        measureText: {
            fontWeight: 600,
        },
        valueText: {
            minWidth: 100,
            textAlign: 'right',
        },
        total: {
            display: 'flex',
            gap: 8,
            flexDirection: 'row',
        },
        step: {
            display: 'flex',
            gap: 8,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    });

    return (
        <div className={classNames.container}>
            <Text variant="mediumPlus" className={classNames.title}>
                {props.showValues ? 'Calculated Value' : 'Calculated Values'}
            </Text>

            {props.seriesTypeName && (
                <Text variant="small">
                    <code>
                        SeriesName:{' '}
                        {props.seriesTypeName == 'PhasedForecast'
                            ? t('measure-of-success.forecast')
                            : props.seriesTypeName}
                    </code>
                </Text>
            )}

            {props.asOf?.asOfDate && (
                <Text variant="small" className={classNames.underline}>
                    <code>AsOf: {props.asOf?.asOfDate}</code>
                </Text>
            )}

            {/* Step breakdown from actual execution */}
            {(stepLog?.steps || []).map((step, index) => (
                <div
                    key={`step-${index}`}
                    className={`${classNames.step} ${
                        step.name?.startsWith('=') ? classNames.overline : ''
                    }`}
                >
                    <Text
                        variant="small"
                        block
                        style={{ marginLeft: `${step.indent}em` }}
                    >
                        {step?.name}
                    </Text>

                    <div className={classNames.valueText}>
                        <Text block variant="small">
                            {step.formatNumber
                                ? measureFormatter.formatDecimalValue(
                                      step.decimalValue
                                  )
                                : step.decimalValue}
                        </Text>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ValueCalcToolTipContent;

// function findValueByAsOf(
//     valueHistory:
//         | {
//               asOfDate: string;
//               values:
//                   | {
//                         decimalValue: number | null;
//                         seriesType: {
//                             name: string | null;
//                         } | null;
//                     }[]
//                   | null;
//           }[]
//         | null,
//     seriesTypeName?: string,
//     asOfDate?: string | null
// ): number | null | undefined {
//     if (!seriesTypeName || !asOfDate) {
//         return undefined;
//     }

//     const asOf = valueHistory?.find((a) => a.asOfDate === asOfDate);

//     const series = asOf?.values?.find(
//         (v) => v.seriesType?.name === seriesTypeName
//     );

//     return series?.decimalValue;
// }
