import React, { useEffect, useState } from 'react';
import {
    DefaultButton,
    FontSizes,
    Label,
    Persona,
    PersonaSize,
    Stack,
} from '@fluentui/react';
import { ImportResults, ImportResult } from './ImportResults';
import { useMissionMentor } from '../../../hooks/useMissionMentor';
import { ChatBubble } from '../../../components/mai/ChatBubble';
import mai from '../../../images/mai.png';
import { Markdown } from '../../Presentations/Markdown';
import { ImportFilter } from './ImportFilter';
import { convertDataUrlToBlob, readFile } from '../../Measure/utils/fileUtils';

export function AIImportPivot(): JSX.Element {
    const [importResults, setImportResults] =
        React.useState<ImportResult | null>();
    const [importMessage, setImportMessage] = React.useState<string | null>('');
    const [importError, setImportError] = React.useState<string | null>('');
    const [importFileData, setImportFileData] = React.useState<
        string | ArrayBuffer | null
    >();

    // ImportFilter:
    const [selectedMissionIds, setSelectedMissionIds] = React.useState<
        string[]
    >([]);
    const [, setSelectedTeamIds] = React.useState<string[]>([]);
    const [, setSelectedTagIds] = React.useState<string[]>([]);

    const {
        importFileAnalysis,
        contentMd,
        //isEnabled: isMAIEnabled,
        isStreaming,
        cancel: cancelMaiStreaming,
    } = useMissionMentor();

    const [chatContent, setChatContent] = useState<JSX.Element>();

    const onFileChange = async (
        e: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
        setImportMessage(null);
        setImportError(null);
        // setImportReady(false);
        setImportResults(null);
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            // Disable checking for now
            // if (file.name.toLowerCase().endsWith('.xlsx')) {
            //   setIsExcel(true);
            // } else if (!file.name.toLowerCase().endsWith('.json')) {
            //   setImportError('Only .xslx and .json files are supported');
            //   setImportFileData(null);
            //   return;
            // }
            const importDataUrl = await readFile(file);
            setImportFileData(importDataUrl);
        }
    };

    const handleGoButtonClick = () => {
        cancelMaiStreaming();
        const blob = convertDataUrlToBlob(importFileData);
        importFileAnalysis(selectedMissionIds, blob);
    };

    useEffect(() => {
        let markdown = contentMd;

        if (markdown.indexOf('```markdown') > -1) {
            markdown = markdown.replaceAll('```markdown', '');
            markdown = markdown.replaceAll('```', '');
        }

        if (markdown) {
            if (isStreaming) {
                markdown = markdown + ' ⬤';
            }

            setChatContent(<Markdown source={markdown} />);
        } else if (isStreaming) {
            setChatContent(<p className="cursor">⬤</p>);
        } else {
            setChatContent(undefined);
        }
    }, [contentMd, isStreaming]);

    return (
        <Stack tokens={{ childrenGap: '24' }}>
            <ImportFilter
                missionIds={selectedMissionIds}
                setSelectedMissionIds={setSelectedMissionIds}
                setSelectedTeamIds={setSelectedTeamIds}
                setSelectedTagIds={setSelectedTagIds}
            />
            <Label>Upload a File to be analysed by AI</Label>
            <div
                style={{
                    fontSize: FontSizes.size12,
                    marginTop: '0',
                }}
            >
                (either in .xlsx or .json format)
            </div>

            <input type="file" onChange={onFileChange} />

            {importFileData && (
                <Stack.Item align="start">
                    <DefaultButton
                        text="Go"
                        iconProps={{ iconName: 'Robot' }}
                        onClick={handleGoButtonClick}
                        style={{ width: '5em' }}
                    />
                </Stack.Item>
            )}

            {chatContent && (
                <ChatBubble
                    content={chatContent}
                    tailPosition="topLeft"
                    persona={
                        <Persona
                            imageUrl={mai}
                            size={PersonaSize.size32}
                            hidePersonaDetails
                        />
                    }
                />
            )}

            {importMessage && (
                <>
                    <Stack.Item
                        styles={{ root: { color: 'black', margin: '10px 0' } }}
                    >
                        {importMessage}
                    </Stack.Item>
                </>
            )}

            {importResults && <ImportResults results={importResults} />}
            {importError && (
                <Stack.Item
                    styles={{
                        root: {
                            color: 'red',
                            margin: '10px 0',
                        },
                    }}
                >
                    {importError}
                </Stack.Item>
            )}
        </Stack>
    );
}
