import { DayOfWeek, IDatePickerProps } from '@fluentui/react';
import dayjs from 'dayjs';

export class DefaultProps {
    public static DatePickerProps: IDatePickerProps = {
        firstDayOfWeek: DayOfWeek.Monday,
        formatDate: (date?: Date | null): string => {
            return dayjs(date || undefined).format('DD MMM YYYY');
        },
    };
}
