import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    Checkbox,
    mergeStyleSets,
    IRawStyle,
} from '@fluentui/react';

export function MeasureValuesClearDialog(props: {
    hidden: boolean;
    onDismiss: () => void;
    onClearClick: (valuesToClear: {
        target: boolean;
        actual: boolean;
        forecast: boolean;
    }) => void;
    allowClear: {
        target: boolean;
        actual: boolean;
        forecast: boolean;
    };
}): JSX.Element {
    const [valuesToClear, setValuesToClear] = useState({
        target: false,
        actual: false,
        forecast: false,
    });

    const classNames = mergeStyleSets({
        checkBoxes: {
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
        } as IRawStyle,
    });

    useEffect(() => {
        if (!props.hidden) {
            setValuesToClear({
                target: false,
                actual: false,
                forecast: false,
            });
        }
    }, [props.hidden]);

    return (
        <Dialog
            hidden={props.hidden}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Clear Values',
                subText: 'Select the values you want to clear.',
                showCloseButton: true,
            }}
            modalProps={{
                isBlocking: true,
            }}
            onDismiss={props.onDismiss}
        >
            <div className={classNames.checkBoxes}>
                {props.allowClear.target && (
                    <Checkbox
                        label="Clear Targets"
                        checked={valuesToClear.target}
                        onChange={(_ev, checked) =>
                            setValuesToClear((v) => ({
                                ...v,
                                target: checked || false,
                            }))
                        }
                    />
                )}

                {props.allowClear.actual && (
                    <Checkbox
                        label="Clear Actuals"
                        checked={valuesToClear.actual}
                        onChange={(_ev, checked) =>
                            setValuesToClear((v) => ({
                                ...v,
                                actual: checked || false,
                            }))
                        }
                    />
                )}

                {props.allowClear.forecast && (
                    <Checkbox
                        label="Clear Forecasts"
                        checked={valuesToClear.forecast}
                        onChange={(_ev, checked) =>
                            setValuesToClear((v) => ({
                                ...v,
                                forecast: checked || false,
                            }))
                        }
                    />
                )}
            </div>

            <DialogFooter>
                <PrimaryButton
                    onClick={() => {
                        props.onClearClick(valuesToClear);
                    }}
                    text="Clear Values"
                    disabled={
                        !valuesToClear.target &&
                        !valuesToClear.actual &&
                        !valuesToClear.forecast
                    }
                />
                <DefaultButton onClick={props.onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
}
