export function Introduction(): JSX.Element {
    return (
        <div>
            <h1>Help Introduction</h1>
            <p>Welcome to the Advance API!</p>
            <p>
                You can use our API to access Advance API endpoints, which can
                get information on various divisions, teams, missions, measures,
                and tasks.
            </p>
        </div>
    );
}
