import React, { useState } from 'react';
import {
    CheckboxVisibility,
    CommandBar,
    DatePicker,
    DetailsListLayoutMode,
    DetailsRow,
    FocusZone,
    IColumn,
    IconButton,
    IDetailsFooterProps,
    IIconProps,
    IObjectWithKey,
    Selection,
    SelectionMode,
    Separator,
    ShimmeredDetailsList,
    Stack,
    Text,
} from '@fluentui/react';

import dayjs from 'dayjs';

import { useStateContext } from '../services/contextProvider';

import {
    MeasureAsOf,
    SeriesValue,
    GetMeasureQuery,
    useUpdateMeasureAsOfMutation,
    PhaseType,
    useUpdateMeasureMutation,
    Measure,
    GetMeasureValueHistoryQuery,
} from '../data/types';

import {
    ExtractQueryArrayType,
    MeasureSeriesNames,
} from '../data/extendedTypes';

import { ValueInput } from './inputs';
import { DefaultProps } from '../DefaultProps';
import { useMeasureValueFormatter } from '../hooks/useMeasureValueFormatter';
import { sorters } from '../data/sorters';

import { HistoryChartV1 } from './HistoryChart';
import PhasingWarnings from './PhasingWarnings';
import { DetailsListCellItemContainer } from './shared/DetailsListCellItemContainer';

type PhasingProps = {
    isLoading: boolean;
    measure: GetMeasureQuery['measure'] | undefined;
    allowEdit: boolean;
    onPhasingChanged: () => void;
};

export type MeasureValueHistoryItem = ExtractQueryArrayType<
    GetMeasureValueHistoryQuery,
    ['measure', 'valueHistory']
>;

export default function Phasing(props: PhasingProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [showAddPhase, setShowAddPhase] = useState<boolean>();
    const [editingId, setEditingId] = useState<string | null>();

    const [defaultTargetValue, setDefaultTargetValue] = useState<{
        decimalValue: number | null;
        stringValue: string | null;
        dateValue: string | null;
    }>({ decimalValue: null, stringValue: null, dateValue: null });

    const [defaultForecastValue, setDefaultForecastValue] = useState<{
        decimalValue: number | null;
        stringValue: string | null;
        dateValue: string | null;
    }>({ decimalValue: null, stringValue: null, dateValue: null });

    const [asOfInput, setAsOfInput] = useState<MeasureAsOf>({
        id: null,
        measureId: props.measure?.id || null,
        asOfDate: null,
        values: [],
        version: '',
    });

    const [selectedPhasings, setSelectedPhasings] = useState<IObjectWithKey[]>(
        []
    );

    const measureValueFormatter = useMeasureValueFormatter(props.measure);

    React.useEffect(() => {
        if (props.measure) {
            setEditingId(null);
            setAsOfInput({
                id: null,
                measureId: props.measure.id || null,
                asOfDate: null,
                values: [],
                version: '',
            });
        }
    }, [props.measure, currentTenantId]);

    const [updateMeasureAsOf, { loading: isSaving }] =
        useUpdateMeasureAsOfMutation();

    const [updateMeasure, { loading: isSavingMeasure }] =
        useUpdateMeasureMutation();

    const savePhaseAsync = async (): Promise<void> => {
        if (
            asOfInput &&
            asOfInput?.asOfDate &&
            asOfInput?.values &&
            asOfInput?.values?.length > 0 &&
            asOfInput?.values?.find((v) => v.decimalValue != null)
        ) {
            const asOfDate = asOfInput?.asOfDate;
            const adjustedInput = asOfInput as MeasureAsOf;

            if (
                adjustedInput.asOfDate &&
                props.measure?.phaseType === PhaseType.Cumulative
            ) {
                adjustedInput.values
                    ?.filter(
                        (v) =>
                            v.seriesType?.name ===
                                MeasureSeriesNames.PhasedTarget ||
                            v.seriesType?.name ===
                                MeasureSeriesNames.PhasedForecast
                    )
                    .forEach((v) => {
                        const previousSeriesValue = getPreviousAsOfValue(
                            v.seriesType?.name as MeasureSeriesNames,
                            asOfDate
                        );

                        // Convert the inputted value into period end.
                        if (
                            previousSeriesValue?.decimalValue !== null &&
                            previousSeriesValue?.decimalValue !== undefined &&
                            v.decimalValue !== null &&
                            v.decimalValue !== undefined
                        ) {
                            v.decimalValue += previousSeriesValue?.decimalValue;
                        }
                    });
            }

            await updateMeasureAsOf({
                variables: {
                    tenantId: currentTenantId || '',
                    input: adjustedInput,
                },
            });

            setAsOfInput({ ...asOfInput, asOfDate: null, values: [] });

            setEditingId(null);
            setShowAddPhase(false);

            props.onPhasingChanged();
        }
    };

    const handleEditButtonClick = (measureAsOf?: MeasureValueHistoryItem) => {
        if (measureAsOf) {
            setEditingId(measureAsOf?.id);

            setShowAddPhase(false);

            const currentTargetValue = measureAsOf?.values?.find(
                (v) => v.seriesType?.name === MeasureSeriesNames.PhasedTarget
            );

            const currentForecastValue = measureAsOf?.values?.find(
                (v) => v.seriesType?.name === MeasureSeriesNames.PhasedForecast
            );

            const targetValue = {
                id: currentTargetValue?.id || null,
                seriesType: {
                    id: null,
                    calcSymbol: null,
                    defaultFormat: null,
                    sequence: null,
                    name: MeasureSeriesNames.PhasedTarget,
                },
                calcId: currentTargetValue?.calcId || null,
                version: currentTargetValue?.version || null,
                decimalValue:
                    currentTargetValue?.decimalValue !== undefined
                        ? currentTargetValue?.decimalValue
                        : null,
                dateValue: currentTargetValue?.dateValue || null,
                stringValue: currentTargetValue?.stringValue || null,
            };

            const forecastValue: SeriesValue = {
                id: currentForecastValue?.id || null,
                seriesType: {
                    id: null,
                    calcSymbol: null,
                    defaultFormat: null,
                    sequence: null,
                    name: MeasureSeriesNames.PhasedForecast,
                },
                calcId: currentForecastValue?.calcId || null,
                version: currentForecastValue?.version || null,
                decimalValue:
                    currentForecastValue?.decimalValue !== undefined
                        ? currentForecastValue?.decimalValue
                        : null,
                dateValue: currentForecastValue?.dateValue || null,
                stringValue: currentForecastValue?.stringValue || null,
            };

            if (props.measure?.phaseType === PhaseType.Cumulative) {
                targetValue.decimalValue = getCumulativeValue(
                    MeasureSeriesNames.PhasedTarget,
                    measureAsOf.asOfDate
                );

                forecastValue.decimalValue = getCumulativeValue(
                    MeasureSeriesNames.PhasedForecast,
                    measureAsOf.asOfDate
                );
            }

            setDefaultTargetValue(targetValue);

            setDefaultForecastValue(forecastValue);

            setAsOfInput({
                id: measureAsOf?.id || null,
                measureId: props.measure?.id || null,
                asOfDate: measureAsOf.asOfDate,
                values: [targetValue, forecastValue],
                version: measureAsOf.version,
            });
        }
    };

    const deleteSelectedPhasingAsync = async (
        selectedPhasing: MeasureValueHistoryItem
    ): Promise<void> => {
        return updateMeasureAsOf({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    id: selectedPhasing.id,
                    measureId: props.measure?.id || '',
                    asOfDate: selectedPhasing.asOfDate,
                    version: selectedPhasing.version,
                    values: (selectedPhasing.values || [])
                        .filter(
                            (v) =>
                                v.seriesType?.name ===
                                    MeasureSeriesNames.PhasedTarget ||
                                v.seriesType?.name ===
                                    MeasureSeriesNames.PhasedForecast
                        )
                        .map((v) => {
                            return {
                                id: v.id,
                                seriesType: v.seriesType,
                                calcId: v.calcId,
                                version: v.version,
                                decimalValue: null,
                                dateValue: null,
                                stringValue: null,
                            } as SeriesValue;
                        }),
                },
            },
        }).then(() => {
            // ¯\_(ツ)_/¯
        });
    };

    const deleteSelectedPhasingsAsync = async (): Promise<void> => {
        await (selectedPhasings as MeasureValueHistoryItem[]).reduce(
            async (previousPromise, selectedPhasing) => {
                await previousPromise;
                return deleteSelectedPhasingAsync(selectedPhasing);
            },
            Promise.resolve()
        );
        props.onPhasingChanged();
    };

    const onValueInputChange = (
        seriesName: MeasureSeriesNames,
        decimalValue: number | null,
        stringValue: string | null,
        dateValue: string | null
    ): void => {
        const values = asOfInput.values || [];

        let phasedValue = values.find(
            (v) => v?.seriesType?.name === seriesName
        );

        if (!phasedValue) {
            phasedValue = {
                seriesType: {
                    id: null,
                    calcSymbol: null,
                    sequence: null,
                    defaultFormat: null,
                    name: seriesName,
                },
            } as SeriesValue;

            values.push(phasedValue);
        }

        phasedValue.id = null;
        phasedValue.calcId = null;
        phasedValue.decimalValue = decimalValue;
        phasedValue.stringValue = stringValue;
        phasedValue.dateValue = dateValue;
        phasedValue.version = '';

        setAsOfInput({
            ...asOfInput,
            values: values,
        });
    };

    const handlePhasedTargetValueInputChange = (
        decimalValue: number | null,
        stringValue: string | null,
        dateValue: string | null
    ) => {
        onValueInputChange(
            MeasureSeriesNames.PhasedTarget,
            decimalValue,
            stringValue,
            dateValue
        );
    };

    const handlePhasedForecastValueInputChange = (
        decimalValue: number | null,
        stringValue: string | null,
        dateValue: string | null
    ) => {
        onValueInputChange(
            MeasureSeriesNames.PhasedForecast,
            decimalValue,
            stringValue,
            dateValue
        );
    };

    const handleAddPhaseButtonClick = () => {
        setDefaultTargetValue({
            decimalValue: null,
            stringValue: null,
            dateValue: null,
        });

        setDefaultForecastValue({
            decimalValue: null,
            stringValue: null,
            dateValue: null,
        });

        setAsOfInput({
            id: null,
            measureId: props.measure?.id || '',
            version: '',
            asOfDate: null,
            values: [],
        });

        setShowAddPhase(true);
    };

    const getPreviousAsOfValue = (
        seriesName: MeasureSeriesNames,
        asOfDate: string
    ): {
        decimalValue: number | null;
    } | null => {
        const previousAsOf = props.measure?.valueHistory
            ?.filter((v) => v.asOfDate && dayjs(v.asOfDate).isBefore(asOfDate))
            .sort(sorters.asOfDateSorter)
            .reverse()
            .find((af) =>
                af.values?.find((v) => v.seriesType?.name === seriesName)
            );

        const seriesValue = previousAsOf?.values?.find(
            (sv) => sv?.seriesType?.name === seriesName
        );

        return seriesValue || null;
    };

    const getCumulativeValue = (
        seriesName: MeasureSeriesNames,
        asOfDate: string
    ): number | null => {
        const thisAsOf = props.measure?.valueHistory?.find(
            (v) => v.asOfDate === asOfDate
        );

        const thisSeriesValue = thisAsOf?.values?.find(
            (sv) => sv?.seriesType?.name === seriesName
        );

        const previousSeriesValue = getPreviousAsOfValue(seriesName, asOfDate);

        if (
            thisSeriesValue?.decimalValue === null ||
            thisSeriesValue?.decimalValue === undefined
        ) {
            // If no phasing values have been setup for this series, return null.
            return null;
        }

        // Remove the previous value from the period end value to get the cumulative fugure.
        let cumulativeValue: number = thisSeriesValue?.decimalValue || 0;
        cumulativeValue -= previousSeriesValue?.decimalValue || 0;
        return cumulativeValue;
    };

    const getYearEndFormattedValueForSeries = (
        seriesName: MeasureSeriesNames
    ): string | null => {
        const seriesAsOfs = sortedPhasings?.filter((asOf) =>
            asOf.values?.find((v) => v.seriesType?.name === seriesName)
        );

        if (seriesAsOfs && seriesAsOfs.length > 0) {
            const seriesValues =
                seriesAsOfs[seriesAsOfs.length - 1].values || [];
            const decimalValue = seriesValues.find(
                (v) => v.seriesType?.name === seriesName
            )?.decimalValue;

            return decimalValue !== null && decimalValue !== undefined
                ? measureValueFormatter.formatDecimalValue(decimalValue)
                : null;
        } else {
            return null;
        }
    };

    const getFormattedValueForSeries = (
        value: MeasureValueHistoryItem | null | undefined,
        seriesName: MeasureSeriesNames
    ) => {
        let decimalValue: number | null;

        const seriesValue = value?.values?.find(
            (v) => v?.seriesType?.name === seriesName
        );

        const seriesDecimalValue =
            seriesValue?.decimalValue !== undefined
                ? seriesValue?.decimalValue
                : null;

        if (
            props.measure?.phaseType === PhaseType.Cumulative &&
            value?.asOfDate
        ) {
            decimalValue = getCumulativeValue(seriesName, value?.asOfDate);

            if (decimalValue !== null && seriesDecimalValue !== decimalValue) {
                return (
                    ' + ' +
                    measureValueFormatter.formatDecimalValue(decimalValue) +
                    ' (' +
                    measureValueFormatter.formatDecimalValue(
                        seriesDecimalValue || 0
                    ) +
                    ')'
                );
            }
        }

        return seriesDecimalValue !== null
            ? measureValueFormatter.formatDecimalValue(seriesDecimalValue)
            : null;
    };

    const columns: IColumn[] = [
        {
            key: 'asOfDate',
            name: 'As Of',
            fieldName: 'asOfDate',
            minWidth: 80,
            onRender: function renderAsOfDate(
                value?: MeasureValueHistoryItem | null,
                index?: number
            ): string | JSX.Element | undefined {
                const isFooter = index === undefined || index < 0;
                const isEditing = editingId === value?.id;

                return (
                    <DetailsListCellItemContainer>
                        {!isEditing && !isFooter && (
                            <Text variant="small">
                                {value?.asOfDate
                                    ? dayjs
                                          .utc(value?.asOfDate)
                                          .format('DD MMM YYYY')
                                    : ''}
                            </Text>
                        )}

                        {isFooter && !showAddPhase && <Text>Year End:</Text>}

                        {((isFooter && showAddPhase) || isEditing) && (
                            <DatePicker
                                {...DefaultProps.DatePickerProps}
                                disabled={isSaving || isSavingMeasure}
                                styles={{
                                    root: {
                                        marginTop: 5,
                                    },
                                }}
                                allowTextInput={false}
                                value={
                                    asOfInput.asOfDate
                                        ? dayjs(asOfInput.asOfDate).toDate()
                                        : undefined
                                }
                                onSelectDate={(
                                    date: Date | null | undefined
                                ): void => {
                                    const formatted = date
                                        ? dayjs(date).format('YYYY-MM-DD')
                                        : undefined;

                                    setAsOfInput({
                                        ...asOfInput,
                                        asOfDate: formatted || null,
                                    });
                                }}
                            />
                        )}
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'phasedTarget',
            name: 'Target / Budget',
            fieldName: 'phasedTarget',
            minWidth: 140,
            onRender: function renderPhasedTarget(
                value?: MeasureValueHistoryItem,
                index?: number
            ): string | JSX.Element | undefined | null {
                const isFooter = index === undefined || index < 0;
                const isEditing = editingId === value?.id;

                const yearEndValue = getYearEndFormattedValueForSeries(
                    MeasureSeriesNames.PhasedTarget
                );

                return (
                    <DetailsListCellItemContainer>
                        {!isEditing && !isFooter && (
                            <Text variant="small">
                                {getFormattedValueForSeries(
                                    value,
                                    MeasureSeriesNames.PhasedTarget
                                )}
                            </Text>
                        )}

                        {isFooter && !showAddPhase && (
                            <Text>{yearEndValue}</Text>
                        )}

                        {((isFooter && showAddPhase) || isEditing) &&
                            !!props.measure?.measureType && (
                                <ValueInput
                                    value={defaultTargetValue}
                                    disabled={isSaving || isSavingMeasure}
                                    measureType={props.measure.measureType}
                                    multiplier={props.measure.multiplier}
                                    currencySymbol={
                                        props.measure.currency?.symbol
                                    }
                                    decimalPlaces={props.measure.decimalPlaces}
                                    onChange={
                                        handlePhasedTargetValueInputChange
                                    }
                                />
                            )}
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'phasedForecast',
            name: 'Forecast (optional)',
            fieldName: 'phasedForecast',
            minWidth: 140,
            onRender: function renderPhasedForecast(
                value?: MeasureValueHistoryItem,
                index?: number
            ): string | JSX.Element | undefined | null {
                const isFooter = index === undefined || index < 0;
                const isEditing = editingId === value?.id;

                const yearEndValue = getYearEndFormattedValueForSeries(
                    MeasureSeriesNames.PhasedForecast
                );

                return (
                    <DetailsListCellItemContainer>
                        {!isEditing && !isFooter && (
                            <Text variant="small">
                                {getFormattedValueForSeries(
                                    value,
                                    MeasureSeriesNames.PhasedForecast
                                )}
                            </Text>
                        )}

                        {isFooter && !showAddPhase && (
                            <Text>{yearEndValue}</Text>
                        )}

                        {((isFooter && showAddPhase) || isEditing) &&
                            !!props.measure?.measureType && (
                                <ValueInput
                                    value={defaultForecastValue}
                                    disabled={isSaving || isSavingMeasure}
                                    measureType={props.measure.measureType}
                                    multiplier={props.measure.multiplier}
                                    currencySymbol={
                                        props.measure.currency?.symbol
                                    }
                                    decimalPlaces={props.measure.decimalPlaces}
                                    onChange={
                                        handlePhasedForecastValueInputChange
                                    }
                                />
                            )}
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'editIcon',
            name: '',
            minWidth: 64,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderEditIcon(
                value?: MeasureValueHistoryItem,
                index?: number
            ): JSX.Element | null {
                const isEditing = editingId === value?.id;
                const isFooter = index === undefined || index < 0;

                if (!props.allowEdit) {
                    return null;
                }

                if (!isEditing && !isFooter) {
                    return (
                        <IconButton
                            iconProps={{ iconName: 'Edit' }}
                            disabled={isSaving || isSavingMeasure}
                            onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                handleEditButtonClick(value);
                            }}
                        />
                    );
                } else if (isEditing) {
                    return (
                        <Stack horizontal>
                            <IconButton
                                iconProps={{ iconName: 'CheckMark' }}
                                disabled={isSaving || isSavingMeasure}
                                onClick={async (ev) => {
                                    ev.preventDefault();
                                    await savePhaseAsync();
                                    setEditingId(null);
                                }}
                            />
                            <IconButton
                                iconProps={{ iconName: 'Cancel' }}
                                disabled={isSaving || isSavingMeasure}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setEditingId(null);
                                }}
                            />
                        </Stack>
                    );
                } else if (showAddPhase) {
                    return (
                        <IconButton
                            iconProps={{ iconName: 'Add' }}
                            disabled={isSaving || isSavingMeasure}
                            onClick={savePhaseAsync}
                        />
                    );
                } else {
                    return null;
                }
            },
        },
    ];

    const sortedPhasings = (props.measure?.valueHistory || [])
        .slice()
        .filter((h) =>
            h?.values?.some(
                (v) =>
                    v?.seriesType?.name === MeasureSeriesNames.PhasedTarget ||
                    v?.seriesType?.name === MeasureSeriesNames.PhasedForecast
            )
        )
        .sort(sorters.asOfDateSorter);

    const selection = new Selection({
        onSelectionChanged: () => {
            setSelectedPhasings(selection.getSelection());
        },
    });

    const isBusy = props.isLoading || isSaving || isSavingMeasure;

    const onRenderDetailsFooter = (
        detailsFooterProps?: IDetailsFooterProps
    ): JSX.Element | null => {
        if (
            showAddPhase ||
            (props.measure?.phaseType === PhaseType.Cumulative &&
                sortedPhasings.length > 0 &&
                !isBusy)
        ) {
            return (
                <DetailsRow
                    {...detailsFooterProps}
                    columns={detailsFooterProps?.columns}
                    item={{}}
                    itemIndex={-1}
                    groupNestingDepth={detailsFooterProps?.groupNestingDepth}
                />
            );
        }
        return null;
    };

    const handleDeleteSelectionClick = async () => {
        await deleteSelectedPhasingsAsync();
        selection.setAllSelected(false);
    };

    const phaseTypeDictionary: {
        [key in PhaseType]: {
            text: string;
            title: string;
            iconProps: IIconProps;
        };
    } = {
        [PhaseType.Cumulative]: {
            text: 'Cumulative',
            iconProps: { iconName: 'Chart' },
            title: "All phasing targets prior to the measure's as of date are summed up.",
        },
        [PhaseType.PeriodEnd]: {
            text: 'Period End',
            iconProps: { iconName: 'BarChartVertical' },
            title: "The most recent phasing amount prior to the measure's as of date is used.",
        },
    };

    const handlePhaseTypeChanged = async (phaseType: PhaseType) => {
        if (!props.measure?.id) {
            return;
        }

        const input: Measure = {
            id: props.measure.id,
            missionId: props.measure.missionId,
            measureGroupId: props.measure.measureGroupId,
            name: props.measure.name,
            shortName: props.measure.shortName,
            description: props.measure.description,
            measureType: props.measure.measureType,
            phaseType: phaseType,
            currency: props.measure.currency
                ? {
                      code: props.measure.currency?.code,
                      descr: props.measure.currency?.descr,
                      symbol: props.measure.currency?.symbol,
                  }
                : null,
            multiplier: props.measure.multiplier,
            decimalPlaces: props.measure.decimalPlaces,
            statusType: props.measure.statusType,
            yellowStart: props.measure.yellowStart,
            greenRange: props.measure.greenRange,
            yellowRange: props.measure.yellowRange,
            isStatusLimited: props.measure.isStatusLimited,
            frequencyNumber: props.measure.frequencyNumber,
            frequencyPeriod: props.measure.frequencyPeriod,
            isLinked: props.measure.isLinked,
            linkedFromMeasureId: props.measure.linkedFromMeasureId,
            tags: props.measure.tags,
            sequence: props.measure.sequence,
            version: props.measure.version,

            previousFYMeasureId: props.measure.previousFYMeasureId,
            fullYearTarget: props.measure.fullYearTarget,
            fullYearString: props.measure.fullYearString,
            isFullYearTarget: props.measure.isFullYearTarget,
            chartDisplay: props.measure.chartDisplay,
            chartType: props.measure.chartType,
            showForecast: props.measure.showForecast,
            showFutureLook: props.measure.showFutureLook,
            isCustom: props.measure.isCustom,
            targetType: props.measure.targetType,
            valueType: props.measure.valueType,
            valueFormula: props.measure.valueFormula,
        };

        await updateMeasure({
            variables: {
                tenantId: currentTenantId || '',
                input: input,
            },
        });
    };

    return (
        <FocusZone>
            <Stack tokens={{ childrenGap: 0, padding: 0 }}>
                {props.allowEdit &&
                    !!currentTenantId &&
                    !!props.measure?.id && (
                        <Stack.Item>
                            <PhasingWarnings
                                currentTenantId={currentTenantId}
                                measureId={props.measure?.id}
                                valueHistory={props.measure?.valueHistory}
                                onHistoryChanged={props.onPhasingChanged}
                            />
                        </Stack.Item>
                    )}

                <Stack.Item>
                    {props.measure && sortedPhasings.length > 1 && (
                        <div style={{ paddingTop: 24 }}>
                            <HistoryChartV1
                                measureId={props.measure.id}
                                status={
                                    props.measure?.lastAsOf?.statusValue || 0
                                }
                                isStatusLimited={props.measure.isStatusLimited}
                                recentHistory={sortedPhasings}
                                measureType={props.measure.measureType}
                                multiplier={props.measure.multiplier}
                                decimalPlaces={props.measure.decimalPlaces}
                                currencySymbol={props.measure.currency?.symbol}
                                arrowColour={
                                    props.measure.lastAsOf?.arrowColour ||
                                    'YELLOW'
                                }
                                isCustom={props.measure.isCustom}
                                frequencyPeriod={props.measure.frequencyPeriod}
                                width="100%"
                                height={180}
                                hideActual
                                showTarget={false}
                                showTargetPhasing={true}
                                showForecast={true}
                                chartType={
                                    props.measure.phaseType ===
                                    PhaseType.PeriodEnd
                                        ? 'bar'
                                        : 'line'
                                }
                                showPhasingAsBar={
                                    props.measure.phaseType ===
                                    PhaseType.PeriodEnd
                                }
                                showAxis={true}
                                showLegend={true}
                            />
                        </div>
                    )}
                </Stack.Item>

                <Stack.Item>
                    <Separator />
                </Stack.Item>

                <Stack.Item>
                    {props.measure && props.allowEdit && (
                        <CommandBar
                            items={[
                                {
                                    key: 'PhaseType',
                                    title: phaseTypeDictionary[
                                        props.measure.phaseType
                                    ].title,
                                    disabled:
                                        isSaving ||
                                        props.isLoading ||
                                        isSavingMeasure,
                                    text: phaseTypeDictionary[
                                        props.measure.phaseType
                                    ].text,
                                    iconProps:
                                        phaseTypeDictionary[
                                            props.measure.phaseType
                                        ].iconProps,
                                    subMenuProps: {
                                        items: [
                                            PhaseType.PeriodEnd,
                                            PhaseType.Cumulative,
                                        ].map((pt) => {
                                            const r =
                                                phaseTypeDictionary[
                                                    pt as PhaseType
                                                ];
                                            return {
                                                key: pt,
                                                text: r.text,
                                                iconProps: r.iconProps,
                                                title: r.title,
                                                onClick: () => {
                                                    handlePhaseTypeChanged(pt);
                                                },
                                            };
                                        }),
                                    },
                                },
                                {
                                    key: 'AddRow',
                                    text: 'Add Row',
                                    onClick: handleAddPhaseButtonClick,
                                    disabled:
                                        isSaving ||
                                        isSavingMeasure ||
                                        props.isLoading ||
                                        showAddPhase ||
                                        !!editingId,
                                    iconProps: {
                                        iconName: 'Add',
                                    },
                                },
                                {
                                    key: 'DeleteSelection',
                                    text: 'Delete Selection',
                                    onClick: () => {
                                        handleDeleteSelectionClick();
                                    },
                                    disabled:
                                        !selectedPhasings.length ||
                                        isSaving ||
                                        isSavingMeasure ||
                                        props.isLoading,
                                    iconProps: {
                                        iconName: 'Delete',
                                    },
                                },
                            ]}
                        />
                    )}
                </Stack.Item>
                <ShimmeredDetailsList
                    items={sortedPhasings}
                    enableShimmer={
                        props.isLoading || isSaving || isSavingMeasure
                    }
                    shimmerLines={!props.isLoading ? sortedPhasings.length : 4}
                    columns={columns}
                    layoutMode={DetailsListLayoutMode.justified}
                    checkboxVisibility={CheckboxVisibility.onHover}
                    setKey="set"
                    selectionMode={
                        props.allowEdit
                            ? SelectionMode.multiple
                            : SelectionMode.none
                    }
                    selection={selection}
                    disableSelectionZone={isSaving || isSavingMeasure}
                    selectionPreservedOnEmptyClick={true}
                    onRenderDetailsFooter={onRenderDetailsFooter}
                    onShouldVirtualize={(): boolean => false}
                />
            </Stack>
        </FocusZone>
    );
}
