import React from 'react';
import orderBy from 'lodash/orderBy';

export const useSorter = <
    ValuesType extends { [key: string]: unknown | string | undefined | null }
>(
    unsortedItems: ValuesType[] | undefined | null,
    defaultSortColumnName: string,
    defaultSortIsDesc?: boolean | undefined,
    defaultSortIsString?: boolean | undefined
): {
    sortColumn: string | undefined;
    sortIsDesc: boolean;
    sortedItems: ValuesType[];
    setSortColumnName: (
        fieldName: string,
        isString?: boolean | undefined
    ) => void;
} => {
    const [sortColumn, setSortColumn] = React.useState<string>(
        defaultSortColumnName
    );
    const [sortIsDesc, setSortIsDesc] = React.useState<boolean>(
        defaultSortIsDesc || false
    );
    const [sortIsString, setSortIsString] = React.useState<boolean>(
        defaultSortIsString || false
    );

    const setSortColumnName = (
        fieldName: string,
        isString?: boolean | undefined
    ): void => {
        if (sortColumn === fieldName) {
            setSortIsDesc(!sortIsDesc);
        } else {
            setSortColumn(fieldName);
            setSortIsDesc(false);
            setSortIsString(isString || false);
        }
    };

    let sortedItems: ValuesType[] = [];

    if (sortColumn && unsortedItems) {
        if (sortIsString) {
            sortedItems = orderBy(
                unsortedItems,
                [
                    (items): string =>
                        (
                            (items[`${sortColumn}`] as string) || ''
                        ).toLowerCase(),
                ],
                [sortIsDesc ? 'desc' : 'asc']
            );
        } else {
            sortedItems = orderBy(unsortedItems, sortColumn, [
                sortIsDesc ? 'desc' : 'asc',
            ]);
        }
    } else {
        sortedItems = unsortedItems || [];
    }

    return {
        sortColumn,
        sortIsDesc,
        sortedItems,
        setSortColumnName,
    };
};
