import React from 'react';
import {
    ActionButton,
    ContextualMenuItemType,
    IContextualMenuItem,
} from '@fluentui/react';
import orderBy from 'lodash/orderBy';
import dayjs from 'dayjs';

export function TaskFilterDueSelector(props: {
    tasks: {
        due: string | null | undefined;
    }[];
    selectedDueDateRanges: { startDate: string; endDate: string }[];
    onSelectedDueDateRangesChanged: (
        selectedDueDates: { startDate: string; endDate: string }[]
    ) => void;
}): JSX.Element | null {
    const { tasks, selectedDueDateRanges, onSelectedDueDateRangesChanged } =
        props;

    const handleMonthClick = (range: {
        startDate: string;
        endDate: string;
    }) => {
        let updated: { startDate: string; endDate: string }[];
        if (
            selectedDueDateRanges.some(
                (r) =>
                    r.startDate === range.startDate &&
                    r.endDate === range.endDate
            )
        ) {
            updated = selectedDueDateRanges.filter(
                (ts) =>
                    ts.startDate !== range.startDate &&
                    ts.endDate !== range.endDate
            );
        } else {
            updated = [...(selectedDueDateRanges || []), range];
        }
        onSelectedDueDateRangesChanged(updated);
    };

    const months = tasks
        .filter((t) => !!t.due)
        .filter(
            (date, i, self) =>
                self.findIndex(
                    (t) =>
                        dayjs(t.due).month() === dayjs(date.due).month() &&
                        dayjs(t.due).year() === dayjs(date.due).year()
                ) === i
        )
        .map((t) => {
            const due = dayjs(t.due);
            return {
                month: due.month(),
                year: due.year(),
                isThisMonth:
                    due.month() === dayjs().month() &&
                    due.year() === dayjs().year(),
            };
        });

    const monthsSorted = orderBy(
        months,
        ['isThisMonth', 'year', 'month'],
        ['desc', 'asc', 'asc']
    );

    const menuItems: IContextualMenuItem[] = monthsSorted.flatMap((m) => {
        const range = {
            startDate: `${m.year}-${m.month + 1}-01`,
            endDate: `${m.year}-${m.month + 1}-${dayjs(
                `${m.year}-${m.month + 1}-01`
            ).daysInMonth()}`,
        };
        const isChecked = selectedDueDateRanges.some(
            (r) =>
                r.startDate === range.startDate && r.endDate === range.endDate
        );

        const menuItem: IContextualMenuItem = {
            key: `${m.month}${m.year}`,
            text: m.isThisMonth
                ? 'This Month'
                : dayjs(`${m.year}-${m.month + 1}-01`).format('MMMM YYYY'),
            canCheck: true,
            checked: isChecked,
            onClick: () => handleMonthClick(range),
            iconProps: {
                iconName: 'DateTime2',
            },
        };

        if (m.isThisMonth && months.length > 1) {
            return [
                menuItem,
                {
                    key: 'thismonth-divider',
                    itemType: ContextualMenuItemType.Divider,
                },
            ];
        } else {
            return menuItem;
        }
    });

    if (selectedDueDateRanges.length > 0) {
        menuItems.push(
            ...[
                {
                    key: 'clear-divider',
                    itemType: ContextualMenuItemType.Divider,
                },
                {
                    key: 'clear',
                    text: 'Clear',
                    iconProps: {
                        iconName: 'Cancel',
                    },
                    onClick: () => onSelectedDueDateRangesChanged([]),
                },
            ]
        );
    }

    if (!menuItems.length) {
        return null;
    }

    const text = `Due${
        selectedDueDateRanges.length === 1
            ? `: ${menuItems.find((mi) => mi.checked)?.text}`
            : selectedDueDateRanges.length > 1
            ? `: ${menuItems.find((mi) => mi.checked)?.text} (+${
                  selectedDueDateRanges.length - 1
              })`
            : ''
    }`;

    return (
        <ActionButton
            text={text}
            menuProps={{
                items: menuItems,
            }}
        />
    );
}
