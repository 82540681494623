import React from 'react';
import PropTypes from 'prop-types';

import {
    Icon,
    IconButton,
    IContextualMenuItem,
    IPanelHeaderRenderer,
    IPanelProps,
    Panel,
    PanelType,
    Stack,
    TooltipHost,
} from '@fluentui/react';

import { ActionBar } from './ActionBar';
import { ActiveViews, useActiveView } from '../../hooks/useActiveView';

type EditPanelProps = {
    showPanel: boolean;
    headerText: string;
    saveErrorMessage: string | null | undefined;
    isSaving: boolean;
    isValid: boolean;
    hasSaved?: boolean | null;
    panelType?: PanelType | null;
    additionalButtons?: JSX.Element[];
    headerContextMenuItems?: IContextualMenuItem[];
    onUpdateClick?: () => void | Promise<void>;
    onDeleteClick?: () => void | Promise<void>;
    onDismiss: () => void;
    infoTooltipContent?: JSX.Element;
    commandBar?: JSX.Element;
    deleteIconName?: string | null;
    deleteText?: string | null;
    forceScrollbar?: boolean;
    activeViewName: keyof typeof ActiveViews | null;
    activeViewAdditionalProperties?: {
        [key: string]: string | number | boolean | undefined;
    };
};

export function EditPanel(
    props: React.PropsWithChildren<EditPanelProps>
): JSX.Element {
    useActiveView(
        props.showPanel ? props.activeViewName : null,
        undefined,
        props.activeViewAdditionalProperties
    );

    const onRenderNavigationContent = (): JSX.Element => (
        <ActionBar
            isSaving={props.isSaving}
            isSaved={props.hasSaved || false}
            isValid={props.isValid}
            disabled={!props.showPanel}
            errorMessage={props.saveErrorMessage}
            onSaveButtonClick={props.onUpdateClick}
            onDiscardButtonClick={props.onDismiss}
            onDeleteButtonClick={props.onDeleteClick}
            additionalButtons={props.additionalButtons}
            commandBar={props.commandBar}
            deleteIconName={props.deleteIconName}
            deleteText={props.deleteText}
        />
    );

    const handleRenderPanelHeader = (
        panelProps?: IPanelProps,
        defaultRender?: IPanelHeaderRenderer
    ): JSX.Element | null => {
        return (
            <Stack horizontal>
                {defaultRender && defaultRender(props)}
                {panelProps?.headerText && props.infoTooltipContent && (
                    <TooltipHost
                        tooltipProps={{
                            onRenderContent: () =>
                                props.infoTooltipContent || null,
                        }}
                    >
                        <Icon
                            style={{ cursor: 'pointer' }}
                            iconName="Info"
                            aria-label="Rollup Info"
                        />
                    </TooltipHost>
                )}
                {(props.headerContextMenuItems || []).length > 0 && (
                    <IconButton
                        iconProps={{ iconName: 'More' }}
                        menuProps={{
                            items: props.headerContextMenuItems || [],
                        }}
                        onRenderMenuIcon={() => null}
                    />
                )}
            </Stack>
        );
    };

    return (
        <Panel
            isOpen={props.showPanel}
            onOuterClick={(): void => {
                // Fix dialog causing panel to close.
                // https://github.com/microsoft/fluentui/issues/6476
            }}
            isLightDismiss={false}
            type={props.panelType || PanelType.smallFixedFar}
            onDismiss={props.onDismiss}
            headerText={props.headerText}
            hasCloseButton={false}
            onRenderHeader={handleRenderPanelHeader}
            layerProps={{ eventBubblingEnabled: true }} // This allows dragging inside the panel, needed by the profile resize.
            styles={{
                header: {
                    paddingRight: props.infoTooltipContent ? 8 : undefined, // The header by default has a padding of 24 left & right
                },
                commands: {
                    zIndex: 1,
                },
                scrollableContent: props.forceScrollbar
                    ? {
                          // Always show the scrollbar
                          height: '100%',
                          overflowY: 'scroll',
                      }
                    : undefined,
            }}
            onRenderNavigationContent={onRenderNavigationContent}
        >
            {props.children}
        </Panel>
    );
}

EditPanel.propTypes = {
    showPanel: PropTypes.bool.isRequired,
    headerText: PropTypes.string.isRequired,
    saveErrorMessage: PropTypes.string,
    isSaving: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    hasSaved: PropTypes.bool,
    panelType: PropTypes.any,
    onUpdateClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onDismiss: PropTypes.func,
};
