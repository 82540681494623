import { useCallback, useMemo } from 'react';
import { MeasureTypes, Multipliers } from '../data/types';

export const useFormatters = (): {
    formatMeasureStatus: (
        statusValue: number | null | undefined,
        isStatusLimited: boolean
    ) => string;
    formatMeasureValue: (
        measureFormat: {
            measureType: MeasureTypes;
            multiplier: Multipliers | null;
            currencySymbol: string | null;
            decimalPlaces: number;
        },
        value: unknown
    ) => string;
    formatTaskPercentage: (
        percentComplete: number | null | undefined
    ) => string;
} => {
    const percentFormatter = useMemo(
        () =>
            new Intl.NumberFormat(undefined, {
                style: 'percent',
                maximumFractionDigits: 1,
            }),
        []
    );

    const formatMeasureStatus = useCallback(
        (
            statusValue: number | null | undefined,
            isStatusLimited: boolean
        ): string => {
            let statusLimitedValue = statusValue || 0;

            if (isStatusLimited) {
                statusLimitedValue = Math.min(statusLimitedValue, 1);
                statusLimitedValue = Math.max(statusLimitedValue, 0);
            }

            return percentFormatter.format(statusLimitedValue);
        },
        [percentFormatter]
    );

    const formatMeasureValue = useCallback(
        (
            measureFormat: {
                measureType: MeasureTypes;
                multiplier: Multipliers | null;
                currencySymbol: string | null;
                decimalPlaces: number;
            },
            value: unknown
        ): string => {
            if (
                measureFormat.measureType === MeasureTypes.OnOff ||
                measureFormat.measureType === MeasureTypes.YesNo ||
                measureFormat.measureType === MeasureTypes.Gyr ||
                measureFormat.measureType === MeasureTypes.Date
            ) {
                return value as string;
            }

            // Use British English for consistency for now.
            const locale = 'en-GB';

            const numberValue = value as number;
            const decimalPlaces = Math.max(
                Math.min(measureFormat.decimalPlaces, 4),
                0
            );

            if (numberValue !== null && numberValue !== undefined) {
                switch (measureFormat.measureType) {
                    case MeasureTypes.Percentage:
                        return `${(numberValue * 100).toFixed(decimalPlaces)}%`;
                    case MeasureTypes.Numeric:
                    case MeasureTypes.Currency: {
                        const prefix = measureFormat.currencySymbol
                            ? `${measureFormat.currencySymbol} `
                            : '';
                        switch (measureFormat.multiplier) {
                            case Multipliers.K:
                                return `${prefix}${(
                                    numberValue / 1000
                                ).toLocaleString(locale, {
                                    minimumFractionDigits: decimalPlaces,
                                    maximumFractionDigits: decimalPlaces,
                                })}K`;
                            case Multipliers.M:
                                return `${prefix}${(
                                    numberValue / 1000000
                                ).toLocaleString(locale, {
                                    minimumFractionDigits: decimalPlaces,
                                    maximumFractionDigits: decimalPlaces,
                                })}M`;
                            case Multipliers.B:
                                return `${prefix}${(
                                    numberValue / 1000000000
                                ).toLocaleString(locale, {
                                    minimumFractionDigits: decimalPlaces,
                                    maximumFractionDigits: decimalPlaces,
                                })}B`;
                            default:
                                return `${prefix}${numberValue.toLocaleString(
                                    locale,
                                    {
                                        minimumFractionDigits: decimalPlaces,
                                        maximumFractionDigits: decimalPlaces,
                                    }
                                )}`;
                        }
                    }
                    default: {
                        // Status is used for graph
                        return `${(numberValue * 100).toFixed()}%`;
                    }
                }
            } else {
                return '';
            }
        },
        []
    );

    const formatTaskPercentage = useCallback(
        (percentComplete: number | null | undefined): string => {
            return percentFormatter.format(percentComplete || 0);
        },
        [percentFormatter]
    );

    return {
        formatMeasureStatus,
        formatMeasureValue,
        formatTaskPercentage,
    };
};
