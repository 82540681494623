import React from 'react';
import { Stack, Label, TooltipHost, ActionButton } from '@fluentui/react';

export function TaskTypeToggle(props: {
    taskType: 'resourced' | 'checklist';
    onTaskTypeOptionChanged: (
        newChartOption: 'resourced' | 'checklist'
    ) => void;
    disabled?: boolean;
    allowChange?: boolean;
    hideSwitch: boolean;
}): JSX.Element {
    const {
        taskType,
        onTaskTypeOptionChanged,
        disabled,
        allowChange,
        hideSwitch,
    } = props;

    const switchButtonTooltipContent =
        taskType === 'resourced'
            ? 'Task Checklists allow you to itemize the activity associated with an Implied Tasks. Task Checklists are only available on tasks you are responsible for and will be disabled if any resources have been added.'
            : undefined;

    return (
        <Stack horizontal tokens={{ childrenGap: 8 }}>
            <Stack.Item align="end" grow>
                {taskType === 'resourced' && <Label>Resources</Label>}
            </Stack.Item>
            <Stack.Item align="start">
                {!hideSwitch && (
                    <TooltipHost content={switchButtonTooltipContent}>
                        <ActionButton
                            text={
                                taskType === 'resourced'
                                    ? 'Switch to Checklist'
                                    : 'Switch to Resourced'
                            }
                            iconProps={{
                                iconName: 'Switch',
                            }}
                            onClick={() =>
                                onTaskTypeOptionChanged(
                                    taskType === 'resourced'
                                        ? 'checklist'
                                        : 'resourced'
                                )
                            }
                            disabled={disabled || !allowChange}
                        />
                    </TooltipHost>
                )}
            </Stack.Item>
        </Stack>
    );
}
