import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { Multipliers } from '../../data/types';

export function MultiplierInput(props: {
    defaultValue: Multipliers | undefined | null;
    errorMessage: string | undefined;
    onChange: (newValue: Multipliers) => void;
    disabled?: boolean;
}): JSX.Element {
    const multipliers: IDropdownOption[] = [
        { key: Multipliers.None, text: 'None' },
        { key: Multipliers.K, text: 'Thousands (K)' },
        { key: Multipliers.M, text: 'Millions (M)' },
        { key: Multipliers.B, text: 'Billions (B)' },
    ];
    return (
        <Dropdown
            label="Multiplier"
            defaultSelectedKey={props.defaultValue || Multipliers.None}
            options={multipliers}
            errorMessage={props.errorMessage}
            onChange={(ev: React.FormEvent, option?): void => {
                props.onChange(
                    option ? (option.key as Multipliers) : Multipliers.None
                );
            }}
            disabled={props.disabled}
        />
    );
}
