import React, { useEffect, useState } from 'react';
import { ChoiceGroup, IChoiceGroupOption, PanelType } from '@fluentui/react';
import { EditPanel } from '../../../components/shared/EditPanel';

export function TemplateReportEditLayoutPanel(props: {
    layoutDirection: string | null;
    isOpen: boolean;
    onDismiss: () => void;
    onLayoutChanged: (layoutDirection: string) => void;
    isReadOnly: boolean;
}) {
    const [layoutDirection, setLayoutDirection] = useState<string>('row');

    useEffect(() => {
        if (props.isOpen && props.layoutDirection) {
            setLayoutDirection(props.layoutDirection);
        }
    }, [props.isOpen, props.layoutDirection]);

    const handleUpdateClick = () => {
        props.onLayoutChanged(layoutDirection);
        props.onDismiss();
    };

    const options: IChoiceGroupOption[] = [
        {
            key: 'row',
            text: 'Horizontal',
            iconProps: { iconName: 'TripleColumn' },
        },
        {
            key: 'column',
            text: 'Vertical',
            styles: {
                iconWrapper: {
                    transform: 'rotate(90deg)',
                },
            },
            iconProps: { iconName: 'TripleColumn' },
        },
    ];

    return (
        <EditPanel
            activeViewName="TemplateReport"
            onDismiss={props.onDismiss}
            showPanel={props.isOpen}
            panelType={PanelType.smallFixedFar}
            headerText="Change Layout"
            isSaving={false}
            isValid
            hasSaved={false}
            saveErrorMessage={undefined}
            onUpdateClick={handleUpdateClick}
        >
            <ChoiceGroup
                label="Choose how the contents will stack"
                selectedKey={layoutDirection}
                onChange={(_ev, opt) => setLayoutDirection(opt?.key || 'row')}
                options={options}
            />
        </EditPanel>
    );
}
