import React from 'react';

import {
    Stack,
    Dropdown,
    IDropdownOption,
    PanelType,
    DetailsList,
    DetailsListLayoutMode,
    mergeStyles,
    IColumn,
    IconButton,
    SelectionMode,
} from '@fluentui/react';
import { EditPanel } from '../../../components/shared/EditPanel';
import { useStateContext } from '../../../services/contextProvider';
import {
    UserQl,
    useUpdateRightMutation,
    useRightDeleteMutation,
    TeamQl,
    useGetRightsQuery,
    GetUsersQuery,
    useGetUsersSearchQuery,
} from '../../../data/types';
import { Guid } from 'guid-typescript';
import { InputShimmer } from '../../../components/inputs';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';

type UserQL = ExtractQueryArrayType<GetUsersQuery, ['userSearch']>;

export type TeamAccessPanelProps = {
    showPanel: boolean;
    showGlobalRights: boolean;
    team: TeamQl | null;
    users: UserQL[];
    onUpdate: () => void;
    onCancel: () => void;
};

export interface IDetailsListCompactExampleItem {
    id: string;
    name: string;
    read: boolean;
    write: boolean;
    export: boolean;
    import: boolean;
    divisionId: string | null;
    teamId: string | null;
    missionId: string | null;
    userId: string;
}

export interface IDetailsListCompactExampleState {
    items: IDetailsListCompactExampleItem[];
    selectionDetails: string;
}

type Errors = {
    saveError?: string;
    teamSelector?: string;
};

export function TeamAccessPanel(props: TeamAccessPanelProps): JSX.Element {
    const { currentTenantId } = useStateContext();
    const [showPanel, setShowPanel] = React.useState(false);
    const [errors, setErrors] = React.useState<Errors>();

    React.useEffect(() => {
        if (props.showPanel !== showPanel) {
            setShowPanel(props.showPanel);
        }
    }, [props.showPanel, showPanel]);

    const { data: rightsQuery, loading: rightsLoading } = useGetRightsQuery({
        skip: !props.showPanel || !currentTenantId || !props.team?.id,
        variables: {
            userId: null,
            divisionId: null,
            teamId: props.team?.id || null,
            missionId: null,
            tenantId: currentTenantId || '',
            financialYearCode: null,
        },
    });

    const { data: userQuery, loading: usersLoading } = useGetUsersSearchQuery({
        skip: !props.showPanel || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            searchText: '',
            useCache: false,
        },
    });

    const [updateRight, { loading: updateRightLoading }] =
        useUpdateRightMutation({
            onError: (error) => {
                setErrors({
                    ...errors,
                    saveError: error.message,
                });
            },
            refetchQueries: ['GetRights'],
        });

    const [rightDeleteMutation, { loading: deleteRightLoading }] =
        useRightDeleteMutation({
            refetchQueries: ['GetRights'],
        });

    const getUserText = (id: string | null) => {
        if (!id) return 'Not found';
        if (usersLoading) return 'Loading...';
        return (
            userQuery?.userSearch?.find((u) => u.id === id)?.displayName || ''
        );
    };

    const userRights: IDetailsListCompactExampleItem[] =
        rightsQuery?.rights
            .filter((r) => r.userId != null)
            .map((r) => {
                return {
                    ...r,
                    name: getUserText(r.userId),
                };
            }) || [];

    const usersCanAdd = userQuery?.userSearch
        ?.filter((u) => {
            const some = rightsQuery?.rights.some((r) => {
                return r.userId === u.id;
            });
            return !some;
        })
        .map((d) => d as UserQl); //TODO: Might need to be extracted type

    const toggleRight = async (
        item: IDetailsListCompactExampleItem,
        field: string
    ) => {
        if (
            field === 'read' ||
            field === 'write' ||
            field === 'export' ||
            field === 'import'
        ) {
            await updateRight({
                variables: {
                    tenantId: currentTenantId || '',
                    right: {
                        id: item.id || Guid.createEmpty().toString(),
                        read: field === 'read' ? !item.read : item.read,
                        write: field === 'write' ? !item.write : item.write,
                        export: field === 'export' ? !item.export : item.export,
                        import: field === 'import' ? !item.import : item.import,
                        divisionId: item.divisionId,
                        teamId: item.teamId,
                        missionId: item.missionId,
                        userId: item.userId,
                    },
                },
            });
        }
    };

    const addRight = async (
        divisionId: string | null,
        teamId: string | null,
        missionId: string | null,
        userId: string
    ) => {
        await updateRight({
            variables: {
                tenantId: currentTenantId || '',
                right: {
                    id: Guid.createEmpty().toString(),
                    read: true,
                    write: true,
                    export: true,
                    import: true,
                    divisionId: divisionId,
                    teamId: teamId,
                    missionId: missionId,
                    userId: userId,
                },
            },
        });
    };

    const onDeleteClick = async (right: IDetailsListCompactExampleItem) => {
        await rightDeleteMutation({
            variables: {
                tenantId: currentTenantId || '',
                id: right.id,
                restore: false,
            },
        });
    };

    const buildColumns = (name: string): IColumn[] => {
        const rightsTable: IColumn[] = [];
        rightsTable.push({
            key: 'column1',
            name: name,
            fieldName: 'name',
            minWidth: 150,
            isResizable: true,
        });

        const names = ['Read', 'Write', 'Export', 'Import'];
        const fieldNames = ['read', 'write', 'export', 'import'];
        for (let i = 0; i < 4; i++) {
            rightsTable.push({
                key: `column${i + 2}`,
                name: names[Number(i)],
                fieldName: fieldNames[Number(i)],
                minWidth: 50,
                maxWidth: 70,
            });
        }

        rightsTable.push({
            key: 'column6',
            name: '',
            fieldName: 'delete',
            minWidth: 30,
            maxWidth: 30,
        });
        return rightsTable;
    };

    function renderItemColumn(
        item: IDetailsListCompactExampleItem,
        index?: number,
        column?: IColumn
    ) {
        const fieldContent = item[
            column?.fieldName as keyof IDetailsListCompactExampleItem
        ] as string;

        switch (column?.key) {
            case 'column2':
            case 'column3':
            case 'column4':
            case 'column5':
                if (fieldContent) {
                    return (
                        <span
                            data-selection-disabled={true}
                            className={mergeStyles({
                                display: 'block',
                                height: '25px',
                                background: '#A5D782',
                                border: 'solid 1px #8FC668',
                                cursor: 'pointer',
                            })}
                            onClick={() => {
                                if (column.fieldName) {
                                    toggleRight(item, column.fieldName);
                                }
                            }}
                        ></span>
                    );
                } else {
                    return (
                        <span
                            data-selection-disabled={true}
                            className={mergeStyles({
                                display: 'block',
                                height: '25px',
                                background: '#FF7E79',
                                border: 'solid 1px #F56964',
                                cursor: 'pointer',
                            })}
                            onClick={() => {
                                if (column.fieldName) {
                                    toggleRight(item, column.fieldName);
                                }
                            }}
                        ></span>
                    );
                }
            case 'column6':
                return (
                    <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        onClick={async () => {
                            onDeleteClick(item);
                        }}
                    ></IconButton>
                );
            default:
                return <span>{fieldContent}</span>;
        }
    }

    return (
        <React.Fragment>
            <EditPanel
                activeViewName="TeamAccess"
                onDismiss={props.onCancel}
                showPanel={showPanel}
                headerText={'Team Access'}
                isSaving={updateRightLoading || deleteRightLoading}
                isValid={true}
                saveErrorMessage={errors?.saveError}
                panelType={PanelType.medium}
            >
                <Stack tokens={{ childrenGap: 8 }}>
                    <Stack>
                        <InputShimmer isDataLoaded={!rightsLoading}>
                            <DetailsList
                                compact={true}
                                items={userRights || []}
                                columns={buildColumns('Users')}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                selectionMode={SelectionMode.none}
                                ariaLabelForSelectionColumn="Toggle selection"
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                checkButtonAriaLabel="Row checkbox"
                                onRenderItemColumn={renderItemColumn}
                            />
                        </InputShimmer>

                        {usersCanAdd && usersCanAdd?.length > 0 && (
                            <InputShimmer isDataLoaded={!usersLoading}>
                                <Dropdown
                                    required
                                    options={
                                        usersCanAdd?.map((d) => {
                                            return {
                                                key: d.id || '',
                                                text:
                                                    d.displayName || d.id || '',
                                            };
                                        }) || []
                                    }
                                    defaultSelectedKey={null}
                                    placeholder="Select user"
                                    errorMessage={errors?.teamSelector}
                                    onChange={(
                                        _event: React.FormEvent,
                                        option?: IDropdownOption | undefined
                                    ): void => {
                                        if (option && props.team?.id) {
                                            addRight(
                                                null,
                                                props.team?.id,
                                                null,
                                                option.key as string
                                            );
                                        }
                                    }}
                                    label="Add User"
                                />
                            </InputShimmer>
                        )}
                    </Stack>
                </Stack>
            </EditPanel>
        </React.Fragment>
    );
}
