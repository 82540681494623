import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
    IconButton,
    Dialog,
    TextField,
    DialogType,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    IButtonStyles,
    mergeStyleSets,
} from '@fluentui/react';
import { useThemes } from '../hooks/useThemes';
import { useViewport } from '../hooks/useViewport';

// Yeah, yeah, I know this is spelt wrong. Sorry! I'll rename at some point.
export function EditibleText(
    props: PropsWithChildren<{
        isReadOnly: boolean;
        isRequired?: boolean;
        text: string;
        dialogTitle: string;
        onUpdateClick: (newText: string) => Promise<void>;
        disableOnMobile?: boolean;
    }>
): JSX.Element {
    const [isEditIconShown, setIsEditIconShown] = useState(false);
    const [isEditDialogShown, setIsEditDialogShown] = useState(false);
    const [isEditingDisabled, setIsEditingDisabled] = useState(
        props.isReadOnly
    );
    const [isUpdating, setIsUpdating] = useState(false);
    const [editTitleText, setEditTitleText] = useState('');

    const { currentTheme } = useThemes();

    const { isTouchDevice, width } = useViewport();

    useEffect(() => {
        const isMobile = width < 800;
        const disabled =
            (isMobile && !!props.disableOnMobile) || props.isReadOnly;
        setIsEditingDisabled(disabled);

        if (isTouchDevice && !disabled) {
            setIsEditIconShown(true);
        }
    }, [props.isReadOnly, props.disableOnMobile, isTouchDevice, width]);

    const handleMouseEnter = !props.isReadOnly
        ? () => setIsEditIconShown(true)
        : undefined;

    const handleMouseLeave =
        !props.isReadOnly && !isTouchDevice
            ? () => setIsEditIconShown(false)
            : undefined;

    const handleEditIconClick = () => {
        setEditTitleText(props.text);
        setIsUpdating(false);
        setIsEditDialogShown(true);
    };

    const handleUpdateClick = async () => {
        setIsUpdating(true);
        await props.onUpdateClick(editTitleText);
        setIsUpdating(false);
        setIsEditDialogShown(false);
    };

    const buttonStyle: IButtonStyles = {
        root: {
            color: currentTheme.palette.themeLighter,
            selectors: {
                '&:hover': {
                    color: currentTheme.palette.themePrimary,
                },
            },
        },
    };

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            alignItems: 'center',
            minHeight: 32,
        },
        iconContainer: {
            minHeight: 32,
            minWidth: 32,
        },
    });

    const isValid = !props.isRequired || !!editTitleText;

    return (
        <React.Fragment>
            <div
                className={classNames.container}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {props.children}
                {!isEditingDisabled && (
                    <div className={classNames.iconContainer}>
                        {isEditIconShown && (
                            <IconButton
                                iconProps={{ iconName: 'Rename' }}
                                onClick={handleEditIconClick}
                                styles={buttonStyle}
                                title="Rename"
                            />
                        )}
                    </div>
                )}
            </div>
            <Dialog
                hidden={!isEditDialogShown}
                onDismiss={() => setIsEditDialogShown(false)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: props.dialogTitle,
                    closeButtonAriaLabel: 'Close',
                }}
            >
                <TextField
                    value={editTitleText}
                    onChange={(_ev, newValue) => {
                        setEditTitleText(newValue || '');
                    }}
                    multiline
                    autoAdjustHeight
                    disabled={isUpdating}
                    required={props.isRequired}
                />

                <DialogFooter>
                    <PrimaryButton
                        text="Update"
                        onClick={handleUpdateClick}
                        disabled={isUpdating || !isValid}
                    />
                    <DefaultButton
                        onClick={() => setIsEditDialogShown(false)}
                        text="Cancel"
                        disabled={isUpdating}
                    />
                </DialogFooter>
            </Dialog>
        </React.Fragment>
    );
}
