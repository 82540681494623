import React from 'react';
import { Icon, mergeStyleSets, Persona, PersonaSize } from '@fluentui/react';
import { photoService } from '../services/photo.service';
import { useThemes } from '../hooks/useThemes';

export function HistoryCoin(props: {
    userId: string | null | undefined;
    userDisplayName: string;
    iconName: string;
}): JSX.Element {
    const { currentTheme } = useThemes();

    const styles = mergeStyleSets({
        container: {
            position: 'relative',
            width: 48,
            height: 28,
        },
        overlapItem: {
            position: 'absolute',
            top: 0,
            left: 0,
        },
        persona: {
            marginLeft: 0,
        },
        icon: {
            marginTop: 16,
            marginLeft: 20,
        },
    });

    const imageUrl = photoService.getImageUrl(props.userId);

    return (
        <div className={styles.container}>
            <div className={`${styles.overlapItem} ${styles.persona}`}>
                <Persona
                    imageUrl={imageUrl}
                    text={props.userId ? props.userDisplayName : '?'}
                    imageInitials={!props.userId ? '⚙' : undefined}
                    size={PersonaSize.size32}
                    hidePersonaDetails
                    coinProps={{
                        styles: {
                            coin: {
                                borderRadius: '50%',
                                border: `solid 2px ${currentTheme.palette.white}`,
                            },
                        },
                    }}
                />
            </div>
            <div className={`${styles.overlapItem} ${styles.icon}`}>
                <Icon
                    iconName={props.iconName}
                    styles={{
                        root: {
                            backgroundColor:
                                currentTheme.palette.themeSecondary,
                            color: currentTheme.palette.white,
                            borderRadius: '50%',
                            fontSize: 12,
                            lineHeight: 12,
                            padding: 4,
                        },
                    }}
                />
            </div>
        </div>
    );
}
