import React, { useRef, useState } from 'react';
import { Icon, Stack, Text, Separator, ActionButton } from '@fluentui/react';
import MarkdownEditor, {
    MarkdownEditorRef,
} from '../../../components/inputs/MarkdownEditor';
import { AdvanceCard } from '../../../components/AdvanceCard';
import ExpandCollapse from '../../../components/ExpandCollapse';
import { Markdown } from '../../Presentations/Markdown';

export function StatusReportEditorCard(props: {
    title: string;
    iconName: string;
    defaultValue?: string | null;
    onChange: (value: string) => void;
    readOnly: boolean;
    canExpandCollapse?: boolean;
    insertButton?: {
        iconName: string;
        text: string;
        listToInsert: string[];
        disabled: boolean;
    };
    previousReportButton?: JSX.Element;
}): JSX.Element {
    const [isExpanded, setIsExpanded] = useState(true);

    const editorRef = useRef<MarkdownEditorRef>(null);

    const handleInsertButtonClick = () => {
        if (props.insertButton?.listToInsert) {
            editorRef.current?.insertList(props.insertButton?.listToInsert);
        }
    };

    return (
        <AdvanceCard style={{ height: '100%' }}>
            <AdvanceCard.Item>
                <Stack
                    horizontal
                    tokens={{ childrenGap: 8 }}
                    verticalAlign={'center'}
                >
                    {props.canExpandCollapse && (
                        <ExpandCollapse
                            isExpanded={isExpanded}
                            onExpand={(): void => setIsExpanded(true)}
                            onCollapse={(): void => setIsExpanded(false)}
                        />
                    )}
                    <Icon iconName={props.iconName} />
                    <Stack.Item grow>
                        <Text variant="mediumPlus" block>
                            {props.title}
                        </Text>
                    </Stack.Item>
                    <Stack.Item>
                        {!!props.insertButton && !props.readOnly && (
                            <ActionButton
                                iconProps={{
                                    iconName: props.insertButton.iconName,
                                }}
                                onClick={handleInsertButtonClick}
                                text={props.insertButton.text}
                                disabled={props.insertButton.disabled}
                                styles={{
                                    root: {
                                        height: 20, // Same as title's height
                                    },
                                }}
                            />
                        )}
                    </Stack.Item>

                    <Stack.Item>{props.previousReportButton}</Stack.Item>
                </Stack>
            </AdvanceCard.Item>
            {!props.readOnly && (
                <AdvanceCard.Item fill>
                    {isExpanded && (
                        <Separator
                            styles={{
                                root: {
                                    padding: 0,
                                },
                            }}
                        />
                    )}
                </AdvanceCard.Item>
            )}
            <AdvanceCard.Item>
                {isExpanded && !props.readOnly && (
                    <MarkdownEditor
                        ref={editorRef}
                        defaultValue={props.defaultValue}
                        onChange={props.onChange}
                    />
                )}
                {isExpanded && props.readOnly && (
                    <Markdown source={props.defaultValue} />
                )}
            </AdvanceCard.Item>
        </AdvanceCard>
    );
}
