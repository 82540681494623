import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    CSSProperties,
    useMemo,
} from 'react';
import { EditorState, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Schema, DOMParser, NodeSpec, Node } from 'prosemirror-model';
import { schema } from 'prosemirror-schema-basic';
import { keymap } from 'prosemirror-keymap';
import {
    getSuggestPluginState,
    suggest,
    SuggestChangeHandlerProps,
    Suggester,
} from 'prosemirror-suggest';
import {
    ContextualMenu,
    mergeStyleSets,
    PersonaCoin,
    PersonaSize,
} from '@fluentui/react';

import { useStateContext } from '../services/contextProvider';
import { useGetUsersSearchQuery } from '../data/types';
import { photoService } from '../services/photo.service';
import { useThemes } from '../hooks/useThemes';
import { baseKeymap } from 'prosemirror-commands';
import PlaceholderPlugin from './plugins/PlaceholderPlugin';

export const mentionNode: NodeSpec = {
    group: 'inline',
    inline: true,
    atom: true,
    attrs: {
        id: {},
        label: {},
    },
    selectable: false,
    toDOM: (node) => [
        'span',
        {
            'data-mention-id': node.attrs.id,
            class: 'mention',
            style: 'font-weight: bold;',
        },
        `@${node.attrs.label}`,
    ],
    parseDOM: [
        {
            tag: 'span[data-mention-id]',
            getAttrs: (dom) => ({
                id: dom.getAttribute('data-mention-id'),
                label: dom.textContent?.slice(1),
            }),
        },
    ],
};

const editorSchema = new Schema({
    nodes: schema.spec.nodes.addToEnd('mention', mentionNode),
    marks: schema.spec.marks,
});

interface MentionTextFieldProps {
    onChange: (value: string) => void;
    placeholder?: string;
    value?: string;
}

export const MentionTextField = ({
    onChange,
    value,
}: MentionTextFieldProps): JSX.Element => {
    const { currentTheme } = useThemes();

    const { currentTenantId } = useStateContext();
    const editorRef = useRef<HTMLDivElement>(null);
    const viewRef = useRef<EditorView | null>(null);
    const [calloutTarget, setCalloutTarget] = useState<HTMLElement>();
    const [searchText, setSearchText] = useState<string>('');
    const [serializedContent, setSerializedContent] = useState<string>();
    const [shouldFocusOnMount, setShouldFocusOnMount] = useState(false);

    const { data } = useGetUsersSearchQuery({
        skip: !currentTenantId || !searchText,
        variables: {
            tenantId: currentTenantId || '',
            searchText,
            useCache: false,
        },
    });

    const insertMention = useCallback((id: string, displayName: string) => {
        if (!viewRef.current) return;

        const { state, dispatch } = viewRef.current;
        const suggestState = getSuggestPluginState(viewRef.current.state);

        if (suggestState?.match?.range) {
            const { from, to } = suggestState.match.range;
            const node = editorSchema.nodes.mention.create({
                id,
                label: displayName,
            });
            const transaction = state.tr.replaceWith(from, to, node);
            dispatch(transaction);
        }

        setSearchText('');
        setCalloutTarget(undefined);
    }, []);

    const parseContent = useCallback((content: string) => {
        const nodes = [];
        const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
        let lastIndex = 0;
        let match;

        while ((match = mentionRegex.exec(content)) !== null) {
            if (match.index > lastIndex) {
                nodes.push(
                    editorSchema.text(content.slice(lastIndex, match.index))
                );
            }
            nodes.push(
                editorSchema.nodes.mention.create({
                    label: match[1],
                    id: match[2],
                })
            );
            lastIndex = mentionRegex.lastIndex;
        }

        if (lastIndex < content.length) {
            nodes.push(editorSchema.text(content.slice(lastIndex)));
        }

        return editorSchema.nodes.paragraph.create(null, nodes);
    }, []);

    const serializeDoc = useCallback((doc: Node) => {
        let result = '';

        let hasMentions = false;
        doc.descendants((node) => {
            if (node.type.name === 'mention') {
                hasMentions = true;
                return false;
            }
        });

        if (!hasMentions) {
            return doc.textBetween(0, doc.content.size, '\n', '');
        }

        doc.descendants((node: Node) => {
            if (node.type.name === 'mention') {
                result += `@[${node.attrs.label}](${node.attrs.id})`;
            } else if (node.isText) {
                result += node.text;
            } else if (node.isBlock && result.length > 0) {
                result += '\n';
            }
        });
        return result;
    }, []);

    const handleMentionChange = useCallback(
        (changeDetails: SuggestChangeHandlerProps) => {
            const pos = changeDetails.view.state.selection.$to.pos;
            const coords = changeDetails.view.coordsAtPos(pos);
            const query = changeDetails.query.full;
            setSearchText(query.trim());
            setCalloutTarget({
                getBoundingClientRect: () => ({
                    top: coords.top,
                    bottom: coords.bottom,
                    left: coords.left,
                    right: coords.right,
                    width: 0,
                    height: 0,
                }),
            } as HTMLElement);
        },
        []
    );

    const mentions: Suggester = useMemo(
        () => ({
            char: '@',
            name: 'mention_suggester',
            onChange: handleMentionChange,
            supportedCharacters: /[\w\s]+/, // allow spaces
        }),
        [handleMentionChange]
    );

    const [editorState, setEditorState] = useState<EditorState>();

    useEffect(() => {
        if (!editorRef.current) return;

        if (viewRef.current) return;

        const placeholderPlugin = PlaceholderPlugin('Write a comment...');
        const mentionPlugin = suggest(mentions);

        let state = editorState;

        if (!state) {
            state = EditorState.create({
                doc: DOMParser.fromSchema(editorSchema).parse(
                    editorRef.current
                ),
                plugins: [
                    placeholderPlugin,
                    mentionPlugin,
                    keymap({
                        ...baseKeymap,
                        ArrowDown: () => {
                            setShouldFocusOnMount(true);
                            return true;
                        },
                    }),
                ],
            });
            setEditorState(state);
        }

        if (state) {
            const view = new EditorView(editorRef.current, {
                state,
                dispatchTransaction(tr: Transaction) {
                    const newState = view.state.apply(tr);
                    view.updateState(newState);
                    const serialized = serializeDoc(newState.doc);
                    setSerializedContent(serialized);
                    onChange(serialized);
                },
            });

            viewRef.current = view;

            if (value) {
                view.dispatch(state.tr.insert(0, parseContent(value)));
            }
        }
    }, [editorState, mentions, onChange, parseContent, serializeDoc, value]);

    const classNames = mergeStyleSets({
        editor: currentTheme.components
            ? ({
                  ...currentTheme.components['TextField'].styles?.root,
                  backgroundColor: currentTheme.semanticColors.inputBackground,
                  borderColor: currentTheme.semanticColors.inputBorder,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  padding: 8,
              } as CSSProperties)
            : {},
    });

    useEffect(() => {
        if (value !== serializedContent && viewRef.current) {
            setSerializedContent(serializedContent);
            const { state, dispatch } = viewRef.current;
            const transaction = state.tr.replaceWith(
                0,
                state.doc.content.size,
                parseContent(value || '')
            );
            dispatch(transaction);
        }
    }, [value, serializedContent, parseContent]);

    return (
        <div>
            <ContextualMenu
                items={
                    data?.userSearch
                        .filter((u) => u.accessEnabled)
                        .map((u) => ({
                            key: u.id || '',
                            text: u.displayName || '',
                            iconProps: {},
                            onRenderIcon: () => (
                                <PersonaCoin
                                    imageUrl={photoService.getImageUrl(u.id)}
                                    text={u.displayName || u.id || ''}
                                    size={PersonaSize.size24}
                                />
                            ),
                        })) || []
                }
                onDismiss={() => {
                    setShouldFocusOnMount(false);
                }}
                hidden={!searchText || !calloutTarget}
                target={calloutTarget}
                onItemClick={(_ev, item) => {
                    if (item) {
                        insertMention(item?.key || '', item?.text || '');
                    }

                    if (viewRef.current) {
                        const { state, dispatch } = viewRef.current;
                        const tr = state.tr.insert(
                            state.selection.from,
                            state.schema.text(' ')
                        );
                        dispatch(tr);

                        viewRef.current.focus();
                    }
                    setShouldFocusOnMount(false);
                }}
                shouldFocusOnMount={shouldFocusOnMount}
            />

            <div ref={editorRef} className={classNames.editor} />
        </div>
    );
};
