import React from 'react';

import {
    Dropdown,
    DropdownMenuItemType,
    IDropdownOption,
} from '@fluentui/react';
import { useGetDivisionsQuery } from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';

import { InputShimmer } from '../../../components/inputs';

type DivisionPickerProps = {
    label?: string;
    placeholder?: string;
    selectedDivisionIds?: string[];
    selectedDivisionId?: string | null;
    multiSelect?: boolean;
    onChange: (divisionId: string, isSelected: boolean) => void;
    fyCodeFilter?: string | null;
};

export function DivisionPicker(props: DivisionPickerProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { data, loading } = useGetDivisionsQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: props.fyCodeFilter ?? null,
        },
    });

    const groupedDivisons = groupBy(
        orderBy(data?.divisions || [], (d) => d.financialYear?.code, 'desc'),
        (d) => d.financialYear?.code
    );

    const options: IDropdownOption[] = [];

    for (const fyCode in groupedDivisons) {
        const divisions = groupedDivisons[fyCode as string];
        options.push({
            key: fyCode,
            text: fyCode,
            itemType: DropdownMenuItemType.Header,
        });
        divisions.forEach((d) => {
            options.push({
                key: d.id || '',
                text: d.name || d.code || d.id || '',
            });
        });
    }

    const onChange = (
        _event?: React.FormEvent<HTMLDivElement>,
        item?: IDropdownOption
    ): void => {
        if (item) {
            if (props.multiSelect) {
                props.onChange(item.key as string, item.selected || false);
            } else {
                props.onChange(item.key as string, true);
            }
        }
    };

    return (
        <InputShimmer isDataLoaded={!loading}>
            <Dropdown
                placeholder={props.placeholder}
                label={props.label}
                selectedKeys={props.selectedDivisionIds}
                selectedKey={props.selectedDivisionId}
                onChange={onChange}
                multiSelect={props.multiSelect}
                options={options || []}
            />
        </InputShimmer>
    );
}
