import dayjs from 'dayjs';
import { FrequencyPeriods } from '../data/types';
import { DatePickerStrings } from '../services/i18n';
import { getQuarter } from '../scenes/Measure/utils/measureUtils';

export const useLatestValueWarning = (
    measure: {
        frequencyPeriod?: FrequencyPeriods | null | undefined;
        asOfDate?: string | null | undefined;
    },
    financialYear:
        | {
              startDate: string | null | undefined;
              endDate: string | null | undefined;
          }
        | undefined
        | null
): {
    hasWarning: boolean;
    warningMessage: string | null;
} => {
    let hasWarning = false;
    let warningMessage: string | null = null;

    // Compare to the last day of the year + 1
    const compareDate =
        financialYear?.endDate && dayjs(financialYear.endDate).isBefore(dayjs())
            ? dayjs(financialYear.endDate).add(1, 'day')
            : dayjs();

    let warningPeriod: string | null = null;

    if (measure?.asOfDate) {
        switch (measure?.frequencyPeriod) {
            case FrequencyPeriods.Day: {
                hasWarning = compareDate.diff(measure.asOfDate, 'days') > 0;
                break;
            }
            case FrequencyPeriods.Week: {
                hasWarning = compareDate.diff(measure.asOfDate, 'weeks') > 0;
                break;
            }
            case FrequencyPeriods.Month: {
                // Did this measure have data last month?
                const lastMonth = compareDate.subtract(1, 'month');
                hasWarning = dayjs(measure.asOfDate).isBefore(
                    lastMonth,
                    'month'
                );
                if (hasWarning) {
                    const nextUpdateMonth = dayjs(measure.asOfDate)
                        .add(1, 'month')
                        .month();
                    warningPeriod =
                        DatePickerStrings.months[nextUpdateMonth as number];
                }
                break;
            }
            case FrequencyPeriods.Quarter: {
                if (financialYear?.startDate) {
                    const lastQuarter =
                        getQuarter(financialYear.startDate, compareDate) - 1;

                    const asOfQuarter = getQuarter(
                        financialYear.startDate,
                        measure.asOfDate
                    );

                    if (lastQuarter > 0 && lastQuarter > asOfQuarter) {
                        hasWarning = true;
                    }

                    if (hasWarning) {
                        warningPeriod = `Q${asOfQuarter + 1}`;
                    }
                } else {
                    hasWarning =
                        compareDate.diff(measure.asOfDate, 'quarters') > 0;
                }

                break;
            }
            case FrequencyPeriods.Year: {
                hasWarning = false; // No warning for year
                break;
            }
        }

        if (hasWarning) {
            warningMessage = warningPeriod
                ? `Update Frequency Alert: A value update is due for ${warningPeriod}.`
                : 'Update Frequency Alert: A value update is due.';
        }
    }

    return {
        hasWarning,
        warningMessage,
    };
};
