import React, { useEffect, useState } from 'react';
import { Comment, useUpdateCommentMutation } from '../data/types';
import { useActiveView } from '../hooks/useActiveView';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from '@fluentui/react';
import { MentionTextField } from './MentionTextField';

export function CommentEditModal(props: {
    tenantId: string;
    comment: Comment | null;
    onCommentSaved: () => void;
    onDismiss: () => void;
}): JSX.Element {
    const [updateComment, { loading: isUpdating, error: updateError }] =
        useUpdateCommentMutation();

    const [commentText, setCommentText] = useState<string>('');

    useEffect(() => {
        if (props.comment) {
            setCommentText(props.comment.text || '');
        }
    }, [props.comment]);

    useActiveView(
        props.comment
            ? props.comment.measureId
                ? 'MeasureCommentEdit'
                : props.comment.taskId
                  ? 'TaskCommentEdit'
                  : null
            : null
    );

    const updateCommentAsync = async () => {
        if (props.comment) {
            await updateComment({
                variables: {
                    input: {
                        ...props.comment,
                        text: commentText,
                    },
                    tenantId: props.tenantId,
                },
            });
        }
        props.onCommentSaved();
    };

    const handleCommentTextChange = (newValue?: string | undefined) => {
        setCommentText(newValue || '');
    };

    return (
        <Dialog
            hidden={!props.comment}
            onDismiss={props.onDismiss}
            modalProps={{
                isBlocking: true,
            }}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Edit Comment',
                closeButtonAriaLabel: 'Close',
            }}
        >
            {updateError && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                >
                    {updateError.message}
                </MessageBar>
            )}

            {/* <TextField
                placeholder="Write a comment..."
                multiline
                autoAdjustHeight
                value={commentText}
                onChange={handleCommentTextChange}
            /> */}

            <MentionTextField
                value={commentText}
                onChange={handleCommentTextChange}
            />

            <DialogFooter>
                <PrimaryButton
                    text="Save"
                    disabled={isUpdating}
                    onClick={(): void => {
                        updateCommentAsync();
                    }}
                />
                <DefaultButton
                    text="Cancel"
                    disabled={isUpdating}
                    onClick={(): void => {
                        props.onDismiss();
                    }}
                />
            </DialogFooter>
        </Dialog>
    );
}
