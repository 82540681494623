import React, { useCallback } from 'react';

import EditMissionPanel from './EditMissionPanel';
import EditMeasurePanel from './EditMeasurePanel';
import EditMeasureActualPanel from './EditMeasureActualPanel';
import MeasurePanel from './MeasurePanel';
import CommentsPanel from './CommentsPanel';
import NewMissionPanel from './NewMissionPanel';
import AttachmentsPanel from './AttachmentsPanel';
import TaskPanel, { TaskPanelSections } from './TaskPanel';

export type PanelTypes =
    | 'new-mission'
    | 'edit-mission'
    | 'edit-measure'
    | 'edit-measure-actual'
    | 'new-task'
    | 'task-alignment'
    | 'edit-task'
    | 'edit-team'
    | 'measure-overview'
    | 'comments'
    | 'attachments'
    | null;

type EditPanelsProps = {
    teamId?: string | null;
    missionId?: string | null;
    taskId?: string | null;
    measureId?: string | null;
    measureGroupId?: string | null;
    activePanel: PanelTypes | null;
    onActivePanelChanged: (activePanel: PanelTypes | null) => void;
    onActiveTaskChanged: (activeTaskId: string | null) => void;
    onMeasureLockedToggle?: () => void;
    onDeleteMeasure?: () => void;
    hideDelete?: boolean;
};

export function EditPanels(props: EditPanelsProps): JSX.Element {
    const { onActivePanelChanged } = props;
    const closePanel = useCallback(
        (): void => onActivePanelChanged(null),
        [onActivePanelChanged]
    );

    return (
        <React.Fragment>
            <EditMeasurePanel
                missionId={props.missionId || ''}
                measureId={props.measureId}
                defaultMeasureGroupId={props.measureGroupId}
                showPanel={props.activePanel === 'edit-measure'}
                onCancel={closePanel}
                onSave={closePanel}
                onDelete={() => {
                    props.onActivePanelChanged(null);
                    if (props.onDeleteMeasure) {
                        props.onDeleteMeasure();
                    }
                }}
                onMeasureLockToggleClick={props.onMeasureLockedToggle}
                onAddValuesButtonClick={(): void =>
                    props.onActivePanelChanged('edit-measure-actual')
                }
                onMeasureOverviewButtonClick={(): void =>
                    props.onActivePanelChanged('measure-overview')
                }
                hideDelete={props.hideDelete}
            />

            <EditMeasureActualPanel
                measureId={props.measureId}
                showPanel={props.activePanel === 'edit-measure-actual'}
                onCancel={closePanel}
                onMeasureLockToggleClick={props.onMeasureLockedToggle}
                onEditMeasureButtonClick={(): void =>
                    props.onActivePanelChanged('edit-measure')
                }
                onMeasureOverviewButtonClick={(): void =>
                    props.onActivePanelChanged('measure-overview')
                }
            />

            <NewMissionPanel
                teamId={props.teamId}
                showPanel={props.activePanel === 'new-mission'}
                onCancel={closePanel}
                onSave={closePanel}
            />

            <EditMissionPanel
                missionId={props.missionId || ''}
                showPanel={props.activePanel === 'edit-mission'}
                onCancel={closePanel}
                onSave={closePanel}
            />

            <TaskPanel
                missionId={props.missionId || ''}
                taskId={props.activePanel === 'new-task' ? null : props.taskId}
                newImpliedSpecifiedTaskId={
                    props.activePanel === 'new-task' ? props.taskId : null
                }
                showPanel={
                    props.activePanel === 'task-alignment' ||
                    props.activePanel === 'edit-task' ||
                    props.activePanel === 'new-task'
                }
                sectionHint={
                    props.activePanel === 'edit-task' ||
                    props.activePanel === 'new-task'
                        ? TaskPanelSections.Edit
                        : TaskPanelSections.Alignment
                }
                onDismiss={closePanel}
                onSwapTask={props.onActiveTaskChanged}
                onCommentsButtonClick={(): void =>
                    props.onActivePanelChanged('comments')
                }
                onAttachmentsButtonClick={(): void =>
                    props.onActivePanelChanged('attachments')
                }
                hideDelete={props.hideDelete}
            />

            <MeasurePanel
                measureId={props.measureId}
                showPanel={props.activePanel === 'measure-overview'}
                onDismiss={closePanel}
                onMeasureLockToggleClick={props.onMeasureLockedToggle}
                onEditMeasureButtonClick={(): void =>
                    props.onActivePanelChanged('edit-measure')
                }
                onAddValuesButtonClick={(): void =>
                    props.onActivePanelChanged('edit-measure-actual')
                }
                onCommentsButtonClick={(): void =>
                    props.onActivePanelChanged('comments')
                }
                hideDelete={props.hideDelete}
            />

            <CommentsPanel
                measureId={props.measureId}
                taskId={props.taskId}
                showPanel={props.activePanel === 'comments'}
                onDismiss={closePanel}
            />

            <AttachmentsPanel
                measureId={props.measureId}
                taskId={props.taskId}
                showPanel={props.activePanel === 'attachments'}
                onDismiss={closePanel}
            />
        </React.Fragment>
    );
}
