import React, {
    CSSProperties,
    MouseEventHandler,
    PropsWithChildren,
} from 'react';
import {
    mergeStyleSets,
    AnimationStyles,
    ActionButton,
    IButtonProps,
    FontSizes,
} from '@fluentui/react';
import { useThemes } from '../hooks/useThemes';

export type AdvanceCardActionButton = IButtonProps & {
    key: string;
    onRender?: () => JSX.Element;
};

function AdvanceCardContainer(
    props: PropsWithChildren<{
        style?: CSSProperties;
        childrenGap?: number;
        onClick?: MouseEventHandler;
        title?: string;
        buttons?: AdvanceCardActionButton[];
    }>
): JSX.Element {
    const { currentTheme } = useThemes();

    const classNames = mergeStyleSets({
        advanceCard: {
            paddingTop: 8,
            paddingBottom: 8,
            width: '100%',
            backgroundColor: currentTheme.palette.white,
            boxShadow: currentTheme.effects.elevation4,
            transition: 'box-shadow 0.5s ease',
            borderRadius: 8,
            ':hover': {
                boxShadow: currentTheme.effects.elevation16,
            },
            ...AnimationStyles.fadeIn500,
            ...props.style,
            '@media print': {
                boxShadow: 'none !important',
                border: 'solid 1px #aaa',
            },
            display: 'flex',
            flexDirection: 'column',
            gap: 0,
            minWidth: '100%',
            boxSizing: 'border-box',
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            minHeight: 32,
            paddingLeft: 8,
            alignItems: 'center',
            marginBottom: 16,
        },
        title: {
            fontSize: FontSizes.mediumPlus,
            fontWeight: 600,
        },
        buttons: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
        },
    });

    const hasCommandBar = !!props.buttons?.length;

    return (
        <div
            className={classNames.advanceCard}
            style={{
                gap: props.childrenGap,
                ...props.style,
            }}
            onClick={props.onClick}
        >
            {(!!props.title || hasCommandBar) && (
                <AdvanceCard.Item>
                    <div className={classNames.header}>
                        <div>
                            <span className={classNames.title}>
                                {props.title}
                            </span>
                        </div>

                        {hasCommandBar && (
                            <div className={classNames.buttons}>
                                {props.buttons?.map((b) => {
                                    if (b.onRender) {
                                        return b.onRender();
                                    }
                                    return (
                                        <ActionButton
                                            {...b}
                                            key={b.key}
                                            style={{
                                                height: 32,
                                            }}
                                            className="no-print"
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </AdvanceCard.Item>
            )}

            {props.children}
        </div>
    );
}

function AdvanceCardItem(
    props: PropsWithChildren<{
        fill?: boolean;
        style?: CSSProperties;
        align?: 'auto' | 'stretch' | 'baseline' | 'start' | 'center' | 'end';
    }>
): JSX.Element {
    const classNames = mergeStyleSets({
        advanceCardItem: {
            padding: '0 8px',
        },
        advanceCardItemFill: {
            padding: 0,
        },
    });

    return (
        <div
            className={
                props.fill
                    ? classNames.advanceCardItemFill
                    : classNames.advanceCardItem
            }
            style={{ ...props.style, alignSelf: props.align }}
        >
            {props.children}
        </div>
    );
}

export const AdvanceCard = Object.assign(AdvanceCardContainer, {
    Item: AdvanceCardItem,
});
