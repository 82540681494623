import React, { useEffect, useMemo, useState } from 'react';

import { Icon, Link, mergeStyleSets, TooltipHost } from '@fluentui/react';
import {
    GetMeasurePeriodDataQuery,
    GetMeasuresForStatusReportQuery,
} from '../../data/types';
import {
    ExtractQueryArrayType,
    ExtractQueryType,
} from '../../data/extendedTypes';
import { useThemes } from '../../hooks/useThemes';
import { getLabelForDate } from '../../scenes/Measure/utils/measureUtils';

export function ReportPeriodDataActualWarning(props: {
    periodData:
        | ExtractQueryType<
              GetMeasurePeriodDataQuery,
              ['periodData', 'measurePeriodData']
          >
        | undefined
        | null;
    measure: ExtractQueryArrayType<
        GetMeasuresForStatusReportQuery,
        ['measures']
    > | null;
    fyStartDate: string | null | undefined;
    onWarningClick: (() => void) | null;
}) {
    const { currentTheme } = useThemes();

    const { measure, periodData, fyStartDate } = props;

    const [warningMessage, setWarningMessage] = useState<string | null>();

    const periodsMissingActuals = useMemo(
        () =>
            fyStartDate && measure
                ? periodData?.noActualsAsOfDates.map((aod) =>
                      getLabelForDate(fyStartDate, measure.frequencyPeriod, aod)
                  )
                : null,
        [fyStartDate, periodData, measure]
    );

    useEffect(() => {
        if (periodsMissingActuals?.length) {
            setWarningMessage(
                periodsMissingActuals.length > 1
                    ? `Actuals are missing for the periods: ${periodsMissingActuals.join(', ')}.`
                    : `Actual missing for ${periodsMissingActuals.join(', ')}.`
            );
        } else if (!periodData?.actualFormatted) {
            setWarningMessage('No values have been entered for this period.');
        } else {
            setWarningMessage(null);
        }
    }, [periodsMissingActuals, periodData?.actualFormatted]);

    const classNames = mergeStyleSets({
        icon: {
            color: currentTheme.semanticColors.severeWarningIcon,
        },
    });

    if (warningMessage) {
        return (
            <TooltipHost content={warningMessage}>
                <Link
                    onClick={
                        props.onWarningClick ? props.onWarningClick : undefined
                    }
                >
                    <Icon
                        iconName="EventDateMissed12"
                        className={classNames.icon}
                    />
                </Link>
            </TooltipHost>
        );
    }

    return null;
}
