import * as React from 'react';
import { FormEvent, useEffect, useState } from 'react';
import {
    Checkbox,
    Dropdown,
    IDropdownOption,
    mergeStyleSets,
} from '@fluentui/react';
import dayjs from 'dayjs';
import { InputShimmer } from '../../../components/inputs';
import { useGetMissionTemplatesReportsQuery } from '../../../data/types';
import orderBy from 'lodash/orderBy';
import { MissionComboBox } from '../../../components/MissionComboBox';

export function ReportExportTypePicker(props: {
    tenantId: string;
    financialYearCode: string;
    missionId?: string;
    onReportChange: (
        report: {
            reportType: 'TemplateReport' | 'MissionAnalysis';
            templateReportId: string | null;
            templateReportViewId: string | null;
            missionId: string;
            missionName: string;
            reportName: string;
        } | null
    ) => void;
}): JSX.Element {
    const { onReportChange, missionId } = props;
    interface IExportDropdownOption extends IDropdownOption {
        reportType?: 'TemplateReport' | 'MissionAnalysis' | null;
        templateReportViewId?: string | null;
    }

    const [selectedMission, setSelectedMission] = useState<{
        id: string;
        name: string;
    } | null>(null);

    const [selectedReport, setSelectedReport] = useState<{
        id: string;
        name: string;
        reportType: 'TemplateReport' | 'MissionAnalysis' | null | undefined;
        templateReportViewId: string | null;
    } | null>(null);

    const [includeDraftReports, setIncludeDraftReports] = useState<boolean>();

    const { data: templateReportData, loading: templateReportsLoading } =
        useGetMissionTemplatesReportsQuery({
            skip: !selectedMission?.id || !props.tenantId,
            variables: {
                tenantId: props.tenantId,
                missionId: selectedMission?.id || '',
            },
        });

    useEffect(() => {
        onReportChange(
            selectedReport?.id && selectedReport?.reportType
                ? {
                      templateReportId: selectedReport.id || null,
                      templateReportViewId:
                          selectedReport.templateReportViewId || null,
                      reportName: selectedReport.name || '',
                      missionId: selectedMission?.id || '',
                      missionName: selectedMission?.name || '',
                      reportType: selectedReport.reportType,
                  }
                : null
        );
    }, [onReportChange, selectedReport, selectedMission]);

    useEffect(() => {
        if (missionId) {
            setSelectedMission({
                id: missionId,
                name: 'DEFAULT',
            });
        }
    }, [missionId]);

    const handleMissionChange = (
        mission: { id: string; name: string } | null
    ): void => {
        setSelectedMission(mission);
        setSelectedReport(null);
    };

    const onIncludeDraftsChange = (
        _ev?: FormEvent,
        checked?: boolean | undefined
    ) => {
        setIncludeDraftReports(checked);
    };

    const onReportChanged = (
        _event?: React.FormEvent,
        option?: IExportDropdownOption
    ) => {
        setSelectedReport(
            option?.key
                ? {
                      id: option.key as string,
                      templateReportViewId: option.templateReportViewId || null,
                      name: option.text || '',
                      reportType: option.reportType,
                  }
                : null
        );
    };

    const templateReports = orderBy(
        templateReportData?.reports?.templateReports.filter(
            (tr) => !!tr.utcCompletedDate || includeDraftReports
        ),
        ['reportDate', 'utcCreated'],
        ['desc', 'desc']
    );

    const reportOptions: IExportDropdownOption[] = [];

    templateReports.forEach((tr) => {
        reportOptions.push({
            key: tr.id || '',
            text:
                ([
                    tr.title,
                    dayjs(tr.reportDate || tr.utcCreated).format('DD MMM YYYY'),
                ].join(' - ') || 'Untitled Report') +
                (!tr.utcCompletedDate ? ' (DRAFT)' : ''),
            reportType: 'TemplateReport',
        });

        tr.views.forEach((v) => {
            reportOptions.push({
                key: tr.id || '',
                text:
                    ([
                        tr.title,
                        dayjs(tr.reportDate || tr.utcCreated).format(
                            'DD MMM YYYY'
                        ),
                        v.title,
                    ].join(' - ') || 'Untitled Report') +
                    (!tr.utcCompletedDate ? ' (DRAFT)' : ''),
                reportType: 'TemplateReport',
                templateReportViewId: v.id,
            });
        });
    });

    reportOptions.unshift({
        key: selectedMission?.id || '',
        text: 'Mission Analysis',
        reportType: 'MissionAnalysis',
    });

    if (reportOptions.length) {
        reportOptions.unshift({
            key: '',
            text: 'Select Report',
            reportType: null,
        });
    } else {
        reportOptions.unshift({
            key: '',
            text: 'No reports found',
            reportType: null,
        });
    }

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 8,
            '@media (max-width: 800px)': {
                flexDirection: 'column',
            },
        },
        containerItem: {
            flexGrow: 1,
            flexBasis: 1,
            alignSelf: 'end',
            minWidth: 200,
            '@media (max-width: 800px)': {
                alignSelf: 'start',
            },
        },
    });

    return (
        <div className={classNames.container}>
            {!props.missionId && (
                <div className={classNames.containerItem}>
                    <MissionComboBox
                        label="Mission"
                        placeholder="Select Mission"
                        selectedMissionId={selectedMission?.id}
                        onMissionChange={handleMissionChange}
                        financialYearCode={props.financialYearCode}
                    />
                </div>
            )}

            <div className={classNames.containerItem}>
                <InputShimmer isDataLoaded={!templateReportsLoading}>
                    {!!selectedMission?.id && (
                        <Dropdown
                            label="Report"
                            options={reportOptions}
                            selectedKey={selectedReport?.id ?? ''}
                            onChange={onReportChanged}
                            placeholder="Select Report"
                            disabled={reportOptions.length === 1}
                            notifyOnReselect
                        />
                    )}
                </InputShimmer>
            </div>

            <div className={classNames.containerItem}>
                {!!selectedMission?.id && (
                    <Checkbox
                        label="Include draft reports"
                        onChange={onIncludeDraftsChange}
                    />
                )}
            </div>
        </div>
    );
}
