import React, { memo, useCallback } from 'react';

import {
    IBreadcrumbStyles,
    IBreadcrumbItem,
    Breadcrumb,
    Shimmer,
    IContextualMenuProps,
    IShimmerStyles,
} from '@fluentui/react';
import { useBreadcrumbItems } from './useBreadcrumbItems';
import { BreadcrumbButton } from './BreadcrumbButton';
import { BreadcrumbOverflowButton } from './BreadcrumbOverflowButton';

export interface AdvanceBreadcrumb extends IBreadcrumbItem {
    menuProps?: IContextualMenuProps;
}

type BreadcrumbsProps = {
    tenantId: string;
    financialYearCode: string;
    tenantCode: string;
    tenantName: string;
    teamCode: string | undefined;
    missionId: string | undefined;
    contributorId: string | undefined;
};

const shimmerStyles: Partial<IShimmerStyles> = {
    shimmerWrapper: {
        marginTop: 14,
    },
};

const breadcrumbStyle: Partial<IBreadcrumbStyles> = {
    root: {
        margin: 0,
        padding: 0,
        height: 44,
    },
    chevron: {
        padding: 8,
    },
    item: {
        margin: 0,
        padding: 0,
    },
    overflowButton: {
        height: 44,
    },
};

function Breadcrumbs(props: BreadcrumbsProps): JSX.Element {
    const { items, loading } = useBreadcrumbItems(props);

    const onRenderItem = useCallback(
        (item: IBreadcrumbItem | undefined): JSX.Element => {
            return <BreadcrumbButton breadcrumb={item as AdvanceBreadcrumb} />;
        },
        []
    );

    // This is a bit of a hack to fix the breadcrumbs not updating when items change.
    const key = `breadcrumbs_${props.tenantCode?.toUpperCase() || ''}_${props.financialYearCode?.toUpperCase() || ''}_${props.teamCode?.toUpperCase() || ''}_${props.missionId || ''}${props.contributorId || ''}_${items.length}_${loading}`;

    return (
        <Shimmer
            height="40"
            width="80%"
            styles={shimmerStyles}
            isDataLoaded={!loading && items.length > 0 && !!props.tenantId}
        >
            <Breadcrumb
                key={key}
                onRenderItem={onRenderItem}
                overflowButtonAs={(props) => (
                    <BreadcrumbOverflowButton {...props} allItems={items} />
                )}
                items={items}
                styles={breadcrumbStyle}
            />
        </Shimmer>
    );
}

export default memo(Breadcrumbs);
