import React from 'react';
import {
    ActivityItem,
    CommandBarButton,
    IActivityItemStyles,
    IButtonStyles,
    Icon,
    Link,
    mergeStyleSets,
} from '@fluentui/react';
import { useThemes } from '../hooks/useThemes';
import { CommentTooltipHost } from './CommentTooltipHost';
import { useComments } from '../hooks/useComments';

type CommentItemProps = {
    id: string | null | undefined;
    dateTime: string | undefined;
    content: string | null | undefined;
    authorName: string | null | undefined;
    authorUserId: string | null | undefined;
    maximumContentLines?: number | null;
    onClick?: () => void;
};

function CommentItem(props: CommentItemProps): JSX.Element {
    const { currentTheme } = useThemes();

    const { renderCommentText } = useComments();

    const classNames = mergeStyleSets({
        authorName: {
            fontWeight: 'bold',
        },
        noText: {
            fontStyle: 'italic',
        },
    });

    const commandBarStyles: IButtonStyles = {
        label: {
            whiteSpace: 'nowrap',
        },
        root: {
            height: 32, // Same as IconButton
        },
    };

    const activityItemStyles: IActivityItemStyles = {
        activityContent: {
            padding: 0,
            paddingLeft: 8,
        },
        activityText: {
            lineHeight: 'normal',
        },
        commentText: {
            lineHeight: 'normal',
        },
        personaContainer: {
            minWidth: 24,
            width: 24,
        },
        root: {
            color: currentTheme.palette.neutralSecondary, // workaround for ActivityItem not using the theme
        },
    };

    if (!props.id && props.onClick) {
        return (
            <CommandBarButton
                iconProps={{
                    iconName: 'CommentAdd',
                }}
                onClick={props.onClick}
                styles={commandBarStyles}
            >
                Add Comments
            </CommandBarButton>
        );
    }

    const lineClampClassName = props.maximumContentLines
        ? `line-clamp${props.maximumContentLines}`
        : undefined;

    return (
        <ActivityItem
            key={`latestComment-${props?.id || 'none'}`}
            styles={activityItemStyles}
            activityIcon={<Icon iconName={'Message'} />}
            activityDescription={
                <div>
                    <span className={classNames.authorName}>
                        {props.authorName}
                    </span>
                    &nbsp;commented
                </div>
            }
            comments={
                <CommentTooltipHost {...props}>
                    <Link onClick={props.onClick}>
                        {props.content?.trim() ? (
                            <div className={lineClampClassName}>
                                {renderCommentText(props.content)}
                            </div>
                        ) : (
                            <div className={classNames.noText}>
                                {props.content?.trim() || 'No Text'}
                            </div>
                        )}
                    </Link>
                </CommentTooltipHost>
            }
        />
    );
}

export default React.memo(CommentItem);
