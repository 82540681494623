import React from 'react';
import {
    ActivityItem,
    Icon,
    IconButton,
    Link,
    Stack,
    Text,
} from '@fluentui/react';
import { Access, ExtractQueryArrayType } from '../data/extendedTypes';
import {
    GetTaskQuery,
    refetchGetMissionQuery,
    refetchGetMissionTasksQuery,
    useDeleteTaskMutation,
} from '../data/types';
import DeleteModal from './shared/DeleteModal';
import { useThemes } from '../hooks/useThemes';

export type DuplicateTask = ExtractQueryArrayType<
    GetTaskQuery,
    ['task', 'subTasks']
>;

export default function DuplicateTaskList(props: {
    tenantId: string;
    missionId: string;
    missionAccess: Access;
    duplicateTasks: DuplicateTask[];
    onTaskUnmerged: () => void;
    onTaskClick: (taskId: string | null) => void;
}): JSX.Element {
    const [isConfirmingDeleteTaskId, setIsConfirmingDeleteTaskId] =
        React.useState<string | null>(null);

    const [deleteTaskMutation, { loading: isDeleting, error: deleteError }] =
        useDeleteTaskMutation();

    const deleteTaskAsync = async (taskId: string): Promise<void> => {
        await deleteTaskMutation({
            variables: {
                tenantId: props.tenantId,
                id: taskId,
                isTaskResource: false,
                restore: false,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                refetchGetMissionQuery({
                    tenantId: props.tenantId,
                    missionId: props.missionId,
                }),
                refetchGetMissionTasksQuery({
                    tenantId: props.tenantId,
                    missionId: props.missionId,
                }),
                'GetDependencies',
            ],
        });

        setIsConfirmingDeleteTaskId(null);
        props.onTaskUnmerged();
    };

    return (
        <React.Fragment>
            <DeleteModal
                activeViewName="TaskDelete"
                isOpen={isConfirmingDeleteTaskId !== null}
                onDismiss={(): void => setIsConfirmingDeleteTaskId(null)}
                message={
                    'Are you sure you want to unmerge this task? This task will return to the dependencies.'
                }
                deleteAction={() => {
                    if (isConfirmingDeleteTaskId) {
                        deleteTaskAsync(isConfirmingDeleteTaskId);
                    }
                }}
                primaryButtonText="Unmerge"
                isDeleting={isDeleting}
                error={deleteError}
            />
            <Stack tokens={{ childrenGap: 16 }}>
                {props.duplicateTasks.map((t) => {
                    const resourcedFromTask = t.resourcedFromTask;

                    const isRemoved = !!t.utcResourceRemoved;

                    return (
                        <Stack
                            horizontal
                            tokens={{ childrenGap: 4 }}
                            key={t.id}
                        >
                            <Stack.Item grow>
                                <Stack tokens={{ childrenGap: 4 }}>
                                    <Link
                                        onClick={() => props.onTaskClick(t.id)}
                                    >
                                        {t.name}
                                    </Link>

                                    {resourcedFromTask && (
                                        <Text variant="xSmall" block>
                                            {`Requested by: ${
                                                resourcedFromTask.mission
                                                    ?.owner ||
                                                resourcedFromTask.mission?.title
                                            }`}
                                        </Text>
                                    )}
                                    <Stack.Item>
                                        {isRemoved && (
                                            <DuplicateTaskWarningMessage text="This task has warnings" />
                                        )}

                                        {t.utcChangesPending && !isRemoved && (
                                            <DuplicateTaskWarningMessage text="This task has warnings" />
                                        )}
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                {!isRemoved && props.missionAccess.write && (
                                    <IconButton
                                        onClick={() =>
                                            setIsConfirmingDeleteTaskId(t.id)
                                        }
                                        iconProps={{
                                            iconName: 'DependencyRemove',
                                        }}
                                        title="Unmerge"
                                        styles={{
                                            label: {
                                                whiteSpace: 'nowrap',
                                            },
                                            root: {
                                                height: 32, // Same as IconButton
                                            },
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    );
                })}
            </Stack>
        </React.Fragment>
    );
}

function DuplicateTaskWarningMessage(props: { text: string }) {
    const { currentTheme } = useThemes();
    return (
        <ActivityItem
            styles={{
                activityTypeIcon: {},
                activityText: {
                    color: currentTheme.semanticColors.severeWarningIcon,
                },
            }}
            isCompact
            activityIcon={
                <Icon
                    iconName="Warning"
                    styles={{
                        root: {
                            color: currentTheme.semanticColors
                                .severeWarningIcon,
                        },
                    }}
                />
            }
            activityDescription={props.text}
        />
    );
}
