import React from 'react';
import { Text, PrimaryButton } from '@fluentui/react';
import { ErrorContainer } from './components/ErrorContainer';
import { authService } from '../../services/auth.service';
import { useActiveView } from '../../hooks/useActiveView';

export function NoTenantFound(props: {
    emailAddress?: string | null;
    isTenantDisabled?: boolean;
    disabledTenantName?: string | null;
}): JSX.Element {
    const logout = (): void => {
        authService.logoutAsync();
    };

    useActiveView('Error', 'Error');

    return (
        <ErrorContainer emailAddress={props.emailAddress}>
            {!props.isTenantDisabled && (
                <Text variant="xLargePlus" block>
                    No dashboard found
                </Text>
            )}
            {props.isTenantDisabled ? (
                <Text variant="medium" block>
                    Access to {props.disabledTenantName ?? 'this dashboard'} has
                    been deactivated.
                </Text>
            ) : (
                <Text variant="medium" block>
                    We could not find a dashboard linked to{' '}
                    {props.emailAddress || 'this email address'}.
                </Text>
            )}
            {props.isTenantDisabled ? (
                <Text variant="medium" block>
                    Please contact your account administrator for details.
                </Text>
            ) : (
                <Text variant="medium" block>
                    Please try another email address or contact our support
                    team.
                </Text>
            )}
            <PrimaryButton text="Log Out" onClick={logout}></PrimaryButton>
        </ErrorContainer>
    );
}
