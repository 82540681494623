import { Label } from '@fluentui/react';
import styles from './ApiHelp.module.css';

export function ApiHelpMeasureValues(): JSX.Element {
    return (
        <div>
            <h1>Measure Values</h1>
            A measure may have zero or many values. Each value has an AsOf date,
            and is linked to a series type like a 'Target' or 'Actual'.
            <br />
            <br />
            <Label>OData Query Parameters</Label>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td>
                            <code className={styles.method}>GET</code>
                            <code className={styles.code}>/MeasureAsOf</code>
                        </td>
                        <td>
                            Get a List of Measure Values
                            <br />
                            <br />
                            <p>
                                Filter to a single Measure:
                                <br />
                                <i>
                                    <code className={styles.code}>
                                        $filter=MeasureId eq &#123;id&#125;
                                    </code>
                                </i>
                            </p>
                            <p>
                                Expandable properties: <br />
                                <code className={styles.code}>
                                    ?$expand=Values(select=DecimalValue,SeriesType;expand=SeriesType(select=Name))
                                </code>
                            </p>
                            <p>
                                Useful selectable fields: <br />
                                <code className={styles.code}>
                                    ?$select=Id,MeasureId,AsOfDate,Values
                                </code>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <code className={styles.method}>GET</code>
                            <code className={styles.code}>
                                /MeasureAsOf(&#123;id&#125;)
                            </code>
                        </td>
                        <td>Get single Measure Value</td>
                    </tr>

                    <tr>
                        <td>
                            <code className={styles.method}>PATCH</code>
                            <code className={styles.code}>/MeasureAsOf</code>
                        </td>
                        <td>
                            Update one or more Measure Values
                            <br />
                            <br />
                            Example Body: <br />
                            <code className={styles.code}>
                                [<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&#123;
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"MeasureId":
                                "a677e5e7-22ce-41c7-de66-08dc3f5cf092",
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"AsOfDate":
                                "2025-01-01",
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Values":
                                [<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Series":
                                "Target",
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"DecimalValue":
                                100
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Series":
                                "Actual",
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"DecimalValue":
                                99
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&#125;
                                <br />]
                            </code>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
