import React from 'react';
import { ActionButton, IButtonStyles } from '@fluentui/react';
import { FontSizes } from '@fluentui/theme';
import { AdvanceBreadcrumb } from './Breadcrumbs';

export function BreadcrumbButton(props: {
    breadcrumb: AdvanceBreadcrumb;
}): JSX.Element {
    const { breadcrumb } = props;

    const buttonStyle: IButtonStyles = {
        root: {
            margin: 0,
            padding: 0,
            height: 44,
        },
        label: {
            margin: 0,
            padding: 0,
        },
        menuIcon: {
            fontSize: FontSizes.size10,
        },
        textContainer: {
            fontSize: FontSizes.size14,
            fontWeight:
                breadcrumb && breadcrumb.isCurrentItem ? 'bold' : 'normal',
        },
    };

    if (breadcrumb && breadcrumb.isCurrentItem) {
        return (
            <ActionButton
                text={breadcrumb.text}
                styles={buttonStyle}
                menuProps={breadcrumb.menuProps}
            />
        );
    } else if (breadcrumb) {
        return (
            <ActionButton
                text={breadcrumb.text}
                onRenderMenuIcon={(): null => null}
                styles={buttonStyle}
                menuProps={breadcrumb.menuProps}
            />
        );
    } else {
        return <span />;
    }
}
