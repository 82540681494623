import * as React from 'react';

import { useImporterBatchesQuery, BatchQl } from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import {
    ShimmeredDetailsList,
    IconButton,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    Stack,
    Persona,
    PersonaSize,
    Selection,
} from '@fluentui/react';
import { useSorter } from '../../../hooks/useSorter';
import { photoService } from '../../../services/photo.service';
import dayjs from 'dayjs';

type BatchListProps = {
    onBatchIdSelected?: (batchId: string | undefined) => void;
};

export function BatchList(props: BatchListProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { data, loading } = useImporterBatchesQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
        },
    });

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(data?.importerBatches, 'utcBatchDate', true, false);

    function notNull<T>(val: T | null): val is T {
        return val !== null;
    }

    const _selection = new Selection({
        onSelectionChanged: () => {
            if (props.onBatchIdSelected) {
                const batchIds = _selection
                    .getSelection()
                    .map((s) => {
                        const m = s as BatchQl;
                        return m.batchId;
                    })
                    .filter(notNull);
                const batchId = batchIds.length > 0 ? batchIds[0] : undefined;
                props.onBatchIdSelected(batchId);
            }
        },
    });

    const columns: IColumn[] = [
        {
            key: 'utcBatchDate',
            name: 'Date',
            fieldName: 'utcBatchDate',
            minWidth: 100,
            maxWidth: 180,
            isResizable: true,
            isSorted: sortColumn === 'utcBatchDate',
            isSortedDescending: sortColumn === 'utcBatchDate' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('utcBatchDate', false),
            onRender: (value): string =>
                dayjs
                    .utc(value.utcBatchDate)
                    .tz(dayjs.tz.guess())
                    .format('DD MMM YYYY - HH:mm (z)'),
        },
        {
            key: 'username',
            name: 'User',
            fieldName: 'username',
            minWidth: 200,
            isResizable: true,
            isSorted: sortColumn === 'username',
            isSortedDescending: sortColumn === 'username' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('username', true),
            onRender: function renderDisplayName(item: {
                updatedByUserId: string | null;
                username: string | null;
            }): JSX.Element {
                if (item.updatedByUserId) {
                    return (
                        <Persona
                            text={item.username || item.updatedByUserId}
                            imageUrl={photoService.getImageUrl(
                                item.updatedByUserId
                            )}
                            size={PersonaSize.size24}
                        />
                    );
                } else {
                    return <span />;
                }
            },
        },
        {
            key: 'batchId',
            name: 'BatchId',
            fieldName: 'batchId',
            minWidth: 200,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: sortColumn === 'batchId',
            isSortedDescending: sortColumn === 'batchId' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('batchId', true),
        },
        {
            key: 'actions',
            name: '',
            minWidth: 64,
            isIconOnly: true,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderActions(item: BatchQl): JSX.Element {
                return (
                    <Stack horizontal>
                        <IconButton
                            iconProps={{ iconName: 'NavigateForward' }}
                            onClick={(): void => {
                                if (props.onBatchIdSelected) {
                                    props.onBatchIdSelected(item.batchId);
                                }
                            }}
                        />
                    </Stack>
                );
            },
        },
    ];

    return (
        <Stack>
            <ShimmeredDetailsList
                setKey="items"
                items={sortedItems || []}
                columns={columns}
                selectionMode={SelectionMode.none}
                selection={_selection}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={loading}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details"
                listProps={{
                    renderedWindowsAhead: 0,
                    renderedWindowsBehind: 0,
                }}
                onItemInvoked={(item?: BatchQl): void => {
                    if (item && props.onBatchIdSelected) {
                        props.onBatchIdSelected(item.batchId);
                    }
                }}
            />
        </Stack>
    );
}
