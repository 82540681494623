import React, { useEffect, useState } from 'react';
import { Checkbox, mergeStyleSets } from '@fluentui/react';
import Loading from '../../../components/Loading';
import {
    useGetMeasureGroupsQuery,
    useGetMissionMeasuresQuery,
} from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import orderBy from 'lodash/orderBy';
import { EditPanel } from '../../../components/shared/EditPanel';

export function MeasurePickerPanel(props: {
    isOpen: boolean;
    onDismiss: () => void;
    onUpdateClick: (selectedMeasureIds: string[]) => void | Promise<void>;
    missionId: string;
    selectedMeasureIds: string[];
}) {
    const { currentTenantId } = useStateContext();

    const [selectedMeasureIds, setSelectedMeasureIds] = useState<string[]>([]);

    useEffect(() => {
        setSelectedMeasureIds(props.selectedMeasureIds);
    }, [props.selectedMeasureIds]);

    const { loading: groupsLoading, data: groupData } =
        useGetMeasureGroupsQuery({
            skip: !props.missionId || !currentTenantId,
            variables: {
                tenantId: currentTenantId || '',
                missionId: props.missionId,
            },
        });

    const { loading, data } = useGetMissionMeasuresQuery({
        skip: !props.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId,
        },
    });

    const measureGroups: {
        id: string | null;
        name: string | null;
        sequence: number;
    }[] = orderBy(groupData?.measureGroups, 'sequence');

    const measures = orderBy(data?.measures, 'sequence');

    if (measures.find((m) => m.measureGroupId === null)) {
        measureGroups.push({
            id: null,
            name: 'Ungrouped',
            sequence: 9990,
        });
    }

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            marginTop: 16,
        },
    });

    const handleUpdateClick = () => props.onUpdateClick(selectedMeasureIds);

    return (
        <EditPanel
            showPanel={props.isOpen}
            saveErrorMessage={undefined}
            isSaving={false}
            isValid={true}
            activeViewName={null}
            headerText="Pick Measures of Success"
            onUpdateClick={handleUpdateClick}
            onDismiss={props.onDismiss}
        >
            {(loading || groupsLoading) && <Loading />}

            <div className={classNames.container}>
                {measureGroups.map((mg) => {
                    return (
                        <div key={mg?.id || 'ungrouped'}>
                            {measureGroups.length > 1 && (
                                <strong>{mg?.name ?? 'Ungrouped'}</strong>
                            )}

                            {measures
                                .filter((m) => m.measureGroupId === mg?.id)
                                ?.map((m) => {
                                    return (
                                        <Checkbox
                                            key={m.id}
                                            label={m.name || m.id || ''}
                                            checked={selectedMeasureIds.some(
                                                (id) => id === m.id
                                            )}
                                            styles={{
                                                root: { marginTop: 4 },
                                            }}
                                            onChange={(_ev, checked) => {
                                                const updated =
                                                    selectedMeasureIds.filter(
                                                        (id) => id !== m.id
                                                    );
                                                if (checked && m.id) {
                                                    updated.push(m.id);
                                                }
                                                setSelectedMeasureIds(updated);
                                            }}
                                        />
                                    );
                                })}
                        </div>
                    );
                })}
            </div>
        </EditPanel>
    );
}
