import React from 'react';
import {
    Context,
    Dispatch,
    DispatchContext,
    DefaultState,
    InitialState,
    Reducer,
} from './context';

function ContextProvider(props: { children?: React.ReactNode }): JSX.Element {
    const [state, dispatch] = React.useReducer(Reducer, InitialState);

    return (
        <Context.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {props.children}
            </DispatchContext.Provider>
        </Context.Provider>
    );
}

export function useStateContext(): DefaultState {
    const context = React.useContext(Context);
    if (context === undefined) {
        throw new Error(
            'useStateContext must be used within a ContextProvider'
        );
    }
    return context;
}

export function useDispatchContext(): Dispatch {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
        throw new Error(
            'useDispatchContext must be used within a DispatchContextProvider'
        );
    }
    return context;
}

export default ContextProvider;
