import React, { MouseEvent } from 'react';

import { Toggle } from '@fluentui/react';

export function IsStatusLimitedInput(props: {
    defaultValue: boolean;
    onChange: (newValue: boolean) => void;
    disabled: boolean;
}): JSX.Element {
    return (
        <Toggle
            label="Limit status between 0% and 100%"
            defaultChecked={props.defaultValue}
            onChange={(ev: MouseEvent, checked?: boolean): void =>
                props.onChange(checked || false)
            }
            onText="Yes"
            offText="No"
            disabled={props.disabled}
        />
    );
}
